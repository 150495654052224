import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button, touchRippleClasses } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

//Part 2 ILLUSTRATION 5

export default function ComparativeProfitLossStatement() {

    const data = [{
        "questionPallete": [
            {
                "questionType": "debitSide",
                "context": {
                    "type": "receivedAndPosted",
                    "name": "Ashok",
                    "amtReceived": 2596,
                    "amtPosted": 180,
                    "suspense": 2776
                }
            },
            {
                "questionType": "debitSide",
                "context": {
                    "type": "paidAndPosted",
                    "name": "Repairing expenses",
                    "amtPaid": 1424,
                    "nameTo": "Machinery",
                    "amtTo": 624,
                    "suspense": 800
                }
            },
            {
                "questionType": "discount",
                "context": {
                    "type": "discountCreditedReceived",
                    "name": "Piyush",
                    "amount": 100,
                    "suspense": 200
                }
            },
            {
                "questionType": "debitSide",
                "context": {
                    "type": "paidAndDebited",
                    "name": "Narendra",
                    "amtPaid": 1828,
                    "nameTo": "Mahendra",
                    "amtTo": 1028,
                    "suspense": 800
                }
            },
            {
                "questionType": "undercast",
                "context": {
                    "type": "undercast",
                    "amount": 400,
                    "suspense": 400
                }
            },
            {
                "questionType": "purchase",
                "context": {
                    "type": "fixedAsset",
                    "amount": 1468,
                    "assetName": "Furniture"
                }
            }
        ],
        "totalRectificationAmount": 8096,
        "bookOwnerGender": "Shri",
        "bookOwnerName": "Ramanlal",
        "date": "2014-12-31",
        "rightSideArray1": [
            {
                "date": "",
                "name": "Balance",
                "amount": 576
            },
            {
                "date": 2,
                "name": "Repairing expenses",
                "amount": 800
            },
            {
                "date": 3,
                "name": "Piyush",
                "amount": 200
            },
            {
                "date": 4,
                "name": "Narendra",
                "amount": 800
            },
            {
                "date": 5,
                "name": "Purchase",
                "amount": 400
            }
        ],
        "leftSideArray1": [
            {
                "date": 1,
                "name": "Ashok",
                "amount": 2776
            }
        ],
        "Array2": [
            {
                "type": "receivedAndPosted",
                "right": "-",
                "left": "-"
            },
            {
                "type": "paidAndPosted",
                "cause": "Repairing expenses",
                "right": 1424,
                "left": "-"
            },
            {
                "type": "discountCreditedReceived",
                "right": "-",
                "left": "-"
            },
            {
                "type": "paidAndDebited",
                "right": "-",
                "left": "-"
            },
            {
                "type": "undercast",
                "right": 400,
                "left": "-"
            },
            {
                "type": "fixedAsset",
                "right": "-",
                "left": 1468
            }
        ],
        "Array2LeftTotal": 1468,
        "Array2RightTotal": 1824,
        "profitDiscloseAmount": 31440,
        "profitDiscloseAmount1": 32908,
        "profitDiscloseAmount2": 31084
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const AlphabetRegex = new RegExp(/^[a-zA-Z]+$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                bookOwnerGender: "Shri",
                bookOwnerName: "Ramanlal",
                writtenDifference: 576,
                date: "2014-12-31",
                month: "January",
                year: 2015,
                profitDiscloseAmount: 31440,
                questionPallete: [
                    {//1
                        questionType: "debitSide",
                        context: {
                            type: "receivedAndPosted",
                            name: "Ashok",
                            amtReceived: 2596,
                            amtPosted: 180
                        }
                    },
                    {//2
                        questionType: "debitSide",
                        context: {
                            type: "paidAndPosted",
                            name: "Repairing expenses",
                            amtPaid: 1424,
                            nameTo: "Machinery",
                            amtTo: 624
                        }
                    },
                    {//3
                        questionType: "discount",
                        context: {
                            type: "discountCreditedReceived",
                            name: "Piyush",
                            amount: 100
                        }
                    },
                    {//4
                        questionType: "debitSide",
                        context: {
                            type: "paidAndDebited",
                            name: "Narendra",
                            amtPaid: 1828,
                            nameTo: "Mahendra",
                            amtTo: 1028
                        }
                    },
                    {//5
                        questionType: "undercast",
                        context: {
                            type: "undercast",
                            amount: 400
                        }
                    },
                    {//6
                        questionType: "purchase",
                        context: {
                            type: "fixedAsset",
                            amount: 1468,
                            assetName: "Furniture",
                        },
                    },
                    // {//1
                    //     questionType: "purchase",
                    //     context: {
                    //         type: "tradeDiscount",
                    //         name: "Dhruvil",
                    //         amount: 10000,
                    //         discount: 10,
                    //         billno: 110,
                    //         paid: {
                    //             type: false,
                    //         }
                    //     },
                    //     date: 1
                    // },
                    //#region
                    // {//2
                    //     questionType: "purchase",
                    //     context: {
                    //         type: "cashDiscount",
                    //         name: "Komal",
                    //         amount: 4000,
                    //         discount: 10,
                    //         credit: 2,
                    //         paid: {
                    //             type: false
                    //         }
                    //     },
                    //     date: 3
                    // },
                    // {//3
                    //     questionType: "sales",
                    //     context: {
                    //         type: "sales",
                    //         name: "Sajan",
                    //         amount: 8000,
                    //         tradeDiscount: 5,
                    //         cashDiscount: 2,
                    //         order: false,
                    //         purchasedFrom: "-",
                    //         invoiceNo: 90,
                    //         credit: "-",
                    //         extraSum: "-",
                    //         extraSumReason: "-"
                    //     },
                    //     date: 7
                    // },
                    // {//4
                    //     questionType: "purchase",
                    //     context: {
                    //         type: "memo",
                    //         amount: 4000,
                    //         no: 30
                    //     },
                    //     date: 10
                    // },
                    // {//5
                    //     questionType: "salesReturns",
                    //     context: {
                    //         type: "salesReturns",
                    //         name: "Sajan",
                    //         amount: 2000,
                    //         creditNoteNo: 15,
                    //         rebate: false
                    //     },
                    //     date: 11
                    // },
                    // {//6
                    //     questionType: "purchaseReturns",
                    //     context: {
                    //         type: "purchaseReturns",
                    //         name: "Dhruvil",
                    //         percentageReturn: 10,
                    //         debitNoteNo: 16,
                    //         saleReturn: {
                    //             istrue: false
                    //         }
                    //     },
                    //     date: 15
                    // },
                    // {//7
                    //     questionType: "purchase",
                    //     context: {
                    //         type: "fixedAsset",
                    //         name: "Shree Bahuchar Furniture Mart",
                    //         amount: 5000,
                    //         billno: "-",
                    //         assetName: "furniture",
                    //         paid: {
                    //             type: false
                    //         }
                    //     },
                    //     date: 16
                    // },
                    // {//8
                    //     questionType: "purchase",
                    //     context: {
                    //         type: "nodiscount",
                    //         name: "Nirmi",
                    //         amount: 30000,
                    //         billno: "-",
                    //         paid: {
                    //             type: true,
                    //             amount: 15000
                    //         }
                    //     },
                    //     date: 18
                    // },
                    // {//9
                    //     questionType: "sales",
                    //     context: {
                    //         type: "sales",
                    //         name: "Haily",
                    //         amount: 36000,
                    //         tradeDiscount: 10,
                    //         cashDiscount: "-",
                    //         order: false,
                    //         purchasedFrom: "Nirmi",
                    //         invoiceNo: 100,
                    //         credit: 3,
                    //         extraSum: "-",
                    //         extraSumReason: "-"
                    //     },
                    //     date: 19
                    // },
                    // {//10
                    //     questionType: "purchaseReturns",
                    //     context: {
                    //         type: "purchaseReturns",
                    //         name: "Nirmi",
                    //         percentageReturn: 50,
                    //         debitNoteNo: "-",
                    //         saleReturn: {
                    //             istrue: true,
                    //             returneeName: "Haily"
                    //         }
                    //     },
                    //     date: 20
                    // },
                    // {//11
                    //     questionType: "order",
                    //     context: {
                    //         type: "order",
                    //         name: "Kanu"
                    //     },
                    //     date: 21
                    // },
                    // {//12
                    //     questionType: "sales",
                    //     context: {
                    //         type: "sales",
                    //         name: "Kanu",
                    //         amount: 12000,
                    //         order: true,
                    //         tradeDiscount: 10,
                    //         cashDiscount: "-",
                    //         purchasedFrom: "-",
                    //         invoiceNo: 102,
                    //         credit: "-",
                    //         extraSum: 300,
                    //         extraSumReason: "labour"
                    //     },
                    //     date: 24
                    // },
                    // {//13
                    //     questionType: "cashPurchaseSales",
                    //     context: {
                    //         type: "cashPurchaseSales",
                    //         cashPurchase: 20000,
                    //         cashSales: 25000
                    //     },
                    //     date: 25
                    // },
                    // {//14
                    //     questionType: "salesReturns",
                    //     context: {
                    //         type: "salesReturns",
                    //         name: "Kanu",
                    //         amount: 5400,
                    //         creditNoteNo: "-",
                    //         rebate: true
                    //     },
                    //     date: 31
                    // }
                    //#endregion
                ],

                //#region Temporary variables
                addQuestionType: "none",
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempAmountPosted: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempRebate: false,


                tempAmountPaidImmediately: '',
                //#endregion Temporary variables

                editModequestionPalette: [
                    {//1
                        name: false,
                        amtReceived: false,
                        amtPosted: false
                    },
                    {//2
                        name: false,
                        amtPaid: false,
                        nameTo: false,
                        amtTo: false
                    },
                    {//3
                        name: false,
                        amount: false
                    },
                    {//4
                        name: false,
                        amtPaid: false,
                        nameTo: false,
                        amtTo: false
                    },
                    {//5
                        amount: false
                    },
                    {//6
                        amount: false,
                        assetName: false
                    }
                    //#region
                    // {//1
                    //     name: false,
                    //     amount: false,
                    //     discount: false,
                    //     billno: false
                    // },
                    // {//2
                    //     name: false,
                    //     amount: false,
                    //     discount: false,
                    //     credit: false
                    // },
                    // {//3
                    //     name: false,
                    //     amount: false,
                    //     tradeDiscount: false,
                    //     cashDiscount: false,
                    //     invoiceNo: false,
                    //     credit: false,
                    // },
                    // {//4
                    //     amount: false
                    // },
                    // {//5
                    //     amount: false,
                    //     creditNoteNo: false,
                    // },
                    // {//6
                    //     percentageReturn: false,
                    //     debitNoteNo: false,
                    // },
                    // {//7
                    //     name: false,
                    //     amount: false,
                    //     billno: false,
                    //     assetName: false,

                    // },
                    // {//8
                    //     name: false,
                    //     amount: false,
                    //     billno: false
                    // },
                    // {//9
                    //     name: false,
                    //     amount: false,
                    //     tradeDiscount: false,
                    //     invoiceNo: false,
                    //     credit: false,
                    // },
                    // {//10
                    //     percentageReturn: false,
                    // },
                    // {//11

                    // },
                    // {//12
                    //     name: false,
                    //     amount: false,
                    //     tradeDiscount: false,
                    //     invoiceNo: false,
                    // },
                    // {//13
                    //     cashPurchase: false,
                    //     cashSales: false
                    // },
                    // {//14
                    //     amount: false
                    // }
                    //#endregion
                ],


                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModewrittenDifference: false,
                editModedate: false,
                editModeprofitDiscloseAmount: false,

                addQuestionChecker: false,


                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
            this.nameSetter = this.nameSetter.bind(this)
            this.removeQuestion = this.removeQuestion.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (event.target.value !== '-') {
                if (customArray.length === 3) {
                    switch (customArray[0]) {
                        case 'fixedAsset':
                            switch (customArray[1]) {
                                case 'assetName':
                                    this.state.questionPallete[customArray[2]].context.assetName = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'billno':
                                    this.state.questionPallete[customArray[2]].context.billno = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'receivedAndPosted':
                            switch (customArray[1]) {
                                case 'amtReceived':
                                    this.state.questionPallete[customArray[2]].context.amtReceived = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'amtPosted':
                                    this.state.questionPallete[customArray[2]].context.amtPosted = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'paidAndPosted':
                            switch (customArray[1]) {
                                case 'amtPaid':
                                    this.state.questionPallete[customArray[2]].context.amtPaid = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'nameTo':
                                    this.state.questionPallete[customArray[2]].context.nameTo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'amtTo':
                                    this.state.questionPallete[customArray[2]].context.amtTo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'discountCreditedReceived':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'paidAndDebited':
                            switch (customArray[1]) {
                                case 'amtPaid':
                                    this.state.questionPallete[customArray[2]].context.amtPaid = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'nameTo':
                                    this.state.questionPallete[customArray[2]].context.nameTo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'amtTo':
                                    this.state.questionPallete[customArray[2]].context.amtTo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'undercast':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                    }
                }

                switch (event.target.name) {
                    case 'bookOwnerName':
                        this.setState({
                            bookOwnerName: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'date':
                        this.setState({
                            date: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'writtenDifference':
                        this.setState({
                            writtenDifference: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'profitDiscloseAmount':
                        this.setState({
                            profitDiscloseAmount: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                }
            }
        }

        nameSetter(name, newName, type) {
            if (type === 'purchase') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "purchaseReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            if (type === 'sales') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "order" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }

            if (type === 'preOrder') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "sales" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            this.setState({})
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'date':
                    this.setState({
                        tempDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'name':
                    this.setState({
                        tempName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amount':
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPosted':
                    this.setState({
                        tempAmountPosted: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'discount':
                    this.setState({
                        tempDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Tdiscount':
                    this.setState({
                        tempTDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Cdiscount':
                    this.setState({
                        tempCDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'purchasedFrom':
                    this.setState({
                        tempPurchasedFrom: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'billno':
                    this.setState({
                        tempBillno: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'credit':
                    this.setState({
                        tempCredit: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPercentage':
                    this.setState({
                        tempAmountPercentage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'invoiceNo':
                    this.setState({
                        tempInvoiceNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'debitNoteNo':
                    this.setState({
                        tempDebitNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'creditNoteNo':
                    this.setState({
                        tempCreditNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPaidImmediately':
                    this.setState({
                        tempAmountPaidImmediately: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'memo':
                    this.setState({
                        tempMemo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'assetName':
                    this.setState({
                        tempAssetName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmount':
                    this.setState({
                        tempExtraSum: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmountReason':
                    this.setState({
                        tempExtraSumReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashPurchase':
                    this.setState({
                        tempCashPurchase: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashSales':
                    this.setState({
                        tempCashSales: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            switch (this.state.addQuestionType) {
                case 'receivedAndPosted':
                    if (this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempAmountPosted !== '' && NumberRegex.test(this.state.tempAmountPosted)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'paidAndPosted':
                    if (this.state.tempName !== '' &&
                        this.state.tempAssetName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempAmountPosted !== '' && NumberRegex.test(this.state.tempAmountPosted)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'discountCreditedReceived':
                    if (this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'paidAndDebited':
                    if (this.state.tempName !== '' &&
                        this.state.tempAssetName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempAmountPosted !== '' && NumberRegex.test(this.state.tempAmountPosted)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'undercast':
                    if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'fixedAsset':
                    if (this.state.tempAssetName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
            }
        }

        genderChange(event) {
            this.setState({
                bookOwnerGender: event.target.value
            })
        }

        monthChange(event) {
            this.setState({
                month: event.target.value
            })
        }

        questionTypeChange(event) {
            this.setState({
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempAmountPosted: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                addQuestionChecker: false,
                addQuestionType: event.target.value
            })
        }

        addNameChange(event) {
            this.setState({
                tempName: event.target.value
            })
        }

        addReturneeNameChange(event) {
            this.setState({
                tempReturneeName: event.target.value
            })
        }

        purchaseFromNameChange(event) {
            this.setState({
                tempPurchasedFrom: event.target.value
            })
        }

        rebateChange(event) {
            this.setState({
                tempRebate: event.target.value
            })
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.questionPallete) {

                switch (i.context.type) {
                    case 'receivedAndPosted':
                        if (i.context.name === '' ||
                            i.context.amtReceived === '' || !NumberRegex.test(i.context.amtReceived) ||
                            i.context.amtPosted === '' || !NumberRegex.test(i.context.amtPosted)) {
                            flag = false
                        }
                        break;
                    case 'paidAndPosted':
                        if (i.context.name === '' ||
                            i.context.nameTo === '' ||
                            i.context.amtPaid === '' || !NumberRegex.test(i.context.amtPaid) ||
                            i.context.amtTo === '' || !NumberRegex.test(i.context.amtTo)) {
                            flag = false
                        }
                        break;
                    case 'discountCreditedReceived':
                        if (i.context.name === '' ||
                            i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'paidAndDebited':
                        if (i.context.name === '' ||
                            i.context.nameTo === '' ||
                            i.context.amtPaid === '' || !NumberRegex.test(i.context.amtPaid) ||
                            i.context.amtTo === '' || !NumberRegex.test(i.context.amtTo)) {
                            flag = false
                        }
                        break;
                    case 'undercast':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'fixedAsset':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.assetName === '') {
                            flag = false
                        }
                        break;
                }
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: false,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: false,
                    submitChecker: false
                })
            }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            // console.log(JSON.stringify(this.state.questionPallete,null,4))
            let totalRectificationAmount = 0

            let rightSideArray1 = []
            let leftSideArray1 = []

            rightSideArray1.push({
                date: "",
                name: "Balance",
                amount: this.state.writtenDifference,
            })

            let Array2 = []

            let arrayDateCount = 1

            for (let i of this.state.questionPallete) {
                switch (i.context.type) {
                    case 'receivedAndPosted':
                        i.context.suspense = parseFloat(i.context.amtReceived) + parseFloat(i.context.amtPosted)
                        totalRectificationAmount += parseFloat(i.context.amtReceived) + parseFloat(i.context.amtPosted)

                        leftSideArray1.push({
                            date: arrayDateCount,
                            name: i.context.name,
                            amount: parseFloat(i.context.amtReceived) + parseFloat(i.context.amtPosted)
                        })

                        Array2.push({
                            type: i.context.type,
                            right: "-",
                            left: "-"
                        })
                        break;
                    case 'paidAndPosted':
                        i.context.suspense = parseFloat(i.context.amtPaid) - parseFloat(i.context.amtTo)
                        totalRectificationAmount += parseFloat(i.context.amtPaid)

                        rightSideArray1.push({
                            date: arrayDateCount,
                            name: i.context.name,
                            amount: parseFloat(i.context.amtPaid) - parseFloat(i.context.amtTo)
                        })

                        Array2.push({
                            type: i.context.type,
                            cause: i.context.name,
                            right: parseFloat(i.context.amtPaid),
                            left: "-"
                        })
                        break;
                    case 'discountCreditedReceived':
                        i.context.suspense = parseFloat(i.context.amount) * 2
                        totalRectificationAmount += parseFloat(i.context.amount) * 2

                        rightSideArray1.push({
                            date: arrayDateCount,
                            name: i.context.name,
                            amount: parseFloat(i.context.amount) * 2
                        })

                        Array2.push({
                            type: i.context.type,
                            right: "-",
                            left: "-"
                        })
                        break;
                    case 'paidAndDebited':
                        i.context.suspense = parseFloat(i.context.amtPaid) - parseFloat(i.context.amtTo)
                        totalRectificationAmount += parseFloat(i.context.amtPaid)

                        rightSideArray1.push({
                            date: arrayDateCount,
                            name: i.context.name,
                            amount: parseFloat(i.context.amtPaid) - parseFloat(i.context.amtTo)
                        })

                        Array2.push({
                            type: i.context.type,
                            right: "-",
                            left: "-"
                        })
                        break;
                    case 'undercast':
                        i.context.suspense = parseFloat(i.context.amount)
                        totalRectificationAmount += parseFloat(i.context.amount)

                        rightSideArray1.push({
                            date: arrayDateCount,
                            name: "Purchase",
                            amount: parseFloat(i.context.amount)
                        })

                        Array2.push({
                            type: i.context.type,
                            right: parseFloat(i.context.amount),
                            left: "-"
                        })
                        break;
                    case 'fixedAsset':
                        totalRectificationAmount += parseFloat(i.context.amount)

                        Array2.push({
                            type: i.context.type,
                            right: "-",
                            left: parseFloat(i.context.amount)
                        })
                        break;
                }
                arrayDateCount++
            }

            let Array2RightTotal = 0
            let Array2LeftTotal = 0

            for (let i of Array2) {
                if (i.right !== '-') {
                    Array2RightTotal += i.right
                }
                if (i.left !== '-') {
                    Array2LeftTotal += i.left
                }
            }

            // console.log(JSON.stringify(this.state.questionPallete, null, 4))

            // let dataObject = JSON.parse(JSON.stringify(this.state.questionPallete))

            let dataObject = {
                questionPallete: this.state.questionPallete,
                totalRectificationAmount,
                bookOwnerGender: this.state.bookOwnerGender,
                bookOwnerName: this.state.bookOwnerName,
                date: this.state.date,
                rightSideArray1,
                leftSideArray1,
                Array2,
                Array2LeftTotal,
                Array2RightTotal,
                profitDiscloseAmount: this.state.profitDiscloseAmount,
                profitDiscloseAmount1: parseFloat(this.state.profitDiscloseAmount) + Array2LeftTotal,
                profitDiscloseAmount2: (parseFloat(this.state.profitDiscloseAmount) + Array2LeftTotal) - Array2RightTotal,
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.clear()
            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addQuestion() {
            let adderFlag = true

            let temporaryTemplate = {
                questionType: this.state.addQuestionType,
                date: parseFloat(this.state.tempDate),
                assetName: this.state.tempAssetName,
                name: this.state.tempName,
                amount: this.state.tempAmount,
                amountPosted: this.state.tempAmountPosted,
                amountPercentage: this.state.tempAmountPercentage,
                discount: this.state.tempDiscount,
                tradeDiscount: this.state.tempTDiscount,
                cashDiscount: this.state.tempCDiscount,
                purchasedFrom: this.state.tempPurchasedFrom,
                billno: this.state.tempBillno,
                invoiceNo: this.state.tempInvoiceNo,
                debitNoteNo: this.state.tempDebitNoteNo,
                creditNoteNo: this.state.tempCreditNoteNo,
                credit: this.state.tempCredit,
                memo: this.state.tempMemo,
                extraSum: this.state.tempExtraSum,
                extraSumReason: this.state.tempExtraSumReason,
                cashPurchase: this.state.tempCashPurchase,
                cashSales: this.state.tempCashSales,
                rebate: this.state.tempRebate,
                returneeName: this.state.tempReturneeName,

                amountPaidImmediately: this.state.tempAmountPaidImmediately
            }
            let questionObject
            let editModequestionObject

            switch (temporaryTemplate.questionType) {
                case 'receivedAndPosted':
                    questionObject = {
                        questionType: "debitSide",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amtReceived: temporaryTemplate.amount,
                            amtPosted: temporaryTemplate.amountPosted,
                        },
                    }

                    editModequestionObject = {
                        name: false,
                        amtReceived: false,
                        amtPosted: false
                    }
                    break;
                case 'paidAndPosted':
                    questionObject = {
                        questionType: "debitSide",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amtPaid: temporaryTemplate.amount,
                            nameTo: temporaryTemplate.assetName,
                            amtTo: temporaryTemplate.amountPosted,
                        },
                    }

                    editModequestionObject = {
                        name: false,
                        amtPaid: false,
                        nameTo: false,
                        amtTo: false
                    }
                    break;
                case 'discountCreditedReceived':
                    questionObject = {
                        questionType: "discount",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                        },
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                    }
                    break;
                case 'paidAndDebited':
                    questionObject = {
                        questionType: "debitSide",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amtPaid: temporaryTemplate.amount,
                            nameTo: temporaryTemplate.assetName,
                            amtTo: temporaryTemplate.amountPosted,
                        },
                    }

                    editModequestionObject = {
                        name: false,
                        amtPaid: false,
                        nameTo: false,
                        amtTo: false
                    }
                    break;
                case 'undercast':
                    questionObject = {
                        questionType: "undercast",
                        context: {
                            type: temporaryTemplate.questionType,
                            amount: temporaryTemplate.amount,
                        },
                    }

                    editModequestionObject = {
                        amount: false,
                    }
                    break;
                case 'fixedAsset':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            amount: temporaryTemplate.amount,
                            assetName: temporaryTemplate.assetName,
                        },
                    }

                    editModequestionObject = {
                        amount: false,
                        assetName: false
                    }
                    break;

            }

            this.state.questionPallete.push(questionObject)
            this.state.editModequestionPalette.push(editModequestionObject)


            this.setState({})

            // console.log(JSON.stringify(questionObject,null,4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPalette.splice(event, 1)
            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 3) {
                switch (customArray[0]) {
                    case 'fixedAsset':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'assetName':
                                this.state.editModequestionPalette[customArray[2]].assetName = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'billno':
                                this.state.editModequestionPalette[customArray[2]].billno = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'receivedAndPosted':
                        switch (customArray[1]) {
                            case 'amtReceived':
                                this.state.editModequestionPalette[customArray[2]].amtReceived = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'amtPosted':
                                this.state.editModequestionPalette[customArray[2]].amtPosted = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'paidAndPosted':
                        switch (customArray[1]) {
                            case 'amtPaid':
                                this.state.editModequestionPalette[customArray[2]].amtPaid = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'nameTo':
                                this.state.editModequestionPalette[customArray[2]].nameTo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'amtTo':
                                this.state.editModequestionPalette[customArray[2]].amtTo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'discountCreditedReceived':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'paidAndDebited':
                        switch (customArray[1]) {
                            case 'amtPaid':
                                this.state.editModequestionPalette[customArray[2]].amtPaid = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'nameTo':
                                this.state.editModequestionPalette[customArray[2]].nameTo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'amtTo':
                                this.state.editModequestionPalette[customArray[2]].amtTo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'undercast':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                }
            }


            switch (event.target.name) {
                case 'bookOwnerGender':
                    this.setState({
                        editModebookOwnerGender: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'bookOwnerName':
                    this.setState({
                        editModebookOwnerName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'date':
                    this.setState({
                        editModedate: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'writtenDifference':
                    this.setState({
                        editModewrittenDifference: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'profitDiscloseAmount':
                    this.setState({
                        editModeprofitDiscloseAmount: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {

            for (let i of this.state.editModequestionPalette) {
                if (i.name) {
                    i.name = false
                }
                if (i.amount) {
                    i.amount = false
                }
                if (i.amtReceived) {
                    i.amtReceived = false
                }
                if (i.amtPosted) {
                    i.amtPosted = false
                }
                if (i.amtPaid) {
                    i.amtPaid = false
                }
                if (i.nameTo) {
                    i.nameTo = false
                }
                if (i.amtTo) {
                    i.amtTo = false
                }
                if (i.assetName) {
                    i.assetName = false
                }
            }


            this.setState({
                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModedate: false,
                editModewrittenDifference: false,
                editModeprofitDiscloseAmount: false,

                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        As on&nbsp;
                        {this.state.editModedate ? <TextField type="date" style={{ 'margin-top': "-15px", }} name="bookOwnerName" id='outlined-base' error={this.state.date === ''}
                            value={this.state.date} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="date" onClick={this.buttonMaker.bind(this)}> {this.state.date}</a>}&nbsp;

                        trial balance of&nbsp;
                        {this.state.editModebookOwnerGender ?
                            <FormControl style={{ width: "10%" }}>
                                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                <Select
                                    labelId="gender"
                                    id="gender"
                                    value={this.state.bookOwnerGender}
                                    label="Option to Add"
                                    onChange={this.genderChange.bind(this)}
                                >
                                    <MenuItem value={"Shri"}>Shri</MenuItem>
                                    <MenuItem value={"Shrimati"}>Shrimati</MenuItem>
                                    <MenuItem value={"Kumar"}>Kumar</MenuItem>
                                    <MenuItem value={"Kumari"}>Kumari</MenuItem>
                                </Select>
                            </FormControl> :
                            <a className='txt' name="bookOwnerGender" onClick={this.buttonMaker.bind(this)}> {this.state.bookOwnerGender}</a>
                        }&nbsp;

                        {this.state.editModebookOwnerName ? <TextField style={{ 'margin-top': "-15px", }} name="bookOwnerName" id='outlined-base' error={this.state.bookOwnerName === ''}
                            value={this.state.bookOwnerName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="bookOwnerName" onClick={this.buttonMaker.bind(this)}> {this.state.bookOwnerName}</a>}&nbsp;

                        does not tally and therefore he has written a difference of ₹&nbsp;

                        {this.state.editModewrittenDifference ? <TextField style={{ 'margin-top': "-15px", }} name="writtenDifference" id='outlined-base' error={this.state.writtenDifference === '' || isNaN(this.state.writtenDifference)}
                            value={this.state.writtenDifference} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="writtenDifference" onClick={this.buttonMaker.bind(this)}> {this.state.writtenDifference}</a>}&nbsp;

                        on the credit side of suspense account and gets his trial balance tallied. Later on, after preparing final accounts the following errors are detected in the next year.

                        <br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)} >Edit Mode Off</ColorButton> : <span></span>}

                        <br /><br />
                        Date<br /><br />
                        <div label="totalQuestionsInArray">
                            {this.state.questionPallete.map((x, i) => (
                                <div>
                                    <span>({i + 1})</span>&emsp;&emsp;

                                    {x.context.type === 'fixedAsset' &&
                                        <span>
                                            Purchase of &nbsp;
                                            {this.state.editModequestionPalette[i].assetName ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "assetName", i]} id='outlined-base' error={x.context.assetName === ''}
                                                value={x.context.assetName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "assetName", i]} onClick={this.buttonMaker.bind(this)}> {x.context.assetName}</a>}&nbsp;

                                            of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            debited to purchase account.

                                        </span>
                                    }
                                    {x.context.type === 'receivedAndPosted' &&
                                        <span>
                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amtReceived ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["receivedAndPosted", "amtReceived", i]} id='outlined-base' error={x.context.amtReceived === '' || isNaN(x.context.amtReceived)}
                                                value={x.context.amtReceived} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["receivedAndPosted", "amtReceived", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amtReceived}</a>}&nbsp;

                                            received from&nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["receivedAndPosted", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["receivedAndPosted", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            is posted as ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amtPosted ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["receivedAndPosted", "amtPosted", i]} id='outlined-base' error={x.context.amtPosted === '' || isNaN(x.context.amtPosted)}
                                                value={x.context.amtPosted} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["receivedAndPosted", "amtPosted", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amtPosted}</a>}&nbsp;

                                        </span>
                                    }
                                    {x.context.type === 'paidAndPosted' &&
                                        <span>
                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amtPaid ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["paidAndPosted", "amtPaid", i]} id='outlined-base' error={x.context.amtPaid === '' || isNaN(x.context.amtPaid)}
                                                value={x.context.amtPaid} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidAndPosted", "amtPaid", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amtPaid}</a>}&nbsp;

                                            paid for&nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "30%" }} name={["paidAndPosted", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidAndPosted", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            were posted  on the debit side of &nbsp;
                                            {this.state.editModequestionPalette[i].nameTo ? <TextField style={{ marginTop: "-15px", width: "30%" }} name={["paidAndPosted", "nameTo", i]} id='outlined-base' error={x.context.nameTo === ''}
                                                value={x.context.nameTo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidAndPosted", "nameTo", i]} onClick={this.buttonMaker.bind(this)}> {x.context.nameTo}</a>}&nbsp;

                                            account as ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amtTo ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["paidAndPosted", "amtTo", i]} id='outlined-base' error={x.context.amtTo === '' || isNaN(x.context.amtTo)}
                                                value={x.context.amtTo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidAndPosted", "amtTo", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amtTo}</a>}&nbsp;

                                        </span>
                                    }
                                    {x.context.type === 'discountCreditedReceived' &&
                                        <span>
                                            Discount of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["discountCreditedReceived", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["discountCreditedReceived", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            given by&nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "30%" }} name={["discountCreditedReceived", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["discountCreditedReceived", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            is credited to his account as well as discount received account.

                                        </span>
                                    }
                                    {x.context.type === 'paidAndDebited' &&
                                        <span>
                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amtPaid ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["paidAndDebited", "amtPaid", i]} id='outlined-base' error={x.context.amtPaid === '' || isNaN(x.context.amtPaid)}
                                                value={x.context.amtPaid} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidAndDebited", "amtPaid", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amtPaid}</a>}&nbsp;

                                            paid to&nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "30%" }} name={["paidAndDebited", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidAndDebited", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            is debited to&nbsp;
                                            {this.state.editModequestionPalette[i].nameTo ? <TextField style={{ marginTop: "-15px", width: "30%" }} name={["paidAndDebited", "nameTo", i]} id='outlined-base' error={x.context.nameTo === ''}
                                                value={x.context.nameTo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidAndDebited", "nameTo", i]} onClick={this.buttonMaker.bind(this)}> {x.context.nameTo}</a>}'s&nbsp;

                                            account as ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amtTo ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["paidAndDebited", "amtTo", i]} id='outlined-base' error={x.context.amtTo === '' || isNaN(x.context.amtTo)}
                                                value={x.context.amtTo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidAndDebited", "amtTo", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amtTo}</a>}&nbsp;

                                        </span>
                                    }
                                    {x.context.type === 'undercast' &&
                                        <span>
                                            Total of purchase book is undercast by ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["undercast", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["undercast", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;
                                        </span>
                                    }

                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeQuestion.bind(this, i)} name="-">-</IconButton>

                                    <br /><br />
                                </div>
                            ))}
                        </div>
                        To rectify the above errors, pass necessary rectification entries and prepare suspense account.<br /><br />
                        At the end of year {this.state.date.slice(0, 4)}, if profit and loss A/c discloses a profit of ₹&nbsp;
                        {this.state.editModeprofitDiscloseAmount ? <TextField style={{ 'margin-top': "-15px", width: "15%" }} name="profitDiscloseAmount" id='outlined-base' error={this.state.profitDiscloseAmount === '' || isNaN(this.state.profitDiscloseAmount)}
                            value={this.state.profitDiscloseAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="profitDiscloseAmount" onClick={this.buttonMaker.bind(this)}> {this.state.profitDiscloseAmount}</a>}.&nbsp;

                        Find out correct profit after rectification of errors.<br /><br />

                        <div label="addQuestionInputs">
                            <b>ADD NEW QUESTION :</b><br /><br /><br />
                            <b>QUESTION TYPE: </b>
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">Question</InputLabel>
                                <Select
                                    labelId="question"
                                    id="question"
                                    value={this.state.addQuestionType}
                                    label="Question"
                                    onChange={this.questionTypeChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"none"}>None</MenuItem>
                                    <MenuItem value={"receivedAndPosted"}>Received & Posted</MenuItem>
                                    <MenuItem value={"paidAndPosted"}>Paid & Posted</MenuItem>
                                    <MenuItem value={"discountCreditedReceived"}>Discount - Credited/Received</MenuItem>
                                    <MenuItem value={"paidAndDebited"}>Paid & Debited</MenuItem>
                                    <MenuItem value={"undercast"}>Total of Purchase book Undercast</MenuItem>
                                    <MenuItem value={"fixedAsset"}>Asset Debit Purchase</MenuItem>

                                </Select>
                            </FormControl><br /><br /><br />

                            {this.state.addQuestionType === 'receivedAndPosted' &&
                                <span>
                                    Enter Amount Received From: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="Enter Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount Received: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount Received*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount Posted: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPosted" error={this.state.tempAmountPosted === '' || isNaN(this.state.tempAmountPosted)}
                                        placeholder="Enter Amount Posted*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'paidAndPosted' &&
                                <span>
                                    Enter Paid For Reason: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="Enter Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount Paid: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount Received*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Posted on Name: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="assetName" error={this.state.tempAssetName === ''}
                                        placeholder="Enter Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount Posted: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPosted" error={this.state.tempAmountPosted === '' || isNaN(this.state.tempAmountPosted)}
                                        placeholder="Enter Amount Posted*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'discountCreditedReceived' &&
                                <span>
                                    Enter Name: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="Enter Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount Received*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'paidAndDebited' &&
                                <span>
                                    Enter Paid To: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="Enter Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount Paid: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount Received*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Debited To: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="assetName" error={this.state.tempAssetName === ''}
                                        placeholder="Enter Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount Debited: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPosted" error={this.state.tempAmountPosted === '' || isNaN(this.state.tempAmountPosted)}
                                        placeholder="Enter Amount Debited*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'undercast' &&
                                <span>
                                    Enter Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'fixedAsset' &&
                                <span>
                                    Enter Asset Name: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="assetName" error={this.state.tempAssetName === ''}
                                        placeholder="Enter Asset Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }



                            {this.state.addQuestionType !== 'none' && <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%" }} onClick={this.addQuestion.bind(this)}>ADD NEW QUESTION</AddButton>}
                        </div>

                        <br /><br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)} >Edit Mode Off</ColorButton> : <span></span>}


                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right'>
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                        <br />
                        <br />

                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>Ans. :</b><br /><br />

                            <div align="center"><b>Rectification Entries of {data[chartState.selected].bookOwnerGender} {data[chartState.selected].bookOwnerName}</b></div>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Date</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Debit (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Credit (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].questionPallete.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{i + 1}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {x.context.type === 'receivedAndPosted' &&
                                                        <span>
                                                            Suspense A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                            &emsp;&emsp;{x.context.name}'s A/c<br />
                                                            [Being the rectificiation of ₹ {x.context.amtReceived} received from {x.context.name} posted on the debit side of his account by ₹ {x.context.amtPosted}.]
                                                        </span>
                                                    }
                                                    {x.context.type === 'paidAndPosted' &&
                                                        <span>
                                                            Repairing expense A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                            &emsp;&emsp;To {x.context.nameTo} A/c<br />
                                                            &emsp;&emsp;To Suspense A/c<br />
                                                            [Being the rectification of repairing expenses of ₹ {x.context.amtPaid} were debited to {x.context.nameTo} as ₹ {x.context.amtTo}.]
                                                        </span>
                                                    }
                                                    {x.context.type === 'discountCreditedReceived' &&
                                                        <span>
                                                            {x.context.name}'s A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                            &emsp;&emsp;To Suspense A/c<br />
                                                            [Being the rectification of discount ₹ {x.context.amount} given by {x.context.name} credited to his account.]
                                                        </span>
                                                    }
                                                    {x.context.type === 'paidAndDebited' &&
                                                        <span>
                                                            {x.context.name}'s A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                            &emsp;&emsp;To {x.context.nameTo}'s A/c<br />
                                                            &emsp;&emsp;To Suspense A/c<br />
                                                            [Being the rectification of ₹ {x.context.amtPaid} paid to {x.context.name} debited to {x.context.nameTo}'s A/c by ₹ {x.context.amtTo}]
                                                        </span>
                                                    }
                                                    {x.context.type === 'undercast' &&
                                                        <span>
                                                            Purchase A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                            &emsp;To Suspense A/c<br />
                                                            [Being the rectification of purchase book total ₹ {x.context.amount} undercast.]
                                                        </span>
                                                    }
                                                    {x.context.type === 'fixedAsset' &&
                                                        <span>
                                                            {x.context.assetName} A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                            &emsp;&emsp;To Purchase A/c<br />
                                                            [Being the rectification of purchase of furniture ₹ {x.context.amount} debited to purchase A/c.]
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                    {x.context.type === 'receivedAndPosted' &&
                                                        <span>
                                                            {x.context.suspense}
                                                        </span>
                                                    }
                                                    {x.context.type === 'paidAndPosted' &&
                                                        <span>
                                                            {x.context.amtPaid}
                                                        </span>
                                                    }
                                                    {x.context.type === 'discountCreditedReceived' &&
                                                        <span>
                                                            {x.context.suspense}
                                                        </span>
                                                    }
                                                    {x.context.type === 'paidAndDebited' &&
                                                        <span>
                                                            {x.context.amtPaid}
                                                        </span>
                                                    }
                                                    {x.context.type === 'undercast' &&
                                                        <span>
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'fixedAsset' &&
                                                        <span>
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                    {x.context.type === 'receivedAndPosted' &&
                                                        <span>
                                                            <br />
                                                            {x.context.suspense}
                                                        </span>
                                                    }
                                                    {x.context.type === 'paidAndPosted' &&
                                                        <span>
                                                            <br />
                                                            {x.context.amtTo}<br />
                                                            {x.context.suspense}
                                                        </span>
                                                    }
                                                    {x.context.type === 'discountCreditedReceived' &&
                                                        <span>
                                                            <br />
                                                            {x.context.suspense}
                                                        </span>
                                                    }
                                                    {x.context.type === 'paidAndDebited' &&
                                                        <span>
                                                            <br />
                                                            {x.context.amtTo}<br />
                                                            {x.context.suspense}
                                                        </span>
                                                    }
                                                    {x.context.type === 'undercast' &&
                                                        <span>
                                                            <br />
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'fixedAsset' &&
                                                        <span>
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>Total</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].totalRectificationAmount}</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].totalRectificationAmount}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align="center"><b>Suspense Account</b></div>
                            <span style={{ float: "left" }}>Dr</span><span style={{ float: "right" }}>Cr</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Date</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Date</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].rightSideArray1.length >= data[chartState.selected].leftSideArray1.length ?
                                            data[chartState.selected].rightSideArray1.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell align="center">
                                                        {data[chartState.selected].leftSideArray1[i] &&
                                                            <span>
                                                                {data[chartState.selected].leftSideArray1[i].date}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {data[chartState.selected].leftSideArray1[i] &&
                                                            <span>
                                                                {data[chartState.selected].leftSideArray1[i].name}'s A/c
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {data[chartState.selected].leftSideArray1[i] &&
                                                            <span>
                                                                {data[chartState.selected].leftSideArray1[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <span>
                                                            {data[chartState.selected].rightSideArray1[i].date}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        <span>
                                                            By {data[chartState.selected].rightSideArray1[i].name}'s A/c<br />
                                                            {i === 0 && "(difference)"}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                        <span>
                                                            {data[chartState.selected].rightSideArray1[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                </TableRow>
                                            )) :
                                            data[chartState.selected].leftSideArray1.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell align="center">
                                                        <span>
                                                            {data[chartState.selected].leftSideArray1[i].date}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        <span>
                                                            {data[chartState.selected].leftSideArray1[i].name}'s A/c
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <span>
                                                            {data[chartState.selected].leftSideArray1[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {data[chartState.selected].rightSideArray1[i] &&
                                                            <span>
                                                                {data[chartState.selected].rightSideArray1[i].date}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {data[chartState.selected].rightSideArray1[i] &&
                                                            <span>
                                                                By {data[chartState.selected].rightSideArray1[i].name}'s A/c<br />
                                                                {i === 0 && "(difference)"}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                        {data[chartState.selected].rightSideArray1[i] &&
                                                            <span>
                                                                {data[chartState.selected].rightSideArray1[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))
                                        }
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].leftSideArray1.reduce((a, b) => (a + parseFloat(b.amount)), 0)}</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].rightSideArray1.reduce((a, b) => (a + parseFloat(b.amount)), 0)}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            Because of rectification of these errors the effect on profit-loss can be found out as under:<br />
                            <div align="center"><b>Effect of Rectification of Error on Profit-Loss</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Note<br /> No.</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Increase in <br />(₹)</StyledTableCell>
                                            <StyledTableCell align="center">Decrease in <br />(₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].Array2.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center">({i + 1})</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {x.type === 'receivedAndPosted' && "Profit-Loss remains unaffected"}
                                                    {x.type === 'paidAndPosted' && "Profit decreases because " + x.cause + " A/c is debited"}
                                                    {x.type === 'discountCreditedReceived' && "Proft-Loss remains unaffected"}
                                                    {x.type === 'paidAndDebited' && "Proft-Loss remains unaffected"}
                                                    {x.type === 'undercast' && "Profit decreases because purchase A/c is debited"}
                                                    {x.type === 'fixedAsset' && "Profit increases because purchase A/c is credited"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{x.left}</StyledTableCell>
                                                <StyledTableCell align="center">{x.right}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell>Profit of the year {data[chartState.selected].date.slice(0, 4)} before rectification of error</StyledTableCell>
                                            <StyledTableCell>₹ <span style={{ float: "right" }}>{data[chartState.selected].profitDiscloseAmount}</span></StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell>+ Increase in profit due to rectification of error</StyledTableCell>
                                            <StyledTableCell>₹ <span style={{ float: "right" }}>{data[chartState.selected].Array2LeftTotal}</span></StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell>₹<b> <span style={{ float: "right" }}>{data[chartState.selected].profitDiscloseAmount1}</span></b></StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell>- Decrease in profit due to rectification of error</StyledTableCell>
                                            <StyledTableCell>₹ <span style={{ float: "right" }}>{data[chartState.selected].Array2RightTotal}</span></StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell><b>Rectified correct profit in the year {data[chartState.selected].date.slice(0, 4)}</b></StyledTableCell>
                                            <StyledTableCell>₹<b> <span style={{ float: "right" }}>{data[chartState.selected].profitDiscloseAmount2}</span></b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

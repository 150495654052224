import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";


export default function ComparativeProfitLossStatement() {
    const data = [{
        "RMax": 265,
        "RMin": 80,
        "totalNumbers":28,
        "Range": 185,
        "numberOfClasses": 8,
        "classLength": 23.125,
        "preferredRange": 25,
        "classRanges": [
            {
                "start": 75,
                "end": 100,
                "count": 4,
                "tally": "||||"
            },
            {
                "start": 100,
                "end": 125,
                "count": 4,
                "tally": "||||"
            },
            {
                "start": 125,
                "end": 150,
                "count": 8,
                "tally": "|||| ||||"
            },
            {
                "start": 150,
                "end": 175,
                "count": 2,
                "tally": "||"
            },
            {
                "start": 175,
                "end": 200,
                "count": 2,
                "tally": "||"
            },
            {
                "start": 200,
                "end": 225,
                "count": 4,
                "tally": "||||"
            },
            {
                "start": 225,
                "end": 250,
                "count": 3,
                "tally": "|||"
            },
            {
                "start": 250,
                "end": 275,
                "count": 1,
                "tally": "|"
            }
        ]
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                sampleString: "228 125 100 90 115 125 230 220 130 80 95 160 180 200 200 128 120 85 185 140 265 230 135 127 100 145 150 210",
                sampleStringArray: [228, 125, 100, 90, 115, 125, 230, 220, 130, 80, 95, 160, 180, 200, 200, 128, 120, 85, 185, 140, 265, 230, 135, 127, 100, 145, 150, 210],
                sampleStringLength: 28,
                numberOfClass: 8,

                editModetotalSets: false,
                editModesampleString: false,
                editModenumberOfClass: false,
                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.stringReducer = this.stringReducer.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            switch (event.target.name) {
                case 'sampleString':
                    this.setState({
                        sampleString: this.stringReducer(event.target.value)
                    }, () => { this.fillDetails() })
                    break;
                case 'numberOfClass':
                    this.setState({
                        numberOfClass: event.target.value
                    }, () => { this.fillDetails() })
                    break;

            }
        }

        stringReducer(string) {
            let stringArray = string.split(" ")

            for (let i in stringArray) {
                if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === '') {
                    // console.log(i)
                    stringArray.splice(i, 1)
                }
            }

            if(stringArray[0]===null){
                stringArray[0]= 0
            }

            this.setState({
                sampleStringLength: stringArray.length,
                sampleStringArray: stringArray
            })

            return string
        }

        fillDetails() {
            let flag = true

            if(this.state.sampleString === ''){
                flag = false
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        autoSave(event) {
            // if (this.state.totalPurchase !== '' && this.state.cashPurchase !== '' && this.state.purchaseReturn !== '' && this.state.openingTradePayables !== '' && this.state.closingTradePayables !== '') {
            if (event.key === 'Enter') {
                this.editModeOff()
            }
            // }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region RANGE MAKER LITE START xd

        classStartRangeEnd(start, range, end) {
            // console.log(start,range,end)
            let tempArray = []
            tempArray.push(start - start % range)

            for (let i = 0; i < (parseInt(this.state.numberOfClass)) - 1; i++) {
                tempArray.push(tempArray[i] + range)
            }

            if (tempArray[tempArray.length - 1] + 25 < end) {
                return this.classStartRangeEnd(start, range + 5, end)
            } else {
                return {
                    rangeArray: tempArray,
                    range
                }
            }
        }

        //#endregion RANGE MAKER LITE END xd

        //#region BUTTON PANEL START

        onSubmit() {

            let RMax = 0
            let RMin = 1

            for (let i in this.state.sampleStringArray) {
                if (parseInt(i) === 0) {
                    RMin = parseFloat(this.state.sampleStringArray[i])
                }
                if (parseFloat(this.state.sampleStringArray[i]) > RMax) {
                    RMax = parseFloat(this.state.sampleStringArray[i])
                }
                if (parseFloat(this.state.sampleStringArray[i]) < RMin) {
                    RMin = parseFloat(this.state.sampleStringArray[i])
                }
            }

            let classLength = Math.round((RMax - RMin) / parseFloat(this.state.numberOfClass) * 1000) / 1000
            let oneClassRange = 0
            let classRanges = []

            if (classLength % 5 >= 2.5) {
                oneClassRange = classLength + (5 - classLength % 5)
            }
            else {
                oneClassRange = classLength - classLength % 5
            }

            let sortedArray = this.state.sampleStringArray.sort((a, b) => { return a - b })

            let classStartRangeEnd = this.classStartRangeEnd(sortedArray[0], oneClassRange, sortedArray[sortedArray.length - 1])

            for (let i in classStartRangeEnd.rangeArray) {
                classRanges.push({
                    start: classStartRangeEnd.rangeArray[i],
                    end: classStartRangeEnd.rangeArray[i] + classStartRangeEnd.range,
                    count: 0
                })
            }

            for (let i of classRanges) {
                for (let j in sortedArray) {
                    if (sortedArray[j] >= i.start && sortedArray[j] < i.end) {
                        i.count++
                        i.tally= i.tally===undefined? '|': [i.count - 1]%5==0? i.tally + ' |': i.tally + '|'
                    }
                }
            }

            let dataObject = {
                RMax,
                RMin,
                totalNumbers:sortedArray.length,
                Range: RMax - RMin,
                numberOfClasses: this.state.numberOfClass,
                classLength,
                preferredRange: classStartRangeEnd.range,
                classRanges
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            switch (event.target.name) {
                case 'numberOfClass':
                    this.setState({
                        editModenumberOfClass: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'sampleString':
                    this.setState({
                        editModesampleString: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;

            }
        }

        editModeOff() {
            this.setState({
                sampleString: this.state.sampleStringArray.reduce((a, b) => { return a + " " + b }),

                submitChecker: true,
                editModenumberOfClass: false,
                editModesampleString: false,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                         એક સુપર મોલમાં {Math.floor(this.state.sampleStringArray.length / 7)} અઠવાડિયા {this.state.sampleStringArray.length % 7 !== 0 && this.state.sampleStringArray.length % 7 + " days"} દરમિયાન થયેલ વેચાણના આંકડા (હજાર રૂપિયામાં) નીચે મુજબ છે :
                        <br /><br />

                        {this.state.editModesampleString ? <TextField style={{ "width": "100%", "padding": "-10px" }} name='sampleString' id='outlined-base' error={this.state.sampleString === ''}
                            value={this.state.sampleString} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="sampleString" onClick={this.buttonMaker.bind(this)}> {this.state.sampleString}</a>} &nbsp;
                        <br/>
                        આ માહિતીને&nbsp;
                            {this.state.editModenumberOfClass ? <TextField style={{ "width": "2%", "padding": "-10px" }} name='numberOfClass' id='outlined-base' error={this.state.numberOfClass === '' || isNaN(this.state.numberOfClass)}
                                value={this.state.numberOfClass} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="numberOfClass" onClick={this.buttonMaker.bind(this)}> {this.state.numberOfClass}</a>} વર્ગોમાં વિભાજિત કરતા આવૃત્તિ-વિતરણની રચના કરો.<br /><br />
                        

                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            આપેલ માહિતીનો ચલ વેચાણ (હાર રૂપિયામાં) એ સતત ચલ તરીકે લઈશું. તેમજ વર્ગોની સંખ્યા <i>K</i> = {data[chartState.selected].numberOfClasses} આપેલ છે.<br /><br />
                            &emsp;માહિતીનો વિસ્તાર <i>R</i> = મહત્તમ કિંમત - લઘુતમ કિંમત<br />
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].RMax} - {data[chartState.selected].RMin}<br />
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].Range}<br /><br />
                            ∴ વર્ગની વર્ગલંબાઈ <i>C</i> = <sup>વિસ્તાર</sup>/<sub>(વર્ગોની સંખ્યા)</sub> = <sup><i>R</i></sup>/<sub><i>K</i></sub><br />
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;= <sup>{data[chartState.selected].Range}</sup>/<sub>{data[chartState.selected].numberOfClasses}</sub><br />
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].classLength}<br /><br />
                            &emsp;∴ અહીં ગણતરીની સરળતા માટે વર્ગની વર્ગલંબાઈ {data[chartState.selected].preferredRange}, i.e. <i>C</i> ≈ {data[chartState.selected].preferredRange} લેવી જોઈએ (<i>C K</i>≥ <i>R</i> )<br/>
                            &emsp;પ્રથમ વર્ગની નીચલી સીમા {data[chartState.selected].classRanges[0].start} અને ઉપલી સીમા {data[chartState.selected].classRanges[0].end}, લેતાં લઘુતમ અવલોકન {data[chartState.selected].RMin} ને સમાવે તેવો પ્રથમ વર્ગ&nbsp;
                            {data[chartState.selected].classRanges[0].start}-{data[chartState.selected].classRanges[0].end}. તે જ રીતે છેલ્લો વર્ગ {data[chartState.selected].classRanges[data[chartState.selected].classRanges.length - 1].start}-{data[chartState.selected].classRanges[data[chartState.selected].classRanges.length - 1].end} થાય, જે મહત્તમ અવલોકન  {data[chartState.selected].RMax}ને સમાવે છે.

                            <br /><br />
                            <div align='center'>સમયગાળા દરમિયાન 'વેચાણ (હજાર રૂપિયામાં)'નું વિશિષ્ટ સતત આવર્તન વિતરણ</div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700}} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{  fontSize:"20px" }}>વસ્તુનું વેચાણ<br />(હજાર રૂપિયા)<br />વર્ગો</StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize:"20px" }}>આવૃત્તિ ચિહ્ન</StyledTableCell>
                                            <StyledTableCell align="center" style={{  fontSize:"20px" }}>દિવસોની <br /> સંખ્યા આવૃત્તિ  (<i>f</i>)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].classRanges.map((x)=>(
                                            <TableRow>
                                                <StyledTableCell align="center">{x.start}-{x.end}</StyledTableCell>
                                                <StyledTableCell align="center">{x.tally}</StyledTableCell>
                                                <StyledTableCell align="center">{x.count}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center" style={{  fontSize:"20px" }}><b>કુલ</b></StyledTableCell>
                                                <StyledTableCell align="center"><b>{data[chartState.selected].totalNumbers}</b></StyledTableCell>
                                            </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

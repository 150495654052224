import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {

    const data = [{
        "RMax": 5,
        "RMin": 0,
        "UniqueArray": [
            {
                "number": 0,
                "total": 18,
                "tally": "||||| ||||| ||||| |||"
            },
            {
                "number": 1,
                "total": 12,
                "tally": "||||| ||||| ||"
            },
            {
                "number": 2,
                "total": 9,
                "tally": "||||| ||||"
            },
            {
                "number": 3,
                "total": 6,
                "tally": "||||| |"
            },
            {
                "number": 4,
                "total": 3,
                "tally": "|||"
            },
            {
                "number": 5,
                "total": 2,
                "tally": "||"
            }
        ],
        "sampleStringLength": 50
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                totalSets: 500,
                sampleString: "0 3 2 1 0 5 2 3 0 2 3 0 0 1 0 2 3 4 1 0 4 5 2 1 0 3 2 1 1 0 2 4 2 1 0 0 0 1 0 1 3 1 0 0 0 0 1 1 0 2",
                sampleStringArray: [0, 3, 2, 1, 0, 5, 2, 3, 0, 2, 3, 0, 0, 1, 0, 2, 3, 4, 1, 0, 4, 5, 2, 1, 0, 3, 2, 1, 1, 0, 2, 4, 2, 1, 0, 0, 0, 1, 0, 1, 3, 1, 0, 0, 0, 0, 1, 1, 0, 2],
                sampleStringLength: 50,

                editModetotalSets: false,
                editModesampleString: false,
                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.stringReducer = this.stringReducer.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            switch (event.target.name) {
                case 'totalSets':
                    this.setState({
                        totalSets: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'sampleString':
                    this.setState({
                        sampleString: this.stringReducer(event.target.value)
                    }, () => { this.fillDetails() })
                    break;

            }
        }

        stringReducer(string) {
            let stringArray = string.split(" ")

            for (let i in stringArray) {
                if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === '') {
                    // console.log(i)
                    stringArray.splice(i, 1)
                }
            }

            if(stringArray[0]===null){
                stringArray[0]= 0
            }

            this.setState({
                sampleStringLength: stringArray.length,
                sampleStringArray: stringArray
            })

            return string
        }

        fillDetails() {
            let flag = true

            if(!NormalNumberRegex.test(this.state.totalSets) || this.state.totalSets === ''){
                flag = false
            }

            if(this.state.sampleString === ''){
                flag = false
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        autoSave(event) {
            // if (this.state.totalPurchase !== '' && this.state.cashPurchase !== '' && this.state.purchaseReturn !== '' && this.state.openingTradePayables !== '' && this.state.closingTradePayables !== '') {
            if (event.key === 'Enter') {
                this.editModeOff()
            }
            // }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            let RMax = 0
            let RMin = 1
            let UniqueArray = []

            for (let i in this.state.sampleStringArray) {
                let numberFlag = true
                if (parseInt(i) === 0) {
                    RMin = parseFloat(this.state.sampleStringArray[i])
                    UniqueArray.push({ number: RMin, total: 1 })
                }
                if (parseFloat(this.state.sampleStringArray[i]) > RMax) {
                    RMax = parseFloat(this.state.sampleStringArray[i])
                }
                if (parseFloat(this.state.sampleStringArray[i]) < RMin) {
                    RMin = parseFloat(this.state.sampleStringArray[i])
                }
                for (let j in UniqueArray) {

                    if (UniqueArray[j].number === parseFloat(this.state.sampleStringArray[i])) {
                        UniqueArray[j].total++
                        numberFlag = false
                    }
                } if (numberFlag) {
                    UniqueArray.push({ number: parseFloat(this.state.sampleStringArray[i]), total: 1 })
                }
            }

            UniqueArray.sort((a, b) => {
                return a.number - b.number
            })

            for (let i in UniqueArray) {
                UniqueArray[i].tally = '|'
            }
            for (let i in UniqueArray) {
                for (let j = 2; j <= UniqueArray[i].total; j++) {
                    if (parseInt(j) % 5 !== 0) {
                        UniqueArray[i].tally += '|'
                    }
                    else {
                        UniqueArray[i].tally += '| '
                    }
                }
            }

            let dataObject = {
                RMax: RMax,
                RMin: RMin,
                UniqueArray: UniqueArray,
                sampleStringLength: this.state.sampleStringLength
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            switch (event.target.name) {
                case 'totalSets':
                    this.setState({
                        editModetotalSets: true,
                        editModeButton: true,
                        submitChecker:false
                    })
                    break;
                case 'sampleString':
                    this.setState({
                        editModesampleString: true,
                        editModeButton: true,
                        submitChecker:false
                    })

            }
        }

        editModeOff() {
            this.setState({
                sampleString: this.state.sampleStringArray.reduce((a, b) => { return a + " " + b }),

                submitChecker: true,
                editModetotalSets: false,
                editModesampleString: false,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >

                    ટેલિવિઝન સેટનું ઉત્પાદન કરતી એક ફેક્ટરીમાં અઠવાડિયા દરમિયાન,&nbsp;
                        {this.state.editModetotalSets ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='totalSets' id='outlined-base' error={this.state.totalSets === '' || isNaN(this.state.totalSets)}
                            value={this.state.totalSets} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="totalSets" onClick={this.buttonMaker.bind(this)}> {this.state.totalSets}</a>} &nbsp;
                        સેટનું ઉત્પાદન થયું હતું અને તેમાંથી  {this.state.sampleStringLength} ટેલિવિઝન સેટનો એક નિદર્શ લઈ દરેક ટેલિવિઝન સેટની તપાસ કરતાં નીચે પ્રમાણે ખામીઓની સંખ્યા મળે છે. આ માહિતી પરથી યોગ્ય આવૃત્તિ-વિતરણ તૈયાર કરો.<br />
                        <br /><br />

                        {this.state.editModesampleString ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='sampleString' id='outlined-base' error={this.state.sampleString === ''}
                            value={this.state.sampleString} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="sampleString" onClick={this.buttonMaker.bind(this)}> {this.state.sampleString}</a>} &nbsp;

                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {!this.state.solutionShow &&
                        <div label="Answer" align="left">
                            &emsp; અહીં'ટેલિવિઝન સેટમાં ખામીઓની સંખ્યા' એ અસતત ચલ છે તેમજ ખામીઓની મહત્તમ સંખ્યા {data[chartState.selected].RMax} અને લઘુત્તમ સંખ્યા  {data[chartState.selected].RMin}.&nbsp;
                            છે તેથી આપેલ અવર્ગીકૃત માહિતીનો વિસ્તાર <i>R</i> = {data[chartState.selected].RMax} - {data[chartState.selected].RMin} = {parseFloat(data[chartState.selected].RMax) - parseFloat(data[chartState.selected].RMin)} થાય.&nbsp;
                            તેથી ટેલિવિઝન સેટ દીઠ ખામીઓ દર્શાવતું અસતત આવૃત્તિ-વિતરણ નીચે મુજબ મેળવી શકાય.<br/><br/>
                             {data[chartState.selected].sampleStringLength} ટેલિવિઝન સેટના નિદર્શમાં ટેલિવિઝન સેટ દીઠ ખામીઓની સંખ્યા દર્શાવતું અસતત આવૃત્તિ-વિતરણ

                            <br /><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>ટેલિવિઝન સેટ દીઠ<br />ખામીઓની સંખ્યા<br />(ચલ <i>x</i>)</StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px"}}>આવૃત્તિ ચિહ્ન</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>ટેલિવિઝન સેટની <br /> સંખ્યા આવૃત્તિ<br />(<i>f</i>)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].UniqueArray.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align='center'>{x.number}</StyledTableCell>
                                                <StyledTableCell align='center'>{x.tally}</StyledTableCell>
                                                <StyledTableCell align='center'>{x.total}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center' style={{fontSize: "20px"}}><b>કુલ</b></StyledTableCell>
                                            <StyledTableCell align='center'><b>{data[chartState.selected].sampleStringLength}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";


export default function ComparativeProfitLossStatement() {

    const data = [{
        "subjectOne": "Economics",
        "subjectTwo": "Dancing skill",
        "combinedCountArrayOne": {
            "10": {
                "count": 1,
                "position": [
                    8
                ]
            },
            "20": {
                "count": 1,
                "position": [
                    7
                ]
            },
            "30": {
                "count": 3,
                "position": [
                    4,
                    5,
                    6
                ]
            },
            "40": {
                "count": 1,
                "position": [
                    3
                ]
            },
            "50": {
                "count": 1,
                "position": [
                    2
                ]
            },
            "60": {
                "count": 1,
                "position": [
                    1
                ]
            }
        },
        "combinedCountArrayTwo": {
            "12": {
                "count": 1,
                "position": [
                    8
                ]
            },
            "15": {
                "count": 1,
                "position": [
                    7
                ]
            },
            "20": {
                "count": 2,
                "position": [
                    5,
                    6
                ]
            },
            "28": {
                "count": 1,
                "position": [
                    4
                ]
            },
            "40": {
                "count": 1,
                "position": [
                    3
                ]
            },
            "60": {
                "count": 1,
                "position": [
                    2
                ]
            },
            "80": {
                "count": 1,
                "position": [
                    1
                ]
            }
        },
        "students": [
            {
                "marksOne": 60,
                "marksTwo": 80,
                "rankOne": 1,
                "rankTwo": 1,
                "d": 0,
                "d2": 0
            },
            {
                "marksOne": 30,
                "marksTwo": 20,
                "rankOne": 5,
                "rankTwo": 5.5,
                "d": -0.5,
                "d2": 0.25
            },
            {
                "marksOne": 10,
                "marksTwo": 60,
                "rankOne": 8,
                "rankTwo": 2,
                "d": 6,
                "d2": 36
            },
            {
                "marksOne": 20,
                "marksTwo": 40,
                "rankOne": 7,
                "rankTwo": 3,
                "d": 4,
                "d2": 16
            },
            {
                "marksOne": 30,
                "marksTwo": 12,
                "rankOne": 5,
                "rankTwo": 8,
                "d": -3,
                "d2": 9
            },
            {
                "marksOne": 50,
                "marksTwo": 28,
                "rankOne": 2,
                "rankTwo": 4,
                "d": -2,
                "d2": 4
            },
            {
                "marksOne": 30,
                "marksTwo": 20,
                "rankOne": 5,
                "rankTwo": 5.5,
                "d": -0.5,
                "d2": 0.25
            },
            {
                "marksOne": 40,
                "marksTwo": 15,
                "rankOne": 3,
                "rankTwo": 7,
                "d": -4,
                "d2": 16
            }
        ],
        "n": 8,
        "totalD": 0,
        "totalD2": 81.5,
        "totalCF": 2.5,
        "r": 0,
        "repeatedObservation": [
            {
                "number": "30",
                "count": 3,
                "CF": 2
            },
            {
                "number": "20",
                "count": 2,
                "CF": 0.5
            }
        ],
        "extraValues": {
            "d2CF": 84,
            "n2": 64,
            "nn2": 504,
            "sixd2cf": 504
        }
    }]

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                subjects: {
                    one: "Economics",
                    two: "Dancing skill"
                },
                students: [
                    {
                        marksOne: 60,
                        marksTwo: 80
                    }, {
                        marksOne: 30,
                        marksTwo: 20
                    }, {
                        marksOne: 10,
                        marksTwo: 60
                    }, {
                        marksOne: 20,
                        marksTwo: 40
                    }, {
                        marksOne: 30,
                        marksTwo: 12
                    }, {
                        marksOne: 50,
                        marksTwo: 28
                    }, {
                        marksOne: 30,
                        marksTwo: 20
                    }, {
                        marksOne: 40,
                        marksTwo: 15
                    }
                ],

                editModesubjects: {
                    one: false,
                    two: false
                },
                editModestudents: [
                    {
                        marksOne: false,
                        marksTwo: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }
                ],

                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'marksOne':
                        this.state.students[customArray[1]].marksOne = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'marksTwo':
                        this.state.students[customArray[1]].marksTwo = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'subjectOne':
                    this.state.subjects.one = event.target.value
                    this.setState({}, () => { this.fillDetails() })
                    break;
                case 'subjectTwo':
                    this.state.subjects.two = event.target.value
                    this.setState({}, () => { this.fillDetails() })
                    break;
            }
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.students) {
                if(i.marksOne === '' || NormalNumberRegex.test(i.marksOne)){
                    flag = false
                }
                if(i.marksTwo === '' || NormalNumberRegex.test(i.marksTwo)){
                    flag = false
                }
            }

            if(this.state.subjects.one === ''){
                flag = false
            }
            if(this.state.subjects.two === ''){
                flag = false
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //EVENT FUNCTIONALTIES END

        // BUTTON PANEL START

        onSubmit() {

            let arrayOne = []
            let arrayTwo = []

            for (let i of this.state.students) {
                arrayOne.push(parseFloat(i.marksOne))
                arrayTwo.push(parseFloat(i.marksTwo))
            }

            arrayOne = arrayOne.sort((a, b) => b - a)
            arrayTwo = arrayTwo.sort((a, b) => b - a)

            let combinedCountArrayOne = {}
            let combinedCountArrayTwo = {}

            for (let i in arrayOne) {
                if (combinedCountArrayOne[arrayOne[i]] === undefined) {
                    combinedCountArrayOne[arrayOne[i]] = {
                        count: 1,
                        position: [parseFloat(i) + 1]
                    }
                } else {
                    combinedCountArrayOne[arrayOne[i]].count++
                    combinedCountArrayOne[arrayOne[i]].position.push(parseFloat(i) + 1)
                }

                if (combinedCountArrayTwo[arrayTwo[i]] === undefined) {
                    combinedCountArrayTwo[arrayTwo[i]] = {
                        count: 1,
                        position: [parseFloat(i) + 1]
                    }
                } else {
                    combinedCountArrayTwo[arrayTwo[i]].count++
                    combinedCountArrayTwo[arrayTwo[i]].position.push(parseFloat(i) + 1)
                }
            }

            let totalD = 0
            let totalD2 = 0
            let totalCF = 0

            for (let i of this.state.students) {
                i.rankOne = combinedCountArrayOne[i.marksOne].position.reduce((a, b) => a + b) / combinedCountArrayOne[i.marksOne].count
                i.rankTwo = combinedCountArrayTwo[i.marksTwo].position.reduce((a, b) => a + b) / combinedCountArrayTwo[i.marksTwo].count
                i.d = i.rankOne - i.rankTwo
                i.d2 = i.d * i.d

                totalD += i.d
                totalD2 += i.d2
            }

            let repeatedObservation = []

            Object.keys(combinedCountArrayOne).forEach((x) => {
                if (combinedCountArrayOne[x].count > 1) {
                    repeatedObservation.push({
                        number: x,
                        count: combinedCountArrayOne[x].count
                    })
                }
            })
            Object.keys(combinedCountArrayTwo).forEach((x) => {
                if (combinedCountArrayTwo[x].count > 1) {
                    repeatedObservation.push({
                        number: x,
                        count: combinedCountArrayTwo[x].count
                    })
                }
            })

            for (let i of repeatedObservation) {
                i.CF = Math.round(((i.count * i.count * i.count - i.count) / 12) * 100) / 100
                totalCF += Math.round(((i.count * i.count * i.count - i.count) / 12) * 100) / 100
            }

            let r = 1 - (6 * (totalD2 + totalCF)) / (this.state.students.length * (this.state.students.length * this.state.students.length - 1))


            let dataObject = {
                subjectOne: this.state.subjects.one,
                subjectTwo: this.state.subjects.two,
                combinedCountArrayOne,
                combinedCountArrayTwo,
                students: this.state.students,
                n: this.state.students.length,
                totalD: totalD,
                totalD2: totalD2,
                totalCF: totalCF,
                r: r,
                repeatedObservation,
                extraValues: {
                    d2CF: totalD2 + totalCF,
                    n2: this.state.students.length * this.state.students.length,
                    nn2: this.state.students.length * (this.state.students.length * this.state.students.length - 1),
                    sixd2cf: 6 * (totalD2 + totalCF)
                }
            }

            // data.push(JSON.parse(JSON.stringify(dataObject)))

            // chartState.selected += 1

            this.setState({})

            console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addRows() {
            this.state.students.push({
                marksOne: 10,
                marksTwo: 50
            })
            this.state.editModestudents.push({
                marksOne: false,
                marksTwo: false
            })

            this.setState({
                editModeButton: true
            })
        }

        removeRows(event) {
            this.state.students.splice(event, 1)
            this.state.editModestudents.splice(event, 1)
            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //BUTTON PANEL END


        // EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'marksOne':
                        this.state.editModestudents[customArray[1]].marksOne = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'marksTwo':
                        this.state.editModestudents[customArray[1]].marksTwo = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'subjectOne':
                    this.state.editModesubjects.one = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'subjectTwo':
                    this.state.editModesubjects.two = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
            }
        }

        editModeOff() {
            for (let i of this.state.editModestudents) {
                i.marksOne = false
                i.marksTwo = false
            }

            this.state.editModesubjects.one = false
            this.state.editModesubjects.two = false

            this.setState({
                editModeButton: false,
            })
        }

        // EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        <b>To know the relation between understanding of students in the subject of Economics and their dancing skill, a sample of {this.state.students.length} students is taken and a test is conducted for them.&nbsp;
                            The marks obtained are given below, Find the rank correlation coefficient between the marks obtained in two subjects</b><br />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Marks in&nbsp;
                                            {
                                                this.state.editModesubjects.one ?
                                                    <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "20%" }} name="subjectOne" id='outlined-base' error={this.state.subjects.one === '' || isNaN(this.state.subjects.one)}
                                                        value={this.state.subjects.one} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name="subjectOne" onClick={this.buttonMaker.bind(this)}> {this.state.subjects.one}</a>
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell align="center">Marks in&nbsp;
                                            {
                                                this.state.editModesubjects.two ?
                                                    <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "20%" }} name="subjectTwo" id='outlined-base' error={this.state.subjects.two === '' || isNaN(this.state.subjects.two)}
                                                        value={this.state.subjects.two} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name="subjectTwo" onClick={this.buttonMaker.bind(this)}> {this.state.subjects.two}</a>
                                            }
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.students.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModestudents[i].marksOne ?
                                                        <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "20%" }} name={["marksOne", i]} id='outlined-base' error={x.marksOne === '' || isNaN(x.marksOne)}
                                                            value={x.marksOne} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["marksOne", i]} onClick={this.buttonMaker.bind(this)}> {x.marksOne}</a>
                                                }
                                                {this.state.students.length > 2 && <IconButton style={{ width: "3%", color: "red" }} onClick={this.removeRows.bind(this, i)} name="-">-</IconButton>}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModestudents[i].marksTwo ?
                                                        <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "20%" }} name={["marksTwo", i]} id='outlined-base' error={x.marksTwo === '' || isNaN(x.marksTwo)}
                                                            value={x.marksTwo} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["marksTwo", i]} onClick={this.buttonMaker.bind(this)}> {x.marksTwo}</a>
                                                }
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />
                        <AddButton style={{ width: "15%" }} onClick={this.addRows.bind(this)}>ADD ROWS</AddButton><br /><br />

                        <b>

                        </b>
                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>Ans.</b><br />


                            Now, let us prepare a table as follows.<br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Marks in <br /> {data[chartState.selected].subjectOne} <i>x</i></StyledTableCell>
                                            <StyledTableCell align="center">Rank of <i>X</i> <br /><i>R<sub>x</sub></i></StyledTableCell>
                                            <StyledTableCell align="center">Marks in <br />{data[chartState.selected].subjectTwo} <i>y</i></StyledTableCell>
                                            <StyledTableCell align="center">Rank of <i>Y</i><br /><i>R<sub>y</sub></i></StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top" }}><i>d = R<sub>x</sub> - R<sub>y</sub></i></StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top" }}><i>d<sup>2</sup></i></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].students.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center">{x.marksOne}</StyledTableCell>
                                                <StyledTableCell align="center">{x.marksTwo}</StyledTableCell>
                                                <StyledTableCell align="center">{x.rankOne}</StyledTableCell>
                                                <StyledTableCell align="center">{x.rankTwo}</StyledTableCell>
                                                <StyledTableCell align="center">{x.d}</StyledTableCell>
                                                <StyledTableCell align="center">{x.d2}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="center"><b>Total</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>-</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>-</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>-</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].totalD}</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].totalD2}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />


                            The calculation of Correction Factor (CF) is as follows.<br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Repeated <br />observation</StyledTableCell>
                                            <StyledTableCell align="center">No. of times the<br />observation is repeated (<i>m</i>)</StyledTableCell>
                                            <StyledTableCell align="center">(<sup><i>m<sup>3</sup>-m</i></sup>/<sub>12</sub>)</StyledTableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].repeatedObservation.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center">{x.number}</StyledTableCell>
                                                <StyledTableCell align="center">{x.count}</StyledTableCell>
                                                <StyledTableCell align="center">(<sup><i>{x.count}<sup>3</sup>-{x.count}</i></sup>/<sub>12</sub>) = {x.CF}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="center"><b>Total</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>-</b></StyledTableCell>

                                            <StyledTableCell align="center"><b>{data[chartState.selected].totalCF}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <i>r</i> = 1 - <sup>6[Σ<i>d</i><sup>2</sup> + <i>CF</i>]</sup>/<sub><i>n(n<sup>2</sup> - 1)</i></sub><br />
                            &ensp;&nbsp;= 1 = <sup>6[{data[chartState.selected].totalD2} + {data[chartState.selected].totalCF}]</sup>/<sub>{data[chartState.selected].n}({data[chartState.selected].extraValues.n2 - 1})</sub><br />
                            &ensp;&nbsp;= 1 - <sup>6({data[chartState.selected].extraValues.d2CF})</sup>/<sub>{data[chartState.selected].extraValues.nn2}</sub><br />
                            &ensp;&nbsp;= 1 - <sup>{data[chartState.selected].extraValues.sixd2cf}</sup>/<sub>{data[chartState.selected].extraValues.nn2}</sub><br />
                            &ensp;&nbsp;= 1 - {Math.round((data[chartState.selected].extraValues.sixd2cf / data[chartState.selected].extraValues.nn2) * 100) / 100}<br />
                            ∴ <i>r</i> = {data[chartState.selected].r}<br />

                            {data[chartState.selected].r === 0 &&
                                <span>
                                    &emsp;&emsp;Here, <i>r</i> = 0, it can be said that there is a lack of linear correlation between the ranks of the marks in both subjects&nbsp;
                                    i.e. the performances of the students of the given group in the subjects of {data[chartState.selected].subjectOne} and {data[chartState.selected].subjectTwo} are independent with reference to the linear relationship.
                                </span>
                            }
                            {data[chartState.selected].r > 0 && data[chartState.selected].r < 0.5 &&
                                <span>
                                    Here, <i>r</i> = {data[chartState.selected].r}, it can be said that there is a weak linear correlation between the ranks of the marks in both subjects&nbsp;
                                    i.e. the performances of the students of the given group in the subjects of {data[chartState.selected].subjectOne} and {data[chartState.selected].subjectTwo} are minorly dependent with reference to the linear relationship.
                                </span>
                            }
                            {data[chartState.selected].r >= 0.5 && data[chartState.selected].r < 1 &&
                                <span>
                                    Here, <i>r</i> = {data[chartState.selected].r}, it can be said that there is a strong of linear correlation between the ranks of the marks in both subjects&nbsp;
                                    i.e. the performances of the students of the given group in the subjects of {data[chartState.selected].subjectOne} and {data[chartState.selected].subjectTwo} are majorly dependent with reference to the linear relationship.
                                </span>
                            }
                            {data[chartState.selected].r === 1 &&
                                <span>
                                    Here, <i>r</i> = 1, it can be said that there is a Strong linear correlation between the ranks of the marks in both subjects&nbsp;
                                    i.e. the performances of the students of the given group in the subjects of {data[chartState.selected].subjectOne} and {data[chartState.selected].subjectTwo} are dependent with reference to the linear relationship.
                                </span>
                            }

                        </div>}
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

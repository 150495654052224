import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button, Switch, touchRippleClasses } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
//ILLUSTRATION 4

export default function ComparativeProfitLossStatement() {

    const data = [{
        "questionPallete": [
            {
                "questionType": "debenture",
                "context": {
                    "type": "debenturesPar",
                    "issuedAmount": 5000,
                    "debenturePercent": 10,
                    "debentureAmount": 100,
                    "newAmount": 500000
                }
            },
            {
                "questionType": "debenture",
                "context": {
                    "type": "debenturesDiscount",
                    "issuedAmount": 5000,
                    "debenturePercent": 10,
                    "debentureAmount": 100,
                    "percent": 5,
                    "amountCut": 95,
                    "amountRest": 5,
                    "newAmount": 475000,
                    "extraAmount": 25000
                }
            },
            {
                "questionType": "debenture",
                "context": {
                    "type": "debenturesPremium",
                    "issuedAmount": 5000,
                    "debenturePercent": 10,
                    "debentureAmount": 100,
                    "percent": 8,
                    "amountAdd": 108,
                    "amountRest": 8,
                    "newAmount": 540000,
                    "extraAmount": 40000
                }
            }
        ]
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const AlphabetRegex = new RegExp(/^[a-zA-Z]+$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                bookOwnerGender: "Shri",
                bookOwnerName: "Gokul Traders",
                month: "April",
                year: 2014,
                questionPallete: [
                    {//1
                        questionType: "debenture",
                        context: {
                            type: "debenturesPar",
                            issuedAmount: 5000,
                            debenturePercent: 10,
                            debentureAmount: 100
                        }
                    },
                    {//2
                        questionType: "debenture",
                        context: {
                            type: "debenturesDiscount",
                            issuedAmount: 5000,
                            debenturePercent: 10,
                            debentureAmount: 100,
                            percent: 5
                        }
                    },
                    {//3
                        questionType: "debenture",
                        context: {
                            type: "debenturesPremium",
                            issuedAmount: 5000,
                            debenturePercent: 10,
                            debentureAmount: 100,
                            percent: 8
                        }
                    }
                ],

                addQuestionType: "none",
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempReason: '',
                tempShares: '',
                tempBrokerage: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                editModequestionPalette: [
                    {//1
                        issuedAmount: false,
                        debenturePercent: false,
                        debentureAmount: false
                    },
                    {//2
                        issuedAmount: false,
                        debenturePercent: false,
                        debentureAmount: false,
                        percent: false
                    },
                    {//3
                        issuedAmount: false,
                        debenturePercent: false,
                        debentureAmount: false,
                        percent: false
                    }

                ],


                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModemonth: false,
                editModeyear: false,

                addQuestionChecker: false,


                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
            this.nameSetter = this.nameSetter.bind(this)
            this.removeQuestion = this.removeQuestion.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (event.target.value !== '-') {
                if (customArray.length === 3) {
                    switch (customArray[0]) {
                        case 'debenturesPar':
                            switch (customArray[1]) {
                                case 'issuedAmount':
                                    this.state.questionPallete[customArray[2]].context.issuedAmount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debenturePercent':
                                    this.state.questionPallete[customArray[2]].context.debenturePercent = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debentureAmount':
                                    this.state.questionPallete[customArray[2]].context.debenturePercent = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'debenturesDiscount':
                            switch (customArray[1]) {
                                case 'issuedAmount':
                                    this.state.questionPallete[customArray[2]].context.issuedAmount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debenturePercent':
                                    this.state.questionPallete[customArray[2]].context.debenturePercent = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debentureAmount':
                                    this.state.questionPallete[customArray[2]].context.debenturePercent = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'percent':
                                    this.state.questionPallete[customArray[2]].context.percent = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'debenturesPremium':
                            switch (customArray[1]) {
                                case 'issuedAmount':
                                    this.state.questionPallete[customArray[2]].context.issuedAmount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debenturePercent':
                                    this.state.questionPallete[customArray[2]].context.debenturePercent = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debentureAmount':
                                    this.state.questionPallete[customArray[2]].context.debenturePercent = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'percent':
                                    this.state.questionPallete[customArray[2]].context.percent = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;


                    }
                }

                switch (event.target.name) {
                    case 'bookOwnerName':
                        this.setState({
                            bookOwnerName: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'year':
                        this.setState({
                            year: event.target.value
                        }, () => { this.fillDetails() })
                        break;

                }
            }
        }

        nameSetter(name, newName, type) {
            if (type === 'purchase') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "purchaseReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            if (type === 'sales') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "order" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }

            if (type === 'preOrder') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "sales" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            this.setState({})
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'date':
                    this.setState({
                        tempDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'name':
                    this.setState({
                        tempName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amount':
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'reason':
                    this.setState({
                        tempReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'shares':
                    this.setState({
                        tempShares: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'brokerage':
                    this.setState({
                        tempBrokerage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'discount':
                    this.setState({
                        tempDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Tdiscount':
                    this.setState({
                        tempTDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Cdiscount':
                    this.setState({
                        tempCDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'purchasedFrom':
                    this.setState({
                        tempPurchasedFrom: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'billno':
                    this.setState({
                        tempBillno: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'credit':
                    this.setState({
                        tempCredit: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPercentage':
                    this.setState({
                        tempAmountPercentage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'invoiceNo':
                    this.setState({
                        tempInvoiceNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'debitNoteNo':
                    this.setState({
                        tempDebitNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'creditNoteNo':
                    this.setState({
                        tempCreditNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPaidImmediately':
                    this.setState({
                        tempAmountPaidImmediately: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'memo':
                    this.setState({
                        tempMemo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'assetName':
                    this.setState({
                        tempAssetName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmount':
                    this.setState({
                        tempExtraSum: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmountReason':
                    this.setState({
                        tempExtraSumReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashPurchase':
                    this.setState({
                        tempCashPurchase: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashSales':
                    this.setState({
                        tempCashSales: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            switch (this.state.addQuestionType) {
                case 'debenturesPar':
                    if (this.state.tempShares !== '' && NumberRegex.test(this.state.tempShares) &&
                        this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100 &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'debenturesDiscount':
                    if (this.state.tempShares !== '' && NumberRegex.test(this.state.tempShares) &&
                        this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100 &&
                        this.state.tempTDiscount !== '' && NumberRegex.test(this.state.tempTDiscount) && parseFloat(this.state.tempTDiscount) > 0 && parseFloat(this.state.tempTDiscount) < 100 &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'debenturesPremium':
                    if (this.state.tempShares !== '' && NumberRegex.test(this.state.tempShares) &&
                        this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100 &&
                        this.state.tempTDiscount !== '' && NumberRegex.test(this.state.tempTDiscount) && parseFloat(this.state.tempTDiscount) > 0 && parseFloat(this.state.tempTDiscount) < 100 &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
            }
        }

        genderChange(event) {
            this.setState({
                bookOwnerGender: event.target.value
            })
        }

        monthChange(event) {
            this.setState({
                month: event.target.value
            })
        }

        questionTypeChange(event) {
            this.setState({
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempReason: '',
                tempShares: '',
                tempBrokerage: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                addQuestionChecker: false,
                addQuestionType: event.target.value
            })
        }

        addNameChange(event) {
            this.setState({
                tempName: event.target.value
            })
        }

        addReturneeNameChange(event) {
            this.setState({
                tempReturneeName: event.target.value
            })
        }

        purchaseFromNameChange(event) {
            this.setState({
                tempPurchasedFrom: event.target.value
            })
        }

        rebateChange(event) {
            this.setState({
                tempRebate: event.target.value
            })
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.questionPallete) {
                switch (i.context.type) {
                    case 'debenturesPar':
                        if (i.context.issuedAmount === '' || !NumberRegex.test(i.context.issuedAmount) ||
                            i.context.debenturePercent === '' || !NumberRegex.test(i.context.debenturePercent) || parseFloat(i.context.debenturePercent) < 0 || parseFloat(i.context.debenturePercent) > 100 ||
                            i.context.debentureAmount === '' || !NumberRegex.test(i.context.debentureAmount)
                        ) {
                            flag = false
                        }
                        break;
                    case 'debenturesDiscount':
                        if (i.context.issuedAmount === '' || !NumberRegex.test(i.context.issuedAmount) ||
                            i.context.debenturePercent === '' || !NumberRegex.test(i.context.debenturePercent) || parseFloat(i.context.debenturePercent) < 0 || parseFloat(i.context.debenturePercent) > 100 ||
                            i.context.debentureAmount === '' || !NumberRegex.test(i.context.debentureAmount ||
                                i.context.percent === '' || !NumberRegex.test(i.context.percent) || parseFloat(i.context.percent) < 0 || parseFloat(i.context.percent) > 100)
                        ) {
                            flag = false
                        }
                        break;
                    case 'debenturesPremium':
                        if (i.context.issuedAmount === '' || !NumberRegex.test(i.context.issuedAmount) ||
                            i.context.debenturePercent === '' || !NumberRegex.test(i.context.debenturePercent) || parseFloat(i.context.debenturePercent) < 0 || parseFloat(i.context.debenturePercent) > 100 ||
                            i.context.debentureAmount === '' || !NumberRegex.test(i.context.debentureAmount ||
                                i.context.percent === '' || !NumberRegex.test(i.context.percent) || parseFloat(i.context.percent) < 0 || parseFloat(i.context.percent) > 100)
                        ) {
                            flag = false
                        }
                        break;
                }
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: false,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: true,
                    submitChecker: false
                })
            }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {


            let answerPalette = []

            for (let i of this.state.questionPallete) {
                switch (i.context.type) {
                    case 'debenturesPar':
                        i.context.newAmount = parseFloat(i.context.issuedAmount) * parseFloat(i.context.debentureAmount)
                        break;
                    case 'debenturesDiscount':
                        i.context.amountCut = parseFloat(i.context.debentureAmount) - (parseFloat(i.context.debentureAmount) * parseFloat(i.context.percent)) / 100
                        i.context.amountRest = (parseFloat(i.context.debentureAmount) * parseFloat(i.context.percent)) / 100
                        i.context.newAmount = parseFloat(i.context.issuedAmount) * i.context.amountCut
                        i.context.extraAmount = parseFloat(i.context.issuedAmount) * i.context.amountRest
                        break;
                    case 'debenturesPremium':
                        i.context.amountAdd = parseFloat(i.context.debentureAmount) + (parseFloat(i.context.debentureAmount) * parseFloat(i.context.percent)) / 100
                        i.context.amountRest = (parseFloat(i.context.debentureAmount) * parseFloat(i.context.percent)) / 100
                        i.context.newAmount = parseFloat(i.context.issuedAmount) * i.context.amountAdd
                        i.context.extraAmount = parseFloat(i.context.issuedAmount) * i.context.amountRest
                        break;
                }

            }

            // console.log(JSON.stringify(this.state.questionPallete,null,4))


            // console.log(JSON.stringify(this.state.questionPallete, null, 4))

            // let dataObject = JSON.parse(JSON.stringify(this.state.questionPallete))

            let dataObject = {
                questionPallete: this.state.questionPallete,
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.clear()
            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addQuestion() {
            let adderFlag = true

            let temporaryTemplate = {
                questionType: this.state.addQuestionType,
                date: parseFloat(this.state.tempDate),
                assetName: this.state.tempAssetName,
                name: this.state.tempName,
                amount: this.state.tempAmount,
                reason: this.state.tempReason,
                shares: this.state.tempShares,
                brokerage: this.state.tempBrokerage,
                amountPercentage: this.state.tempAmountPercentage,
                discount: this.state.tempDiscount,
                tradeDiscount: this.state.tempTDiscount,
                cashDiscount: this.state.tempCDiscount,
                purchasedFrom: this.state.tempPurchasedFrom,
                billno: this.state.tempBillno,
                invoiceNo: this.state.tempInvoiceNo,
                debitNoteNo: this.state.tempDebitNoteNo,
                creditNoteNo: this.state.tempCreditNoteNo,
                credit: this.state.tempCredit,
                memo: this.state.tempMemo,
                extraSum: this.state.tempExtraSum,
                extraSumReason: this.state.tempExtraSumReason,
                cashPurchase: this.state.tempCashPurchase,
                cashSales: this.state.tempCashSales,
                rebate: this.state.tempRebate,
                returneeName: this.state.tempReturneeName,

                amountPaidImmediately: this.state.tempAmountPaidImmediately
            }
            let questionObject
            let editModequestionObject

            switch (temporaryTemplate.questionType) {
                case 'debenturesPar':
                    questionObject = {
                        questionType: "debenture",
                        context: {
                            type: temporaryTemplate.questionType,
                            issuedAmount: temporaryTemplate.shares,
                            debenturePercent: temporaryTemplate.discount,
                            debentureAmount: temporaryTemplate.amount,
                        }
                    }
                    editModequestionObject = {
                        issuedAmount: false,
                        debenturePercent: false,
                        debentureAmount: false
                    }
                    break;
                case 'debenturesDiscount':
                    questionObject = {
                        questionType: "debenture",
                        context: {
                            type: temporaryTemplate.questionType,
                            issuedAmount: temporaryTemplate.shares,
                            debenturePercent: temporaryTemplate.discount,
                            debentureAmount: temporaryTemplate.amount,
                            percent: temporaryTemplate.tradeDiscount
                        }
                    }
                    editModequestionObject = {
                        issuedAmount: false,
                        debenturePercent: false,
                        debentureAmount: false,
                        percent: false
                    }
                    break;
                case 'debenturesPremium':
                    questionObject = {
                        questionType: "debenture",
                        context: {
                            type: temporaryTemplate.questionType,
                            issuedAmount: temporaryTemplate.shares,
                            debenturePercent: temporaryTemplate.discount,
                            debentureAmount: temporaryTemplate.amount,
                            percent: temporaryTemplate.tradeDiscount
                        }
                    }
                    editModequestionObject = {
                        issuedAmount: false,
                        debenturePercent: false,
                        debentureAmount: false,
                        percent: false
                    }
                    break;

            }

            this.state.questionPallete.push(questionObject)
            this.state.editModequestionPalette.push(editModequestionObject)

            this.setState({})

            // console.log(JSON.stringify(questionObject,null,4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            let questionType = this.state.questionPallete[event].context.type
            let questionName = this.state.questionPallete[event].context.name

            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPalette.splice(event, 1)

            if (questionType === 'sales') {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].context.type === 'salesReturns') {
                        this.state.questionPallete.splice(i, 1)
                        this.state.editModequestionPalette.splice(i, 1)
                    }
                }
            }
            if (questionType === 'nodiscount') {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].context.type === 'purchaseReturns') {
                        this.state.questionPallete.splice(i, 1)
                        this.state.editModequestionPalette.splice(i, 1)
                    }
                }
            }

            this.setState({})
        }


        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 3) {
                switch (customArray[0]) {
                    case 'debenturesPar':
                        switch (customArray[1]) {
                            case 'issuedAmount':
                                this.state.editModequestionPalette[customArray[2]].issuedAmount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'debenturePercent':
                                this.state.editModequestionPalette[customArray[2]].debenturePercent = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'debentureAmount':
                                this.state.editModequestionPalette[customArray[2]].debentureAmount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'debenturesDiscount':
                        switch (customArray[1]) {
                            case 'issuedAmount':
                                this.state.editModequestionPalette[customArray[2]].issuedAmount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'debenturePercent':
                                this.state.editModequestionPalette[customArray[2]].debenturePercent = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'debentureAmount':
                                this.state.editModequestionPalette[customArray[2]].debentureAmount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'percent':
                                this.state.editModequestionPalette[customArray[2]].percent = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'debenturesPremium':
                        switch (customArray[1]) {
                            case 'issuedAmount':
                                this.state.editModequestionPalette[customArray[2]].issuedAmount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'debenturePercent':
                                this.state.editModequestionPalette[customArray[2]].debenturePercent = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'debentureAmount':
                                this.state.editModequestionPalette[customArray[2]].debentureAmount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'percent':
                                this.state.editModequestionPalette[customArray[2]].percent = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;

                }
            }

            switch (event.target.name) {
                case 'bookOwnerGender':
                    this.setState({
                        editModebookOwnerGender: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'bookOwnerName':
                    this.setState({
                        editModebookOwnerName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'year':
                    this.setState({
                        editModeyear: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'month':
                    this.setState({
                        editModemonth: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {

            for (let i of this.state.editModequestionPalette) {
                i.issuedAmount = false
                i.debentureAmount = false
                i.debenturePercent = false
                if (i.percent) {
                    i.percent = false
                }
            }


            this.setState({
                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModemonth: false,
                editModeyear: false,

                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        Write the Journal entries only for the issue of debentures from the following transaction (without narration).
                        <br /><br />

                        <div label="totalQuestionsInArray">
                            {this.state.questionPallete.map((x, i) => (
                                <div>
                                    <span>({i + 1})</span>&emsp;&emsp;
                                    {x.context.type === 'debenturesPar' &&
                                        <span>
                                            Issued&nbsp;
                                            {this.state.editModequestionPalette[i].issuedAmount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "issuedAmount", i]} id='outlined-base' error={x.context.issuedAmount === '' || isNaN(x.context.issuedAmount)}
                                                value={x.context.issuedAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "issuedAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.issuedAmount}</a>},&nbsp;

                                            {this.state.editModequestionPalette[i].debenturePercent ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "debenturePercent", i]} id='outlined-base' error={x.context.debenturePercent === '' || isNaN(x.context.debenturePercent) || x.context.debenturePercent < 0 || x.context.debenturePercent > 100}
                                                value={x.context.debenturePercent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "debenturePercent", i]} onClick={this.buttonMaker.bind(this)}> {x.context.debenturePercent}</a>} %&nbsp;

                                            debentures of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].debentureAmount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "debentureAmount", i]} id='outlined-base' error={x.context.debentureAmount === '' || isNaN(x.context.debentureAmount)}
                                                value={x.context.debentureAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "debentureAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.debentureAmount}</a>}&nbsp;

                                            each at par, redeemable also at par.
                                        </span>
                                    }
                                    {x.context.type === 'debenturesDiscount' &&
                                        <span>
                                            Issued&nbsp;
                                            {this.state.editModequestionPalette[i].issuedAmount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "issuedAmount", i]} id='outlined-base' error={x.context.issuedAmount === '' || isNaN(x.context.issuedAmount)}
                                                value={x.context.issuedAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "issuedAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.issuedAmount}</a>},&nbsp;

                                            {this.state.editModequestionPalette[i].debenturePercent ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "debenturePercent", i]} id='outlined-base' error={x.context.debenturePercent === '' || isNaN(x.context.debenturePercent) || x.context.debenturePercent < 0 || x.context.debenturePercent > 100}
                                                value={x.context.debenturePercent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "debenturePercent", i]} onClick={this.buttonMaker.bind(this)}> {x.context.debenturePercent}</a>} %&nbsp;

                                            debentures of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].debentureAmount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "debentureAmount", i]} id='outlined-base' error={x.context.debentureAmount === '' || isNaN(x.context.debentureAmount)}
                                                value={x.context.debentureAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "debentureAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.debentureAmount}</a>}&nbsp;

                                            each at a discount of&nbsp;
                                            {this.state.editModequestionPalette[i].debentureAmount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "percent", i]} id='outlined-base' error={x.context.percent === '' || isNaN(x.context.percent) || x.context.percent < 0 || x.context.percent > 100}
                                                value={x.context.percent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "percent", i]} onClick={this.buttonMaker.bind(this)}> {x.context.percent}</a>} %,&nbsp;

                                            redeemable also at par.
                                        </span>
                                    }
                                    {x.context.type === 'debenturesPremium' &&
                                        <span>
                                            Issued&nbsp;
                                            {this.state.editModequestionPalette[i].issuedAmount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "issuedAmount", i]} id='outlined-base' error={x.context.issuedAmount === '' || isNaN(x.context.issuedAmount)}
                                                value={x.context.issuedAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "issuedAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.issuedAmount}</a>},&nbsp;

                                            {this.state.editModequestionPalette[i].debenturePercent ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "debenturePercent", i]} id='outlined-base' error={x.context.debenturePercent === '' || isNaN(x.context.debenturePercent) || x.context.debenturePercent < 0 || x.context.debenturePercent > 100}
                                                value={x.context.debenturePercent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "debenturePercent", i]} onClick={this.buttonMaker.bind(this)}> {x.context.debenturePercent}</a>} %&nbsp;

                                            debentures of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].debentureAmount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "debentureAmount", i]} id='outlined-base' error={x.context.debentureAmount === '' || isNaN(x.context.debentureAmount)}
                                                value={x.context.debentureAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "debentureAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.debentureAmount}</a>}&nbsp;

                                            each at a premium of&nbsp;
                                            {this.state.editModequestionPalette[i].debentureAmount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debenturesPar", "percent", i]} id='outlined-base' error={x.context.percent === '' || isNaN(x.context.percent) || x.context.percent < 0 || x.context.percent > 100}
                                                value={x.context.percent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debenturesPar", "percent", i]} onClick={this.buttonMaker.bind(this)}> {x.context.percent}</a>} %,&nbsp;

                                            redeemable also at par.
                                        </span>
                                    }

                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeQuestion.bind(this, i)} name="-">-</IconButton>

                                    <br /><br />
                                </div>
                            ))}
                        </div>

                        <div label="addQuestionInputs">
                            <b>ADD NEW QUESTION :</b><br /><br /><br />
                            <b>QUESTION TYPE: </b>
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">Question</InputLabel>
                                <Select
                                    labelId="question"
                                    id="question"
                                    value={this.state.addQuestionType}
                                    label="Question"
                                    onChange={this.questionTypeChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"none"}>None</MenuItem>
                                    <MenuItem value={"debenturesPar"}>Debentures - Each at Par</MenuItem>
                                    <MenuItem value={"debenturesDiscount"}>Debentures - Each at Discount</MenuItem>
                                    <MenuItem value={"debenturesPremium"}>Debentures - Each at Premium</MenuItem>

                                </Select>
                            </FormControl><br /><br /><br />
                            {this.state.addQuestionType === 'debenturesPar' &&
                                <span>
                                    Enter Issued Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="shares" error={this.state.tempShares === '' || isNaN(this.state.tempShares)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Debenture Percent: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="discount" error={this.state.tempDiscount === '' || isNaN(this.state.tempDiscount) || this.state.tempDiscount < 0 || this.state.tempDiscount > 100}
                                        placeholder="Enter Percent*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Debenture Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'debenturesDiscount' &&
                                <span>
                                    Enter Issued Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="shares" error={this.state.tempShares === '' || isNaN(this.state.tempShares)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Debenture Percent: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="discount" error={this.state.tempDiscount === '' || isNaN(this.state.tempDiscount) || this.state.tempDiscount < 0 || this.state.tempDiscount > 100}
                                        placeholder="Enter Percent*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Debenture Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Discount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="Tdiscount" error={this.state.tempTDiscount === '' || isNaN(this.state.tempTDiscount) || this.state.tempTDiscount < 0 || this.state.tempTDiscount > 100}
                                        placeholder="Enter Discount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'debenturesPremium' &&
                                <span>
                                    Enter Issued Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="shares" error={this.state.tempShares === '' || isNaN(this.state.tempShares)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Debenture Percent: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="discount" error={this.state.tempDiscount === '' || isNaN(this.state.tempDiscount) || this.state.tempDiscount < 0 || this.state.tempDiscount > 100}
                                        placeholder="Enter Percent*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Debenture Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Premium: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="Tdiscount" error={this.state.tempTDiscount === '' || isNaN(this.state.tempTDiscount) || this.state.tempTDiscount < 0 || this.state.tempTDiscount > 100}
                                        placeholder="Enter Premium*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }

                            {this.state.addQuestionType !== 'none' && <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%" }} onClick={this.addQuestion.bind(this)}>ADD NEW QUESTION</AddButton>}
                        </div>

                        <br /><br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)} >Edit Mode Off</ColorButton> : <span></span>}


                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right'>
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                        <br />
                        <br />

                    </div>
                    <br /><br />
                    {
                        this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>Ans. :</b><br /><br />
                            <div align="center"><b>Journal of Company</b></div><br /><br />
                            {data[chartState.selected].questionPallete.map((x, i) => (
                                data[chartState.selected].questionPallete[i].context.type === 'debenturesPar' ?
                                    <span>
                                        ({i + 1}) Debentures issued price of ₹ {data[chartState.selected].questionPallete[i].context.debentureAmount} each, redeemable at ₹ {data[chartState.selected].questionPallete[i].context.debentureAmount} each. <br /><br />
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center">Sr No.</StyledTableCell>
                                                        <StyledTableCell align="center">Particulars</StyledTableCell>
                                                        <StyledTableCell align="center">Debit (₹)</StyledTableCell>
                                                        <StyledTableCell align="center">Credit (₹)</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <StyledTableCell align='center' style={{ verticalAlign: "top" }}>(i)</StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            Bank A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                            &emsp;&emsp;To Debenture application and allotment A/c<br />
                                                            &emsp;&emsp;({x.context.issuedAmount} debentures X ₹ {x.context.debentureAmount})
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                            {x.context.newAmount}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                            <br />
                                                            {x.context.newAmount}
                                                        </StyledTableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <StyledTableCell align='center' style={{ verticalAlign: "top" }}>(ii)</StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            Debenture application and allotment A/c<span style={{ float: "right" }}>Dr.</span><br />
                                                            &emsp;&emsp;To {x.context.debenturePercent} % Debenture A/c<br />
                                                            &emsp;&emsp;({x.context.issuedAmount} debentures X ₹ {x.context.debentureAmount})
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                            {x.context.newAmount}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                            <br />
                                                            {x.context.newAmount}
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer><br /><br />
                                    </span> :
                                    data[chartState.selected].questionPallete[i].context.type === 'debenturesDiscount' ?
                                        <span>
                                            ({i + 1}) Debentures issued price of ₹ {data[chartState.selected].questionPallete[i].context.amountCut} each, redeemable at ₹ {data[chartState.selected].questionPallete[i].context.debentureAmount} each.<br /><br />
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="center">Sr No.</StyledTableCell>
                                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                                            <StyledTableCell align="center">Debit (₹)</StyledTableCell>
                                                            <StyledTableCell align="center">Credit (₹)</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>(i)</StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                Bank A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                                &emsp;&emsp;To Debenture application and allotment A/c<br />
                                                                &emsp;&emsp;({x.context.issuedAmount} debentures X ₹ {x.context.amountCut})
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                                {x.context.newAmount}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                                <br />
                                                                {x.context.newAmount}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>(ii)</StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                Debenture application and allotment A/c<span style={{ float: "right" }}>Dr.</span><br />
                                                                Discount on debenture A/c<span style={{ float: "right" }}>Dr.</span><br />
                                                                ({x.context.issuedAmount} debenture X ₹ {x.context.amountRest} discount A/c)<br />
                                                                &emsp;&emsp;To {x.context.debenturePercent} % debenture A/c
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                                {x.context.newAmount}<br />
                                                                {x.context.extraAmount}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                                <br /><br />
                                                                {x.context.newAmount + x.context.extraAmount}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer><br /><br />
                                        </span> :
                                        data[chartState.selected].questionPallete[i].context.type === 'debenturesPremium' &&
                                        <span>
                                            ({i + 1}) Debentures issued price of ₹ {data[chartState.selected].questionPallete[i].context.amountAdd} each, redeemable at ₹ {data[chartState.selected].questionPallete[i].context.debentureAmount} each. <br /><br />
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="center">Sr No.</StyledTableCell>
                                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                                            <StyledTableCell align="center">Debit (₹)</StyledTableCell>
                                                            <StyledTableCell align="center">Credit (₹)</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>(i)</StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                Bank A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                                &emsp;&emsp;To Debenture application and allotment A/c
                                                                &emsp;&emsp;({x.context.issuedAmount} debenture X ₹ {x.context.amountAdd})
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                                {x.context.newAmount}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                                <br />
                                                                {x.context.newAmount}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>(ii)</StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                Debenture application and allotment A/c<span style={{ float: "right" }}>Dr.</span><br />
                                                                &emsp;&emsp;To {x.context.debenturePercent} % debenture A/c<br />
                                                                &emsp;&emsp;To Security premium reserve A/c<br />
                                                                &emsp;&emsp;({x.context.issuedAmount} Debenture X ₹ {x.context.amountRest})
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                                {x.context.newAmount}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                                <br />
                                                                {x.context.newAmount - x.context.extraAmount}<br />
                                                                {x.context.extraAmount}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer><br /><br />
                                        </span>
                            ))}

                        </div>
                    }
                    <br />
                    <br />
                </div >
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

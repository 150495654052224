import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";


export default function ComparativeProfitLossStatement() {

    const data = [{
        "n": 6,
        "xWithALine": 15416.67,
        "yWithALine": 63.33,
        "gcdDensity": 500,
        "gcdPatients": 10,
        "A": 15500,
        "B": 60,
        "tableValues": [
            {
                "density": 12000,
                "no": 80,
                "u": -7,
                "v": 2,
                "uv": -14,
                "u2": 49,
                "v2": 4
            },
            {
                "density": 14500,
                "no": 60,
                "u": -2,
                "v": 0,
                "uv": 0,
                "u2": 4,
                "v2": 0
            },
            {
                "density": 19000,
                "no": 90,
                "u": 7,
                "v": 3,
                "uv": 21,
                "u2": 49,
                "v2": 9
            },
            {
                "density": 17500,
                "no": 80,
                "u": 4,
                "v": 2,
                "uv": 8,
                "u2": 16,
                "v2": 4
            },
            {
                "density": 13500,
                "no": 40,
                "u": -4,
                "v": -2,
                "uv": 8,
                "u2": 16,
                "v2": 4
            },
            {
                "density": 16000,
                "no": 30,
                "u": 1,
                "v": -3,
                "uv": -3,
                "u2": 1,
                "v2": 9
            }
        ],
        "totalTableValues": {
            "totalDensity": 92500,
            "totalPatients": 380,
            "totalu": -1,
            "totalv": 2,
            "totaluv": 20,
            "totalu2": 135,
            "totalv2": 30
        },
        "b": 0.003,
        "a": 17.08,
        "yWithARoof": 62.08,
        "reliabilityRegressionModel": "0.1045",
        "X": 15000
    }]

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                patients: [
                    {
                        density: 12000,
                        no: 80
                    }, {
                        density: 14500,
                        no: 60
                    }, {
                        density: 19000,
                        no: 90
                    }, {
                        density: 17500,
                        no: 80
                    }, {
                        density: 13500,
                        no: 40
                    }, {
                        density: 16000,
                        no: 30
                    }
                ],
                densityPopulation: 15000,

                editModepatients: [
                    {
                        density: false,
                        no: false
                    }, {
                        density: false,
                        no: false
                    }, {
                        density: false,
                        no: false
                    }, {
                        density: false,
                        no: false
                    }, {
                        density: false,
                        no: false
                    }, {
                        density: false,
                        no: false
                    }
                ],
                editModedensityPopulation: false,

                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'density':
                        this.state.patients[customArray[1]].density = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'no':
                        this.state.patients[customArray[1]].no = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'densityPopulation':
                    this.setState({
                        densityPopulation: event.target.value
                    }, () => { this.fillDetails() })
                    break;
            }
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.patients) {
                if (!NumberRegex.test(i.density) || i.density === '') {
                    flag = false
                }
            }

            if (!NumberRegex.test(this.state.densityPopulation) || this.state.densityPopulation === '') {
                flag = false
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //EVENT FUNCTIONALTIES END


        //#region GREATEST COMMON DIVISOR START 

        gcd(a, b) {
            if (a == 0)
                return b;
            return this.gcd(b % a, a);
        }

        findGCD(arr, n) {
            let result = arr[0];
            for (let i = 1; i < n; i++) {
                result = this.gcd(arr[i], result);

                if (result == 1) {
                    return 1;
                }
            }
            return result;
        }

        //#endregion GREATEST COMMON DIVISOR END

        // BUTTON PANEL START

        onSubmit() {
            let totalDensity = 0
            let totalPatients = 0
            let newDensityArray = []
            let newPatientsArray = []
            for (let i of this.state.patients) {
                newDensityArray.push(parseFloat(i.density))
                newPatientsArray.push(parseFloat(i.no))
                totalDensity += parseFloat(i.density)
                totalPatients += parseFloat(i.no)
            }
            newDensityArray = newDensityArray.sort((a, b) => { return a - b })
            newPatientsArray = newPatientsArray.sort((a, b) => { return a - b })

            let greatestCommonDivisorDensity = this.findGCD(newDensityArray, newDensityArray.length)

            let greatestCommonDivisorPatient = this.findGCD(newPatientsArray, newPatientsArray.length)

            let assumedValueOfA = (newDensityArray[0] + newDensityArray[newDensityArray.length - 1]) / 2
            let assumedValueOfB = (newPatientsArray[0] + newPatientsArray[newPatientsArray.length - 1]) / 2

            let tableValues = []
            let totalTableValues = {
                totalDensity: totalDensity,
                totalPatients: totalPatients,
                totalu: 0,
                totalv: 0,
                totaluv: 0,
                totalu2: 0,
                totalv2: 0
            }

            for (let i of this.state.patients) {
                tableValues.push({
                    density: i.density,
                    no: i.no,
                    u: (parseFloat(i.density) - assumedValueOfA) / greatestCommonDivisorDensity,
                    v: (parseFloat(i.no) - assumedValueOfB) / greatestCommonDivisorPatient,
                })
            }

            for (let i of tableValues) {
                i.uv = i.u * i.v
                i.u2 = i.u * i.u
                i.v2 = i.v * i.v
            }

            for (let i of tableValues) {
                totalTableValues.totalu += i.u
                totalTableValues.totalv += i.v
                totalTableValues.totaluv += i.uv
                totalTableValues.totalu2 += i.u2
                totalTableValues.totalv2 += i.v2
            }

            let n = this.state.patients.length

            let finalValueB = ((n * (totalTableValues.totaluv) - (totalTableValues.totalu * totalTableValues.totalv)) / (n * (totalTableValues.totalu2) - (totalTableValues.totalu * totalTableValues.totalu))) * (greatestCommonDivisorPatient / greatestCommonDivisorDensity)

            finalValueB = Math.round(finalValueB * 1000) / 1000

            let valueOfa = Math.round(totalPatients / this.state.patients.length * 100) / 100 - finalValueB * (Math.round(totalDensity / this.state.patients.length * 100) / 100)

            valueOfa = Math.round(valueOfa * 100) / 100

            let regressionY = valueOfa + finalValueB * this.state.densityPopulation

            let reliabilityRegressionModel = ((n * totalTableValues.totaluv) - (totalTableValues.totalu) * (totalTableValues.totalv)) * ((n * totalTableValues.totaluv) - (totalTableValues.totalu) * (totalTableValues.totalv))

            reliabilityRegressionModel = (reliabilityRegressionModel) / ((n * totalTableValues.totalu2 - totalTableValues.totalu * totalTableValues.totalu) * (n * totalTableValues.totalv2 - totalTableValues.totalv * totalTableValues.totalv))

            reliabilityRegressionModel = String(reliabilityRegressionModel).slice(0, 6)




            // console.log(finalValueB)

            let dataObject = {
                n: this.state.patients.length,
                xWithALine: Math.round(totalDensity / this.state.patients.length * 100) / 100,
                yWithALine: Math.round(totalPatients / this.state.patients.length * 100) / 100,
                gcdDensity: greatestCommonDivisorDensity,
                gcdPatients: greatestCommonDivisorPatient,
                A: assumedValueOfA,
                B: assumedValueOfB,
                tableValues: tableValues,
                totalTableValues: totalTableValues,
                b: finalValueB,
                a: valueOfa,
                yWithARoof: regressionY,
                reliabilityRegressionModel: reliabilityRegressionModel,
                X: this.state.densityPopulation
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addRows() {
            this.state.patients.push({
                density: 10000,
                no: 10
            })
            this.state.editModepatients.push({
                density: true,
                no: true
            })
            this.setState({
                editModeButton: true
            })
        }

        removeRows(event) {
            this.state.patients.splice(event, 1)
            this.state.editModepatients.splice(event, 1)
            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //BUTTON PANEL END


        // EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'density':
                        this.state.editModepatients[customArray[1]].density = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'no':
                        this.state.editModepatients[customArray[1]].no = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'densityPopulation':
                    this.setState({
                        editModedensityPopulation: true,
                        editModeButton: true
                    })
                    break;
            }
        }

        editModeOff() {
            for (let i of this.state.editModepatients) {
                i.density = false
                i.no = false
            }

            this.setState({
                editModeButton: false,
            })
        }

        // EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        સવાલ : વસ્તીની ગીચતા અને ચામડીના દર્દોથી પીડાતી વ્યક્તિઓની સંખ્યા વચ્ચેનો સંબંધ જાણવા,&nbsp;
                            {this.state.patients.length} અને ચામડીનાં દર્દોથી પીડાતા દર્દીઓ (દર હજારે) વિશે નીચે મુજબ માહિતી મળે છે.<br />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>ગીચતા (ચો કિમીદીઠ) 𝑥</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>દર્દીઓની સંખ્યા (દર હજારે) 𝑦</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.patients.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModepatients[i].density ?
                                                        <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "20%" }} name={["density", i]} id='outlined-base' error={x.density === '' || isNaN(x.density)}
                                                            value={x.density} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["density", i]} onClick={this.buttonMaker.bind(this)}> {x.density}</a>
                                                }
                                                {this.state.patients.length > 2 && <IconButton style={{ width: "3%", color: "red" }} onClick={this.removeRows.bind(this, i)} name="-">-</IconButton>}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModepatients[i].no ?
                                                        <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "20%" }} name={["no", i]} id='outlined-base' error={x.no === '' || isNaN(x.no)}
                                                            value={x.no} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["no", i]} onClick={this.buttonMaker.bind(this)}> {x.no}</a>
                                                }
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />
                        <AddButton style={{ width: "15%", fontSize: "20px" }} onClick={this.addRows.bind(this)}>પંક્તિઓ ઉમેરો</AddButton><br /><br />

                        આ માહિતી પરથી Y અને X પરની નિયતસંબંધ રેખા મેળવો. જો કોઈ શહેરની ગીયતા&nbsp;
                            {
                                this.state.editModedensityPopulation ?
                                    <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "20%" }} name="densityPopulation" id='outlined-base' error={this.state.densityPopulation === '' || isNaN(this.state.densityPopulation)}
                                        value={this.state.densityPopulation} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name="densityPopulation" onClick={this.buttonMaker.bind(this)}> {this.state.densityPopulation}</a>
                            }&nbsp;
                            (ચો કિમીદીઠ) હોય તો તેમાં ચામડીનાં દર્દોથી પીડાતા દર્દીઓની સંખ્યાનું અનુમાન કરો. આ નિયતસંબંધ મોડેલની વિશ્વસનીયતા ચકાસો.
                        
                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize: "20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize: "20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                    <div label="Answer" align="left">
                        <b>જવાબ,</b><br />
                        &emsp;અહીં, n = {data[chartState.selected].n}, x̄ = (<sup>Σ𝑥</sup>/<sub>𝑛</sub>) = (<sup>{data[chartState.selected].totalTableValues.totalDensity}</sup>/<sub>{data[chartState.selected].n}</sub>) = {data[chartState.selected].xWithALine},&nbsp;&nbsp;ȳ = (<sup>Σ𝑦</sup>/<sub>𝑛</sub>) = {data[chartState.selected].yWithALine}<br />
                        &emsp;આપણે જોઈ શકીએ છીએ કે, ચલ <i>X</i> ની કિંમતો {data[chartState.selected].gcdDensity} ના ગુણકમાં અને ચલ <i>Y</i> ની કિંમતો {data[chartState.selected].gcdPatients}ના ગુણકમાં છે. તેથી.<br />
                        <i>A</i> = {data[chartState.selected].A}, <i>B</i> = {data[chartState.selected].B}, c<sub>𝑥</sub> = {data[chartState.selected].gcdDensity}, c<sub>𝑦</sub> = {data[chartState.selected].gcdPatients}, લઈ આપણે ટૂંકી રીતનો ઉપયોગ કરીશું. હવે આપણે 𝑢 અને 𝑣 મુજબ વ્યાખ્યાયિત કરીએ.<br />
                        𝑢 = <sup>(𝑥 - <i>A</i>)</sup>/<sub>c<sub>𝑥</sub></sub> = <sup>(𝑥 - {data[chartState.selected].A})</sup>/<sub>{data[chartState.selected].gcdDensity}</sub> and 𝑣 = <sup>(𝑦 - <i>B</i>)</sup>/<sub>c<sub>𝑦</sub></sub> = <sup>(𝑦 - {data[chartState.selected].B})</sup>/<sub>{data[chartState.selected].gcdPatients}</sub> <br /><br />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>ગીચતા <br />(ચો કિમીદીઠ)<br /> 𝑥</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>દર્દીઓની સંખ્યા <br /> (દર હજારે) <br /> 𝑦</StyledTableCell>
                                        <StyledTableCell align="center">𝑢<br />= <sup>(𝑥 - {data[chartState.selected].A})</sup>/<sub>{data[chartState.selected].gcdDensity}</sub></StyledTableCell>
                                        <StyledTableCell align="center">𝑣<br />= <sup>(𝑦 - {data[chartState.selected].B})</sup>/<sub>{data[chartState.selected].gcdPatients}</sub></StyledTableCell>
                                        <StyledTableCell align="center">𝑢𝑣</StyledTableCell>
                                        <StyledTableCell align="center">𝑢<sup>2</sup></StyledTableCell>
                                        <StyledTableCell align="center">𝑣<sup>2</sup></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data[chartState.selected].tableValues.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align="center">{x.density}</StyledTableCell>
                                            <StyledTableCell align="center">{x.no}</StyledTableCell>
                                            <StyledTableCell align="center">{x.u}</StyledTableCell>
                                            <StyledTableCell align="center">{x.v}</StyledTableCell>
                                            <StyledTableCell align="center">{x.uv}</StyledTableCell>
                                            <StyledTableCell align="center">{x.u2}</StyledTableCell>
                                            <StyledTableCell align="center">{x.v2}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <StyledTableCell align="center"><b>{data[chartState.selected].totalTableValues.totalDensity}</b></StyledTableCell>
                                        <StyledTableCell align="center"><b>{data[chartState.selected].totalTableValues.totalPatients}</b></StyledTableCell>
                                        <StyledTableCell align="center"><b>{data[chartState.selected].totalTableValues.totalu}</b></StyledTableCell>
                                        <StyledTableCell align="center"><b>{data[chartState.selected].totalTableValues.totalv}</b></StyledTableCell>
                                        <StyledTableCell align="center"><b>{data[chartState.selected].totalTableValues.totaluv}</b></StyledTableCell>
                                        <StyledTableCell align="center"><b>{data[chartState.selected].totalTableValues.totalu2}</b></StyledTableCell>
                                        <StyledTableCell align="center"><b>{data[chartState.selected].totalTableValues.totalv2}</b></StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <br /><br />
                        𝑏 = <sup>(𝑛Σ𝑢𝑣 - (Σ𝑢)(Σ𝑣))</sup>/<sub>(𝑛Σ𝑢<sup>2</sup> - (Σ𝑢)<sup>2</sup>)</sub> X <sup>c<sub>𝑦</sub></sup>/<sub>c<sub>𝑥</sub></sub><br />
                        &nbsp;&nbsp;&nbsp;= <sup>({data[chartState.selected].n}({data[chartState.selected].totalTableValues.totaluv}) - ({data[chartState.selected].totalTableValues.totalu})({data[chartState.selected].totalTableValues.totalv}))</sup>/<sub>({data[chartState.selected].n}({data[chartState.selected].totalTableValues.totalu2}) - ({data[chartState.selected].totalTableValues.totalu})<sup>2</sup>)</sub> X <sup>{data[chartState.selected].gcdPatients}</sup>/<sub>{data[chartState.selected].gcdDensity}</sub><br />
                        &nbsp;&nbsp;&nbsp;= <sup>{data[chartState.selected].n * data[chartState.selected].totalTableValues.totaluv} - {data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalv}</sup>/<sub>{data[chartState.selected].n * data[chartState.selected].totalTableValues.totalu2} - {data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalu}</sub> X <sup>1</sup>/<sub>{data[chartState.selected].gcdDensity / data[chartState.selected].gcdPatients}</sub><br />
                        &nbsp;&nbsp;&nbsp;= <sup>{data[chartState.selected].n * data[chartState.selected].totalTableValues.totaluv - data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalv}</sup>/<sub>{data[chartState.selected].n * data[chartState.selected].totalTableValues.totalu2 - data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalu}</sub> X <sup>1</sup>/<sub>{data[chartState.selected].gcdDensity / data[chartState.selected].gcdPatients}</sub><br />
                        &nbsp;&nbsp;&nbsp;= <sup>{data[chartState.selected].n * data[chartState.selected].totalTableValues.totaluv - data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalv}</sup>/<sub>{(data[chartState.selected].n * data[chartState.selected].totalTableValues.totalu2 - data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalu) * (data[chartState.selected].gcdDensity / data[chartState.selected].gcdPatients)}</sub><br />
                        𝑏 = {data[chartState.selected].b}
                        <br /><br />
                        𝑎 = ȳ - 𝑏x̄<br />
                        &nbsp;&nbsp;&nbsp;= {data[chartState.selected].yWithALine} - {data[chartState.selected].b}({data[chartState.selected].xWithALine})<br />
                        &nbsp;&nbsp;&nbsp;= {data[chartState.selected].yWithALine} - {Math.round(data[chartState.selected].b * data[chartState.selected].xWithALine * 100) / 100}<br />
                        ∴ 𝑎 = {data[chartState.selected].a}<br /><br />
                        <i>Y</i> ની <i>X</i> પરની નિયતસંબંધ રેખા <br />
                        ŷ = 𝑎 + 𝑏𝑥<br />
                        ŷ = {data[chartState.selected].a} + {data[chartState.selected].b}𝑥<br />
                        𝑥 (<i>X</i>) = {data[chartState.selected].X} મૂકતાં,<br />
                        ŷ = {data[chartState.selected].a} + {data[chartState.selected].b}({data[chartState.selected].X})<br />
                        ŷ = {data[chartState.selected].a} + {data[chartState.selected].b * data[chartState.selected].X}<br />
                        ∴ ŷ = {data[chartState.selected].yWithARoof}<br /><br />
                        &emsp;તેથી કોઈ શહેરની ગીચતા {data[chartState.selected].X} હોય, તો તેમાં ચામડીના દર્દથી પીડાતા દર્દીઓની અનુમાનિત સંખ્યા દર હજારે {data[chartState.selected].yWithARoof} ≈ {Math.round(data[chartState.selected].yWithARoof)}થાય.<br /><br />
                        &emsp;હવે,નિયતસંબંધ મોડેલની વિશ્વસનીયતા નિશ્ચાયક્તાના આંક <i>R</i><sup>2</sup>. પરથી ચકાસી શકાય છે. તેથી આપણે તે મેળવીએ <br /><br />

                        <i>R</i><sup>2</sup> = <i>r</i><sup>2</sup> = {"["}<sup>(𝑛Σ𝑢𝑣 - (Σ𝑢)(Σ𝑣))</sup>/<sub>((√𝑛Σ𝑢<sup>2</sup> - (Σ𝑢)<sup>2</sup>)⋅(√𝑛Σ𝑣<sup>2</sup> - (Σ𝑣)<sup>2</sup>))</sub> {"]"}<sup>2</sup><br />
                        &emsp;&emsp;&emsp;&nbsp;= <sup>[{data[chartState.selected].n}({data[chartState.selected].totalTableValues.totaluv}) - ({data[chartState.selected].totalTableValues.totalu})({data[chartState.selected].totalTableValues.totalv})]</sup>/[{data[chartState.selected].n}({data[chartState.selected].totalTableValues.totalu2}) - ({data[chartState.selected].totalTableValues.totalu})<sup>2</sup>][{data[chartState.selected].n}({data[chartState.selected].totalTableValues.totalv2}) - ({data[chartState.selected].totalTableValues.totalv})<sup>2</sup>]<br />
                        &emsp;&emsp;&emsp;&nbsp;= <sup>[{data[chartState.selected].n * data[chartState.selected].totalTableValues.totaluv - data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalv}]</sup>/[{data[chartState.selected].n * data[chartState.selected].totalTableValues.totalu2 - data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalu}][{data[chartState.selected].n * data[chartState.selected].totalTableValues.totalv2 - data[chartState.selected].totalTableValues.totalv * data[chartState.selected].totalTableValues.totalv}]<br />
                        &emsp;&emsp;&emsp;&nbsp;= <sup>[{(data[chartState.selected].n * data[chartState.selected].totalTableValues.totaluv - data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalv) * (data[chartState.selected].n * data[chartState.selected].totalTableValues.totaluv - data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalv)}]</sup>/[{data[chartState.selected].n * data[chartState.selected].totalTableValues.totalu2 - data[chartState.selected].totalTableValues.totalu * data[chartState.selected].totalTableValues.totalu}][{data[chartState.selected].n * data[chartState.selected].totalTableValues.totalv2 - data[chartState.selected].totalTableValues.totalv * data[chartState.selected].totalTableValues.totalv}]<br />
                        ∴ <i>R</i><sup>2</sup> = {data[chartState.selected].reliabilityRegressionModel}<br /><br />
                       
                            {parseFloat(data[chartState.selected].reliabilityRegressionModel) <= 0.3 && <span><i>R</i><sup>2</sup> ની કિંમત 0ની ખૂબ નજીક હોવાથી નિયતસંબંધ મૉડેલ વિશ્વસનીય છે તેમ કહી શકાય નહિ.</span>}
                            {parseFloat(data[chartState.selected].reliabilityRegressionModel) > 0.3 && parseFloat(data[chartState.selected].reliabilityRegressionModel) <= 0.5 && <span><i>R</i><sup>2</sup> ની કિંમત 0 ની ખૂબ નજીક છે, રીગ્રેશનની નબળી અસર છે.</span>}
                            {parseFloat(data[chartState.selected].reliabilityRegressionModel) > 0.5 && parseFloat(data[chartState.selected].reliabilityRegressionModel) <= 0.7 && <span><i>R</i><sup>2</sup> ની કિંમત 0 ની ખૂબ નજીક છે, રીગ્રેશનની મધ્યમ અસર છે.</span>}
                            {parseFloat(data[chartState.selected].reliabilityRegressionModel) > 0.7 && <span><i>R</i><sup>2</sup> ની કિંમત 0 ની ખૂબ નજીક છે, રીગ્રેશનની મજબૂત અસર છે.</span>}
                        

                    </div>}
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

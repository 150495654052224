import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {
    const data = [{
        "particulars": [
            {
                "type": "ખોરાક",
                "familyA": 40000,
                "familyB": 50000,
                "familyADegree": 160,
                "familyBDegree": 112.5
            },
            {
                "type": "કપડાં",
                "familyA": 10000,
                "familyB": 20000,
                "familyADegree": 40,
                "familyBDegree": 45
            },
            {
                "type": "ભાડું",
                "familyA": 25000,
                "familyB": 30000,
                "familyADegree": 100,
                "familyBDegree": 67.5
            },
            {
                "type": "શિક્ષણ",
                "familyA": 10000,
                "familyB": 32000,
                "familyADegree": 40,
                "familyBDegree": 72
            },
            {
                "type": "પરચૂરણ",
                "familyA": 5000,
                "familyB": 28000,
                "familyADegree": 20,
                "familyBDegree": 63
            }
        ],
        "totalExpenseFamilyA": 90000,
        "totalExpenseFamilyB": 160000,
        "familyARadius": 3,
        "familyBRadius": 4
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }


    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                particulars: [
                    {
                        type: "ખોરાક",
                        familyA: 40000,
                        familyB: 50000
                    }, {
                        type: "કપડાં",
                        familyA: 10000,
                        familyB: 20000
                    }, {
                        type: "ભાડું",
                        familyA: 25000,
                        familyB: 30000
                    }, {
                        type: "શિક્ષણ",
                        familyA: 10000,
                        familyB: 32000
                    }, {
                        type: "પરચૂરણ",
                        familyA: 5000,
                        familyB: 28000
                    }
                ],

                editModeParticulars: [
                    {
                        type: false,
                        familyA: false,
                        familyB: false
                    }, {
                        type: false,
                        familyA: false,
                        familyB: false
                    }, {
                        type: false,
                        familyA: false,
                        familyB: false
                    }, {
                        type: false,
                        familyA: false,
                        familyB: false
                    }, {
                        type: false,
                        familyA: false,
                        familyB: false
                    }
                ],



                editModenumberOfClass: false,
                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'type':
                        this.state.particulars[customArray[1]].type = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'familyA':
                        this.state.particulars[customArray[1]].familyA = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'familyB':
                        this.state.particulars[customArray[1]].familyB = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {

            }
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.particulars) {
                if (i.type === '') {
                    flag = false
                }
                if (!NumberRegex.test(i.familyA) || i.familyA === '') {
                    flag = false
                }
                if (!NumberRegex.test(i.familyB) || i.familyB === '') {
                    flag = false
                }
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        autoSave(event) {
            // if (this.state.totalPurchase !== '' && this.state.cashPurchase !== '' && this.state.purchaseReturn !== '' && this.state.openingTradePayables !== '' && this.state.closingTradePayables !== '') {
            if (event.key === 'Enter') {
                this.editModeOff()
            }
            // }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {
            let familyARadius = 0
            let familyBRadius = 0
            let totalExpenseFamilyA = 0
            let totalExpenseFamilyB = 0

            for (let i of this.state.particulars) {
                totalExpenseFamilyA += parseFloat(i.familyA)
                totalExpenseFamilyB += parseFloat(i.familyB)
            }

            for (let i of this.state.particulars) {
                i.familyADegree = Math.round(parseFloat(i.familyA) / totalExpenseFamilyA * 360 * 100) / 100
                i.familyBDegree = Math.round(parseFloat(i.familyB) / totalExpenseFamilyB * 360 * 100) / 100
            }

            familyARadius = Math.round(Math.sqrt(totalExpenseFamilyA) * 100) / 10000
            familyBRadius = Math.round(Math.sqrt(totalExpenseFamilyB) * 100) / 10000


            let dataObject = {
                particulars: this.state.particulars,
                totalExpenseFamilyA,
                totalExpenseFamilyB,
                familyARadius,
                familyBRadius
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addRow() {
            this.state.particulars.push({
                type: "નવી આઇટમ",
                familyA: 10000,
                familyB: 10000
            })
            this.state.editModeParticulars.push({
                type: true,
                familyA: true,
                familyB: true
            })
            this.setState({
                editModeButton: true
            })
        }

        removeRow(event) {
            this.state.particulars.splice(event, 1)
            this.state.editModeParticulars.splice(event, 1)
            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'type':
                        this.state.editModeParticulars[customArray[1]].type = true
                        this.setState({
                            editModeButton: true,
                            submitChecker:false
                        })
                        break;
                    case 'familyA':
                        this.state.editModeParticulars[customArray[1]].familyA = true
                        this.setState({
                            editModeButton: true,
                            submitChecker:false
                        })
                        break;
                    case 'familyB':
                        this.state.editModeParticulars[customArray[1]].familyB = true
                        this.setState({
                            editModeButton: true,
                            submitChecker:false
                        })
                        break;
                }
            }

            switch (event.target.name) {


            }
        }

        editModeOff() {

            for (let i of this.state.editModeParticulars) {
                i.type = false
                i.familyA = false
                i.familyB = false
            }



            this.setState({

                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                    મધ્યમ વર્ગીય બે કુટુંબોની વાર્ષિક ખર્ચની વિગતો નીચે મુજબ છે, તેને વૃતાંશ-આકૃતિ દ્વારા દર્શાવો.
                        <br /><br />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center"></StyledTableCell>
                                        <StyledTableCell align="right" style={{fontSize: "20px"}}>વાર્ષિક ખર્ચ (રૂપિયામાં) </StyledTableCell>
                                        <StyledTableCell align="left">(₹)</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>કુટુંબ A</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>કુટુંબ B</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.particulars.map((x, i) =>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>
                                                {this.state.editModeParticulars[i].type ? <TextField style={{ "width": "50%", "padding": "-10px" }} name={["type", i]} id='outlined-base' error={x.type === ''}
                                                    value={x.type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["type", i]} onClick={this.buttonMaker.bind(this)}> {x.type}</a>} &nbsp;
                                                {this.state.particulars.length > 2 && <IconButton style={{ width: "3%", color: "red" }} onClick={this.removeRow.bind(this, i)} name="-">-</IconButton>}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>
                                                {this.state.editModeParticulars[i].familyA ? <TextField style={{ "width": "25%", "padding": "-10px" }} name={["familyA", i]} id='outlined-base' error={x.familyA === '' || isNaN(x.familyA)}
                                                    value={x.familyA} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["familyA", i]} onClick={this.buttonMaker.bind(this)}> {x.familyA}</a>} &nbsp;
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>
                                                {this.state.editModeParticulars[i].familyB ? <TextField style={{ "width": "25%", "padding": "-10px" }} name={["familyB", i]} id='outlined-base' error={x.familyB === '' || isNaN(x.familyB)}
                                                    value={x.familyB} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["familyB", i]} onClick={this.buttonMaker.bind(this)}> {x.familyB}</a>} &nbsp;
                                            </StyledTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer><br />
                        <AddButton style={{ width: "15%", fontSize: "20px" }} onClick={this.addRow.bind(this)}>આઇટમ ઉમેરો</AddButton>



                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            &emsp;બંને કુટુંબો માટે અલગ-અલગ વર્તુળની ત્રિજયા તેમના કુલ ખર્ચના વર્ગમૂળના પ્રમાણમાં લઈશું તેમજ કુલ ખર્ચને 360 લઈ તેમના પેટાવિભાગ ખર્ચનો વૃતાંશ મેળવીશું.<br /><br />
                            કુટુંબ A માટેની ત્રિજ્યા = <sup>√{data[chartState.selected].totalExpenseFamilyA}</sup>/<sub>100</sub> = <sup>{Math.round((Math.sqrt(data[chartState.selected].totalExpenseFamilyA)) * 100) / 100}</sup>/<sub>100</sub> = {data[chartState.selected].familyARadius} સેમી<br />
                            કુટુંબ B માટેની ત્રિજ્યા = <sup>√{data[chartState.selected].totalExpenseFamilyB}</sup>/<sub>100</sub> = <sup>{Math.round((Math.sqrt(data[chartState.selected].totalExpenseFamilyB)) * 100) / 100}</sup>/<sub>100</sub> = {data[chartState.selected].familyBRadius} સેમી<br />
                            <div align="center">વાર્ષિક ખર્ચ (₹) </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>કુટુંબ A (₹) </StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>કુટુંબ B (₹) </StyledTableCell>
                                            <StyledTableCell align="center"></StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>ખર્ચ</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>અંશ</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>ખર્ચ</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>અંશ</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].particulars.map((x, i) =>
                                            <TableRow>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>{x.type}</StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>{x.familyA}</StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>
                                                    <sup>{x.familyA}</sup>/<sub>{data[chartState.selected].totalExpenseFamilyA} = {x.familyADegree}°</sub>
                                                </StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>{x.familyB}</StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>
                                                    <sup>{x.familyB}</sup>/<sub>{data[chartState.selected].totalExpenseFamilyB} = {x.familyBDegree}°</sub>
                                                </StyledTableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}><b>કુલ</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].totalExpenseFamilyA}</b></StyledTableCell>
                                            <StyledTableCell align="right"><b>= 360°</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].totalExpenseFamilyB}</b></StyledTableCell>
                                            <StyledTableCell align="right"><b>= 360°</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

//Illustration 9

export default function ComparativeProfitLossStatement() {

    const data = [{
        "name": "Gujarat Ltd.",
        "date": "2017-03-31",
        "totalQuestions": [
            {
                "type": "Non-current assets",
                "subCategory": [
                    {
                        "nameInType": "Fixed assets",
                        "subCategory": [
                            {
                                "type": "Tangilble",
                                "name": "Plant-machinery",
                                "amount": 600000
                            },
                            {
                                "type": "Intangible",
                                "name": "Trademark",
                                "amount": 20000
                            }
                        ]
                    },
                    {
                        "nameInType": "Non-current investments assets",
                        "subCategory": [
                            {
                                "type": "-",
                                "name": "10 % Narmada bond",
                                "amount": 70000
                            }
                        ]
                    },
                    {
                        "nameInType": "Long-term loans and advances",
                        "subCategory": [
                            {
                                "type": "-",
                                "name": "Telephone deposit",
                                "amount": 30000
                            }
                        ]
                    },
                    {
                        "nameInType": "Other non-current assets",
                        "subCategory": []
                    }
                ]
            },
            {
                "type": "Current assets",
                "subCategory": [
                    {
                        "nameInType": "Current investments",
                        "subCategory": [
                            {
                                "type": "-",
                                "name": "Units of mutual fund (Temporary)",
                                "amount": 10000
                            }
                        ]
                    },
                    {
                        "nameInType": "Inventory",
                        "subCategory": [
                            {
                                "type": "-",
                                "name": "Loose tools",
                                "amount": 18000
                            }
                        ]
                    },
                    {
                        "nameInType": "Trade receivables",
                        "subCategory": [
                            {
                                "type": "-",
                                "name": "Debtors",
                                "amount": 42000
                            }
                        ]
                    },
                    {
                        "nameInType": "Cash and cash equivalents",
                        "subCategory": [
                            {
                                "type": "-",
                                "name": "Cash on hand",
                                "amount": 37000
                            }
                        ]
                    },
                    {
                        "nameInType": "Short-term loans and advances",
                        "subCategory": []
                    },
                    {
                        "nameInType": "Other current assets",
                        "subCategory": [
                            {
                                "type": "-",
                                "name": "Interest accrued",
                                "amount": 3000
                            }
                        ]
                    }
                ]
            }
        ],
        "NCATotal": 720000,
        "CATotal": 110000
    }]

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({

                name: "Gujarat Ltd.",
                date: "2017-03-31",

                questionPalette: [{
                    name: "Interest accrued",
                    amount: 3000,
                    type: "Current assets",
                    nameInType: "Other current assets",
                    subCategory: {
                        name: "-"
                    },
                }, {
                    name: "Plant machinery",
                    amount: 600000,
                    type: "Non-current assets",
                    nameInType: "Fixed assets",
                    subCategory: {
                        name: "Tangible"
                    },
                }, {
                    name: "Cash on hand",
                    amount: 37000,
                    type: "Current assets",
                    nameInType: "Cash and cash equivalents",
                    subCategory: {
                        name: "-",
                    },
                }, {
                    name: "Trademark",
                    amount: 20000,
                    type: "Non-current assets",
                    nameInType: "Fixed assets",
                    subCategory: {
                        name: "Intangible"
                    },
                }, {
                    name: "Debtors",
                    amount: 42000,
                    type: "Current assets",
                    nameInType: "Trade receivables",
                    subCategory: {
                        name: "-",
                    },
                }, {
                    name: "10% Narmada bond",
                    amount: 70000,
                    type: "Non-current assets",
                    nameInType: "Non-current investments",
                    subCategory: {
                        name: "-",
                    },
                }, {
                    name: "Loose tools",
                    amount: 18000,
                    type: "Current assets",
                    nameInType: "Inventory",
                    subCategory: {
                        name: "-",
                    },
                }, {
                    name: "Telephone deposit",
                    amount: 30000,
                    type: "Non-current assets",
                    nameInType: "Long-term loans and advances",
                    subCategory: {
                        name: "-",
                    },
                }, {
                    name: "Units of mutual fund (Temporary)",
                    amount: 10000,
                    type: "Current assets",
                    nameInType: "Current investments",
                    subCategory: {
                        name: "-",
                    },
                },],

                totalQuestions: [{
                    type: "Non-current assets",
                    subCategory: [{
                        nameInType: "Fixed assets",
                        subCategory: [{
                            type: "Tangilble",
                            name: "Plant-machinery",
                            amount: 600000
                        }, {
                            type: "Intangible",
                            name: "Trademark",
                            amount: 20000
                        }],
                    }, {
                        nameInType: "Non-current investments assets",
                        subCategory: [{
                            type: "-",
                            name: "10 % Narmada bond",
                            amount: 70000
                        }],

                    }, {
                        nameInType: "Long-term loans and advances",
                        subCategory: [{
                            type: "-",
                            name: "Telephone deposit",
                            amount: 30000
                        }],
                    }, {
                        nameInType: "Other non-current assets",
                        subCategory: [],
                    }]
                }, {
                    type: "Current assets",
                    subCategory: [{
                        nameInType: "Current investments",
                        subCategory: [{
                            type: "-",
                            name: "Units of mutual fund (Temporary)",
                            amount: 10000
                        }],
                    }, {
                        nameInType: "Inventory",
                        subCategory: [{
                            type: "-",
                            name: "Loose tools",
                            amount: 18000
                        }],
                    }, {
                        nameInType: "Trade receivables",
                        subCategory: [{
                            type: "-",
                            name: "Debtors",
                            amount: 42000
                        }],
                    }, {
                        nameInType: "Cash and cash equivalents",
                        subCategory: [{
                            type: "-",
                            name: "Cash on hand",
                            amount: 37000
                        }],
                    }, {
                        nameInType: "Short-term loans and advances",
                        subCategory: [],
                    }, {
                        nameInType: "Other current assets",
                        subCategory: [{
                            type: "-",
                            name: "Interest accrued",
                            amount: 3000
                        }],
                    }]
                }],

                tempName: '',
                tempAmount: '',
                tempType: '',
                tempTypeInName: '',
                tempSubCategory: '',

                editModename: false,
                editModedate: false,

                editModequestionPalette: [{
                    name: false,
                    amount: false
                }, {
                    name: false,
                    amount: false
                }, {
                    name: false,
                    amount: false
                }, {
                    name: false,
                    amount: false
                }, {
                    name: false,
                    amount: false
                }, {
                    name: false,
                    amount: false
                }, {
                    name: false,
                    amount: false
                }, {
                    name: false,
                    amount: false
                }, {
                    name: false,
                    amount: false
                },],

                solutionShow: false,
                // tableModeChartModeSwitch: true,
                addQuestionChecker: false,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.stringReducer = this.stringReducer.bind(this)
            this.removeEntry = this.removeEntry.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {

            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'name':
                        this.state.questionPalette[customArray[1]].name = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'amount':
                        this.state.questionPalette[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'name':
                    this.setState({
                        totalSets: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'date':
                    this.setState({
                        sampleString: this.stringReducer(event.target.value)
                    }, () => { this.fillDetails() })
                    break;

            }
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'name':
                    this.setState({
                        tempName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amount':
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'category':
                    this.setState({
                        tempSubCategory: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            if (this.state.tempName !== '' &&
                this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                this.state.tempType !== '' &&
                this.state.tempTypeInName !== '') {
                this.setState({
                    addQuestionChecker: true
                })
            } else {
                this.setState({
                    addQuestionChecker: false
                })
            }
        }

        addTypeChange(event) {
            this.setState({
                tempType: event.target.value
            }, () => { this.addButtonChecker() })
        }

        addNameTypeChange(event) {
            this.setState({
                tempTypeInName: event.target.value
            }, () => { this.addButtonChecker() })
        }

        stringReducer(string) {
            let stringArray = string.split(" ")

            for (let i in stringArray) {
                if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === '') {
                    // console.log(i)
                    stringArray.splice(i, 1)
                }
            }

            if (stringArray[0] === null) {
                stringArray[0] = 0
            }

            this.setState({
                sampleStringLength: stringArray.length,
                sampleStringArray: stringArray
            })

            return string
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.questionPalette) {
                if (i.name === '') {
                    flag = false
                }
                if (i.amount === '' || !NumberRegex.test(i.amount)) {
                    flag = false
                }
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            let NCATotal = 0
            let CATotal = 0

            for (let i of this.state.totalQuestions[0].subCategory) {
                if (i.subCategory.length !== 0) {
                    NCATotal += i.subCategory.reduce((a, b) => (a + parseFloat(b.amount)), 0)
                }
            }
            for (let i of this.state.totalQuestions[1].subCategory) {
                if (i.subCategory.length !== 0) {
                    CATotal += i.subCategory.reduce((a, b) => (a + parseFloat(b.amount)), 0)
                }
            }

            let dataObject = {
                name: this.state.name,
                date: this.state.date,
                totalQuestions: this.state.totalQuestions,
                NCATotal,
                CATotal
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        removeEntry(event) {
            let name = this.state.questionPalette[event].name
            let type = this.state.questionPalette[event].type
            let nameInType = this.state.questionPalette[event].nameInType

            this.state.questionPalette.splice(event, 1)
            this.state.editModequestionPalette.splice(event, 1)

            for (let i in this.state.totalQuestions) {
                if (this.state.totalQuestions[i].type === type) {
                    for (let j in this.state.totalQuestions[i].subCategory) {
                        if (this.state.totalQuestions[i].subCategory[j].nameInType === nameInType) {
                            for (let k in this.state.totalQuestions[i].subCategory[j].subCategory) {
                                if (this.state.totalQuestions[i].subCategory[j].subCategory[k].name === name) {
                                    this.state.totalQuestions[i].subCategory[j].subCategory.splice(k, 1)
                                }
                            }
                        }
                    }
                }
            }
            console.log(this.state.totalQuestions)

            this.setState({})
        }

        addQuestion() {
            let adderFlag = true

            let temporaryTemplate = {
                name: this.state.tempName,
                amount: this.state.tempAmount,
                type: this.state.tempType,
                typeInName: this.state.tempTypeInName,
                subCategory: this.state.tempSubCategory
            }

            for (let i of this.state.totalQuestions) {
                if (i.type === temporaryTemplate.type) {
                    for (let j of i.subCategory) {
                        if (j.nameInType === temporaryTemplate.typeInName) {
                            j.subCategory.push({
                                type: temporaryTemplate.subCategory === '' ? '-' : temporaryTemplate.subCategory,
                                name: temporaryTemplate.name,
                                amount: temporaryTemplate.amount
                            })
                        }
                    }
                }
            }

            this.state.questionPalette.push({
                name: temporaryTemplate.name,
                amount: temporaryTemplate.amount,
                type: temporaryTemplate.type,
                nameInType: temporaryTemplate.typeInName,
                subCategory: {
                    name: temporaryTemplate.subCategory === '' ? '-' : temporaryTemplate.subCategory
                }
            })
            this.state.editModequestionPalette.push({
                name: false,
                amount: false
            })

            // this.state.questionPallete.push(questionObject)
            // this.state.editModequestionPallete.push(editModequestionObject)


            this.setState({})

            console.log(JSON.stringify(this.state.totalQuestions, null, 4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }


        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'name':
                        this.state.editModequestionPalette[customArray[1]].name = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'amount':
                        this.state.editModequestionPalette[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'name':
                    this.setState({
                        editModename: true,
                        editModeButton: true
                    })
                    break;
                case 'date':
                    this.setState({
                        editModedate: true,
                        editModeButton: true
                    })
                    break;

                default:
                    break;
            }
        }

        editModeOff() {
            for (let i of this.state.editModequestionPalette) {
                i.name = false
                i.amount = false
            }

            this.setState({
                editModename: false,
                editModedate: false,
                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        Following balances are extracted from books of&nbsp;

                        {
                            this.state.editModename ?
                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name="name" id='outlined-base' error={this.state.name === ''}
                                    value={this.state.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="name" onClick={this.buttonMaker.bind(this)}> {this.state.name}</a>
                        }&nbsp;

                        as on&nbsp;

                        {
                            this.state.editModedate ?
                                <TextField type="date" style={{ 'margin-top': "-15px", width: "15%" }} name="date" id='outlined-base' error={this.state.date === ''}
                                    value={this.state.date} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="date" onClick={this.buttonMaker.bind(this)}> {this.state.date}</a>
                        }.&nbsp;

                        Prepare statement indicating assets of balance sheet as per schedule-III of Companies Act, 2013.

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Balances</StyledTableCell>
                                        <StyledTableCell align="center">(₹)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.questionPalette.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align="left">({i + 1})&nbsp;
                                                {
                                                    this.state.editModequestionPalette[i].name ?
                                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["name", i]} id='outlined-base' error={x.name === ''}
                                                            value={x.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["name", i]} onClick={this.buttonMaker.bind(this)}> {x.name}</a>
                                                }&nbsp;

                                                <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeEntry.bind(this, i)} name="-">-</IconButton>

                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {
                                                    this.state.editModequestionPalette[i].amount ?
                                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["amount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                            value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["amount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                                }
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />

                        ADD NEW ASSET<br /><br />

                        Enter Name of Asset: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                            placeholder="Enter Name*" onChange={this.addEventHandler.bind(this)} variant="standard" autoComplete='off' /><br /><br />

                        Enter Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                            placeholder="Enter Name*" onChange={this.addEventHandler.bind(this)} variant="standard" autoComplete='off' /><br /><br />

                        Enter Name of Type of Asset:&nbsp;
                        <FormControl style={{ m: 1, width: "30%" }} size="small">
                            <InputLabel id="demo-simple-select-label">Type Of Asset*</InputLabel>
                            <Select
                                labelId="Name"
                                id="Name"
                                error={this.state.tempType === ''}
                                value={this.state.tempType}
                                label="Name"
                                onChange={this.addTypeChange.bind(this)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value="Non-current assets">Non-Current Asset</MenuItem>
                                <MenuItem value="Current assets">Current Asset</MenuItem>
                            </Select>
                        </FormControl><br /><br />

                        Enter Name of Type of Subcategory:&nbsp;
                        <FormControl style={{ m: 1, width: "30%" }} size="small">
                            <InputLabel id="demo-simple-select-label">Sub-Category*</InputLabel>
                            <Select
                                labelId="Name"
                                id="Name"
                                error={this.state.tempTypeInName === ''}
                                value={this.state.tempTypeInName}
                                label="Name"
                                onChange={this.addNameTypeChange.bind(this)}
                                MenuProps={MenuProps}
                            >
                                {this.state.totalQuestions.map((x, i) =>
                                    x.type === this.state.tempType &&
                                    this.state.totalQuestions[i].subCategory.map((y, j) =>
                                        <MenuItem value={y.nameInType}>{y.nameInType}</MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl><br /><br />


                        Enter Category Title: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="category"
                            placeholder="Enter Category" onChange={this.addEventHandler.bind(this)} variant="standard" autoComplete='off' /><br /><br />

                        <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%" }} onClick={this.addQuestion.bind(this)}>ADD NEW QUESTION</AddButton>


                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">

                            <br /><br />
                            <div align="center"><b>Balance sheet of {data[chartState.selected].name} as at {data[chartState.selected].date}</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top" }}>Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amt.<br />(₹)</StyledTableCell>
                                            <StyledTableCell align="center">Amt.<br />(₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell><b>Assets :</b></StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell><b>(1) Non-current assets :</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].totalQuestions[0].subCategory.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell>
                                                    ({i + 1}) {x.nameInType} {x.subCategory.length === 1 && x.subCategory[0].type === "-" && <span>({x.subCategory[0].name})</span>}<br />
                                                    {x.subCategory.length === 1 && x.subCategory[0].type !== "-" && <span>&emsp;&emsp;[1] ({x.subCategory[0].type}) ({x.subCategory[0].name})</span>}
                                                    {x.subCategory.length > 1 &&
                                                        <span>
                                                            {x.subCategory.map((y, j) => (
                                                                <span>
                                                                    &emsp;&emsp;[{j + 1}] {y.type !== '-' && y.type + " ("} {y.name} {y.type !== '-' && ")"}<br />
                                                                </span>
                                                            ))}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                    {x.subCategory.length === 1 && x.subCategory[0].type === "-" && <span>{x.subCategory[0].amount}</span>}<br />
                                                    {x.subCategory.length === 1 && x.subCategory[0].type !== "-" && <span>{x.subCategory[0].amount}</span>}
                                                    {x.subCategory.length > 1 &&
                                                        <span>
                                                            {x.subCategory.map((y, j) => (
                                                                <span>
                                                                    {x.subCategory[j].amount}<br />
                                                                </span>
                                                            ))}
                                                        </span>
                                                    }
                                                    {x.subCategory.length === 0 && '-'}
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>
                                                    {i === data[chartState.selected].totalQuestions[0].subCategory.length - 1 &&
                                                        data[chartState.selected].NCATotal
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell><b>(2) Current assets :</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].totalQuestions[1].subCategory.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell>
                                                    ({i + 1}) {x.nameInType} {x.subCategory.length === 1 && x.subCategory[0].type === "-" && <span>({x.subCategory[0].name})</span>}<br />
                                                    {x.subCategory.length === 1 && x.subCategory[0].type !== "-" && <span>&emsp;&emsp;[1] ({x.subCategory[0].type}) ({x.subCategory[0].name})</span>}
                                                    {x.subCategory.length > 1 &&
                                                        <span>
                                                            {x.subCategory.map((y, j) => (
                                                                <span>
                                                                    &emsp;&emsp;[{j + 1}] {y.type !== '-' && y.type + " ("} {y.name} {y.type !== '-' && ")"}<br />
                                                                </span>
                                                            ))}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                    {x.subCategory.length === 1 && x.subCategory[0].type === "-" && <span>{x.subCategory[0].amount}</span>}<br />
                                                    {x.subCategory.length === 1 && x.subCategory[0].type !== "-" && <span>{x.subCategory[0].amount}</span>}
                                                    {x.subCategory.length > 1 &&
                                                        <span>
                                                            {x.subCategory.map((y, j) => (
                                                                <span>
                                                                    {x.subCategory[j].amount}<br />
                                                                </span>
                                                            ))}
                                                        </span>
                                                    }
                                                    {x.subCategory.length === 0 && '-'}
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>
                                                    {i === data[chartState.selected].totalQuestions[1].subCategory.length - 1 &&
                                                        data[chartState.selected].CATotal
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="left">&emsp;&emsp;&emsp;&emsp;<b>Total</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].NCATotal + data[chartState.selected].CATotal}</b></StyledTableCell>
                                        </TableRow>


                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                            {/* <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Number of defects<br />per television set<br />(<i>x</i>)</StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top" }}>Tally Marks</StyledTableCell>
                                            <StyledTableCell align="center">Number of <br /> television sets<br />(<i>f</i>)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].UniqueArray.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align='center'>{x.number}</StyledTableCell>
                                                <StyledTableCell align='center'>{x.tally}</StyledTableCell>
                                                <StyledTableCell align='center'>{x.total}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'><b>Total</b></StyledTableCell>
                                            <StyledTableCell align='center'><b>{data[chartState.selected].sampleStringLength}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br /> */}
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button, Switch, touchRippleClasses } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
//ILLUSTRATION 4

export default function ComparativeProfitLossStatement() {

    const data = [{
        "questionPallete": [
            {
                "questionType": "purchase",
                "context": {
                    "type": "multipurchase",
                    "name": [
                        "cash",
                        "stock",
                        "furniture"
                    ],
                    "amount": [
                        10000,
                        10000,
                        5000
                    ],
                    "totalAmount": 25000
                },
                "date": 1
            },
            {
                "questionType": "purchase",
                "context": {
                    "type": "nodiscount",
                    "name": "Mathura traders",
                    "amount": 5000,
                    "billno": "-",
                    "paid": {
                        "type": false
                    }
                },
                "date": 3
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "sales",
                    "name": "-",
                    "amount": 4000,
                    "tradeDiscount": "-",
                    "cashDiscount": "-",
                    "order": false,
                    "purchasedFrom": "-",
                    "invoiceNo": "-",
                    "credit": "-",
                    "extraSum": "-",
                    "extraSumReason": "-",
                    "totalAmount": 4000
                },
                "date": 4
            },
            {
                "questionType": "purchase",
                "context": {
                    "type": "nodiscount",
                    "name": "Rushikesh",
                    "amount": 8000,
                    "billno": "-",
                    "paid": {
                        "type": true,
                        "amount": 4000
                    }
                },
                "date": 6
            },
            {
                "questionType": "purchaseReturns",
                "context": {
                    "type": "purchaseReturns",
                    "name": "Mathura traders",
                    "amount": 1600,
                    "debitNoteNo": "-",
                    "saleReturn": {
                        "istrue": false
                    }
                },
                "date": 7
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "others",
                    "reason": "નો માલ વડાપ્રધાન રાહત ફંડમાં આપ્યો તેના",
                    "name": "ધર્માદા ખર્ચ",
                    "amount": 600
                },
                "date": 9
            },
            {
                "questionType": "purchase",
                "context": {
                    "type": "fixedAsset",
                    "name": "-",
                    "amount": 450,
                    "billno": "-",
                    "assetName": "Electronic weight machine",
                    "paid": {
                        "type": false
                    }
                },
                "date": 10
            },
            {
                "questionType": "purchase",
                "context": {
                    "type": "sharePurchase",
                    "name": "Shri Barsa Ltd.",
                    "shares": 100,
                    "amount": 30,
                    "brokerage": 2,
                    "paid": {
                        "type": false
                    },
                    "totalAmount": 3060
                },
                "date": 15
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "sales",
                    "name": "KedarNath",
                    "amount": 4000,
                    "tradeDiscount": "-",
                    "cashDiscount": "-",
                    "order": false,
                    "purchasedFrom": "-",
                    "invoiceNo": "-",
                    "credit": "-",
                    "extraSum": "-",
                    "extraSumReason": "-",
                    "totalAmount": 4000
                },
                "date": 18
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "others",
                    "reason": "નો માલ આગથી નાશ પામ્યો તેના",
                    "name": "આગથી થયેલ નુક્સાન",
                    "amount": 1500
                },
                "date": 20
            },
            {
                "questionType": "salesReturns",
                "context": {
                    "type": "salesReturns",
                    "name": "Kedarnath",
                    "originalAmount": 4000,
                    "amount": 2000,
                    "creditNoteNo": "-",
                    "rebate": false
                },
                "date": 22
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "others",
                    "reason": "નો માલ અંગત વપરાશ માટે લઈ ગયા તેના",
                    "name": "ઉપાડ",
                    "amount": 500
                },
                "date": 25
            },
            {
                "questionType": "purchase",
                "context": {
                    "type": "fixedAsset",
                    "name": "Shree Bahuchar Furniture Mart",
                    "amount": 3000,
                    "billno": "-",
                    "assetName": "furniture",
                    "paid": {
                        "type": false
                    }
                },
                "date": 28
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "sales",
                    "name": "Chandni",
                    "amount": 1000,
                    "tradeDiscount": 20,
                    "cashDiscount": "-",
                    "order": false,
                    "purchasedFrom": "-",
                    "invoiceNo": "-",
                    "credit": "-",
                    "extraSum": 50,
                    "extraSumReason": "carraiges",
                    "totalAmount": 1200
                },
                "date": 30
            }
        ],
        "totalAmount": 59960,
        "bookOwnerName": "Gokul Traders",
        "month": "April",
        "year": 2014
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const AlphabetRegex = new RegExp(/^[a-zA-Z]+$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                bookOwnerGender: "Shri",
                bookOwnerName: "Gokul Traders",
                month: "April",
                year: 2014,
                questionPallete: [
                    {//1
                        questionType: "purchase",
                        context: {
                            type: "multipurchase",
                            name: ["cash", "stock", "furniture"],
                            amount: [10000, 10000, 5000]
                        },
                        date: 1
                    },
                    {//2
                        questionType: "purchase",
                        context: {
                            type: "nodiscount",
                            name: "Mathura traders",
                            amount: 5000,
                            billno: "-",
                            paid: {
                                type: false,
                            }
                        },
                        date: 3
                    },
                    {//3
                        questionType: "sales",
                        context: {
                            type: "sales",
                            name: "-",
                            amount: 4000,
                            tradeDiscount: "-",
                            cashDiscount: "-",
                            order: false,
                            purchasedFrom: "-",
                            invoiceNo: "-",
                            credit: "-",
                            extraSum: "-",
                            extraSumReason: "-"
                        },
                        date: 4
                    },
                    {//4
                        questionType: "purchase",
                        context: {
                            type: "nodiscount",
                            name: "Rushikesh",
                            amount: 8000,
                            billno: "-",
                            paid: {
                                type: true,
                                amount: 4000
                            }
                        },
                        date: 6
                    },
                    {//5
                        questionType: "purchaseReturns",
                        context: {
                            type: "purchaseReturns",
                            name: "Mathura traders",
                            amount: 1600,
                            debitNoteNo: "-",
                            saleReturn: {
                                istrue: false,
                            }
                        },
                        date: 7
                    },
                    {//6
                        questionType: "sales",
                        context: {
                            type: "others",
                            reason: "આપ્યો",
                            name: "વડાપ્રધાનનાં રાહતફંડમાં",
                            amount: 600
                        },
                        date: 9
                    },
                    {//7
                        questionType: "purchase",
                        context: {
                            type: "fixedAsset",
                            name: "-",
                            amount: 450,
                            billno: "-",
                            assetName: "ઇલેક્ટ્રોનિક તોલ મશીન",
                            paid: {
                                type: false
                            }
                        },
                        date: 10
                    },
                    {//8
                        questionType: "purchase",
                        context: {
                            type: "sharePurchase",
                            name: "Shri Barsa Ltd.",
                            shares: 100,
                            amount: 30,
                            brokerage: 2,
                            paid: {
                                type: false
                            }
                        },
                        date: 15
                    },
                    {//9
                        questionType: "sales",
                        context: {
                            type: "sales",
                            name: "KedarNath",
                            amount: 4000,
                            tradeDiscount: "-",
                            cashDiscount: "-",
                            order: false,
                            purchasedFrom: "-",
                            invoiceNo: "-",
                            credit: "-",
                            extraSum: "-",
                            extraSumReason: "-"
                        },
                        date: 18
                    },
                    {//10
                        questionType: "sales",
                        context: {
                            type: "others",
                            reason: "નાશ પામ્યો",
                            name: "આગથી",
                            amount: 1500
                        },
                        date: 20
                    },
                    {//11
                        questionType: "salesReturns",
                        context: {
                            type: "salesReturns",
                            name: "Kedarnath",
                            originalAmount: 4000,
                            amount: 2000,
                            creditNoteNo: "-",
                            rebate: false
                        },
                        date: 22
                    },
                    {//12
                        questionType: "sales",
                        context: {
                            type: "others",
                            reason: "લઈ ગયા",
                            name: "અંગત વપરાશ માટે",
                            amount: 500
                        },
                        date: 25
                    },
                    {//13
                        questionType: "purchase",
                        context: {
                            type: "fixedAsset",
                            name: "Shree Bahuchar Furniture Mart",
                            amount: 3000,
                            billno: "-",
                            assetName: "furniture",
                            paid: {
                                type: false
                            }
                        },
                        date: 28
                    },
                    {//9
                        questionType: "sales",
                        context: {
                            type: "sales",
                            name: "Chandni",
                            amount: 1000,
                            tradeDiscount: 20,
                            cashDiscount: "-",
                            order: false,
                            purchasedFrom: "-",
                            invoiceNo: "-",
                            credit: "-",
                            extraSum: 50,
                            extraSumReason: "carraiges"
                        },
                        date: 30
                    },
                ],

                addQuestionType: "none",
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempReason: '',
                tempShares: '',
                tempBrokerage: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                editModequestionPalette: [
                    {//1
                        nameMultiple: [false, false, false],
                        amountMultiple: [false, false, false]
                    },
                    {//2
                        name: false,
                        amount: false,
                        billno: false
                    },
                    {//3
                        name: false,
                        amount: false,
                        tradeDiscount: false,
                        invoiceNo: false,
                        credit: false,
                    },
                    {//4
                        name: false,
                        amount: false,
                        billno: false,
                    },
                    {//5
                        amount: false,
                    },
                    {//6
                        name: false,
                        amount: false,
                        reason: false
                    },
                    {//7
                        amount: false,
                        assetName: false,
                        name: false
                    },
                    {//8
                        name: false,
                        shares: false,
                        amount: false,
                        brokerage: false
                    },
                    {//9
                        name: false,
                        amount: false,
                    },
                    {//10
                        name: false,
                        amount: false,
                        reason: false
                    },
                    {//11
                        amount: false
                    },
                    {//12
                        name: false,
                        amount: false,
                        reason: false
                    },
                    {//13
                        name: false,
                        amount: false,
                        assetName: false
                    },
                    {//9
                        name: false,
                        amount: false,
                    },
                ],


                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModemonth: false,
                editModeyear: false,

                addQuestionChecker: false,


                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
            this.nameSetter = this.nameSetter.bind(this)
            this.removeQuestion = this.removeQuestion.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (event.target.value !== '-') {
                if (customArray.length === 3) {
                    switch (customArray[0]) {
                        case 'tradeDiscount':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "purchase")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'discount':
                                    this.state.questionPallete[customArray[2]].context.discount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'billno':
                                    this.state.questionPallete[customArray[2]].context.billno = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'cashDiscount':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "purchase")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'discount':
                                    this.state.questionPallete[customArray[2]].context.discount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'credit':
                                    this.state.questionPallete[customArray[2]].context.credit = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'memo':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'fixedAsset':
                            switch (customArray[1]) {
                                case 'assetName':
                                    this.state.questionPallete[customArray[2]].context.assetName = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'billno':
                                    this.state.questionPallete[customArray[2]].context.billno = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'nodiscount':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "purchase")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'billno':
                                    this.state.questionPallete[customArray[2]].context.billno = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'sharePurchase':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'shares':
                                    this.state.questionPallete[customArray[2]].context.shares = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'brokerage':
                                    this.state.questionPallete[customArray[2]].context.brokerage = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'sales':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "sales")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'tradeDiscount':
                                    this.state.questionPallete[customArray[2]].context.tradeDiscount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'cashDiscount':
                                    this.state.questionPallete[customArray[2]].context.cashDiscount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'credit':
                                    this.state.questionPallete[customArray[2]].context.credit = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'invoiceNo':
                                    this.state.questionPallete[customArray[2]].context.invoiceNo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'purchaseReturns':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'percentageReturn':
                                    this.state.questionPallete[customArray[2]].context.percentageReturn = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debitNoteNo':
                                    this.state.questionPallete[customArray[2]].context.debitNoteNo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'salesReturns':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'creditNoteNo':
                                    this.state.questionPallete[customArray[2]].context.creditNoteNo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'cashPurchaseSales':
                            switch (customArray[1]) {
                                case 'cashPurchase':
                                    this.state.questionPallete[customArray[2]].context.cashPurchase = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'cashSales':
                                    this.state.questionPallete[customArray[2]].context.cashSales = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'order':
                            switch (customArray[1]) {
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "preOrder")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'others':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'reason':
                                    this.state.questionPallete[customArray[2]].context.reason = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'multipurchase':
                            let customInput = customArray[1].split("-")
                            switch (customInput[0]) {
                                case 'amountMultiple':
                                    this.state.questionPallete[customArray[2]].context.amount[parseInt(customInput[1])] = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;

                                case 'nameMultiple':
                                    this.state.questionPallete[customArray[2]].context.name[parseInt(customInput[1])] = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                    }
                }

                switch (event.target.name) {
                    case 'bookOwnerName':
                        this.setState({
                            bookOwnerName: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'year':
                        this.setState({
                            year: event.target.value
                        }, () => { this.fillDetails() })
                        break;

                }
            }
        }

        nameSetter(name, newName, type) {
            if (type === 'purchase') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "purchaseReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            if (type === 'sales') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "order" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }

            if (type === 'preOrder') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "sales" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            this.setState({})
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'date':
                    this.setState({
                        tempDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'name':
                    this.setState({
                        tempName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amount':
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'reason':
                    this.setState({
                        tempReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'shares':
                    this.setState({
                        tempShares: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'brokerage':
                    this.setState({
                        tempBrokerage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'discount':
                    this.setState({
                        tempDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Tdiscount':
                    this.setState({
                        tempTDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Cdiscount':
                    this.setState({
                        tempCDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'purchasedFrom':
                    this.setState({
                        tempPurchasedFrom: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'billno':
                    this.setState({
                        tempBillno: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'credit':
                    this.setState({
                        tempCredit: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPercentage':
                    this.setState({
                        tempAmountPercentage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'invoiceNo':
                    this.setState({
                        tempInvoiceNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'debitNoteNo':
                    this.setState({
                        tempDebitNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'creditNoteNo':
                    this.setState({
                        tempCreditNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPaidImmediately':
                    this.setState({
                        tempAmountPaidImmediately: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'memo':
                    this.setState({
                        tempMemo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'assetName':
                    this.setState({
                        tempAssetName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmount':
                    this.setState({
                        tempExtraSum: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmountReason':
                    this.setState({
                        tempExtraSumReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashPurchase':
                    this.setState({
                        tempCashPurchase: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashSales':
                    this.setState({
                        tempCashSales: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            switch (this.state.addQuestionType) {
                case 'tradeDiscount':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '' && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) && this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'cashDiscount':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '' && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) && this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'memo':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'fixedAsset':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempAssetName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'nodiscount':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '' && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'sharePurchase':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempShares !== '' && NumberRegex.test(this.state.tempShares) &&
                        this.state.tempBrokerage !== '' && NumberRegex.test(this.state.tempBrokerage) && parseFloat(this.state.tempBrokerage) > 0 && parseFloat(this.state.tempBrokerage) < 100
                    ) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'sales':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&

                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        !isNaN(this.state.tempTDiscount)) {
                        if (this.state.tempTDiscount !== '') {
                            if (parseFloat(this.state.tempTDiscount) > 0 && parseFloat(this.state.tempTDiscount) < 100) {
                                this.setState({
                                    addQuestionChecker: true
                                })
                            } else {
                                this.setState({
                                    addQuestionChecker: false
                                })
                            }
                        } else {
                            this.setState({
                                addQuestionChecker: true
                            })
                        }
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'purchaseReturns':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'salesReturns':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '' && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'order':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'cashPurchaseSales':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32) {
                        if (this.state.tempCashPurchase !== '' || this.state.tempCashSales !== '') {
                            this.setState({
                                addQuestionChecker: true
                            })
                        } else {
                            this.setState({
                                addQuestionChecker: false
                            })
                        }
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'multipurchase':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempAmount !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'others':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempReason !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;

            }
        }

        genderChange(event) {
            this.setState({
                bookOwnerGender: event.target.value
            })
        }

        monthChange(event) {
            this.setState({
                month: event.target.value
            })
        }

        questionTypeChange(event) {
            this.setState({
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempReason: '',
                tempShares: '',
                tempBrokerage: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                addQuestionChecker: false,
                addQuestionType: event.target.value
            })
        }

        addNameChange(event) {
            this.setState({
                tempName: event.target.value
            })
        }

        addReturneeNameChange(event) {
            this.setState({
                tempReturneeName: event.target.value
            })
        }

        purchaseFromNameChange(event) {
            this.setState({
                tempPurchasedFrom: event.target.value
            })
        }

        rebateChange(event) {
            this.setState({
                tempRebate: event.target.value
            })
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.questionPallete) {
                switch (i.context.type) {
                    case 'fixedAsset':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '' ||
                            i.context.assetName === '') {
                            flag = false
                        }
                        break;
                    case 'nodiscount':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '') {
                            flag = false
                        }
                        break;
                    case "sales":
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '') {
                            flag = false
                        }
                        if (i.context.tradeDiscount !== '-') {
                            if (i.context.tradeDiscount === '' || !NumberRegex.test(i.context.tradeDiscount) || parseFloat(i.context.tradeDiscount) < 0 || parseFloat(i.context.tradeDiscount) > 100) {
                                flag = false
                            }
                        }
                        break;
                    case 'purchaseReturns':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'salesReturns':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'others':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.reason === '' ||
                            i.context.name === ''
                        ) {
                            flag = false
                        }
                        break
                    case 'sharePurchase':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.shares === '' || !NumberRegex.test(i.context.shares) ||
                            i.context.brokerage === '' || !NumberRegex.test(i.context.brokerage) || parseFloat(i.context.brokerage) < 0 || parseFloat(i.context.brokerage) > 100 ||
                            i.context.name === ''
                        ) {
                            flag = false
                        }
                        break;
                    case 'multipurchase':
                        for (let j in i.context.name) {
                            if (i.context.name[j] === '') {
                                flag = false
                            }
                            if (i.context.amount[j] === '' || !NumberRegex.test(i.context.amount[j])) {
                                flag = false
                            }
                        }
                        break;
                }
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            let totalAmount = 0

            for (let i of this.state.questionPallete) {
                // totalAmount += parseFloat(i.context.amount)
                if (typeof (i.context.amount) === 'object') {
                    for (let j in i.context.amount) {
                        totalAmount += parseFloat(i.context.amount[j])
                    }
                } else if (i.context.shares) {
                    totalAmount += (parseFloat(i.context.amount) * parseFloat(i.context.shares)) + (parseFloat(i.context.amount) * parseFloat(i.context.shares) * parseFloat(i.context.brokerage) / 100)
                    i.context.totalAmount = (parseFloat(i.context.amount) * parseFloat(i.context.shares)) + (parseFloat(i.context.amount) * parseFloat(i.context.shares) * parseFloat(i.context.brokerage) / 100)
                } else if (i.context.type === 'sales') {
                    if (i.context.tradeDiscount !== '-' && i.context.extraSum !== '-') {
                        totalAmount += parseFloat(i.context.amount) + (parseFloat(i.context.amount) * parseFloat(i.context.tradeDiscount) / 100) + parseFloat(i.context.extraSum)
                        i.context.totalAmount = parseFloat(i.context.amount) + (parseFloat(i.context.amount) * parseFloat(i.context.tradeDiscount) / 100)
                    } else if (i.context.extraSum !== '-') {
                        totalAmount += parseFloat(i.context.amount) + parseFloat(i.context.extraSum)
                        i.context.totalAmount = parseFloat(i.context.amount)
                    } else if (i.context.tradeDiscount !== '-') {
                        totalAmount += parseFloat(i.context.amount) + (parseFloat(i.context.amount) * parseFloat(i.context.tradeDiscount) / 100)
                        i.context.totalAmount = parseFloat(i.context.amount) + (parseFloat(i.context.amount) * parseFloat(i.context.tradeDiscount) / 100)
                    } else {
                        totalAmount += parseFloat(i.context.amount)
                        i.context.totalAmount = parseFloat(i.context.amount)
                    }
                } else {
                    totalAmount += parseFloat(i.context.amount)
                }

                if (typeof (i.context.amount) === 'object') {
                    i.context.totalAmount = i.context.amount.reduce((a, b) => parseFloat(a) + parseFloat(b))
                }
            }

            // console.log(JSON.stringify(this.state.questionPallete,null,4))


            // console.log(JSON.stringify(this.state.questionPallete, null, 4))

            // let dataObject = JSON.parse(JSON.stringify(this.state.questionPallete))

            let dataObject = {
                questionPallete: this.state.questionPallete,
                totalAmount: totalAmount,
                bookOwnerName: this.state.bookOwnerName,
                month: this.state.month,
                year: this.state.year
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.clear()
            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addQuestion() {
            let adderFlag = true

            let temporaryTemplate = {
                questionType: this.state.addQuestionType,
                date: parseFloat(this.state.tempDate),
                assetName: this.state.tempAssetName,
                name: this.state.tempName,
                amount: this.state.tempAmount,
                reason: this.state.tempReason,
                shares: this.state.tempShares,
                brokerage: this.state.tempBrokerage,
                amountPercentage: this.state.tempAmountPercentage,
                discount: this.state.tempDiscount,
                tradeDiscount: this.state.tempTDiscount,
                cashDiscount: this.state.tempCDiscount,
                purchasedFrom: this.state.tempPurchasedFrom,
                billno: this.state.tempBillno,
                invoiceNo: this.state.tempInvoiceNo,
                debitNoteNo: this.state.tempDebitNoteNo,
                creditNoteNo: this.state.tempCreditNoteNo,
                credit: this.state.tempCredit,
                memo: this.state.tempMemo,
                extraSum: this.state.tempExtraSum,
                extraSumReason: this.state.tempExtraSumReason,
                cashPurchase: this.state.tempCashPurchase,
                cashSales: this.state.tempCashSales,
                rebate: this.state.tempRebate,
                returneeName: this.state.tempReturneeName,

                amountPaidImmediately: this.state.tempAmountPaidImmediately
            }
            let questionObject
            let editModequestionObject

            switch (temporaryTemplate.questionType) {
                case 'tradeDiscount':

                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            discount: temporaryTemplate.discount,
                            billno: "-",
                            paid: {
                                type: false
                            },

                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        discount: false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.billno)) {
                        questionObject.context.billno = temporaryTemplate.billno
                        editModequestionObject.billno = false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.amountPaidImmediately)) {
                        questionObject.context.paid.type = true
                        questionObject.context.paid.amount = temporaryTemplate.amountPaidImmediately
                    }
                    break;
                case 'cashDiscount':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            discount: temporaryTemplate.discount,
                            credit: "-",
                            paid: {
                                type: false
                            },

                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        discount: false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.credit)) {
                        questionObject.context.credit = temporaryTemplate.credit
                        editModequestionObject.credit = false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.amountPaidImmediately)) {
                        questionObject.context.paid.type = true
                        questionObject.context.paid.amount = temporaryTemplate.amountPaidImmediately
                    }
                    break;
                case 'memo':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            amount: temporaryTemplate.amount,
                            no: "-",
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        amount: false,
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.memo)) {
                        questionObject.context.no = temporaryTemplate.memo
                    }
                    break;
                case 'fixedAsset':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: "-",
                            amount: temporaryTemplate.amount,
                            assetName: temporaryTemplate.assetName,
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        assetName: false
                    }

                    if (temporaryTemplate.name !== '') {
                        questionObject.context.name = temporaryTemplate.name
                    }
                    break;
                case 'nodiscount':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            paid: {
                                type: false
                            },

                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.amountPaidImmediately)) {
                        questionObject.context.paid.type = true
                        questionObject.context.paid.amount = temporaryTemplate.amountPaidImmediately
                    }
                    break;
                case 'multipurchase':
                    let newNameArray = temporaryTemplate.name.split(", ")
                    let newAmountArray = temporaryTemplate.amount.split(", ")

                    editModequestionObject = {
                        nameMultiple: [],
                        amountMultiple: [],
                    }

                    for (let i in newNameArray) {
                        if (newNameArray[i] === undefined) {
                            newNameArray[i] = "new item"
                        }
                        if (newAmountArray[i] === undefined || !NumberRegex.test(newAmountArray[i])) {
                            newAmountArray[i] = 0
                        }
                    }
                    for (let i in newAmountArray) {
                        if (newNameArray[i] === undefined) {
                            newNameArray[i] = "new item"
                        }
                        if (newAmountArray[i] === undefined || !NumberRegex.test(newAmountArray[i])) {
                            newAmountArray[i] = 0
                        }
                    }
                    for (let i in newNameArray) {
                        editModequestionObject.nameMultiple.push(false)
                        editModequestionObject.amountMultiple.push(false)
                    }

                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: newNameArray,
                            amount: newAmountArray,
                        },
                        date: temporaryTemplate.date
                    }

                    console.log(questionObject)

                    // adderFlag = false
                    break;
                case 'sharePurchase':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            shares: temporaryTemplate.shares,
                            amount: temporaryTemplate.amount,
                            brokerage: temporaryTemplate.brokerage
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        shares: false,
                        brokerage: false
                    }
                    break;
                case 'others':
                    questionObject = {
                        questionType: "sales",
                        context: {
                            type: temporaryTemplate.questionType,
                            reason: temporaryTemplate.reason,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        discount: false
                    }

                    break;
                case 'sales':
                    questionObject = {
                        questionType: "sales",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: "-",
                            amount: temporaryTemplate.amount,
                            tradeDiscount: "-",
                            cashDiscount: "-",
                            order: false,
                            purchasedFrom: "-",
                            invoiceNo: "-",
                            credit: "-",
                            extraSum: "-",
                            extraSumReason: "-"
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                    }

                    if (temporaryTemplate.name !== '') {
                        questionObject.context.name = temporaryTemplate.name
                    }
                    if (NormalNumberRegex.test(temporaryTemplate.tradeDiscount)) {
                        questionObject.context.tradeDiscount = temporaryTemplate.tradeDiscount
                        editModequestionObject.tradeDiscount = false
                    }
                    if (NormalNumberRegex.test(temporaryTemplate.extraSum)) {
                        questionObject.context.extraSum = temporaryTemplate.extraSum
                    }
                    if (AlphabetRegex.test(temporaryTemplate.extraSumReason)) {
                        questionObject.context.extraSumReason = temporaryTemplate.extraSumReason
                    }
                    break;
                case 'purchaseReturns':

                    for (let i of this.state.questionPallete) {
                        if (i.context.name === temporaryTemplate.name) {
                            if (i.date >= temporaryTemplate.date) {
                                adderFlag = false
                            }
                        }
                    }

                    questionObject = {
                        questionType: "purchaseReturns",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            debitNoteNo: "-",
                            saleReturn: {
                                istrue: false
                            }
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        amount: false
                    }

                    break;
                case 'salesReturns':
                    questionObject = {
                        questionType: "salesReturns",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            rebate: temporaryTemplate.rebate
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        amount: false
                    }

                    for (let i of this.state.questionPallete) {
                        if (i.questionType === "sales") {
                            if (i.context.name === temporaryTemplate.name) {
                                if (i.date >= temporaryTemplate.date) {
                                    adderFlag = false
                                } else {
                                    questionObject.context.originalAmount = i.context.amount
                                }
                            }
                        }
                    }

                    break;
                case 'order':
                    questionObject = {
                        questionType: "order",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        amount: false
                    }

                    for (let i of this.state.questionPallete) {
                        if (i.date > temporaryTemplate.date) {
                            if (i.context.name === temporaryTemplate.name) {
                                i.context.order = true
                            }
                        }
                    }
                    break;
                case 'cashPurchaseSales':
                    questionObject = {
                        questionType: "order",
                        context: {
                            type: temporaryTemplate.questionType,
                            cashPurchase: "-",
                            cashSales: "-"
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.cashPurchase)) {
                        questionObject.context.cashPurchase = temporaryTemplate.cashPurchase
                        editModequestionObject.cashPurchase = false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.cashSales)) {
                        questionObject.context.cashSales = temporaryTemplate.cashSales
                        editModequestionObject.cashSales = false
                    }

                    break;
            }

            if (adderFlag === true) {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].date >= temporaryTemplate.date) {
                        this.state.questionPallete.splice(i, 0, questionObject)
                        this.state.editModequestionPalette.splice(i, 0, editModequestionObject)
                        break;
                    }
                }
            }


            this.setState({})

            // console.log(JSON.stringify(questionObject,null,4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            let questionType = this.state.questionPallete[event].context.type
            let questionName = this.state.questionPallete[event].context.name

            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPalette.splice(event, 1)

            if(questionType === 'sales'){
                for(let i in this.state.questionPallete){
                    if(this.state.questionPallete[i].context.type === 'salesReturns'){
                        this.state.questionPallete.splice(i,1)
                        this.state.editModequestionPalette.splice(i,1)
                    }
                }
            }
            if(questionType === 'nodiscount'){
                for(let i in this.state.questionPallete){
                    if(this.state.questionPallete[i].context.type === 'purchaseReturns'){
                        this.state.questionPallete.splice(i,1)
                        this.state.editModequestionPalette.splice(i,1)
                    }
                }
            }

            this.setState({})
        }


        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 3) {
                switch (customArray[0]) {
                    case 'tradeDiscount':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'discount':
                                this.state.editModequestionPalette[customArray[2]].discount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'billno':
                                this.state.editModequestionPalette[customArray[2]].billno = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'cashDiscount':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'discount':
                                this.state.editModequestionPalette[customArray[2]].discount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'credit':
                                this.state.editModequestionPalette[customArray[2]].credit = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'memo':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'fixedAsset':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'assetName':
                                this.state.editModequestionPalette[customArray[2]].assetName = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'billno':
                                this.state.editModequestionPalette[customArray[2]].billno = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'nodiscount':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'billno':
                                this.state.editModequestionPalette[customArray[2]].billno = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break
                    case 'sharePurchase':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'shares':
                                this.state.editModequestionPalette[customArray[2]].shares = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'brokerage':
                                this.state.editModequestionPalette[customArray[2]].brokerage = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'sales':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'tradeDiscount':
                                this.state.editModequestionPalette[customArray[2]].tradeDiscount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'cashDiscount':
                                this.state.editModequestionPalette[customArray[2]].cashDiscount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'credit':
                                this.state.editModequestionPalette[customArray[2]].credit = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'invoiceNo':
                                this.state.editModequestionPalette[customArray[2]].invoiceNo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'purchaseReturns':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'salesReturns':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'creditNoteNo':
                                this.state.editModequestionPalette[customArray[2]].creditNoteNo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'cashPurchaseSales':
                        switch (customArray[1]) {
                            case 'cashPurchase':
                                this.state.editModequestionPalette[customArray[2]].cashPurchase = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'cashSales':
                                this.state.editModequestionPalette[customArray[2]].cashSales = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'order':
                        switch (customArray[1]) {
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'others':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'reason':
                                this.state.editModequestionPalette[customArray[2]].reason = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'multipurchase':
                        let customInput = customArray[1].split("-")
                        switch (customInput[0]) {
                            case 'amountMultiple':
                                this.state.editModequestionPalette[customArray[2]].amountMultiple[parseInt(customInput[1])] = true
                                this.setState({
                                    editModeButton: true
                                })
                                console.log(customInput)
                                break;
                            case 'nameMultiple':
                                this.state.editModequestionPalette[customArray[2]].nameMultiple[parseInt(customInput[1])] = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                }
            }

            switch (event.target.name) {
                case 'bookOwnerGender':
                    this.setState({
                        editModebookOwnerGender: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'bookOwnerName':
                    this.setState({
                        editModebookOwnerName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'year':
                    this.setState({
                        editModeyear: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'month':
                    this.setState({
                        editModemonth: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {

            for (let i of this.state.editModequestionPalette) {
                if (i.name) {
                    i.name = false
                }
                if (i.amount) {
                    i.amount = false
                }
                if (i.discount) {
                    i.discount = false
                }
                if (i.billno) {
                    i.billno = false
                }
                if (i.credit) {
                    i.credit = false
                }
                if (i.assetName) {
                    i.assetName = false
                }
                if (i.tradeDiscount) {
                    i.tradeDiscount = false
                }
                if (i.cashDiscount) {
                    i.cashDiscount = false
                }
                if (i.invoiceNo) {
                    i.invoiceNo = false
                }
                if (i.percentageReturn) {
                    i.percentageReturn = false
                }
                if (i.debitNoteNo) {
                    i.debitNoteNo = false
                }
                if (i.creditNoteNo) {
                    i.creditNoteNo = false
                }
                if (i.cashPurchase) {
                    i.cashPurchase = false
                }
                if (i.cashSales) {
                    i.cashSales = false
                }
                if (i.reason) {
                    i.reason = false
                }
                if (i.nameMultiple) {
                    for (let j in i.nameMultiple) {
                        i.nameMultiple[j] = false
                    }
                }
                if (i.amountMultiple) {
                    for (let j in i.amountMultiple) {
                        i.amountMultiple[j] = false
                    }
                }
                if (i.shares) {
                    i.shares = false
                }
                if (i.brokerage) {
                    i.brokerage = false
                }
            }


            this.setState({
                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModemonth: false,
                editModeyear: false,

                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                    <br /><br />

                        {this.state.editModebookOwnerName ? <TextField style={{ 'margin-top': "-15px", }} name="bookOwnerName" id='outlined-base' error={this.state.bookOwnerName === ''} 
                            value={this.state.bookOwnerName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="bookOwnerName" onClick={this.buttonMaker.bind(this)}> {this.state.bookOwnerName}</a>}ના નીચેના વ્યવહારોની આમનોંધ લખો.
                        <br /><br />
                        માસ :&nbsp;
                        {this.state.editModemonth ?
                            <FormControl style={{ width: "20%" }} size="small">
                                <InputLabel id="demo-simple-select-label">માસ</InputLabel>
                                <Select
                                    labelId="month"
                                    id="month"
                                    value={this.state.month}
                                    label="Month"
                                    onChange={this.monthChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"January"}>જાન્યુઆરી</MenuItem>
                                    <MenuItem value={"February"}>ફેબ્રુઆરી</MenuItem>
                                    <MenuItem value={"March"}>માર્ચ</MenuItem>
                                    <MenuItem value={"April"}>એપ્રિલ</MenuItem>
                                    <MenuItem value={"May"}>મે</MenuItem>
                                    <MenuItem value={"June"}>જૂન</MenuItem>
                                    <MenuItem value={"July"}>જુલાઈ</MenuItem>
                                    <MenuItem value={"August"}>ઓગસ્ટ</MenuItem>
                                    <MenuItem value={"September"}>સપ્ટેમ્બર</MenuItem>
                                    <MenuItem value={"October"}>ઓક્ટોબર</MenuItem>
                                    <MenuItem value={"November"}>નવેમ્બર</MenuItem>
                                    <MenuItem value={"December"}>ડિસેમ્બર</MenuItem>
                                </Select>
                            </FormControl> :
                            <a className='txt' name="month" onClick={this.buttonMaker.bind(this)}> {this.state.month}</a>
                        }
                        &nbsp;&nbsp;વર્ષ :&nbsp;
                        {this.state.editModeyear ? <TextField style={{ 'margin-top': "-15px", }} name="year" id='outlined-base' error={this.state.year === '' || isNaN(this.state.year) || parseInt(this.state.year) < 1000 || parseInt(this.state.year) > 9000}
                            value={this.state.year} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="year" onClick={this.buttonMaker.bind(this)}> {this.state.year}</a>}.
                        <br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)} >સંપાદિત મોડ બંધ</ColorButton> : <span></span>}

                        <br /><br />
                        તારીખ<br/><br/>
                        <div label="totalQuestionsInArray">
                            {this.state.questionPallete.map((x, i) => (
                                <div>
                                    <span>{x.date}</span>&emsp;&emsp;
                                    {x.context.type === 'tradeDiscount' &&
                                        <span>
                                            Purchased goods of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["tradeDiscount", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            from&nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["tradeDiscount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            at&nbsp;
                                            {this.state.editModequestionPalette[i].discount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "discount", i]} id='outlined-base' error={x.context.discount === '' || isNaN(x.context.discount) || x.context.discount < 0 || x.context.discount > 100}
                                                value={x.context.discount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["tradeDiscount", "discount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.discount}</a>} %&nbsp;


                                            trade discount
                                            {x.context.paid.type === true &&
                                                <span>
                                                    &nbsp;and {parseFloat(x.context.amount) / 2 === parseFloat(x.context.paid.amount) ? "half " : <span>₹ {x.context.paid.amount}</span>} of the amount paid immediately
                                                </span>
                                            }.&nbsp;
                                            {x.context.billno !== '-' &&
                                                <span>Bill no.&nbsp;
                                                    {this.state.editModequestionPalette[i].billno ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "billno", i]} id='outlined-base' error={x.context.billno === '' || isNaN(x.context.billno)}
                                                        value={x.context.billno} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["tradeDiscount", "billno", i]} onClick={this.buttonMaker.bind(this)}> {x.context.billno}</a>}.&nbsp;
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'cashDiscount' &&
                                        <span>
                                            Purchased goods of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["cashDiscount", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            from&nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["cashDiscount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            at&nbsp;
                                            {this.state.editModequestionPalette[i].discount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "discount", i]} id='outlined-base' error={x.context.discount === '' || isNaN(x.context.discount) || x.context.discount < 0 || x.context.discount > 100}
                                                value={x.context.discount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["cashDiscount", "discount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.discount}</a>} %&nbsp;


                                            cash discount
                                            {x.context.paid.type === true &&
                                                <span>
                                                    &nbsp;and {parseFloat(x.context.amount) / 2 === parseFloat(x.context.paid.amount) ? "half " : <span>₹ {x.context.paid.amount}</span>} of the amount paid immediately
                                                </span>
                                            }.&nbsp;
                                            {x.context.credit !== '-' &&
                                                <span>Credit&nbsp;
                                                    {this.state.editModequestionPalette[i].credit ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["cashDiscount", "credit", i]} id='outlined-base' error={x.context.credit === '' || isNaN(x.context.credit)}
                                                        value={x.context.credit} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["cashDiscount", "credit", i]} onClick={this.buttonMaker.bind(this)}> {x.context.credit}</a>} months.&nbsp;
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'memo' &&
                                        <span>
                                            Purchased goods of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["memo", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["memo", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}.&nbsp;
                                            {x.context.no !== '-' ? <span>Cash memo no. {x.context.no}.</span> : "Cash Memo"}
                                        </span>
                                    }
                                    {x.context.type === 'multipurchase' &&
                                        <span>
                                            &nbsp;
                                            {x.context.name.map((y, j) => (
                                                <span>
                                                    ₹&nbsp;
                                                    {this.state.editModequestionPalette[i].amountMultiple[j] ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["multipurchase", "amountMultiple-" + j, i]} id='outlined-base' error={x.context.amount[j] === '' || isNaN(x.context.amount[j])}
                                                        value={x.context.amount[j]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["multipurchase", "amountMultiple-" + j, i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount[j]}</a>} &nbsp;
                                                        
                                                    {this.state.editModequestionPalette[i].nameMultiple[j] ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["multipurchase", "nameMultiple-" + j, i]} id='outlined-base' error={x.context.name[j] === ''}
                                                        value={x.context.name[j]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["multipurchase", "nameMultiple-" + j, i]} onClick={this.buttonMaker.bind(this)}> {x.context.name[j]}</a>} &nbsp;
                                                    

                                                    

                                                    {/* {x.context.amount[j]} */}

                                                    {parseInt(j) !== x.context.name.length - 1 ? parseInt(j) !== x.context.name.length - 2 ? ", " : " અને " : ""}
                                                    &nbsp;
                                                </span>
                                            ))}
                                            લાવી ધંધો શરૂ કર્યો.
                                        </span>
                                    }
                                    {x.context.type === 'fixedAsset' &&
                                        <span>
                                            
                                            {x.context.name === '-' && <span>દુકાન માટે&nbsp;
                                            {this.state.editModequestionPalette[i].assetName ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "assetName", i]} id='outlined-base' error={x.context.assetName === ''}
                                                value={x.context.assetName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "assetName", i]} onClick={this.buttonMaker.bind(this)}> {x.context.assetName}</a>}&nbsp;

                                            ખરીદ્યું ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;</span>}

                                            {x.context.name !== '-' && <span>&nbsp;
                                                {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "30%" }} name={["fixedAsset", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                    value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["fixedAsset", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;માંથી&nbsp;
                                                ₹&nbsp;
                                                {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}નું&nbsp;
                                                    
                                                {this.state.editModequestionPalette[i].assetName ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "assetName", i]} id='outlined-base' error={x.context.assetName === ''}
                                                value={x.context.assetName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "assetName", i]} onClick={this.buttonMaker.bind(this)}> {x.context.assetName}</a>}&nbsp;ખરીદ્યું</span>}.

                                        </span>
                                    }
                                    {x.context.type === 'nodiscount' &&
                                        <span>
                                            
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["nodiscount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}
                                                માંથી&nbsp;

                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["nodiscount", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount} નો માલ ખરીદી</a>}&nbsp;

                                            

                                            {x.context.paid.type === true &&
                                                <span>
                                                    &nbsp; {parseFloat(x.context.amount) / 2 === parseFloat(x.context.paid.amount) ? "અડધાં " : <span>₹ {x.context.paid.amount}</span>} નાણાં રોકડેથી ચૂકવ્યા
                                                </span>
                                            }.&nbsp;

                                        </span>
                                    }
                                    {x.context.type === 'sharePurchase' &&
                                        <span>
                                             {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sharePurchase", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sharePurchase", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;
                                                નાં શેર નંગ
                                            {this.state.editModequestionPalette[i].shares ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sharePurchase", "shares", i]} id='outlined-base' error={x.context.shares === '' || isNaN(x.context.shares)}
                                                value={x.context.shares} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sharePurchase", "shares", i]} onClick={this.buttonMaker.bind(this)}> {x.context.shares}</a>}&nbsp;દર&nbsp;

                                            
                                           

                                            ₹&nbsp;

                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sharePurchase", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sharePurchase", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            ના ભાવે ખરીદ્યા.&nbsp;
                                            દલાલી&nbsp;
                                            {this.state.editModequestionPalette[i].brokerage ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sharePurchase", "brokerage", i]} id='outlined-base' error={x.context.brokerage === '' || isNaN(x.context.brokerage) || x.context.brokerage < 0 || x.context.brokerage > 100}
                                                value={x.context.brokerage} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sharePurchase", "brokerage", i]} onClick={this.buttonMaker.bind(this)}> {x.context.brokerage}</a>}&nbsp;

                                            % લેખે ચૂકવી.&nbsp;બંને ચેકથી ચૂકવી.
                                        </span>
                                    }
                                    {x.context.type === 'sales' &&
                                        <span>
                                            {x.context.purchasedFrom !== '-' ?
                                                <span>The entire goods purchased from {x.context.purchasedFrom} of ₹&nbsp;
                                                </span>
                                                : ""}

                                           
                                            {x.context.name === '-' ? <span> 
                                            રોકડેથી માલ વેચ્યો ₹&nbsp;                                               
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sales", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}
                                            </span> : ""}                                   
                            
                                            {x.context.name !== '-' ? <span> 
                                            {x.context.purchasedFrom !== '-' && "sold "}
                                                {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                    value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["sales", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name} </a>}
                                                ને&nbsp;
                                                {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sales", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}&nbsp;</a>} નો માલ વેચ્યો&nbsp;
                                            
                                            </span> : ""}
                                            {x.context.tradeDiscount !== '-' &&
                                                <span> 
                                                    તે&nbsp;
                                                    {this.state.editModequestionPalette[i].tradeDiscount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "tradeDiscount", i]} id='outlined-base' error={x.context.tradeDiscount === '' || isNaN(x.context.tradeDiscount) || x.context.tradeDiscount < 0 || x.context.tradeDiscount > 100}
                                                        value={x.context.tradeDiscount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["sales", "tradeDiscount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.tradeDiscount}</a>} % નફો ચઢાવીને વેચ્યો&nbsp;
                                                </span>
                                            }

                                            {/* {x.context.tradeDiscount !== '-' ? x.context.cashDiscount !== '-' ? "at " : "at " : ""} */}

                                            {/* {x.context.tradeDiscount !== '-' ? x.context.cashDiscount !== '-' ? " and" : "" : ""} */}
                                            {/* {x.context.cashDiscount !== '-' &&
                                                <span>&nbsp;
                                                    {this.state.editModequestionPalette[i].cashDiscount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "cashDiscount", i]} id='outlined-base' error={x.context.cashDiscount === '' || isNaN(x.context.cashDiscount) || x.context.cashDiscount < 0 || x.context.cashDiscount > 100}
                                                        value={x.context.cashDiscount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["sales", "cashDiscount", i]} onClick={this.buttonMaker.bind(this)}>{x.context.cashDiscount}</a>} % cash discount
                                                </span>
                                            }  */}
                                            {x.context.order && "for the pre-order"}.
                                            {x.context.extraSum !== '-' &&
                                                <span>&nbsp;
                                                    તેમનાં વતી લારીભાડાના ₹ {x.context.extraSum} ચૂકવ્યા.
                                                </span>
                                            }&nbsp;

                                            {x.context.credit !== '-' &&
                                                <span>Credit period of&nbsp;
                                                    {this.state.editModequestionPalette[i].credit ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "credit", i]} id='outlined-base' error={x.context.credit === '' || isNaN(x.context.credit)}
                                                        value={x.context.credit} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["sales", "credit", i]} onClick={this.buttonMaker.bind(this)}>{x.context.credit}</a>} months.&nbsp;
                                                </span>
                                            }

                                            {x.context.invoiceNo !== '-' && <span>Invoice no :&nbsp;
                                                {this.state.editModequestionPalette[i].invoiceNo ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "invoiceNo", i]} id='outlined-base' error={x.context.invoiceNo === '' || isNaN(x.context.invoiceNo)}
                                                    value={x.context.invoiceNo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["sales", "invoiceNo", i]} onClick={this.buttonMaker.bind(this)}> {x.context.invoiceNo}</a>}.&nbsp;</span>}
                                        </span>
                                    }
                                    {x.context.type === 'purchaseReturns' &&
                                        <span>
                                            

                                            {/* {x.context.saleReturn.istrue ?
                                                <span>
                                                    {x.context.saleReturn.returneeName} returned&nbsp;
                                                    {this.state.editModequestionPalette[i].percentageReturn ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "percentageReturn", i]} id='outlined-base' error={x.context.percentageReturn === '' || isNaN(x.context.percentageReturn) || x.context.percentageReturn < 0 || x.context.percentageReturn > 100}
                                                        value={x.context.percentageReturn} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["purchaseReturns", "percentageReturn", i]} onClick={this.buttonMaker.bind(this)}>{parseFloat(x.context.percentageReturn) === 50 ? "half" : x.context.percentageReturn}</a>} {parseFloat(x.context.percentageReturn) !== 50 && "%"} of the goods and the same were&nbsp;

                                                </span> : */}
                                            
                                            {/* } */}
                                            {x.context.name}ને&nbsp;
                                            ₹&nbsp;
                                            <span>
                                                {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                    value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["purchaseReturns", "amount", i]} onClick={this.buttonMaker.bind(this)}>{x.context.amount}</a>} નો માલ પરત કર્યો.&nbsp;
                                            </span>

                                            {/* {x.context.debitNoteNo !== '-' &&
                                                <span>
                                                    Debit note no.&nbsp;
                                                    {this.state.editModequestionPalette[i].debitNoteNo ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "debitNoteNo", i]} id='outlined-base' error={x.context.debitNoteNo === '' || isNaN(x.context.debitNoteNo)}
                                                        value={x.context.debitNoteNo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["purchaseReturns", "debitNoteNo", i]} onClick={this.buttonMaker.bind(this)}>{x.context.debitNoteNo}</a>} sent.
                                                </span>
                                            } */}
                                        </span>
                                    }
                                    {x.context.type === 'salesReturns' &&
                                        <span>
                                            કેદારનાથ પાસેથી અડધો માલ પરત આવ્યો
                                            {/* ₹&nbsp;

                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["salesReturns", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["salesReturns", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            of goods were returned by {x.context.name} */}

                                            {x.context.rebate === true && " and it was agreed to give rebate of carriage in proportion"}.&nbsp;

                                            {x.context.creditNoteNo !== '-' &&
                                                <span>
                                                    Credit note no.&nbsp;
                                                    {this.state.editModequestionPalette[i].creditNoteNo ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "creditNoteNo", i]} id='outlined-base' error={x.context.creditNoteNo === '' || isNaN(x.context.creditNoteNo)}
                                                        value={x.context.creditNoteNo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["purchaseReturns", "creditNoteNo", i]} onClick={this.buttonMaker.bind(this)}>{x.context.creditNoteNo}</a>} sent.
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'order' &&
                                        <span>
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["order", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["order", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;
                                            placed a pre-order for supplying goods.
                                        </span>
                                    }
                                    {x.context.type === 'cashPurchaseSales' &&
                                        <span>
                                            {x.context.cashPurchase !== '-' &&
                                                <span>Cash Purchase ₹&nbsp;
                                                    {this.state.editModequestionPalette[i].cashPurchase ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["cashPurchaseSales", "cashPurchase", i]} id='outlined-base' error={x.context.cashPurchase === '' || isNaN(x.context.cashPurchase)}
                                                        value={x.context.cashPurchase} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["cashPurchaseSales", "cashPurchase", i]} onClick={this.buttonMaker.bind(this)}> {x.context.cashPurchase}</a>
                                                    }
                                                </span>
                                            }

                                            {x.context.cashPurchase !== '-' ? x.context.cashSales !== '-' ? " and " : " " : " "}

                                            {x.context.cashSales !== '-' &&
                                                <span>Cash Sales ₹&nbsp;
                                                    {this.state.editModequestionPalette[i].cashSales ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["cashPurchaseSales", "cashSales", i]} id='outlined-base' error={x.context.cashSales === '' || isNaN(x.context.cashSales)}
                                                        value={x.context.cashSales} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["cashPurchaseSales", "cashSales", i]} onClick={this.buttonMaker.bind(this)}> {x.context.cashSales}</a>
                                                    }
                                                </span>
                                            }.
                                        </span>
                                    }
                                    {x.context.type === 'others' &&
                                        <span>
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "40%" }} name={["others", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["others", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;
                                            ₹

                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["others", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["others", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;
                                            નો માલ&nbsp;

                                            {this.state.editModequestionPalette[i].reason ? <TextField style={{ marginTop: "-15px", width: "40%" }} name={["others", "reason", i]} id='outlined-base' error={x.context.reason === ''}
                                                value={x.context.reason} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["others", "reason", i]} onClick={this.buttonMaker.bind(this)}> {x.context.reason}</a>}&nbsp;

                                            

                                        </span>
                                    }

                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeQuestion.bind(this, i)} name="-">-</IconButton>

                                    <br /><br />
                                </div>
                            ))}
                        </div>

                        <div label="addQuestionInputs">
                        નવો પ્રશ્ન ઉમેરો :<br /><br /><br />
                        પ્રશ્નનો પ્રકાર: 
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">પ્રશ્ન</InputLabel>
                                <Select
                                    labelId="question"
                                    id="question"
                                    value={this.state.addQuestionType}
                                    label="Question"
                                    onChange={this.questionTypeChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"none"}>None</MenuItem>
                                    <MenuItem value={"nodiscount"}>ખરીદી - સરળ</MenuItem>
                                    <MenuItem value={"multipurchase"}>ખરીદી - બહુવિધ વસ્તુઓ</MenuItem>
                                    <MenuItem value={"fixedAsset"}>ખરીદી - વસ્તુ</MenuItem>
                                    <MenuItem value={"sharePurchase"}>ખરીદી - શેર</MenuItem>
                                    <MenuItem value={"others"}>ખરીદી - અન્ય</MenuItem>
                                    <MenuItem value={"sales"}>વેચાણ - સરળ</MenuItem>
                                    <MenuItem value={"purchaseReturns"}>ખરીદી - રીટર્ન</MenuItem>
                                    <MenuItem value={"salesReturns"}>વેચાણ - વળતર</MenuItem>
                                    {/* <MenuItem value={"tradeDiscount"}>Purchase - Trade Discount</MenuItem>
                                    <MenuItem value={"cashDiscount"}>Purchase - Cash Discount</MenuItem>
                                    <MenuItem value={"memo"}>Purchase - Cash Memo</MenuItem>
                                    <MenuItem value={"fixedAsset"}>Purchase - Fixed Assets</MenuItem>
                                    <MenuItem value={"nodiscount"}>Purchase - No Discount</MenuItem>
                                    <MenuItem value={"sales"}>Sales</MenuItem>
                                    <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem>
                                    <MenuItem value={"salesReturns"}>Sales Returns</MenuItem>
                                    <MenuItem value={"order"}>Pre-Order Placement</MenuItem>
                                    <MenuItem value={"cashPurchaseSales"}>Cash Purchase/Sales</MenuItem>                                    <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                </Select>
                            </FormControl><br /><br /><br />


                            {this.state.addQuestionType === 'tradeDiscount' &&
                                <span>
                                    Enter Date: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="Enter Date*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Name: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="Enter Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Discount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="discount" error={this.state.tempDiscount === '' || isNaN(this.state.tempDiscount) || this.state.tempDiscount < 0 || this.state.tempDiscount > 100}
                                        placeholder="Enter Discount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Bill no.(Optional): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="billno" error={isNaN(this.state.tempBillno)}
                                        placeholder="Enter Bill no." onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Was Amount Paid Immediately?(Optional): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="Enter Amount Paid" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'cashDiscount' &&
                                <span>
                                    Enter Date: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="Enter Date*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Name: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="Enter Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Discount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="discount" error={this.state.tempDiscount === '' || isNaN(this.state.tempDiscount) || this.state.tempDiscount < 0 || this.state.tempDiscount > 100}
                                        placeholder="Enter Discount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Credit months(Optional): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="credit" error={isNaN(this.state.tempCredit)}
                                        placeholder="Enter Months" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Was Amount Paid Immediately?(Optional): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="Enter Amount Paid" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'memo' &&
                                <span>
                                    Enter Date: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="Enter Date*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Memo no.(Optional): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="memo" error={isNaN(this.state.tempCredit)}
                                        placeholder="Enter Months" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Was Amount Paid Immediately?(Optional): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="Enter Amount Paid" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'fixedAsset' &&
                                <span>
                                    Enter Date: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="Enter Date*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Name: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="assetName" error={this.state.tempAssetName === ''}
                                        placeholder="Enter Asset Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Asset Name: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="Enter Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Amount: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Amount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'nodiscount' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ તરત જ ચૂકવવામાં આવી હતી?(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="ચૂકવેલ રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'multipurchase' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામો દાખલ કરોs: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામો દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === ''}
                                        placeholder="રકમ દાખલ કરોt*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    (અલ્પવિરામ અને સ્પેસ દ્વારા અલગ કરાયેલા નામ અને રકમ દાખલ કરો e.g.:- harsh, dimple)<br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'sharePurchase' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    શેર દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="shares" error={this.state.tempShares === '' || isNaN(this.state.tempShares)}
                                        placeholder="શેરની સંખ્યા દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    બ્રોકરેજ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="brokerage" error={this.state.tempBrokerage === '' || isNaN(this.state.tempBrokerage) || this.state.tempBrokerage < 0 || this.state.tempBrokerage > 100}
                                        placeholder="બ્રોકરેજ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'sales' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name"
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    વધારાની રકમની ટકાવારી દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="Tdiscount" error={isNaN(this.state.tempTDiscount) || this.state.tempTDiscount < 0 || this.state.tempTDiscount > 100}
                                        placeholder="ટ્રેડ ડિસ્કાઉન્ટ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    વધારાની ખરીદીની રકમ: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="extraAmount" error={isNaN(this.state.tempExtraSum)}
                                        placeholder="વધારાની રકમ" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    વધારાની ખરીદીની રકમનું કારણ: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="extraAmountReason"
                                        placeholder="વધારાની રકમનું કારણ" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'others' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ક્રિયા દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="reason" error={this.state.tempReason === ''}
                                        placeholder="ક્રિયા દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /> (E.g.: Withdrawn for)<br /><br />

                                    કારણ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="કારણ દાખલ કરોt*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /> (E.g.: Personal Use)<br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'purchaseReturns' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "tradeDiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "cashDiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))} */}
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "nodiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    પરત કરેલ રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'salesReturns' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "sales" && y.context.name !== '-' && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === "order" &&
                                <span>
                                    Enter Date: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="Enter Date*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Name: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="Enter Name*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'cashPurchaseSales' &&
                                <span>
                                    Enter Date: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="Enter Date*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Cash Purchase: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="cashPurchase" error={isNaN(this.state.tempCashPurchase)}
                                        placeholder="Enter Amount" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Cash Sales: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="cashSales" error={isNaN(this.state.tempCashSales)}
                                        placeholder="Enter Amount" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }

                            {this.state.addQuestionType !== 'none' && <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%" }} onClick={this.addQuestion.bind(this)}>નવો પ્રશ્ન ઉમેરો</AddButton>}
                        </div>

                        <br /><br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px", fontSize:"20px" }} align='right' onClick={this.editModeOff.bind(this)} >સંપાદિત મોડ બંધ કરો</ColorButton> : <span></span>}


                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો/છુપાવો</AddButton>
                        </div>
                        <div align='right'>
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                        <br />
                        <br />

                    </div>
                    <br /><br />
                    {
                        !this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>જવાબ. :</b><br /><br />
                            <div align="center"><b>{data[chartState.selected].bookOwnerName} ની આમનોંધ</b></div><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{  fontSize:"20px" }}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="center" style={{  fontSize:"20px" }}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{  fontSize:"20px" }}>ઉધાર (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{  fontSize:"20px" }}>જમા (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center' style={{  fontSize:"20px" }}>{data[chartState.selected].year}</StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].questionPallete.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align='center' style={{ verticalAlign: "top", fontSize:"20px"}}>{parseInt(i) === 0 && data[chartState.selected].month}&nbsp; <span style={{ float: 'right' }}>{x.date}</span></StyledTableCell>
                                                <StyledTableCell align='left' style={{  fontSize:"20px" }}>
                                                    {x.context.type === 'multipurchase' &&
                                                        <span>
                                                            {x.context.name.map((y, j) => (
                                                                <span>{y} ખાતે <span style={{ float: 'right' }}>ઉ</span> <br /></span>
                                                            ))}
                                                            &emsp;&emsp;તે મૂડી ખાતે<br />
                                                            [બા.જે. :&nbsp;
                                                            {x.context.name.map((y, j) => (
                                                                <span>
                                                                    {y} ₹ {x.context.amount[j]}

                                                                    {parseInt(j) !== x.context.name.length - 1 ? parseInt(j) !== x.context.name.length - 2 ? ", " : " તથા " : " લાવી ધંધો શરૂ કર્યો તેના."}
                                                                </span>
                                                            ))}
                                                            ]
                                                        </span>
                                                    }

                                                    {x.context.type === 'nodiscount' &&
                                                        <span>
                                                            ખરીદ ખાતે <span style={{ float: 'right' }}>Dr.</span><br />
                                                            {x.context.paid.type === true &&
                                                                <span>
                                                                    &emsp;&emsp;તે રોકડ ખાતે <br />
                                                                    &emsp;&emsp;તે {x.context.name} ખાતે
                                                                </span>
                                                            }
                                                            {x.context.paid.type === false &&
                                                                <span>
                                                                    &emsp;&emsp;તે {x.context.name} ખાતે
                                                                </span>
                                                            }<br />

                                                            [બા.જે. : ₹ {x.context.amount}નો માલ ખરીદ્યો
                                                            {x.context.paid.type === true &&
                                                                <span>&nbsp;
                                                                    અને {parseFloat(x.context.amount) / parseFloat(x.context.paid.amount) === 2 ? "અડધા નાણાં" : x.context.paid.amount} રોકડા ચૂકવ્યા તેના
                                                                </span>
                                                            }
                                                            .]
                                                        </span>
                                                    }

                                                    {x.context.type === 'sales' &&
                                                        <span>
                                                            {x.context.name === '-' ? "રોકડ" : x.context.name} ખાતે <span style={{ float: 'right' }}>ઉ</span><br />
                                                            &emsp;&emsp;તે વેચાણ ખાતે<br />
                                                            {x.context.extraSum !== '-' && <span>&emsp;&emsp;તે રોકડ ખાતે <br /></span>}

                                                            [બા.જે. : ₹ {x.context.amount}  {x.context.name === '-' ? "રોકડેથી માલ વેચ્યો તેના" : "નો માલ " + x.context.name + "ને શાખ પર વેચ્યો તેના " }&nbsp;
                                                            {x.context.tradeDiscount !== '-' ? x.context.extraSum !== "-" ? "પર " : "after adding " : ""}
                                                            {x.context.tradeDiscount !== '-' && x.context.tradeDiscount + " % નફો ચઢાવી વેચ્યો "}
                                                            {x.context.tradeDiscount !== '-' ? x.context.extraSum !== "-" ? "તથા " : "" : ""}
                                                            {x.context.extraSum !== '-' && <span>તેમના વતી લારીભાડાનાં ચૂકવ્યા તેના</span>}
                                                            ]
                                                        </span>
                                                    }

                                                    {x.context.type === 'purchaseReturns' &&
                                                        <span>
                                                            {x.context.name} ખાતે <span style={{ float: 'right' }}>ઉ</span><br />
                                                            &emsp;&emsp;તે ખરીદમાલ-પરત ખાતે <br />
                                                            [બા.જે. : ₹ {x.context.amount} ખરીદેલ માલ {x.context.name} ને પરત કર્યા તેના.]
                                                        </span>
                                                    }
                                                    {x.context.type === 'others' &&
                                                        <span>
                                                            {x.context.name} ખાતે <span style={{ float: 'right' }}>ઉ</span><br />
                                                            &emsp;&emsp;તે ખરીદ ખાતે<br />
                                                            [બા.જે. : ₹ {x.context.amount} {x.context.reason}.]
                                                        </span>
                                                    }
                                                    {x.context.type === 'fixedAsset' &&
                                                        <span>
                                                            ડેડસ્ટોક ખાતે <span style={{ float: 'right' }}>ઉ</span><br />
                                                            &emsp;&emsp;તે રોકડ ખાતે<br />
                                                            {x.context.name === '-' &&
                                                            <span>
                                                            [બા.જે. : {x.context.assetName} ₹ {x.context.amount} માં ખરીદ્યું.]
                                                            </span>
                                                            }
                                                            {x.context.name !== '-' &&
                                                                <span>
                                                                    [બા.જે. : ₹ {x.context.amount} નું {x.context.name} માંથી શાખ પર ખરીદ્યું તેના.]
                                                                </span>
                                                            }

                                                            
                                                        </span>
                                                    }
                                                    {x.context.type === 'sharePurchase' &&
                                                        <span>
                                                            {x.context.name} ના શેર રોકાણ ખાતે <span style={{ float: 'right' }}>ઉ</span><br />
                                                            &emsp;&emsp;તે બૅન્ક ખાતે<br />
                                                            [બા.જે. : {x.context.brokerage} %  દલાલી સહિત {x.context.shares} શેર ₹ {x.context.amount} લેખે ચેકથી ખરીદ્યા તેના.]
                                                        </span>
                                                    }
                                                    {x.context.type === 'salesReturns' &&
                                                        <span>
                                                            વેચાણમાલ પરત ખાતે <span style={{ float: 'right' }}>ઉ</span><br />
                                                            &emsp;&emsp;તે {x.context.name} ખાતે<br />
                                                            [બા.જે. : ₹ {x.context.amount} નો વેચેલમાલ {x.context.name} પાસેથી પરત આવ્યો તેના.]
                                                        </span>
                                                    }


                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x.context.type === 'multipurchase' &&
                                                        <span>
                                                            {x.context.amount.map((y, j) => (
                                                                <span>{y} <br /></span>
                                                            ))}
                                                        </span>
                                                    }
                                                    {x.context.type === 'nodiscount' &&
                                                        <span>
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'sales' &&
                                                        <span>
                                                            {x.context.tradeDiscount !== '-' ?
                                                                <span>{parseFloat(x.context.totalAmount) + parseFloat(x.context.extraSum)}</span> :
                                                                <span>{x.context.amount}</span>
                                                            }
                                                        </span>
                                                    }
                                                    {x.context.type === 'purchaseReturns' &&
                                                        <span>
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'others' &&
                                                        <span>
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'fixedAsset' &&
                                                        <span>
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'sharePurchase' &&
                                                        <span>
                                                            {x.context.totalAmount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'salesReturns' &&
                                                        <span>
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x.context.type === 'multipurchase' &&
                                                        <span>
                                                            {x.context.amount.map((y, j) => (
                                                                <span><br /></span>
                                                            ))}
                                                            {x.context.totalAmount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'nodiscount' &&
                                                        <span>
                                                            <br />
                                                            {x.context.paid.type ?
                                                                <span>
                                                                    {x.context.paid.amount}<br />
                                                                    {parseFloat(x.context.amount) - parseFloat(x.context.paid.amount)}
                                                                </span> :
                                                                <span>
                                                                    {x.context.amount}
                                                                </span>
                                                            }
                                                        </span>
                                                    }
                                                    {x.context.type === 'sales' &&
                                                        <span>
                                                            <br />
                                                            {x.context.tradeDiscount !== '-' ?
                                                                <span>{x.context.totalAmount}</span> :
                                                                <span>{x.context.amount}</span>
                                                            }<br />
                                                            {x.context.extraSum !== '-' && x.context.extraSum}
                                                        </span>
                                                    }
                                                    {x.context.type === 'purchaseReturns' &&
                                                        <span>
                                                            <br />
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'others' &&
                                                        <span>
                                                            <br />
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'fixedAsset' &&
                                                        <span>
                                                            <br />
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'sharePurchase' &&
                                                        <span>
                                                            <br />
                                                            {x.context.totalAmount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'salesReturns' &&
                                                        <span>
                                                            <br />
                                                            {x.context.amount}
                                                        </span>
                                                    }

                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='right' style={{  fontSize:"20px" }}><b>કુલ</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].totalAmount}</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].totalAmount}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />


                        </div>
                    }
                    <br />
                    <br />
                </div >
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {

    const data = [{
        "standard": {
            "first": 1,
            "last": 5
        },
        "startingDate": {
            "first": 1,
            "last": 5
        },
        "totalDates": [
            "2009-10",
            "2010-11",
            "2011-12",
            "2012-13",
            "2013-14",
            "2014-15",
            "2015-16",
            "2016-17",
            "2017-18"
        ],
        "students": [
            {
                "dropoutRate": 3.24
            },
            {
                "dropoutRate": 2.98
            },
            {
                "dropoutRate": 2.29
            },
            {
                "dropoutRate": 2.2
            },
            {
                "dropoutRate": 2.09
            },
            {
                "dropoutRate": 2.07
            },
            {
                "dropoutRate": 2.04
            }
        ],
        "valuesOfT": [
            {
                "t": 1,
                "t2": 1,
                "ty": 3.24
            },
            {
                "t": 2,
                "t2": 4,
                "ty": 5.96
            },
            {
                "t": 3,
                "t2": 9,
                "ty": 6.87
            },
            {
                "t": 4,
                "t2": 16,
                "ty": 8.8
            },
            {
                "t": 5,
                "t2": 25,
                "ty": 10.45
            },
            {
                "t": 6,
                "t2": 36,
                "ty": 12.42
            },
            {
                "t": 7,
                "t2": 49,
                "ty": 14.28
            }
        ],
        "valuesOfTTotal": {
            "y": 16.91,
            "t": 28,
            "t2": 140,
            "ty": 62.02
        },
        "answerPalette": {
            "tWithABar": 4,
            "yWithBar": 2.42,
            "b": -0.2,
            "a": 3.22,
            "t1": 1.62,
            "t2": 1.42
        },
        "n": 7
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)
    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                standard: {
                    first: 1,
                    last: 5
                },
                startingDate: 2009,
                totalDates: ["2009-10", "2010-11", "2011-12", "2012-13", "2013-14", "2014-15", "2015-16", "2016-17", "2017-18"],
                students: [
                    {
                        dropoutRate: 3.24,
                    }, {
                        dropoutRate: 2.98,
                    }, {
                        dropoutRate: 2.29,
                    }, {
                        dropoutRate: 2.20,
                    }, {
                        dropoutRate: 2.09,
                    }, {
                        dropoutRate: 2.07,
                    }, {
                        dropoutRate: 2.04
                    }
                ],

                editModestandard: {
                    first: false,
                    last: false
                },
                editModestartingDate: false,
                editModestudents: [
                    {
                        dropoutRate: false
                    }, {
                        dropoutRate: false
                    }, {
                        dropoutRate: false
                    }, {
                        dropoutRate: false
                    }, {
                        dropoutRate: false
                    }, {
                        dropoutRate: false
                    }, {
                        dropoutRate: false
                    }
                ],
                editModedensityPopulation: false,

                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'dropoutRate':
                        this.state.students[customArray[1]].dropoutRate = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'standardFirst':
                    this.state.standard.first = event.target.value
                    this.setState({}, () => { this.fillDetails() })
                    break;
                case 'standardLast':
                    this.state.standard.last = event.target.value
                    this.setState({}, () => { this.fillDetails() })
                    break;
                case 'startingDate':
                    this.state.startingDate = event.target.value
                    for (let i in this.state.totalDates) {
                        this.state.totalDates[i] = String((parseInt(event.target.value) + parseInt(i))) + "-" + String(parseInt(event.target.value) + parseInt(i) + 1).slice(2, 4)
                    }
                    this.setState({
                        editModeButton: true
                    })
                    break;
            }
        }

        fillDetails() {
            let flag = true

            if(!NormalNumberRegex.test(this.state.standard.first) || this.state.standard.first < 1 || this.state.standard.first > 12 || this.state.standard.first === ''){
                flag = false
            }
            if(!NormalNumberRegex.test(this.state.standard.last) || this.state.standard.last < 1 || this.state.standard.last > 12 || this.state.standard.last === ''){
                flag = false
            }
            if(!NormalNumberRegex.test(this.state.startingDate) || this.state.startingDate < 1000 || this.state.startingDate > 9000 || this.state.startingDate === ''){
                flag = false
            }
            for(let i of this.state.students){
                if(!NumberRegex.test(i.dropoutRate) || i.dropoutRate === '' || i.dropoutRate > 100 || i.dropoutRate < 0){
                    flag = false
                }
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region GREATEST COMMON DIVISOR START 

        gcd(a, b) {
            if (a == 0)
                return b;
            return this.gcd(b % a, a);
        }

        findGCD(arr, n) {
            let result = arr[0];
            for (let i = 1; i < n; i++) {
                result = this.gcd(arr[i], result);

                if (result == 1) {
                    return 1;
                }
            }
            return result;
        }

        //#endregion GREATEST COMMON DIVISOR END

        //#region BUTTON PANEL START

        onSubmit() {

            let valuesOfT = []
            let valuesOfTTotal = {
                y: 0,
                t: 0,
                t2: 0,
                ty: 0
            }

            for (let i in this.state.students) {
                valuesOfT.push({
                    t: (parseInt(i) + 1),
                    t2: (parseInt(i) + 1) * (parseInt(i) + 1),
                    ty: Math.round((parseInt(i) + 1) * parseFloat(this.state.students[i].dropoutRate) * 100) / 100
                })
                valuesOfTTotal.y += parseFloat(this.state.students[i].dropoutRate)
                valuesOfTTotal.t += (parseInt(i) + 1)
                valuesOfTTotal.t2 += (parseInt(i) + 1) * (parseInt(i) + 1)
                valuesOfTTotal.ty += Math.round((parseInt(i) + 1) * parseFloat(this.state.students[i].dropoutRate) * 100) / 100
            }

            let answerPalette = {
                tWithABar: Math.round((valuesOfTTotal.t / this.state.students.length) * 100) / 100,
                yWithBar: Math.round((valuesOfTTotal.y / this.state.students.length) * 100) / 100,
                b: Math.round(((this.state.students.length) * (valuesOfTTotal.ty) - (valuesOfTTotal.t) * (valuesOfTTotal.y)) / ((this.state.students.length) * (valuesOfTTotal.t2) - (valuesOfTTotal.t) * (valuesOfTTotal.t)) * 100) / 100
            }

            answerPalette.a = Math.round((answerPalette.yWithBar - answerPalette.b * answerPalette.tWithABar) * 100) / 100

            answerPalette.t1 = Math.round((answerPalette.a + answerPalette.b * (this.state.students.length + 1)) * 100) / 100
            answerPalette.t2 = Math.round((answerPalette.a + answerPalette.b * (this.state.students.length + 2)) * 100) / 100

            let dataObject = {
                standard: this.state.standard,
                startingDate: this.state.standard,
                totalDates: this.state.totalDates,
                students: this.state.students,
                valuesOfT: valuesOfT,
                valuesOfTTotal: valuesOfTTotal,
                answerPalette: answerPalette,
                n: this.state.students.length
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject,null,4))
        }

        onReset() {

        }

        addRemoveYear(event) {
            switch (event.target.name) {
                case '+':
                    this.state.students.push({ dropoutRate: "1.00" })
                    this.state.editModestudents.push({ dropoutRate: true })
                    this.state.totalDates.push(String((parseInt(this.state.startingDate) + this.state.totalDates.length)) + "-" + String(parseInt(this.state.startingDate) + this.state.totalDates.length + 1).slice(2, 4))
                    this.setState({ editModeButton: true })
                    break;
                case '-':
                    this.state.students.pop()
                    this.state.editModestudents.pop()
                    this.state.totalDates.pop()
                    this.setState({})
                    break;
            }
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'dropoutRate':
                        this.state.editModestudents[customArray[1]].dropoutRate = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'standardFirst':
                    this.state.editModestandard.first = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'standardLast':
                    this.state.editModestandard.last = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'startingDate':
                    this.state.editModestartingDate = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
            }
        }

        editModeOff() {
            for (let i of this.state.editModestudents) {
                i.dropoutRate = false
            }

            this.state.editModestandard.first = false
            this.state.editModestandard.last = false

            this.setState({
                editModestartingDate: false,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        <b>The dropout rate of students of standard&nbsp;
                            {
                                this.state.editModestandard.first ?
                                    <TextField style={{ 'margin-top': "-15px", width: "20%" }} name="standardFirst" id='outlined-base' error={this.state.standard.first === '' || isNaN(this.state.standard.first) || this.state.standard.first < 0 || this.state.standard.first > 12}
                                        value={this.state.standard.first} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name="standardFirst" onClick={this.buttonMaker.bind(this)}> {this.state.standard.first}</a>
                            }&nbsp;
                            to&nbsp;
                            {
                                this.state.editModestandard.last ?
                                    <TextField style={{ 'margin-top': "-15px", width: "20%" }} name="standardLast" id='outlined-base' error={this.state.standard.last === '' || isNaN(this.state.standard.last) || this.state.standard.last < 0 || this.state.standard.last > 12}
                                        value={this.state.standard.last} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name="standardLast" onClick={this.buttonMaker.bind(this)}> {this.state.standard.last}</a>
                            }&nbsp;
                            from primary schools of a district is as follows :</b>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Year</StyledTableCell>
                                        <StyledTableCell align="center">Dropout Rate</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.students.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align="center">
                                                {this.state.totalDates[i]}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModestudents[i].dropoutRate ?
                                                        <TextField style={{ 'margin-top': "-15px", width: "20%" }} name={["dropoutRate", i]} id='outlined-base' error={x.dropoutRate === '' || isNaN(x.dropoutRate) || x.dropoutRate < 0 || x.dropoutRate > 100}
                                                            value={x.dropoutRate} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["dropoutRate", i]} onClick={this.buttonMaker.bind(this)}> {x.dropoutRate}</a>
                                                }
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />
                        STARTING DATE: {
                            this.state.editModestartingDate ?
                                <TextField style={{ 'margin-top': "-15px", width: "20%" }} name="startingDate" id='outlined-base' error={this.state.startingDate === '' || isNaN(this.state.startingDate) || this.state.startingDate < 1000 || this.state.startingDate > 9000}
                                    value={this.state.startingDate} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="startingDate" onClick={this.buttonMaker.bind(this)}> {this.state.startingDate}</a>
                        }&nbsp;&nbsp;
                        ADD/REMOVE YEAR
                        <IconButton style={{ width: "3%" }} onClick={this.addRemoveYear.bind(this)} name="+">+</IconButton>
                        <IconButton disabled={this.state.students.length < 5} style={{ width: "3%" }} onClick={this.addRemoveYear.bind(this)} name="-">-</IconButton><br /><br />
                        &emsp;<b>Estimate the dropout rate for students from standard {this.state.standard.first} to {this.state.standard.last} for the years {this.state.totalDates[this.state.totalDates.length - 2]} and {this.state.totalDates[this.state.totalDates.length - 1]} by fitting a linear equation for the trend</b><br />

                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>Ans.</b><br />
                            &emsp; The data are given for <i>n</i> = {data[chartState.selected].n} years. We will thus denote the given years as <i>t</i> = 1, 2, ..., {data[chartState.selected].n} respectively.<br />
                            &emsp;Calculations for fitting linear trend
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Year</StyledTableCell>
                                            <StyledTableCell align="center">Dropout Rate <i>y</i></StyledTableCell>
                                            <StyledTableCell align="center"><i>t</i></StyledTableCell>
                                            <StyledTableCell align="center"><i>t</i><sup>2</sup></StyledTableCell>
                                            <StyledTableCell align="center"><i>ty</i></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].valuesOfT.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center">{data[chartState.selected].totalDates[i]}</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].students[i].dropoutRate}</StyledTableCell>
                                                <StyledTableCell align="center">{x.t}</StyledTableCell>
                                                <StyledTableCell align="center">{x.t2}</StyledTableCell>
                                                <StyledTableCell align="center">{x.ty}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="center"><b>Total</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].valuesOfTTotal.y}</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].valuesOfTTotal.t}</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].valuesOfTTotal.t2}</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].valuesOfTTotal.ty}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>  <br /><br />
                            <i>T</i> = <sup>Σ<i>t</i></sup>/<sub><i>n</i></sub> = <sup>{data[chartState.selected].valuesOfTTotal.t}</sup>/<sub>{data[chartState.selected].n}</sub> = {data[chartState.selected].answerPalette.tWithABar}<br />
                            ȳ = <sup>Σ<i>y</i></sup>/<sub><i>n</i></sub> = <sup>{data[chartState.selected].valuesOfTTotal.y}</sup>/<sub>{data[chartState.selected].n}</sub> = {Math.round(data[chartState.selected].valuesOfTTotal.y / data[chartState.selected].n*10000)/10000} ≃ {data[chartState.selected].answerPalette.yWithBar}<br /><br />
                            <i>b</i> = <sup>(<i>n</i>Σ<i>ty</i> - (Σ<i>t</i>)(Σ<i>y</i>))</sup>/<sub>(<i>n</i>Σ<i>t</i><sup>2</sup> - (Σ<i>t</i>)<sup>2</sup>)</sub><br />
                            &nbsp;&nbsp;&nbsp;= <sup>({data[chartState.selected].n} x {data[chartState.selected].valuesOfTTotal.ty} - {data[chartState.selected].valuesOfTTotal.t} x {data[chartState.selected].valuesOfTTotal.y}) </sup>/<sub>({data[chartState.selected].n} x {data[chartState.selected].valuesOfTTotal.t2} - ({data[chartState.selected].valuesOfTTotal.t})<sup>2</sup>)</sub><br />
                            &nbsp;&nbsp;&nbsp;= <sup>(({Math.round((data[chartState.selected].n * data[chartState.selected].valuesOfTTotal.ty) * 100) / 100}) - {Math.round((data[chartState.selected].valuesOfTTotal.t * data[chartState.selected].valuesOfTTotal.y) * 100) / 100}) </sup>/<sub>({Math.round((data[chartState.selected].n * data[chartState.selected].valuesOfTTotal.t2) * 100) / 100} - {Math.round((data[chartState.selected].valuesOfTTotal.t * data[chartState.selected].valuesOfTTotal.t) * 100) / 100})</sub><br />
                            &nbsp;&nbsp;&nbsp;= <sup>({Math.round((Math.round((data[chartState.selected].n * data[chartState.selected].valuesOfTTotal.ty) * 100) / 100 - Math.round((data[chartState.selected].valuesOfTTotal.t * data[chartState.selected].valuesOfTTotal.y) * 100) / 100) * 100) / 100}) </sup>/<sub>({Math.round((Math.round((data[chartState.selected].n * data[chartState.selected].valuesOfTTotal.t2) * 100) / 100 - Math.round((data[chartState.selected].valuesOfTTotal.t * data[chartState.selected].valuesOfTTotal.t) * 100) / 100) * 100) / 100})</sub> <br />
                            &nbsp;&nbsp;&nbsp;= {Math.round(((Math.round((Math.round((data[chartState.selected].n * data[chartState.selected].valuesOfTTotal.ty) * 100) / 100 - Math.round((data[chartState.selected].valuesOfTTotal.t * data[chartState.selected].valuesOfTTotal.y) * 100) / 100) * 100) / 100) / (Math.round((Math.round((data[chartState.selected].n * data[chartState.selected].valuesOfTTotal.t2) * 100) / 100 - Math.round((data[chartState.selected].valuesOfTTotal.t * data[chartState.selected].valuesOfTTotal.t) * 100) / 100) * 100) / 100))*10000)/10000} <br />
                            &nbsp;&nbsp;&nbsp;≃ {data[chartState.selected].answerPalette.b}<br /><br />
                            <i>a</i> = ȳ - <i>bT</i><br />
                            &nbsp;&nbsp;&nbsp;= {data[chartState.selected].answerPalette.yWithBar} - ({data[chartState.selected].answerPalette.b}) x {data[chartState.selected].answerPalette.tWithABar}<br />
                            &nbsp;&nbsp;&nbsp;= {data[chartState.selected].answerPalette.yWithBar} {data[chartState.selected].answerPalette.b < 0 && "+ " + (-1) * data[chartState.selected].answerPalette.b * data[chartState.selected].answerPalette.tWithABar}{data[chartState.selected].answerPalette.b >= 0 && "- " + data[chartState.selected].answerPalette.b * data[chartState.selected].answerPalette.tWithABar}<br />
                            &nbsp;&nbsp;&nbsp;= {data[chartState.selected].answerPalette.a}<br /><br />
                            Equation for trend ŷ = <i>a</i> + <i>bT</i><br />
                            ∴ ŷ = {data[chartState.selected].answerPalette.a} + ({data[chartState.selected].answerPalette.b})<i>T</i><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;= {data[chartState.selected].answerPalette.a} {data[chartState.selected].answerPalette.b < 0 && "- " + (-1) * data[chartState.selected].answerPalette.b}{data[chartState.selected].answerPalette.b >= 0 && "+ " + data[chartState.selected].answerPalette.b}<i>T</i><br /><br />

                            We Take <i>t</i> = {data[chartState.selected].totalDates.length - 1} for the year {data[chartState.selected].totalDates[data[chartState.selected].totalDates.length - 2]}<br />
                            ∴ ŷ = {data[chartState.selected].answerPalette.a} {data[chartState.selected].answerPalette.b < 0 && "- " + (-1) * data[chartState.selected].answerPalette.b}{data[chartState.selected].answerPalette.b >= 0 && "+ " + data[chartState.selected].answerPalette.b} x {data[chartState.selected].totalDates.length - 1}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;= {data[chartState.selected].answerPalette.a} {data[chartState.selected].answerPalette.b < 0 && "- " + (-1) * (data[chartState.selected].totalDates.length - 1) * data[chartState.selected].answerPalette.b}{data[chartState.selected].answerPalette.b >= 0 && "+ " + data[chartState.selected].answerPalette.b * (data[chartState.selected].totalDates.length - 1)}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;= {data[chartState.selected].answerPalette.t1}<br /><br />

                            We Take <i>t</i> = {data[chartState.selected].totalDates.length} for the year {data[chartState.selected].totalDates[data[chartState.selected].totalDates.length - 1]}<br />
                            ∴ ŷ = {data[chartState.selected].answerPalette.a} {data[chartState.selected].answerPalette.b < 0 && "- " + (-1) * data[chartState.selected].answerPalette.b}{data[chartState.selected].answerPalette.b >= 0 && "+ " + data[chartState.selected].answerPalette.b} x {data[chartState.selected].totalDates.length}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;= {data[chartState.selected].answerPalette.a} {data[chartState.selected].answerPalette.b < 0 && "- " + (-1) * (data[chartState.selected].totalDates.length) * data[chartState.selected].answerPalette.b}{data[chartState.selected].answerPalette.b >= 0 && "+ " + data[chartState.selected].answerPalette.b * (data[chartState.selected].totalDates.length)}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;= {data[chartState.selected].answerPalette.t2}<br/><br/>

                            Thus, the estimates of dropout rates for the students of standard {data[chartState.selected].startingDate.first} to {data[chartState.selected].startingDate.last} in this district of the years&nbsp;
                            {data[chartState.selected].totalDates[data[chartState.selected].totalDates.length - 2]} and {data[chartState.selected].totalDates[data[chartState.selected].totalDates.length - 1]} are {data[chartState.selected].answerPalette.t1} and {data[chartState.selected].answerPalette.t2} respectively
                                
                        </div>}
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

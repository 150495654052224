import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";


export default function ComparativeProfitLossStatement() {

    const data = [{
        "totalValuePalette": {
            "totalHours": 231,
            "totalMarks": 504,
            "totalDH": 0,
            "totalDM": 0,
            "totalDHxDM": 151,
            "totalDH2": 182,
            "totalDM2": 136
        },
        "extraValues": {
            "multiplyDH2DM2": 24752,
            "sqrtOfmultiplyDH2DM2": 157.3277
        },
        "r": 0.9598,
        "n": 7,
        "meanHours": 33,
        "meanMarks": 72,
        "students": [
            {
                "hours": 25,
                "marks": 65,
                "meanDH": -8,
                "meanDM": -7,
                "meanDHxDM": 56,
                "meanDH2": 64,
                "meanDM2": 49
            },
            {
                "hours": 38,
                "marks": 75,
                "meanDH": 5,
                "meanDM": 3,
                "meanDHxDM": 15,
                "meanDH2": 25,
                "meanDM2": 9
            },
            {
                "hours": 30,
                "marks": 68,
                "meanDH": -3,
                "meanDM": -4,
                "meanDHxDM": 12,
                "meanDH2": 9,
                "meanDM2": 16
            },
            {
                "hours": 28,
                "marks": 70,
                "meanDH": -5,
                "meanDM": -2,
                "meanDHxDM": 10,
                "meanDH2": 25,
                "meanDM2": 4
            },
            {
                "hours": 34,
                "marks": 72,
                "meanDH": 1,
                "meanDM": 0,
                "meanDHxDM": 0,
                "meanDH2": 1,
                "meanDM2": 0
            },
            {
                "hours": 40,
                "marks": 79,
                "meanDH": 7,
                "meanDM": 7,
                "meanDHxDM": 49,
                "meanDH2": 49,
                "meanDM2": 49
            },
            {
                "hours": 36,
                "marks": 75,
                "meanDH": 3,
                "meanDM": 3,
                "meanDHxDM": 9,
                "meanDH2": 9,
                "meanDM2": 9
            }
        ]
    }]

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                students: [
                    {
                        hours: 25,
                        marks: 65
                    }, {
                        hours: 38,
                        marks: 75
                    }, {
                        hours: 30,
                        marks: 68
                    }, {
                        hours: 28,
                        marks: 70
                    }, {
                        hours: 34,
                        marks: 72
                    }, {
                        hours: 40,
                        marks: 79
                    }, {
                        hours: 36,
                        marks: 75
                    }
                ],

                editModestudents: [
                    {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }, {
                        hours: false,
                        marks: false
                    }
                ],

                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'hours':
                        this.state.students[customArray[1]].hours = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'marks':
                        this.state.students[customArray[1]].marks = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }
        }

        fillDetails() {
            let flag = true

            for(let i of this.state.students){
                if(i.hours === '' || NumberRegex.test(i.hours)){
                    flag = false
                }
                if(i.marks === '' || NumberRegex.test(i.marks)){
                    flag = false
                }
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //EVENT FUNCTIONALTIES END


        //#region GREATEST COMMON DIVISOR START 

        gcd(a, b) {
            if (a == 0)
                return b;
            return this.gcd(b % a, a);
        }

        findGCD(arr, n) {
            let result = arr[0];
            for (let i = 1; i < n; i++) {
                result = this.gcd(arr[i], result);

                if (result == 1) {
                    return 1;
                }
            }
            return result;
        }

        //#endregion GREATEST COMMON DIVISOR END

        // BUTTON PANEL START

        onSubmit() {

            let totalValuePalette = {
                totalHours: 0,
                totalMarks: 0,
                totalDH: 0,
                totalDM: 0,
                totalDHxDM: 0,
                totalDH2: 0,
                totalDM2: 0
            }


            for (let i of this.state.students) {
                totalValuePalette.totalHours += parseFloat(i.hours)
                totalValuePalette.totalMarks += parseFloat(i.marks)
            }

            let meanHours = Math.floor(totalValuePalette.totalHours / this.state.students.length)
            let meanMarks = Math.floor(totalValuePalette.totalMarks / this.state.students.length)

            for (let i of this.state.students) {
                i.meanDH = parseFloat(i.hours) - meanHours
                i.meanDM = parseFloat(i.marks) - meanMarks
                i.meanDHxDM = (parseFloat(i.hours) - meanHours) * (parseFloat(i.marks) - meanMarks)
                i.meanDH2 = (parseFloat(i.hours) - meanHours) * (parseFloat(i.hours) - meanHours)
                i.meanDM2 = (parseFloat(i.marks) - meanMarks) * (parseFloat(i.marks) - meanMarks)

                totalValuePalette.totalDH += i.meanDH
                totalValuePalette.totalDM += i.meanDM
                totalValuePalette.totalDHxDM += i.meanDHxDM
                totalValuePalette.totalDH2 += i.meanDH2
                totalValuePalette.totalDM2 += i.meanDM2
            }

            let r = Math.round((totalValuePalette.totalDHxDM) / (Math.sqrt(totalValuePalette.totalDH2) * Math.sqrt(totalValuePalette.totalDM2)) * 10000) / 10000

            let dataObject = {
                totalValuePalette,
                extraValues: {
                    multiplyDH2DM2: totalValuePalette.totalDH2 * totalValuePalette.totalDM2,
                    sqrtOfmultiplyDH2DM2: Math.round((Math.sqrt(totalValuePalette.totalDH2 * totalValuePalette.totalDM2)) * 10000) / 10000
                },
                r: r,
                n: this.state.students.length,
                meanHours: meanHours,
                meanMarks: meanMarks,
                students: this.state.students
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addRows() {
            this.state.students.push({
                hours: 10,
                marks: 50
            })
            this.state.editModestudents.push({
                hours: false,
                marks: false
            })

            this.setState({
                editModeButton: true
            })
        }

        removeRows(event) {
            this.state.students.splice(event, 1)
            this.state.editModestudents.splice(event, 1)
            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //BUTTON PANEL END


        // EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'hours':
                        this.state.editModestudents[customArray[1]].hours = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'marks':
                        this.state.editModestudents[customArray[1]].marks = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }
        }

        editModeOff() {
            for (let i of this.state.editModestudents) {
                i.hours = false
                i.marks = false
            }

            this.setState({
                editModeButton: false,
            })
        }

        // EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        <b>The following information information is collected by taking a sample of seven candidates having nearly same intellectual ability to know the effect of 'last days preparation' for a competitve examination of general knowledge on 'result of the examination'.</b><br />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Reading hours of last three days</StyledTableCell>
                                        <StyledTableCell align="center">Marks obtained in examination</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.students.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModestudents[i].hours ?
                                                        <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "20%" }} name={["hours", i]} id='outlined-base' error={x.hours === '' || isNaN(x.hours)}
                                                            value={x.hours} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["hours", i]} onClick={this.buttonMaker.bind(this)}> {x.hours}</a>
                                                }
                                                {this.state.students.length > 2 && <IconButton style={{ width: "3%", color: "red" }} onClick={this.removeRows.bind(this, i)} name="-">-</IconButton>}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModestudents[i].marks ?
                                                        <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "20%" }} name={["marks", i]} id='outlined-base' error={x.marks === '' || isNaN(x.marks)}
                                                            value={x.marks} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["marks", i]} onClick={this.buttonMaker.bind(this)}> {x.marks}</a>
                                                }
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />
                        <AddButton style={{ width: "15%" }} onClick={this.addRows.bind(this)}>ADD ROWS</AddButton><br /><br />

                        <b>
                            Find the correlation coefficient between reading hours of the last three days and marks obtained in the examination from the data and interpret it.
                        </b>
                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>Ans.</b><br />
                            &emsp;&emsp;Here, <i>n</i> = {data[chartState.selected].n}, mean for reading hours (<i>x</i>) is x̄ = <sup>Σ<i>x</i></sup>/<sub><i>n</i></sub> = <sup>{data[chartState.selected].totalValuePalette.totalHours}</sup>/<sub>{data[chartState.selected].n}</sub> = {data[chartState.selected].meanHours},<br />
                            mean for marks (<i>y</i>) is ȳ = <sup>Σ<i>y</i></sup>/<sub><i>n</i></sub> = <sup>{data[chartState.selected].totalValuePalette.totalMarks}</sup>/<sub>{data[chartState.selected].n}</sub> = {data[chartState.selected].meanMarks}<br />

                            (If values of x̄ and ȳ are non-integers we can take the nearest value integer)<br /><br />

                            And now since both x̄ and ȳ are integers, we can obtain <i>r</i> as follows.<br />

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Reading hours <br/><i>x</i></StyledTableCell>
                                            <StyledTableCell align="center">Marks <br/><i>y</i></StyledTableCell>
                                            <StyledTableCell align="center" style={{verticalAlign:"top"}}><i>x</i> - x̄</StyledTableCell>
                                            <StyledTableCell align="center" style={{verticalAlign:"top"}}><i>y</i> - ȳ</StyledTableCell>
                                            <StyledTableCell align="center" style={{verticalAlign:"top"}}>(<i>x</i> - x̄)(<i>y</i> - ȳ)</StyledTableCell>
                                            <StyledTableCell align="center" style={{verticalAlign:"top"}}>(<i>x</i> - x̄)<sup>2</sup></StyledTableCell>
                                            <StyledTableCell align="center" style={{verticalAlign:"top"}}>(<i>y</i> - ȳ)<sup>2</sup></StyledTableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].students.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center">{x.hours}</StyledTableCell>
                                                <StyledTableCell align="center">{x.marks}</StyledTableCell>
                                                <StyledTableCell align="center">{x.meanDH}</StyledTableCell>
                                                <StyledTableCell align="center">{x.meanDM}</StyledTableCell>
                                                <StyledTableCell align="center">{x.meanDHxDM}</StyledTableCell>
                                                <StyledTableCell align="center">{x.meanDH2}</StyledTableCell>
                                                <StyledTableCell align="center">{x.meanDM2}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                                <StyledTableCell align="center"><b><span style={{float:"left"}}>TOTAL</span>{data[chartState.selected].totalValuePalette.totalHours}</b></StyledTableCell>
                                                <StyledTableCell align="center"><b>{data[chartState.selected].totalValuePalette.totalMarks}</b></StyledTableCell>
                                                <StyledTableCell align="center"><b>{data[chartState.selected].totalValuePalette.totalDH}</b></StyledTableCell>
                                                <StyledTableCell align="center"><b>{data[chartState.selected].totalValuePalette.totalDM}</b></StyledTableCell>
                                                <StyledTableCell align="center"><b>{data[chartState.selected].totalValuePalette.totalDHxDM}</b></StyledTableCell>
                                                <StyledTableCell align="center"><b>{data[chartState.selected].totalValuePalette.totalDH2}</b></StyledTableCell>
                                                <StyledTableCell align="center"><b>{data[chartState.selected].totalValuePalette.totalDM2}</b></StyledTableCell>
                                            </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <i>r</i> = <sup>Σ(<i>x</i>-x̄)(<i>y</i>-ȳ)</sup>/<sub>√(Σ(<i>x</i>-x̄)<sup>2</sup>) ⋅ √(Σ(<i>y</i>-ȳ)<sup>2</sup>)</sub><br/>
                            &ensp;&nbsp;= <sup>{data[chartState.selected].totalValuePalette.totalDHxDM}</sup>/<sub>√{data[chartState.selected].totalValuePalette.totalDH2} ⋅ √{data[chartState.selected].totalValuePalette.totalDM2}</sub><br/>
                            &ensp;&nbsp;= <sup>{data[chartState.selected].totalValuePalette.totalDHxDM}</sup>/<sub>√{data[chartState.selected].totalValuePalette.totalDH2} x √{data[chartState.selected].totalValuePalette.totalDM2}</sub><br/>
                            &ensp;&nbsp;= <sup>{data[chartState.selected].totalValuePalette.totalDHxDM}</sup>/<sub>√{data[chartState.selected].extraValues.multiplyDH2DM2}</sub><br/>
                            &ensp;&nbsp;= <sup>{data[chartState.selected].totalValuePalette.totalDHxDM}</sup>/<sub>{data[chartState.selected].extraValues.sqrtOfmultiplyDH2DM2}</sub><br/>
                            &ensp;&nbsp;= {data[chartState.selected].r}<br/><br/>
                            ∴ <i>r</i> = {Math.round(data[chartState.selected].r*100)/100}<br/><br/>

                            {Math.round(data[chartState.selected].r*100)/100 > 0.5 && 
                            <span>
                                &emsp;&emsp;We can see that the value of <i>r</i> is very near to 1. Thus there is high degree ofpositive correlation between the reading hours and the marks.&nsbp;
                                Hence, it can be said that generally if last days reading hours are more then more marks can be obtained in the examination.
                            </span>
                            }
                            {Math.round(data[chartState.selected].r*100)/100 <= 0.5 && 
                            <span>
                                &emsp;&emsp;We can see that the value of <i>r</i> is very near to 0. Thus there is low degree of positive correlation between the reading hours and the marks.&nsbp;
                                Hence, it can be said that generally even if last days reading hours are more marks cannot be obtained in the examination.
                            </span>
                            }
                        </div>}
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

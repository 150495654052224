import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

// import Datetime from 'react-datetime';

export default function ComparativeProfitLossStatement() {

    // const [value, setValue] = React.useState(
    //     new Date('2014-08-18T21:11:54'),
    //   );

    //   const handleChange = (newValue) => {
    //     setValue(newValue);
    //   };

    const data = [{
        companyName: "'A. A.' Company Ltd.",
        salesRevenue: {
            left: 2000000,
            right: 1600000,
            incdec: 400000,
            incdecratio: 25
        },
        otherIncome: {
            left: 300000,
            right: 400000,
            incdec: 100000,
            incdecratio: 25
        },
        totalIncome: {
            left: 2300000,
            right: 2000000,
            incdec: 300000,
            incdecratio: 15
        },
        expenses: [{
            name: "વપરાયેલ માલની પડતર",
            left: 900000,
            right: 800000,
            incdec: 100000,
            incdecratio: 12.5
        }, {
            name: "પુન:વેચાણની ચોખ્ખી ખરીદી",
            left: 110000,
            right: 90000,
            incdec: 20000,
            incdecratio: 22.2
        }, {
            name: "સ્ટોકમાં ફેરફારો",
            left: 50000,
            right: 50000,
            incdec: '-',
            incdecratio: '-'
        }, {
            name: "કર્મચારી લાભના ખર્ચા",
            left: 120000,
            right: 90000,
            incdec: 30000,
            incdecratio: 33.33
        }, {
            name: "નાણાકીય પડતરો",
            left: 40000,
            right: 60000,
            incdec: 20000,
            incdecratio: 33.33
        }, {
            name: "ઘસારો અને માંડી વાળેલ રકમો",
            left: 270000,
            right: 180000,
            incdec: 90000,
            incdecratio: 50
        }],
        totalExpenses: {
            left: 1270000,
            right: 1490000,
            incdec: 220000,
            incdecratio: 17.32
        },
        profitBeforeTax: {
            left: 730000,
            right: 810000,
            incdec: 80000,
            incdecratio: 10.96
        },
        lessIncomeTax: {
            left: 219000,
            right: 243000,
            incdec: 24000,
            incdecratio: 10.96
        },
        profitAfterTax: {
            left: 511000,
            right: 567000,
            incdec: 56000,
            incdecratio: 10.96
        },
        incomeTaxRate: 30,
        date: {
            left: '31-03-2017',
            right: '31-03-2016'
        }
    }]

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    const [value, onChange] = useState(new Date());
    const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                companyName: "'A. A.' Company Ltd.",
                salesRevenue: {
                    left: 2000000,
                    right: 1600000
                },
                otherIncome: {
                    left: 300000,
                    right: 400000
                },
                expenses: [{
                    name: "વપરાયેલ માલની પડતર",
                    left: 900000,
                    right: 800000
                }, {
                    name: "પુન:વેચાણ માટેની ચોખ્ખી ખરીદી",
                    left: 110000,
                    right: 90000
                }, {
                    name: "સ્ટોકમાં ફેરફારો",
                    left: 50000,
                    right: 50000
                }, {
                    name: "કર્મચારીઓને લગતા ખર્ચાઓ",
                    left: 120000,
                    right: 90000
                }, {
                    name: "નાણાકીય પડતરો",
                    left: 40000,
                    right: 60000
                }, {
                    name: "ઘસારો અને માંડી વાળેલ રકમો",
                    left: 270000,
                    right: 180000
                }],
                incomeTaxRate: 30,

                editModeName: false,
                editModeSalesRevenue: {
                    left: false,
                    right: false
                },
                editModeOtherIncome: {
                    left: false,
                    right: false
                },
                editModeExpenses: [{
                    name: false,
                    left: false,
                    right: false
                }, {
                    name: false,
                    left: false,
                    right: false
                }, {
                    name: false,
                    left: false,
                    right: false
                }, {
                    name: false,
                    left: false,
                    right: false
                }, {
                    name: false,
                    left: false,
                    right: false
                }, {
                    name: false,
                    left: false,
                    right: false
                }],
                editModeRate: false,
                solutionShow: false,
                // tableModeChartModeSwitch: true,
                submitChecker: true

            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //EVENT FUNCTIONALITIES START

        eventHandler(event) {

            let rowList = event.target.name.split(',')

            if (rowList.length > 1) {
                switch (rowList[0]) {
                    case 'name':
                        this.state.expenses[parseInt(rowList[2])].name = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'left':
                        this.state.expenses[parseInt(rowList[2])].left = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'right':
                        this.state.expenses[parseInt(rowList[2])].right = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    default:
                        this.setState({})
                }

            }

            switch (event.target.name) {
                case 'companyName':
                    this.setState({
                        companyName: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'SRLeft':
                    this.state.salesRevenue.left = event.target.value
                    this.setState({}, () => {
                        this.fillDetails()
                    })
                    break;
                case 'SRRight':
                    this.state.salesRevenue.right = event.target.value
                    this.setState({}, () => {
                        this.fillDetails()
                    })
                    break;
                case 'OILeft':
                    this.state.otherIncome.left = event.target.value
                    this.setState({}, () => {
                        this.fillDetails()
                    })
                    break;
                case 'OIRight':
                    this.state.otherIncome.right = event.target.value
                    this.setState({}, () => {
                        this.fillDetails()
                    })
                    break;
                case 'incomeTaxRate':
                    this.setState({
                        incomeTaxRate: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
            }
        }

        fillDetails() {
            let count = 0

            if (NumberRegex.test(this.state.salesRevenue.left) && this.state.salesRevenue.left !== '') {
                count += 1
            }
            if (NumberRegex.test(this.state.salesRevenue.right) && this.state.salesRevenue.right !== '') {
                count += 1
            }
            if (NumberRegex.test(this.state.otherIncome.left) && this.state.otherIncome.left !== '') {
                count += 1
            }
            if (NumberRegex.test(this.state.otherIncome.right) && this.state.otherIncome.right !== '') {
                count += 1
            }
            if (NumberRegex.test(this.state.incomeTaxRate) && this.state.incomeTaxRate !== '' && parseInt(this.state.incomeTaxRate) >= 0 && parseInt(this.state.incomeTaxRate) <= 100) {
                count += 1
            }
            for (let i of this.state.expenses) {
                if (NumberRegex.test(i.left) && i.left !== '') {
                    count += 1
                }
                if (NumberRegex.test(i.right) && i.right !== '') {
                    count += 1
                }
            }
            // console.log(count)
            if (count === (5 + (this.state.expenses.length) * 2)) {
                this.setState({
                    submitChecker: true
                })
            } else {
                this.setState({
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //EVENT FUNCTIONALTIES END

        // BUTTON PANEL START

        addExpenseItem() {
            this.state.expenses.push({
                name: "નવો ખર્ચ",
                left: 0,
                right: 0
            })
            this.state.editModeExpenses.push({
                name: true,
                left: true,
                right: true
            })
            this.setState({
                editModeButton: true
            })
            this.setState({})
        }

        removeExpenseItem(event) {
            console.log(event)
            this.state.expenses.splice(event, 1)
            this.state.editModeExpenses.splice(event, 1)
            this.setState({})
        }

        onSubmit() {
            let salesRevenueV = Math.abs(parseFloat(this.state.salesRevenue.right) - parseFloat(this.state.salesRevenue.left))
            let salesRevenueR = Math.round(((salesRevenueV) / parseFloat(this.state.salesRevenue.right) * 100*100))/100
            let otherIncomeV = Math.abs(parseFloat(this.state.otherIncome.right) - parseFloat(this.state.otherIncome.left))
            let otherIncomeR = Math.round((otherIncomeV) / parseFloat(this.state.otherIncome.right) * 100* 100)/100
            let totalIncomeLeft = parseFloat(this.state.salesRevenue.left) + parseFloat(this.state.otherIncome.left)
            let totalIncomeRight = parseFloat(this.state.salesRevenue.right) + parseFloat(this.state.otherIncome.right)
            let totalIncomeV = Math.abs(totalIncomeRight - totalIncomeLeft)
            let totalIncomeR = Math.round((totalIncomeV) / totalIncomeRight * 100 * 100)/100

            let expenses = []

            let totalExpensesLeft = 0
            let totalExpensesRight = 0
            let totalExpensesV = 0
            let totalExpensesR = 0

            for (let i of this.state.expenses) {
                expenses.push({
                    name: i.name,
                    left: i.left,
                    right: i.right,
                    incdec: ((parseFloat(i.right) - parseFloat(i.left)) === 0 ? "-" : Math.abs(parseFloat(i.right) - parseFloat(i.left))),
                    incdecratio: ((parseFloat(i.right) - parseFloat(i.left)) === 0 ? "-" : Math.round((Math.abs(parseFloat(i.right) - parseFloat(i.left))) / parseFloat(i.right) * 100*100)/100)
                })
                totalExpensesLeft += parseFloat(i.left)
                totalExpensesRight += parseFloat(i.right)
            }

            totalExpensesV = Math.abs(totalExpensesRight - totalExpensesLeft)
            totalExpensesR = Math.round((totalExpensesV / totalExpensesRight) * 100 * 100)/100

            let profitBeforeTaxLeft = totalIncomeLeft - totalExpensesLeft
            let profitBeforeTaxRight = totalIncomeRight - totalExpensesRight
            let profitBeforeTaxV = Math.abs(profitBeforeTaxRight - profitBeforeTaxLeft)
            let profitBeforeTaxR = Math.round((profitBeforeTaxV / profitBeforeTaxRight) * 100 * 100)/100

            let lessIncomeTaxLeft = (profitBeforeTaxLeft * (this.state.incomeTaxRate)) / 100
            let lessIncomeTaxRight = (profitBeforeTaxRight * (this.state.incomeTaxRate)) / 100
            let lessIncomeTaxV = Math.abs(lessIncomeTaxRight - lessIncomeTaxLeft)
            let lessIncomeTaxR = Math.round((lessIncomeTaxV / lessIncomeTaxRight) * 100 * 100)/100

            let profitAfterTaxLeft = profitBeforeTaxLeft - lessIncomeTaxLeft
            let profitAfterTaxRight = profitBeforeTaxRight - lessIncomeTaxRight
            let profitAfterTaxV = Math.abs(profitAfterTaxRight - profitAfterTaxLeft)
            let profitAfterTaxR = Math.round((profitAfterTaxV / profitAfterTaxRight) * 100*100)/100

            let newObject = {
                companyName: this.state.companyName,
                salesRevenue: {
                    left: this.state.salesRevenue.left,
                    right: this.state.salesRevenue.right,
                    incdec: (salesRevenueV === 0 ? "-" : salesRevenueV),
                    incdecratio: (salesRevenueV === 0 ? "-" : salesRevenueR)
                },
                otherIncome: {
                    left: this.state.otherIncome.left,
                    right: this.state.otherIncome.right,
                    incdec: (otherIncomeV === 0 ? "-" : otherIncomeV),
                    incdecratio: (otherIncomeV === 0 ? "-" : otherIncomeR)
                },
                totalIncome: {
                    left: totalIncomeLeft,
                    right: totalIncomeRight,
                    incdec: (totalIncomeV === 0 ? "-" : totalIncomeV),
                    incdecratio: (totalIncomeV === 0 ? "-" : totalIncomeR)
                },
                expenses: expenses,
                totalExpenses: {
                    left: totalExpensesLeft,
                    right: totalExpensesRight,
                    incdec: (totalExpensesV === 0 ? "-" : totalExpensesV),
                    incdecratio: (totalExpensesV === 0 ? "-" : totalExpensesR)
                },
                profitBeforeTax: {
                    left: profitBeforeTaxLeft,
                    right: profitBeforeTaxRight,
                    incdec: profitBeforeTaxV,
                    incdecratio: profitBeforeTaxR
                },
                lessIncomeTax: {
                    left: lessIncomeTaxLeft,
                    right: lessIncomeTaxRight,
                    incdec: lessIncomeTaxV,
                    incdecratio: lessIncomeTaxR
                },
                profitAfterTax: {
                    left: profitAfterTaxLeft,
                    right: profitAfterTaxRight,
                    incdec: profitAfterTaxV,
                    incdecratio: profitAfterTaxR
                },
                incomeTaxRate: this.state.incomeTaxRate,
                date: {
                    left: '31-03-2017',
                    right: '31-03-2016'
                }
            }

            data.push(newObject)
            chartState.selected += 1

            this.setState({})
        }

        onReset() {
            window.location.reload()
        }

        switchTableToChartAndBack() {
            // this.setState({
            //     tableModeChartModeSwitch: !this.state.tableModeChartModeSwitch
            // })
        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //BUTTON PANEL END


        // EDIT BUTTON START

        buttonMaker(event) {
            let rowList = event.target.name.split(",")

            if (rowList.length > 1) {
                switch (rowList[0]) {
                    case 'name':
                        this.state.editModeExpenses[parseInt(rowList[2])].name = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'left':
                        this.state.editModeExpenses[parseInt(rowList[2])].left = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'right':
                        this.state.editModeExpenses[parseInt(rowList[2])].right = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    default:
                        this.setState({})

                }
            }

            switch (event.target.name) {
                case 'companyName':
                    this.setState({
                        editModeName: true,
                        editModeButton: true
                    })
                    break;
                case 'SRLeft':
                    this.state.editModeSalesRevenue.left = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'SRRight':
                    this.state.editModeSalesRevenue.right = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'OILeft':
                    this.state.editModeOtherIncome.left = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'OIRight':
                    this.state.editModeOtherIncome.right = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'incomeTaxRate':
                    this.setState({
                        editModeRate: true,
                        editModeButton: true
                    })
                    break;
            }


        }

        editModeOff() {
            for (let i of this.state.editModeExpenses) {
                i.name = false
                i.left = false
                i.right = false
            }

            this.state.editModeSalesRevenue.left = false
            this.state.editModeSalesRevenue.right = false
            this.state.editModeOtherIncome.left = false
            this.state.editModeOtherIncome.right = false


            this.setState({
                editModeRate: false,
                editModeName: false,
                editModeButton: false
            })
        }

        // EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        <b>પ્રશ્ન:</b><br /><br /> <span>
                            {
                                this.state.editModeName ?
                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name='companyName' id='outlined-base' error={this.state.companyName === ''}
                                        value={this.state.companyName} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name="companyName" onClick={this.buttonMaker.bind(this)}> {this.state.companyName}</a>
                            }

                        </span>  &nbsp;
                        {data[chartState.selected].date.left} and {data[chartState.selected].date.right}
                            {/* <Datetime/> */}

                        &nbsp;
                        ના રોજ પૂરા થતાં વર્ષનું તુલનાત્મક નફા-નુક્શાનનું પત્રક.<br /> <br />
                        <div align="center">
                            <b>{this.state.companyName}</b><br />
                            <b> {data[chartState.selected].date.left} and {data[chartState.selected].date.right} ના રોજ પૂરા થતાં વર્ષનું તુલનાત્મક નફા-નુક્શાનનું પત્રક </b><br />
                        </div>
                        <br />
                        <div label="TableContents">
                            <div>
                                <TableContainer>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">વિગત </StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].date.right}<br />(₹)</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].date.left}<br />(₹)</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow key='salesRevenue'>
                                                <StyledTableCell align="left">વેચાણની ઉપજ </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {this.state.editModeSalesRevenue.left ? <TextField style={{ 'margin-top': "-15px" }} name="SRLeft" id='outlined-base' error={this.state.salesRevenue.left === '' || isNaN(this.state.salesRevenue.left)}
                                                        value={this.state.salesRevenue.left} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="SRLeft" onClick={this.buttonMaker.bind(this)}> {this.state.salesRevenue.left}</a>}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {this.state.editModeSalesRevenue.right ? <TextField style={{ 'margin-top': "-15px" }} name="SRRight" id='outlined-base' error={this.state.salesRevenue.right === '' || isNaN(this.state.salesRevenue.right)}
                                                        value={this.state.salesRevenue.right} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="SRRight" onClick={this.buttonMaker.bind(this)}> {this.state.salesRevenue.right}</a>}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key='otherIncome'>
                                                <StyledTableCell align="left">અન્ય આવકો </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {this.state.editModeOtherIncome.left ? <TextField style={{ 'margin-top': "-15px" }} name="OILeft" id='outlined-base' error={this.state.otherIncome.left === '' || isNaN(this.state.otherIncome.left)}
                                                        value={this.state.otherIncome.left} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="OILeft" onClick={this.buttonMaker.bind(this)}> {this.state.otherIncome.left}</a>}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {this.state.editModeOtherIncome.right ? <TextField style={{ 'margin-top': "-15px" }} name="OIRight" id='outlined-base' error={this.state.otherIncome.right === '' || isNaN(this.state.otherIncome.right)}
                                                        value={this.state.otherIncome.right} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="OIRight" onClick={this.buttonMaker.bind(this)}> {this.state.otherIncome.right}</a>}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key='expenses'>
                                                <StyledTableCell align="left">ખર્ચાઓ  : </StyledTableCell>
                                                <StyledTableCell align="right"></StyledTableCell>
                                                <StyledTableCell align="right"></StyledTableCell>
                                            </StyledTableRow>
                                            {this.state.expenses.map((x, i) => (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell align="left">&emsp;
                                                        {this.state.editModeExpenses[i].name ? <TextField style={{ width: "90%" }} name={["name", x.name, i]} id='outlined-base' error={this.state.expenses[i].name === ''}
                                                            value={this.state.expenses[i].name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["name", x.name, i]} onClick={this.buttonMaker.bind(this)}> {x.name}</a>}
                                                        &nbsp;&nbsp; {this.state.expenses.length > 1 && <Button onClick={this.removeExpenseItem.bind(this, i)} style={{ "line-height": "0.7", padding: "0px" }}><Icon style={{ color: "red" }}>-</Icon></Button>}
                                                        {(this.state.expenses.length - 1) === i && <div align="right"><Icon onClick={this.addExpenseItem.bind(this)}>+</Icon></div>}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {this.state.editModeExpenses[i].left ? <TextField style={{ 'margin-top': "-15px", }} name={["left", x.left, i]} id='outlined-base' error={this.state.expenses[i].left === '' || isNaN(this.state.expenses[i].left)}
                                                            value={this.state.expenses[i].left} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["left", x.left, i]} onClick={this.buttonMaker.bind(this)}> {x.left}</a>}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {this.state.editModeExpenses[i].right ? <TextField style={{ 'margin-top': "-15px", }} name={["right", x.right, i]} id='outlined-base' error={this.state.expenses[i].right === '' || isNaN(this.state.expenses[i].right)}
                                                            value={this.state.expenses[i].right} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["right", x.right, i]} onClick={this.buttonMaker.bind(this)}> {x.right}</a>}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                આવક વેરાનો દર  
                                {this.state.editModeRate ? <TextField style={{ 'margin-top': "-15px", }} name="incomeTaxRate" id='outlined-base' error={this.state.incomeTaxRate === '' || isNaN(this.state.incomeTaxRate) || this.state.incomeTaxRate < 0 || this.state.incomeTaxRate > 100}
                                    value={this.state.incomeTaxRate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name="incomeTaxRate" onClick={this.buttonMaker.bind(this)}> {this.state.incomeTaxRate}</a>} % હતો
                            </div>
                            <br /><br />
                            {this.state.editModeButton ? <ColorButton style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)} disabled={this.state.totalPurchase === '' || this.state.cashPurchase === '' || this.state.purchaseReturn === '' || this.state.openingTradePayables === '' || this.state.closingTradePayables === ''}>Edit Mode Off</ColorButton> : <span></span>}
                        </div>


                    </div>
                    <div align="left" label="buttons">
                        <br />
                        <br />
                        <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                        <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                    </div>
                    <div align='right'>
                        <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {chartState.selected + 1}
                        &nbsp;&nbsp;&nbsp;
                        <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                    </div>
                    <br />
                    <br />
                    {this.state.solutionShow ? <div label="solution">
                        <div label="table">
                            <div align="center">
                                <b> {data[chartState.selected].companyName} </b><br />
                                <b>ના રોજ પૂરા થતાં વર્ષનું તુલનાત્મક નફા-નુક્શાનનું પત્રક  {data[chartState.selected].date.left} and {data[chartState.selected].date.right}</b>
                            </div>
                            <br /><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">વિગત  <br /><br /> 1</StyledTableCell>
                                            <StyledTableCell align="center">{data[chartState.selected].date.left}<br />(₹)<br />2</StyledTableCell>
                                            <StyledTableCell align="center">{data[chartState.selected].date.right}<br />(₹)<br />3</StyledTableCell>
                                            <StyledTableCell align="center">વધારો / ઘટાડો<br/>(₹)<br />4(3 - 2)</StyledTableCell>
                                            <StyledTableCell align="center">વધારો / ઘટાડો<br/>(%)<br />5(<sup>4</sup>/<sub>2</sub> x 100)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="salesRevenue">
                                            <StyledTableCell align="left">(I) વેચાણની ઉપજ </StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].salesRevenue.right}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].salesRevenue.left}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].salesRevenue.incdec}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].salesRevenue.incdecratio}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="otherIncome">
                                            <StyledTableCell align="left">(II) અન્ય આવકો </StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].otherIncome.right}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].otherIncome.left}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].otherIncome.incdec}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].otherIncome.incdecratio}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="totalIncome">
                                            <StyledTableCell align="left">(III) કુલ આવકો  (I + II)</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].totalIncome.right}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].totalIncome.left}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].totalIncome.incdec}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].totalIncome.incdecratio}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="left">(IV)ખર્ચાઓ  :</StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                        </StyledTableRow>
                                        {data[chartState.selected].expenses.map((x, i) => (
                                            <StyledTableRow key={i}>
                                                <StyledTableCell align="left">&emsp;
                                                    ({i + 1}) : {x.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {x.right}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {x.left}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {x.incdec}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {x.incdecratio}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        <StyledTableRow>
                                            <StyledTableCell align="left">&emsp;કુલ ખર્ચાઓ </StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].totalExpenses.right}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].totalExpenses.left}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].totalExpenses.incdec}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].totalExpenses.incdecratio}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="left">(V) આવકવેરા પહેલાંનો નફો  <br />(III - IV)</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].profitBeforeTax.right}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].profitBeforeTax.left}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].profitBeforeTax.incdec}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].profitBeforeTax.incdecratio}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="left">(VI) બાદ : આવકવેરો  ({data[chartState.selected].incomeTaxRate} %)</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].lessIncomeTax.right}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].lessIncomeTax.left}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].lessIncomeTax.incdec}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].lessIncomeTax.incdecratio}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="left">(VII) આવકવેરા પછીનો નફો  <br />(V - VI)</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].profitAfterTax.right}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].profitAfterTax.left}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].profitAfterTax.incdec}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].profitAfterTax.incdecratio}</StyledTableCell>
                                        </StyledTableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div> :
                        <div></div>}
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

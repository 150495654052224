import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {


    const data = [[
        {
            "type": "fiveYearsAverageProfit",
            "startingYear": 2012,
            "profitYear": [
                90000,
                110000,
                120000,
                130000,
                140000
            ],
            "totalProfit": 590000,
            "averageProfit": 118000,
            "totalYears": 5
        },
        {
            "type": "purchaseOfAverageProfit",
            "years": 3,
            "startingYear": 2012,
            "profitYear": [
                90000,
                110000,
                120000,
                130000,
                140000
            ],
            "totalProfit": 590000,
            "averageProfit": 118000,
            "purchaseOfAverageProfit": 354000
        },
        {
            "type": "purchaseOfweightedAverageProfit",
            "years": 2,
            "startingYear": 2012,
            "profitYear": [
                90000,
                110000,
                120000,
                130000,
                140000
            ],
            "weightedProfit": [
                90000,
                220000,
                360000,
                520000,
                700000
            ],
            "totalWeightedProfit": 1890000,
            "totalWeight": 15,
            "WeightedAverageProfit": 126000,
            "purchaseOfweightedAverageProfit": 252000
        },
        {
            "type": "purchaseOfSuperProfit",
            "years": 4,
            "businessAssets": 1000000,
            "businessLiabilities": 200000,
            "capitalEmployed": 800000,
            "expectedRateOfReturn": 10,
            "expectedProfit": 80000,
            "weightedAverageProfit": 126000,
            "superProfit": 46000,
            "purchaseOfSuperProfit": 184000
        },
        {
            "type": "capitalizationProfitMethod",
            "businessAssets": 1000000,
            "businessLiabilities": 200000,
            "capitalEmployed": 800000,
            "expectedRateOfReturn": 10,
            "weightedAverageProfit": 126000,
            "capitalizedProfit": 1260000,
            "capitalizationProfitMethod": 460000
        },
        {
            "type": "capitalizationSuperProfit",
            "businessAssets": 1000000,
            "businessLiabilities": 200000,
            "capitalEmployed": 800000,
            "expectedRateOfReturn": 10,
            "expectedProfit": 80000,
            "weightedAverageProfit": 126000,
            "superProfit": 46000,
            "capitalizationSuperProfit": 460000
        }
    ]]

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                partner: {
                    one: "Harpal",
                    two: "Rajesh",
                    three: "Jayesh"
                },
                businessAssets: 1000000,
                businessLiabilities: 200000,
                expectedRateOfReturn: 10,
                startingYear: 2012,
                profitYear: [90000, 110000, 120000, 130000, 140000],
                questions: [{
                    type: "fiveYearsAverageProfit",
                }, {
                    type: "purchaseOfAverageProfit",
                    years: 3
                }, {
                    type: "purchaseOfweightedAverageProfit",
                    years: 2
                }, {
                    type: "purchaseOfSuperProfit",
                    years: 4
                }, {
                    type: "capitalizationProfitMethod",
                }, {
                    type: "capitalizationSuperProfit"
                }],
                optionSelect: "fiveYearsAverageProfit",

                editModeName: {
                    one: false,
                    two: false,
                    three: false
                },
                editModeDate: false,
                editModeBA: false,
                editModeBL: false,
                editModeEROR: false,
                editModePY: [false, false, false, false, false],
                editModeStartingYear: false,
                editModeQuestions: [false, false, false, false, false, false],

                editModeRate: false,
                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true

            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let questionData = event.target.name.split(",")

            if (questionData.length === 2) {
                if (questionData[0] === "years") {
                    this.state.questions[parseInt(questionData[1])].years = event.target.value
                    this.setState({
                    }, () => {
                        this.fillDetails()
                    })
                }
                if (questionData[0] === "dateColumn") {
                    this.state.profitYear[parseInt(questionData[1])] = event.target.value
                    this.setState({
                    }, () => {
                        this.fillDetails()
                    })
                }
            }

            switch (event.target.name) {
                case 'partnerOne':
                    this.state.partner.one = event.target.value
                    this.setState({
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'partnerTwo':
                    this.state.partner.two = event.target.value
                    this.setState({
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'partnerThree':
                    this.state.partner.three = event.target.value
                    this.setState({
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'businessAssets':
                    this.setState({
                        businessAssets: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'businessLiabilities':
                    this.setState({
                        businessLiabilities: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'expectedRateOfReturn':
                    this.setState({
                        expectedRateOfReturn: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'startingYear':
                    this.setState({
                        startingYear: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
            }



        }

        handleChange(event) {
            this.setState({
                optionSelect: event.target.value
            })
        }

        fillDetails() {
            let flag = true
            let count = 0
            if(!NumberRegex.test(this.state.businessAssets) || this.state.businessAssets === ''){
                flag = false
            }
            if(!NumberRegex.test(this.state.businessLiabilities) || this.state.businessLiabilities === ''){
                flag = false
            }
            if(!NumberRegex.test(this.state.expectedRateOfReturn) || this.state.expectedRateOfReturn === '' || this.state.expectedRateOfReturn <0 || this.state.expectedRateOfReturn>100){
                flag = false
            }
            if(!NumberRegex.test(this.state.startingYear) || this.state.startingYear === '' || this.state.startingYear <1000 || this.state.startingYear>9000){
                flag = false

            }


            for(let i in this.state.profitYear){
                if(!NumberRegex.test(this.state.profitYear[i]) || this.state.profitYear[i] === ''){
                    flag = false
                }
            }

            for(let i of this.state.questions){
                if(i.type==='purchaseOfAverageProfit' || i.type==='purchaseOfweightedAverageProfit' || i.type==="purchaseOfSuperProfit"){
                    if(!NumberRegex.test(i.years) || i.years==='' || i.years>this.state.profitYear.length){
                        flag = false
                    }
                }
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: false,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: true,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //EVENT FUNCTIONALTIES END

        // BUTTON PANEL START

        addQuestion() {
            this.state.questions.push({
                type: this.state.optionSelect,
                years: 2
            })
            this.state.editModeQuestions.push(true)
            this.setState({
                editModeButton: true
            })
        }

        removeQuestion(event) {
            console.log(event)
            this.state.questions.splice(event, 1)
            this.state.editModeQuestions.splice(event, 1)
            this.setState({})
        }

        addRemoveYear(event) {
            switch (event.target.name) {
                case '+':
                    this.state.profitYear.push(10000)
                    this.state.editModePY.push(true)
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case '-':
                    this.state.profitYear.pop()
                    this.state.editModePY.pop()
                    break;
            }
            this.setState({})
        }

        onSubmit() {
            let questionAnswerArray = []

            let totalProfit = 0
            for (let i in this.state.profitYear) {
                totalProfit += parseFloat(this.state.profitYear[i])
            }

            let weightedProfit = []
            let totalWeightedProfit = 0
            let weight = 0
            let totalWeight = 0
            for (let i in this.state.profitYear) {
                weight++
                totalWeight += weight
                weightedProfit.push(parseFloat(this.state.profitYear[i]) * (weight))
                totalWeightedProfit += parseFloat(this.state.profitYear[i]) * (weight)
            }

            for (let question of this.state.questions) {
                switch (question.type) {
                    case 'fiveYearsAverageProfit':
                        questionAnswerArray.push({
                            type: question.type,
                            startingYear: this.state.startingYear,
                            profitYear: this.state.profitYear,
                            totalProfit: totalProfit,
                            averageProfit: parseFloat(totalProfit / 5),
                            totalYears: this.state.profitYear.length
                        })
                        break;
                    case 'purchaseOfAverageProfit':
                        questionAnswerArray.push({
                            type: question.type,
                            years: question.years,
                            startingYear: this.state.startingYear,
                            profitYear: this.state.profitYear,
                            totalProfit: totalProfit,
                            averageProfit: parseFloat(totalProfit / 5),
                            purchaseOfAverageProfit: parseFloat(totalProfit / 5) * parseFloat(question.years)
                        })
                        break;
                    case 'purchaseOfweightedAverageProfit':
                        questionAnswerArray.push({
                            type: question.type,
                            years: question.years,
                            startingYear: this.state.startingYear,
                            profitYear: this.state.profitYear,
                            weightedProfit: weightedProfit,
                            totalWeightedProfit: totalWeightedProfit,
                            totalWeight: totalWeight,
                            WeightedAverageProfit: parseFloat(totalWeightedProfit / totalWeight),
                            purchaseOfweightedAverageProfit: parseFloat(totalWeightedProfit / totalWeight) * parseFloat(question.years)
                        })
                        break;
                    case 'purchaseOfSuperProfit':
                        questionAnswerArray.push({
                            type: question.type,
                            years: question.years,
                            businessAssets: this.state.businessAssets,
                            businessLiabilities: this.state.businessLiabilities,
                            capitalEmployed: parseFloat(this.state.businessAssets) - parseFloat(this.state.businessLiabilities),
                            expectedRateOfReturn: this.state.expectedRateOfReturn,
                            expectedProfit: (parseFloat(this.state.businessAssets) - parseFloat(this.state.businessLiabilities)) * (parseFloat(this.state.expectedRateOfReturn)) / 100,
                            weightedAverageProfit: totalWeightedProfit / totalWeight,
                            superProfit: totalWeightedProfit / totalWeight - (parseFloat(this.state.businessAssets) - parseFloat(this.state.businessLiabilities)) * (parseFloat(this.state.expectedRateOfReturn)) / 100,
                            purchaseOfSuperProfit: (totalWeightedProfit / totalWeight - (parseFloat(this.state.businessAssets) - parseFloat(this.state.businessLiabilities)) * (parseFloat(this.state.expectedRateOfReturn)) / 100) * parseFloat(question.years)
                        })
                        break;
                    case 'capitalizationProfitMethod':
                        questionAnswerArray.push({
                            type: question.type,
                            businessAssets: this.state.businessAssets,
                            businessLiabilities: this.state.businessLiabilities,
                            capitalEmployed: parseFloat(this.state.businessAssets) - parseFloat(this.state.businessLiabilities),
                            expectedRateOfReturn: this.state.expectedRateOfReturn,
                            weightedAverageProfit: totalWeightedProfit / totalWeight,
                            capitalizedProfit: (totalWeightedProfit / totalWeight) * 100 / parseFloat(this.state.expectedRateOfReturn),
                            capitalizationProfitMethod: (totalWeightedProfit / totalWeight) * 100 / parseFloat(this.state.expectedRateOfReturn) - (parseFloat(this.state.businessAssets) - parseFloat(this.state.businessLiabilities))
                        })
                        break;
                    case 'capitalizationSuperProfit':
                        questionAnswerArray.push({
                            type: question.type,
                            businessAssets: this.state.businessAssets,
                            businessLiabilities: this.state.businessLiabilities,
                            capitalEmployed: parseFloat(this.state.businessAssets) - parseFloat(this.state.businessLiabilities),
                            expectedRateOfReturn: this.state.expectedRateOfReturn,
                            expectedProfit: (parseFloat(this.state.businessAssets) - parseFloat(this.state.businessLiabilities)) * (parseFloat(this.state.expectedRateOfReturn)) / 100,
                            weightedAverageProfit: totalWeightedProfit / totalWeight,
                            superProfit: totalWeightedProfit / totalWeight - (parseFloat(this.state.businessAssets) - parseFloat(this.state.businessLiabilities)) * (parseFloat(this.state.expectedRateOfReturn)) / 100,
                            purchaseOfSuperProfit: (totalWeightedProfit / totalWeight - (parseFloat(this.state.businessAssets) - parseFloat(this.state.businessLiabilities)) * (parseFloat(this.state.expectedRateOfReturn)) / 100) * 100 / parseFloat(this.state.expectedRateOfReturn)
                        })
                        break;
                }
            }

            chartState.selected += 1
            console.log(JSON.stringify(data,null,4))
            data.push(JSON.parse(JSON.stringify(questionAnswerArray)))

            this.setState({})
        }

        onReset() {
            window.location.reload()
        }

        switchTableToChartAndBack() {
            // this.setState({
            //     tableModeChartModeSwitch: !this.state.tableModeChartModeSwitch
            // })
        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //BUTTON PANEL END


        // EDIT BUTTON START

        buttonMaker(event) {
            let questionData = event.target.name.split(",")

            if (questionData.length === 2) {
                if (questionData[0] === 'years') {
                    this.state.editModeQuestions[parseInt(questionData[1])] = true
                    this.setState({
                        editModeButton: true
                    })
                }
                else if (questionData[0] === 'dateColumn') {
                    this.state.editModePY[parseInt(questionData[1])] = true
                    this.setState({
                        editModeButton: true
                    })
                }
            }

            switch (event.target.name) {
                case 'partnerOne':
                    this.state.editModeName.one = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'partnerTwo':
                    this.state.editModeName.two = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'partnerThree':
                    this.state.editModeName.three = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'businessAssets':
                    this.setState({
                        editModeBA: true,
                        editModeButton: true
                    })
                    break;
                case 'businessLiabilities':
                    this.setState({
                        editModeBL: true,
                        editModeButton: true
                    })
                    break;
                case 'expectedRateOfReturn':
                    this.setState({
                        editModeEROR: true,
                        editModeButton: true
                    })
                    break;
                case 'startingYear':
                    this.setState({
                        editModeStartingYear: true,
                        editModeButton: true
                    })

            }
        }

        editModeOff() {

            for (let i in this.state.editModeQuestions) {
                this.state.editModeQuestions[i] = false
            }
            for (let i in this.state.editModePY) {
                this.state.editModePY[i] = false
            }

            this.state.editModeName.one = false
            this.state.editModeName.two = false
            this.state.editModeName.three = false

            this.setState({
                editModeBA: false,
                editModeBL: false,
                editModeEROR: false,
                editModeStartingYear: false,
                editModeButton: false,
            })
        }

        // EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        <b>Question :</b>
                        {
                            this.state.editModeName.one ?
                                <TextField style={{ 'margin-top': "-15px", width: "10%" }} name='partnerOne' id='outlined-base' error={this.state.partner.one === ''}
                                    value={this.state.partner.one} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="partnerOne" onClick={this.buttonMaker.bind(this)}> {this.state.partner.one}</a>
                        }&nbsp;,


                        {
                            this.state.editModeName.two ?
                                <TextField style={{ 'margin-top': "-15px", width: "10%" }} name='partnerTwo' id='outlined-base' error={this.state.partner.two === ''}
                                    value={this.state.partner.two} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="partnerTwo" onClick={this.buttonMaker.bind(this)}> {this.state.partner.two}</a>
                        } and
                        {
                            this.state.editModeName.three ?
                                <TextField style={{ 'margin-top': "-15px", width: "10%" }} name='partnerThree' id='outlined-base' error={this.state.partner.three === ''}
                                    value={this.state.partner.three} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="partnerThree" onClick={this.buttonMaker.bind(this)}> {this.state.partner.three}</a>
                        }'s firm's information is as under: <br /> <br />
                        (1) Business assests: ₹
                        {
                            this.state.editModeBA ?
                                <TextField type="number" style={{ 'margin-top': "-15px", width: "10%" }} name='businessAssets' id='outlined-base' error={this.state.businessAssets === '' || isNaN(this.state.businessAssets)}
                                    value={this.state.businessAssets} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="businessAssets" onClick={this.buttonMaker.bind(this)}> {this.state.businessAssets}</a>
                        } <br /><br />
                        (2) Business liabilities: ₹
                        {
                            this.state.editModeBL ?
                                <TextField style={{ 'margin-top': "-15px", width: "10%" }} name='businessLiabilities' id='outlined-base' error={this.state.businessLiabilities === '' || isNaN(this.state.businessLiabilities)}
                                    value={this.state.businessLiabilities} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="businessLiabilities" onClick={this.buttonMaker.bind(this)}> {this.state.businessLiabilities}</a>
                        }<br /><br />
                        (3) Expected rate of return:
                        {
                            this.state.editModeEROR ?
                                <TextField style={{ 'margin-top': "-15px", width: "10%" }} name='expectedRateOfReturn' id='outlined-base' error={this.state.expectedRateOfReturn === '' || isNaN(this.state.expectedRateOfReturn) || this.state.expectedRateOfReturn < 0 || this.state.expectedRateOfReturn > 100}
                                    value={this.state.expectedRateOfReturn} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="expectedRateOfReturn" onClick={this.buttonMaker.bind(this)}> {this.state.expectedRateOfReturn}</a>
                        } %<br /><br />
                        Firm's last&nbsp;
                        {this.state.profitYear.length === 2 && "two years"}
                        {this.state.profitYear.length === 3 && "three years"}
                        {this.state.profitYear.length === 4 && "four years"}
                        {this.state.profitYear.length === 5 && "five years"}
                        {this.state.profitYear.length === 6 && "six years"}
                        {this.state.profitYear.length === 7 && "seven years"}
                        {this.state.profitYear.length === 8 && "eight years"}
                        {this.state.profitYear.length === 9 && "nine years"}
                        {this.state.profitYear.length === 10 && "ten years"}&nbsp;
                        profit are as under :
                        STARTING YEAR:&nbsp;
                        {
                            this.state.editModeStartingYear ?
                                <TextField style={{ 'margin-top': "-15px", width: "10%" }} name="startingYear" id='outlined-base' error={this.state.startingYear === '' || isNaN(this.state.startingYear)}
                                    value={this.state.startingYear} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="startingYear" onClick={this.buttonMaker.bind(this)}> {this.state.startingYear}</a>
                        }&nbsp;
                        ADD/REMOVE YEAR:&nbsp;
                        <IconButton disabled={this.state.profitYear.length > 9} style={{ width: "3%" }} onClick={this.addRemoveYear.bind(this)} name="+">+</IconButton>
                        <IconButton disabled={this.state.profitYear.length < 3} style={{ width: "3%" }} onClick={this.addRemoveYear.bind(this)} name="-">-</IconButton>
                        <br /><br />

                        {this.state.editModeButton ? <ColorButton
                        disabled={this.state.editModeButtonChecker}
                        style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}


                        <br /><br />
                        <div label="QuestionTable">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align='center'>Year</StyledTableCell>
                                            <StyledTableCell align='center'>Profit (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.profitYear.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align='center'>{parseInt(this.state.startingYear) + i}-{String(parseInt(this.state.startingYear) + i + 1).slice(2, 4)}</StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    {
                                                        this.state.editModePY[i] ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "10%" }} name={["dateColumn", i]} id='outlined-base' error={this.state.profitYear[i] === '' || isNaN(this.state.profitYear[i])}
                                                                value={x} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["dateColumn", i]} onClick={this.buttonMaker.bind(this)}> {x}</a>
                                                    }
                                                </StyledTableCell>
                                            </TableRow>))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <br />

                        From the above information, determine the value of goodwill of the firm. <br /><br />

                        {this.state.questions.map((x, i) => (
                            <div>
                                ({i + 1})&nbsp;
                                {x.type === "fiveYearsAverageProfit" &&
                                    <span>Calculate the goodwill of the firm equal to five years average profit.</span>
                                }
                                {x.type === "purchaseOfAverageProfit" &&
                                    <span>On the basis of {
                                        this.state.editModeQuestions[i] ?
                                            <TextField style={{ 'margin-top': "-15px", width: "10%" }} name={["years", i]} id='outlined-base' error={x.years === '' || isNaN(x.years)}
                                                value={x.years} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                            <a className='txt' name={["years", i]} onClick={this.buttonMaker.bind(this)}> {x.years}</a>
                                    } years of purchase of average profit.</span>
                                }
                                {x.type === "purchaseOfweightedAverageProfit" &&
                                    <span>On the basis of {
                                        this.state.editModeQuestions[i] ?
                                            <TextField style={{ 'margin-top': "-15px", width: "10%" }} name={["years", i]} id='outlined-base' error={x.years === '' || isNaN(x.years)}
                                                value={x.years} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                            <a className='txt' name={["years", i]} onClick={this.buttonMaker.bind(this)}> {x.years}</a>
                                    } years of purchase of weighted average profit.</span>
                                }
                                {x.type === "purchaseOfSuperProfit" &&
                                    <span>On the basis of {
                                        this.state.editModeQuestions[i] ?
                                            <TextField style={{ 'margin-top': "-15px", width: "10%" }} name={["years", i]} id='outlined-base' error={x.years === '' || isNaN(x.years)}
                                                value={x.years} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                            <a className='txt' name={["years", i]} onClick={this.buttonMaker.bind(this)}> {x.years}</a>
                                    } years of purchase of super profit.</span>
                                }
                                {x.type === "capitalizationProfitMethod" &&
                                    <span>As per capitalisation profit method. (Weighted average basis)</span>
                                }
                                {x.type === "capitalizationSuperProfit" &&
                                    <span>As per capitalisation of super profit. (Weighted average basis)</span>
                                }
                                {this.state.questions.length > 1 && <Button onClick={this.removeQuestion.bind(this, i)} style={{ lineHeight: "0.7", padding: "0px" }}><Icon style={{ color: "red" }}>-</Icon></Button>}
                                <br /><br />
                            </div>
                        ))}
                        <FormControl style={{ width: "50%" }}>
                            <InputLabel id="demo-simple-select-label">Option to Add</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.optionSelect}
                                label="Option to Add"
                                onChange={this.handleChange.bind(this)}
                            >
                                <MenuItem value={"fiveYearsAverageProfit"}>Five Years Average Profit</MenuItem>
                                <MenuItem value={"purchaseOfAverageProfit"}>Average Profit</MenuItem>
                                <MenuItem value={"purchaseOfweightedAverageProfit"}>Weighted Average Profit</MenuItem>
                                <MenuItem value={"purchaseOfSuperProfit"}>Super Profit</MenuItem>
                                <MenuItem value={"capitalizationProfitMethod"}>Capitalization Profit Method</MenuItem>
                                <MenuItem value={"capitalizationSuperProfit"}>Capitalization Super Profit</MenuItem>

                            </Select>
                        </FormControl> &nbsp;&nbsp;
                        <AddButton style={{ width: "20%" }} onClick={this.addQuestion.bind(this)}>ADD QUESTION</AddButton>


                        <div label="TableContents">

                            <br /><br />
                            {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker} 
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}>Edit Mode Off
                            </ColorButton> : <span></span>}
                        </div>


                    </div>
                    <div align="left" label="buttons">
                        <br />
                        <br />
                        <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                        <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                    </div>
                    <div align='right'>
                        <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {chartState.selected + 1}
                        &nbsp;&nbsp;&nbsp;
                        <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                    </div>
                    <br />
                    <br />
                    {this.state.solutionShow ? <div label="solution">
                        <div label="table">
                            <div align="left">
                                {data[chartState.selected].map((x, i) => (
                                    <div>
                                        {x.type === "fiveYearsAverageProfit" &&
                                            <div>
                                                <b>({i + 1}) As per last {x.totalYears} years average profit method :</b><br /><br />
                                                <b>Goodwill :</b>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell align="center">Year</StyledTableCell>
                                                                <StyledTableCell align="right">Profit (₹)</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {x.profitYear.map((y, j) => (
                                                                <TableRow>
                                                                    <StyledTableCell align="center">{parseInt(x.startingYear) + j}-{String(parseInt(x.startingYear) + j + 1).slice(2, 4)}</StyledTableCell>
                                                                    <StyledTableCell align="right">{y}</StyledTableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <StyledTableCell align="center"><b>Total Profit</b></StyledTableCell>
                                                                <StyledTableCell align="right"><b>{x.totalProfit}</b></StyledTableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer><br />
                                                Average profit = (<sup>Total Profit</sup>/<sub>No. of years</sub>)<br />
                                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp; =(<sup>{x.totalProfit}</sup>/<sub>{x.profitYear.length}</sub>)<br />
                                                Average profit = {x.averageProfit}   <br /><br />
                                                Goodwill = Average profit
                                                <h4>Goodwill = {x.averageProfit}</h4>
                                                <hr />
                                                <br />
                                            </div>
                                        }
                                        {x.type === "purchaseOfAverageProfit" &&
                                            <div>
                                                <b>({i + 1}) Goodwill equal to {x.years} years of average profit method :</b><br /><br />
                                                <b>Goodwill :</b>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell align="center">Year</StyledTableCell>
                                                                <StyledTableCell align="right">Profit (₹)</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {x.profitYear.map((y, j) => (
                                                                <TableRow>
                                                                    <StyledTableCell align="center">{parseInt(x.startingYear) + i}-{String(parseInt(x.startingYear) + i + 1).slice(2, 4)}</StyledTableCell>
                                                                    <StyledTableCell align="right">{x.profitYear[j]}</StyledTableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <StyledTableCell align="center"><b>Total Profit</b></StyledTableCell>
                                                                <StyledTableCell align="right"><b>{x.totalProfit}</b></StyledTableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer><br />
                                                Average profit = (<sup>Total Profit</sup>/<sub>No. of years</sub>)<br />
                                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp; =(<sup>{x.totalProfit}</sup>/<sub>{x.profitYear.length}</sub>)<br />
                                                Average profit = {x.averageProfit}   <br /><br />
                                                Goodwill = Average profit X No. of years of purchase <br />
                                                &emsp;&emsp;&emsp;&emsp;&nbsp;= {x.averageProfit} X {x.years}
                                                <h4>Goodwill = {x.purchaseOfAverageProfit}</h4>
                                                <hr />
                                                <br />
                                            </div>
                                        }
                                        {x.type === "purchaseOfweightedAverageProfit" &&
                                            <div>
                                                <b>({i + 1}) Goodwill equal to {x.years} years as per weighted average profit Method :</b><br /><br />
                                                <div align="center"><b>Statement Showing Computation of Weighted Average Profit</b></div>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell align="center">Year</StyledTableCell>
                                                                <StyledTableCell align="center">Profit (₹)</StyledTableCell>
                                                                <StyledTableCell align="center">Weight</StyledTableCell>
                                                                <StyledTableCell align="center">Weighted profit (₹)</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <StyledTableCell align="center">(1)</StyledTableCell>
                                                                <StyledTableCell align="center">(2)</StyledTableCell>
                                                                <StyledTableCell align="center">(3)</StyledTableCell>
                                                                <StyledTableCell align="center">(4)(2 X 3)</StyledTableCell>
                                                            </TableRow>
                                                            {x.profitYear.map((y, j) => (
                                                                <TableRow>
                                                                    <StyledTableCell align="center">{parseInt(x.startingYear) + i}-{String(parseInt(x.startingYear) + i + 1).slice(2, 4)}</StyledTableCell>
                                                                    <StyledTableCell align="center">{x.profitYear[j]}</StyledTableCell>
                                                                    <StyledTableCell align="center">{j + 1}</StyledTableCell>
                                                                    <StyledTableCell align="center">{x.weightedProfit[j]}</StyledTableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <StyledTableCell align="center"><b></b></StyledTableCell>
                                                                <StyledTableCell align="center"><b>Total Profit</b></StyledTableCell>
                                                                <StyledTableCell align="center"><b>{x.totalWeight}</b></StyledTableCell>
                                                                <StyledTableCell align="center"><b>{x.totalWeightedProfit}</b></StyledTableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer><br />
                                                Weighted average profit = (<sup>Total Weighted profit</sup>/<sub>Total weight</sub>)<br />
                                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;=(<sup>{x.totalWeightedProfit}</sup>/<sub>{x.totalWeight}</sub>)<br />
                                                Weighted average profit = {x.WeightedAverageProfit}<br />
                                                Goodwill = Weighted average profit X No. of years of purchase<br />
                                                &emsp;&emsp;&emsp;&emsp;&nbsp;= {x.WeightedAverageProfit} X {x.years}
                                                <h4>Goodwill = {x.purchaseOfweightedAverageProfit}</h4>
                                                <hr />
                                                <br />
                                            </div>
                                        }
                                        {x.type === "purchaseOfSuperProfit" &&
                                            <div>
                                                <b>({i + 1}) On the basis of {x.years} years purchase of super profit :</b><br /><br />
                                                <div align="center"><b>Statement Showing Computation of Goodwill</b></div>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell align="left">Step No.</StyledTableCell>
                                                                <StyledTableCell align="center">Particulars</StyledTableCell>
                                                                <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(1)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Capital Employed<br />
                                                                    &emsp;= Total assests - Total external liabilities <br />
                                                                    &emsp;= {x.businessAssets} - {x.businessLiabilities} <br />
                                                                    &emsp;Capital Employed = {x.capitalEmployed}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.capitalEmployed}</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(2)</StyledTableCell>
                                                                <StyledTableCell align="left">Expected Rate of return</StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.expectedRateOfReturn} %</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(3)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Expected profit = Capital employed X Expected rate of return<br />
                                                                    &emsp;&emsp;= {x.capitalEmployed} X {x.expectedRateOfReturn} % = {x.expectedProfit}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.expectedProfit}</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(4)</StyledTableCell>
                                                                <StyledTableCell align="left">Average Profit : (Weighted average profit)</StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.weightedAverageProfit}</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(5)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Super profit = Average profit - Expected profit<br />
                                                                    &emsp;&emsp; = {x.weightedAverageProfit} - {x.expectedProfit} = {x.superProfit}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}></StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(6)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Goodwill = Super profit X no. of years of purchase<br />
                                                                    &emsp;&emsp; = {x.superProfit} X {x.years}<br />
                                                                    Goodwill = {x.purchaseOfSuperProfit}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.purchaseOfSuperProfit}</StyledTableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer><br />
                                                <hr />
                                                <br />
                                            </div>
                                        }
                                        {x.type === "capitalizationProfitMethod" &&
                                            <div>
                                                <b>({i + 1}) Profit Capitalization Method :</b><br /><br />
                                                <div align="center"><b>Statement Showing Computation of Goodwill</b></div>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell align="left">Step No.</StyledTableCell>
                                                                <StyledTableCell align="center">Particulars</StyledTableCell>
                                                                <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(1)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Capital Employed<br />
                                                                    &emsp;= Total assests - Total external liabilities <br />
                                                                    &emsp;= {x.businessAssets} - {x.businessLiabilities} <br />
                                                                    &emsp;Capital Employed = {x.capitalEmployed}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.capitalEmployed}</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(2)</StyledTableCell>
                                                                <StyledTableCell align="left">Expected Rate of return</StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.expectedRateOfReturn} %</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(3)</StyledTableCell>
                                                                <StyledTableCell align="left">Average Profit : (Weighted average profit)</StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.weightedAverageProfit}</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(4)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Capitalised profit = (<sup>Average profit</sup>/<sub>Expected rate of return</sub> X 100)<br />
                                                                    &emsp;&emsp;=(<sup>{x.weightedAverageProfit}</sup>/<sub>{x.expectedRateOfReturn}</sub> X 100) = <b>{x.capitalizedProfit}</b>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.capitalizedProfit}</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(5)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Goodwill = Capitalised profit - Capital employed <br />
                                                                    &emsp;&emsp; = {x.capitalizedProfit} - {x.capitalEmployed} <br />
                                                                    <b>Goodwill = {x.capitalizationProfitMethod} </b>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.capitalizationProfitMethod}</StyledTableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer><br />
                                                <hr />
                                                <br />
                                            </div>
                                        }
                                        {x.type === "capitalizationSuperProfit" &&
                                            <div>
                                                <b>({i + 1}) Super profit capitalisation method :</b><br /><br />
                                                <div align="center"><b>Statement Showing Computation of Goodwill</b></div>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell align="left">Step No.</StyledTableCell>
                                                                <StyledTableCell align="center">Particulars</StyledTableCell>
                                                                <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(1)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Capital Employed<br />
                                                                    &emsp;= Total assests - Total external liabilities <br />
                                                                    &emsp;= {x.businessAssets} - {x.businessLiabilities} <br />
                                                                    &emsp;Capital Employed = {x.capitalEmployed}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.capitalEmployed}</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(2)</StyledTableCell>
                                                                <StyledTableCell align="left">Expected Rate of return</StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.expectedRateOfReturn} %</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(3)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Expected profit = Capital employed X Expected rate of return<br />
                                                                    &emsp;&emsp;= {x.capitalEmployed} X {x.expectedRateOfReturn} % = {x.expectedProfit}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.expectedProfit}</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(4)</StyledTableCell>
                                                                <StyledTableCell align="left">Average Profit : (Weighted average profit)</StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.weightedAverageProfit}</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(5)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Super profit = Average profit - Expected profit<br />
                                                                    &emsp;&emsp; = {x.weightedAverageProfit} - {x.expectedProfit} = {x.superProfit}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.superProfit}</StyledTableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>(6)</StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Goodwill = (<sup>Super profit</sup>/<sub>Expected rate of return</sub> X 100) = (<sup>{x.superProfit}</sup>/<sub>{x.expectedRateOfReturn}</sub> X 100)
                                                                    Goodwill = {x.purchaseOfSuperProfit}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{x.purchaseOfSuperProfit}</StyledTableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer><br />
                                                <hr />
                                                <br />
                                            </div>
                                        }

                                    </div>
                                ))}
                            </div>
                            <br /><br />
                        </div>
                    </div> :
                        <div></div>}
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  AddButton,
  BootstrapTextField,
  ColorButton,
  CustomColorButton,
  DeleteButton,
  LinkButton,
  RedditTextField,
  ResponseButton,
  ReverseLinkButton,
  StyledGrid,
  StyledTableCell,
  StyledTableRow,
} from "../../MelzoLibrary/StyledComponents";
import { Container } from "@mui/system";

import Icon from "@mui/material/Icon";

import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";

import "react-datepicker/dist/react-datepicker.css";

export default function StandardDeviation() {
  const data = [
    {
      averageVAlue: 50,
      totalOfOriginalValues: 350,
      originalArray: [52, 58, 40, 60, 54, 38, 48],
      array_diffof_values_with_Avarage: [2, 8, -10, 10, 4, -12, -2],
      total_array_diffof_values_with_Avarage: 0,
      array_squar_diff: [4, 64, 100, 100, 16, 144, 4],
      total_array_squar_diff: 432,
      s_squar: 61.71,
      standard_deviation_s: 7.86,
    },
  ];

  const NormalNumberRegex = new RegExp(/^[0-9]+$/);

  const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);

  const chartState = {
    selected: 0,
  };

  // const [value, onChange] = useState(new Date());
  // const [value2, onChange2] = useState(new Date());

  class OperatingRatio extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: "rainfall",
        type: "mm",
        sampleString: "52 58 40 60 54 38 48",
        sampleStringArray: [52, 58, 40, 60, 54, 38, 48],
        averageValue: 50,
        sampleStringLength: 7,

        editModename: false,
        editModetype: false,
        editModesampleString: false,
        solutionShow: false,
        // tableModeChartModeSwitch: true,
        editModeButtonChecker: false,
        editModeButton: false,
        submitChecker: true,
      };
      this.eventHandler = this.eventHandler.bind(this);
      this.editModeOff = this.editModeOff.bind(this);
      this.stringReducer = this.stringReducer.bind(this);
      this.autoSave = this.autoSave.bind(this);
      this.moveLeftAndRight = this.moveLeftAndRight.bind(this);
    }

    //#region EVENT FUNCTIONALITIES START

    eventHandler(event) {
      event.preventDefault();
      console.log("first console");
      switch (event.target.name) {
        case "sampleString":
          this.setState(
            {
              sampleString: this.stringReducer(event.target.value),
            },
            () => {
              this.fillDetails();
              console.log("sampleString", "sampleString");
            }
          );

          break;
        case "name":
          this.setState(
            {
              name: event.target.value,
            },
            () => {
              this.fillDetails();
            }
          );
          break;
      }
    }

    handleChange(event) {
      this.setState({
        type: event.target.value,
      });
    }

    stringReducer(string) {
      let stringArray = string.split(" ");
      //   console.log("string", string);

      for (let i in stringArray) {
        if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === "") {
          // console.log(i)
          stringArray.splice(i, 1);
        }
      }

      if (stringArray[0] === null) {
        stringArray[0] = 0;
      }
      const averageValue = stringArray.reduce((a, b) => Number(a) + Number(b), 0) / stringArray.length;
      //   console.log("string", averageValue);

      //   console.log("stringArray", stringArray);

      this.setState({
        sampleStringLength: stringArray.length,
        sampleStringArray: stringArray,
        // averageValue: averageValue,
      });

      return string;
    }

    fillDetails() {
      let flag = true;

      if (this.state.name === "") {
        flag = false;
      }

      if (flag === true) {
        this.setState({
          editModeButtonChecker: !flag,
        });
      } else {
        this.setState({
          editModeButtonChecker: !flag,
        });
      }
    }

    autoSave(event) {}

    //#endregion EVENT FUNCTIONALTIES END

    //#region BUTTON PANEL START

    onSubmit() {
      const values = {
        averageVAlue: 0,
        totalOfOriginalValues: 0,
        originalArray: [],
        array_diffof_values_with_Avarage: [],
        total_array_diffof_values_with_Avarage: 0,
        array_squar_diff: [],
        total_array_squar_diff: 0,
        s_squar: 0,
        standard_deviation_s: 0,
      };

      const makeThreeDigitPression = (value) => {
        // console.log("Number ====?", value, Number.parseFloat(value).toFixed(3));
        // console.log("Number ====?", value, Number.parseInt(value).toFixed(3));

        return Number.parseFloat(value).toFixed(1);
      };

      let originalArray = JSON.parse(JSON.stringify(this.state.sampleStringArray));
      values.originalArray = originalArray;
      //   console.log("originalArray step 1", originalArray);

      const totalOfOriginalValues = originalArray.reduce((a, b) => Number(a) + Number(b), 0);
      values.totalOfOriginalValues = totalOfOriginalValues;
      //   console.log("originalArray step 1", originalArray);

      const averageValue = totalOfOriginalValues / originalArray.length;
      //   console.log("string", averageValue);

      values.averageVAlue = makeThreeDigitPression(averageValue);
      //finding the average of original values

      originalArray.map((a, i) => {
        values.array_diffof_values_with_Avarage.push(makeThreeDigitPression(a - averageValue));
      });

      //pushing the values in array

      const totalOfDiff = values.array_diffof_values_with_Avarage.reduce((a, b) => Number(a) + Number(b), 0);
      console.log("totalOfDiff", totalOfDiff);

      values.total_array_diffof_values_with_Avarage = makeThreeDigitPression(totalOfDiff);

      values.array_diffof_values_with_Avarage.map((a, i) => {
        values.array_squar_diff.push(makeThreeDigitPression(a ** 2));
      });

      const totalOfSquarOfDiff = values.array_squar_diff.reduce((a, b) => Number(a) + Number(b), 0);
      console.log("totalOfSquarOfDiff", totalOfSquarOfDiff);

      values.total_array_squar_diff = makeThreeDigitPression(totalOfSquarOfDiff);

      //we get all our values require for for

      //now solve further problem

      let s_squar_Temp = makeThreeDigitPression(totalOfSquarOfDiff) / originalArray.length;
      console.log("s_squar_Temp", s_squar_Temp);
      values.s_squar = makeThreeDigitPression(s_squar_Temp);
      let standard_deviation_s_Temp = Number.parseFloat(Math.sqrt(s_squar_Temp)).toFixed(2);

      values.standard_deviation_s = standard_deviation_s_Temp;
      // now hear we get our answer
      //   console.log("standard_deviation_s", standard_deviation_s_Temp);
      data.push(JSON.parse(JSON.stringify(values)));

      //   data.push(values);
      console.log("values ==>", values);

      chartState.selected += 1;

      this.setState({});

      console.log(JSON.stringify(values));
    }

    onReset() {}

    switchTableToChartAndBack() {}

    showSolutionOnOff() {
      this.setState({
        solutionShow: !this.state.solutionShow,
      });
    }

    moveLeftAndRight(event) {
      if (event.target.name === "Left") {
        chartState.selected = chartState.selected - 1;
        this.setState({});
      } else if (event.target.name === "Right") {
        chartState.selected += 1;
        this.setState({});
      }
    }

    //#endregion BUTTON PANEL END

    //#region EDIT BUTTON START

    buttonMaker(event) {
      switch (event.target.name) {
        case "sampleString":
          this.setState({
            editModesampleString: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
        case "name":
          this.setState({
            editModename: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
        case "type":
          this.setState({
            editModetype: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
      }
    }

    editModeOff() {
      this.setState({
        sampleString: this.state.sampleStringArray.reduce((a, b) => {
          return a + " " + b;
        }),

        submitChecker: true,

        editModename: false,
        editModetype: false,
        editModesampleString: false,
        editModeButton: false,
      });
    }

    //#endregion EDIT BUTTON END

    render() {
      return (
        <div style={{fontSize:"20px"}}>
          <br />
          <div label="InputFieldTables" align="left">
          એક બેટ્સમૅનના છેલ્લી સાત મૅચમાં નીચે મુજબ રન થાય છે : <br />
            <br />
            {/* this is editable fild in Question ====================>*/}
            {this.state.editModesampleString ? (
              <TextField
                style={{ width: "50%", padding: "-10px" }}
                name="sampleString"
                id="outlined-base"
                error={this.state.sampleString === ""}
                value={this.state.sampleString}
                onChange={this.eventHandler}
                variant="standard"
                autoComplete="off"
              />
            ) : (
              <a className="txt" name="sampleString" onClick={this.buttonMaker.bind(this)}>
                {" "}
                {this.state.sampleString}
              </a>
            )}{" "}
            &nbsp;
            <br />
            {/* <br /> */}
            {/* 52, 58, 40,60,54,38,48 <br /> */}
            <br />
            આ માહિતી પરથી બૅટ્સમૅનના રનનું વિચરણ શોધો તથા પ્રમાણિત વિચરણ પણ શોધો.<br /><br />
            {/* ==============================================
			             Question ends here */}
            {this.state.editModeButton ? (
              <ColorButton
                disabled={this.state.editModeButtonChecker}
                style={{ "margin-top": "-20px", fontSize:"16px" }}
                align="right"
                onClick={this.editModeOff.bind(this)}
              >
               સંપાદિત મોડ બંધ કરો
              </ColorButton>
            ) : (
              <span></span>
            )}
            <br />
            <br />
            {/* ==============================================
			    above button for close the input fild , save the changes in Question inputs and show the input fild values */}
            <div align="left" label="buttons">
              <AddButton
                style={{ width: "15%", fontSize:"20px" }}
                disabled={!this.state.submitChecker}
                onClick={this.onSubmit.bind(this)}
              >
                રકમની ગણતરી કરો
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
              <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>
              ઉકેલ બતાવો / છુપાવો
              </AddButton>
            </div>
            {/* ============================================================================
			                just navigation buttons
			=============================================================================== */}
            <div align="right" label="sumNavigator">
              <AddButton
                name="Left"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected - 1 < 0}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ◄
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {chartState.selected + 1}
              &nbsp;&nbsp;&nbsp;
              <AddButton
                name="Right"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected + 1 > data.length - 1}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ►
              </AddButton>
            </div>
            {/* ============================================================================
			                navigation buttons ends here
			=============================================================================== */}
          </div>
          <br />
          <br />
          {this.state.solutionShow && (
            <div label="Answer" align="left">
              <br />
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" style={{fontSize:"20px"}}>
                      રન <br />
                        <i>x</i>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <i>x</i> - <i>x̄</i> <br /> <i>x̄</i>= 50
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        (<i>x</i> - <i>x̄</i>)^2
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {console.log("data", data)}
                    {data[chartState.selected].originalArray &&
                      data[chartState.selected].originalArray.map((x, i) => (
                        <TableRow>
                          <StyledTableCell align="center">{x}</StyledTableCell>
                          <StyledTableCell align="center">
                            {data[chartState.selected].array_diffof_values_with_Avarage[i]}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data[chartState.selected].array_squar_diff[i]}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <StyledTableCell align="center" style={{fontSize:"15px"}}> 
                        <b>કુલ = </b> &emsp;
                        <i>{data[chartState.selected].totalOfOriginalValues}</i>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b> {data[chartState.selected].total_array_diffof_values_with_Avarage}</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>{data[chartState.selected].total_array_squar_diff}</b>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <br />
              <p>
              મઘ્યક, <i>x̄</i>&nbsp; =&nbsp;
                <sup>
                  Σ<i>x</i>
                </sup>
                /<sub>n</sub>
                <br />
                <br />
                &emsp;&emsp;&emsp;&nbsp;&nbsp;= <sup>{data[chartState.selected].totalOfOriginalValues}</sup>/
                <sub>{data[chartState.selected].originalArray.length}</sub>
                <br />
                <br />
                &emsp;&emsp;&emsp;&nbsp;&nbsp;={data[chartState.selected].averageVAlue} રન
                <br />
                <br />
                વિચરણ s<sup>2</sup>=
                <sup>
                  Σ (<i>x</i> - <i>x̄</i>)^2
                </sup>
                /<sub>n</sub>
                <br />
                <br />
                &emsp;&emsp;&emsp;&nbsp;&nbsp;= &nbsp;<sup>{data[chartState.selected].total_array_squar_diff}</sup>/
                <sub>{data[chartState.selected].originalArray.length}</sub>
                <br />
                <br />
                &emsp;&emsp;&emsp;&nbsp;&nbsp;&emsp;&emsp;=&nbsp;{data[chartState.selected].s_squar}
                <br />
                <br />
                &emsp;&emsp; &#8756;&emsp; s<sup>2</sup> = &emsp;{data[chartState.selected].s_squar}
                (રન) <sup>2</sup>
                <br />
                <br />
                <br />
                પ્રમાણિત વિચલન s = &#8730; &emsp;
                <sup>
                  Σ (<i>x</i> - <i>x̄</i>)^2
                </sup>
                /<sub>n</sub>
                <br />
                <br />
                &emsp;&emsp;&nbsp;&emsp;&emsp;= &#8730; &emsp;{data[chartState.selected].s_squar}
                <br />
                <br />
                &emsp;&emsp;&nbsp;&emsp;&emsp;= &emsp;{data[chartState.selected].standard_deviation_s}
                <br />
                <br />
                &emsp;&emsp;&nbsp;&emsp; s = &emsp;{data[chartState.selected].standard_deviation_s} રન
              </p>
              {/* next line */}
              <p>
              આમ, બૅટ્સમૅનના રનનું પ્રમાણિત વિચલન &nbsp;
                {data[chartState.selected].standard_deviation_s} રન છે.{" "}
              </p>
              <p>
                <b>નોંધ: </b> પ્રમાણિત વિચલનને અવલોકનોના એકમમાં દર્શાવવામાં આવે છે. આપણે જાણીએ છીએ કે વિચરણ એ પ્રમાણિત વિચલનનો વર્ગ છે, તેથી વિચરણનો એકમ પ્રમાણિત વિચલનના 'એકમનો વર્ગ' થાય છે.
              </p>
              <p>
                {" "}
                <b>દા.ત.,</b> અવલોકનનો એકમ કિગ્રા હોય તો પ્રમાણિત વિચલનનો એકમ પણ કિગ્રા થાય છે. જ્યારે વિચરણનો એકમ (કિગ્રા)<sup>2</sup> થાય છે.
              </p>
            </div>
          )}
          <br />
          <br />
        </div>
      );
    }
  }

  return (
    <div>
      <Container fixed>
        <OperatingRatio />
      </Container>
    </div>
  );
}

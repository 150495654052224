import * as React from 'react';

import { Container } from '@mui/system';
import TextField from '@mui/material/TextField';

//#region HEADER IMPORT START

import MelzoGuru from '../MelzoLibrary/newguru.png'

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

//#endregion HEADER IMPORT END

//#region DRAWER IMPORT START

import Drawer from '@mui/material/Drawer';
import { Button } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReplayIcon from '@mui/icons-material/Replay';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import TreeItem from '@mui/lab/TreeItem';
import { useEffect } from 'react';

//#endregion DRAWER IMPORT END

//SUM IMPORT START

//#region MY SUMS START

import OperatingProfitRatio from '../SumFolder/Accounts_12/OperatingProfitRatio';
import CreditorsTurnover from '../SumFolder/Accounts_12/CreditorsTurnover';
import ComparativeProfitLossStatement from '../SumFolder/Accounts_12/ComparativeProfitLossStatement'
import ValueOfGoodwill from '../SumFolder/Accounts_12/ValueOfGoodwill'
import InvestingActivity from '../SumFolder/Accounts_12/InvestingActivity';
import ProfitLossAppropriationAccount from '../SumFolder/Accounts_12/ProfitLossAppropriationAccount'
import RegressionModelAndReliability from '../SumFolder/Statistics_12/RegressionModelAndReliability'
import DropoutRateOfStudents from '../SumFolder/Statistics_12/DropoutRateOfStudents'
import GeneralPriceIndexNumber from '../SumFolder/Statistics_12/GeneralPriceIndexNumber'
import FrequencyDistribution from '../SumFolder/Statistics_11/FrequencyDistribution'
import ContinuousFrequencyDistribution from '../SumFolder/Statistics_11/ContinuousFrequencyDistribution'
import FrequencyDistributionMidValue from '../SumFolder/Statistics_11/FrequencyDistributionMidValue'
import FrequencyDistributionPieDiagram from '../SumFolder/Statistics_11/FrequencyDistributionPieDiagram'
import MeanRainfall from '../SumFolder/Statistics_11/MeanRainfall'
import MeanTravelTime from '../SumFolder/Statistics_11/MeanTravelTime'
import MedianNumberOfCheques from '../SumFolder/Statistics_11/MedianNumberOfCheques'
import PurchaseBook from '../SumFolder/Accounts_11/PurchaseBook'
import PassJournal from '../SumFolder/Accounts_11/PassJournal'
import JournalEntries from '../SumFolder/Accounts_11/JournalEntries'
import JournalEntryAndAccountingTreatments from '../SumFolder/Accounts_11/JournalEntryAndAccountingTreatments' //*
import QuartileDecilePercentile from '../SumFolder/Statistics_11/QuartileDecilePercentile' //*
import CorrelationCoefficient from '../SumFolder/Statistics_12/CorrelationCoefficient'
import CorrelationCoefficientRank from '../SumFolder/Statistics_12/CorrelationCoefficientRank'
import FixedBaseIndexNumber from '../SumFolder/Statistics_12/FixedBaseIndexNumber'
import JournalEntryRevaluationAccount from '../SumFolder/Accounts_12/JournalEntryRevaluationAccount'
import RevaluationAccountBalanceSheet from '../SumFolder/Accounts_12/RevaluationAccountBalanceSheet'
import StatementsAsPerScheduleIII from '../SumFolder/Accounts_12/StatementsAsPerScheduleIII'
import JournalEntryForDebentures from '../SumFolder/Accounts_12/JournalEntryForDebentures'
import RectificationEntries from '../SumFolder/Accounts_11/RectificationEntries'
import AnnualDepreciationAndRate from '../SumFolder/Accounts_11/AnnualDepreciationAndRate'
import JournalEntryBillExchange from '../SumFolder/Accounts_11/JournalEntryBillExchange'
import ThreeColumnarWeeklyCashBookLedger from '../SumFolder/Accounts_11/ThreeColumnarWeeklyCashBookLedger'
import CashBookPreparation from '../SumFolder/Accounts_11/CashBookPreparation'
import RetirementOfPartnerInFirm from '../SumFolder/Accounts_12/RetirementOfPartnerInFirm'
import DissolutionOfPartnership from '../SumFolder/Accounts_12/DissolutionOfPartnership'
import SecuritiesPremiumAccount from '../SumFolder/Accounts_12/SecuritiesPremiumAccount'

//#endregionMY SUMS END

//#region SHIHAB SUMS START

import WorldTradeReport from '../SumFolder/Economics_12/WorldTradeReport'
import ClusterBarDiagram from '../SumFolder/Economics_11/ClusterBarDiagram'
import DemandCurve from '../SumFolder/Economics_11/DemandCurve'
import Lawofdemand from '../SumFolder/Economics_11/Lawofdemand'

//#endregion SHIHAB SUMS END

//#region ONKAR SUMS START

import StandardDeviation from '../SumFolder/Statistics_11/StandardDeviation'
import SkewnessAndItsCoefficient from '../SumFolder/Statistics_11/SkewnessAndItsCoefficient'
import Permutation from '../SumFolder/Statistics_11/Permutation'
import DrawRandomSample from '../SumFolder/Statistics_11/DrawRandomSample'
import DomainCo_domainRange from '../SumFolder/Statistics_11/DomainCo_domainRange'
import FindGP from '../SumFolder/Statistics_11/FindGP'

//#endregion ONKAR SUMS END


//#endregion SUM IMPORT END

//#region GUJARATI SUM IMPORT START

import OperatingProfitRatioGuj from '../SumFolderGujarati/Accounts_12/OperatingProfitRatio';
import CreditorsTurnoverGuj from '../SumFolderGujarati/Accounts_12/CreditorsTurnover';
import ComparativeProfitLossStatementGuj from '../SumFolderGujarati/Accounts_12/ComparativeProfitLossStatement'
import FrequencyDistributionGuj from '../SumFolderGujarati/Statistics_11/FrequencyDistribution'
import ContinuousFrequencyDistributionGuj from '../SumFolderGujarati/Statistics_11/ContinuousFrequencyDistribution'
import FrequencyDistributionMidValueGuj from '../SumFolderGujarati/Statistics_11/FrequencyDistributionMidValue'
import FrequencyDistributionPieDiagramGuj from '../SumFolderGujarati/Statistics_11/FrequencyDistributionPieDiagram'
import MeanRainfallGuj from '../SumFolderGujarati/Statistics_11/MeanRainfall'
import MeanTravelTimeGuj from '../SumFolderGujarati/Statistics_11/MeanTravelTime'
import MedianNumberOfChequesGuj from '../SumFolderGujarati/Statistics_11/MedianNumberOfCheques'
import RegressionModelAndReliabilityGuj from '../SumFolderGujarati/Statistics_12/RegressionModelAndReliability'
import DropoutRateOfStudentsGuj from '../SumFolderGujarati/Statistics_12/DropoutRateOfStudents'
import GeneralPriceIndexNumberGuj from '../SumFolderGujarati/Statistics_12/GeneralPriceIndexNumber'
import ValueOfGoodwillGuj from '../SumFolderGujarati/Accounts_12/ValueOfGoodwill'
import InvestingActivityGuj from '../SumFolderGujarati/Accounts_12/InvestingActivity'
import ProfitLossAppropriationAccountGuj from '../SumFolderGujarati/Accounts_12/ProfitLossAppropriationAccount'
import JournalEntriesGuj from '../SumFolderGujarati/Accounts_11/JournalEntries'
import PassJournalGuj from '../SumFolderGujarati/Accounts_11/PassJournal'
import PurchaseBookGuj from '../SumFolderGujarati/Accounts_11/PurchaseBook'
import FixedBaseIndexNumberGuj from '../SumFolderGujarati/Statistics_12/FixedBaseIndexNumber'
import CorrelationCoefficientGuj from '../SumFolderGujarati/Statistics_12/CorrelationCoefficient'
import CorrelationCoefficientRankGuj from '../SumFolderGujarati/Statistics_12/CorrelationCoefficientRank'
import JournalEntryRevaluationAccountGuj from '../SumFolderGujarati/Accounts_12/JournalEntryRevaluationAccount'
import RevaluationAccountBalanceSheetGuj from '../SumFolderGujarati/Accounts_12/RevaluationAccountBalanceSheet'
import JournalEntryForDebenturesGuj from '../SumFolderGujarati/Accounts_12/JournalEntryForDebentures'
import StatementsAsPerScheduleIIIGuj from '../SumFolderGujarati/Accounts_12/StatementsAsPerScheduleIII'
import RectificationEntriesGuj from '../SumFolderGujarati/Accounts_11/RectificationEntries'
import AnnualDepreciationAndRateGuj from '../SumFolderGujarati/Accounts_11/AnnualDepreciationAndRate'
import JournalEntryBillExchangeGuj from '../SumFolderGujarati/Accounts_11/JournalEntryBillExchange'
import ThreeColumnarWeeklyCashBookLedgerGuj from '../SumFolderGujarati/Accounts_11/ThreeColumnarWeeklyCashBookLedger'
import CashBookPreparationGuj from '../SumFolderGujarati/Accounts_11/CashBookPreparation'
import RetirementOfPartnerInFirmGuj from '../SumFolderGujarati/Accounts_12/RetirementOfPartnerInFirm'
import DissolutionOfPartnershipGuj from '../SumFolderGujarati/Accounts_12/DissolutionOfPartnership'

import WorldTradeReportGuj from '../SumFolderGujarati/Economics_12/WorldTradeReport'
import ClusterBarDiagramGuj from '../SumFolderGujarati/Economics_11/ClusterBarDiagram'
import DemandCurveGuj from '../SumFolderGujarati/Economics_11/DemandCurve'
import LawofdemandGuj from '../SumFolderGujarati/Economics_11/Lawofdemand'

import StandardDeviationGuj from '../SumFolderGujarati/Statistics_11/StandardDeviation'
import SkewnessAndItsCoefficientGuj from '../SumFolderGujarati/Statistics_11/SkewnessAndItsCoefficient'
import PermutationGuj from '../SumFolderGujarati/Statistics_11/Permutation'
import DrawRandomSampleGuj from '../SumFolderGujarati/Statistics_11/DrawRandomSample'
import DomainCo_domainRangeGuj from '../SumFolderGujarati/Statistics_11/DomainCo_domainRange'
import FindGPGuj from '../SumFolderGujarati/Statistics_11/FindGP'

//GUJARATI SUM IMPORT END

export default function Homepage() {

    const [currentPage, setCurrentPage] = React.useState("Securities Premium Account")

    const contents = [
        {//STANDARD 11
            chapter: "Standard 11",
            subContent: [
                {
                    type: "Accounts ",
                    content:
                        [{//1-4
                            chapter: "Journal",
                            subContent: [{
                                title: "Pass Journal"
                            }, {
                                title: "Journal Entry"
                            }]
                        }, {//1-6
                            chapter: "Subsidiary Books",
                            subContent: [{
                                title: "Purchase Book"
                            }]
                        }, {//1-9
                            chapter: "Ledger-Posting",
                            subContent: [{
                                title: "Ledger of a three columnar weekly cash book"
                            }]
                        }, {//1-11
                            chapter: "Bank Reconciliation Statement",
                            subContent: [{
                                title: "Cash Book Preparation"
                            }]
                        }/* {
                            chapter: "Accounting Equation and Business Transactions",
                            subContent: [{
                                title: "Journal Entry & Accounting Treatments"
                            }]
                        }*/, {//2-1
                            chapter: "Rectification of Errors",
                            subContent: [{
                                title: "Rectification of Entries"
                            }]
                        }, {//2-2
                            chapter: "Depreciation Accounts",
                            subContent: [{
                                title: "Annual Depreciation And Rate"
                            }]
                        }, {//2-4
                            chapter: "Bills of Exchange",
                            subContent: [{
                                title: "Journal Entry For Bill Exchange"
                            }]
                        },]
                },
                {
                    type: "Statistics ",
                    content:
                        [{//2
                            chapter: "Presentation of Data",
                            subContent: [{
                                title: "Continuous Frequency Distribution"
                            }, {
                                title: "Frequency Distribution"
                            }, {
                                title: "Frequency Distribution and Mid Value"
                            }, {
                                title: "Frequency Distribution Pie Diagram"
                            },]
                        }, {//3
                            chapter: "Measures of Central Tendency",
                            subContent: [{
                                title: "Mean Rainfall"
                            }, {
                                title: "Mean Travel Time"
                            }, {
                                title: "Median Number of Cheques"
                            }]
                        }, {//4
                            chapter: "Measures of Dispersion",
                            subContent: [{
                                title: "Standard Deviation"
                            },]
                        }, {//5
                            chapter: "Skewness of Frequency Distribution",
                            subContent: [{
                                title: "Skewneess and its Coefficient"
                            },]
                        }, {//6
                            chapter: "Permutations, Combinations and Binomial Expansion",
                            subContent: [{
                                title: "Permutation"
                            },]
                        }, {//7
                            chapter: "Sampling Methods",
                            subContent: [{
                                title: "Drawing Random Sample"
                            },]
                        }, {//8
                            chapter: "Function",
                            subContent: [{
                                title: "Domain & Co-domain Range"
                            },]
                        }, {//9
                            chapter: "Geometric Progression",
                            subContent: [{
                                title: "Finding Geometric Progression"
                            },]
                        },]
                },
                {
                    type: "Economics  ",
                    content:
                        [{//1
                            chapter: "Economics: An Introduction",
                            subContent: [{
                                title: "Demand Curve"
                            }, {
                                title: "Cluster Bar Diagram"
                            },]
                        },
                        {//2
                            chapter: "Demand",
                            subContent: [{
                                title: "Law of Demand"
                            },]
                        }]
                }
            ]
        },
        {//STANDARD 12
            chapter: "Standard 12",
            subContent: [
                {
                    type: "Accounts",
                    content:
                        [{//1-1
                            chapter: "Introduction To Partnership",
                            subContent: [{
                                title: "P/L Appropriation/Partners Capital-Current Acc.",
                            }]
                        }, {//1-3
                            chapter: "Valuation of Goodwill",
                            subContent: [{
                                title: "Value Of Goodwill",
                            }]
                        }, {//1-4
                            chapter: "Reconstruction of Partnership",
                            subContent: [{
                                title: "Journal Entry and Revaluation Account",
                            }]
                        }, {//1-5
                            chapter: "Admission of a Partner",
                            subContent: [{
                                title: "Revaluation Account, Partners Capital Acc & Balance Sheet",
                            }]
                        }, {//1-6
                            chapter: "Retirement / Death of a Partner",
                            subContent: [{
                                title: "Retirement of partner in firm",
                            }]
                        }, {//1-7
                            chapter: "Dissolution of Partnership Firm",
                            subContent: [{
                                title: "Partner Accounts' after Dissolution",
                            }]
                        }, {//2-1
                            chapter: "Accounting for Share Capital",
                            subContent: [{
                                title: "Securities Premium Account",
                            }]
                        }, {//2-2
                            chapter: "Accounting for Debentures",
                            subContent: [{
                                title: "Journal Entry for Debentures",
                            }]
                        }, {//2-3
                            chapter: "Company Final Accounts",
                            subContent: [{
                                title: "Balance Sheet as per schedule-III",
                            }]
                        }, {//2-4
                            chapter: "Anaylsis of Financial Statement",
                            subContent: [{
                                title: "Comparative Profit Loss Statement",
                            }]
                        }, {//2-5
                            chapter: "Accounting Ratio and Anaylsis",
                            subContent: [
                                {
                                    title: "Creditors TurnOver and Payment Period",
                                }, {
                                    title: "Operating Profit Ratio",
                                }
                            ]
                        }, {//2-6
                            chapter: "Cash Flow Statement",
                            subContent: [{
                                title: "Investing Activity",
                            }]
                        },]
                }, {
                    type: "Statistics",
                    content: [{//1-1
                        chapter: "Index Number",
                        subContent: [{
                            title: "General Price Index Number"
                        }, {
                            title: "Fixed Base Index Number"
                        }]
                    }, {//1-2
                        chapter: "Linear Correlation",
                        subContent: [{
                            title: "Correlation Coefficient",
                        }, {
                            title: "Correlation Coefficient Rank"
                        }]
                    }, {//1-3
                        chapter: "Linear Regression",
                        subContent: [{
                            title: "Regression Mode and it's reliability",
                        }]
                    }, {//1-4
                        chapter: "Time Series",
                        subContent: [{
                            title: "Dropout Rate of Students"
                        }]
                    }]
                }, {
                    type: "Economics",
                    content: [{//1-1
                        chapter: "Graphs in Economics",
                        subContent: [{
                            title: "World Trade Report",
                        }]
                    },]
                }
            ]
        },
        {//STANDARD 11 GUJ
            chapter: "ધોરણ 11",
            subContent: [
                {
                    type: "એકાઉન્ટ્સ ",
                    content: [{//1-4
                        chapter: "આમનોંધ ",
                        subContent: [
                            {
                                title: "આમનોંધ"
                            }, {
                                title: "રોજમેળ-નોંધ"
                            }
                        ]
                    }, {//1-6
                        chapter: " પેટાનોંધો",
                        subContent: [
                            {
                                title: "ખરીદનોંધ"
                            }
                        ]
                    }, {//1-9
                        chapter: "ખાતાવહી-ખતવણી",
                        subContent: [{
                            title: "ત્રણ ખાનાંવાળો અઠવાડિક"
                        }]
                    }, {//1-11
                        chapter: "બૅન્ક સિલકમેળ",
                        subContent: [{
                            title: "રોકડમેળ"
                        }]
                    }, {//2-1
                        chapter: "ભૂલ સુધારણા ",
                        subContent: [{
                            title: "ભૂલ સુધારણા"
                        }]
                    }, {//2-2
                        chapter: "ઘસારાના હિસાબો",
                        subContent: [{
                            title: "વાર્ષિક ઘસારાનો દર"
                        }]
                    }, {//2-4
                        chapter: "હુંડીઓ",
                        subContent: [{
                            title: "હુંડીઓની આમનોંધ"
                        }]
                    },]
                },
                {
                    type: "આંકડાશાસ્ત્ર ",
                    content: [
                        {//2
                            chapter: " મહિતિનુ નિરૂપણ",
                            subContent: [
                                {
                                    title: "સતત આવૃત્તિ-વિતરણ"
                                }, {
                                    title: "આવૃત્તિ-વિતરણ"
                                }, {
                                    title: "મધ્યકિંમત દર્શાવતું આવૃત્તિ-વિતરણ"
                                }, {
                                    title: "વૃતાંશ-આકૃતિ"
                                }
                            ]
                        }, {//3
                            chapter: "મધ્યવર્તી સ્થિતિનાં માપ",
                            subContent: [
                                {
                                    title: "વરસાદનો મધ્યક"
                                }, {
                                    title: "પ્રવાસના સમયનો મધ્યક"
                                }, {
                                    title: "ભરાયેલા ચેકની મધ્યસ્થ"
                                }
                            ]
                        }, {//4
                            chapter: "પ્રસારમાન",
                            subContent: [{
                                title: "પ્રમાણિત વિચરણ"
                            },]
                        }, {//5
                            chapter: "આવૃત્તિ-વિતરણની વિષમતા",
                            subContent: [{
                                title: "વિષમતા અને વિષમતાંક"
                            },]
                        }, {//6
                            chapter: "ક્રમચય, સંચય અને દ્વિપદી વિસ્તરણ",
                            subContent: [{
                                title: "ક્રમચય"
                            },]
                        }, {//7
                            chapter: "નિદર્શન પદ્ધતિઓ",
                            subContent: [{
                                title: "યાદચ્છિક નિદર્શન"
                            },]
                        }, {//8
                            chapter: "વિધેય",
                            subContent: [{
                                title: "પ્રદેશ, સહપ્રદેશ અને વિસ્તાર"
                            },]
                        }, {//9
                            chapter: "ગુણોત્તર-શ્રેણી ",
                            subContent: [{
                                title: "ગુણોત્તર-શ્રેણી"
                            },]
                        },
                    ]
                },
                {
                    type: "અર્થશાસ્ત્ર  ",
                    content:
                        [{//1
                            chapter: "અર્થશાસ્ત્ર વિષય પ્રવેશ",
                            subContent: [{
                                title: "માંગ વક્ર"
                            }, {
                                title: "પાસપાસેની સ્તંભાકૃતિ"
                            },]
                        },
                        {//2
                            chapter: "માંગ",
                            subContent: [{
                                title: "માંગનો નિયમ"
                            },]
                        }]
                }
            ]
        },
        {//STANDARD 12 GUJ
            chapter: "ધોરણ 12",
            subContent: [
                {
                    type: "એકાઉન્ટ્સ",
                    content: [{//1-1
                        chapter: "ભાગીદારી : વિષય-પ્રવેશ",
                        subContent: [
                            {
                                title: "નફા-નુકસાન ફાળવણી ખાતું/ભાગીદારોનાં મૂડી-ચાલુ ખાતાં"
                            }
                        ]
                    }, {//1-3
                        chapter: "પાઘડીનું મૂલ્યાંકન",
                        subContent: [
                            {
                                title: "પાઘડીનું મૂલ્ય"
                            }
                        ]
                    }, {//1-4
                        chapter: "ભાગીદારીનું પુનર્ગઠન",
                        subContent: [{
                            title: "પેઢીના ચોપડામાં આમનોંધ અને પુન:મૂલ્યાંકન ખાતું",
                        }]
                    }, {//1-5
                        chapter: "ભાગીદારનો પ્રવેશ",
                        subContent: [{
                            title: "પુન:મૂલ્યાંકન ખાતે, ભાગીદારોના મૂડી ખાતે અને પાકું સરવૈયું",
                        }]
                    }, {//1-6
                        chapter: "ભાગીદારની નિવૃત્તિ / મૃત્યુ",
                        subContent: [{
                            title: "પેઢીમાં ભાગીદારની નિવૃત્તિ",
                        }]
                    }, {//1-7
                        chapter: "ભાગીદારી પેઢીનું વિસર્જન",
                        subContent: [{
                            title: "વિસર્જન પછી ભાગીદારોનાં ખાતાં",
                        }]
                    }, {//2-2
                        chapter: "ડિબેન્ચરના હિસાબો",
                        subContent: [{
                            title: "ડિબેન્ચર માટે આમનોંધ",
                        }]
                    }, {//2-3
                        chapter: "કંપનીના વાર્ષિક હિસાબો",
                        subContent: [{
                            title: "પરિશિષ્ટ III મુજબ પાકું સરવૈયું",
                        }]
                    }, {//2-4
                        chapter: "નાણાકીય પત્રકોનું વિશ્લેષણ",
                        subContent: [
                            {
                                title: "તુલનાત્મક નફા-નુક્શાનનું પત્રક"
                            }
                        ]
                    }, {//2-5
                        chapter: "હિસાબી ગુણોત્તરો અને વિશ્લેષણ",
                        subContent: [
                            {
                                title: "લેણદારોનો ઉથલો અને ચૂકવણીની મુદ્દત"
                            }, {
                                title: "કામગીરી નફા ગુણોત્તર"
                            }
                        ]
                    }, {//2-6
                        chapter: "રોકડ પ્રવાહ પત્રક",
                        subContent: [
                            {
                                title: "રોકાણની પ્રવૃત્તિ"
                            }
                        ]
                    },
                    ]
                }, {
                    type: "આંકડાશાસ્ત્ર",
                    content: [{//1-1
                        chapter: "સુરેખ નિયત સંબંધ",
                        subContent: [
                            {
                                title: "નિયતસંબંધ મોડેલ"
                            }, {
                                title: "અચલ આધારે સૂચક આંક"
                            }
                        ]
                    }, {//1-2
                        chapter: "સુરેખ સહસંબંધ",
                        subContent: [
                            {
                                title: "સહસંબંધાંક"
                            }, {
                                title: "ક્રમાંક સહસંબંધાંક"
                            }
                        ]
                    }, {//1-3
                        chapter: "સામયિક શ્રેણી",
                        subContent: [
                            {
                                title: "અભ્યાસ છોડનાર વિદ્યાર્થીઓનો દર"
                            }
                        ]
                    }, {//1-4
                        chapter: "સૂચક આંક",
                        subContent: [
                            {
                                title: "ભાવનો સામાન્ય સૂચક આંક"
                            }
                        ]
                    }
                    ]
                }, {
                    type: "અર્થશાસ્ત્ર",
                    content: [{//1-1
                        chapter: "અર્થશાસ્ત્રમાં આલેખ",
                        subContent: [{
                            title: "વર્લ્ડ ટ્રેડ રિપૉર્ટ",
                        }]
                    },]
                }
            ]
        }
    ]

    const [drawerState, setDrawerState] = React.useState(false)

    const drawerONOFF = () => {
        setDrawerState(!drawerState)
    }

    const changePage = (event) => {
        setCurrentPage(event)
    }

    let tempTray = ''

    const refreshPage = () => {

    }

    useEffect(() => {
        setCurrentPage(currentPage)
    }, [currentPage])


    class AppHeader extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
            })
        }

        changeCurrentPage(event) {
            drawerONOFF()
            changePage(event.title)
        }

        render() {
            return (
                <div>
                    <AppBar position='static' style={{ boxShadow: 'none', backgroundColor: 'white' }}>
                        <Container style={{ backgroundColor: 'white' }} maxWidth='xl'>
                            <Toolbar style={{ backgroundColor: 'white', width: '100%' }}>

                                <React.Fragment key="1">
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                        onClick={drawerONOFF}
                                    >
                                        <MenuIcon style={{ color: "black" }} />
                                    </IconButton>

                                    <Drawer
                                        anchor='left'
                                        open={drawerState}
                                        onClose={drawerONOFF}
                                        sx={{
                                            width: "240",
                                            flexShrink: 0,
                                            '& .MuiDrawer-paper': {
                                                width: "240",
                                                boxSizing: 'border-box',
                                            },
                                        }}
                                        style={{ flexWrap: "wrap" }}
                                    >
                                        <h1>&nbsp;SELECT CHAPTER&nbsp;</h1>
                                        <TreeView
                                            aria-label="file system navigator"
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                            sx={{ height: 240, flexGrow: 1, maxWidth: '20', overflowX: 'none', flexWrap: "wrap" }}>
                                            {contents.map((x, i) => (
                                                <div>
                                                    <TreeItem key={x.chapter} nodeId={x.chapter} label={x.chapter}>
                                                        {x.subContent.map((y, j) => (
                                                            <TreeItem key={y.type} nodeId={y.type} label={y.type}>
                                                                {y.content.map((z, k) => (
                                                                    <TreeItem key={z.chapter} nodeId={z.chapter} label={z.chapter}>
                                                                        {z.subContent.map((xz, l) => (
                                                                            <u><TreeItem key={xz.title} nodeId={xz.title} label={xz.title} onClick={this.changeCurrentPage.bind(this, xz)}></TreeItem></u>
                                                                        ))}
                                                                    </TreeItem>
                                                                ))}
                                                            </TreeItem>
                                                        ))}
                                                    </TreeItem></div>
                                            ))}
                                        </TreeView>
                                    </Drawer>
                                </React.Fragment>

                                <img src={MelzoGuru} width="150" height="60"></img>
                                &emsp;

                                <Typography
                                    style={{ color: '#1CC9BE', alignItems: 'center', justifyContent: 'center', width: '100%' }}
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    href="/"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'montserrat',
                                        fontWeight: 700,
                                        letterSpacing: '.1rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Interactive Commerce Tool
                                </Typography>

                                {/* <React.Fragment key="2">
                                    <IconButton
                                        position="static"
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="reset"
                                        sx={{ mr: 2 }}
                                        onClick={useEffect}
                                    >
                                        <ReplayIcon style={{ color: "black" }} />
                                    </IconButton>
                                </React.Fragment> */}

                            </Toolbar>
                        </Container>
                    </AppBar>
                    <Divider />
                </div>
            )
        }
    }

    return (
        <div>
            <AppHeader />
            <h3>{currentPage}</h3>
            {currentPage === "Operating Profit Ratio" && <OperatingProfitRatio />}
            {currentPage === "Creditors TurnOver and Payment Period" && <CreditorsTurnover />}
            {currentPage === "Comparative Profit Loss Statement" && <ComparativeProfitLossStatement />}
            {currentPage === "Value Of Goodwill" && <ValueOfGoodwill />}
            {currentPage === "Investing Activity" && <InvestingActivity />}
            {currentPage === "P/L Appropriation/Partners Capital-Current Acc." && <ProfitLossAppropriationAccount />}
            {currentPage === "Regression Mode and it's reliability" && <RegressionModelAndReliability />}
            {currentPage === "Dropout Rate of Students" && <DropoutRateOfStudents />}
            {currentPage === "General Price Index Number" && <GeneralPriceIndexNumber />}
            {currentPage === "Frequency Distribution" && <FrequencyDistribution />}
            {currentPage === "Continuous Frequency Distribution" && <ContinuousFrequencyDistribution />}
            {currentPage === "Frequency Distribution and Mid Value" && <FrequencyDistributionMidValue />}
            {currentPage === "Frequency Distribution Pie Diagram" && <FrequencyDistributionPieDiagram />}
            {currentPage === "Mean Rainfall" && <MeanRainfall />}
            {currentPage === "Mean Travel Time" && <MeanTravelTime />}
            {currentPage === "Median Number of Cheques" && <MedianNumberOfCheques />}
            {currentPage === "Purchase Book" && <PurchaseBook />}
            {currentPage === "Pass Journal" && <PassJournal />}
            {currentPage === 'Journal Entry' && <JournalEntries />}
            {currentPage === 'Journal Entry & Accounting Treatments' && <JournalEntryAndAccountingTreatments />}
            {currentPage === 'Quartile, Decile and Percentile' && <QuartileDecilePercentile />}
            {currentPage === 'Correlation Coefficient' && <CorrelationCoefficient />}
            {currentPage === 'Correlation Coefficient Rank' && <CorrelationCoefficientRank />}
            {currentPage === 'Fixed Base Index Number' && <FixedBaseIndexNumber />}
            {currentPage === 'Journal Entry and Revaluation Account' && <JournalEntryRevaluationAccount />}
            {currentPage === 'Revaluation Account, Partners Capital Acc & Balance Sheet' && <RevaluationAccountBalanceSheet />}
            {currentPage === 'Balance Sheet as per schedule-III' && <StatementsAsPerScheduleIII />}
            {currentPage === 'Journal Entry for Debentures' && <JournalEntryForDebentures />}
            {currentPage === 'Rectification of Entries' && <RectificationEntries />}
            {currentPage === 'Annual Depreciation And Rate' && <AnnualDepreciationAndRate />}
            {currentPage === 'Journal Entry For Bill Exchange' && <JournalEntryBillExchange />}
            {currentPage === 'Ledger of a three columnar weekly cash book' && <ThreeColumnarWeeklyCashBookLedger />}
            {currentPage === 'Cash Book Preparation' && <CashBookPreparation />}
            {currentPage === 'Retirement of partner in firm' && <RetirementOfPartnerInFirm />}
            {currentPage === "Partner Accounts' after Dissolution" && <DissolutionOfPartnership />}
            {currentPage === 'Securities Premium Account' && <SecuritiesPremiumAccount />}

            {currentPage === 'World Trade Report' && <WorldTradeReport />}
            {currentPage === 'Cluster Bar Diagram' && <ClusterBarDiagram />}
            {currentPage === 'Demand Curve' && <DemandCurve />}
            {currentPage === 'Law of Demand' && <Lawofdemand />}

            {currentPage === 'Standard Deviation' && <StandardDeviation />}
            {currentPage === 'Skewneess and its Coefficient' && <SkewnessAndItsCoefficient />}
            {currentPage === 'Permutation' && <Permutation />}
            {currentPage === 'Drawing Random Sample' && <DrawRandomSample />}
            {currentPage === 'Domain & Co-domain Range' && <DomainCo_domainRange />}
            {currentPage === 'Finding Geometric Progression' && <FindGP />}

            {currentPage === "કામગીરી નફા ગુણોત્તર" && <OperatingProfitRatioGuj />}
            {currentPage === "લેણદારોનો ઉથલો અને ચૂકવણીની મુદ્દત" && <CreditorsTurnoverGuj />}
            {currentPage === "તુલનાત્મક નફા-નુક્શાનનું પત્રક" && <ComparativeProfitLossStatementGuj />}
            {currentPage === 'સતત આવૃત્તિ-વિતરણ' && <ContinuousFrequencyDistributionGuj />}
            {currentPage === 'આવૃત્તિ-વિતરણ' && <FrequencyDistributionGuj />}
            {currentPage === 'મધ્યકિંમત દર્શાવતું આવૃત્તિ-વિતરણ' && <FrequencyDistributionMidValueGuj />}
            {currentPage === 'વૃતાંશ-આકૃતિ' && <FrequencyDistributionPieDiagramGuj />}
            {currentPage === 'વરસાદનો મધ્યક' && <MeanRainfallGuj />}
            {currentPage === 'પ્રવાસના સમયનો મધ્યક' && <MeanTravelTimeGuj />}
            {currentPage === 'ભરાયેલા ચેકની મધ્યસ્થ' && <MedianNumberOfChequesGuj />}
            {currentPage === 'નિયતસંબંધ મોડેલ' && <RegressionModelAndReliabilityGuj />}
            {currentPage === 'અભ્યાસ છોડનાર વિદ્યાર્થીઓનો દર' && <DropoutRateOfStudentsGuj />}
            {currentPage === 'ભાવનો સામાન્ય સૂચક આંક' && <GeneralPriceIndexNumberGuj />}
            {currentPage === 'પાઘડીનું મૂલ્ય' && <ValueOfGoodwillGuj />}
            {currentPage === 'રોકાણની પ્રવૃત્તિ' && <InvestingActivityGuj />}
            {currentPage === 'નફા-નુકસાન ફાળવણી ખાતું/ભાગીદારોનાં મૂડી-ચાલુ ખાતાં' && <ProfitLossAppropriationAccountGuj />}
            {currentPage === 'આમનોંધ' && <PassJournalGuj />}
            {currentPage === 'રોજમેળ-નોંધ' && <JournalEntriesGuj />}
            {currentPage === 'ખરીદનોંધ' && <PurchaseBookGuj />}
            {currentPage === 'અચલ આધારે સૂચક આંક' && <FixedBaseIndexNumberGuj />}
            {currentPage === 'સહસંબંધાંક' && <CorrelationCoefficientGuj />}
            {currentPage === 'ક્રમાંક સહસંબંધાંક' && <CorrelationCoefficientRankGuj />}
            {currentPage === 'પેઢીના ચોપડામાં આમનોંધ અને પુન:મૂલ્યાંકન ખાતું' && <JournalEntryRevaluationAccountGuj />}
            {currentPage === 'પુન:મૂલ્યાંકન ખાતે, ભાગીદારોના મૂડી ખાતે અને પાકું સરવૈયું' && <RevaluationAccountBalanceSheetGuj />}
            {currentPage === 'રોકાણની પ્રવૃત્તિ' && <JournalEntryForDebenturesGuj />}
            {currentPage === 'પરિશિષ્ટ III મુજબ પાકું સરવૈયું' && <StatementsAsPerScheduleIIIGuj />}
            {currentPage === 'ભૂલ સુધારણા' && <RectificationEntriesGuj />}
            {currentPage === 'વાર્ષિક ઘસારાનો દર' && <AnnualDepreciationAndRateGuj />}
            {currentPage === 'હુંડીઓની આમનોંધ' && <JournalEntryBillExchangeGuj />}
            {currentPage === 'ત્રણ ખાનાંવાળો અઠવાડિક' && <ThreeColumnarWeeklyCashBookLedgerGuj />}
            {currentPage === 'રોકડમેળ' && <CashBookPreparationGuj />}
            {currentPage === 'પેઢીમાં ભાગીદારની નિવૃત્તિ' && <RetirementOfPartnerInFirmGuj />}
            {currentPage === 'વિસર્જન પછી ભાગીદારોનાં ખાતાં' && <DissolutionOfPartnershipGuj />}

            {currentPage === 'વર્લ્ડ ટ્રેડ રિપૉર્ટ' && <WorldTradeReportGuj />}
            {currentPage === 'પાસપાસેની સ્તંભાકૃતિ' && <ClusterBarDiagramGuj />}
            {currentPage === 'માંગ વક્ર' && <DemandCurveGuj />}
            {currentPage === 'માંગનો નિયમ' && <LawofdemandGuj />}

            {currentPage === 'પ્રમાણિત વિચરણ' && <StandardDeviationGuj />}
            {currentPage === 'વિષમતા અને વિષમતાંક' && <SkewnessAndItsCoefficientGuj />}
            {currentPage === 'ક્રમચય' && <PermutationGuj />}
            {currentPage === 'યાદચ્છિક નિદર્શન' && <DrawRandomSampleGuj />}
            {currentPage === 'પ્રદેશ, સહપ્રદેશ અને વિસ્તાર' && <DomainCo_domainRangeGuj />}
            {currentPage === 'ગુણોત્તર-શ્રેણી' && <FindGPGuj />}
        </div>
    )
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {

    const data = [{
        "type": "mm",
        "name": "rainfall",
        "answerPallete": [
            {
                "number": 126,
                "A": 23
            },
            {
                "number": 110,
                "A": 7
            },
            {
                "number": 91,
                "A": -12
            },
            {
                "number": 115,
                "A": 12
            },
            {
                "number": 112,
                "A": 9
            },
            {
                "number": 80,
                "A": -23
            },
            {
                "number": 101,
                "A": -2
            },
            {
                "number": 93,
                "A": -10
            },
            {
                "number": 97,
                "A": -6
            },
            {
                "number": 113,
                "A": 10
            }
        ],
        "assumedA": 103,
        "totalA": 8,
        "n": 10,
        "mean": 103.8
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                name: "rainfall",
                type: "mm",
                sampleString: "126 110 91 115 112 80 101 93 97 113",
                sampleStringArray: [126, 110, 91, 115, 112, 80, 101, 93, 97, 113],
                sampleStringLength: 10,

                editModename: false,
                editModetype: false,
                editModesampleString: false,
                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.stringReducer = this.stringReducer.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            switch (event.target.name) {
                case 'sampleString':
                    this.setState({
                        sampleString: this.stringReducer(event.target.value)
                    }, () => { this.fillDetails() })
                    break;
                case 'name':
                    this.setState({
                        name: event.target.value
                    }, () => { this.fillDetails() })
                    break;

            }
        }

        handleChange(event) {
            this.setState({
                type: event.target.value
            })
        }

        stringReducer(string) {
            let stringArray = string.split(" ")

            for (let i in stringArray) {
                if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === '') {
                    // console.log(i)
                    stringArray.splice(i, 1)
                }
            }

            if (stringArray[0] === null) {
                stringArray[0] = 0
            }

            this.setState({
                sampleStringLength: stringArray.length,
                sampleStringArray: stringArray
            })

            return string
        }

        fillDetails() {
            let flag = true

            if(this.state.name === ''){
                flag = false
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            let originalArray = JSON.parse(JSON.stringify(this.state.sampleStringArray))

            let sortedArray = this.state.sampleStringArray.sort((a, b) => { return a - b })

            let assumedA = Math.round((parseFloat(sortedArray[0]) + parseFloat(sortedArray[sortedArray.length - 1])) / 2)

            let answerPallete = []
            let totalA = 0
            // console.log(sortedArray)


            for (let i in originalArray) {
                answerPallete.push({
                    number: parseFloat(originalArray[i]),
                    A: parseFloat(originalArray[i]) - assumedA
                })
                totalA += parseFloat(originalArray[i]) - assumedA
            }

            let dataObject = {
                type:this.state.type,
                name:this.state.name,
                answerPallete,
                assumedA,
                totalA,
                n: this.state.sampleStringLength,
                mean: assumedA + Math.round((totalA / this.state.sampleStringLength) * 100) / 100
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            switch (event.target.name) {
                case 'sampleString':
                    this.setState({
                        editModesampleString: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'name':
                    this.setState({
                        editModename: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'type':
                    this.setState({
                        editModetype: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;

            }
        }

        editModeOff() {
            this.setState({
                sampleString: this.state.sampleStringArray.reduce((a, b) => { return a + " " + b }),

                submitChecker: true,

                editModename: false,
                editModetype: false,
                editModesampleString: false,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        The&nbsp;
                        {this.state.editModename ? <TextField style={{ "width": "9%", "padding": "-10px" }} name='name' id='outlined-base' error={this.state.name === ''}
                            value={this.state.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="name" onClick={this.buttonMaker.bind(this)}>{this.state.name}</a>} &nbsp;
                        {this.state.editModetype ?
                            <FormControl style={{ width: "9%" }}>
                                <InputLabel id="demo-simple-select-label">Option to Add</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.type}
                                    label="Option to Add"
                                    onChange={this.handleChange.bind(this)}
                                >
                                    <MenuItem value={"mm"}>mm</MenuItem>
                                    <MenuItem value={"cm"}>cm</MenuItem>
                                    <MenuItem value={"m"}>m</MenuItem>
                                    <MenuItem value={"km"}>km</MenuItem>
                                    <MenuItem value={"inch"}>mm</MenuItem>
                                    <MenuItem value={"g"}>g</MenuItem>
                                    <MenuItem value={"kg"}>kg</MenuItem>
                                    <MenuItem value={"l"}>l</MenuItem>
                                    <MenuItem value={"ms"}>ms</MenuItem>
                                    <MenuItem value={"s"}>s</MenuItem>
                                    <MenuItem value={"min"}>min</MenuItem>
                                    <MenuItem value={"hr"}>hr</MenuItem>
                                </Select>
                            </FormControl> :
                            <a className='txt' name="type" onClick={this.buttonMaker.bind(this)}>({this.state.type})</a>}&nbsp;
                        at {this.state.sampleStringLength} different places of a district was recorded as :

                        <br />


                        {this.state.editModesampleString ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='sampleString' id='outlined-base' error={this.state.sampleString === ''}
                            value={this.state.sampleString} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="sampleString" onClick={this.buttonMaker.bind(this)}> {this.state.sampleString}</a>} &nbsp;<br />
                        <b>Find mean {this.state.name}</b>


                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            As per the observations we will use short cut method. We will select assumed mean (in which our case uses the middle value of the lowest and highest value) A = {data[chartState.selected].assumedA}. The observations <i>x</i> and deviations <i>d</i> = <i>x</i> - A are shown in the following table:<br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">{data[chartState.selected].name} ({data[chartState.selected].type}) <i>x</i></StyledTableCell>
                                            <StyledTableCell align="center"><i>d</i> = <i>x</i> - <i>A,A</i> = {data[chartState.selected].assumedA}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].answerPallete.map((x,i)=>
                                        <TableRow>
                                            <StyledTableCell align="center">{x.number}</StyledTableCell>
                                            <StyledTableCell align="center">{x.A}</StyledTableCell>
                                        </TableRow>
                                        )}
                                        <TableRow>
                                            <StyledTableCell align="center"><b>Total</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].totalA}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br/>
                            Here, <i>n</i> = {data[chartState.selected].n}<br/><br/>
                            Mean x̄ = <i>A</i> + <sup>Σ<i>d</i></sup>/<sub>n</sub><br/>
                            &emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].assumedA} + <sup>{data[chartState.selected].totalA}</sup>/<sub>{data[chartState.selected].n}</sub><br/>
                            &emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].assumedA} + {Math.round((data[chartState.selected].totalA/data[chartState.selected].n)*100)/100}<br/>
                            &emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].mean}<br/><br/>
                            Thus, the mean {data[chartState.selected].name} is {data[chartState.selected].mean} {data[chartState.selected].type}

                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

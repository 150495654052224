import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button, touchRippleClasses } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
//ILLUSTRATION 7

export default function ComparativeProfitLossStatement() {

    const data = [{
        "purchaseBook": [
            43000,
            {
                "questionType": "purchase",
                "context": {
                    "type": "tradeDiscount",
                    "name": "Dhruvil",
                    "amount": 10000,
                    "discount": 10,
                    "billno": 110,
                    "paid": {
                        "type": false,
                        "amount": 5000
                    },
                    "finalAmount": 9000
                },
                "date": 1
            },
            {
                "questionType": "purchase",
                "context": {
                    "type": "cashDiscount",
                    "name": "Komal",
                    "amount": 4000,
                    "discount": 10,
                    "credit": 2,
                    "paid": {
                        "type": false
                    }
                },
                "date": 3
            },
            {
                "questionType": "purchase",
                "context": {
                    "type": "nodiscount",
                    "name": "Nirmi",
                    "amount": 30000,
                    "billno": "-",
                    "paid": {
                        "type": true,
                        "amount": 15000
                    }
                },
                "date": 18
            }
        ],
        "salesBook": [
            50800,
            {
                "questionType": "sales",
                "context": {
                    "type": "sales",
                    "name": "Sajan",
                    "amount": 8000,
                    "tradeDiscount": 5,
                    "cashDiscount": 2,
                    "order": false,
                    "purchasedFrom": "-",
                    "invoiceNo": 90,
                    "credit": "-",
                    "extraSum": "-",
                    "extraSumReason": "-",
                    "finalAmount": 7600
                },
                "date": 7
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "sales",
                    "name": "Haily",
                    "amount": 36000,
                    "tradeDiscount": 10,
                    "cashDiscount": "-",
                    "order": false,
                    "purchasedFrom": "Nirmi",
                    "invoiceNo": 100,
                    "credit": 3,
                    "extraSum": "-",
                    "extraSumReason": "-",
                    "finalAmount": 32400
                },
                "date": 19
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "sales",
                    "name": "Kanu",
                    "amount": 12000,
                    "order": true,
                    "tradeDiscount": 10,
                    "cashDiscount": "-",
                    "purchasedFrom": "-",
                    "invoiceNo": 102,
                    "credit": "-",
                    "extraSum": 300,
                    "extraSumReason": "labour",
                    "finalAmount": 10800
                },
                "date": 24
            }
        ],
        "purchaseReturns": [
            15900,
            {
                "questionType": "purchaseReturns",
                "context": {
                    "type": "purchaseReturns",
                    "name": "Dhruvil",
                    "percentageReturn": 10,
                    "debitNoteNo": 16,
                    "saleReturn": {
                        "istrue": false
                    },
                    "amountReturned": 900
                },
                "date": 15
            },
            {
                "questionType": "purchaseReturns",
                "context": {
                    "type": "purchaseReturns",
                    "name": "Nirmi",
                    "percentageReturn": 50,
                    "debitNoteNo": "-",
                    "saleReturn": {
                        "istrue": true,
                        "returneeName": "Haily",
                        "amountReturned": 16200
                    },
                    "amountReturned": 15000
                },
                "date": 20
            }
        ],
        "salesReturns": [
            23600,
            {
                "questionType": "salesReturns",
                "context": {
                    "type": "salesReturns",
                    "name": "Sajan",
                    "amount": 2000,
                    "creditNoteNo": 15,
                    "rebate": false
                },
                "date": 11
            },
            {
                "questionType": "salesReturns",
                "context": {
                    "type": "salesReturns",
                    "name": "Haily",
                    "amount": 16200,
                    "creditNoteNo": "-",
                    "rebate": false
                },
                "date": 20
            },
            {
                "questionType": "salesReturns",
                "context": {
                    "type": "salesReturns",
                    "name": "Kanu",
                    "amount": 5400,
                    "creditNoteNo": "-",
                    "rebate": true
                },
                "date": 31
            }
        ],
        "transactionsNotRecorded": [
            {
                "type": "memo",
                "date": 10
            },
            {
                "type": "fixedAsset",
                "date": 16
            },
            {
                "type": "order",
                "date": 21
            },
            {
                "type": "extraSum",
                "amount": 300,
                "date": 24
            },
            {
                "type": "cashPurchaseSales",
                "cashPurchase": 20000,
                "cashSales": 25000,
                "date": 25
            }
        ],
        "otherTransactions": [
            {
                "type": "specialPurchaseReturns",
                "returnedAmount": 900,
                "percentageReturn": 10,
                "initialAmount": 9000,
                "initialDate": 1,
                "finalDate": 15,
                "showDate": 15
            },
            {
                "type": "specialPurchaseSalesReturns",
                "purchasedFromName": "Nirmi",
                "soldToName": "Haily",
                "soldDate": 19,
                "finalDate": 20,
                "amountReturnedByPurchaser": 16200,
                "amountReturnedToSeller": 15000,
                "showDate": 19,
                "percent": 50
            }
        ],
        "bookOwnerName": "Hasmukhbhai",
        "month": "January",
        "year": 2015
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const AlphabetRegex = new RegExp(/^[a-zA-Z]+$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                bookOwnerGender: "Shri",
                bookOwnerName: "Hasmukhbhai",
                month: "January",
                year: 2015,
                questionPallete: [
                    {//1
                        questionType: "purchase",
                        context: {
                            type: "tradeDiscount",
                            name: "Dhruvil",
                            amount: 10000,
                            discount: 10,
                            billno: 110,
                            paid: {
                                type: false,
                            }
                        },
                        date: 1
                    },
                    {//2
                        questionType: "purchase",
                        context: {
                            type: "cashDiscount",
                            name: "Komal",
                            amount: 4000,
                            discount: 10,
                            credit: 2,
                            paid: {
                                type: false
                            }
                        },
                        date: 3
                    },
                    {//3
                        questionType: "sales",
                        context: {
                            type: "sales",
                            name: "Sajan",
                            amount: 8000,
                            tradeDiscount: 5,
                            cashDiscount: 2,
                            order: false,
                            purchasedFrom: "-",
                            invoiceNo: 90,
                            credit: "-",
                            extraSum: "-",
                            extraSumReason: "-"
                        },
                        date: 7
                    },
                    {//4
                        questionType: "purchase",
                        context: {
                            type: "memo",
                            amount: 4000,
                            no: 30
                        },
                        date: 10
                    },
                    {//5
                        questionType: "salesReturns",
                        context: {
                            type: "salesReturns",
                            name: "Sajan",
                            amount: 2000,
                            creditNoteNo: 15,
                            rebate: false
                        },
                        date: 11
                    },
                    {//6
                        questionType: "purchaseReturns",
                        context: {
                            type: "purchaseReturns",
                            name: "Dhruvil",
                            percentageReturn: 10,
                            debitNoteNo: 16,
                            saleReturn: {
                                istrue: false
                            }
                        },
                        date: 15
                    },
                    {//7
                        questionType: "purchase",
                        context: {
                            type: "fixedAsset",
                            name: "Shree Bahuchar Furniture Mart",
                            amount: 5000,
                            billno: "-",
                            assetName: "furniture",
                            paid: {
                                type: false
                            }
                        },
                        date: 16
                    },
                    {//8
                        questionType: "purchase",
                        context: {
                            type: "nodiscount",
                            name: "Nirmi",
                            amount: 30000,
                            billno: "-",
                            paid: {
                                type: true,
                                amount: 15000
                            }
                        },
                        date: 18
                    },
                    {//9
                        questionType: "sales",
                        context: {
                            type: "sales",
                            name: "Haily",
                            amount: 36000,
                            tradeDiscount: 10,
                            cashDiscount: "-",
                            order: false,
                            purchasedFrom: "Nirmi",
                            invoiceNo: 100,
                            credit: 3,
                            extraSum: "-",
                            extraSumReason: "-"
                        },
                        date: 19
                    },
                    {//10
                        questionType: "purchaseReturns",
                        context: {
                            type: "purchaseReturns",
                            name: "Nirmi",
                            percentageReturn: 50,
                            debitNoteNo: "-",
                            saleReturn: {
                                istrue: true,
                                returneeName: "Haily"
                            }
                        },
                        date: 20
                    },
                    {//11
                        questionType: "order",
                        context: {
                            type: "order",
                            name: "Kanu"
                        },
                        date: 21
                    },
                    {//12
                        questionType: "sales",
                        context: {
                            type: "sales",
                            name: "Kanu",
                            amount: 12000,
                            order: true,
                            tradeDiscount: 10,
                            cashDiscount: "-",
                            purchasedFrom: "-",
                            invoiceNo: 102,
                            credit: "-",
                            extraSum: 300,
                            extraSumReason: "labour"
                        },
                        date: 24
                    },
                    {//13
                        questionType: "cashPurchaseSales",
                        context: {
                            type: "cashPurchaseSales",
                            cashPurchase: 20000,
                            cashSales: 25000
                        },
                        date: 25
                    },
                    {//14
                        questionType: "salesReturns",
                        context: {
                            type: "salesReturns",
                            name: "Kanu",
                            amount: 5400,
                            creditNoteNo: "-",
                            rebate: true
                        },
                        date: 31
                    }
                ],

                addQuestionType: "none",
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                editModequestionPalette: [
                    {//1
                        name: false,
                        amount: false,
                        discount: false,
                        billno: false
                    },
                    {//2
                        name: false,
                        amount: false,
                        discount: false,
                        credit: false
                    },
                    {//3
                        name: false,
                        amount: false,
                        tradeDiscount: false,
                        cashDiscount: false,
                        invoiceNo: false,
                        credit: false,
                    },
                    {//4
                        amount: false
                    },
                    {//5
                        amount: false,
                        creditNoteNo: false,
                    },
                    {//6
                        percentageReturn: false,
                        debitNoteNo: false,
                    },
                    {//7
                        name: false,
                        amount: false,
                        billno: false,
                        assetName: false,

                    },
                    {//8
                        name: false,
                        amount: false,
                        billno: false
                    },
                    {//9
                        name: false,
                        amount: false,
                        tradeDiscount: false,
                        invoiceNo: false,
                        credit: false,
                    },
                    {//10
                        percentageReturn: false,
                    },
                    {//11

                    },
                    {//12
                        name: false,
                        amount: false,
                        tradeDiscount: false,
                        invoiceNo: false,
                    },
                    {//13
                        cashPurchase: false,
                        cashSales: false
                    },
                    {//14
                        amount: false
                    }
                ],


                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModemonth: false,
                editModeyear: false,

                addQuestionChecker: false,


                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
            this.nameSetter = this.nameSetter.bind(this)
            this.removeQuestion = this.removeQuestion.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (event.target.value !== '-') {
                if (customArray.length === 3) {
                    switch (customArray[0]) {
                        case 'tradeDiscount':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "purchase")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'discount':
                                    this.state.questionPallete[customArray[2]].context.discount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'billno':
                                    this.state.questionPallete[customArray[2]].context.billno = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'cashDiscount':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "purchase")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'discount':
                                    this.state.questionPallete[customArray[2]].context.discount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'credit':
                                    this.state.questionPallete[customArray[2]].context.credit = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'memo':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'fixedAsset':
                            switch (customArray[1]) {
                                case 'assetName':
                                    this.state.questionPallete[customArray[2]].context.assetName = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'billno':
                                    this.state.questionPallete[customArray[2]].context.billno = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'nodiscount':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "purchase")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'billno':
                                    this.state.questionPallete[customArray[2]].context.billno = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'sales':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "sales")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'tradeDiscount':
                                    this.state.questionPallete[customArray[2]].context.tradeDiscount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'cashDiscount':
                                    this.state.questionPallete[customArray[2]].context.cashDiscount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'credit':
                                    this.state.questionPallete[customArray[2]].context.credit = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'invoiceNo':
                                    this.state.questionPallete[customArray[2]].context.invoiceNo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'purchaseReturns':
                            switch (customArray[1]) {
                                case 'percentageReturn':
                                    this.state.questionPallete[customArray[2]].context.percentageReturn = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debitNoteNo':
                                    this.state.questionPallete[customArray[2]].context.debitNoteNo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'salesReturns':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'creditNoteNo':
                                    this.state.questionPallete[customArray[2]].context.creditNoteNo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'cashPurchaseSales':
                            switch (customArray[1]) {
                                case 'cashPurchase':
                                    this.state.questionPallete[customArray[2]].context.cashPurchase = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'cashSales':
                                    this.state.questionPallete[customArray[2]].context.cashSales = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'order':
                            switch (customArray[1]) {
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "preOrder")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                    }
                }

                switch (event.target.name) {
                    case 'bookOwnerName':
                        this.setState({
                            bookOwnerName: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'year':
                        this.setState({
                            year: event.target.value
                        }, () => { this.fillDetails() })
                        break;

                }
            }
        }

        nameSetter(name, newName, type) {
            if (type === 'purchase') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "purchaseReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            if (type === 'sales') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "order" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }

            if (type === 'preOrder') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "sales" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            this.setState({})
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'date':
                    this.setState({
                        tempDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'name':
                    this.setState({
                        tempName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amount':
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'discount':
                    this.setState({
                        tempDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Tdiscount':
                    this.setState({
                        tempTDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Cdiscount':
                    this.setState({
                        tempCDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'purchasedFrom':
                    this.setState({
                        tempPurchasedFrom: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'billno':
                    this.setState({
                        tempBillno: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'credit':
                    this.setState({
                        tempCredit: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPercentage':
                    this.setState({
                        tempAmountPercentage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'invoiceNo':
                    this.setState({
                        tempInvoiceNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'debitNoteNo':
                    this.setState({
                        tempDebitNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'creditNoteNo':
                    this.setState({
                        tempCreditNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPaidImmediately':
                    this.setState({
                        tempAmountPaidImmediately: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'memo':
                    this.setState({
                        tempMemo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'assetName':
                    this.setState({
                        tempAssetName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmount':
                    this.setState({
                        tempExtraSum: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmountReason':
                    this.setState({
                        tempExtraSumReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashPurchase':
                    this.setState({
                        tempCashPurchase: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashSales':
                    this.setState({
                        tempCashSales: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            switch (this.state.addQuestionType) {
                case 'tradeDiscount':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '' && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) && this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'cashDiscount':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '' && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) && this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'memo':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'fixedAsset':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '' && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'nodiscount':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '' && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'sales':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        !isNaN(this.state.tempTDiscount) && !isNaN(this.state.tempCDiscount) &&
                        this.state.tempInvoiceNo !== '' && !isNaN(this.state.tempInvoiceNo)) {
                        if (this.state.tempTDiscount !== '') {
                            if (parseFloat(this.state.tempTDiscount) > 0 && parseFloat(this.state.tempTDiscount) < 100) {
                                this.setState({
                                    addQuestionChecker: true
                                })
                            } else {
                                this.setState({
                                    addQuestionChecker: false
                                })
                            }
                        } else if (this.state.tempCDiscount !== '') {
                            if (parseFloat(this.state.tempCDiscount) > 0 && parseFloat(this.state.tempCDiscount) < 100) {
                                this.setState({
                                    addQuestionChecker: true
                                })
                            } else {
                                this.setState({
                                    addQuestionChecker: false
                                })
                            }
                        } else {
                            this.setState({
                                addQuestionChecker: true
                            })
                        }
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'purchaseReturns':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '' && this.state.tempAmountPercentage !== '' && NumberRegex.test(this.state.tempAmountPercentage) && parseFloat(this.state.tempAmountPercentage) > 0 && parseFloat(this.state.tempAmountPercentage) < 100) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'salesReturns':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '' && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'order':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 && this.state.tempName !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'cashPurchaseSales':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32) {
                        if (this.state.tempCashPurchase !== '' || this.state.tempCashSales !== '') {
                            this.setState({
                                addQuestionChecker: true
                            })
                        } else {
                            this.setState({
                                addQuestionChecker: false
                            })
                        }
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;

            }
        }

        genderChange(event) {
            this.setState({
                bookOwnerGender: event.target.value
            })
        }

        monthChange(event) {
            this.setState({
                month: event.target.value
            })
        }

        questionTypeChange(event) {
            this.setState({
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                addQuestionChecker: false,
                addQuestionType: event.target.value
            })
        }

        addNameChange(event) {
            this.setState({
                tempName: event.target.value
            })
        }

        addReturneeNameChange(event) {
            this.setState({
                tempReturneeName: event.target.value
            })
        }

        purchaseFromNameChange(event) {
            this.setState({
                tempPurchasedFrom: event.target.value
            })
        }

        rebateChange(event) {
            this.setState({
                tempRebate: event.target.value
            })
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.questionPallete) {

                switch (i.context.type) {
                    case 'tradeDiscount':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '' ||
                            i.context.discount === '' || !NumberRegex.test(i.context.discount) || parseFloat(i.context.discount) < 0 || parseFloat(i.context.discount) > 100 ||
                            i.context.billno === '' || !NumberRegex.test(i.context.billno)) {
                            flag = false
                        }
                        break;
                    case 'cashDiscount':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '' ||
                            i.context.discount === '' || !NumberRegex.test(i.context.discount) || parseFloat(i.context.discount) < 0 || parseFloat(i.context.discount) > 100 ||
                            i.context.credit === '' || !NumberRegex.test(i.context.credit)) {
                            flag = false
                        }
                        break;
                    case 'fixedAsset':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '' ||
                            i.context.assetName === '') {
                            flag = false
                        }
                        if (i.context.billno !== '-') {
                            if (i.context.billno === '' || !NumberRegex.test(i.context.billno)) {
                                flag = false
                            }
                        }

                        break;
                    case 'memo':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'nodiscount':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '') {
                            flag = false
                        }
                        if (i.context.billno !== '-') {
                            if (i.context.billno === '' || !NumberRegex.test(i.context.billno)) {
                                flag = false
                            }
                        }
                        break;
                    case "sales":
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '') {
                            flag = false
                        }
                        if (i.context.tradeDiscount !== '-') {
                            if (i.context.tradeDiscount === '' || !NumberRegex.test(i.context.tradeDiscount) || parseFloat(i.context.tradeDiscount) < 0 || parseFloat(i.context.tradeDiscount) > 100) {
                                flag = false
                            }
                        }
                        if (i.context.cashDiscount !== '-') {
                            if (i.context.cashDiscount === '' || !NumberRegex.test(i.context.cashDiscount) || parseFloat(i.context.cashDiscount) < 0 || parseFloat(i.context.cashDiscount) > 100) {
                                flag = false
                            }
                        }
                        if (i.context.invoiceNo !== '-') {
                            if (i.context.invoiceNo === '' || !NumberRegex.test(i.context.invoiceNo)) {
                                flag = false
                            }
                        }
                        if (i.context.credit !== '-') {
                            if (i.context.credit === '' || !NumberRegex.test(i.context.credit)) {
                                flag = false
                            }
                        }
                        break;
                    case 'purchaseReturns':
                        if (i.context.percentageReturn === '' || !NumberRegex.test(i.context.percentageReturn) || parseFloat(i.context.percentageReturn) < 0 || parseFloat(i.context.percentageReturn) > 100) {
                            flag = false
                        }
                        if (i.context.debitNoteNo !== '-') {
                            if (i.context.debitNoteNo === '' || !NumberRegex.test(i.context.debitNoteNo)) {
                                flag = false
                            }
                        }
                        break;
                    case 'salesReturns':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        if (i.context.creditNoteNo !== '-') {
                            if (i.context.creditNoteNo === '' || !NumberRegex.test(i.context.creditNoteNo)) {
                                flag = false
                            }
                        }
                        break;
                    case 'order':
                        if (i.context.name === '') {
                            flag = false
                        }
                        break;
                    case 'cashPurchaseSales':
                        if (i.context.cashPurchase !== '-') {
                            if (i.context.cashPurchase === '' || !NumberRegex.test(i.context.cashPurchase)) {
                                flag = false
                            }
                        }
                        if (i.context.cashSales !== '-') {
                            if (i.context.cashSales === '' || !NumberRegex.test(i.context.cashSales)) {
                                flag = false
                            }
                        }
                        break;
                }
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            // console.log(JSON.stringify(this.state.questionPallete,null,4))

            for (let i of this.state.questionPallete) {
                if (i.questionType === "purchase") {
                    if (i.context.type === 'tradeDiscount') {
                        i.context.finalAmount = parseFloat(i.context.amount) - parseFloat(i.context.amount) * parseFloat(i.context.discount) / 100
                    }
                }

                if (i.questionType === "sales") {
                    if (i.context.tradeDiscount !== '-') {
                        i.context.finalAmount = parseFloat(i.context.amount) - parseFloat(i.context.amount) * parseFloat(i.context.tradeDiscount) / 100
                    }
                }

                if (i.questionType === "purchaseReturns") {
                    for (let j of this.state.questionPallete) {
                        if (j.context.type === "tradeDiscount") {
                            if (j.context.name === i.context.name) {
                                i.context.amountReturned = parseFloat(j.context.finalAmount) * parseFloat(i.context.percentageReturn) / 100
                            }
                        } else if (j.context.type === 'cashDiscount' || j.context.type === 'nodiscount') {
                            if (j.context.name === i.context.name) {
                                i.context.amountReturned = parseFloat(j.context.amount) * parseFloat(i.context.percentageReturn) / 100
                            }
                        }
                    }
                    if (i.context.saleReturn.istrue === true) {
                        for (let j of this.state.questionPallete) {
                            if (j.context.type === "sales") {
                                if (j.context.name === i.context.saleReturn.returneeName) {
                                    i.context.saleReturn.amountReturned = parseFloat(j.context.finalAmount) * parseFloat(i.context.percentageReturn) / 100
                                }
                            }
                        }
                    }
                }
            }

            let purchaseBook = [0]
            let salesBook = [0]
            let purchaseReturns = [0]
            let salesReturns = [0]
            let transactionsNotRecorded = []
            let otherTransactions = []

            for (let i of this.state.questionPallete) {
                if (i.context.type === "tradeDiscount") {
                    purchaseBook[0] += parseFloat(i.context.finalAmount)
                    purchaseBook.push(i)
                } else if (i.context.type === "cashDiscount" || i.context.type === "nodiscount") {
                    purchaseBook[0] += parseFloat(i.context.amount)
                    purchaseBook.push(i)
                }
                else if (i.context.type === "sales") {
                    salesBook[0] += parseFloat(i.context.finalAmount)
                    salesBook.push(i)
                }
                else if (i.context.type === "purchaseReturns") {
                    purchaseReturns[0] += parseFloat(i.context.amountReturned)
                    purchaseReturns.push(i)
                    if (i.context.saleReturn.istrue) {
                        salesReturns[0] += parseFloat(i.context.saleReturn.amountReturned)
                        salesReturns.push({
                            "questionType": "salesReturns",
                            "context": {
                                "type": "salesReturns",
                                "name": i.context.saleReturn.returneeName,
                                "amount": i.context.saleReturn.amountReturned,
                                "creditNoteNo": "-",
                                "rebate": false
                            },
                            "date": i.date
                        })
                    }
                }
                else if (i.context.type === "salesReturns") {
                    salesReturns[0] += parseFloat(i.context.amount)
                    salesReturns.push(i)
                }

                if (i.context.type === "memo") {
                    transactionsNotRecorded.push({
                        type: "memo",
                        date: i.date
                    })
                }
                if (i.context.type === "fixedAsset") {
                    transactionsNotRecorded.push({
                        type: "fixedAsset",
                        date: i.date
                    })
                }
                if (i.context.type === 'order') {
                    transactionsNotRecorded.push({
                        type: "order",
                        date: i.date
                    })
                }
                if (i.context.type === 'sales') {
                    if (i.context.extraSum !== "-") {
                        transactionsNotRecorded.push({
                            type: "extraSum",
                            amount: i.context.extraSum,
                            date: i.date
                        })
                    }
                }
                if (i.context.type === 'cashPurchaseSales') {
                    transactionsNotRecorded.push({
                        type: "cashPurchaseSales",
                        cashPurchase: i.context.cashPurchase,
                        cashSales: i.context.cashSales,
                        date: i.date
                    })
                }

                if (i.context.type === 'purchaseReturns') {
                    for (let j of this.state.questionPallete) {
                        if (j.context.type === "tradeDiscount") {
                            if (i.context.name === j.context.name) {
                                otherTransactions.push({
                                    type: "specialPurchaseReturns",
                                    returnedAmount: i.context.amountReturned,
                                    percentageReturn: i.context.percentageReturn,
                                    initialAmount: j.context.finalAmount,
                                    initialDate: j.date,
                                    finalDate: i.date,
                                    showDate: i.date
                                })
                            }
                        }
                    }
                }
                if (i.context.type === 'purchaseReturns') {
                    if (i.context.saleReturn.istrue) {
                        for (let j of this.state.questionPallete) {
                            if (j.context.type === 'sales') {
                                if (i.context.saleReturn.returneeName === j.context.name) {
                                    otherTransactions.push({
                                        type: "specialPurchaseSalesReturns",
                                        purchasedFromName: i.context.name,
                                        soldToName: i.context.saleReturn.returneeName,
                                        soldDate: j.date,
                                        finalDate: i.date,
                                        amountReturnedByPurchaser: i.context.saleReturn.amountReturned,
                                        amountReturnedToSeller: i.context.amountReturned,
                                        showDate: j.date,
                                        percent: i.context.percentageReturn
                                    })
                                }
                            }
                        }
                    }
                }
            }

            // console.log(JSON.stringify(this.state.questionPallete, null, 4))

            // let dataObject = JSON.parse(JSON.stringify(this.state.questionPallete))

            let dataObject = {
                purchaseBook,
                salesBook,
                purchaseReturns,
                salesReturns,
                transactionsNotRecorded,
                otherTransactions,
                bookOwnerName: this.state.bookOwnerName,
                month: this.state.month,
                year: this.state.year
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.clear()
            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addQuestion() {
            let adderFlag = true

            let temporaryTemplate = {
                questionType: this.state.addQuestionType,
                date: parseFloat(this.state.tempDate),
                assetName: this.state.tempAssetName,
                name: this.state.tempName,
                amount: this.state.tempAmount,
                amountPercentage: this.state.tempAmountPercentage,
                discount: this.state.tempDiscount,
                tradeDiscount: this.state.tempTDiscount,
                cashDiscount: this.state.tempCDiscount,
                purchasedFrom: this.state.tempPurchasedFrom,
                billno: this.state.tempBillno,
                invoiceNo: this.state.tempInvoiceNo,
                debitNoteNo: this.state.tempDebitNoteNo,
                creditNoteNo: this.state.tempCreditNoteNo,
                credit: this.state.tempCredit,
                memo: this.state.tempMemo,
                extraSum: this.state.tempExtraSum,
                extraSumReason: this.state.tempExtraSumReason,
                cashPurchase: this.state.tempCashPurchase,
                cashSales: this.state.tempCashSales,
                rebate: this.state.tempRebate,
                returneeName: this.state.tempReturneeName,

                amountPaidImmediately: this.state.tempAmountPaidImmediately
            }
            let questionObject
            let editModequestionObject

            switch (temporaryTemplate.questionType) {
                case 'tradeDiscount':

                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            discount: temporaryTemplate.discount,
                            billno: "-",
                            paid: {
                                type: false
                            },

                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        discount: false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.billno)) {
                        questionObject.context.billno = temporaryTemplate.billno
                        editModequestionObject.billno = false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.amountPaidImmediately)) {
                        questionObject.context.paid.type = true
                        questionObject.context.paid.amount = temporaryTemplate.amountPaidImmediately
                    }
                    break;
                case 'cashDiscount':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            discount: temporaryTemplate.discount,
                            credit: "-",
                            paid: {
                                type: false
                            },

                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        discount: false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.credit)) {
                        questionObject.context.credit = temporaryTemplate.credit
                        editModequestionObject.credit = false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.amountPaidImmediately)) {
                        questionObject.context.paid.type = true
                        questionObject.context.paid.amount = temporaryTemplate.amountPaidImmediately
                    }
                    break;
                case 'memo':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            amount: temporaryTemplate.amount,
                            no: "-",
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        amount: false,
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.memo)) {
                        questionObject.context.no = temporaryTemplate.memo
                    }
                    break;
                case 'fixedAsset':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            billno: "-",
                            assetName: temporaryTemplate.assetName,
                            paid: {
                                type: false
                            },

                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        assetName: false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.billno)) {
                        questionObject.context.billno = temporaryTemplate.billno
                        editModequestionObject.billno = false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.amountPaidImmediately)) {
                        questionObject.context.paid.type = true
                        questionObject.context.paid.amount = temporaryTemplate.amountPaidImmediately
                    }
                    break;
                case 'nodiscount':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            billno: "-",
                            paid: {
                                type: false
                            },

                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.billno)) {
                        questionObject.context.billno = temporaryTemplate.billno
                        editModequestionObject.billno = false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.amountPaidImmediately)) {
                        questionObject.context.paid.type = true
                        questionObject.context.paid.amount = temporaryTemplate.amountPaidImmediately
                    }
                    break;
                case 'sales':
                    questionObject = {
                        questionType: "sales",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            tradeDiscount: "-",
                            cashDiscount: "-",
                            order: false,
                            purchasedFrom: "-",
                            invoiceNo: temporaryTemplate.invoiceNo,
                            credit: "-",
                            extraSum: "-",
                            extraSumReason: "-"
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.tradeDiscount)) {
                        questionObject.context.tradeDiscount = temporaryTemplate.tradeDiscount
                        editModequestionObject.tradeDiscount = false
                    }
                    if (NormalNumberRegex.test(temporaryTemplate.cashDiscount)) {
                        questionObject.context.cashDiscount = temporaryTemplate.cashDiscount
                        editModequestionObject.cashDiscount = false
                    }
                    if (NormalNumberRegex.test(temporaryTemplate.credit)) {
                        questionObject.context.credit = temporaryTemplate.credit
                        editModequestionObject.credit = false
                    }
                    if (NormalNumberRegex.test(temporaryTemplate.extraSum)) {
                        questionObject.context.extraSum = temporaryTemplate.extraSum
                    }
                    if (AlphabetRegex.test(temporaryTemplate.extraSumReason)) {
                        questionObject.context.extraSumReason = temporaryTemplate.extraSumReason
                    }
                    if (temporaryTemplate.purchasedFrom !== '') {
                        questionObject.context.purchasedFrom = temporaryTemplate.purchasedFrom
                    }
                    for (let i of this.state.questionPallete) {
                        if (i.context.type === 'order') {
                            if (temporaryTemplate.name === i.context.name) {
                                questionObject.context.order = true
                            }
                        }
                    }
                    break;
                case 'purchaseReturns':

                    for (let i of this.state.questionPallete) {
                        if (i.context.name === temporaryTemplate.name) {
                            if (i.date >= temporaryTemplate.date) {
                                adderFlag = false
                            }
                        }
                    }

                    questionObject = {
                        questionType: "sales",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            percentageReturn: temporaryTemplate.amountPercentage,
                            debitNoteNo: "-",
                            saleReturn: {
                                istrue: false
                            }
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        amount: false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.debitNoteNo)) {
                        questionObject.context.debitNoteNo = temporaryTemplate.debitNoteNo
                        editModequestionObject.debitNoteNo = false
                    }

                    if (temporaryTemplate.returneeName !== '') {
                        questionObject.context.saleReturn.istrue = true
                        questionObject.context.saleReturn.returneeName = temporaryTemplate.returneeName
                    }

                    if (temporaryTemplate.returneeName !== '') {
                        for (let i of this.state.questionPallete) {
                            if (i.context.name === temporaryTemplate.returneeName) {
                                if (i.date >= temporaryTemplate.date) {
                                    adderFlag = false
                                }
                            }
                        }
                    }

                    break;
                case 'salesReturns':
                    questionObject = {
                        questionType: "salesReturns",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            creditNoteNo: "-",
                            rebate: temporaryTemplate.rebate
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        amount: false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.creditNoteNo)) {
                        questionObject.context.creditNoteNo = temporaryTemplate.creditNoteNo
                        editModequestionObject.creditNoteNo = false
                    }

                    for (let i of this.state.questionPallete) {
                        if (i.questionType === "sales") {
                            if (i.context.name === temporaryTemplate.name) {
                                if (i.date >= temporaryTemplate.date) {
                                    adderFlag = false
                                }
                            }
                        }
                    }

                    break;
                case 'order':
                    questionObject = {
                        questionType: "order",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        amount: false
                    }

                    for (let i of this.state.questionPallete) {
                        if (i.date > temporaryTemplate.date) {
                            if (i.context.name === temporaryTemplate.name) {
                                i.context.order = true
                            }
                        }
                    }
                    break;
                case 'cashPurchaseSales':
                    questionObject = {
                        questionType: "order",
                        context: {
                            type: temporaryTemplate.questionType,
                            cashPurchase: "-",
                            cashSales: "-"
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.cashPurchase)) {
                        questionObject.context.cashPurchase = temporaryTemplate.cashPurchase
                        editModequestionObject.cashPurchase = false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.cashSales)) {
                        questionObject.context.cashSales = temporaryTemplate.cashSales
                        editModequestionObject.cashSales = false
                    }

                    break;
            }

            if (adderFlag === true) {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].date >= temporaryTemplate.date) {
                        this.state.questionPallete.splice(i, 0, questionObject)
                        this.state.editModequestionPalette.splice(i, 0, editModequestionObject)
                        break;
                    }
                }
            }


            this.setState({})

            // console.log(JSON.stringify(questionObject,null,4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            let questionType = this.state.questionPallete[event].context.type
            let questionName = this.state.questionPallete[event].context.name

            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPalette.splice(event, 1)

            if (questionType === 'tradeDiscount' || questionType === 'cashDiscount' || questionType === 'nodiscount') {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].context.type === 'sales') {
                        if (this.state.questionPallete[i].context.purchasedFrom === questionName) {
                            this.state.questionPallete.splice(i, 1)
                            this.state.editModequestionPalette.splice(i, 1)
                        }
                    }
                    if (this.state.questionPallete[i].context.type === 'purchaseReturns') {
                        if (this.state.questionPallete[i].context.name === questionName) {
                            this.state.questionPallete.splice(i, 1)
                            this.state.editModequestionPalette.splice(i, 1)
                        }
                    }
                }
            }
            if (questionType === 'sales') {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].context.type === 'purchaseReturns') {
                        if (this.state.questionPallete[i].context.saleReturn.istrue) {
                            if (this.state.questionPallete[i].context.saleReturn.returneeName === questionName) {
                                this.state.questionPallete.splice(i, 1)
                                this.state.editModequestionPalette.splice(i, 1)
                            }
                        }
                    }
                    if (this.state.questionPallete[i].context.type === 'salesReturns') {
                        if (this.state.questionPallete[i].context.name === questionName) {
                            this.state.questionPallete.splice(i, 1)
                            this.state.editModequestionPalette.splice(i, 1)
                        }
                    }
                }
            }
            if (questionType === 'order') {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].questionType === 'sales') {
                        if (this.state.questionPallete[i].context.name === questionName) {
                            this.state.questionPallete[i].context.order = false
                        }
                    }
                }
            }


            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 3) {
                switch (customArray[0]) {
                    case 'tradeDiscount':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'discount':
                                this.state.editModequestionPalette[customArray[2]].discount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'billno':
                                this.state.editModequestionPalette[customArray[2]].billno = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'cashDiscount':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'discount':
                                this.state.editModequestionPalette[customArray[2]].discount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'credit':
                                this.state.editModequestionPalette[customArray[2]].credit = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'memo':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'fixedAsset':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'assetName':
                                this.state.editModequestionPalette[customArray[2]].assetName = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'billno':
                                this.state.editModequestionPalette[customArray[2]].billno = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'nodiscount':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'billno':
                                this.state.editModequestionPalette[customArray[2]].billno = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break
                    case 'sales':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'tradeDiscount':
                                this.state.editModequestionPalette[customArray[2]].tradeDiscount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'cashDiscount':
                                this.state.editModequestionPalette[customArray[2]].cashDiscount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'credit':
                                this.state.editModequestionPalette[customArray[2]].credit = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'invoiceNo':
                                this.state.editModequestionPalette[customArray[2]].invoiceNo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'purchaseReturns':
                        switch (customArray[1]) {
                            case 'percentageReturn':
                                this.state.editModequestionPalette[customArray[2]].percentageReturn = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'debitNoteNo':
                                this.state.editModequestionPalette[customArray[2]].debitNoteNo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'salesReturns':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'creditNoteNo':
                                this.state.editModequestionPalette[customArray[2]].creditNoteNo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'cashPurchaseSales':
                        switch (customArray[1]) {
                            case 'cashPurchase':
                                this.state.editModequestionPalette[customArray[2]].cashPurchase = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'cashSales':
                                this.state.editModequestionPalette[customArray[2]].cashSales = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'order':
                        switch (customArray[1]) {
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                }
            }


            switch (event.target.name) {
                case 'bookOwnerGender':
                    this.setState({
                        editModebookOwnerGender: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'bookOwnerName':
                    this.setState({
                        editModebookOwnerName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'year':
                    this.setState({
                        editModeyear: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'month':
                    this.setState({
                        editModemonth: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {

            for (let i of this.state.editModequestionPalette) {
                if (i.name) {
                    i.name = false
                }
                if (i.amount) {
                    i.amount = false
                }
                if (i.discount) {
                    i.discount = false
                }
                if (i.billno) {
                    i.billno = false
                }
                if (i.credit) {
                    i.credit = false
                }
                if (i.assetName) {
                    i.assetName = false
                }
                if (i.tradeDiscount) {
                    i.tradeDiscount = false
                }
                if (i.cashDiscount) {
                    i.cashDiscount = false
                }
                if (i.invoiceNo) {
                    i.invoiceNo = false
                }
                if (i.percentageReturn) {
                    i.percentageReturn = false
                }
                if (i.debitNoteNo) {
                    i.debitNoteNo = false
                }
                if (i.creditNoteNo) {
                    i.creditNoteNo = false
                }
                if (i.cashPurchase) {
                    i.cashPurchase = false
                }
                if (i.cashSales) {
                    i.cashSales = false
                }
            }


            this.setState({
                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModemonth: false,
                editModeyear: false,

                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        નીચેના વ્યવહારો પરથી&nbsp;
                        {this.state.editModebookOwnerGender ?
                            <FormControl style={{ width: "10%" }}>
                                <InputLabel id="demo-simple-select-label">જાતિ</InputLabel>
                                <Select
                                    labelId="gender"
                                    id="gender"
                                    value={this.state.bookOwnerGender}
                                    label="Option to Add"
                                    onChange={this.genderChange.bind(this)}
                                >
                                    <MenuItem value={"Shri"}>શ્રી</MenuItem>
                                    <MenuItem value={"Shrimati"}>શ્રીમતી</MenuItem>
                                    <MenuItem value={"Kumar"}>કુમાર</MenuItem>
                                    <MenuItem value={"Kumari"}>કુમારી</MenuItem>
                                </Select>
                            </FormControl> :
                            <a className='txt' name="bookOwnerGender" onClick={this.buttonMaker.bind(this)}> {this.state.bookOwnerGender}</a>
                        } 
                        &nbsp;&nbsp;
                        {this.state.editModebookOwnerName ? <TextField style={{ 'margin-top': "-15px", }} name="bookOwnerName" id='outlined-base' error={this.state.bookOwnerName === ''}
                            value={this.state.bookOwnerName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="bookOwnerName" onClick={this.buttonMaker.bind(this)}> {this.state.bookOwnerName}</a>}ના ચોપડે ખરીદનોંધ, વેચાણનોંધ, ખરીદપરત નોંધ અને વેચાણપરત નોંધ તૈયાર કરો.
                        <br /><br />
                        માસ :&nbsp;
                        {this.state.editModemonth ?
                            <FormControl style={{ width: "20%" }} size="small">
                                <InputLabel id="demo-simple-select-label">માસ</InputLabel>
                                <Select
                                    labelId="month"
                                    id="month"
                                    value={this.state.month}
                                    label="Month"
                                    onChange={this.monthChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"January"}>જાન્યુઆરી</MenuItem>
                                    <MenuItem value={"February"}>ફેબ્રુઆરી</MenuItem>
                                    <MenuItem value={"March"}>માર્ચ</MenuItem>
                                    <MenuItem value={"April"}>એપ્રિલ</MenuItem>
                                    <MenuItem value={"May"}>મે</MenuItem>
                                    <MenuItem value={"June"}>જૂન</MenuItem>
                                    <MenuItem value={"July"}>જુલાઈ</MenuItem>
                                    <MenuItem value={"August"}>ઓગસ્ટ</MenuItem>
                                    <MenuItem value={"September"}>સપ્ટેમ્બર</MenuItem>
                                    <MenuItem value={"October"}>ઓક્ટોબર</MenuItem>
                                    <MenuItem value={"November"}>નવેમ્બર</MenuItem>
                                    <MenuItem value={"December"}>ડિસેમ્બર</MenuItem>
                                </Select>
                            </FormControl> :
                            <a className='txt' name="month" onClick={this.buttonMaker.bind(this)}> {this.state.month}</a>
                        }
                        &nbsp;&nbsp;વર્ષ :&nbsp;
                        {this.state.editModeyear ? <TextField style={{ 'margin-top': "-15px", }} name="year" id='outlined-base' error={this.state.year === '' || isNaN(this.state.year) || parseInt(this.state.year) < 1000 || parseInt(this.state.year) > 9000}
                            value={this.state.year} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="year" onClick={this.buttonMaker.bind(this)}> {this.state.year}</a>}.
                        <br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px", fontSize:"20px" }} align='right' onClick={this.editModeOff.bind(this)} >સંપાદિત મોડ બંધ કરો</ColorButton> : <span></span>}

                        <br /><br />
                        તારીખ<br/><br/>
                        <div label="totalQuestionsInArray">
                            {this.state.questionPallete.map((x, i) => (
                                <div>
                                    <span>{x.date}</span>&emsp;&emsp;
                                    {x.context.type === 'tradeDiscount' &&
                                        <span>
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["tradeDiscount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            પાસેથી ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["tradeDiscount", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            નો માલ ખરીદ્યો.&nbsp;
                                            

                                            &nbsp;
                                            {this.state.editModequestionPalette[i].discount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "discount", i]} id='outlined-base' error={x.context.discount === '' || isNaN(x.context.discount) || x.context.discount < 0 || x.context.discount > 100}
                                                value={x.context.discount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["tradeDiscount", "discount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.discount}</a>} %&nbsp;


                                            વેપારીવટાવ
                                            {x.context.paid.type === true &&
                                                <span>
                                                    &nbsp;and {parseFloat(x.context.amount) / 2 === parseFloat(x.context.paid.amount) ? "half " : <span>₹ {x.context.paid.amount}</span>} of the amount paid immediately
                                                </span>
                                            }.&nbsp;
                                            {x.context.billno !== '-' &&
                                                <span>બિલ નં.&nbsp;
                                                    {this.state.editModequestionPalette[i].billno ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "billno", i]} id='outlined-base' error={x.context.billno === '' || isNaN(x.context.billno)}
                                                        value={x.context.billno} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["tradeDiscount", "billno", i]} onClick={this.buttonMaker.bind(this)}> {x.context.billno}</a>}.&nbsp;
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'cashDiscount' &&
                                        <span>
                                            પાસેથી&nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["cashDiscount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["cashDiscount", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            

                                            નો માલ&nbsp;
                                            {this.state.editModequestionPalette[i].discount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "discount", i]} id='outlined-base' error={x.context.discount === '' || isNaN(x.context.discount) || x.context.discount < 0 || x.context.discount > 100}
                                                value={x.context.discount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["cashDiscount", "discount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.discount}</a>} %&nbsp;


                                            રોકડ વટાવે ખરીદ્યો
                                            {x.context.paid.type === true &&
                                                <span>
                                                    &nbsp;and {parseFloat(x.context.amount) / 2 === parseFloat(x.context.paid.amount) ? "half " : <span>₹ {x.context.paid.amount}</span>} of the amount paid immediately
                                                </span>
                                            }.&nbsp;
                                            {x.context.credit !== '-' &&
                                                <span>શાખ&nbsp;
                                                    {this.state.editModequestionPalette[i].credit ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["cashDiscount", "credit", i]} id='outlined-base' error={x.context.credit === '' || isNaN(x.context.credit)}
                                                        value={x.context.credit} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["cashDiscount", "credit", i]} onClick={this.buttonMaker.bind(this)}> {x.context.credit}</a>} માસ.&nbsp;
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'memo' &&
                                        <span>
                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["memo", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["memo", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}નો માલ ખરીદ્યો.&nbsp;
                                            {x.context.no !== '-' ? <span>કેશમેમો નં. {x.context.no}.</span> : "Cash Memo"}
                                        </span>
                                    }
                                    {x.context.type === 'fixedAsset' &&
                                        <span>
                                            
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "30%" }} name={["fixedAsset", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;માંથી&nbsp;
                                            ₹&nbsp;                
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}નું&nbsp;

                                            {this.state.editModequestionPalette[i].assetName ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "assetName", i]} id='outlined-base' error={x.context.assetName === ''}
                                                value={x.context.assetName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "assetName", i]} onClick={this.buttonMaker.bind(this)}> {x.context.assetName}</a>}&nbsp;ખરીદ્યું&nbsp;

                                            
                                            

                                            

                                            {x.context.paid.type === true &&
                                                <span>
                                                    &nbsp;and {parseFloat(x.context.amount) / 2 === parseFloat(x.context.paid.amount) ? "half " : <span>₹ {x.context.paid.amount}</span>} of the amount paid immediately
                                                </span>
                                            }.&nbsp;
                                            {x.context.billno !== '-' &&
                                                <span>Bill no.&nbsp;
                                                    {this.state.editModequestionPalette[i].billno ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "billno", i]} id='outlined-base' error={x.context.billno === '' || isNaN(x.context.billno)}
                                                        value={x.context.billno} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["fixedAsset", "billno", i]} onClick={this.buttonMaker.bind(this)}> {x.context.billno}</a>}.&nbsp;
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'nodiscount' &&
                                        <span>
                                            
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["nodiscount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            પાસેથી ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["nodiscount", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;નો માલ ખરીદો.&nbsp;

                                            

                                            {x.context.paid.type === true &&
                                                <span>
                                                    &nbsp;{parseFloat(x.context.amount) / 2 === parseFloat(x.context.paid.amount) ? "અડધાં " : <span>₹ {x.context.paid.amount}</span>} નાણાં તરત જ ચૂકવી દીધા
                                                </span>
                                            }.&nbsp;
                                            {x.context.billno !== '-' &&
                                                <span>Bill no.&nbsp;
                                                    {this.state.editModequestionPalette[i].billno ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["nodiscount", "billno", i]} id='outlined-base' error={x.context.billno === '' || isNaN(x.context.billno)}
                                                        value={x.context.billno} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["nodiscount", "billno", i]} onClick={this.buttonMaker.bind(this)}> {x.context.billno}</a>}.&nbsp;
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'sales' &&
                                        <span>
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sales", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;ને

                                            ₹
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sales", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;
                                                
                                                

                                            {x.context.purchasedFrom !== '-' ?
                                                <span>માં {x.context.purchasedFrom} પાસેથી ખરીદેલ બધો જ માલ&nbsp;
                                                </span>
                                                : "નો માલ "}

                                            
                                            {x.context.purchasedFrom !== '-' && ""}

                                            

                                            {x.context.tradeDiscount !== '-' ? x.context.cashDiscount !== '-' ? " " : " " : ""}
                                            {x.context.cashDiscount !== '-' &&
                                                <span>&nbsp;
                                                    {this.state.editModequestionPalette[i].cashDiscount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "cashDiscount", i]} id='outlined-base' error={x.context.cashDiscount === '' || isNaN(x.context.cashDiscount) || x.context.cashDiscount < 0 || x.context.cashDiscount > 100}
                                                        value={x.context.cashDiscount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["sales", "cashDiscount", i]} onClick={this.buttonMaker.bind(this)}>{x.context.cashDiscount}</a>} % રોકડ વટાવે
                                                </span>
                                            }
                                            
                                            {x.context.tradeDiscount !== '-' ? x.context.cashDiscount !== '-' ? " અને" : "" : ""}
                                            {x.context.tradeDiscount !== '-' &&
                                                <span>
                                                    {this.state.editModequestionPalette[i].tradeDiscount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "tradeDiscount", i]} id='outlined-base' error={x.context.tradeDiscount === '' || isNaN(x.context.tradeDiscount) || x.context.tradeDiscount < 0 || x.context.tradeDiscount > 100}
                                                        value={x.context.tradeDiscount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["sales", "tradeDiscount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.tradeDiscount}</a>} % વેપારી વટાવે વેચ્યો
                                                </span>
                                            }  {x.context.order &&
                                             "મોકલી આપવા ઑર્ડર આપ્યો"}.
                                            {x.context.extraSum !== '-' &&
                                                <span>&nbsp;
                                                    ₹ {x.context.extraSum} ઉમેર્યા {x.context.extraSumReason !== '-' ? <span>.</span> : "for extra expense."}
                                                </span>
                                            }&nbsp;

                                            {x.context.credit !== '-' &&
                                                <span>શાખ&nbsp;
                                                    {this.state.editModequestionPalette[i].credit ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "credit", i]} id='outlined-base' error={x.context.credit === '' || isNaN(x.context.credit)}
                                                        value={x.context.credit} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["sales", "credit", i]} onClick={this.buttonMaker.bind(this)}>{x.context.credit}</a>} માસ.&nbsp;
                                                </span>
                                            }

                                            બિલ નં. :&nbsp;
                                            {this.state.editModequestionPalette[i].invoiceNo ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "invoiceNo", i]} id='outlined-base' error={x.context.invoiceNo === '' || isNaN(x.context.invoiceNo)}
                                                value={x.context.invoiceNo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sales", "invoiceNo", i]} onClick={this.buttonMaker.bind(this)}> {x.context.invoiceNo}</a>}.&nbsp;


                                        </span>
                                    }
                                    {x.context.type === 'purchaseReturns' &&
                                        <span>
                                            {x.context.saleReturn.istrue ?
                                                <span>
                                                    {x.context.saleReturn.returneeName} પાસેથી&nbsp;
                                                    {this.state.editModequestionPalette[i].percentageReturn ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "percentageReturn", i]} id='outlined-base' error={x.context.percentageReturn === '' || isNaN(x.context.percentageReturn) || x.context.percentageReturn < 0 || x.context.percentageReturn > 100}
                                                        value={x.context.percentageReturn} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["purchaseReturns", "percentageReturn", i]} onClick={this.buttonMaker.bind(this)}>{parseFloat(x.context.percentageReturn) === 50 ? "અડધો" : x.context.percentageReturn}</a>} {parseFloat(x.context.percentageReturn) !== 50 && "%"} માલ પરત આવ્યો,&nbsp;
                                                        જે માલ {x.context.name} ને મોકલી આપવામાં આવ્યો.&nbsp;
                                                </span> :
                                                <span>
                                                    {x.context.name}&nbsp;ને
                                                    {this.state.editModequestionPalette[i].percentageReturn ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "percentageReturn", i]} id='outlined-base' error={x.context.percentageReturn === '' || isNaN(x.context.percentageReturn) || x.context.percentageReturn < 0 || x.context.percentageReturn > 100}
                                                        value={x.context.percentageReturn} onChange={this.eventHandler} variant="standard" autoComplete='off' /> : 
                                                        <a  className='txt' name={["purchaseReturns", "percentageReturn", i]}  onClick={this.buttonMaker.bind(this)}>{parseFloat(x.context.percentageReturn) === 50 ? "Half" : x.context.percentageReturn}</a>}  {parseFloat(x.context.percentageReturn) !== 50 && "%"} માલ પરત કર્યો અને&nbsp;
                                                        
                                                </span>}
                                                

                                            {x.context.debitNoteNo !== '-' &&
                                                <span>
                                                    ઉધારચિઠ્ઠી નં.&nbsp;
                                                    {this.state.editModequestionPalette[i].debitNoteNo ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "debitNoteNo", i]} id='outlined-base' error={x.context.debitNoteNo === '' || isNaN(x.context.debitNoteNo)}
                                                        value={x.context.debitNoteNo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["purchaseReturns", "debitNoteNo", i]} onClick={this.buttonMaker.bind(this)}>{x.context.debitNoteNo}</a>} મોકલી આપી.
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'salesReturns' &&
                                        <span>
                                            {x.context.name}&nbsp;ને
                                            ₹&nbsp;

                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["salesReturns", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["salesReturns", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;નો માલ પરત કર્યો,


                                            {x.context.rebate === true && " અને પ્રમાણસર મજુરીના મજરે આપવાનું નક્કી કર્યું"}&nbsp;

                                            {x.context.creditNoteNo !== '-' &&
                                                <span>
                                                    જેની સામે જમાચિઠ્ઠી નં.&nbsp;
                                                    {this.state.editModequestionPalette[i].creditNoteNo ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "creditNoteNo", i]} id='outlined-base' error={x.context.creditNoteNo === '' || isNaN(x.context.creditNoteNo)}
                                                        value={x.context.creditNoteNo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["purchaseReturns", "creditNoteNo", i]} onClick={this.buttonMaker.bind(this)}>{x.context.creditNoteNo}</a>} સાજનને મોકલી આપી.
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'order' &&
                                        <span>
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["order", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["order", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;
                                            એ માલ મોકલી આપવા ઑર્ડર આપ્યો.
                                        </span>
                                    }
                                    {x.context.type === 'cashPurchaseSales' &&
                                        <span>
                                            {x.context.cashPurchase !== '-' &&
                                                <span>રોક્ડ ખરીદી ₹&nbsp;
                                                    {this.state.editModequestionPalette[i].cashPurchase ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["cashPurchaseSales", "cashPurchase", i]} id='outlined-base' error={x.context.cashPurchase === '' || isNaN(x.context.cashPurchase)}
                                                        value={x.context.cashPurchase} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["cashPurchaseSales", "cashPurchase", i]} onClick={this.buttonMaker.bind(this)}> {x.context.cashPurchase}</a>
                                                    }
                                                </span>
                                            }

                                            {x.context.cashPurchase !== '-' ? x.context.cashSales !== '-' ? " અને " : " " : " "}

                                            {x.context.cashSales !== '-' &&
                                                <span>રોડવેચાણ ₹&nbsp;
                                                    {this.state.editModequestionPalette[i].cashSales ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["cashPurchaseSales", "cashSales", i]} id='outlined-base' error={x.context.cashSales === '' || isNaN(x.context.cashSales)}
                                                        value={x.context.cashSales} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["cashPurchaseSales", "cashSales", i]} onClick={this.buttonMaker.bind(this)}> {x.context.cashSales}</a>
                                                    }
                                                </span>
                                            }.
                                        </span>
                                    }

                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeQuestion.bind(this, i)} name="-">-</IconButton>

                                    <br /><br />
                                </div>
                            ))}
                        </div>

                        <div label="addQuestionInputs">
                            નવો પ્રશ્ન ઉમેરો :<br /><br /><br />
                            પ્રશ્નનો પ્રકાર: 
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">પ્રશ્ન</InputLabel>
                                <Select
                                    labelId="question"
                                    id="question"
                                    value={this.state.addQuestionType}
                                    label="Question"
                                    onChange={this.questionTypeChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"none"}>None</MenuItem>
                                    <MenuItem value={"tradeDiscount"}>ખરીદી - વેપારી વટાવ</MenuItem>
                                    <MenuItem value={"cashDiscount"}>ખરીદી - રોકડ વટાવ</MenuItem>
                                    <MenuItem value={"memo"}>ખરીદી - કેશ મેમો</MenuItem>
                                    <MenuItem value={"fixedAsset"}>ખરીદી - નિયત મિલકત</MenuItem>
                                    <MenuItem value={"nodiscount"}>ખરીદી - કોઈ ડિસ્કાઉન્ટ નથી</MenuItem>
                                    <MenuItem value={"sales"}>વેચાણ</MenuItem>
                                    <MenuItem value={"purchaseReturns"}>ખરીદી પરત</MenuItem>
                                    <MenuItem value={"salesReturns"}>વેચાણ પરત</MenuItem>
                                    <MenuItem value={"order"}>પ્રી-ઓર્ડર પ્લેસમેન્ટ</MenuItem>
                                    <MenuItem value={"cashPurchaseSales"}>રોકડ ખરીદી/વેચાણ</MenuItem>                                    {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                </Select>
                            </FormControl><br /><br /><br />


                            {this.state.addQuestionType === 'tradeDiscount' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ડિસ્કાઉન્ટ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="discount" error={this.state.tempDiscount === '' || isNaN(this.state.tempDiscount) || this.state.tempDiscount < 0 || this.state.tempDiscount > 100}
                                        placeholder="ડિસ્કાઉન્ટ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    બિલ નંબર દાખલ કરો.(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="billno" error={isNaN(this.state.tempBillno)}
                                        placeholder="બિલ નંબર દાખલ કરો." onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ તરત જ ચૂકવવામાં આવી હતી?(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="ચૂકવેલ રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'cashDiscount' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ડિસ્કાઉન્ટ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="discount" error={this.state.tempDiscount === '' || isNaN(this.state.tempDiscount) || this.state.tempDiscount < 0 || this.state.tempDiscount > 100}
                                        placeholder="ડિસ્કાઉન્ટ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ક્રેડિટ મહિના દાખલ કરો(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="credit" error={isNaN(this.state.tempCredit)}
                                        placeholder="મહિના દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ તરત જ ચૂકવવામાં આવી હતી?(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="ચૂકવેલ રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'memo' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    મેમો નંબર દાખલ કરો.(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="memo" error={isNaN(this.state.tempCredit)}
                                        placeholder="મહિના દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ તરત જ ચૂકવવામાં આવી હતી?(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="ચૂકવેલ રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'fixedAsset' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="assetName" error={this.state.tempAssetName === ''}
                                        placeholder="સંપત્તિનું નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    સંપત્તિનું નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ તરત જ ચૂકવવામાં આવી હતી?(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="ચૂકવેલ રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'nodiscount' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ તરત જ ચૂકવવામાં આવી હતી?(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="ચૂકવેલ રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'sales' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    વેપારી વટાવ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="Tdiscount" error={isNaN(this.state.tempTDiscount) || this.state.tempTDiscount < 0 || this.state.tempTDiscount > 100}
                                        placeholder="વેપારી વટાવ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રોકડ વટાવ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="Cdiscount" error={isNaN(this.state.tempCDiscount) || this.state.tempCDiscount < 0 || this.state.tempCDiscount > 100}
                                        placeholder="રોકડ વટાવ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ઇન્વોઇસ નંબર દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="invoiceNo" error={isNaN(this.state.tempInvoiceNo) || this.state.tempInvoiceNo === ''}
                                        placeholder="ઇન્વોઇસ નંબર દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    વધારાની રકમ ઉમેરાઈ: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="extraAmount" error={isNaN(this.state.tempExtraSum)}
                                        placeholder="વધારાની રકમ" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    વધારાની રકમનું કારણ: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="extraAmountReason"
                                        placeholder="વધારાની રકમનું કારણ" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    તેઓ કોઈ બીજા પાસેથી ખરીદવામાં આવ્યા હતા? <br />

                                    જો હા, તો તેઓ કોની પાસેથી ખરીદવામાં આવ્યા હતા? :
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Purchased From</InputLabel>
                                        <Select
                                            labelId="purchaseFromName"
                                            id="purchaseFromName"
                                            value={this.state.tempPurchasedFrom}
                                            label="Name"
                                            onChange={this.purchaseFromNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "tradeDiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "cashDiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "nodiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'purchaseReturns' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "tradeDiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "cashDiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "nodiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    પરત કરેલ રકમની ટકાવારી દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPercentage" error={this.state.tempAmountPercentage === '' || isNaN(this.state.tempAmountPercentage) || this.state.tempAmountPercentage < 0 || this.state.tempAmountPercentage > 100}
                                        placeholder="રકમની ટકાવારી દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ડેબિટ નોંધ નંબર દાખલ કરો.: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="debitNoteNo" error={isNaN(this.state.tempDebitNoteNo)}
                                        placeholder="ડેબિટ નોંધ નંબર દાખલ કરો." onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    શું માલ કોઈના વેચાણ દ્વારા પરત કરવામાં આવ્યો હતો?<br /><br />

                                    જો હા, તો રિટર્નીનું નામ:&nbsp;

                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">પરત કરનારનું નામ</InputLabel>
                                        <Select
                                            labelId="returneeName"
                                            id="returneeName"
                                            value={this.state.tempReturneeName}
                                            label="returneeName"
                                            onChange={this.addReturneeNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.questionPallete.map((y, j) => (
                                                y.questionType === "sales" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'salesReturns' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Name*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "sales" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ક્રેડિટ નોટ નંબર દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="creditNoteNo" error={isNaN(this.state.tempCreditNoteNo)}
                                        placeholder="ક્રેડિટ નોટ નંબર દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રિબેટ સંમત હતો ? :&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Name*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            value={this.state.tempRebate}
                                            label="Name"
                                            onChange={this.rebateChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value={false}>ના</MenuItem>
                                            <MenuItem value={true}>હા</MenuItem>
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === "order" &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'cashPurchaseSales' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રોકડ ખરીદી દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="cashPurchase" error={isNaN(this.state.tempCashPurchase)}
                                        placeholder="રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રોકડ વેચાણ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="cashSales" error={isNaN(this.state.tempCashSales)}
                                        placeholder="રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }

                            {this.state.addQuestionType !== 'none' && <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%", fontSize:"20px" }} onClick={this.addQuestion.bind(this)}>નવો પ્રશ્ન ઉમેરો</AddButton>}
                        </div>

                        <br /><br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px", fontSize:"20px" }} align='right' onClick={this.editModeOff.bind(this)} >Edit Mode Off</ColorButton> : <span></span>}


                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો/છુપાવો</AddButton>
                        </div>
                        <div align='right'>
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                        <br />
                        <br />

                    </div>
                    <br /><br />
                    {!this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>જવાબ. :</b><br /><br />
                            <div align="center"><b>{data[chartState.selected].bookOwnerName} ની ખરીદનોંધ</b></div><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px" }}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px" }}>વેપારીનું નામ</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>આવક <br /> ભરતિયા નં.</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ <br />(₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center'>{data[chartState.selected].year}</StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].purchaseBook.map((x, i) => (
                                            parseInt(i) !== 0 &&
                                            <TableRow>
                                                <StyledTableCell align='center'>{parseInt(i) === 1 && data[chartState.selected].month.slice(0, 3) + "."} {x.date}</StyledTableCell>
                                                <StyledTableCell align='left' style={{fontSize: "16px"}}>{x.context.name} ખાતે</StyledTableCell>
                                                <StyledTableCell align='center'>{x.context.billno}{x.context.credit !== undefined && "-"}</StyledTableCell>
                                                <StyledTableCell align='right'>{x.context.finalAmount}{x.context.finalAmount === undefined && x.context.amount}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='right' style={{fontSize: "16px"}}>કુલ</StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].purchaseBook[0]}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align="center"><b>{data[chartState.selected].bookOwnerName} ની વેચાણનોંધ</b></div><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px" }}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px" }}>ગ્રાહકનું નામ</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>જાવક <br /> ભરતિયા નં.</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ <br />(₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center'>{data[chartState.selected].year}</StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].salesBook.map((x, i) => (
                                            parseInt(i) !== 0 &&
                                            <TableRow>
                                                <StyledTableCell align='center'>{parseInt(i) === 1 && data[chartState.selected].month.slice(0, 3) + "."} {x.date}</StyledTableCell>
                                                <StyledTableCell align='left' style={{fontSize: "16px"}}>{x.context.name} ખાતે</StyledTableCell>
                                                <StyledTableCell align='center'>{x.context.invoiceNo}</StyledTableCell>
                                                <StyledTableCell align='right'>{x.context.finalAmount}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='right' style={{fontSize: "16px"}}>કુલ</StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].salesBook[0]}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align="center"><b>{data[chartState.selected].bookOwnerName} ની ખરીદપરત નોંધ</b></div><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px" }}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px" }}>વેપારીનું નામ</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>ઉધાર <br /> ચિઠ્ઠી નં.</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ <br />(₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center'>{data[chartState.selected].year}</StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].purchaseReturns.map((x, i) => (
                                            parseInt(i) !== 0 &&
                                            <TableRow>
                                                <StyledTableCell align='center'>{parseInt(i) === 1 && data[chartState.selected].month.slice(0, 3) + "."} {x.date}</StyledTableCell>
                                                <StyledTableCell align='left' style={{fontSize: "16px"}}>{x.context.name} ખાતે</StyledTableCell>
                                                <StyledTableCell align='center'>{x.context.debitNoteNo}</StyledTableCell>
                                                <StyledTableCell align='right'>{x.context.amountReturned}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='right' style={{fontSize: "16px"}}><b>કુલ</b></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].purchaseReturns[0]}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align="center"><b>{data[chartState.selected].bookOwnerName} ની વેચાણપરત નોંધ</b></div><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px" }}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px" }}>ગ્રાહકનું નામ</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>જમા <br /> ચિઠ્ઠી નં.</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ <br />(₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center'>{data[chartState.selected].year}</StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].salesReturns.map((x, i) => (
                                            parseInt(i) !== 0 &&
                                            <TableRow>
                                                <StyledTableCell align='center'>{parseInt(i) === 1 && data[chartState.selected].month.slice(0, 3) + "."} {x.date}</StyledTableCell>
                                                <StyledTableCell align='left' style={{fontSize: "16px"}}>{x.context.name} ખાતે</StyledTableCell>
                                                <StyledTableCell align='center'>{x.context.creditNoteNo}</StyledTableCell>
                                                <StyledTableCell align='right'>{x.context.amount}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='right' style={{fontSize: "16px"}}><b>કુલ</b></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].salesReturns[0]}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                            <b>નૉૅધ : </b> નીચેના વ્યવહારોની નોંધ થશે નહિ :<br /><br />
                            {data[chartState.selected].transactionsNotRecorded.map((x, i) => (
                                <span>{parseInt(i) === 0 ? "(1) તા." : <span>&emsp;&emsp;&ensp;&nbsp;</span>} {data[chartState.selected].month.slice(0, 3)}.&ensp; {x.date}&emsp;
                                    {x.type === "memo" && "રોકડ ખરીદીનો વ્યવહાર કે જે રોકડમેળમાં નોંધાશે."}
                                    {x.type === "fixedAsset" && "મિલકત ખરીદીનો વ્યવહાર કે જે ખાસ આમનોંધમાં નોંધાશે."}
                                    {x.type === "order" && "બિનઆર્થિક વ્યવહાર કે જેની નોંધ કરવામાં આવતી નથી."}
                                    {x.type === "extraSum" && "આ વ્યવહારના ₹ " + x.amount + " મજૂરીના રોકડમેળમાં નોંધાશે."}
                                    {x.type === "cashPurchaseSales" &&
                                        <span>
                                            {x.cashPurchase !== '-' && "રોક્ડખરીદી"}
                                            {x.cashPurchase !== '-' ? x.cashSales !== '-' ? " અને " : "" : ""}
                                            {x.cashSales !== '-' && "રોકડવેચાણનો"} વ્યવહાર કે જે રોકડમેળમાં નોંધાશે.
                                        </span>}
                                    <br />
                                </span>
                            ))}<br />

                            {data[chartState.selected].otherTransactions.map((x, i) => (
                                <span>{parseInt(i) === 0 ? "(2) તા. " : <span>&emsp;&emsp;&ensp;&nbsp;</span>} {data[chartState.selected].month.slice(0, 3)}.&ensp; {x.showDate} &nbsp;
                                    {x.type === 'specialPurchaseReturns' &&
                                        <span>
                                            ના વ્યવહારમાં પરત આવેલ&nbsp;
                                            {x.percentageReturn} % માલ તા. {x.initialDate} {data[chartState.selected].month} પૈકીનો હશે. તા. {x.initialDate} {data[chartState.selected].month} ની ખરીદીની ચોખ્ખી રકમ ₹ {x.initialAmount}છે.&nbsp;
                                            આથી, ₹ {x.initialAmount} x {x.percentageReturn} % = ₹ {x.returnedAmount} ખરીદમાલ પરત તરીકે નોંધ થાય.
                                        </span>}
                                    {x.type === 'specialPurchaseSalesReturns' &&
                                        <span>
                                            ના રોજ {x.soldToName} ને  {parseFloat(x.amountReturnedByPurchaser) * 100 / parseFloat(x.percent)} માં વેચાણ કરેલ જેમાંથી {parseFloat(x.percent) === 50 ? "અડધો" : x.percent + " %"} માલ પરત આવેલ છે.&nbsp;
                                            આથી તા. {x.finalDate} ના રોજ વેચેલ માલ પરત તરીકે {parseFloat(x.amountReturnedByPurchaser) * 100 / parseFloat(x.percent)} x {x.percent} % = ₹ {x.amountReturnedByPurchaser} દર્શાવેલ છે.&nbsp;
                                            આ બધો જ માલ {x.purchasedFromName} પાસેથી ખરીદેલ પૈકીનો છે. {x.purchasedFromName} પાસેથી ખરીદેલ માલની ચોખ્ખી રકમ {parseFloat(x.amountReturnedToSeller) * 100 / parseFloat(x.percent)} હતી. આથી ખરીદેલ માલપરત તરીકે ₹ {parseFloat(x.amountReturnedToSeller) * 100 / parseFloat(x.percent)} x {x.percent} % = ₹ {x.amountReturnedToSeller} દર્શાવેલ છે.
                                        </span>}
                                    <br /><br />
                                </span>
                            ))}

                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
import { Co2Sharp } from '@mui/icons-material';

//ILLUSTRATION 8

export default function ComparativeProfitLossStatement() {

    const data = [{
        "partners": [
            "Arjun",
            "Vaishali",
            "Jalpa"
        ],
        "capitalAccount": [
            300000,
            200000,
            100000
        ],
        "liabilities": [
            {
                "type": "Sundry creditors",
                "amount": 300000,
                "added": true
            },
            {
                "type": "Bills payable",
                "amount": 40000,
                "added": true
            }
        ],
        "assets": [
            {
                "type": "Machinery",
                "amount": 10000,
                "added": true
            },
            {
                "type": "Debtors",
                "amount": 280000,
                "added": true
            },
            {
                "type": "Bills receivable",
                "amount": 50000,
                "added": true
            },
            {
                "type": "Stock",
                "amount": 450000,
                "added": true
            }
        ],
        "totalAmountLeftSide": 940000,
        "profitLossAccount": 150000,
        "partnersProfitLossAccount": [
            60000,
            45000,
            45000
        ],
        "answerPaletteTable2": [
            {
                "name": "Vaishali",
                "nameOne": "Sundry creditors",
                "amountOne": 300000,
                "nameTwo": "Debtors",
                "amountTwo": 224000
            },
            {
                "name": "Arjun",
                "nameOne": "Bills payable",
                "amountOne": 40000,
                "nameTwo": "Stock",
                "amountTwo": 405000
            },
            {
                "name": "Jalpa",
                "nameOne": "Bills receivable",
                "amountOne": 35000,
                "nameTwo": "Machinery",
                "amountTwo": 9000,
                "flag": true
            }
        ],
        "totalPartnersCapitalLoss": 117000,
        "capitalAccountLoss": [
            46800,
            35100,
            35100
        ],
        "totalTable2": 1130000,
        "toRealizationAccount": [
            405000,
            224000,
            44000
        ],
        "byRealizationAccount": [
            40000,
            300000,
            0
        ],
        "toCashByCash": {
            "left": [
                0,
                195900,
                0
            ],
            "right": [
                171800,
                0,
                24100
            ]
        },
        "highestTotal": [
            511800,
            500000,
            124100
        ],
        "leftSideTableFinal": [
            {
                "name": "Arjun",
                "amount": 171800
            },
            {
                "name": "Jalpa",
                "amount": 24100
            }
        ],
        "rightSideTableFinal": [
            {
                "name": "Vaishali",
                "amount": 195900
            }
        ]
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                partners: ["Arjun", "Vaishali", "Jalpa"],
                capitalAccount: [300000, 200000, 100000],
                initialProfitLossRatio: [4, 3, 3],
                date: "2016-03-31",
                liabilities: [{
                    type: "Sundry creditors",
                    amount: 300000,
                    added: true
                }, {
                    type: "Bills payable",
                    amount: 40000,
                    added: true
                }],
                assets: [{
                    type: "Machinery",
                    amount: 10000,
                    added: true
                }, {
                    type: "Debtors",
                    amount: 280000,
                    added: true,
                }, {
                    type: "Bills receivable",
                    amount: 50000,
                    added: true
                }, {
                    type: "Stock",
                    amount: 450000,
                    added: true
                },],
                questionPallete: [

                    {//1
                        type: "acceptedPaid",
                        name: "Vaishali",
                        assetOne: "Debtors",
                        discount: 20,
                        assetTwo: "Sundry creditors"
                    },
                    {//2
                        type: "acceptedPaid",
                        name: "Arjun",
                        assetOne: "Stock",
                        discount: 10,
                        assetTwo: "Bills payable"
                    },
                    {//3
                        type: "takenDepreciated",
                        name: "Jalpa",
                        assetOne: "Bills receivable",
                        amount: 35000,
                        assetTwo: "Machinery",
                        percent: 10
                    }
                ],

                addQuestionType: 'none',
                tempName: '',
                tempAssetOne: '',
                tempAssetTwo: '',
                tempDiscount: '',
                tempAmount: '',
                tempPercent: '',

                editModepartners: [false, false, false],
                editModecapitalAccount: [false, false, false],
                editModeinitialProfitLossRatio: [false, false, false],
                editModedate: false,
                editModeliabilities: [{
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }],
                editModeassets: [{
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false,
                    originalAmount: false,
                    badDebtR: false
                }],

                editModequestionPallete: [

                    {//1
                        discount: false
                    },
                    {//2
                        discount: false
                    },
                    {//3
                        amount: false,
                        percent: false
                    }
                ],

                addQuestionChecker: false,
                submitChecker: true,
                solutionShow: false

            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.addNameChange = this.addNameChange.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'partners':
                        this.state.partners[customArray[1]] = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'initialPL':
                        this.state.initialProfitLossRatio[customArray[1]] = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'liabilitiesType':
                        this.state.liabilities[customArray[1]].type = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'liabilitiesAmount':
                        this.state.liabilities[customArray[1]].amount = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;
                    case 'assetsType':
                        // for (let i of this.state.questionPallete) {
                        //     if (i.nameOne === this.state.assets[customArray[1]].type) {
                        //         i.nameOne = event.target.value
                        //     } else if (i.nameTwo === this.state.assets[customArray[1]].type) {
                        //         i.nameTwo = event.target.value
                        //     }
                        // }
                        this.state.assets[customArray[1]].type = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'assetsAmount':
                        this.state.assets[customArray[1]].amount = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;
                    case 'assetsOriginalAmount':
                        this.state.assets[customArray[1]].originalAmount = event.target.value
                        this.setState({}, () => {
                            this.badDebtDecreaser(customArray[1])
                        })
                        break;
                    case 'assetsBadDebtR':
                        this.state.assets[customArray[1]].badDebtR = event.target.value
                        this.setState({}, () => {
                            this.badDebtDecreaser(customArray[1])
                        })
                        break;
                    case 'capitalAccount':
                        this.state.capitalAccount[customArray[1]] = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;
                    case 'partnersLoan':
                        this.state.partnersLoan[customArray[1]] = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;

                    case 'acceptedPaid':
                        this.state.questionPallete[customArray[1]].discount = event.target.value
                        this.setState({}, () => { })
                        break;
                    case 'takenDepreciatedAmount':
                        this.state.questionPallete[customArray[1]].amount = event.target.value
                        this.setState({}, () => { })
                        break;
                    case 'takenDepreciatedPercent':
                        this.state.questionPallete[customArray[1]].percent = event.target.value
                        this.setState({}, () => { })
                        break;

                    case 'interestOnCapital':
                        this.state.staticQuestionPallete[customArray[1]].percent = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'amountFirst':
                        this.state.staticQuestionPallete[customArray[1]].amountFirst = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'amountCurrent':
                        this.state.staticQuestionPallete[customArray[1]].amountCurrent = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'amountNet':
                        this.state.staticQuestionPallete[customArray[1]].amountNet = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;

                    case 'writtenOffAppreciated':
                        this.state.questionPallete[customArray[1]].percent = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'date':
                    this.setState({
                        date: event.target.value
                    }, () => { this.fillDetails() })
                    break;
            }
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'amount':
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'discount':
                    this.setState({
                        tempDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'percent':
                    this.setState({
                        tempPercent: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            switch (this.state.addQuestionType) {
                case 'acceptedPaid':
                    if (this.state.tempName !== '' &&
                        this.state.tempAssetOne !== '' &&
                        this.state.tempAssetTwo !== '' &&
                        this.state.tempDiscount !== '' && NormalNumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'takenDepreciated':
                    if (this.state.tempName !== '' &&
                        this.state.tempAssetOne !== '' &&
                        this.state.tempAssetTwo !== '' &&
                        this.state.tempPercent !== '' && NormalNumberRegex.test(this.state.tempPercent) && parseFloat(this.state.tempPercent) > 0 && parseFloat(this.state.tempPercent) < 100 &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
            }

        }

        questionTypeChange(event) {
            this.setState({
                tempName: '',
                tempAssetOne: '',
                tempAssetTwo: '',
                tempDiscount: '',
                tempAmount: '',
                tempPercent: '',

                addQuestionChecker: false,
                addQuestionType: event.target.value
            })
        }

        retiredPartnerChange(event) {
            this.setState({
                retiredPartner: event.target.value
            })
        }

        //DROPDOWN SELECTORS START

        addNameChange(event) {
            this.setState({
                tempName: event.target.value
            }, () => { this.addButtonChecker() })
        }

        addAssetOneChange(event) {
            this.setState({
                tempAssetOne: event.target.value
            }, () => { this.addButtonChecker() })
        }

        addAssetTwoChange(event) {
            this.setState({
                tempAssetTwo: event.target.value
            }, () => { this.addButtonChecker() })
        }

        //DROPDOWN SELECTORS END

        AssetWrittenOffName(event) {
            this.setState({
                tempAssetWrittenOffName: event.target.value
            }, () => { this.addButtonChecker() })
        }

        AssetAppreciatedName(event) {
            this.setState({
                tempAssetAppreciatedName: event.target.value
            }, () => { this.addButtonChecker() })
        }

        addTypeChange(event) {
            this.setState({
                tempPayType: event.target.value
            })
        }

        stringReducer(string) {
            let stringArray = string.split(" ")

            for (let i in stringArray) {
                if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === '') {
                    // console.log(i)
                    stringArray.splice(i, 1)
                }
            }

            if (stringArray[0] === null) {
                stringArray[0] = 0
            }

            this.setState({
                sampleStringLength: stringArray.length,
                sampleStringArray: stringArray
            })

            return string
        }

        totalAmountCalculator() {
            let totalAssetAmount = 0

            let totalLiabilityAmount = this.state.capitalAccount.reduce((a, b) => parseFloat(a) + parseFloat(b))
            totalLiabilityAmount += this.state.partnersLoan.reduce((a, b) => parseFloat(a) + parseFloat(b))

            for (let i of this.state.liabilities) {
                totalLiabilityAmount += parseFloat(i.amount)
            }
            for (let i of this.state.assets) {
                totalAssetAmount += parseFloat(i.amount)
            }

            this.setState({
                totalAssetAmount: totalLiabilityAmount,
                totalLiabilityAmount: totalAssetAmount
            }, () => { this.fillDetails() })

        }

        fillDetails() {

            let flag = true

            for (let i in this.state.partners) {
                if (this.state.partners[i] === '') {
                    flag = false
                }
            }

            for (let i in this.state.capitalAccount) {
                if (this.state.capitalAccount[i] === '' || !NumberRegex.test(this.state.capitalAccount[i])) {
                    flag = false
                }
            }

            for (let i in this.state.initialProfitLossRatio) {
                if (this.state.initialProfitLossRatio[i] === '' || !NumberRegex.test(this.state.initialProfitLossRatio[i])) {
                    flag = false
                }
            }

            for (let i of this.state.liabilities) {
                if (i.type === '') {
                    flag = false
                }
                if (i.amount === '' || !NumberRegex.test(i.amount)) {
                    flag = false
                }
            }

            for (let i of this.state.assets) {
                if (i.type === '') {
                    flag = false
                }
                if (i.amount === '' || !NumberRegex.test(i.amount)) {
                    flag = false
                }
            }

            for (let i of this.state.questionPallete) {
                if (i.discount) {
                    if (i.discount === '' || !NumberRegex.test(i.discount) || parseFloat(i.discount) < 0 || parseFloat(i.discount) > 100) {
                        flag = false
                    }
                }
                if (i.percent) {
                    if (i.percent === '' || !NumberRegex.test(i.percent) || parseFloat(i.percent) < 0 || parseFloat(i.percent) > 100) {
                        flag = false
                    }
                }
                if (i.amount) {
                    if (i.amount === '' || !NumberRegex.test(i.amount)) {
                        flag = false
                    }
                }
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: false,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: true,
                    submitChecker: false
                })
            }
        }


        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            let totalAmountLeftSide = this.state.capitalAccount.reduce((a, b) => (a + parseFloat(b)), 0) + this.state.liabilities.reduce((a, b) => (a + parseFloat(b.amount)), 0)

            let profitLossAccount = totalAmountLeftSide - this.state.assets.reduce((a, b) => (a + parseFloat(b.amount)), 0)
            let partnersProfitLossAccount = []

            //#region TABLE TWO START

            let answerPaletteTable2 = []
            let totalPartnersCapitalLoss = this.state.assets.reduce((a, b) => (a + parseFloat(b.amount)), 0) - this.state.liabilities.reduce((a, b) => (a + parseFloat(b.amount)), 0)
            let totalTable2 = this.state.assets.reduce((a, b) => (a + parseFloat(b.amount)), 0)
            let toRealizationAccount = [0, 0, 0]
            let byRealizationAccount = [0, 0, 0]

            for (let i of this.state.questionPallete) {
                let tempObject = {
                    name: i.name,
                    nameOne: "",
                    amountOne: 0,
                    nameTwo: "",
                    amountTwo: 0
                }
                switch (i.type) {
                    case 'acceptedPaid':
                        for (let j of this.state.liabilities) {
                            if (i.assetTwo === j.type) {
                                tempObject.nameOne = j.type
                                tempObject.amountOne = parseFloat(j.amount)

                                totalPartnersCapitalLoss += parseFloat(j.amount)
                                totalTable2 += parseFloat(j.amount)

                                for (let k in this.state.partners) {
                                    if (this.state.partners[k] === i.name) {
                                        byRealizationAccount[k] += parseFloat(j.amount)
                                    }
                                }
                            }
                        }

                        for (let j of this.state.assets) {
                            if (i.assetOne === j.type) {
                                tempObject.nameTwo = j.type
                                tempObject.amountTwo = parseFloat(j.amount) - parseFloat(j.amount) * parseFloat(i.discount) / 100

                                totalPartnersCapitalLoss -= parseFloat(j.amount) - parseFloat(j.amount) * parseFloat(i.discount) / 100

                                for (let k in this.state.partners) {
                                    if (this.state.partners[k] === i.name) {
                                        toRealizationAccount[k] += parseFloat(j.amount) - parseFloat(j.amount) * parseFloat(i.discount) / 100
                                    }
                                }
                            }
                        }

                        break;
                    case 'takenDepreciated':
                        for (let j of this.state.assets) {
                            if (i.assetTwo === j.type) {
                                tempObject.nameOne = i.assetOne
                                tempObject.amountOne = parseFloat(i.amount)
                                tempObject.flag = true

                                tempObject.nameTwo = j.type
                                tempObject.amountTwo = parseFloat(j.amount) - parseFloat(j.amount) * parseFloat(i.percent) / 100

                                totalPartnersCapitalLoss -= parseFloat(i.amount) + parseFloat(j.amount) - parseFloat(j.amount) * parseFloat(i.percent) / 100

                                for (let k in this.state.partners) {
                                    if (this.state.partners[k] === i.name) {
                                        toRealizationAccount[k] += parseFloat(i.amount) + parseFloat(j.amount) - parseFloat(j.amount) * parseFloat(i.percent) / 100
                                    }
                                }
                            }
                        }
                        break;
                }
                answerPaletteTable2.push(tempObject)
            }

            let capitalAccountLoss = []

            for (let i in this.state.initialProfitLossRatio) {
                capitalAccountLoss.push(Math.round((totalPartnersCapitalLoss * parseFloat(this.state.initialProfitLossRatio[i]) / this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0)) * 100) / 100)
                partnersProfitLossAccount.push(Math.round((profitLossAccount * parseFloat(this.state.initialProfitLossRatio[i]) / this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0)) * 100) / 100)
            }

            //#endregion TABLE TWO END

            //#region TABLE THREE CASH VALUE START

            let leftSideTotals = [0, 0, 0]
            let rightSideTotals = [0, 0, 0]

            for (let i in leftSideTotals) {
                leftSideTotals[i] = partnersProfitLossAccount[i] + toRealizationAccount[i] + capitalAccountLoss[i]
                rightSideTotals[i] = parseFloat(this.state.capitalAccount[i]) + byRealizationAccount[i]
            }

            let toCashByCash = {
                left: [0, 0, 0],
                right: [0, 0, 0]
            }

            let highestTotal = [0, 0, 0]

            for (let i in leftSideTotals) {
                if (leftSideTotals[i] > rightSideTotals[i]) {
                    toCashByCash.right[i] = leftSideTotals[i] - rightSideTotals[i]
                    highestTotal[i] = leftSideTotals[i]
                } else {
                    toCashByCash.left[i] = rightSideTotals[i] - leftSideTotals[i]
                    highestTotal[i] = rightSideTotals[i]
                }
            }

            //#endregion TABLE THREE CASH VALUE END

            //#region FINAL TABLE START

            let leftSideTableFinal = []
            let rightSideTableFinal = []

            for (let i in toCashByCash.left) {
                if (toCashByCash.left[i] !== 0) {
                    rightSideTableFinal.push({
                        name: this.state.partners[i],
                        amount: toCashByCash.left[i]
                    })
                }
                if (toCashByCash.right[i] !== 0) {
                    leftSideTableFinal.push({
                        name: this.state.partners[i],
                        amount: toCashByCash.right[i]
                    })
                }
            }

            //#endregion FINAL TABLE END


            let dataObject = {
                partners: this.state.partners,
                capitalAccount: this.state.capitalAccount,
                liabilities: this.state.liabilities,
                assets: this.state.assets,
                totalAmountLeftSide,
                profitLossAccount,
                partnersProfitLossAccount,
                answerPaletteTable2,
                totalPartnersCapitalLoss,
                capitalAccountLoss,
                totalTable2,
                toRealizationAccount,
                byRealizationAccount,
                toCashByCash,
                highestTotal,
                leftSideTableFinal,
                rightSideTableFinal
            }

            // data.push(JSON.parse(JSON.stringify(dataObject)))

            // chartState.selected += 1

            this.setState({})

            console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addLiability() {
            this.state.liabilities.push({
                type: "Enter Text Here",
                amount: 10000
            })
            this.state.editModeliabilities.push({
                type: false,
                amount: false
            })
            this.setState({
                editModeButton: false
            }, () => { this.fillDetails() })
        }

        removeLiability(event) {
            for (let i in this.state.questionPallete) {
                if (this.state.liabilities[event].type === this.state.questionPallete[i].assetOne ||
                    this.state.liabilities[event].type === this.state.questionPallete[i].assetTwo) {
                    this.state.questionPallete.splice(i, 1)
                }
            }

            this.state.liabilities.splice(event, 1)
            this.state.editModeliabilities.splice(event, 1)
            this.setState({}, () => { this.fillDetails() })
        }

        addAsset() {
            this.state.assets.push({
                type: "Enter Text Here",
                amount: 10000
            })
            this.state.editModeassets.push({
                type: false,
                amount: false
            })
            this.setState({
                editModeButton: false
            }, () => { this.fillDetails() })
        }

        removeAsset(event) {
            for (let i in this.state.questionPallete) {
                if (this.state.assets[event].type === this.state.questionPallete[i].assetOne ||
                    this.state.assets[event].type === this.state.questionPallete[i].assetTwo) {
                    this.state.questionPallete.splice(i, 1)
                }
            }

            this.state.assets.splice(event, 1)
            this.state.editModeassets.splice(event, 1)
            this.setState({}, () => { this.fillDetails() })
        }

        addQuestion() {
            let questionObject
            let editModequestionObject

            switch (this.state.addQuestionType) {
                case 'acceptedPaid':

                    for (let i of this.state.liabilities) {
                        if (i.type === this.state.tempAssetOne) {
                            i.added = true
                        }
                    }

                    for (let i of this.state.assets) {
                        if (i.type === this.state.tempAssetTwo) {
                            i.added = true
                        }
                    }

                    questionObject = {
                        type: "acceptedPaid",
                        name: this.state.tempName,
                        assetOne: this.state.tempAssetOne,
                        discount: this.state.tempDiscount,
                        assetTwo: this.state.tempAssetTwo,
                    }

                    editModequestionObject = {
                        discount: false
                    }

                    break;
                case 'takenDepreciated':
                    for (let i of this.state.assets) {
                        if (i.type === this.state.tempAssetOne || i.type === this.state.tempAssetTwo) {
                            i.added = true
                        }
                    }

                    questionObject = {
                        type: "takenDepreciated",
                        name: this.state.tempName,
                        assetOne: this.state.tempAssetOne,
                        amount: this.state.tempAmount,
                        assetTwo: this.state.tempAssetTwo,
                        percent: this.state.tempPercent
                    }

                    editModequestionObject = {
                        amount: false,
                        percent: false
                    }
                    break;
            }

            this.state.questionPallete.push(questionObject)
            this.state.editModequestionPallete.push(editModequestionObject)

            this.setState({
                tempAssetWrittenOffName: '',
                tempAssetAppreciatedName: '',
                addQuestionChecker: false,
            }, () => {
                this.questionTypeChange({
                    target: {
                        value: this.state.addQuestionType
                    }
                })
            })

            // console.log(JSON.stringify(questionObject, null, 4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            for (let i in this.state.questionPallete) {
                if (parseInt(i) === parseInt(event)) {
                    switch (this.state.questionPallete[i].type) {
                        case 'acceptedPaid':
                            for (let j in this.state.assets) {
                                if (this.state.assets[j].type === this.state.questionPallete[i].assetOne) {
                                    this.state.assets[j].added = false
                                }
                            }
                            for (let j in this.state.liabilities) {
                                console.log(this.state.questionPallete[i].assetOne, this.state.questionPallete[i].assetTwo)
                                if (this.state.liabilities[j].type === this.state.questionPallete[i].assetTwo) {
                                    this.state.liabilities[j].added = false
                                }
                            }
                            break;
                        case 'takenDepreciated':
                            for (let j in this.state.assets) {
                                if (this.state.assets[j].type === this.state.questionPallete[i].assetOne) {
                                    this.state.assets[j].added = false
                                }
                                if (this.state.assets[j].type === this.state.questionPallete[i].assetTwo) {
                                    this.state.assets[j].added = false
                                }
                            }
                            break;
                    }
                }
            }

            console.log(this.state.assets)


            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPallete.splice(event, 1)
            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'partners':
                        this.state.editModepartners[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'initialPL':
                        this.state.editModeinitialProfitLossRatio[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'liabilitiesType':
                        this.state.editModeliabilities[customArray[1]].type = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'liabilitiesAmount':
                        this.state.editModeliabilities[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsType':
                        this.state.editModeassets[customArray[1]].type = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsAmount':
                        this.state.editModeassets[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsOriginalAmount':
                        this.state.editModeassets[customArray[1]].originalAmount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsBadDebtR':
                        this.state.editModeassets[customArray[1]].badDebtR = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'capitalAccount':
                        this.state.editModecapitalAccount[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;

                    case 'acceptedPaid':
                        this.state.editModequestionPallete[customArray[1]].discount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'takenDepreciatedAmount':
                        this.state.editModequestionPallete[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'takenDepreciatedPercent':
                        this.state.editModequestionPallete[customArray[1]].percent = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'date':
                    this.state.editModedate = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'newDate':
                    this.state.editModenewDate = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'retiredPartner':
                    this.state.editModeretiredPartner = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
            }

        }

        editModeOff() {

            for (let i in this.state.editModepartners) {
                this.state.editModepartners[i] = false
                this.state.editModecapitalAccount[i] = false
                this.state.editModeinitialProfitLossRatio[i] = false
            }

            for (let i of this.state.editModeliabilities) {
                i.type = false
                i.amount = false
            }

            for (let i of this.state.editModeassets) {
                i.type = false
                i.amount = false
            }

            for (let i of this.state.editModequestionPallete) {
                if (i.discount) {
                    i.discount = false
                }
                if (i.amount) {
                    i.amount = false
                }
                if (i.percent) {
                    i.percent = false
                }
            }

            this.setState({
                editModedate: false,
                editModeButton: false
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        Partners of the firm: <br /><br />

                        {this.state.partners.map((x, i) => (
                            <div>
                                ({i + 1})&nbsp;
                                {
                                    this.state.editModepartners[i] ?
                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["partners", i]} id='outlined-base' error={x === ''}
                                            value={x} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["partners", i]} onClick={this.buttonMaker.bind(this)}> {x}</a>
                                }<br /><br />
                            </div>
                        ))}

                        Their profit-loss sharing ratio is&nbsp;
                        {this.state.initialProfitLossRatio.map((x, i) => (
                            <span>
                                {
                                    this.state.editModeinitialProfitLossRatio[i] ?
                                        <TextField style={{ 'margin-top': "-15px", width: "5%" }} name={["initialPL", i]} id='outlined-base' error={x === '' || isNaN(x)}
                                            value={x} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["initialPL", i]} onClick={this.buttonMaker.bind(this)}> {x}</a>
                                }

                                {parseInt(i) !== this.state.initialProfitLossRatio.length - 1 && " : "}</span>
                        ))}.&nbsp;<br /><br />

                        Balance sheet of the firm as on&nbsp;

                        {this.state.editModedate ?
                            <TextField type="date" style={{ 'margin-top': "-15px", width: "15%" }} name="date" id='outlined-base' error={this.state.date === ''}
                                value={this.state.date} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="date" onClick={this.buttonMaker.bind(this)}> {this.state.date}</a>
                        }.<br /><br />

                        On this date their information is as follow. Firm was loss making, thus it was dissolved.<br /><br />

                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="center"><b>Balance Sheet</b></div>


                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Liabilities (₹)</StyledTableCell>
                                        <StyledTableCell align="center">Amt. (₹)</StyledTableCell>
                                        <StyledTableCell align="center">Assets</StyledTableCell>
                                        <StyledTableCell align="center">Amt. (₹)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell align="left">
                                            <b>Capital account :</b><br />
                                            {this.state.partners.map((x, i) => (
                                                <span>&emsp;&emsp;{x}
                                                    <span style={{ float: "right" }}>
                                                        {
                                                            this.state.editModecapitalAccount[i] ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["capitalAccount", i]} id='outlined-base' error={this.state.capitalAccount[i] === '' || isNaN(this.state.capitalAccount[i])}
                                                                    value={this.state.capitalAccount[i]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["capitalAccount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.capitalAccount[i]}</a>
                                                        }
                                                    </span><br /></span>
                                            ))}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>{this.state.capitalAccount.reduce((a, b) => { return parseFloat(a) + parseFloat(b) })}</StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                    </TableRow>
                                    {this.state.liabilities.length >= this.state.assets.length ?
                                        this.state.liabilities.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="left">
                                                    {
                                                        this.state.editModeliabilities[i].type ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["liabilitiesType", i]} id='outlined-base' error={this.state.liabilities[i].type === ''}
                                                                value={this.state.liabilities[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["liabilitiesType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].type}</a>
                                                    }&nbsp;
                                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeLiability.bind(this, i)} name="-">-</IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {
                                                        this.state.editModeliabilities[i].amount ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["liabilitiesAmount", i]} id='outlined-base' error={this.state.liabilities[i].amount === '' || isNaN(this.state.liabilities[i].amount)}
                                                                value={this.state.liabilities[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["liabilitiesAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].amount}</a>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {this.state.assets[i] !== undefined && <span>
                                                        {
                                                            this.state.editModeassets[i].type ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["assetsType", i]} id='outlined-base' error={this.state.assets[i].type === ''}
                                                                    value={this.state.assets[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["assetsType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].type}</a>
                                                        }&nbsp;
                                                        <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeAsset.bind(this, i)} name="-">-</IconButton>
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {this.state.assets[i] !== undefined && <span>
                                                        {
                                                            this.state.assets[i].badDebtR === undefined ?
                                                                <span>
                                                                    {
                                                                        this.state.editModeassets[i].amount ?
                                                                            <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsAmount", i]} id='outlined-base' error={this.state.assets[i].amount === '' || isNaN(this.state.assets[i].amount)}
                                                                                value={this.state.assets[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                            <a className='txt' name={["assetsAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].amount}</a>
                                                                    }
                                                                </span> :
                                                                <span>
                                                                    <br /><br />
                                                                    {this.state.assets[i].amount}
                                                                </span>
                                                        }
                                                    </span>}
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                        :
                                        this.state.assets.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="left">
                                                    {this.state.liabilities[i] !== undefined && <span>
                                                        {
                                                            this.state.editModeliabilities[i].type ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["liabilitiesType", i]} id='outlined-base' error={this.state.liabilities[i].type === ''}
                                                                    value={this.state.liabilities[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["liabilitiesType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].type}</a>
                                                        }&nbsp;
                                                        <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeLiability.bind(this, i)} name="-">-</IconButton>
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {this.state.liabilities[i] !== undefined && <span>
                                                        {
                                                            this.state.editModeliabilities[i].amount ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["liabilitiesAmount", i]} id='outlined-base' error={this.state.liabilities[i].amount === '' || isNaN(this.state.liabilities[i].amount)}
                                                                    value={this.state.liabilities[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["liabilitiesAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].amount}</a>
                                                        }
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {
                                                        this.state.editModeassets[i].type ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["assetsType", i]} id='outlined-base' error={this.state.assets[i].type === ''}
                                                                value={this.state.assets[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["assetsType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].type}</a>
                                                    }&nbsp;
                                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeAsset.bind(this, i)} name="-">-</IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {
                                                        this.state.assets[i].badDebtR === undefined ?
                                                            <span>
                                                                {
                                                                    this.state.editModeassets[i].amount ?
                                                                        <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsAmount", i]} id='outlined-base' error={this.state.assets[i].amount === '' || isNaN(this.state.assets[i].amount)}
                                                                            value={this.state.assets[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                        <a className='txt' name={["assetsAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].amount}</a>
                                                                }
                                                            </span> :
                                                            <span>
                                                                <br /><br />
                                                                {this.state.assets[i].amount}
                                                            </span>
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                    }
                                    <TableRow>
                                        <StyledTableCell align='center'><IconButton style={{ width: "3%", height: "10px" }} onClick={this.addLiability.bind(this)}>+</IconButton> ADD LIABILITY</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align='center'>
                                            <IconButton style={{ width: "3%", height: "10px" }} onClick={this.addAsset.bind(this)}>+</IconButton> ADD ASSET<br />
                                        </StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />

                        At the time of dissolution :
                        <br /><br />
                        <div label="Questions">
                            {this.state.questionPallete.map((x, i) => (
                                <span>({i + 1})&emsp;
                                    {x.type === 'acceptedPaid' &&
                                        <span>
                                            {x.name} has accepted {x.assetOne} at&nbsp;
                                            {this.state.editModequestionPallete[i].discount ?
                                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["acceptedPaid", i]} id='outlined-base' error={x.discount === '' || isNaN(x.discount) || x.discount < 0 || x.discount > 100}
                                                    value={x.discount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["acceptedPaid", i]} onClick={this.buttonMaker.bind(this)}> {x.discount}</a>
                                            } % discount and to pay {x.assetTwo}.
                                        </span>
                                    }
                                    {x.type === 'takenDepreciated' &&
                                        <span>
                                            {x.name} has taken bills receivable for ₹&nbsp;
                                            {this.state.editModequestionPallete[i].amount ?
                                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["takenDepreciatedAmount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                    value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["takenDepreciatedAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                            }&nbsp;

                                            and {x.assetTwo} at&nbsp;
                                            {this.state.editModequestionPallete[i].percent ?
                                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["takenDepreciatedPercent", i]} id='outlined-base' error={x.percent === '' || isNaN(x.percent) || x.percent < 0 || x.percent > 100}
                                                    value={x.percent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["takenDepreciatedPercent", i]} onClick={this.buttonMaker.bind(this)}> {x.percent}</a>
                                            } % depreciated value.
                                        </span>
                                    }
                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeQuestion.bind(this, i)} name="-">-</IconButton>

                                    <br /><br />
                                </span>
                            ))}
                        </div>
                        <br /><br />

                        <div label="addQuestionInputs">
                            <b>ADD NEW QUESTION :</b><br /><br /><br />
                            <b>QUESTION TYPE: </b>
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">Question</InputLabel>
                                <Select
                                    labelId="question"
                                    id="question"
                                    value={this.state.addQuestionType}
                                    label="Question"
                                    onChange={this.questionTypeChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"none"}>None</MenuItem>
                                    <MenuItem value={"acceptedPaid"}>Accepted with Discount and Paid</MenuItem>
                                    <MenuItem value={"takenDepreciated"}>Taken Asset at </MenuItem>


                                </Select>
                            </FormControl><br /><br /><br />

                            {this.state.addQuestionType === 'acceptedPaid' &&
                                <span>
                                    Enter Name: &nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Name*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.partners.map((y, j) => (
                                                <MenuItem value={y}>{y}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    Enter Liability to be accepted: &nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Asset One</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempAssetOne === ''}
                                            value={this.state.tempAssetOne}
                                            label="Name"
                                            onChange={this.addAssetOneChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.liabilities.map((y, j) => (
                                                y.added !== true && <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    Enter Discount for it: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="discount" error={this.state.tempDiscount === '' || isNaN(this.state.tempDiscount) || this.state.tempDiscount < 0 || this.state.tempDiscount > 100}
                                        placeholder="Enter Discount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Asset to pay for: &nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Asset Two</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempAssetTwo === ''}
                                            value={this.state.tempAssetTwo}
                                            label="Name"
                                            onChange={this.addAssetTwoChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.assets.map((y, j) => (
                                                y.added !== true && <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'takenDepreciated' &&
                                <span>
                                    Enter Name: &nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Name*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.partners.map((y, j) => (
                                                <MenuItem value={y}>{y}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    Enter Asset to be Received: &nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Asset One</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempAssetOne === ''}
                                            value={this.state.tempAssetOne}
                                            label="Name"
                                            onChange={this.addAssetOneChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.assets.map((y, j) => (
                                                y.added !== true && <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    Enter Amount Receivable for it: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="Enter Discount*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    Enter Asset to be depreciated: &nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Asset Two</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempAssetTwo === ''}
                                            value={this.state.tempAssetTwo}
                                            label="Name"
                                            onChange={this.addAssetTwoChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.assets.map((y, j) => (
                                                y.added !== true && <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    Enter Depreciation Percent for it: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="percent" error={this.state.tempPercent === '' || isNaN(this.state.tempPercent) || this.state.tempPercent < 0 || this.state.tempPercent > 100}
                                        placeholder="Enter Percent*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                </span>
                            }

                            {this.state.addQuestionType !== 'none' && <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%" }} onClick={this.addQuestion.bind(this)}>ADD NEW QUESTION</AddButton>}
                        </div>

                        <br /><br /><br />

                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}

                        <br /><br />



                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>Ans. :</b><br />
                            <div align='center'><b>Balance Sheet of the Firm Before Dissolution</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Liabilities</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Assets</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell>
                                                <b>Capital :</b><br />
                                                {data[chartState.selected].partners.map((x, i) => (
                                                    <span>&emsp;&emsp;{x}<br /></span>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].capitalAccount.map((x, i) => (
                                                    <span>{x}<br /></span>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].assets.length >= data[chartState.selected].liabilities.length ?
                                            data[chartState.selected].assets.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell>
                                                        {data[chartState.selected].liabilities[i] &&
                                                            <span>
                                                                {data[chartState.selected].liabilities[i].type}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        {data[chartState.selected].liabilities[i] &&
                                                            <span>
                                                                {data[chartState.selected].liabilities[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <span>
                                                            {data[chartState.selected].assets[i].type}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        <span>
                                                            {data[chartState.selected].assets[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                </TableRow>
                                            )) :
                                            data[chartState.selected].liabilities.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell>
                                                        <span>
                                                            {data[chartState.selected].liabilities[i].type}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        <span>
                                                            {data[chartState.selected].liabilities[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {data[chartState.selected].assets[i] &&
                                                            <span>
                                                                {data[chartState.selected].assets[i].type}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        {data[chartState.selected].assets[i] &&
                                                            <span>
                                                                {data[chartState.selected].assets[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))
                                        }
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell>Profit and Loss Account (?)</StyledTableCell>
                                            <StyledTableCell align='right'>{data[chartState.selected].profitLossAccount}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].totalAmountLeftSide}</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].totalAmountLeftSide}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <b>Note :</b> Total of liabilities side is ₹ {data[chartState.selected].totalAmountLeftSide}. The difference is of ₹ {data[chartState.selected].profitLossAccount} appeared at assets side.&nbsp;
                            This is debit balance of profit and loss account. The firm is loss making.

                            <div align='center'><b>Realisation Account</b></div>
                            <span style={{ float: "left" }}>Dr</span><span style={{ float: "right" }}>Cr</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align="left"><b>To Assets A/c :</b></StyledTableCell>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="left"><b>To Liabilities A/c</b></StyledTableCell>
                                            <StyledTableCell align="center"></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].assets.length >= data[chartState.selected].liabilities.length ?
                                            data[chartState.selected].assets.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell>
                                                        <span>
                                                            {data[chartState.selected].assets[i].type}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        <span>
                                                            {data[chartState.selected].assets[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {data[chartState.selected].liabilities[i] &&
                                                            <span>
                                                                {data[chartState.selected].liabilities[i].type}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        {data[chartState.selected].liabilities[i] &&
                                                            <span>
                                                                {data[chartState.selected].liabilities[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                </TableRow>
                                            )) :
                                            data[chartState.selected].liabilities.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell>
                                                        {data[chartState.selected].assets[i] &&
                                                            <span>
                                                                {data[chartState.selected].assets[i].type}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        {data[chartState.selected].assets[i] &&
                                                            <span>
                                                                {data[chartState.selected].assets[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <span>
                                                            {data[chartState.selected].liabilities[i].type}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        <span>
                                                            {data[chartState.selected].liabilities[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))
                                        }
                                        {data[chartState.selected].answerPaletteTable2.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell>
                                                    {x.flag !== true &&
                                                        <span>
                                                            <b>To {x.name}'s Capital A/c :</b><br />
                                                            {x.nameOne}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right'>
                                                    {x.flag !== true &&
                                                        <span>
                                                            <br />
                                                            {x.amountOne}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <b>By {x.name}'s Capital A/c :</b><br />
                                                    {x.flag !== true ?
                                                        <span>
                                                            {x.nameTwo}
                                                        </span> :
                                                        <span>
                                                            {x.nameOne}<span style={{ float: "right" }}>{x.amountOne}</span><br />
                                                            {x.nameTwo}<span style={{ float: "right" }}>{x.amountTwo}</span>
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right'>
                                                    <br />
                                                    {x.flag !== true ?
                                                        <span>
                                                            {x.amountTwo}
                                                        </span> :
                                                        <span>
                                                            <br />
                                                            {x.amountOne + x.amountTwo}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell>
                                                <b>By Partners' Capiutal A/c (Loss) :</b><br />
                                                {data[chartState.selected].capitalAccountLoss.map((x, i) => (
                                                    <span>&emsp;&emsp;{data[chartState.selected].partners[i]} <span style={{ float: "right" }}>{x}</span><br /></span>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "bottom" }}>
                                                {data[chartState.selected].totalPartnersCapitalLoss}
                                            </StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].totalTable2}</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].totalTable2}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align='center'><b>Partners' Capital Accounts</b></div>
                            <span style={{ float: "left" }}>Dr</span><span style={{ float: "right" }}>Cr</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            {data[chartState.selected].partners.map((x, i) => (
                                                <StyledTableCell>{x} (₹)</StyledTableCell>
                                            ))}
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            {data[chartState.selected].partners.map((x, i) => (
                                                <StyledTableCell>{x} (₹)</StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell>To Profit & Loss A/c</StyledTableCell>
                                            {data[chartState.selected].partnersProfitLossAccount.map((x, i) => (
                                                <StyledTableCell align='right'>{x}</StyledTableCell>
                                            ))}
                                            <StyledTableCell>By Balance b/d</StyledTableCell>
                                            {data[chartState.selected].capitalAccount.map((x, i) => (
                                                <StyledTableCell align='right'>{x}</StyledTableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell>To Realisation A/c</StyledTableCell>
                                            {data[chartState.selected].toRealizationAccount.map((x, i) => (
                                                x !== 0 ? <StyledTableCell align='right'>{x}</StyledTableCell> :
                                                    <StyledTableCell align='right'>-</StyledTableCell>
                                            ))}
                                            <StyledTableCell>By Realisation A/c</StyledTableCell>
                                            {data[chartState.selected].byRealizationAccount.map((x, i) => (
                                                x !== 0 ? <StyledTableCell align='right'>{x}</StyledTableCell> :
                                                    <StyledTableCell align='right'>-</StyledTableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell>To Realisation A/c (Loss)</StyledTableCell>
                                            {data[chartState.selected].capitalAccountLoss.map((x, i) => (
                                                x !== 0 ? <StyledTableCell align='right'>{x}</StyledTableCell> :
                                                    <StyledTableCell align='right'>-</StyledTableCell>
                                            ))}
                                            <StyledTableCell></StyledTableCell>
                                            {data[chartState.selected].byRealizationAccount.map((x, i) => (
                                                x !== 0 ? <StyledTableCell align='right'></StyledTableCell> :
                                                    <StyledTableCell align='right'></StyledTableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell>To Cash A/c (Payment)</StyledTableCell>
                                            {data[chartState.selected].toCashByCash.left.map((x, i) => (
                                                x !== 0 ? <StyledTableCell align='right'>{x}</StyledTableCell> :
                                                    <StyledTableCell align='right'>-</StyledTableCell>
                                            ))}
                                            <StyledTableCell>By Cash A/c (Deficit)</StyledTableCell>
                                            {data[chartState.selected].toCashByCash.right.map((x, i) => (
                                                x !== 0 ? <StyledTableCell align='right'>{x}</StyledTableCell> :
                                                    <StyledTableCell align='right'>-</StyledTableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            {data[chartState.selected].highestTotal.map((x, i) => (
                                                <StyledTableCell align='right'><b>{x}</b></StyledTableCell>
                                            ))}
                                            <StyledTableCell></StyledTableCell>
                                            {data[chartState.selected].highestTotal.map((x, i) => (
                                                <StyledTableCell align='right'><b>{x}</b></StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align='center'><b>Cash Account</b></div>
                            <span style={{ float: "left" }}>Dr</span><span style={{ float: "right" }}>Cr</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].leftSideTableFinal.length >= data[chartState.selected].rightSideTableFinal.length ?
                                            data[chartState.selected].leftSideTableFinal.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell>
                                                        <span>
                                                            To {data[chartState.selected].leftSideTableFinal[i].name}'s capital A/c
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        <span>
                                                            {data[chartState.selected].leftSideTableFinal[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {data[chartState.selected].rightSideTableFinal[i] &&
                                                            <span>
                                                                By {data[chartState.selected].rightSideTableFinal[i].name}'s capital A/c
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        {data[chartState.selected].rightSideTableFinal[i] &&
                                                            <span>
                                                                {data[chartState.selected].rightSideTableFinal[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                </TableRow>
                                            )) :
                                            data[chartState.selected].rightSideTableFinal.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell>
                                                        {data[chartState.selected].leftSideTableFinal[i] &&
                                                            <span>
                                                                To {data[chartState.selected].leftSideTableFinal[i].name}'s capital A/c
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        {data[chartState.selected].leftSideTableFinal[i] &&
                                                            <span>
                                                                {data[chartState.selected].leftSideTableFinal[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <span>
                                                            By {data[chartState.selected].rightSideTableFinal[i].name}'s capital A/c
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        <span>
                                                            {data[chartState.selected].rightSideTableFinal[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))
                                        }
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].leftSideTableFinal.reduce((a, b) => (a + b.amount), 0)}</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].rightSideTableFinal.reduce((a, b) => (a + b.amount), 0)}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                        </div>
                    }
                    <br />
                    <br />
                </div >
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, ColorButton, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import TextField from '@mui/material/TextField';


export default function unknownQuestion() {

    const data = [{
        totalPurchase: 450000,
        cashPurchase: 50000,
        purchaseReturn: 400000,
        openingTradePayables: 15000,
        closingTradePayables: 25000,
        creditPurchaseA: 3300000,
        creditPurchaseB: 3000000,
        averageTradePayables: 500000,
        creditorsTurnover: 6,
        paymentPeriodDays: 61,
    }]

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }
    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                companyName: "'Nirali' Company Ltd.",
                totalPurchase: 450000,
                cashPurchase: 50000,
                purchaseReturn: 400000,
                openingTradePayables: 15000,
                closingTradePayables: 25000,
                creditPurchaseA: 3300000,
                creditPurchaseB: 3000000,
                averageTradePayables: 500000,
                creditorsTurnover: 6,
                paymentPeriodDays: 61,

                editModeTP: false,
                editModeCP: false,
                editModePR: false,
                editModeOTP: false,
                editModeCTP: false,
                editModeName: false,
                editModeButton: false,

                solutionShow: false,
                tableModeChartModeSwitch: true,
                submitChecker: true

            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //EVENT FUNCTIONALITIES START

        eventHandler(event) {
            switch (event.target.name) {
                case 'companyName':
                    this.setState({
                        companyName: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'totalPurchase':
                    this.setState({
                        totalPurchase: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'cashPurchase':
                    this.setState({
                        cashPurchase: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'purchaseReturn':
                    this.setState({
                        purchaseReturn: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'openingTradePayables':
                    this.setState({
                        openingTradePayables: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'closingTradePayables':
                    this.setState({
                        closingTradePayables: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                default:
                    this.setState({})
                    break;
            }
        }

        fillDetails() {
            let count = 0
            if (NumberRegex.test(this.state.totalPurchase) && this.state.totalPurchase != '') {
                count += 1
            }
            if (NumberRegex.test(this.state.cashPurchase) && this.state.cashPurchase != '') {
                count += 1
            }
            if (NumberRegex.test(this.state.purchaseReturn) && this.state.purchaseReturn != '') {
                count += 1
            }
            if (NumberRegex.test(this.state.openingTradePayables) && this.state.totalPuropeningTradePayableschase != '') {
                count += 1
            }
            if (NumberRegex.test(this.state.closingTradePayables) && this.state.closingTradePayables != '') {
                count += 1
            }
            if (count === 5) {
                this.setState({
                    submitChecker: true
                })
            } else {
                this.setState({
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //EVENT FUNCTIONALTIES END

        // BUTTON PANEL START

        onSubmit() {
            let creditPurchaseA = parseFloat(this.state.totalPurchase) - parseFloat(this.state.purchaseReturn)
            let creditPurchaseB = creditPurchaseA - parseFloat(this.state.cashPurchase)
            let averageTradePayables = parseFloat((parseFloat(this.state.openingTradePayables) + parseFloat(this.state.closingTradePayables)) / 2)
            let creditorsTurnover = Math.round(parseFloat(creditPurchaseB / averageTradePayables)*100)/100
            let paymentPeriodDays = Math.round(parseFloat(365 / creditorsTurnover)*100)/100

            data.push({
                totalPurchase: this.state.totalPurchase,
                cashPurchase: this.state.cashPurchase,
                purchaseReturn: this.state.purchaseReturn,
                openingTradePayables: this.state.openingTradePayables,
                closingTradePayables: this.state.closingTradePayables,
                creditPurchaseA: creditPurchaseA,
                creditPurchaseB: creditPurchaseB,
                averageTradePayables: averageTradePayables,
                creditorsTurnover: creditorsTurnover,
                paymentPeriodDays: paymentPeriodDays,
            })

            chartState.selected = data.length - 1

            this.setState({})
        }

        onReset() {
            window.location.reload()
        }

        switchTableToChartAndBack() {
            this.setState({
                tableModeChartModeSwitch: !this.state.tableModeChartModeSwitch
            })
        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event){
            if(event.target.name === 'Left'){
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if(event.target.name === 'Right'){
                chartState.selected += 1
                this.setState({})
            }
        }

        //BUTTON PANEL END


        // EDIT BUTTON START

        buttonMaker(event) {
            switch (event.target.name) {
                case 'companyName':
                    this.setState({
                        editModeName: true,
                        editModeButton: true
                    })
                    break;
                case 'totalPurchase':
                    this.setState({
                        editModeTP: true,
                        editModeButton: true
                    })
                    break;
                case 'cashPurchase':
                    this.setState({
                        editModeCP: true,
                        editModeButton: true
                    })
                    break;
                case 'purchaseReturn':
                    this.setState({
                        editModePR: true,
                        editModeButton: true
                    })
                    break;
                case 'openingTradePayables':
                    this.setState({
                        editModeOTP: true,
                        editModeButton: true
                    })
                    break;
                case 'closingTradePayables':
                    this.setState({
                        editModeCTP: true,
                        editModeButton: true
                    })
                    break;
            }
        }

        editModeOff() {
            this.setState({
                editModeTP: false,
                editModeCP: false,
                editModePR: false,
                editModeOTP: false,
                editModeCTP: false,
                editModeName: false,
                editModeButton: false
            })
        }

        // EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        <b>સવાલ:</b><br /><br /><span>
                            {
                                this.state.editModeName ?
                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name='companyName' id='outlined-base' error={this.state.companyName === ''}
                                        value={this.state.companyName} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name="companyName" onClick={this.buttonMaker.bind(this)}> {this.state.companyName}</a>
                            }
                        </span> , નીચે આપેલ વિગતોના આધારે રોકાણની પ્રવૃત્તિનો રોકડ પ્રવાહ શોધો.<br /> <br />
                        <div label="TableContents">
                            <div>
                                <TableContainer>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ(₹)</StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ(₹)</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow key='1'>
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>મશીનોની શરૂઆતની બાકી</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.editModeTP ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='totalPurchase' id='outlined-base' error={this.state.totalPurchase === '' || isNaN(this.state.totalPurchase)}
                                                        value={this.state.totalPurchase} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="totalPurchase" onClick={this.buttonMaker.bind(this)}> {this.state.totalPurchase}</a>}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>મશીનોની પર ગણેલ ધસારો</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.editModeCP ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='cashPurchase' id='outlined-base' error={this.state.cashPurchase === '' || isNaN(this.state.cashPurchase)}
                                                        value={this.state.cashPurchase} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="cashPurchase" onClick={this.buttonMaker.bind(this)}> {this.state.cashPurchase}</a>}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key='2'>
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>મશીનોની આખર બાકી</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.editModePR ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='purchaseReturn' id='outlined-base' error={this.state.purchaseReturn === '' || isNaN(this.state.purchaseReturn)}
                                                        value={this.state.purchaseReturn} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="purchaseReturn" onClick={this.buttonMaker.bind(this)}> {this.state.purchaseReturn}</a>}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>મશીનની વેચાણ કિંમત</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.editModeOTP ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='openingTradePayables' id='outlined-base' error={this.state.openingTradePayables === '' || isNaN(this.state.openingTradePayables)}
                                                        value={this.state.openingTradePayables} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="openingTradePayables" onClick={this.buttonMaker.bind(this)}> {this.state.openingTradePayables}</a>}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key='3'>
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>વર્ષ દરમિયાન વેચેલ મશીનની ચોપડેકિંમત</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.editModeCTP ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='closingTradePayables' id='outlined-base' error={this.state.closingTradePayables === '' || isNaN(this.state.closingTradePayables)}
                                                        value={this.state.closingTradePayables} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="closingTradePayables" onClick={this.buttonMaker.bind(this)}> {this.state.closingTradePayables}</a>}
                                                </StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <br /><br />
                            {this.state.editModeButton ? <ColorButton style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)} disabled={this.state.totalPurchase === '' || this.state.cashPurchase === '' || this.state.purchaseReturn === '' || this.state.openingTradePayables === '' || this.state.closingTradePayables === ''}>Edit Mode Off</ColorButton> : <span></span>}
                        </div>


                    </div>
                    <div align="left" label="buttons">
                        <br />
                        <br />
                        <AddButton style={{ width: "15%", fontSize: "20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                        <AddButton style={{ width: "15%", fontSize: "20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        <AddButton style={{ width: "12%", fontSize: "20px" }} onClick={this.switchTableToChartAndBack.bind(this)}>કોષ્ટક / ચાર્ટ</AddButton>
                    </div>
                    <div align='right'>
                        <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {chartState.selected + 1}
                        &nbsp;&nbsp;&nbsp;
                        <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                    </div>
                    <br />
                    <br />
                    {this.state.solutionShow ? <div label="solution">
                        {this.state.tableModeChartModeSwitch ?
                            <div label="chart" align="left">
                                જવાબ. : રોકાણની પ્રવૃત્તિ જાણવા માટે, મશીન એકાઉન્ટ નીચે પ્રમાણે તૈયાર કરવામાં આવશે :
                                <div label="chart1">
                                    <b>(1) </b>  &emsp;&emsp;&emsp;&emsp;<b>મશીન મિલક્ત હોવાથી તેની ઉધાર બાકી હોય. તેથી શરૂઆતની બાકી ઉધાર બાજુ દર્શાવેલ છે.</b><br /><br />

                                    <b>(2) </b>  &emsp;&emsp;&emsp;&emsp;<b>મશીનનો ઘસારો : તેની આમનોંધ નીચે પ્રમાણે છે :</b>
                                   <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;ઘસારા ખાતે ...Dr &emsp;{data[chartState.selected].cashPurchase}</p>                                    
                                    <p> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;તે મશીન ખાતે &emsp;&emsp;&emsp;&emsp;&emsp;{data[chartState.selected].cashPurchase}</p>                                   
                                    <br /><br />
                                    
                                    <b>(3) </b>
                                    {/* &emsp;&emsp; <br /> */}
                                    &emsp;&emsp;&emsp;&emsp;<b>મશીનનુ વેચાણ :</b>&emsp;&emsp;(₹)<br /><br />
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;ચોપડેકિંમત &emsp;&emsp;&nbsp;&nbsp; {data[chartState.selected].closingTradePayables}<br />
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;- વેચાણકિંમત &emsp;&emsp;{data[chartState.selected].openingTradePayables} <br />
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;──────────────   <br />
                                    
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; ખોટ &emsp;&emsp;&emsp;&emsp;&emsp;{data[chartState.selected].closingTradePayables - data[chartState.selected].openingTradePayables}  <br />
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;────────────── <br />
                                    
                                    &emsp;&emsp;&emsp;&emsp;<br />
                                    <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;રોડ ખાતે ...ઉ &emsp;&emsp;&emsp;&emsp;&emsp;        &emsp;&emsp;&emsp;&emsp;&emsp;{data[chartState.selected].openingTradePayables}</p>                                    
                                    <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;વેચાણ નુકશાન ખાતે ...ઉ &emsp;&emsp;{data[chartState.selected].closingTradePayables - data[chartState.selected].openingTradePayables}</p>                                    
                                    <p> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;તે મશીન ખાતે &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{data[chartState.selected].closingTradePayables}</p>                                   
                                    <br /><br />

                                    <b>(4) </b>  &emsp;&emsp;&emsp;&emsp;<b>મશીન ખાતું બંધ કરતાં તફાવતની રક્મ ઉધાર બાજુએ આવે છે, તેથી તે ખરીદી કહેવાય.</b><br /><br />
                                    <div>
                                    <TableContainer component={Paper}>
										<Table sx={{ minWidth: 700 }} aria-label="customized table">
											<TableHead>
												<TableRow>
													<StyledTableCell align="center" style={{fontSize: "20px"}}>પ્રવૃત્તિ</StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
													<StyledTableCell align="center" style={{fontSize: "20px"}}>અસર</StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{/* {data.map((x, i) => ( */}
												<StyledTableRow key="1">
                                                    <StyledTableCell align="left" style={{fontSize: "15px"}}>મશીન વેચાણ ₹ {data[chartState.selected].openingTradePayables} ─ રોકાણની પ્રવૃત્તિ </StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
													<StyledTableCell align="left" style={{fontSize: "15px"}}>રોકાણની પ્રવૃત્તિમાં આવક રોકડ પ્રવાહ</StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
												</StyledTableRow>
												<StyledTableRow key="2">
                                                    <StyledTableCell align="left" style={{fontSize: "15px"}}>મશીન ખરીદી ₹ {data[chartState.selected].closingTradePayables} ─ રોકાણની પ્રવૃત્તિ </StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
													<StyledTableCell align="left" style={{fontSize: "15px"}}>રોકાણની પ્રવૃત્તિમાં જાવક રોકડ પ્રવાહ</StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
												</StyledTableRow>
												<StyledTableRow key="3">
                                                    <StyledTableCell align="left" style={{fontSize: "15px"}}>ઘસારો ₹ {data[chartState.selected].cashPurchase} ─ કામગીરી પ્રવૃત્તિ </StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
													<StyledTableCell align="left" style={{fontSize: "15px"}}>બિનરોકડ વ્યવહાર, કામગીરી નકામાં ઉમેરાશે</StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
												</StyledTableRow>
												<StyledTableRow key="4">
                                                    <StyledTableCell align="left" style={{fontSize: "15px"}}>મશીન વેચાણ નુકસાન ₹ {data[chartState.selected].closingTradePayables - data[chartState.selected].openingTradePayables} </StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
													<StyledTableCell align="left" style={{fontSize: "15px"}}>બિનરોકડ વ્યવહાર, કામગીરી નકામાં ઉમેરાશે</StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
												</StyledTableRow>
												{/* ))} */}
											</TableBody>
										</Table>
									</TableContainer>
                                    </div>

                                </div>
                                <div label="chart2">
                                    {/* <b>(B) Payment period in days :</b> = ( <sup>365</sup>/<sub>Creditors TurnOver</sub> ) <br />
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp; = ( <sup>365</sup>/<sub>{data[chartState.selected].creditorsTurnover}</sub> ) <br />
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp; = {data[chartState.selected].paymentPeriodDays} days */}
                                </div>
                            </div>


                            :
                            <div label="table">
                                <h2>જવાબ. : રોકાણની પ્રવૃત્તિઓ શોધવા માટે મશીન ખાતું નીચે પ્રમાણે બનશે :</h2> <br /> <br />
                                મશીન ખાતું<br /> <br />
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ(₹)</StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ(₹)</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {data.map((x, i) => ( */}
                                            <StyledTableRow key="1">
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>બાકી સાગળ લાવ્યા</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].totalPurchase}</StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>ઘસારા ખાતે</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].cashPurchase}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="2">
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>રોકડ ખાતે (?) (ખરીદી)</StyledTableCell>
                                                <StyledTableCell align="center">({(parseFloat(data[chartState.selected].cashPurchase) + parseFloat(data[chartState.selected].openingTradePayables) + (parseFloat(data[chartState.selected].closingTradePayables) - parseFloat(data[chartState.selected].openingTradePayables)) + parseFloat(data[chartState.selected].purchaseReturn)) - parseFloat(data[chartState.selected].totalPurchase)})</StyledTableCell>
                                                {/* <StyledTableCell align="center">{data[chartState.selected].purchaseReturn}</StyledTableCell> */}
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>રોકડ ખાતે (વેચાણ)</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].openingTradePayables}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="3">
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>મશીન વેંચાણ નુકસાન ખાતે </StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].closingTradePayables - data[chartState.selected].openingTradePayables}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="4">
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "16px"}}>બાકી આગળ લઈ ગયા</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].purchaseReturn}</StyledTableCell>
                                                {/* <StyledTableCell align="center">{data[chartState.selected].creditPurchaseB}</StyledTableCell> */}
                                            </StyledTableRow>
                                            <StyledTableRow key="5">
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="6">
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center">{parseFloat(data[chartState.selected].cashPurchase) + parseFloat(data[chartState.selected].openingTradePayables) + (parseFloat(data[chartState.selected].closingTradePayables) - parseFloat(data[chartState.selected].openingTradePayables)) + parseFloat(data[chartState.selected].purchaseReturn)}</StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center">{parseFloat(data[chartState.selected].cashPurchase) + parseFloat(data[chartState.selected].openingTradePayables) + (parseFloat(data[chartState.selected].closingTradePayables) - parseFloat(data[chartState.selected].openingTradePayables)) + parseFloat(data[chartState.selected].purchaseReturn)}</StyledTableCell>
                                            </StyledTableRow>
                                            {/* ))} */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <br /> <br />
                                રોકાણ પ્રવૃત્તિમાંથી રોકડપ્રવાહ દર્શાવતું પત્રક<br /> <br />
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ(₹)</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {data.map((x, i) => ( */}
                                            <StyledTableRow key="1">
                                                <StyledTableCell align="left" style={{fontSize: "16px"}}>મશીનનું વેચાણ</StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].openingTradePayables}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="2">
                                                <StyledTableCell align="left" style={{fontSize: "16px"}}>મશીનની ખરીદી</StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="center">({data[chartState.selected].closingTradePayables})</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="3">
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key="4">
                                                <StyledTableCell align="left" style={{fontSize: "16px"}}>રોકાણ પ્રવૃત્તિમાંથી જાવક રોક્ડપ્રવાહ</StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="center">({data[chartState.selected].closingTradePayables - data[chartState.selected].openingTradePayables})</StyledTableCell>
                                            </StyledTableRow>
                                            {/* ))} */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>}
                    </div> :
                        <div></div>}
                    <br />
                    <br />
                </div>
            )
        }
    }



    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button, touchRippleClasses } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
//ILLUSTRATION 8

export default function ComparativeProfitLossStatement() {

    const data = [{
        "questionPallete": [
            {
                "questionType": "purchase",
                "context": {
                    "type": "tradeDiscount",
                    "name": "Taruna Traders",
                    "amount": 10000,
                    "discount": 10,
                    "cashDiscount": "-",
                    "billno": "-",
                    "paid": {
                        "type": false
                    },
                    "totalAmount": 9000
                },
                "date": 1
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "sales",
                    "name": "Gandhi Brothers",
                    "amount": 7000,
                    "tradeDiscount": 5,
                    "cashDiscount": "-",
                    "paid": {
                        "type": false
                    },
                    "due": {
                        "type": false
                    },
                    "totalAmount": 6650
                },
                "date": 5
            },
            {
                "questionType": "purchase",
                "context": {
                    "type": "tradeDiscount",
                    "name": "Mangaldas",
                    "amount": 12000,
                    "discount": 10,
                    "cashDiscount": 5,
                    "billno": "-",
                    "paid": {
                        "type": true,
                        "amount": 12000
                    },
                    "totalAmount": 10800
                },
                "date": 8
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "sales",
                    "name": "Viral",
                    "amount": 11000,
                    "tradeDiscount": 10,
                    "cashDiscount": 5,
                    "paid": {
                        "type": true,
                        "amount": 11000
                    },
                    "due": {
                        "type": false
                    },
                    "totalAmount": 9900
                },
                "date": 10
            },
            {
                "questionType": "purchase",
                "context": {
                    "type": "tradeDiscount",
                    "name": "Bihari Brothers",
                    "amount": 8000,
                    "discount": 10,
                    "cashDiscount": 10,
                    "billno": "-",
                    "paid": {
                        "type": true,
                        "amount": 3600
                    },
                    "totalAmount": 7200
                },
                "date": 12
            },
            {
                "questionType": "sales",
                "context": {
                    "type": "sales",
                    "name": "Bimal",
                    "amount": 15000,
                    "tradeDiscount": 10,
                    "cashDiscount": 10,
                    "paid": {
                        "type": false
                    },
                    "due": {
                        "type": true,
                        "date": 20
                    },
                    "totalAmount": 13500
                },
                "date": 15
            },
            {
                "questionType": "payment",
                "context": {
                    "type": "pending",
                    "name": "Bimal",
                    "originalAmount": 13500,
                    "amount": 6750,
                    "cashDiscount": 10,
                    "initialDate": 15,
                    "lastDate": 20,
                    "payType": "check",
                    "remaining": true
                },
                "date": 18
            },
            {
                "questionType": "payment",
                "context": {
                    "type": "pending",
                    "name": "Bimal",
                    "amount": 6750,
                    "cashDiscount": 10,
                    "initialDate": 15,
                    "lastDate": 20,
                    "payType": "cash",
                    "remaining": false
                },
                "date": 22
            },
            {
                "questionType": "payment",
                "context": {
                    "type": "duePayment",
                    "name": "Sunita",
                    "due": 1020,
                    "amount": 1000,
                    "payType": "cash"
                },
                "date": 25
            },
            {
                "questionType": "toBePaid",
                "context": {
                    "type": "toBePaid",
                    "name": "Vaishali",
                    "due": 810,
                    "amount": 800,
                    "payType": "check"
                },
                "date": 31
            }
        ],
        "bookOwnerName": "Mahendra Sanghvi",
        "month": "July",
        "year": 2014,
        "totalAmount": 72380
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const AlphabetRegex = new RegExp(/^[a-zA-Z]+$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                bookOwnerGender: "Shri",
                bookOwnerName: "Mahendra Sanghvi",
                month: "July",
                year: 2014,
                questionPallete: [
                    {//1
                        questionType: "purchase",
                        context: {
                            type: "tradeDiscount",
                            name: "Taruna Traders",
                            amount: 10000,
                            discount: 10,
                            cashDiscount: "-",
                            billno: "-",
                            paid: {
                                type: false
                            }
                        },
                        date: 1
                    },
                    {//2
                        questionType: "sales",
                        context: {
                            type: "sales",
                            name: "Gandhi Brothers",
                            amount: 7000,
                            tradeDiscount: 5,
                            cashDiscount: "-",
                            paid: {
                                type: false
                            },
                            due: {
                                type: false,
                            }
                        },
                        date: 5
                    },
                    {//3
                        questionType: "purchase",
                        context: {
                            type: "tradeDiscount",
                            name: "Mangaldas",
                            amount: 12000,
                            discount: 10,
                            cashDiscount: 5,
                            billno: "-",
                            paid: {
                                type: true,
                                amount: 12000
                            }
                        },
                        date: 8
                    },
                    {//4
                        questionType: "sales",
                        context: {
                            type: "sales",
                            name: "Viral",
                            amount: 11000,
                            tradeDiscount: 10,
                            cashDiscount: 5,
                            paid: {
                                type: true,
                                amount: 11000
                            },
                            due: {
                                type: false,
                            }
                        },
                        date: 10
                    },
                    {//5
                        questionType: "purchase",
                        context: {
                            type: "tradeDiscount",
                            name: "Bihari Brothers",
                            amount: 8000,
                            discount: 10,
                            cashDiscount: 10,
                            billno: "-",
                            paid: {
                                type: true,
                                amount: 3600
                            }
                        },
                        date: 12
                    },
                    {//6
                        questionType: "sales",
                        context: {
                            type: "sales",
                            name: "Bimal",
                            amount: 15000,
                            tradeDiscount: 10,
                            cashDiscount: 10,
                            paid: {
                                type: false,
                            },
                            due: {
                                type: true,
                                date: 20
                            }
                        },
                        date: 15
                    },
                    {//7
                        questionType: "payment",
                        context: {
                            type: "pending",
                            name: "Bimal",
                            originalAmount: 13500,
                            amount: 6750,
                            cashDiscount: 10,
                            initialDate: 15,
                            lastDate: 20,
                            payType: "check",
                            remaining: true
                        },
                        date: 18
                    },
                    {//8
                        questionType: "payment",
                        context: {
                            type: "pending",
                            name: "Bimal",
                            amount: 6750,
                            cashDiscount: 10,
                            initialDate: 15,
                            lastDate: 20,
                            payType: "cash",
                            remaining: false
                        },
                        date: 22
                    },
                    {//9
                        questionType: "payment",
                        context: {
                            type: "duePayment",
                            name: "Sunita",
                            due: 1020,
                            amount: 1000,
                            payType: "cash"
                        },
                        date: 25
                    },
                    {//10
                        questionType: "toBePaid",
                        context: {
                            type: "toBePaid",
                            name: "Vaishali",
                            due: 810,
                            amount: 800,
                            payType: "check"
                        },
                        date: 31
                    }
                    // {//2
                    //     questionType: "purchase",
                    //     context: {
                    //         type: "cashDiscount",
                    //         name: "Komal",
                    //         amount: 4000,
                    //         discount: 10,
                    //         credit: 2,
                    //         paid: {
                    //             type: false
                    //         }
                    //     },
                    //     date: 3
                    // },
                    // {//3
                    //     questionType: "sales",
                    //     context: {
                    //         type: "sales",
                    //         name: "Sajan",
                    //         amount: 8000,
                    //         tradeDiscount: 5,
                    //         cashDiscount: 2,
                    //         order: false,
                    //         purchasedFrom: "-",
                    //         invoiceNo: 90,
                    //         credit: "-",
                    //         extraSum: "-",
                    //         extraSumReason: "-"
                    //     },
                    //     date: 7
                    // },
                    // {//4
                    //     questionType: "purchase",
                    //     context: {
                    //         type: "memo",
                    //         amount: 4000,
                    //         no: 30
                    //     },
                    //     date: 10
                    // },
                    // {//5
                    //     questionType: "salesReturns",
                    //     context: {
                    //         type: "salesReturns",
                    //         name: "Sajan",
                    //         amount: 2000,
                    //         creditNoteNo: 15,
                    //         rebate: false
                    //     },
                    //     date: 11
                    // },
                    // {//6
                    //     questionType: "purchaseReturns",
                    //     context: {
                    //         type: "purchaseReturns",
                    //         name: "Dhruvil",
                    //         percentageReturn: 10,
                    //         debitNoteNo: 16,
                    //         saleReturn: {
                    //             istrue: false
                    //         }
                    //     },
                    //     date: 15
                    // },
                    // {//7
                    //     questionType: "purchase",
                    //     context: {
                    //         type: "fixedAsset",
                    //         name: "Shree Bahuchar Furniture Mart",
                    //         amount: 5000,
                    //         billno: "-",
                    //         assetName: "furniture",
                    //         paid: {
                    //             type: false
                    //         }
                    //     },
                    //     date: 16
                    // },
                    // {//8
                    //     questionType: "purchase",
                    //     context: {
                    //         type: "nodiscount",
                    //         name: "Nirmi",
                    //         amount: 30000,
                    //         billno: "-",
                    //         paid: {
                    //             type: true,
                    //             amount: 15000
                    //         }
                    //     },
                    //     date: 18
                    // },
                    // {//9
                    //     questionType: "sales",
                    //     context: {
                    //         type: "sales",
                    //         name: "Haily",
                    //         amount: 36000,
                    //         tradeDiscount: 10,
                    //         cashDiscount: "-",
                    //         order: false,
                    //         purchasedFrom: "Nirmi",
                    //         invoiceNo: 100,
                    //         credit: 3,
                    //         extraSum: "-",
                    //         extraSumReason: "-"
                    //     },
                    //     date: 19
                    // },
                    // {//10
                    //     questionType: "purchaseReturns",
                    //     context: {
                    //         type: "purchaseReturns",
                    //         name: "Nirmi",
                    //         percentageReturn: 50,
                    //         debitNoteNo: "-",
                    //         saleReturn: {
                    //             istrue: true,
                    //             returneeName: "Haily"
                    //         }
                    //     },
                    //     date: 20
                    // },
                    // {//11
                    //     questionType: "order",
                    //     context: {
                    //         type: "order",
                    //         name: "Kanu"
                    //     },
                    //     date: 21
                    // },
                    // {//12
                    //     questionType: "sales",
                    //     context: {
                    //         type: "sales",
                    //         name: "Kanu",
                    //         amount: 12000,
                    //         order: true,
                    //         tradeDiscount: 10,
                    //         cashDiscount: "-",
                    //         purchasedFrom: "-",
                    //         invoiceNo: 102,
                    //         credit: "-",
                    //         extraSum: 300,
                    //         extraSumReason: "labour"
                    //     },
                    //     date: 24
                    // },
                    // {//13
                    //     questionType: "cashPurchaseSales",
                    //     context: {
                    //         type: "cashPurchaseSales",
                    //         cashPurchase: 20000,
                    //         cashSales: 25000
                    //     },
                    //     date: 25
                    // },
                    // {//14
                    //     questionType: "salesReturns",
                    //     context: {
                    //         type: "salesReturns",
                    //         name: "Kanu",
                    //         amount: 5400,
                    //         creditNoteNo: "-",
                    //         rebate: true
                    //     },
                    //     date: 31
                    // }
                ],

                addQuestionType: "none",
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempPayType: '',
                tempReturneeName: '',
                tempAmount: '',
                tempDue: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempDueDate: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                editModequestionPalette: [
                    {//1
                        name: false,
                        amount: false,
                        discount: false,
                        cashDiscount: false,
                    },
                    {//2
                        name: false,
                        amount: false,
                        tradeDiscount: false,
                        cashDiscount: false,
                        invoiceNo: false,
                        credit: false,
                    },
                    {//3
                        name: false,
                        amount: false,
                        discount: false,
                        cashDiscount: false,
                    },
                    {//4
                        name: false,
                        amount: false,
                        tradeDiscount: false,
                        cashDiscount: false,
                        invoiceNo: false,
                        credit: false,
                    },
                    {//5
                        name: false,
                        amount: false,
                        discount: false,
                        cashDiscount: false,
                    },
                    {//6
                        name: false,
                        amount: false,
                        tradeDiscount: false,
                        cashDiscount: false,
                        invoiceNo: false,
                        credit: false,
                    },
                    {//7

                    },
                    {//8

                    },
                    {//9
                        name: false,
                        due: false,
                        amount: false
                    },
                    {//10
                        name: false,
                        due: false,
                        amount: false
                    }
                    // {//3
                    //     name: false,
                    //     amount: false,
                    //     tradeDiscount: false,
                    //     cashDiscount: false,
                    //     invoiceNo: false,
                    //     credit: false,
                    // },
                    // {//4
                    //     amount: false
                    // },
                    // {//5
                    //     amount: false,
                    //     creditNoteNo: false,
                    // },
                    // {//6
                    //     percentageReturn: false,
                    //     debitNoteNo: false,
                    // },
                    // {//7
                    //     name: false,
                    //     amount: false,
                    //     billno: false,
                    //     assetName: false,

                    // },
                    // {//8
                    //     name: false,
                    //     amount: false,
                    //     billno: false
                    // },
                    // {//9
                    //     name: false,
                    //     amount: false,
                    //     tradeDiscount: false,
                    //     invoiceNo: false,
                    //     credit: false,
                    // },
                    // {//10
                    //     percentageReturn: false,
                    // },
                    // {//11

                    // },
                    // {//12
                    //     name: false,
                    //     amount: false,
                    //     tradeDiscount: false,
                    //     invoiceNo: false,
                    // },
                    // {//13
                    //     cashPurchase: false,
                    //     cashSales: false
                    // },
                    // {//14
                    //     amount: false
                    // }
                ],

                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModemonth: false,
                editModeyear: false,

                addQuestionChecker: false,


                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
            this.nameSetter = this.nameSetter.bind(this)
            this.removeQuestion = this.removeQuestion.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (event.target.value !== '-') {
                if (customArray.length === 3) {
                    switch (customArray[0]) {
                        case 'tradeDiscount':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "purchase")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'discount':
                                    this.state.questionPallete[customArray[2]].context.discount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'cashDiscount':
                                    this.state.questionPallete[customArray[2]].context.cashDiscount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'billno':
                                    this.state.questionPallete[customArray[2]].context.billno = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'cashDiscount':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "purchase")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'discount':
                                    this.state.questionPallete[customArray[2]].context.discount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'credit':
                                    this.state.questionPallete[customArray[2]].context.credit = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'memo':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'fixedAsset':
                            switch (customArray[1]) {
                                case 'assetName':
                                    this.state.questionPallete[customArray[2]].context.assetName = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'billno':
                                    this.state.questionPallete[customArray[2]].context.billno = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'nodiscount':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "purchase")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'billno':
                                    this.state.questionPallete[customArray[2]].context.billno = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'sales':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "sales")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'tradeDiscount':
                                    this.state.questionPallete[customArray[2]].context.tradeDiscount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'cashDiscount':
                                    this.state.questionPallete[customArray[2]].context.cashDiscount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'credit':
                                    this.state.questionPallete[customArray[2]].context.credit = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'invoiceNo':
                                    this.state.questionPallete[customArray[2]].context.invoiceNo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'purchaseReturns':
                            switch (customArray[1]) {
                                case 'percentageReturn':
                                    this.state.questionPallete[customArray[2]].context.percentageReturn = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debitNoteNo':
                                    this.state.questionPallete[customArray[2]].context.debitNoteNo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'salesReturns':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'creditNoteNo':
                                    this.state.questionPallete[customArray[2]].context.creditNoteNo = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'cashPurchaseSales':
                            switch (customArray[1]) {
                                case 'cashPurchase':
                                    this.state.questionPallete[customArray[2]].context.cashPurchase = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'cashSales':
                                    this.state.questionPallete[customArray[2]].context.cashSales = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'order':
                            switch (customArray[1]) {
                                case 'name':
                                    this.nameSetter(this.state.questionPallete[customArray[2]].context.name, event.target.value, "preOrder")
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'duePayment':
                            switch (customArray[1]) {
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'due':
                                    this.state.questionPallete[customArray[2]].context.due = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'toBePaid':
                            switch (customArray[1]) {
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'due':
                                    this.state.questionPallete[customArray[2]].context.due = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                    }
                }

                switch (event.target.name) {
                    case 'bookOwnerName':
                        this.setState({
                            bookOwnerName: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'year':
                        this.setState({
                            year: event.target.value
                        }, () => { this.fillDetails() })
                        break;

                }
            }
        }

        nameSetter(name, newName, type) {
            if (type === 'purchase') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "purchaseReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            if (type === 'sales') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "order" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }

            if (type === 'preOrder') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "sales" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            this.setState({})
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'date':
                    this.setState({
                        tempDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'dueDate':
                    this.setState({
                        tempDueDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'name':
                    this.setState({
                        tempName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amount':
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'due':
                    this.setState({
                        tempDue: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'discount':
                    this.setState({
                        tempDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Tdiscount':
                    this.setState({
                        tempTDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Cdiscount':
                    this.setState({
                        tempCDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'purchasedFrom':
                    this.setState({
                        tempPurchasedFrom: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'billno':
                    this.setState({
                        tempBillno: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'credit':
                    this.setState({
                        tempCredit: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPercentage':
                    this.setState({
                        tempAmountPercentage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'invoiceNo':
                    this.setState({
                        tempInvoiceNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'debitNoteNo':
                    this.setState({
                        tempDebitNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'creditNoteNo':
                    this.setState({
                        tempCreditNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPaidImmediately':
                    this.setState({
                        tempAmountPaidImmediately: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'memo':
                    this.setState({
                        tempMemo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'assetName':
                    this.setState({
                        tempAssetName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmount':
                    this.setState({
                        tempExtraSum: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmountReason':
                    this.setState({
                        tempExtraSumReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashPurchase':
                    this.setState({
                        tempCashPurchase: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashSales':
                    this.setState({
                        tempCashSales: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            switch (this.state.addQuestionType) {
                case 'tradeDiscount':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' && this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100
                    ) {
                        if (this.state.tempCDiscount !== '') {
                            if (NumberRegex.test(this.state.tempCDiscount) && parseFloat(this.state.tempCDiscount) > 0 && parseFloat(this.state.tempCDiscount) < 100) {
                                this.setState({
                                    addQuestionChecker: true
                                })
                            } else {
                                this.setState({
                                    addQuestionChecker: false
                                })
                            }
                        } else {
                            this.setState({
                                addQuestionChecker: true
                            })
                        }
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'sales':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempTDiscount !== '' && parseFloat(this.state.tempTDiscount) > 0 && parseFloat(this.state.tempTDiscount) < 100) {
                        if (this.state.tempCDiscount !== '') {
                            if (NumberRegex.test(this.state.tempCDiscount) && parseFloat(this.state.tempCDiscount) > 0 && parseFloat(this.state.tempCDiscount) < 100) {
                                this.setState({
                                    addQuestionChecker: true
                                })
                            } else {
                                this.setState({
                                    addQuestionChecker: false
                                })
                                break;
                            }
                        }
                        if (this.state.tempDueDate !== '') {
                            if (NormalNumberRegex.test(this.state.tempDueDate) && parseFloat(this.state.tempDueDate) > 0 && parseFloat(this.state.tempDueDate) < 32) {
                                this.setState({
                                    addQuestionChecker: true
                                })
                            } else {
                                this.setState({
                                    addQuestionChecker: false
                                })
                                break;
                            }
                        } else {
                            this.setState({
                                addQuestionChecker: true
                            })
                        }
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'pending':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempPayType !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'duePayment':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempPayType !== '' &&
                        this.state.tempDue !== '' && NumberRegex.test(this.state.tempDue) &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        if (parseFloat(this.state.tempAmount) > parseFloat(this.state.tempDue)) {
                            this.setState({
                                addQuestionChecker: false
                            })
                        } else {
                            this.setState({
                                addQuestionChecker: true
                            })
                        }
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'toBePaid':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempPayType !== '' &&
                        this.state.tempDue !== '' && NumberRegex.test(this.state.tempDue) &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        if (parseFloat(this.state.tempAmount) > parseFloat(this.state.tempDue)) {
                            this.setState({
                                addQuestionChecker: false
                            })
                        } else {
                            this.setState({
                                addQuestionChecker: true
                            })
                        }
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
            }
        }

        genderChange(event) {
            this.setState({
                bookOwnerGender: event.target.value
            })
        }

        monthChange(event) {
            this.setState({
                month: event.target.value
            })
        }

        questionTypeChange(event) {
            this.setState({
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempPayType: '',
                tempReturneeName: '',
                tempAmount: '',
                tempDue: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempDueDate: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                addQuestionChecker: false,
                addQuestionType: event.target.value
            })
        }

        addNameChange(event) {
            this.setState({
                tempName: event.target.value
            }, () => { this.addButtonChecker() })
        }

        addReturneeNameChange(event) {
            this.setState({
                tempReturneeName: event.target.value
            })
        }

        purchaseFromNameChange(event) {
            this.setState({
                tempPurchasedFrom: event.target.value
            })
        }

        rebateChange(event) {
            this.setState({
                tempRebate: event.target.value
            })
        }

        addPayTypeChange(event) {
            this.setState({
                tempPayType: event.target.value
            }, () => { this.addButtonChecker() })
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.questionPallete) {

                switch (i.context.type) {
                    case 'tradeDiscount':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '' ||
                            i.context.discount === '' || !NumberRegex.test(i.context.discount) || parseFloat(i.context.discount) < 0 || parseFloat(i.context.discount) > 100) {
                            flag = false
                        }
                        if (i.context.cashDiscount !== '-') {
                            if (i.context.cashDiscount === '' || !NumberRegex.test(i.context.cashDiscount) || parseFloat(i.context.cashDiscount) < 0 || parseFloat(i.context.cashDiscount) > 100) {
                                flag = false
                            }
                        }
                        break;
                    case "sales":
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '' ||
                            i.context.tradeDiscount === '' || !NumberRegex.test(i.context.tradeDiscount) || parseFloat(i.context.tradeDiscount) < 0 || parseFloat(i.context.tradeDiscount) > 100) {
                            flag = false
                        }
                        if (i.context.cashDiscount !== '-') {
                            if (i.context.cashDiscount === '' || !NumberRegex.test(i.context.cashDiscount) || parseFloat(i.context.cashDiscount) < 0 || parseFloat(i.context.cashDiscount) > 100) {
                                flag = false
                            }
                        }

                        break;
                    case 'duePayment':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '' ||
                            i.context.due === '' || !NumberRegex.test(i.context.due)) {
                            flag = false
                        }
                        break;
                    case 'toBePaid':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '' ||
                            i.context.due === '' || !NumberRegex.test(i.context.due)) {
                            flag = false
                        }
                        break;
                }
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {
            let totalAmount = 0

            for (let i of this.state.questionPallete) {
                if (i.context.type === 'tradeDiscount') {
                    i.context.totalAmount = parseFloat(i.context.amount) - parseFloat(i.context.amount) * parseFloat(i.context.discount) / 100
                    totalAmount += parseFloat(i.context.amount) - parseFloat(i.context.amount) * parseFloat(i.context.discount) / 100
                }
                if (i.context.type === 'sales') {
                    i.context.totalAmount = parseFloat(i.context.amount) - parseFloat(i.context.amount) * parseFloat(i.context.tradeDiscount) / 100
                    totalAmount += parseFloat(i.context.amount) - parseFloat(i.context.amount) * parseFloat(i.context.tradeDiscount) / 100
                }
                if (i.context.type === 'pending') {
                    totalAmount += parseFloat(i.context.amount)
                }
                if (i.context.type === 'duePayment') {
                    totalAmount += parseFloat(i.context.due)
                }
                if (i.context.type === 'toBePaid') {
                    totalAmount += parseFloat(i.context.due)
                }
            }


            // console.log(JSON.stringify(this.state.questionPallete,null,4))


            // console.log(JSON.stringify(this.state.questionPallete, null, 4))

            // let dataObject = JSON.parse(JSON.stringify(this.state.questionPallete))

            let dataObject = {
                questionPallete: this.state.questionPallete,
                bookOwnerName: this.state.bookOwnerName,
                month: this.state.month,
                year: this.state.year,
                totalAmount: totalAmount
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.clear()
            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addQuestion() {
            let adderFlag = true

            let temporaryTemplate = {
                questionType: this.state.addQuestionType,
                date: parseFloat(this.state.tempDate),
                assetName: this.state.tempAssetName,
                name: this.state.tempName,
                payType: this.state.tempPayType,
                amount: this.state.tempAmount,
                due: this.state.tempDue,
                amountPercentage: this.state.tempAmountPercentage,
                discount: this.state.tempDiscount,
                tradeDiscount: this.state.tempTDiscount,
                cashDiscount: this.state.tempCDiscount,
                purchasedFrom: this.state.tempPurchasedFrom,
                billno: this.state.tempBillno,
                invoiceNo: this.state.tempInvoiceNo,
                debitNoteNo: this.state.tempDebitNoteNo,
                creditNoteNo: this.state.tempCreditNoteNo,
                credit: this.state.tempCredit,
                memo: this.state.tempMemo,
                extraSum: this.state.tempExtraSum,
                extraSumReason: this.state.tempExtraSumReason,
                cashPurchase: this.state.tempCashPurchase,
                cashSales: this.state.tempCashSales,
                dueDate: this.state.tempDueDate,
                rebate: this.state.tempRebate,
                returneeName: this.state.tempReturneeName,

                amountPaidImmediately: this.state.tempAmountPaidImmediately
            }
            let questionObject
            let editModequestionObject

            switch (temporaryTemplate.questionType) {
                case 'tradeDiscount':
                    questionObject = {
                        questionType: "purchase",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            discount: temporaryTemplate.discount,
                            cashDiscount: "-",
                            paid: {
                                type: false
                            },

                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        discount: false,
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.cashDiscount)) {
                        editModequestionObject.cashDiscount = false
                        questionObject.context.cashDiscount = temporaryTemplate.cashDiscount
                    }
                    if (NormalNumberRegex.test(temporaryTemplate.amountPaidImmediately)) {
                        questionObject.context.paid.type = true
                        questionObject.context.paid.amount = temporaryTemplate.amountPaidImmediately
                    }
                    break;
                case 'sales':
                    questionObject = {
                        questionType: "sales",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            tradeDiscount: temporaryTemplate.tradeDiscount,
                            cashDiscount: "-",
                            paid: {
                                type: false
                            },
                            due: {
                                type: false
                            }
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.cashDiscount)) {
                        questionObject.context.cashDiscount = temporaryTemplate.cashDiscount
                        editModequestionObject.cashDiscount = false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.amountPaidImmediately)) {
                        questionObject.context.paid.amount = temporaryTemplate.amountPaidImmediately
                        questionObject.context.paid.type = true
                    }
                    if (NormalNumberRegex.test(temporaryTemplate.dueDate)) {
                        questionObject.context.due.date = temporaryTemplate.dueDate
                        questionObject.context.due.type = true
                    }

                    break;
                case 'pending':
                    questionObject = {
                        questionType: "payment",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            tradeDiscount: "-",
                            cashDiscount: "-",
                            lastDate: "-",
                            payType: temporaryTemplate.payType,
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                    }

                    for (let i of this.state.questionPallete) {
                        if (i.context.type === 'sales') {
                            if (i.context.name === temporaryTemplate.name) {
                                questionObject.context.tradeDiscount = i.context.tradeDiscount
                                questionObject.context.cashDiscount = i.context.cashDiscount
                                questionObject.context.initialDate = i.date
                                questionObject.context.originalAmount = i.context.amount
                                if (i.context.due.type) {
                                    questionObject.context.lastDate = i.context.due.date
                                }
                            }
                        }
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.cashDiscount)) {
                        questionObject.context.cashDiscount = temporaryTemplate.cashDiscount
                        editModequestionObject.cashDiscount = false
                    }
                    if (NormalNumberRegex.test(temporaryTemplate.amountPaidImmediately)) {
                        questionObject.context.paid.amount = temporaryTemplate.amountPaidImmediately
                        questionObject.context.paid.type = true
                    }
                    if (NormalNumberRegex.test(temporaryTemplate.dueDate)) {
                        questionObject.context.due.date = temporaryTemplate.dueDate
                        questionObject.context.due.type = true
                    }

                    break;
                case 'duePayment':
                    questionObject = {
                        questionType: "payment",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            due: temporaryTemplate.due,
                            payType: temporaryTemplate.payType,
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        due: false
                    }
                    break;
                case 'toBePaid':
                    questionObject = {
                        questionType: "toBePaid",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            due: temporaryTemplate.due,
                            payType: temporaryTemplate.payType,
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        due: false
                    }
                    break;

                    questionObject = {
                        questionType: "order",
                        context: {
                            type: temporaryTemplate.questionType,
                            cashPurchase: "-",
                            cashSales: "-"
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.cashPurchase)) {
                        questionObject.context.cashPurchase = temporaryTemplate.cashPurchase
                        editModequestionObject.cashPurchase = false
                    }

                    if (NormalNumberRegex.test(temporaryTemplate.cashSales)) {
                        questionObject.context.cashSales = temporaryTemplate.cashSales
                        editModequestionObject.cashSales = false
                    }

                    break;
            }

            if (adderFlag === true) {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].date >= temporaryTemplate.date) {
                        this.state.questionPallete.splice(i, 0, questionObject)
                        this.state.editModequestionPalette.splice(i, 0, editModequestionObject)
                        break;
                    }
                }
            }


            this.setState({})

            console.log(JSON.stringify(questionObject, null, 4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            let questionType = this.state.questionPallete[event].context.type
            let questionName = this.state.questionPallete[event].context.name

            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPalette.splice(event, 1)

            if (questionType === 'tradeDiscount' || questionType === 'cashDiscount' || questionType === 'nodiscount') {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].context.type === 'sales') {
                        if (this.state.questionPallete[i].context.purchasedFrom === questionName) {
                            this.state.questionPallete.splice(i, 1)
                            this.state.editModequestionPalette.splice(i, 1)
                        }
                    }
                    if (this.state.questionPallete[i].context.type === 'purchaseReturns') {
                        if (this.state.questionPallete[i].context.name === questionName) {
                            this.state.questionPallete.splice(i, 1)
                            this.state.editModequestionPalette.splice(i, 1)
                        }
                    }
                }
            }
            if (questionType === 'sales') {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].context.type === 'purchaseReturns') {
                        if (this.state.questionPallete[i].context.saleReturn.istrue) {
                            if (this.state.questionPallete[i].context.saleReturn.returneeName === questionName) {
                                this.state.questionPallete.splice(i, 1)
                                this.state.editModequestionPalette.splice(i, 1)
                            }
                        }
                    }
                    if (this.state.questionPallete[i].context.type === 'salesReturns') {
                        if (this.state.questionPallete[i].context.name === questionName) {
                            this.state.questionPallete.splice(i, 1)
                            this.state.editModequestionPalette.splice(i, 1)
                        }
                    }
                }
            }
            if (questionType === 'order') {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].questionType === 'sales') {
                        if (this.state.questionPallete[i].context.name === questionName) {
                            this.state.questionPallete[i].context.order = false
                        }
                    }
                }
            }


            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 3) {
                switch (customArray[0]) {
                    case 'tradeDiscount':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'discount':
                                this.state.editModequestionPalette[customArray[2]].discount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'cashDiscount':
                                this.state.editModequestionPalette[customArray[2]].cashDiscount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'billno':
                                this.state.editModequestionPalette[customArray[2]].billno = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'cashDiscount':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'discount':
                                this.state.editModequestionPalette[customArray[2]].discount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'credit':
                                this.state.editModequestionPalette[customArray[2]].credit = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'memo':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'fixedAsset':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'assetName':
                                this.state.editModequestionPalette[customArray[2]].assetName = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'billno':
                                this.state.editModequestionPalette[customArray[2]].billno = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'nodiscount':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'billno':
                                this.state.editModequestionPalette[customArray[2]].billno = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break
                    case 'sales':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'tradeDiscount':
                                this.state.editModequestionPalette[customArray[2]].tradeDiscount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'cashDiscount':
                                this.state.editModequestionPalette[customArray[2]].cashDiscount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'credit':
                                this.state.editModequestionPalette[customArray[2]].credit = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'invoiceNo':
                                this.state.editModequestionPalette[customArray[2]].invoiceNo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'purchaseReturns':
                        switch (customArray[1]) {
                            case 'percentageReturn':
                                this.state.editModequestionPalette[customArray[2]].percentageReturn = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'debitNoteNo':
                                this.state.editModequestionPalette[customArray[2]].debitNoteNo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'salesReturns':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'creditNoteNo':
                                this.state.editModequestionPalette[customArray[2]].creditNoteNo = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'cashPurchaseSales':
                        switch (customArray[1]) {
                            case 'cashPurchase':
                                this.state.editModequestionPalette[customArray[2]].cashPurchase = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'cashSales':
                                this.state.editModequestionPalette[customArray[2]].cashSales = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'order':
                        switch (customArray[1]) {
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'duePayment':
                        switch (customArray[1]) {
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'due':
                                this.state.editModequestionPalette[customArray[2]].due = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'toBePaid':
                        switch (customArray[1]) {
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'due':
                                this.state.editModequestionPalette[customArray[2]].due = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                }
            }


            switch (event.target.name) {
                case 'bookOwnerGender':
                    this.setState({
                        editModebookOwnerGender: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'bookOwnerName':
                    this.setState({
                        editModebookOwnerName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'year':
                    this.setState({
                        editModeyear: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'month':
                    this.setState({
                        editModemonth: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {

            for (let i of this.state.editModequestionPalette) {
                if (i.name) {
                    i.name = false
                }
                if (i.amount) {
                    i.amount = false
                }
                if (i.discount) {
                    i.discount = false
                }
                if (i.billno) {
                    i.billno = false
                }
                if (i.credit) {
                    i.credit = false
                }
                if (i.assetName) {
                    i.assetName = false
                }
                if (i.tradeDiscount) {
                    i.tradeDiscount = false
                }
                if (i.cashDiscount) {
                    i.cashDiscount = false
                }
                if (i.invoiceNo) {
                    i.invoiceNo = false
                }
                if (i.percentageReturn) {
                    i.percentageReturn = false
                }
                if (i.debitNoteNo) {
                    i.debitNoteNo = false
                }
                if (i.creditNoteNo) {
                    i.creditNoteNo = false
                }
                if (i.cashPurchase) {
                    i.cashPurchase = false
                }
                if (i.cashSales) {
                    i.cashSales = false
                }
                if (i.due) {
                    i.due = false
                }
            }


            this.setState({
                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModemonth: false,
                editModeyear: false,

                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{ fontSize: "20px" }}>

                    <br />
                    <div label="InputFieldTables" align="left" >
                        નીચેના વ્યવહારો&nbsp;

                        {this.state.editModebookOwnerName ? <TextField style={{ 'margin-top': "-15px", }} name="bookOwnerName" id='outlined-base' error={this.state.bookOwnerName === ''}
                            value={this.state.bookOwnerName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="bookOwnerName" onClick={this.buttonMaker.bind(this)}> {this.state.bookOwnerName}</a>}ના ચોપડે આમનોધમાં લખો :
                        <br /><br />
                        માસ :&nbsp;
                        {this.state.editModemonth ?
                            <FormControl style={{ width: "20%" }} size="small">
                                <InputLabel id="demo-simple-select-label">માસ</InputLabel>
                                <Select
                                    labelId="month"
                                    id="month"
                                    value={this.state.month}
                                    label="Month"
                                    onChange={this.monthChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"January"}>જાન્યુઆરી</MenuItem>
                                    <MenuItem value={"February"}>ફેબ્રુઆરી</MenuItem>
                                    <MenuItem value={"March"}>માર્ચ</MenuItem>
                                    <MenuItem value={"April"}>એપ્રિલ</MenuItem>
                                    <MenuItem value={"May"}>મે</MenuItem>
                                    <MenuItem value={"June"}>જૂન</MenuItem>
                                    <MenuItem value={"July"}>જુલાઈ</MenuItem>
                                    <MenuItem value={"August"}>ઓગસ્ટ</MenuItem>
                                    <MenuItem value={"September"}>સપ્ટેમ્બર</MenuItem>
                                    name: false,
                                    <MenuItem value={"October"}>ઓક્ટોબર</MenuItem>
                                    <MenuItem value={"November"}>નવેમ્બર</MenuItem>
                                    <MenuItem value={"December"}>ડિસેમ્બર</MenuItem>
                                </Select>
                            </FormControl> :
                            <a className='txt' name="month" onClick={this.buttonMaker.bind(this)}> {this.state.month}</a>
                        }
                        &nbsp;&nbsp;વર્ષ :&nbsp;
                        {this.state.editModeyear ? <TextField style={{ 'margin-top': "-15px", }} name="year" id='outlined-base' error={this.state.year === '' || isNaN(this.state.year) || parseInt(this.state.year) < 1000 || parseInt(this.state.year) > 9000}
                            value={this.state.year} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="year" onClick={this.buttonMaker.bind(this)}> {this.state.year}</a>}.
                        <br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px", fontSize: "20px" }} align='right' onClick={this.editModeOff.bind(this)} >સંપાદિત મોડ બંધ કરો</ColorButton> : <span></span>}

                        <br /><br />
                        તારીખ <br /><br />
                        <div label="totalQuestionsInArray">
                            {this.state.questionPallete.map((x, i) => (
                                <div>
                                    <span>{x.date}</span>&emsp;&emsp;
                                    {x.context.type === 'tradeDiscount' &&
                                        <span>

                                            {x.context.cashDiscount === '-' && <span>
                                                {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                    value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["tradeDiscount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;માંથી&nbsp;
                                            </span>}


                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["tradeDiscount", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            નો માલ&nbsp;





                                            {this.state.editModequestionPalette[i].discount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "discount", i]} id='outlined-base' error={x.context.discount === '' || isNaN(x.context.discount) || x.context.discount < 0 || x.context.discount > 100}
                                                value={x.context.discount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["tradeDiscount", "discount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.discount}</a>} %&nbsp;


                                            વેપારીવટાવે&nbsp;
                                            {x.context.cashDiscount === '-' && <span>ખરીદ્યો</span>}

                                            {x.context.cashDiscount !== '-' &&
                                                <span>
                                                    અને&nbsp;
                                                    {this.state.editModequestionPalette[i].cashDiscount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "cashDiscount", i]} id='outlined-base' error={x.context.cashDiscount === '' || isNaN(x.context.cashDiscount) || x.context.cashDiscount < 0 || x.context.cashDiscount > 100}
                                                        value={x.context.cashDiscount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["tradeDiscount", "cashDiscount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.cashDiscount}</a>} % રોકડવટાવે
                                                </span>
                                            }

                                            {x.context.cashDiscount !== '-' && <span>
                                                {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                    value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["tradeDiscount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;માંથી&nbsp;
                                            </span>}
                                            {x.context.cashDiscount !== '-' && <span>ખરીદ્યો</span>}
                                            {x.context.paid.type === true &&
                                                <span>
                                                    . {(parseFloat(x.context.amount) - (parseFloat(x.context.amount) * parseFloat(x.context.discount) / 100)) / 2 === parseFloat(x.context.paid.amount) ? "અડધાં નાણાં તરત જ રોકડા ચૂકવ્યા" : <span>{x.context.paid.amount === x.context.amount ? "નાણાં રોકડા ચૂકવ્યા" : <span>₹ {x.context.paid.amount} નાણાં તરત જ રોકડા ચૂકવ્યા</span>}</span>}
                                                </span>
                                            }.&nbsp;

                                        </span>
                                    }
                                    {x.context.type === 'cashDiscount' &&
                                        <span>
                                            Purchased goods of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["cashDiscount", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            from&nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["cashDiscount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            at&nbsp;
                                            {this.state.editModequestionPalette[i].discount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["tradeDiscount", "discount", i]} id='outlined-base' error={x.context.discount === '' || isNaN(x.context.discount) || x.context.discount < 0 || x.context.discount > 100}
                                                value={x.context.discount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["cashDiscount", "discount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.discount}</a>} %&nbsp;


                                            cash discount
                                            {x.context.paid.type === true &&
                                                <span>
                                                    &nbsp;and {parseFloat(x.context.amount) / 2 === parseFloat(x.context.paid.amount) ? "half " : <span>₹ {x.context.paid.amount}</span>} of the amount paid immediately
                                                </span>
                                            }.&nbsp;
                                            {x.context.credit !== '-' &&
                                                <span>Credit&nbsp;
                                                    {this.state.editModequestionPalette[i].credit ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["cashDiscount", "credit", i]} id='outlined-base' error={x.context.credit === '' || isNaN(x.context.credit)}
                                                        value={x.context.credit} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["cashDiscount", "credit", i]} onClick={this.buttonMaker.bind(this)}> {x.context.credit}</a>} months.&nbsp;
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'memo' &&
                                        <span>
                                            Purchased goods of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["memo", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["memo", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}.&nbsp;
                                            {x.context.no !== '-' ? <span>Cash memo no. {x.context.no}.</span> : "Cash Memo"}
                                        </span>
                                    }
                                    {x.context.type === 'fixedAsset' &&
                                        <span>
                                            Purchased&nbsp;
                                            {this.state.editModequestionPalette[i].assetName ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "assetName", i]} id='outlined-base' error={x.context.assetName === ''}
                                                value={x.context.assetName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "assetName", i]} onClick={this.buttonMaker.bind(this)}> {x.context.assetName}</a>}&nbsp;

                                            of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            from&nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "30%" }} name={["fixedAsset", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["fixedAsset", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}

                                            {x.context.paid.type === true &&
                                                <span>
                                                    &nbsp;and {parseFloat(x.context.amount) / 2 === parseFloat(x.context.paid.amount) ? "half " : <span>₹ {x.context.paid.amount}</span>} of the amount paid immediately
                                                </span>
                                            }.&nbsp;
                                            {x.context.billno !== '-' &&
                                                <span>Bill no.&nbsp;
                                                    {this.state.editModequestionPalette[i].billno ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "billno", i]} id='outlined-base' error={x.context.billno === '' || isNaN(x.context.billno)}
                                                        value={x.context.billno} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["fixedAsset", "billno", i]} onClick={this.buttonMaker.bind(this)}> {x.context.billno}</a>}.&nbsp;
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'nodiscount' &&
                                        <span>
                                            Purchased goods of ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["nodiscount", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            from&nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["fixedAsset", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["nodiscount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}

                                            {x.context.paid.type === true &&
                                                <span>
                                                    &nbsp;and {(parseFloat(x.context.amount) - (parseFloat(x.context.amount) * parseFloat(x.context.tradeDiscount) / 100)) / 2 === parseFloat(x.context.paid.amount) ? "half " : <span>₹ {x.context.paid.amount}</span>} of the amount paid immediately
                                                </span>
                                            }.&nbsp;
                                            {x.context.billno !== '-' &&
                                                <span>Bill no.&nbsp;
                                                    {this.state.editModequestionPalette[i].billno ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["nodiscount", "billno", i]} id='outlined-base' error={x.context.billno === '' || isNaN(x.context.billno)}
                                                        value={x.context.billno} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["nodiscount", "billno", i]} onClick={this.buttonMaker.bind(this)}> {x.context.billno}</a>}.&nbsp;
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'sales' &&
                                        <span>
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sales", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;
                                            ને ₹

                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["sales", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;
                                            નો માલ&nbsp;


                                            {/* {x.context.tradeDiscount !== '-' ? x.context.cashDiscount !== '-' ? "at " : "at " : ""} */}
                                            {x.context.tradeDiscount !== '-' &&
                                                <span>
                                                    {this.state.editModequestionPalette[i].tradeDiscount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "tradeDiscount", i]} id='outlined-base' error={x.context.tradeDiscount === '' || isNaN(x.context.tradeDiscount) || x.context.tradeDiscount < 0 || x.context.tradeDiscount > 100}
                                                        value={x.context.tradeDiscount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["sales", "tradeDiscount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.tradeDiscount}</a>} % વેપારીવટાવે
                                                </span>
                                            }
                                            {x.context.tradeDiscount !== '-' ? x.context.cashDiscount !== '-' ? " અને" : "" : ""}&nbsp;

                                            {x.context.cashDiscount !== '-' &&
                                                <span>
                                                    {x.context.due.type === true &&
                                                        <span>
                                                            જો 20 તારીખ સુધીમાં નાણાં ચૂક્વે તો
                                                        </span>
                                                    }
                                                    &nbsp;
                                                    {this.state.editModequestionPalette[i].cashDiscount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["sales", "cashDiscount", i]} id='outlined-base' error={x.context.cashDiscount === '' || isNaN(x.context.cashDiscount) || x.context.cashDiscount < 0 || x.context.cashDiscount > 100}
                                                        value={x.context.cashDiscount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["sales", "cashDiscount", i]} onClick={this.buttonMaker.bind(this)}>{x.context.cashDiscount}</a>} % રોકડવટાવે વેચ્યો
                                                </span>
                                            }

                                            {x.context.paid.type === true &&
                                                <span>
                                                    . {(parseFloat(x.context.amount) - (parseFloat(x.context.amount) * parseFloat(x.context.tradeDiscount) / 100)) / 2 === parseFloat(x.context.paid.amount) ? "અડધાં નાણાં તરત જ રોકડા ચૂકવ્યા" : <span>{x.context.paid.amount === x.context.amount ? "જેનાં નાણાં રોકડા મળ્યા" : <span>₹ {x.context.paid.amount} નાણાં તરત જ રોકડા ચૂકવ્યા</span>}</span>}
                                                </span>
                                            }.&nbsp;


                                            {/* {x.context.purchasedFrom !== '-' && "વેચ્યો "} */}
                                        </span>
                                    }
                                    {x.context.type === 'purchaseReturns' &&
                                        <span>
                                            {x.context.saleReturn.istrue ?
                                                <span>
                                                    {x.context.saleReturn.returneeName} returned&nbsp;
                                                    {this.state.editModequestionPalette[i].percentageReturn ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "percentageReturn", i]} id='outlined-base' error={x.context.percentageReturn === '' || isNaN(x.context.percentageReturn) || x.context.percentageReturn < 0 || x.context.percentageReturn > 100}
                                                        value={x.context.percentageReturn} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["purchaseReturns", "percentageReturn", i]} onClick={this.buttonMaker.bind(this)}>{parseFloat(x.context.percentageReturn) === 50 ? "half" : x.context.percentageReturn}</a>} {parseFloat(x.context.percentageReturn) !== 50 && "%"} of the goods and the same were&nbsp;

                                                </span> :
                                                <span>
                                                    {this.state.editModequestionPalette[i].percentageReturn ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "percentageReturn", i]} id='outlined-base' error={x.context.percentageReturn === '' || isNaN(x.context.percentageReturn) || x.context.percentageReturn < 0 || x.context.percentageReturn > 100}
                                                        value={x.context.percentageReturn} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["purchaseReturns", "percentageReturn", i]} onClick={this.buttonMaker.bind(this)}>{parseFloat(x.context.percentageReturn) === 50 ? "Half" : x.context.percentageReturn}</a>} {parseFloat(x.context.percentageReturn) !== 50 && "%"} goods were&nbsp;
                                                </span>}
                                            returned to {x.context.name}.&nbsp;

                                            {x.context.debitNoteNo !== '-' &&
                                                <span>
                                                    Debit note no.&nbsp;
                                                    {this.state.editModequestionPalette[i].debitNoteNo ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "debitNoteNo", i]} id='outlined-base' error={x.context.debitNoteNo === '' || isNaN(x.context.debitNoteNo)}
                                                        value={x.context.debitNoteNo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["purchaseReturns", "debitNoteNo", i]} onClick={this.buttonMaker.bind(this)}>{x.context.debitNoteNo}</a>} sent.
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'salesReturns' &&
                                        <span>
                                            ₹&nbsp;

                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["salesReturns", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["salesReturns", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            of goods were returned by {x.context.name}

                                            {x.context.rebate === true && " and it was agreed to give rebate of carriage in proportion"}.&nbsp;

                                            {x.context.creditNoteNo !== '-' &&
                                                <span>
                                                    Credit note no.&nbsp;
                                                    {this.state.editModequestionPalette[i].creditNoteNo ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["purchaseReturns", "creditNoteNo", i]} id='outlined-base' error={x.context.creditNoteNo === '' || isNaN(x.context.creditNoteNo)}
                                                        value={x.context.creditNoteNo} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["purchaseReturns", "creditNoteNo", i]} onClick={this.buttonMaker.bind(this)}>{x.context.creditNoteNo}</a>} sent.
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.context.type === 'order' &&
                                        <span>
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["order", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["order", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;
                                            placed a pre-order for supplying goods.
                                        </span>
                                    }
                                    {x.context.type === 'cashPurchaseSales' &&
                                        <span>
                                            {x.context.cashPurchase !== '-' &&
                                                <span>Cash Purchase ₹&nbsp;
                                                    {this.state.editModequestionPalette[i].cashPurchase ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["cashPurchaseSales", "cashPurchase", i]} id='outlined-base' error={x.context.cashPurchase === '' || isNaN(x.context.cashPurchase)}
                                                        value={x.context.cashPurchase} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["cashPurchaseSales", "cashPurchase", i]} onClick={this.buttonMaker.bind(this)}> {x.context.cashPurchase}</a>
                                                    }
                                                </span>
                                            }

                                            {x.context.cashPurchase !== '-' ? x.context.cashSales !== '-' ? " and " : " " : " "}

                                            {x.context.cashSales !== '-' &&
                                                <span>Cash Sales ₹&nbsp;
                                                    {this.state.editModequestionPalette[i].cashSales ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["cashPurchaseSales", "cashSales", i]} id='outlined-base' error={x.context.cashSales === '' || isNaN(x.context.cashSales)}
                                                        value={x.context.cashSales} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["cashPurchaseSales", "cashSales", i]} onClick={this.buttonMaker.bind(this)}> {x.context.cashSales}</a>
                                                    }
                                                </span>
                                            }.
                                        </span>
                                    }
                                    {x.context.type === 'pending' && x.context.payType === 'check' &&
                                        <span>
                                            {parseFloat(x.date) <= parseFloat(x.context.lastDate) && <span>તા. {x.context.initialDate} ના વેચાણ પૈકી </span>} {parseFloat(x.context.originalAmount) / parseFloat(x.context.amount) === 2 ? " અડધી રકમનો" : " ₹ " + x.context.amount} ચેક બિમલે આપ્યો, જે બૅન્કમાં ભર્યો.
                                        </span>
                                    }
                                    {x.context.type === 'pending' && x.context.payType === 'cash' &&
                                        <span>
                                            {parseFloat(x.date) <= parseFloat(x.context.lastDate) && <span>For the sale of {x.context.initialDate} {this.state.month}, </span>}બિમલે બાકીની લેણી રકમ રોકડેથી ચૂકવી.
                                        </span>
                                    }
                                    {x.context.type === 'duePayment' &&
                                        <span>
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["duePayment", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["duePayment", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>
                                            }&nbsp;

                                            પાસે ₹&nbsp;
                                            {this.state.editModequestionPalette[i].due ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["duePayment", "due", i]} id='outlined-base' error={x.context.due === '' || isNaN(x.context.due)}
                                                value={x.context.due} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["duePayment", "due", i]} onClick={this.buttonMaker.bind(this)}> {x.context.due}</a>
                                            }&nbsp;

                                            લેણા હતા, તેણે ચૂકતેના હિસાબે ₹&nbsp;

                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["duePayment", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["duePayment", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>
                                            } રોકડા આપ્યા.


                                        </span>
                                    }
                                    {x.context.type === 'toBePaid' &&
                                        <span>
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["toBePaid", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["toBePaid", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>
                                            }ને&nbsp;

                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].due ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["toBePaid", "due", i]} id='outlined-base' error={x.context.due === '' || isNaN(x.context.due)}
                                                value={x.context.due} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["toBePaid", "due", i]} onClick={this.buttonMaker.bind(this)}> {x.context.due}</a>
                                            }

                                            ના દેવા પેટે ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["toBePaid", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["toBePaid", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>
                                            } નો ચેક આપી હિસાબ ચૂકતે કર્યો&nbsp;
                                            .
                                        </span>
                                    }



                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeQuestion.bind(this, i)} name="-">-</IconButton>

                                    <br /><br />
                                </div>
                            ))}
                        </div>

                        <div label="addQuestionInputs" >
                            નવો પ્રશ્ન ઉમેરો :<br /><br /><br />
                            પ્રશ્નનો પ્રકાર:
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">પ્રશ્ન</InputLabel>
                                <Select
                                    labelId="question"
                                    id="question"
                                    value={this.state.addQuestionType}
                                    label="Question"
                                    onChange={this.questionTypeChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"none"}>None</MenuItem>
                                    <MenuItem value={"tradeDiscount"}>ખરીદી</MenuItem>
                                    <MenuItem value={"sales"}>વેચાણ</MenuItem>
                                    <MenuItem value={"pending"}>બાકી ચુકવણી</MenuItem>
                                    <MenuItem value={"duePayment"}>બાકી રકમ</MenuItem>
                                    <MenuItem value={"toBePaid"}>ચુકવણી કરવાની રહેશે</MenuItem>
                                    {/* <MenuItem value={"cashDiscount"}>Purchase - Cash Discount</MenuItem>
                                    <MenuItem value={"memo"}>Purchase - Cash Memo</MenuItem>
                                    <MenuItem value={"fixedAsset"}>Purchase - Fixed Assets</MenuItem>
                                    <MenuItem value={"nodiscount"}>Purchase - No Discount</MenuItem>
                                    <MenuItem value={"sales"}>Sales</MenuItem>
                                    <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem>
                                    <MenuItem value={"salesReturns"}>Sales Returns</MenuItem>
                                    <MenuItem value={"order"}>Pre-Order Placement</MenuItem>
                                    <MenuItem value={"cashPurchaseSales"}>Cash Purchase/Sales</MenuItem>                                    <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                </Select>
                            </FormControl><br /><br /><br />


                            {this.state.addQuestionType === 'tradeDiscount' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    વેપારી વટાવ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="discount" error={this.state.tempDiscount === '' || isNaN(this.state.tempDiscount) || this.state.tempDiscount < 0 || this.state.tempDiscount > 100}
                                        placeholder="વેપારી વટાવ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રોકડ વટાવ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="Cdiscount" error={isNaN(this.state.tempCDiscount) || this.state.tempCDiscount < 0 || this.state.tempCDiscount > 100}
                                        placeholder="રોકડ વટાવ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ તરત જ ચૂકવવામાં આવી હતી?(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="ચૂકવેલ રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />



                                </span>
                            }
                            {this.state.addQuestionType === 'sales' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    વેપારી વટાવ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="Tdiscount" error={this.state.tempTDiscount === '' || isNaN(this.state.tempTDiscount) || this.state.tempTDiscount < 0 || this.state.tempTDiscount > 100}
                                        placeholder="વેપારી વટાવ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રોકડ વટાવ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="Cdiscount" error={isNaN(this.state.tempCDiscount) || this.state.tempCDiscount < 0 || this.state.tempCDiscount > 100}
                                        placeholder="રોકડ વટાવ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ તરત જ ચૂકવવામાં આવી હતી?(વૈકલ્પિક): <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPaidImmediately" error={isNaN(this.state.tempAmountPaidImmediately)}
                                        placeholder="ચૂકવેલ રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ત્યાં રોકડ ડિસ્કાઉન્ટ માટે નિયત તારીખ છે? <br /><br />

                                    જો હા, તો તારીખ શું છે? : <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="dueDate" error={isNaN(this.state.tempDueDate) || this.state.tempDueDate < 0 || this.state.tempDueDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'pending' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "sales" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ચુકવણીનો પ્રકાર:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempPayType === ''}
                                            value={this.state.tempPayType}
                                            label="Name"
                                            onChange={this.addPayTypeChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value={"check"}>ચેક દ્વારા</MenuItem>
                                            <MenuItem value={"cash"}>રોકડ દ્વારા</MenuItem>
                                        </Select>
                                    </FormControl><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'duePayment' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    બાકી રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="due" error={this.state.tempDue === '' || isNaN(this.state.tempDue)}
                                        placeholder="બાકી રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ચૂકવેલ રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="ચૂકવેલ રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ચુકવણીનો પ્રકાર:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempPayType === ''}
                                            value={this.state.tempPayType}
                                            label="Name"
                                            onChange={this.addPayTypeChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value={"check"}>ચેક દ્વારા</MenuItem>
                                            <MenuItem value={"cash"}>રોકડ દ્વારા</MenuItem>
                                        </Select>
                                    </FormControl><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'toBePaid' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    બાકી રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="due" error={this.state.tempDue === '' || isNaN(this.state.tempDue)}
                                        placeholder="બાકી રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ચૂકવેલ રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="ચૂકવેલ રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ચુકવણીનો પ્રકાર:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempPayType === ''}
                                            value={this.state.tempPayType}
                                            label="Name"
                                            onChange={this.addPayTypeChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value={"check"}>ચેક દ્વારા</MenuItem>
                                            <MenuItem value={"cash"}>રોકડ દ્વારા</MenuItem>
                                        </Select>
                                    </FormControl><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'purchaseReturns' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "tradeDiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "cashDiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "nodiscount" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    પરત કરેલ રકમની ટકાવારી દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPercentage" error={this.state.tempAmountPercentage === '' || isNaN(this.state.tempAmountPercentage) || this.state.tempAmountPercentage < 0 || this.state.tempAmountPercentage > 100}
                                        placeholder="રકમની ટકાવારી દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ડેબિટ નોંધ નંબર દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="debitNoteNo" error={isNaN(this.state.tempDebitNoteNo)}
                                        placeholder="ડેબિટ નોંધ નંબર દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    શું કોઈના વેચાણ દ્વારા માલ પરત કરવામાં આવ્યો હતો?<br /><br />

                                    જો હા, તો રિટર્નીનું નામ:&nbsp;

                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">પરત કરનાર નું નામ</InputLabel>
                                        <Select
                                            labelId="returneeName"
                                            id="returneeName"
                                            value={this.state.tempReturneeName}
                                            label="returneeName"
                                            onChange={this.addReturneeNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.questionPallete.map((y, j) => (
                                                y.questionType === "sales" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'salesReturns' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.questionPallete.map((y, j) => (
                                                y.context.type === "sales" && <MenuItem value={y.context.name}>{y.context.name}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ક્રેડિટ નોટ નંબર દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="creditNoteNo" error={isNaN(this.state.tempCreditNoteNo)}
                                        placeholder="ક્રેડિટ નોટ નંબર દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રિબેટ સંમત હતો ? :&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            value={this.state.tempRebate}
                                            label="Name"
                                            onChange={this.rebateChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value={false}>ના</MenuItem>
                                            <MenuItem value={true}>હા</MenuItem>
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === "order" &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'cashPurchaseSales' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || parseFloat(this.state.tempDate) < 0 || parseFloat(this.state.tempDate) > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રોકડ ખરીદી દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="cashPurchase" error={isNaN(this.state.tempCashPurchase)}
                                        placeholder="રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રોકડ વેચાણ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="cashSales" error={isNaN(this.state.tempCashSales)}
                                        placeholder="રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }

                            {this.state.addQuestionType !== 'none' && <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%", fontSize: "20px" }} onClick={this.addQuestion.bind(this)}>નવો પ્રશ્ન ઉમેરો</AddButton>}
                        </div>

                        <br /><br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px", fontSize: "20px" }} align='right' onClick={this.editModeOff.bind(this)} >સંપાદિત મોડ બંધ</ColorButton> : <span></span>}


                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize: "20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize: "20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો/છુપાવો</AddButton>
                        </div>
                        <div align='right'>
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                        <br />
                        <br />

                    </div>
                    <br /><br />
                    {!this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>જવાબ. :</b><br /><br />
                            <div align="center"><b>{data[chartState.selected].bookOwnerName}ની આમનોંધ</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px" }}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top", fontSize: "20px" }}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontSize: "20px" }}>ઉધાર (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontSize: "20px" }}>જમા (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>{data[chartState.selected].year}</StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].questionPallete.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align='center' style={{ verticalAlign: "top" }}>{parseInt(i) === 0 && data[chartState.selected].month.slice(0, 3) + "."} <span style={{ float: "right" }}>{x.date}</span></StyledTableCell>
                                                <StyledTableCell align='left' style={{ fontSize: "20px" }}>
                                                    {x.context.type === 'tradeDiscount' &&
                                                        <span>
                                                            ખરીદ ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                            {x.context.cashDiscount === '-' &&
                                                                <span>
                                                                    &emsp;&emsp;તે {x.context.name} ખાતે<br />
                                                                </span>}
                                                            {x.context.cashDiscount !== '-' &&
                                                                <span>
                                                                    &emsp;&emsp;તે રોકડ ખાતે<br />
                                                                    &emsp;&emsp;તે મળેલ વટાવ ખાતે<br />
                                                                    {x.context.paid.type === true ? x.context.amount !== x.context.paid.amount && <span>&emsp;&emsp;તે {x.context.name} ખાતે<br /></span> : ""}
                                                                </span>
                                                            }
                                                            [બા.જે. : ₹ {x.context.amount} નો માલ {x.context.discount} % વેપારીવટાવે {x.context.cashDiscount !== '-' && " અને " + x.context.cashDiscount + " % રોકડવટાવે"} {x.context.name} માંથી ખરીદ્યો તેના {x.context.paid.type ? x.context.paid.amount !== x.context.amount ? parseFloat(x.context.paid.amount) === parseFloat(x.context.amount) / 2 ? ". અડધા નાણાં રોકડા ચૂકવ્યા" : " નાણાં તરત જ ચૂકવ્યા" : "" : ""}.]
                                                        </span>
                                                    }
                                                    {x.context.type === 'sales' &&
                                                        <span>
                                                            {!x.context.due.type &&
                                                                <span>
                                                                    રોકડ ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                                    {x.context.cashDiscount !== '-' && <span>વટાવ ખાતે<span style={{ float: "right" }}>ઉ</span><br /></span>}
                                                                </span>
                                                            }
                                                            {x.context.due.type &&
                                                                <span>
                                                                    {x.context.name} ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                                </span>
                                                            }
                                                            &emsp;&emsp;તે વેચાણ ખાતે<br />
                                                            [બા.જે. : ₹ {x.context.amount} નો માલ {x.context.tradeDiscount} % વેપા૨ીવટાવે

                                                            {!x.context.due.type && <span>
                                                                {x.context.cashDiscount !== '-' && " અને " + x.context.cashDiscount + " % રોકડવટાવે"}
                                                            </span>}
                                                            {x.context.name} ને રોકડેથી વેચ્યો તેના.
                                                            ]
                                                        </span>
                                                    }
                                                    {x.context.type === 'pending' &&
                                                        <span>
                                                            {x.context.payType === 'check' &&
                                                                <span>
                                                                    બૅન્ક ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                                    {x.context.lastDate !== '-' && parseFloat(x.context.lastDate) >= parseFloat(x.date) &&
                                                                        <span>
                                                                            આપેલ વટાવ ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                                        </span>
                                                                    }
                                                                </span>
                                                            }
                                                            {x.context.payType === 'cash' &&
                                                                <span>
                                                                    રોકડ ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                                    {x.context.lastDate !== '-' && parseFloat(x.context.lastDate) >= parseFloat(x.date) &&
                                                                        <span>
                                                                            આપેલ વટાવ ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                                        </span>
                                                                    }
                                                                </span>
                                                            }
                                                            &emsp;&emsp;તે {x.context.name} ખાતે<br />
                                                            [
                                                            {x.context.lastDate !== '-' && parseFloat(x.context.lastDate) >= parseFloat(x.date) &&
                                                                <span>
                                                                    બા.જે. : {x.context.name} અડધી રકમ ચેકથી ચૂકવી અને તેને
                                                                </span>
                                                            }

                                                            {x.context.lastDate !== '-' && parseFloat(x.context.lastDate) < parseFloat(x.date) &&
                                                                <span>
                                                                    {x.context.name} બાકીની લેણી રકમ ₹ &nbsp;
                                                                    {parseFloat(x.context.amount)} ચૂકવી તેના
                                                                </span>
                                                            }
                                                            {x.context.lastDate === '-' &&
                                                                <span>
                                                                    {x.context.name} બાકીની લેણી રકમ ₹ &nbsp;
                                                                    {x.context.amount}
                                                                </span>
                                                            }
                                                            &nbsp;

                                                            {x.context.lastDate !== '-' && parseFloat(x.context.lastDate) >= parseFloat(x.date) &&
                                                                <span>&nbsp;
                                                                    {x.context.cashDiscount} % રોકડવટાવ આપ્યો તેના
                                                                </span>
                                                            }.]
                                                        </span>
                                                    }
                                                    {x.context.type === 'duePayment' &&
                                                        <span>
                                                            રોકડ ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                            કસર ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                            &emsp;&emsp;તે {x.context.name} ખાતે<br />
                                                            [બા.જે. : ₹ {x.context.due} સુનીતા પાસેથી લેણા પેટે ચૂકતે હિસાબે ₹ {x.context.amount} મળ્યા તેના.]
                                                        </span>
                                                    }
                                                    {x.context.type === 'toBePaid' &&
                                                        <span>
                                                            {x.context.name} ખાતે<span style={{ float: "right" }}>ઉ</span><br />
                                                            &emsp;&emsp;તે બેન્ક ખાતે <br />
                                                            &emsp;&emsp;તે કસર ખાતે <br />
                                                            [બા.જે. : {x.context.name} ને ₹ {x.context.due}ના દેવા પેટે ₹ {x.context.amount}નો ચેક આપી હિસાબ ચૂકતે કર્યો તેના.]
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x.context.type === 'tradeDiscount' &&
                                                        <span>
                                                            {x.context.totalAmount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'sales' &&
                                                        <span>
                                                            {x.context.cashDiscount !== '-' && x.context.due.type === false ?
                                                                <span>
                                                                    {parseFloat(x.context.totalAmount) - parseFloat(x.context.totalAmount) * parseFloat(x.context.cashDiscount) / 100}<br />
                                                                    {parseFloat(x.context.totalAmount) * parseFloat(x.context.cashDiscount) / 100}
                                                                </span> :
                                                                <span>
                                                                    {x.context.totalAmount}
                                                                </span>
                                                            }

                                                        </span>
                                                    }
                                                    {x.context.type === 'pending' &&
                                                        <span>
                                                            {x.context.cashDiscount !== '-' ?
                                                                <span>
                                                                    {parseFloat(x.context.lastDate) >= parseFloat(x.date) ?
                                                                        <span>
                                                                            {parseFloat(x.context.amount) - parseFloat(x.context.amount) * parseFloat(x.context.cashDiscount) / 100}<br />
                                                                            {parseFloat(x.context.amount) * parseFloat(x.context.cashDiscount) / 100}<br />
                                                                        </span> :
                                                                        <span>
                                                                            {parseFloat(x.context.amount)}
                                                                        </span>
                                                                    }
                                                                </span> :
                                                                <span>
                                                                    {x.context.amount}
                                                                </span>
                                                            }
                                                        </span>
                                                    }
                                                    {x.context.type === 'duePayment' &&
                                                        <span>
                                                            {parseFloat(x.context.due) - (parseFloat(x.context.due) - parseFloat(x.context.amount))}<br />
                                                            {parseFloat(x.context.due) - parseFloat(x.context.amount)}
                                                        </span>
                                                    }
                                                    {x.context.type === 'toBePaid' &&
                                                        <span>
                                                            {parseFloat(x.context.due)}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x.context.type === 'tradeDiscount' &&
                                                        <span>
                                                            <br />
                                                            {x.context.cashDiscount === '-' && x.context.totalAmount}
                                                            {x.context.cashDiscount !== '-' &&
                                                                <span>
                                                                    {x.context.paid.amount === x.context.amount &&
                                                                        <span>
                                                                            {parseFloat(x.context.totalAmount) - parseFloat(x.context.totalAmount) * parseFloat(x.context.cashDiscount) / 100}<br />
                                                                            {parseFloat(x.context.totalAmount) * parseFloat(x.context.cashDiscount) / 100}
                                                                        </span>
                                                                    }
                                                                    {x.context.paid.amount !== x.context.amount &&
                                                                        <span>
                                                                            {parseFloat(x.context.paid.amount) - parseFloat(x.context.paid.amount) * parseFloat(x.context.discount) / 100}<br />
                                                                            {parseFloat(x.context.paid.amount) * parseFloat(x.context.discount) / 100}<br />
                                                                            {x.context.paid.amount}
                                                                        </span>
                                                                    }
                                                                </span>
                                                            }
                                                        </span>
                                                    }
                                                    {x.context.type === 'sales' &&
                                                        <span>
                                                            {x.context.cashDiscount !== '-' && x.context.due.type === false ?
                                                                <span>
                                                                    <br /><br />
                                                                </span> :
                                                                <span>
                                                                    <br />
                                                                </span>
                                                            }
                                                            {x.context.totalAmount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'pending' &&
                                                        <span>
                                                            {x.context.cashDiscount !== '-' ?
                                                                <span>
                                                                    {parseFloat(x.context.lastDate) >= parseFloat(x.date) ?
                                                                        <span>
                                                                            <br />
                                                                            <br />
                                                                        </span> :
                                                                        <span>
                                                                            <br />
                                                                        </span>
                                                                    }
                                                                </span> :
                                                                <span>
                                                                    <br />
                                                                </span>
                                                            }
                                                            {x.context.amount}
                                                        </span>
                                                    }
                                                    {x.context.type === 'duePayment' &&
                                                        <span>
                                                            <br />
                                                            <br />
                                                            {x.context.due}
                                                        </span>
                                                    }
                                                    {x.context.type === 'toBePaid' &&
                                                        <span>
                                                            <br />
                                                            {parseFloat(x.context.due) - (parseFloat(x.context.due) - parseFloat(x.context.amount))}<br />
                                                            {parseFloat(x.context.due) - parseFloat(x.context.amount)}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="right" style={{ fontSize: "20px" }}><b>કુલ</b></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].totalAmount}</b></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].totalAmount}</b></StyledTableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    }
                    <br />
                    <br />
                </div >
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button, Switch, touchRippleClasses } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
//ILLUSTRATION 8

export default function ComparativeProfitLossStatement() {

    const data = [{
        "bookOwnerName": "Shethana Stationary Mart",
        "month": "March",
        "monthNumber": "3",
        "year": 2016,
        "questionPallete": [
            {
                "questionType": "purchase",
                "context": {
                    "type": "multipurchase",
                    "name": [
                        "cash balance",
                        "bank balance"
                    ],
                    "amount": [
                        18600,
                        62000
                    ]
                },
                "date": 1
            },
            {
                "questionType": "payment",
                "context": {
                    "type": "paidAmount",
                    "name": "પગારનું",
                    "amount": 6000,
                    "style": "cash"
                },
                "date": 2
            },
            {
                "questionType": "payment",
                "context": {
                    "type": "paidAmount",
                    "name": "વીજળી ખર્ચનું",
                    "amount": 1200,
                    "style": "cash"
                },
                "date": 2
            },
            {
                "questionType": "settlement",
                "context": {
                    "type": "debtSettlement",
                    "name": "Shah Brothers",
                    "amount": 8000,
                    "debt": 8200,
                    "style": "cheque"
                },
                "date": 5
            },
            {
                "questionType": "received",
                "context": {
                    "type": "commission",
                    "name": "Camlin Company",
                    "amount": 2500,
                    "style": "cash"
                },
                "date": 6
            },
            {
                "questionType": "received",
                "context": {
                    "type": "chequeSettlement",
                    "name": "Desouza",
                    "amount": 2550,
                    "recievables": 2500,
                    "style": "cheque"
                },
                "date": 7
            },
            {
                "questionType": "withdraw",
                "context": {
                    "type": "withdraw",
                    "name": "household",
                    "amount": 5000,
                    "style": "cash"
                },
                "date": 7
            }
        ],
        "leftSideArray": [
            {
                "date": 1,
                "receipts": "બાકી",
                "discount": "-",
                "cash": 18600,
                "bank": 62000,
                "type": "Balance"
            },
            {
                "date": 4,
                "receipts": "બૅન્ક",
                "discount": "-",
                "cash": 10000,
                "bank": "-"
            },
            {
                "date": 6,
                "receipts": "કમિશન",
                "discount": "-",
                "cash": 2500,
                "bank": "-"
            },
            {
                "date": 7,
                "receipts": "ડિસોઝા",
                "discount": 50,
                "cash": "-",
                "bank": 2500
            }
        ],
        "rightSideArray": [
            {
                "date": 2,
                "receipts": "પગાર",
                "discount": "-",
                "cash": 6000,
                "bank": "-"
            },
            {
                "date": 2,
                "receipts": "વીજળી ખર્ચ",
                "discount": "-",
                "cash": 1200,
                "bank": "-"
            },
            {
                "date": 4,
                "receipts": "રોકડ",
                "discount": "-",
                "cash": "-",
                "bank": 10000
            },
            {
                "date": 5,
                "receipts": "Shah Brothers",
                "discount": 200,
                "cash": "-",
                "bank": 8000
            },
            {
                "date": 7,
                "receipts": "ઉપાડ",
                "discount": "-",
                "cash": 5000,
                "bank": "-"
            }
        ],
        "leftSideTotal": {
            "discount": 50,
            "cash": 31100,
            "bank": 64500
        },
        "rightSideTotal": {
            "discount": 200,
            "cash": 12200,
            "bank": 18000
        },
        "rightSideDifferenceBetweenArray": {
            "cash": 18900,
            "bank": 46500
        },
        "leftSideDifferenceBetweenArray": {
            "cash": -18900,
            "bank": -46500
        }
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const AlphabetRegex = new RegExp(/^[a-zA-Z]+$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                bookOwnerGender: "Shri",
                bookOwnerName: "Shethana Stationary Mart",
                month: "March",
                year: 2016,
                questionPallete: [
                    {//1
                        questionType: "purchase",
                        context: {
                            type: "multipurchase",
                            name: ["રોકડસિલક", "બેંકસિલક"],
                            amount: [18600, 62000]
                        },
                        date: 1
                    },
                    {//2
                        questionType: "payment",
                        context: {
                            type: "paidAmount",
                            name: "પગાર",
                            amount: 6000,
                            style: "cash"
                        },
                        date: 2
                    },
                    {//3
                        questionType: "payment",
                        context: {
                            type: "paidAmount",
                            name: "વીજળીબિલ",
                            amount: 1200,
                            style: "cash"
                        },
                        date: 2
                    },
                    {//4
                        questionType: "withdraw",
                        context: {
                            type: "withdraw",
                            name: "દુકાનખર્ચ",
                            amount: 10000,
                            style: "cheque"
                        },
                        date: 4
                    },
                    {//5
                        questionType: "settlement",
                        context: {
                            type: "debtSettlement",
                            name: "Shah Brothers",
                            amount: 8000,
                            debt: 8200,
                            style: "cheque"
                        },
                        date: 5
                    },
                    {//6
                        questionType: "received",
                        context: {
                            type: "commission",
                            name: "Camlin Company",
                            amount: 2500,
                            style: "cash"
                        },
                        date: 6
                    },
                    {//7
                        questionType: "received",
                        context: {
                            type: "chequeSettlement",
                            name: "Desouza",
                            amount: 2550,
                            recievables: 2500,
                            style: "cheque"
                        },
                        date: 7
                    },
                    {//8
                        questionType: "withdraw",
                        context: {
                            type: "withdraw",
                            name: "household",
                            amount: 5000,
                            style: "cash"
                        },
                        date: 7
                    },
                ],

                addQuestionType: "none",
                tempPayType: '',
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempReason: '',
                tempShares: '',
                tempBrokerage: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                editModequestionPalette: [
                    {//1
                        nameMultiple: [false, false],
                        amountMultiple: [false, false]
                    },
                    {//2
                        name: false,
                        amount: false
                    },
                    {//3
                        name: false,
                        amount: false
                    },
                    {//4
                        name: false,
                        amount: false
                    },
                    {//5
                        name: false,
                        amount: false,
                        debt: false
                    },
                    {//6
                        name: false,
                        amount: false
                    },
                    {//7
                        name: false,
                        amount: false,
                        recievables: false
                    },
                    {//
                        name: false,
                        amount: false
                    }
                ],


                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModemonth: false,
                editModeyear: false,

                addQuestionChecker: false,


                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
            this.nameSetter = this.nameSetter.bind(this)
            this.removeQuestion = this.removeQuestion.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (event.target.value !== '-') {
                if (customArray.length === 3) {
                    switch (customArray[0]) {
                        case 'paidAmount':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'withdraw':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'debtSettlement':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debt':
                                    this.state.questionPallete[customArray[2]].context.debt = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'commission':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'chequeSettlement':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'recievables':
                                    this.state.questionPallete[customArray[2]].context.recievables = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'multipurchase':
                            let customInput = customArray[1].split("-")
                            switch (customInput[0]) {
                                case 'amountMultiple':
                                    this.state.questionPallete[customArray[2]].context.amount[parseInt(customInput[1])] = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;

                                case 'nameMultiple':
                                    this.state.questionPallete[customArray[2]].context.name[parseInt(customInput[1])] = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                    }
                }

                switch (event.target.name) {
                    case 'bookOwnerName':
                        this.setState({
                            bookOwnerName: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'year':
                        this.setState({
                            year: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                }
            }
        }

        nameSetter(name, newName, type) {
            if (type === 'purchase') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "purchaseReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            if (type === 'sales') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "order" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }

            if (type === 'preOrder') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "sales" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            this.setState({})
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'date':
                    this.setState({
                        tempDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'name':
                    this.setState({
                        tempName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amount':
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'reason':
                    this.setState({
                        tempReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'shares':
                    this.setState({
                        tempShares: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'brokerage':
                    this.setState({
                        tempBrokerage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'discount':
                    this.setState({
                        tempDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Tdiscount':
                    this.setState({
                        tempTDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Cdiscount':
                    this.setState({
                        tempCDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'purchasedFrom':
                    this.setState({
                        tempPurchasedFrom: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'billno':
                    this.setState({
                        tempBillno: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'credit':
                    this.setState({
                        tempCredit: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPercentage':
                    this.setState({
                        tempAmountPercentage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'invoiceNo':
                    this.setState({
                        tempInvoiceNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'debitNoteNo':
                    this.setState({
                        tempDebitNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'creditNoteNo':
                    this.setState({
                        tempCreditNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPaidImmediately':
                    this.setState({
                        tempAmountPaidImmediately: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'memo':
                    this.setState({
                        tempMemo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'assetName':
                    this.setState({
                        tempAssetName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmount':
                    this.setState({
                        tempExtraSum: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmountReason':
                    this.setState({
                        tempExtraSumReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashPurchase':
                    this.setState({
                        tempCashPurchase: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashSales':
                    this.setState({
                        tempCashSales: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            switch (this.state.addQuestionType) {
                case 'paidAmount':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempPayType !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'withdraw':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempPayType !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'debtSettlement':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempBrokerage !== '' && NumberRegex.test(this.state.tempBrokerage) &&
                        this.state.tempPayType !== '' &&
                        parseFloat(this.state.tempAmount) < parseFloat(this.state.tempBrokerage)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'commission':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempPayType !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'chequeSettlement':
                    if (this.state.tempDate !== '' && NormalNumberRegex.test(this.state.tempDate) && parseFloat(this.state.tempDate) > 0 && parseFloat(this.state.tempDate) < 32 &&
                        this.state.tempName !== '' &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempBrokerage !== '' && NumberRegex.test(this.state.tempBrokerage) &&
                        this.state.tempPayType !== '' &&
                        parseFloat(this.state.tempAmount) > parseFloat(this.state.tempBrokerage)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
            }
        }

        genderChange(event) {
            this.setState({
                bookOwnerGender: event.target.value
            })
        }

        monthChange(event) {
            this.setState({
                month: event.target.value
            })
        }

        questionTypeChange(event) {
            this.setState({
                addQuestionType: "none",
                tempPayType: '',
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempReason: '',
                tempShares: '',
                tempBrokerage: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                addQuestionChecker: false,
                addQuestionType: event.target.value
            })
        }

        payTypeChange(event) {
            this.setState({
                tempPayType: event.target.value
            }, () => { this.addButtonChecker() })
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.questionPallete) {
                switch (i.context.type) {
                    case 'paidAmount':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === ''
                        ) {
                            flag = false
                        }
                        break
                    case 'withdraw':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === ''
                        ) {
                            flag = false
                        }
                        break
                    case 'debtSettlement':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.debt === '' || !NumberRegex.test(i.context.debt) ||
                            i.context.name === ''
                        ) {
                            flag = false
                        }
                        break
                    case 'commission':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === ''
                        ) {
                            flag = false
                        }
                        break
                    case 'chequeSettlement':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.recievables === '' || !NumberRegex.test(i.context.recievables) ||
                            i.context.name === ''
                        ) {
                            flag = false
                        }
                        break
                    case 'multipurchase':
                        for (let j in i.context.name) {
                            if (i.context.name[j] === '') {
                                flag = false
                            }
                            if (i.context.amount[j] === '' || !NumberRegex.test(i.context.amount[j])) {
                                flag = false
                            }
                        }
                        break;
                }
            }
            if(this.state.bookOwnerName === ''){
                flag = false
            }
            if(this.state.year === '' || !NumberRegex.test(this.state.year)){
                flag = false
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: false,
                })
            } else {
                this.setState({
                    editModeButtonChecker: true,
                    submitChecker:false
                })
            }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            let leftSideArray = []
            let rightSideArray = []

            let monthNumber = ''

            switch (this.state.month) {
                case 'January':
                    monthNumber = '1'
                    break;
                case 'February':
                    monthNumber = '2'
                    break;
                case 'March':
                    monthNumber = '3'
                    break;
                case 'April':
                    monthNumber = '4'
                    break;
                case 'May':
                    monthNumber = '5'
                    break;
                case 'June':
                    monthNumber = '6'
                    break;
                case 'July':
                    monthNumber = '7'
                    break;
                case 'August':
                    monthNumber = '8'
                    break;
                case 'September':
                    monthNumber = '9'
                    break;
                case 'October':
                    monthNumber = '0'
                    break;
                case 'November':
                    monthNumber = '1'
                    break;
                case 'December':
                    monthNumber = '2'
                    break;
            }

            Object.values(this.state.questionPallete).forEach((x, i) => {
                switch (x.context.type) {
                    case 'multipurchase':
                        leftSideArray.push({
                            date: x.date,
                            receipts: "Balance",
                            discount: "-",
                            cash: x.context.amount[0],
                            bank: x.context.amount[1],
                            type: "Balance"
                        })
                        break;
                    case 'paidAmount':
                        if (x.context.style === 'cash') {
                            rightSideArray.push({
                                date: x.date,
                                receipts: x.context.name,
                                discount: "-",
                                cash: x.context.amount,
                                bank: "-"
                            })
                        } else {
                            rightSideArray.push({
                                date: x.date,
                                receipts: x.context.name,
                                discount: "-",
                                cash: "-",
                                bank: x.context.amount
                            })
                        }
                        break;
                    case 'withdraw':
                        if (x.context.style === 'cash') {
                            rightSideArray.push({
                                date: x.date,
                                receipts: "drawings",
                                discount: "-",
                                cash: x.context.amount,
                                bank: "-"
                            })
                        } else {
                            leftSideArray.push({
                                date: x.date,
                                receipts: "bank",
                                discount: "-",
                                cash: x.context.amount,
                                bank: "-"
                            })
                            rightSideArray.push({
                                date: x.date,
                                receipts: "cash",
                                discount: "-",
                                cash: "-",
                                bank: x.context.amount
                            })
                        }
                        break;
                    case 'debtSettlement':
                        if (x.context.style === 'cash') {
                            rightSideArray.push({
                                date: x.date,
                                receipts: x.context.name,
                                discount: parseFloat(x.context.debt) - parseFloat(x.context.amount),
                                cash: x.context.amount,
                                bank: "-"
                            })
                        } else {
                            rightSideArray.push({
                                date: x.date,
                                receipts: x.context.name,
                                discount: parseFloat(x.context.debt) - parseFloat(x.context.amount),
                                cash: "-",
                                bank: x.context.amount
                            })
                        }
                        break;
                    case 'commission':
                        if (x.context.style === 'cash') {
                            leftSideArray.push({
                                date: x.date,
                                receipts: "commission",
                                discount: "-",
                                cash: x.context.amount,
                                bank: "-"
                            })
                        } else {
                            leftSideArray.push({
                                date: x.date,
                                receipts: "commission",
                                discount: "-",
                                cash: "-",
                                bank: x.context.amount
                            })
                        }
                        break;
                    case 'chequeSettlement':
                        if (x.context.style === 'cash') {
                            leftSideArray.push({
                                date: x.date,
                                receipts: x.context.name,
                                discount: parseFloat(x.context.amount) - parseFloat(x.context.recievables),
                                cash: x.context.recievables,
                                bank: "-"
                            })
                        } else {
                            leftSideArray.push({
                                date: x.date,
                                receipts: x.context.name,
                                discount: parseFloat(x.context.amount) - parseFloat(x.context.recievables),
                                cash: "-",
                                bank: x.context.recievables
                            })
                        }
                        break;
                    default:
                        break;
                }
            })

            let leftSideTotal = {
                discount: 0,
                cash: 0,
                bank: 0
            }

            let rightSideTotal = {
                discount: 0,
                cash: 0,
                bank: 0
            }

            for (let i of leftSideArray) {
                if (i.discount !== '-') {
                    leftSideTotal.discount += parseFloat(i.discount)
                }
                if (i.cash !== '-') {
                    leftSideTotal.cash += parseFloat(i.cash)
                }
                if (i.bank !== '-') {
                    leftSideTotal.bank += parseFloat(i.bank)
                }
            }
            for (let i of rightSideArray) {
                if (i.discount !== '-') {
                    rightSideTotal.discount += parseFloat(i.discount)
                }
                if (i.cash !== '-') {
                    rightSideTotal.cash += parseFloat(i.cash)
                }
                if (i.bank !== '-') {
                    rightSideTotal.bank += parseFloat(i.bank)
                }
            }

            let rightSideDifferenceBetweenArray = {
                cash: leftSideTotal.cash - rightSideTotal.cash,
                bank: leftSideTotal.bank - rightSideTotal.bank
            }
            let leftSideDifferenceBetweenArray = {
                cash: rightSideTotal.cash - leftSideTotal.cash,
                bank: rightSideTotal.bank - leftSideTotal.bank
            }

            let newQuestionPallete = JSON.parse(JSON.stringify(this.state.questionPallete))

            Object.values(newQuestionPallete).forEach((x, i) => {
                if (x.context.type === 'withdraw' && x.context.style === 'cheque') {
                    newQuestionPallete.splice(i, 1)
                }
            })

            // console.log(JSON.stringify(this.state.questionPallete,null,4))


            // console.log(JSON.stringify(this.state.questionPallete, null, 4))

            // let dataObject = JSON.parse(JSON.stringify(this.state.questionPallete))

            let dataObject = {
                bookOwnerName: this.state.bookOwnerName,
                month: this.state.month,
                monthNumber,
                year: this.state.year,
                questionPallete: newQuestionPallete,
                leftSideArray,
                rightSideArray,
                leftSideTotal,
                rightSideTotal,
                rightSideDifferenceBetweenArray,
                leftSideDifferenceBetweenArray
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.clear()
            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addQuestion() {
            let adderFlag = true

            let temporaryTemplate = {
                questionType: this.state.addQuestionType,
                payType: this.state.tempPayType,
                date: parseFloat(this.state.tempDate),
                assetName: this.state.tempAssetName,
                name: this.state.tempName,
                amount: this.state.tempAmount,
                reason: this.state.tempReason,
                shares: this.state.tempShares,
                brokerage: this.state.tempBrokerage,
                amountPercentage: this.state.tempAmountPercentage,
                discount: this.state.tempDiscount,
                tradeDiscount: this.state.tempTDiscount,
                cashDiscount: this.state.tempCDiscount,
                purchasedFrom: this.state.tempPurchasedFrom,
                billno: this.state.tempBillno,
                invoiceNo: this.state.tempInvoiceNo,
                debitNoteNo: this.state.tempDebitNoteNo,
                creditNoteNo: this.state.tempCreditNoteNo,
                credit: this.state.tempCredit,
                memo: this.state.tempMemo,
                extraSum: this.state.tempExtraSum,
                extraSumReason: this.state.tempExtraSumReason,
                cashPurchase: this.state.tempCashPurchase,
                cashSales: this.state.tempCashSales,
                rebate: this.state.tempRebate,
                returneeName: this.state.tempReturneeName,

                amountPaidImmediately: this.state.tempAmountPaidImmediately
            }
            let questionObject
            let editModequestionObject

            switch (temporaryTemplate.questionType) {
                case 'paidAmount':
                    questionObject = {
                        questionType: "payment",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            style: temporaryTemplate.payType
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                    }
                    break;
                case 'withdraw':
                    questionObject = {
                        questionType: "withdraw",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            style: temporaryTemplate.payType
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                    }
                    break;
                case 'debtSettlement':
                    questionObject = {
                        questionType: "settlement",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            debt: temporaryTemplate.brokerage,
                            style: temporaryTemplate.payType
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        debt: false,
                    }
                    break;
                case 'commission':
                    questionObject = {
                        questionType: "received",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            style: temporaryTemplate.payType
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                    }
                    break;
                case 'chequeSettlement':
                    questionObject = {
                        questionType: "received",
                        context: {
                            type: temporaryTemplate.questionType,
                            name: temporaryTemplate.name,
                            amount: temporaryTemplate.amount,
                            recievables: temporaryTemplate.brokerage,
                            style: temporaryTemplate.payType
                        },
                        date: temporaryTemplate.date
                    }

                    editModequestionObject = {
                        name: false,
                        amount: false,
                        recievables: false,
                    }
                    break;
            }

            // if (adderFlag === true) {
            for (let i = this.state.questionPallete.length - 1; i >= 0; i--) {
                if (temporaryTemplate.date >= this.state.questionPallete[i].date) {
                    this.state.questionPallete.splice(i + 1, 0, questionObject)
                    this.state.editModequestionPalette.splice(i + 1, 0, editModequestionObject)
                    break;
                }
            }
            // }


            this.setState({})

            // console.log(JSON.stringify(questionObject,null,4),temporaryTemplate.date)
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPalette.splice(event, 1)

            this.setState({})
        }


        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 3) {
                switch (customArray[0]) {
                    case 'paidAmount':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'withdraw':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'debtSettlement':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'debt':
                                this.state.editModequestionPalette[customArray[2]].debt = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'commission':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'chequeSettlement':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'recievables':
                                this.state.editModequestionPalette[customArray[2]].recievables = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'multipurchase':
                        let customInput = customArray[1].split("-")
                        switch (customInput[0]) {
                            case 'amountMultiple':
                                this.state.editModequestionPalette[customArray[2]].amountMultiple[parseInt(customInput[1])] = true
                                this.setState({
                                    editModeButton: true
                                })
                                console.log(customInput)
                                break;
                            case 'nameMultiple':
                                this.state.editModequestionPalette[customArray[2]].nameMultiple[parseInt(customInput[1])] = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                }
            }

            switch (event.target.name) {
                case 'bookOwnerGender':
                    this.setState({
                        editModebookOwnerGender: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'bookOwnerName':
                    this.setState({
                        editModebookOwnerName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'year':
                    this.setState({
                        editModeyear: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'month':
                    this.setState({
                        editModemonth: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {

            for (let i of this.state.editModequestionPalette) {
                if (i.name) {
                    i.name = false
                }
                if (i.amount) {
                    i.amount = false
                }
                if (i.debt) {
                    i.debt = false
                }
                if (i.recievables) {
                    i.recievables = false
                }
                if (i.nameMultiple) {
                    for (let j in i.nameMultiple) {
                        i.nameMultiple[j] = false
                    }
                }
                if (i.amountMultiple) {
                    for (let j in i.amountMultiple) {
                        i.amountMultiple[j] = false
                    }
                }
            }


            this.setState({
                editModebookOwnerGender: false,
                editModebookOwnerName: false,
                editModemonth: false,
                editModeyear: false,

                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        નીચે આપેલ વ્યવહારોના આધારે&nbsp;

                        {this.state.editModebookOwnerName ? <TextField style={{ 'margin-top': "-15px", }} name="bookOwnerName" id='outlined-base' error={this.state.bookOwnerName === ''}
                            value={this.state.bookOwnerName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="bookOwnerName" onClick={this.buttonMaker.bind(this)}> {this.state.bookOwnerName}</a>},&nbsp;

                        માર્ટનો ત્રણ ખાનાંવાળો અઠવાડિક રોડમેળ તૈયાર કરી તેની ખાતાવહીમાં ખતવણી કરો.

                        <br /><br />
                        માસ :&nbsp;
                        {this.state.editModemonth ?
                            <FormControl style={{ width: "20%" }} size="small">
                                <InputLabel id="demo-simple-select-label">માસ</InputLabel>
                                <Select
                                    labelId="month"
                                    id="month"
                                    value={this.state.month}
                                    label="Month"
                                    onChange={this.monthChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"જાન્યુઆરી"}>જાન્યુઆરી</MenuItem>
                                    <MenuItem value={"ફેબ્રુઆરી"}>ફેબ્રુઆરી</MenuItem>
                                    <MenuItem value={"માર્ચ"}>માર્ચ</MenuItem>
                                    <MenuItem value={"એપ્રિલ"}>એપ્રિલ</MenuItem>
                                    <MenuItem value={"મે"}>મે</MenuItem>
                                    <MenuItem value={"જૂન"}>જૂન</MenuItem>
                                    <MenuItem value={"જુલાઈ"}>જુલાઈ</MenuItem>
                                    <MenuItem value={"ઓગસ્ટ"}>ઓગસ્ટ</MenuItem>
                                    <MenuItem value={"સપ્ટેમ્બર"}>સપ્ટેમ્બર</MenuItem>
                                    <MenuItem value={"ઓક્ટોબર"}>ઓક્ટોબર</MenuItem>
                                    <MenuItem value={"નવેમ્બર"}>નવેમ્બર</MenuItem>
                                    <MenuItem value={"ડિસેમ્બર"}>ડિસેમ્બર</MenuItem>
                                </Select>
                            </FormControl> :
                            <a className='txt' name="month" onClick={this.buttonMaker.bind(this)}> {this.state.month}</a>
                        }
                        &nbsp;&nbsp;વર્ષ :&nbsp;
                        {this.state.editModeyear ? <TextField style={{ 'margin-top': "-15px", }} name="year" id='outlined-base' error={this.state.year === '' || isNaN(this.state.year) || parseInt(this.state.year) < 1000 || parseInt(this.state.year) > 9000}
                            value={this.state.year} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="year" onClick={this.buttonMaker.bind(this)}> {this.state.year}</a>}.
                        <br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)} >Edit Mode Off</ColorButton> : <span></span>}

                        <br /><br />
                        તારીખ<br /><br />
                        <div label="totalQuestionsInArray">
                            {this.state.questionPallete.map((x, i) => (
                                <div>
                                    <span>{x.date}</span>&emsp;&emsp;
                                    {x.context.type === 'multipurchase' &&
                                        <span>
                                            શરૂઆતની&nbsp;
                                            {x.context.name.map((y, j) => (
                                                <span>
                                                    {x.context.name[j]} ₹&nbsp;

                                                    {this.state.editModequestionPalette[i].amountMultiple[j] ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["multipurchase", "amountMultiple-" + j, i]} id='outlined-base' error={x.context.amount[j] === '' || isNaN(x.context.amount[j])}
                                                        value={x.context.amount[j]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["multipurchase", "amountMultiple-" + j, i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount[j]}</a>}&nbsp;

                                                    {/* {x.context.amount[j]} */}

                                                    {parseInt(j) !== x.context.name.length - 1 ? parseInt(j) !== x.context.name.length - 2 ? ", " : "" : "."}
                                                    &nbsp;
                                                </span>
                                            ))}
                                        </span>
                                    }
                                    {x.context.type === 'paidAmount' &&
                                        <span>
                                            &nbsp;
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["paidAmount", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidAmount", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["paidAmount", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidAmount", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            {x.context.style === 'cheque' && "ચૂકવ્યો"}
                                            {x.context.style === 'cash' && "ચૂકવ્યો"}.
                                        </span>
                                    }
                                    {x.context.type === 'withdraw' &&
                                        <span>
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["withdraw", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["withdraw", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            માટે {x.context.style === 'cheque' ? "બૅન્કમાંથી" : "ધંધામાંથી"} &nbsp;

                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["withdraw", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["withdraw", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            ઉપાડ્યા.
                                        </span>
                                    }
                                    {x.context.type === 'debtSettlement' &&
                                        <span>
                                            {x.context.style === 'cash' && "by cash"}
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debtSettlement", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debtSettlement", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>} ને&nbsp;

                                                ₹&nbsp;
                                            {this.state.editModequestionPalette[i].debt ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debtSettlement", "debt", i]} id='outlined-base' error={x.context.debt === '' || isNaN(x.context.debt)}
                                                value={x.context.debt} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debtSettlement", "debt", i]} onClick={this.buttonMaker.bind(this)}> {x.context.debt}</a>} ના દેવા પેટે&nbsp;

                                           ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["debtSettlement", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["debtSettlement", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;
                                                  {x.context.style === 'cheque' && "નો ચેક આપી"} ખાતું ચૂક્તે કર્યું.                                       
                                        </span>
                                    }
                                    {x.context.type === 'commission' &&
                                        <span>
                                           
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["commission", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["commission", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;

                                            પાસેથી ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["commission", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["commission", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>} કમિશન પેટે &nbsp;

                                            {x.context.style === 'cash' && "રોકડા મળ્યા"}
                                            {x.context.style === 'cheque' && 'by cheque'}&nbsp;
                                            
                                        </span>
                                    }
                                    {x.context.type === 'chequeSettlement' &&
                                        <span>
                                            {x.context.style === 'cash' && "by cash"} 
                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["chequeSettlement", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["chequeSettlement", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>} પાસેથી&nbsp;

                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["chequeSettlement", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["chequeSettlement", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;
                                                {/* Received {x.context.style === 'cheque' && "a cheque of"}  */}
                                            
                                            ના લેણા પેટે ચૂક્તે હિસાબે ₹&nbsp;
                                            {this.state.editModequestionPalette[i].recievables ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["chequeSettlement", "recievables", i]} id='outlined-base' error={x.context.recievables === '' || isNaN(x.context.recievables)}
                                                value={x.context.recievables} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["chequeSettlement", "recievables", i]} onClick={this.buttonMaker.bind(this)}> {x.context.recievables}</a>}&nbsp;
                                                 {/* Received {x.context.style === 'cheque' && "a cheque of"} */}

                                            {x.context.style === 'cheque' && "નો ચેક મળ્યો જે બૅન્કમાં ભર્યા."}

                                        </span>
                                    }

                                    {i !== 0 && <span>
                                        <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeQuestion.bind(this, i)} name="-">-</IconButton>
                                    </span>}
                                    <br /><br />
                                </div>
                            ))}
                        </div>

                        <div label="addQuestionInputs">
                            નવો પ્રશ્ન ઉમેરો :<br /><br /><br />
                            પ્રશ્નનો પ્રકાર: 
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">પ્રશ્ન</InputLabel>
                                <Select
                                     labelId="question"
                                    id="question"
                                    value={this.state.addQuestionType}
                                    label="Question"
                                    onChange={this.questionTypeChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"none"}>None</MenuItem>
                                    <MenuItem value={"paidAmount"}>ચૂકવેલ ખર્ચ</MenuItem>
                                    <MenuItem value={"withdraw"}>ઉપાડો</MenuItem>
                                    <MenuItem value={"debtSettlement"}>ચૂકવેલ દેવું પતાવટ</MenuItem>
                                    <MenuItem value={"commission"}>કમિશન મેળવ્યું</MenuItem>
                                    <MenuItem value={"chequeSettlement"}>રિસીવ્ડ સેટલમેન્ટ</MenuItem>

                                </Select>
                            </FormControl><br /><br /><br />

                            {this.state.addQuestionType === 'paidAmount' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ચૂકવેલ ખર્ચ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    પે ટાઈપ દાખલ કરો:
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempPayType === ''}
                                            value={this.state.tempPayType}
                                            label="Name"
                                            onChange={this.payTypeChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value='cash'>રોકડા માં</MenuItem>
                                            <MenuItem value='cheque'>ચેક દ્વારા</MenuItem>

                                        </Select>
                                    </FormControl><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'withdraw' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ખર્ચનું નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    પે ટાઈપ દાખલ કરો:
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempPayType === ''}
                                            value={this.state.tempPayType}
                                            label="Name"
                                            onChange={this.payTypeChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value='cash'>બિઝનેસમાંથી</MenuItem>
                                            <MenuItem value='cheque'>બેંકમાંથી</MenuItem>

                                        </Select>
                                    </FormControl><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'debtSettlement' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    દેવું દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="brokerage" error={this.state.tempBrokerage === '' || isNaN(this.state.tempBrokerage)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    પે ટાઈપ દાખલ કરો:
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempPayType === ''}
                                            value={this.state.tempPayType}
                                            label="Name"
                                            onChange={this.payTypeChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value='cash'>રોકડા માં</MenuItem>
                                            <MenuItem value='cheque'>ચેક દ્વારા</MenuItem>

                                        </Select>
                                    </FormControl><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'commission' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    પે ટાઈપ દાખલ કરો:
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempPayType === ''}
                                            value={this.state.tempPayType}
                                            label="Name"
                                            onChange={this.payTypeChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value='cash'>રોકડા માં</MenuItem>
                                            <MenuItem value='cheque'>ચેક દ્વારા</MenuItem>

                                        </Select>
                                    </FormControl><br /><br />

                                </span>
                            }
                            {this.state.addQuestionType === 'chequeSettlement' &&
                                <span>
                                    તારીખ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="date" error={this.state.tempDate === '' || isNaN(this.state.tempDate) || this.state.tempDate < 0 || this.state.tempDate > 31}
                                        placeholder="તારીખ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    નામ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="name" error={this.state.tempName === ''}
                                        placeholder="નામ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    પ્રાપ્ત રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="brokerage" error={this.state.tempBrokerage === '' || isNaN(this.state.tempBrokerage)}
                                        placeholder="રકમ દાખલ કરોt*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    પે ટાઈપ દાખલ કરો:
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Name*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempPayType === ''}
                                            value={this.state.tempPayType}
                                            label="Name"
                                            onChange={this.payTypeChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value='cash'>રોકડા માં</MenuItem>
                                            <MenuItem value='cheque'>ચેક દ્વારા</MenuItem>

                                        </Select>
                                    </FormControl><br /><br />

                                </span>
                            }

                            {this.state.addQuestionType !== 'none' && <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%", fontSize:"20px" }} onClick={this.addQuestion.bind(this)}>નવો પ્રશ્ન ઉમેરો</AddButton>}
                        </div>

                        <br /><br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px", fontSize:"15px" }} align='right' onClick={this.editModeOff.bind(this)} >સંપાદિત મોડ બંધ કરો</ColorButton> : <span></span>}


                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                        </div>
                        <div align='right'>
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                        <br />
                        <br />

                    </div>
                    <br /><br />
                    {
                        this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>જવાબ :</b><br /><br />
                            <div align='center'><b>{data[chartState.selected].bookOwnerName} નો ત્રણ ખાનાંવાળો રોકડમેળ</b></div>
                            <span style={{ float: "left" }}>ઉધાર</span><span style={{ float: "right" }}>જમા</span>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>તારીખ </StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>વિગત <br/>(આય)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>આપેલ વટાવ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>રોકડ રકમ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>બૅન્ક રકમ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>વિગત <br/> (આય)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>મળેલ વટાવ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>રોકડ રકમ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>બૅન્ક રકમ (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center'>{data[chartState.selected].month} {data[chartState.selected].year}</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='center'>{data[chartState.selected].month} {data[chartState.selected].year}</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].leftSideArray.length >= data[chartState.selected].rightSideArray.length ?
                                            data[chartState.selected].leftSideArray.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell align='center'>{data[chartState.selected].leftSideArray[i].date}</StyledTableCell>
                                                    <StyledTableCell align='left' style={{fontSize:"15px"}}>{data[chartState.selected].leftSideArray[i].receipts}{i === 0 ? "આગળ લાવ્યા" : "ખાતે"}</StyledTableCell>
                                                    <StyledTableCell align='right'>{data[chartState.selected].leftSideArray[i].discount}</StyledTableCell>
                                                    <StyledTableCell align='right'>{data[chartState.selected].leftSideArray[i].cash}</StyledTableCell>
                                                    <StyledTableCell align='right'>{data[chartState.selected].leftSideArray[i].bank}</StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {data[chartState.selected].rightSideArray[i] &&
                                                            <span>
                                                                {data[chartState.selected].rightSideArray[i].date}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                        {data[chartState.selected].rightSideArray[i] &&
                                                            <span>
                                                                {data[chartState.selected].rightSideArray[i].receipts} ખાતે
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {data[chartState.selected].rightSideArray[i] &&
                                                            <span>
                                                                {data[chartState.selected].rightSideArray[i].discount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {data[chartState.selected].rightSideArray[i] &&
                                                            <span>
                                                                {data[chartState.selected].rightSideArray[i].cash}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {data[chartState.selected].rightSideArray[i] &&
                                                            <span>
                                                                {data[chartState.selected].rightSideArray[i].bank}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                </TableRow>
                                            )) :
                                            data[chartState.selected].rightSideArray.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell align="center">
                                                        {data[chartState.selected].leftSideArray[i] &&
                                                            <span>
                                                                {data[chartState.selected].leftSideArray[i].date}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                        {data[chartState.selected].leftSideArray[i] &&
                                                            <span>
                                                                {data[chartState.selected].leftSideArray[i].receipts} {i === 0 ? "આગળ લાવ્યા" : "ખાતે"}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {data[chartState.selected].leftSideArray[i] &&
                                                            <span>
                                                                {data[chartState.selected].leftSideArray[i].discount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {data[chartState.selected].leftSideArray[i] &&
                                                            <span>
                                                                {data[chartState.selected].leftSideArray[i].cash}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {data[chartState.selected].leftSideArray[i] &&
                                                            <span>
                                                                {data[chartState.selected].leftSideArray[i].bank}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='center'>{data[chartState.selected].rightSideArray[i].date}</StyledTableCell>
                                                    <StyledTableCell align='left' style={{fontSize:"15px"}}>{data[chartState.selected].rightSideArray[i].receipts} ખાતે</StyledTableCell>
                                                    <StyledTableCell align='right'>{data[chartState.selected].rightSideArray[i].discount}</StyledTableCell>
                                                    <StyledTableCell align='right'>{data[chartState.selected].rightSideArray[i].cash}</StyledTableCell>
                                                    <StyledTableCell align='right'>{data[chartState.selected].rightSideArray[i].bank}</StyledTableCell>
                                                </TableRow>
                                            ))
                                        }
                                        <TableRow>
                                            <StyledTableCell align='center'>{data[chartState.selected].questionPallete[data[chartState.selected].questionPallete.length - 1].date}</StyledTableCell>
                                            <StyledTableCell>To Balance d/f</StyledTableCell>
                                            <StyledTableCell align='right'>-</StyledTableCell>
                                            <StyledTableCell align='right'>
                                                {data[chartState.selected].leftSideDifferenceBetweenArray.cash > 0 ?
                                                    data[chartState.selected].leftSideDifferenceBetweenArray.cash : "-"}
                                            </StyledTableCell>
                                            <StyledTableCell align='right'>
                                                {data[chartState.selected].leftSideDifferenceBetweenArray.bank > 0 ?
                                                    data[chartState.selected].leftSideDifferenceBetweenArray.bank : "-"}
                                            </StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell style={{fontSize:"15px"}}>બાકી આગળ લઈ ગયા</StyledTableCell>
                                            <StyledTableCell align='right'>-</StyledTableCell>
                                            <StyledTableCell align='right'>
                                                {data[chartState.selected].rightSideDifferenceBetweenArray.cash > 0 ?
                                                    data[chartState.selected].rightSideDifferenceBetweenArray.cash : "-"}
                                            </StyledTableCell>
                                            <StyledTableCell align='right'>
                                                {data[chartState.selected].rightSideDifferenceBetweenArray.bank > 0 ?
                                                    data[chartState.selected].rightSideDifferenceBetweenArray.bank : "-"}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].leftSideTotal.discount}</b></StyledTableCell>
                                            <StyledTableCell align='right'>
                                                <b>
                                                    {data[chartState.selected].leftSideTotal.cash >= data[chartState.selected].rightSideTotal.cash ?
                                                        data[chartState.selected].leftSideTotal.cash :
                                                        data[chartState.selected].rightSideTotal.cash
                                                    }
                                                </b>
                                            </StyledTableCell>
                                            <StyledTableCell align='right'>
                                                <b>
                                                    {data[chartState.selected].leftSideTotal.bank >= data[chartState.selected].rightSideTotal.bank ?
                                                        data[chartState.selected].leftSideTotal.bank :
                                                        data[chartState.selected].rightSideTotal.bank
                                                    }
                                                </b>
                                            </StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].rightSideTotal.discount}</b></StyledTableCell>
                                            <StyledTableCell align='right'>
                                                <b>
                                                    {data[chartState.selected].leftSideTotal.cash >= data[chartState.selected].rightSideTotal.cash ?
                                                        data[chartState.selected].leftSideTotal.cash :
                                                        data[chartState.selected].rightSideTotal.cash
                                                    }
                                                </b>
                                            </StyledTableCell>
                                            <StyledTableCell align='right'>
                                                <b>
                                                    {data[chartState.selected].leftSideTotal.bank >= data[chartState.selected].rightSideTotal.bank ?
                                                        data[chartState.selected].leftSideTotal.bank :
                                                        data[chartState.selected].rightSideTotal.bank
                                                    }
                                                </b>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableBody>

                                </Table>
                            </TableContainer><br /><br />

                            <div align='center'><b>{data[chartState.selected].bookOwnerName} ની ખાતાવહી</b></div>
                            {data[chartState.selected].questionPallete.map((x, i) => (
                                i !== 0 &&
                                <div>
                                    <div align='center'>
                                        <b>
                                            {x.context.type === 'commission' ? "કમિશન" :
                                                x.context.type === 'withdraw' ? "ઉપાડ" :
                                                    x.context.name
                                            } ખાતું
                                        </b>
                                    </div>
                                    <span style={{ float: "left" }}>ઉધાર</span><span style={{ float: "right" }}>જમા</span>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center" style={{fontSize:"20px"}}>તારીખ</StyledTableCell>
                                                    <StyledTableCell align="left" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                                    <StyledTableCell align="right" style={{fontSize:"20px"}}>રકમ (₹)</StyledTableCell>
                                                    <StyledTableCell align="center" style={{fontSize:"20px"}}>તારીખ</StyledTableCell>
                                                    <StyledTableCell align="left" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                                    <StyledTableCell align="right" style={{fontSize:"20px"}}>રકમ (₹)</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {x.context.type === 'paidAmount' &&
                                                    <TableRow>
                                                        <StyledTableCell align='center'>
                                                            {x.date + "-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='left' style={{fontSize:"15px"}}>
                                                            {x.context.style === 'cash' ? "રોકડ" : "બૅન્ક"} ખાતે
                                                        </StyledTableCell>
                                                        <StyledTableCell align='right'>{x.context.amount}</StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                    </TableRow>
                                                }
                                                {x.context.type === 'debtSettlement' &&
                                                    <TableRow>
                                                        <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                            {x.date + "-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}<br />
                                                            {x.date + "-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='left' style={{ verticalAlign: "top", fontSize:"15px" }}>
                                                            {x.context.style === 'cash' ? "રોકડ" : "બૅન્ક"} ખાતે<br />
                                                            વટાવ ખાતે
                                                        </StyledTableCell>
                                                        <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                            {x.context.amount}<br />
                                                            {parseFloat(x.context.debt) - parseFloat(x.context.amount)}<br />
                                                            <b>{x.context.debt}</b>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                            {"1-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}<br />
                                                        </StyledTableCell>
                                                        <StyledTableCell align='left' style={{ verticalAlign: "top", fontSize:"15px" }}>
                                                            બાકી આગળ લાવ્યા
                                                        </StyledTableCell>
                                                        <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                            {x.context.debt}<br />
                                                            <br />
                                                            <b>{x.context.debt}</b>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                }
                                                {x.context.type === 'commission' &&
                                                    <TableRow>
                                                        <StyledTableCell></StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            {x.date + "-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='left' style={{fontSize:"15px"}}>
                                                            {x.context.style === 'cash' ? "રોકડ" : "બૅન્ક"} ખાતે
                                                        </StyledTableCell>
                                                        <StyledTableCell align='right'>{x.context.amount}</StyledTableCell>
                                                    </TableRow>
                                                }
                                                {x.context.type === 'chequeSettlement' &&
                                                    <TableRow>
                                                        <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                            {"1-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}<br />
                                                        </StyledTableCell>
                                                        <StyledTableCell align='left' style={{ verticalAlign: "top", fontSize:"15px" }}>
                                                        બાકી આગળ લાવ્યા
                                                        </StyledTableCell>
                                                        <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                            {x.context.amount}<br />
                                                            <br />
                                                            <b>{x.context.amount}</b>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                            {x.date + "-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}<br />
                                                            {x.date + "-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='left' style={{ verticalAlign: "top", fontSize:"16px" }}>
                                                            {x.context.style === 'cash' ? "રોકડ" : "બૅન્ક"} ખાતે<br />
                                                            વટાવ ખાતે
                                                        </StyledTableCell>
                                                        <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                            {x.context.recievables}<br />
                                                            {parseFloat(x.context.amount) - parseFloat(x.context.recievables)}<br />
                                                            <b>{x.context.amount}</b>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                }
                                                {x.context.type === 'withdraw' &&
                                                    <TableRow>
                                                        <StyledTableCell align='center'>
                                                            {x.date + "-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='left' style={{fontSize:"15px"}}>
                                                            {x.context.style === 'cash' ? "રોકડ" : "બૅન્ક"} ખાતે
                                                        </StyledTableCell>
                                                        <StyledTableCell align='right'>{x.context.amount}</StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer><br /><br />
                                </div>
                            ))}

                            <div align='center'><b>આપેલ વટાવનું ખાતું</b></div>
                            <span style={{ float: "left" }}>ઉધાર</span><span style={{ float: "right" }}>જમા</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="left" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="right" style={{fontSize:"20px"}}>રકમ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="left" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="right" style={{fontSize:"20px"}}>રકમ (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center'>
                                                {data[chartState.selected].questionPallete[data[chartState.selected].questionPallete.length - 1].date + "-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}
                                            </StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize:"15px"}}>
                                                કુલ આપેલ વટાવ <br />
                                                (રોકડમેળ મુજબ)
                                            </StyledTableCell>
                                            <StyledTableCell align='right'>{data[chartState.selected].leftSideTotal.discount}</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align='center'><b>મળેલ વટાવનું ખાતું</b></div>
                            <span style={{ float: "left" }}>ઉધાર</span><span style={{ float: "right" }}>જમા</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="left" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="right" style={{fontSize:"20px"}}>રકમ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="left" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="right" style={{fontSize:"20px"}}>રકમ (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='center'>
                                                {data[chartState.selected].questionPallete[data[chartState.selected].questionPallete.length - 1].date + "-" + data[chartState.selected].monthNumber + "-" + String(data[chartState.selected].year).slice(2,)}
                                            </StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize:"15px"}}>
                                                કુલ મળેલ વટાવ <br />
                                                (રોકડમેળ મુજબ)
                                            </StyledTableCell>
                                            <StyledTableCell align='right'>{data[chartState.selected].rightSideTotal.discount}</StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                        </div>
                    }
                    <br />
                    <br />
                </div >
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

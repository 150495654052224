import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
	AddButton,
	BootstrapTextField,
	ColorButton,
	CustomColorButton,
	DeleteButton,
	LinkButton,
	RedditTextField,
	ResponseButton,
	ReverseLinkButton,
	StyledGrid,
	StyledTableCell,
	StyledTableRow
} from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, BarChart,Bar, Tooltip, CartesianGrid } from 'recharts';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import 'react-datepicker/dist/react-datepicker.css';

export default function ComparativeProfitLossStatement() {
	const data = [
		{
			questionPalette: [
				{
                    name:"Agriculture",
					price: 10,
					demand: 30
				},
				{
                    name:"Industry",
					price: 15,
					demand: 25
				},
				{
                    name:"Service",
					price: 20,
					demand: 20
				}
			]
		}
	];

	const NormalNumberRegex = new RegExp(/^[0-9]+$/);

	const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);

	const chartState = {
		selected: 0
	};

	// const [value, onChange] = useState(new Date());
	// const [value2, onChange2] = useState(new Date());

	class OperatingRatio extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				name: 'price',
				type: 'Employment',
				nameOne: 'China',
				nameTwo: 'India',

				travelTime: [
					{
						name: 'Agriculture',
						price: 10,
						demand: 30
					},
					{
						name: 'Industry',
						price: 15,
						demand: 25
					},
					{
						name: 'Service',
						price: 20,
						demand: 20
					}
				],

				editModetravelTime: [
					{
						name: false,
						price: false,
						demand: false
					},
					{
						name: false,
						price: false,
						demand: false
					},
					{
						name: false,
						price: false,
						demand: false
					}
				],

				editModename: false,
				editModetype: false,
				editModenameOne: false,
				editModenameTwo: false,

				solutionShow: false,
				// tableModeChartModeSwitch: true,
				editModeButtonChecker: false,
				editModeButton: false,
				submitChecker: true
			};
			this.eventHandler = this.eventHandler.bind(this);
			this.editModeOff = this.editModeOff.bind(this);
			this.autoSave = this.autoSave.bind(this);
			this.moveLeftAndRight = this.moveLeftAndRight.bind(this);
		}

		//#region EVENT FUNCTIONALITIES START

		eventHandler(event) {
			let customArray = event.target.name.split(',');

			if (customArray.length === 2) {
				switch (customArray[0]) {
					case 'price':
						this.state.travelTime[customArray[1]].price = event.target.value;
						this.setState({}, () => {
							this.fillDetails();
						});
						break;
					case 'demand':
						this.state.travelTime[customArray[1]].demand = event.target.value;
						this.setState({}, () => {
							this.fillDetails();
						});
						break;
					case 'name':
						this.state.travelTime[customArray[1]].name = event.target.value;
						this.setState({}, () => {
							this.fillDetails();
						});
						break;
				}
			}

			switch (event.target.name) {
				case 'name':
					this.setState(
						{
							name: event.target.value
						},
						() => {
							this.fillDetails();
						}
					);
					break;
				case 'nameOne':
					this.setState(
						{
							nameOne: event.target.value
						},
						() => {
							this.fillDetails();
						}
					);
					break;
				case 'nameTwo':
					this.setState(
						{
							nameTwo: event.target.value
						},
						() => {
							this.fillDetails();
						}
					);
					break;
			}
		}

		handleChange(event) {
			this.setState({
				type: event.target.value
			});
		}

		fillDetails() {
			let flag = true;

			for (let i of this.state.travelTime) {
				if (!NumberRegex.test(i.demand) || i.demand === '') {
					flag = false;
				}
				if (!NumberRegex.test(i.price) || i.price === '') {
					flag = false;
				}
			}

			if (flag === true) {
				this.setState({
					editModeButtonChecker: !flag
				});
			} else {
				this.setState({
					editModeButtonChecker: !flag
				});
			}
		}

		autoSave(event) {}

		//#endregion EVENT FUNCTIONALTIES END

		//#region GREATEST COMMON DIVISOR START

		gcd(a, b) {
			if (a == 0) return b;
			return this.gcd(b % a, a);
		}

		findGCD(arr, n) {
			let result = arr[0];
			for (let i = 1; i < n; i++) {
				result = this.gcd(arr[i], result);

				if (result == 1) {
					return 1;
				}
			}
			return result;
		}

		//#endregion GREATEST COMMON DIVISOR END

		//#region BUTTON PANEL START

		onSubmit() {
			// let timeArray = []

			// for (let i of this.state.travelTime) {
			//     timeArray.push(i.time)
			// }

			// let gcdTime = this.findGCD(timeArray, timeArray.length)

			// timeArray = timeArray.sort((a, b) => { return a - b })

			// let assumedA = timeArray[Math.floor(timeArray.length / 2)]

			// let answerPallete = []

			// let totaldays = 0
			// let totalfd = 0

			// for (let i of this.state.travelTime) {
			//     answerPallete.push({
			//         time: i.time,
			//         days: i.days,
			//         f: Math.round(((parseFloat(i.time) - assumedA) / gcdTime) * 100) / 100,
			//         fd: i.days * Math.round(((parseFloat(i.time) - assumedA) / gcdTime) * 100) / 100
			//     })
			//     totaldays += i.days
			//     totalfd += i.days * Math.round(((parseFloat(i.time) - assumedA) / gcdTime) * 100) / 100
			// }

			let dataObject = {
				questionPalette: this.state.travelTime
				// assumedA,
				// totaldays,
				// totalfd,
				// gcdTime,
				// mean: assumedA + Math.round(((totalfd / totaldays) * gcdTime) * 100) / 100,
				// type:this.state.type
			};

			data.push(JSON.parse(JSON.stringify(dataObject)));

			chartState.selected += 1;

			this.setState({});

			// console.log(JSON.stringify(dataObject, null, 4));
		}

		onReset() {}

		addRow() {
			this.state.travelTime.push({
				name: 'Enter Text Here',
				price: 100,
				demand: 10
			});
			this.state.editModetravelTime.push({
				name: true,
				price: true,
				demand: true
			});
			this.setState({
				editModeButton: true
			});
		}

		removeRow(event) {
			this.state.travelTime.splice(event, 1);
			this.state.editModetravelTime.splice(event, 1);
			this.setState({});
		}

		switchTableToChartAndBack() {}

		showSolutionOnOff() {
			this.setState({
				solutionShow: !this.state.solutionShow
			});
		}

		moveLeftAndRight(event) {
			if (event.target.name === 'Left') {
				chartState.selected = chartState.selected - 1;
				this.setState({});
			} else if (event.target.name === 'Right') {
				chartState.selected += 1;
				this.setState({});
			}
		}

		//#endregion BUTTON PANEL END

		//#region EDIT BUTTON START

		buttonMaker(event) {
			let customArray = event.target.name.split(',');

			if (customArray.length === 2) {
				switch (customArray[0]) {
					case 'price':
						this.state.editModetravelTime[customArray[1]].price = true;
						this.setState({
							editModeButton: true,
							submitChecker: false
						});
						break;
					case 'demand':
						this.state.editModetravelTime[customArray[1]].demand = true;
						this.setState({
							editModeButton: true,
							submitChecker: false
						});
						break;
					case 'name':
						this.state.editModetravelTime[customArray[1]].name = true;
						this.setState({
							editModeButton: true,
							submitChecker: false
						});
						break;
				}
			}

			switch (event.target.name) {
				case 'type':
					this.setState({
						editModetype: true,
						editModeButton: true,
						submitChecker: false
					});
					break;
				case 'nameOne':
					this.setState({
						editModenameOne: true,
						editModeButton: true,
						submitChecker: false
					});
					break;
				case 'nameTwo':
					this.setState({
						editModenameTwo: true,
						editModeButton: true,
						submitChecker: false
					});
					break;
			}
		}

		editModeOff() {
			for (let i of this.state.editModetravelTime) {
				i.demand = false;
				i.price = false;
				i.name = false;
			}

			this.setState({
				submitChecker: true,

				editModename: false,
				editModenameOne: false,
				editModenameTwo: false,

				editModetype: false,
				editModesampleString: false,
				editModeButton: false
			});
		}

		//#endregion EDIT BUTTON END

		render() {
			return (
				<div>
					<br />
					<div label="InputFieldTables" align="left">
						 The Cluster Bar Diagram for folowing data of &nbsp; 
						 {this.state.editModetype ?
                            <FormControl style={{ width: "9%" }}>
                                <InputLabel id="demo-simple-select-label">Option to Add</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.type}
                                    label="Option to Add"
                                    onChange={this.handleChange.bind(this)}
                                >
                                    <MenuItem value={"Employment"}>Employment</MenuItem>
                                    <MenuItem value={"Food"}>Food</MenuItem>
                                    <MenuItem value={"Demand"}>Demand</MenuItem>
                                    <MenuItem value={"Industry"}>Industry</MenuItem>
                                </Select>
                            </FormControl> :
                            <a className='txt' name="type" onClick={this.buttonMaker.bind(this)}>({this.state.type})</a>}&nbsp; 
					 in three main sector of economy in country wise and analysis.
						<br />
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 700 }} aria-label="customized table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="center">Sectors</StyledTableCell>
										<StyledTableCell align="center">
											{this.state.editModenameOne ? (
												<TextField
													style={{ width: '30%', padding: '-10px' }}
													name="nameOne"
													id="outlined-base"
													error={this.state.nameOne === ''}
													value={this.state.nameOne}
													onChange={this.eventHandler}
													onKeyDown={this.autoSave}
													variant="standard"
													autoComplete="off"
												/>
											) : (
												<a className="txt" name="nameOne" onClick={this.buttonMaker.bind(this)}>
													{' '}
													{this.state.nameOne}
												</a>
											)}
										</StyledTableCell>
										<StyledTableCell align="center">
											{this.state.editModenameTwo ? (
												<TextField
													style={{ width: '30%', padding: '-10px' }}
													name="nameTwo"
													id="outlined-base"
													error={this.state.nameTwo === ''}
													value={this.state.nameTwo}
													onChange={this.eventHandler}
													onKeyDown={this.autoSave}
													variant="standard"
													autoComplete="off"
												/>
											) : (
												<a className="txt" name="nameTwo" onClick={this.buttonMaker.bind(this)}>
													{' '}
													{this.state.nameTwo}
												</a>
											)}
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.travelTime.map((x, i) => (
										<TableRow key={i}>
											<StyledTableCell align="center">
												{this.state.editModetravelTime[i].name ? (
													<TextField
														style={{ width: '30%', padding: '-10px' }}
														name={[ 'name', i ]}
														id="outlined-base"
														error={x.name === ''}
														value={x.name}
														onChange={this.eventHandler}
														onKeyDown={this.autoSave}
														variant="standard"
														autoComplete="off"
													/>
												) : (
													<a
														className="txt"
														name={[ 'name', i ]}
														onClick={this.buttonMaker.bind(this)}
													>
														{' '}
														{x.name}
													</a>
												)}
												{this.state.travelTime.length > 2 && (
													<IconButton
														style={{ width: '3%', color: 'red' }}
														onClick={this.removeRow.bind(this, i)}
														name="-"
													>
														-
													</IconButton>
												)}
											</StyledTableCell>
											<StyledTableCell align="center">
												{this.state.editModetravelTime[i].price ? (
													<TextField
														style={{ width: '20%', padding: '-10px' }}
														name={[ 'price', i ]}
														id="outlined-base"
														error={x.price === '' || isNaN(x.price)}
														value={x.price}
														onChange={this.eventHandler}
														onKeyDown={this.autoSave}
														variant="standard"
														autoComplete="off"
													/>
												) : (
													<a
														className="txt"
														name={[ 'price', i ]}
														onClick={this.buttonMaker.bind(this)}
													>
														{' '}
														{x.price}
													</a>
												)}&nbsp;
											</StyledTableCell>
											<StyledTableCell align="center">
												{this.state.editModetravelTime[i].demand ? (
													<TextField
														style={{ width: '20%', padding: '-10px' }}
														name={[ 'demand', i ]}
														id="outlined-base"
														error={x.demand === '' || isNaN(x.demand)}
														value={x.demand}
														onChange={this.eventHandler}
														onKeyDown={this.autoSave}
														variant="standard"
														autoComplete="off"
													/>
												) : (
													<a
														className="txt"
														name={[ 'demand', i ]}
														onClick={this.buttonMaker.bind(this)}
													>
														{' '}
														{x.demand}
													</a>
												)}
											</StyledTableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<br />
						<br />
						<AddButton style={{ width: '15%' }} onClick={this.addRow.bind(this)}>
							ADD INSTANCE
						</AddButton>
						<br />
						<br />
						{/* <b>Find Mean Travel Time.</b> */}
						<br />
						<br />
						{this.state.editModeButton ? (
							<ColorButton
								disabled={this.state.editModeButtonChecker}
								style={{ 'margin-top': '-20px' }}
								align="right"
								onClick={this.editModeOff.bind(this)}
							>
								Edit Mode Off
							</ColorButton>
						) : (
							<span />
						)}
						<br />
						<br />
						<div align="left" label="buttons">
							<AddButton
								style={{ width: '15%' }}
								disabled={!this.state.submitChecker}
								onClick={this.onSubmit.bind(this)}
							>
								CALCULATE SUM
							</AddButton>
							&nbsp;&nbsp;&nbsp;
							{/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
							<AddButton style={{ width: '20%' }} onClick={this.showSolutionOnOff.bind(this)}>
								SHOW/HIDE SOLUTION
							</AddButton>
						</div>
						<div align="right" label="sumNavigator">
							<AddButton
								name="Left"
								onClick={this.moveLeftAndRight}
								disabled={chartState.selected - 1 < 0}
								style={{ width: '0%', minWidth: '34px' }}
							>
								◄
							</AddButton>
							&nbsp;&nbsp;&nbsp;
							{chartState.selected + 1}
							&nbsp;&nbsp;&nbsp;
							<AddButton
								name="Right"
								onClick={this.moveLeftAndRight}
								disabled={chartState.selected + 1 > data.length - 1}
								style={{ width: '0%', minWidth: '34px' }}
							>
								►
							</AddButton>
						</div>
					</div>
					<br />
					<br />
					{this.state.solutionShow && (
						<div label="Answer" align="left">
							<ResponsiveContainer width="100%" aspect={3}>
							
                                <BarChart width={500} height={300}  data={data[chartState.selected].questionPalette}  margin={{ top: 5, right: 30,left: 20, bottom: 5}}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis
										
										label={{ value: `<-- Sectors -->`, dy: 13 }}
										dataKey="name"  interval={'preserveStartEnd'}
									/>
									<YAxis  dataKey="demand" interval={'preserveStartEnd'} label={{ value: `<-- Employment ${this.state.nameOne} ${this.state.nameTwo} -->`, angle: -90, dx: -20 }} />
									<Tooltip />
									{/* <Legend /> */}
									{/* <Line dataKey="demand" label={{ value: '<-- construction of demand-->', dy: 13 }} /> */}
                                    <Bar dataKey="price" fill="#8884d8" />
                                    <Bar dataKey="demand" fill="#82ca9d" />
                                    </BarChart>
							</ResponsiveContainer>
						</div>
					)}
					<br />
					<br />
				</div>
			);
		}
	}

	return (
		<div>
			<Container fixed>
				<OperatingRatio />
			</Container>
		</div>
	);
}

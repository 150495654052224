import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";


export default function ComparativeProfitLossStatement() {

    const data = [{
        "partners": [
            {
                "name": "Sudhanshu",
                "profitlossratio": 3,
                "fixedCapitalRatio": 5,
                "credit": 3000,
                "debit": 0
            },
            {
                "name": "Sarvesh",
                "profitlossratio": 2,
                "fixedCapitalRatio": 4,
                "credit": 0,
                "debit": 2000
            }
        ],
        "capitalPartnersDate": "2016-04-01",
        "totalFixedCapitalRatio": 9,
        "totalProfitLossRatio": 5,
        "capitalPartners": 90000,
        "partnersDrawings": [
            {
                "name": "Sudhanshu",
                "amount": 6000,
                "date": "2016-07-01"
            },
            {
                "name": "Sarvesh",
                "amount": 4000,
                "date": "2016-10-01"
            }
        ],
        "provisionPartnershipDeed": 8,
        "chargeInterestDrawings": 12,
        "openingBalanceInterest": 6,
        "commissionPercentage": 5,
        "firmProfit": 37770,
        "endDate": "31-3-17",
        "monthlyGapOfDrawing": [
            9,
            6
        ],
        "interestOnCapital": 7200,
        "interestOnCapitalPerPerson": [
            4000,
            3200
        ],
        "interestOnDrawings": [
            540,
            240
        ],
        "interestOnCurrentAccount": [
            {
                "name": "Sudhanshu",
                "creditInterest": 180,
                "debitInterest": 0
            },
            {
                "name": "Sarvesh",
                "creditInterest": 0,
                "debitInterest": 120
            }
        ],
        "extraCredit": [],
        "profitLossAppropriationTotal": 38670,
        "commission": {
            "nameToBePaidCommission": "Sudhanshu",
            "commission": 1490,
            "initialCommission": 31290,
            "creditInterest": 180
        },
        "partnersCurrentAccountTotal": 29800,
        "partnersCurrentAccount": [
            17880,
            11920
        ],
        "debitBalanceTotal": [
            26550,
            15120
        ],
        "creditBalanceTotal": [
            20010,
            10760
        ]
    }
    ]

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                partners: [
                    {
                        name: "Sudhanshu",
                        profitlossratio: 3,
                        fixedCapitalRatio: 5,
                        credit: 3000,
                        debit: 0
                    }, {
                        name: "Sarvesh",
                        profitlossratio: 2,
                        fixedCapitalRatio: 4,
                        credit: 0,
                        debit: 2000
                    }
                ],
                capitalPartnersDate: "2016-04-01",
                capitalPartners: 90000,
                partnersDrawings: [
                    {
                        name: "Sudhanshu",
                        amount: 6000,
                        date: "2016-07-01"
                    }, {
                        name: "Sarvesh",
                        amount: 4000,
                        date: "2016-10-01"
                    }
                ],
                provisionPartnershipDeed: 8,
                chargeInterestDrawings: 12,
                openingBalanceInterest: 6,
                nameToBePaidCommission: "Sudhanshu",
                commission: 5,
                firmProfit: 37770,

                editModePartners: [
                    {
                        name: false,
                        profitlossratio: false,
                        fixedCapitalRatio: false,
                        credit: false,
                        debit: false
                    }, {
                        name: false,
                        profitlossratio: false,
                        fixedCapitalRatio: false,
                        credit: false,
                        debit: false
                    }

                ],
                editModeCPDate: false,
                editModeCP: false,
                editModePartnersDrawings: [
                    {
                        name: false,
                        amount: false,
                        date: false
                    }, {
                        name: false,
                        amount: false,
                        date: false
                    }
                ],
                editModeprovisionPartnershipDeed: false,
                editModechargeInterestDrawings: false,
                editModeopeningBalanceInterest: false,
                editModenameToBePaidCommission: false,
                editModecommission: false,
                editModefirmProfit: false,

                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true

            })
            this.eventHandler = this.eventHandler.bind(this)
            this.handleChange = this.handleChange.bind(this)
            this.nameChange = this.nameChange.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'partnerName':
                        this.state.partners[customArray[1]].name = event.target.value
                        this.setState({}, () => {
                            this.fillDetails()
                        })
                        break;
                    case 'partnerRatio':
                        this.state.partners[customArray[1]].profitlossratio = event.target.value
                        this.setState({}, () => {
                            this.fillDetails()
                        })
                        break;
                    case 'partnerCapitalRatio':
                        this.state.partners[customArray[1]].fixedCapitalRatio = event.target.value
                        this.setState({}, () => {
                            this.fillDetails()
                        })
                        break;
                    case 'drawingName':
                        this.state.partnersDrawings[customArray[1]].name = event.target.value
                        this.setState({}, () => {
                            this.fillDetails()
                        })
                        break;
                    case 'drawingAmount':
                        this.state.partnersDrawings[customArray[1]].amount = event.target.value
                        this.setState({}, () => {
                            this.fillDetails()
                        })
                        break;
                    case 'drawingDate':
                        this.state.partnersDrawings[customArray[1]].date = event.target.value
                        this.setState({}, () => {
                            this.fillDetails()
                        })
                        break;
                    case 'partnerCredit':
                        this.state.partners[customArray[1]].credit = event.target.value
                        this.setState({}, () => {
                            this.fillDetails()
                        })
                        break;
                    case 'partnerDebit':
                        this.state.partners[customArray[1]].debit = event.target.value
                        this.setState({}, () => {
                            this.fillDetails()
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'capitalPartnersDate':
                    this.setState({
                        capitalPartnersDate: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'capitalPartners':
                    this.setState({
                        capitalPartners: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'provisionPartnershipDeed':
                    this.setState({
                        provisionPartnershipDeed: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'chargeInterestDrawings':
                    this.setState({
                        chargeInterestDrawings: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'openingBalanceInterest':
                    this.setState({
                        openingBalanceInterest: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'commission':
                    this.setState({
                        commission: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'firmProfit':
                    this.setState({
                        firmProfit: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
            }
        }

        handleChange(event) {
            this.state.partnersDrawings[event.target.name].name = event.target.value
            this.setState({})
        }

        nameChange(event) {
            this.setState({
                nameToBePaidCommission: event.target.value
            })
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.partners) {
                if (i.name === '') {
                    flag = false
                }
                if (!NumberRegex.test(i.profitlossratio) || i.profitlossratio === '' || i.profitlossratio < 0 || i.profitlossratio > 100) {
                    flag = false
                }
                if (!NumberRegex.test(i.fixedCapitalRatio) || i.fixedCapitalRatio === '' || i.fixedCapitalRatio < 0 || i.fixedCapitalRatio > 100) {
                    flag = false
                }
                if (!NumberRegex.test(i.credit) || i.credit === '') {
                    flag = false
                }
                if (!NumberRegex.test(i.debit) || i.debit === '') {
                    flag = false
                }
            }

            for (let i of this.state.partnersDrawings) {
                if (i.name === '') {
                    flag = false
                }
                if (!NumberRegex.test(i.amount) || i.amount === '') {
                    flag = false
                }
                if (parseInt(i.date.slice(2, 4)) > parseInt(this.state.capitalPartnersDate.slice(2, 4)) + 1) {
                    flag = false
                }
                if (parseInt(i.date.slice(2, 4)) < parseInt(this.state.capitalPartnersDate.slice(2, 4))) {
                    flag = false
                }

                if (parseInt(i.date.slice(2, 4)) == parseInt(this.state.capitalPartnersDate.slice(2, 4)) + 1) {
                    if (parseInt(i.date.slice(5, 7)) >= parseInt(this.state.capitalPartnersDate.slice(5, 7))) {
                        flag = false
                    }
                }
                if (parseInt(i.date.slice(2, 4)) == parseInt(this.state.capitalPartnersDate.slice(2, 4))) {
                    if (parseInt(i.date.slice(5, 7)) <= parseInt(this.state.capitalPartnersDate.slice(5, 7))) {
                        console.log("HI")
                        flag = false
                    }
                }

            }

            if (!NumberRegex.test(this.state.capitalPartners) || this.state.capitalPartners === '') {
                flag = false
            }
            if (!NumberRegex.test(this.state.firmProfit) || this.state.firmProfit === '') {
                flag = false
            }
            if (!NumberRegex.test(this.state.provisionPartnershipDeed) || this.state.provisionPartnershipDeed === '' || this.state.provisionPartnershipDeed < 0 || this.state.provisionPartnershipDeed > 100) {
                flag = false
            }
            if (!NumberRegex.test(this.state.chargeInterestDrawings) || this.state.chargeInterestDrawings === '' || this.state.chargeInterestDrawings < 0 || this.state.chargeInterestDrawings > 100) {
                flag = false
            }
            if (!NumberRegex.test(this.state.openingBalanceInterest) || this.state.openingBalanceInterest === '' || this.state.openingBalanceInterest < 0 || this.state.openingBalanceInterest > 100) {
                flag = false
            }
            if (!NumberRegex.test(this.state.commission) || this.state.commission === '' || this.state.commission < 0 || this.state.commission > 100) {
                flag = false
            }
            if (this.state.nameToBePaidCommission === '') {
                flag = false
            }




            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //EVENT FUNCTIONALTIES END

        // BUTTON PANEL START

        addPartner() {
            this.state.partners.push({
                name: "name",
                profitlossratio: 1,
                fixedCapitalRatio: 1,
                credit: 0,
                debit: 0
            })
            this.state.editModePartners.push({
                name: true,
                profitlossratio: false,
                fixedCapitalRatio: false,
                credit: false,
                debit: false
            })
            this.setState({
                editModeButton: true
            })
        }

        removePartner(event) {
            let tempPartnerName = this.state.partners[event].name
            this.state.partners.splice(event, 1)
            this.state.editModePartners.splice(event, 1)

            for (let i of this.state.partnersDrawings) {
                if (i.name === tempPartnerName) {
                    this.state.partnersDrawings.splice(i, 1)
                    this.state.editModePartnersDrawings.splice(i, 1)
                }
            }

            if (this.state.nameToBePaidCommission === tempPartnerName) {
                this.setState({
                    nameToBePaidCommission: this.state.partners[0].name
                })
            }
            this.setState({})
        }

        addDrawings() {
            this.state.partnersDrawings.push({
                name: this.state.partners[0].name,
                amount: 1000,
                date: this.state.capitalPartnersDate
            })
            this.state.editModePartnersDrawings.push({
                name: false,
                amount: false,
                date: false
            })
            this.setState({})
        }

        removeDrawings(event) {
            this.state.partnersDrawings.splice(event, 1)
            this.state.editModePartnersDrawings.splice(event, 1)
            this.setState({})
        }

        addRemoveYear(event) {

        }

        onSubmit() {
            //#region TABLE 1 AND EXPLANATION
            let interestOnCapital = parseFloat(this.state.capitalPartners) * parseFloat(this.state.provisionPartnershipDeed) / 100

            let interestOnCapitalPerPerson = []
            let totalFixedCapitalRatio = 0

            for (let i of this.state.partners) {
                totalFixedCapitalRatio += parseFloat(i.fixedCapitalRatio)
            }

            for (let i of this.state.partners) {
                interestOnCapitalPerPerson.push(interestOnCapital * parseFloat(i.fixedCapitalRatio) / totalFixedCapitalRatio)
            }

            let interestOnDrawings = []
            let finalInterestOnDrawings = []
            let monthlyGapOfDrawing = []

            for (let i of this.state.partnersDrawings) {
                let startingDateArray = this.state.capitalPartnersDate.split("-")
                let currentDrawingDateArray = i.date.split("-")

                monthlyGapOfDrawing.push(12 - (parseInt(currentDrawingDateArray[1]) - parseInt(startingDateArray[1])) - (13 * (parseInt(currentDrawingDateArray[0]) - parseInt(startingDateArray[0]))))
            }

            for (let i of this.state.partners) {
                interestOnDrawings[i.name] = 0
            }

            for (let i in this.state.partnersDrawings) {
                interestOnDrawings[this.state.partnersDrawings[i].name] += ((parseFloat(this.state.partnersDrawings[i].amount) * parseFloat(this.state.chargeInterestDrawings) / 100) * monthlyGapOfDrawing[i] / 12)
            }

            for (let i in interestOnDrawings) {
                finalInterestOnDrawings.push(interestOnDrawings[i])
            }

            let interestOnCurrentAccount = []

            for (let i of this.state.partners) {
                interestOnCurrentAccount.push({
                    name: i.name,
                    creditInterest: parseFloat(i.credit) * parseFloat(this.state.openingBalanceInterest) / 100,
                    debitInterest: parseFloat(i.debit) * parseFloat(this.state.openingBalanceInterest) / 100
                })
            }

            let profitLossAppropriationTotal = parseFloat(this.state.firmProfit)

            for (let i in interestOnDrawings) {
                profitLossAppropriationTotal += interestOnDrawings[i]
            }

            for (let i of interestOnCurrentAccount) {
                profitLossAppropriationTotal += i.debitInterest
            }

            let commission = {
                nameToBePaidCommission: this.state.nameToBePaidCommission,
                commission: profitLossAppropriationTotal - interestOnCapital,
                initialCommission: profitLossAppropriationTotal - interestOnCapital
            }

            let partnersCurrentAccountTotal = profitLossAppropriationTotal
            let extraCredit = []

            for (let i of interestOnCurrentAccount) {
                if (i.name === commission.nameToBePaidCommission) {
                    commission.commission -= i.creditInterest
                    commission.initialCommission -= i.creditInterest
                    commission.creditInterest = i.creditInterest
                }
                if (i.name !== commission.nameToBePaidCommission) {
                    if (parseFloat(i.creditInterest) !== 0) {
                        extraCredit.push(parseFloat(i.creditInterest))
                    }
                }

                partnersCurrentAccountTotal -= i.creditInterest
            }

            commission.commission = commission.commission * (parseFloat(this.state.commission) / (100 + parseFloat(this.state.commission)))

            partnersCurrentAccountTotal -= (interestOnCapital + commission.commission)

            let partnersCurrentAccount = []
            let totalProfitLossRatio = 0

            for (let i of this.state.partners) {
                totalProfitLossRatio += parseFloat(i.profitlossratio)
            }


            for (let i of this.state.partners) {
                partnersCurrentAccount.push(Math.round((partnersCurrentAccountTotal * parseFloat(i.profitlossratio) / totalProfitLossRatio)*100)/100)
            }

            //#endregion

            let endDate = "31"//-{parseFloat(this.state.capitalPartnersDate.slice(0, 4)) + 1}
            let endMonth = parseFloat(this.state.capitalPartnersDate.slice(5, 7)) - 1
            let endYear = parseFloat(this.state.capitalPartnersDate.slice(2, 4)) + 1

            let debitBalanceTotal = []
            let creditBalanceTotal = []

            for (let i in this.state.partners) {
                let TempTotal = parseFloat(this.state.partners[i].credit) + interestOnCapitalPerPerson[i] + parseFloat(interestOnCurrentAccount[i].creditInterest) + partnersCurrentAccount[i]
                if (this.state.partners[i].name === commission.nameToBePaidCommission) {
                    TempTotal += commission.commission
                }
                debitBalanceTotal.push(Math.round(TempTotal*100)/100)
            }

            for (let i in this.state.partners) {
                let Temptotal = debitBalanceTotal[i] - finalInterestOnDrawings[i] - parseFloat(interestOnCurrentAccount[i].debitInterest)
                for (let j in this.state.partnersDrawings) {
                    if (this.state.partners[i].name === this.state.partnersDrawings[j].name) {
                        console.log(this.state.partnersDrawings[i].amount)
                        Temptotal -= parseFloat(this.state.partnersDrawings[i].amount)
                    }
                }
                creditBalanceTotal.push(Math.round(Temptotal*100)/100)
            }


            let dataObject = {
                partners: this.state.partners,
                capitalPartnersDate: this.state.capitalPartnersDate,
                totalFixedCapitalRatio,
                totalProfitLossRatio,
                capitalPartners: this.state.capitalPartners,
                partnersDrawings: this.state.partnersDrawings,
                provisionPartnershipDeed: this.state.provisionPartnershipDeed,
                chargeInterestDrawings: this.state.chargeInterestDrawings,
                openingBalanceInterest: this.state.openingBalanceInterest,
                commissionPercentage: this.state.commission,
                firmProfit: this.state.firmProfit,

                endDate: endDate + "-" + endMonth + "-" + endYear,
                monthlyGapOfDrawing: monthlyGapOfDrawing,
                interestOnCapital: interestOnCapital,
                interestOnCapitalPerPerson: interestOnCapitalPerPerson,
                interestOnDrawings: finalInterestOnDrawings,
                interestOnCurrentAccount: interestOnCurrentAccount,
                extraCredit: extraCredit,
                profitLossAppropriationTotal: profitLossAppropriationTotal,
                commission: commission,
                partnersCurrentAccountTotal: partnersCurrentAccountTotal,
                partnersCurrentAccount: partnersCurrentAccount,
                debitBalanceTotal: debitBalanceTotal,
                creditBalanceTotal: creditBalanceTotal
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            console.log(JSON.stringify(data, null, 4))
        }

        onReset() {
            window.location.reload()
        }

        switchTableToChartAndBack() {
            // this.setState({
            //     tableModeChartModeSwitch: !this.state.tableModeChartModeSwitch
            // })
        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //BUTTON PANEL END


        // EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'partnerName':
                        this.state.editModePartners[customArray[1]].name = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'partnerRatio':
                        this.state.editModePartners[customArray[1]].profitlossratio = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'partnerCapitalRatio':
                        this.state.editModePartners[customArray[1]].fixedCapitalRatio = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'drawingName':
                        this.state.editModePartnersDrawings[customArray[1]].name = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'drawingAmount':
                        this.state.editModePartnersDrawings[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'drawingDate':
                        this.state.editModePartnersDrawings[customArray[1]].date = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'partnerCredit':
                        this.state.editModePartners[customArray[1]].credit = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'partnerDebit':
                        this.state.editModePartners[customArray[1]].debit = true
                        this.setState({
                            editModeButton: true
                        })
                        break;

                }
            }

            switch (event.target.name) {
                case 'capitalPartnersDate':
                    this.setState({
                        editModeCPDate: true,
                        editModeButton: true
                    })
                    break;
                case 'capitalPartners':
                    this.setState({
                        editModeCP: true,
                        editModeButton: true
                    })
                    break;
                case 'provisionPartnershipDeed':
                    this.setState({
                        editModeprovisionPartnershipDeed: true,
                        editModeButton: true
                    })
                    break;
                case 'chargeInterestDrawings':
                    this.setState({
                        editModechargeInterestDrawings: true,
                        editModeButton: true
                    })
                    break;
                case 'openingBalanceInterest':
                    this.setState({
                        editModeopeningBalanceInterest: true,
                        editModeButton: true
                    })
                    break;
                case 'nameToBePaidCommission':
                    this.setState({
                        editModenameToBePaidCommission: true,
                        editModeButton: true
                    })
                    break;
                case 'commission':
                    this.setState({
                        editModecommission: true,
                        editModeButton: true
                    })
                    break;
                case 'firmProfit':
                    this.setState({
                        editModefirmProfit: true,
                        editModeButton: true
                    })
                    break;
            }
        }

        editModeOff() {
            for (let i of this.state.editModePartners) {
                i.name = false
                i.profitlossratio = false
                i.fixedCapitalRatio = false
                i.credit = false
                i.debit = false
            }

            for (let i of this.state.editModePartnersDrawings) {
                i.name = false
                i.amount = false
                i.date = false
            }

            this.setState({
                editModeCPDate: false,
                editModeCP: false,
                editModeprovisionPartnershipDeed: false,
                editModechargeInterestDrawings: false,
                editModeopeningBalanceInterest: false,
                editModenameToBePaidCommission: false,
                editModecommission: false,
                editModefirmProfit: false,

                editModeButton: false,
            })
        }

        // EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        Partners of the firm: <br /><br />

                        {this.state.partners.map((x, i) => (
                            <div>
                                ({i + 1})&nbsp;
                                {
                                    this.state.editModePartners[i].name ?
                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["partnerName", i]} id='outlined-base' error={x.name === ''}
                                            value={x.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["partnerName", i]} onClick={this.buttonMaker.bind(this)}> {x.name}</a>
                                }
                                {this.state.partners.length > 2 ? <IconButton style={{ width: "3%", color: "red" }} onClick={this.removePartner.bind(this, i)} name="-">-</IconButton> : <span><br /><br /></span>}
                                {i + 1 === this.state.partners.length && <div align="right">
                                    ADD PARTNERS
                                    <IconButton style={{ width: "3%" }} onClick={this.addPartner.bind(this)}>+</IconButton></div>}
                            </div>
                        ))}
                        Their profit-loss sharing ratio is&nbsp;
                        {this.state.partners.map((x, i) => (
                            <span>
                                {
                                    this.state.editModePartners[i].profitlossratio ?
                                        <TextField style={{ 'margin-top': "-15px", width: "2%" }} name={["partnerRatio", i]} id='outlined-base' error={x.profitlossratio === '' || isNaN(x.profitlossratio)}
                                            value={x.profitlossratio} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["partnerRatio", i]} onClick={this.buttonMaker.bind(this)}> {x.profitlossratio}</a>
                                }&nbsp;{i + 1 !== this.state.partners.length && ":"}&nbsp;
                            </span>
                        ))}

                        <br /><br />
                        Opening Total Capital of the Partners on&nbsp;
                        {
                            this.state.editModeCPDate ?
                                <TextField type="date" style={{ 'margin-top': "-15px", width: "15%" }} id='outlined-base'
                                    value={this.state.capitalPartnersDate} name="capitalPartnersDate" onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="capitalPartnersDate" onClick={this.buttonMaker.bind(this)}> {this.state.capitalPartnersDate.slice(8, 10)}-{this.state.capitalPartnersDate.slice(5, 7)}-{this.state.capitalPartnersDate.slice(0, 4)}</a>

                        }&nbsp;
                        is ₹&nbsp;
                        {
                            this.state.editModeCP ?
                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name="capitalPartners" id='outlined-base' error={this.state.capitalPartners === '' || isNaN(this.state.capitalPartners)}
                                    value={this.state.capitalPartners} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="capitalPartners" onClick={this.buttonMaker.bind(this)}> {this.state.capitalPartners}</a>
                        }<br /><br />
                        Capital of the partners is in the proportion of&nbsp;
                        {this.state.partners.map((x, i) => (
                            <span>
                                {
                                    this.state.editModePartners[i].fixedCapitalRatio ?
                                        <TextField style={{ 'margin-top': "-15px", width: "2%" }} name={["partnerCapitalRatio", i]} id='outlined-base' error={x.fixedCapitalRatio === '' || isNaN(x.fixedCapitalRatio)}
                                            value={x.fixedCapitalRatio} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["partnerCapitalRatio", i]} onClick={this.buttonMaker.bind(this)}> {x.fixedCapitalRatio}</a>
                                }&nbsp;{i + 1 !== this.state.partners.length && ":"}&nbsp;
                            </span>
                        ))}
                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />
                        <b>Drawings of the partners is as follows :</b><br /><br />

                        {this.state.partnersDrawings.map((x, i) => (
                            <div>
                                {this.state.editModePartnersDrawings[i].name ?
                                    <FormControl style={{ width: "15%" }}>
                                        <InputLabel id="demo-simple-select-label">Partner Name</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name={i}
                                            value={this.state.partnersDrawings[i].name}
                                            label="Partner Name"
                                            onChange={this.handleChange}
                                        >
                                            {this.state.partners.map((y) => (
                                                <MenuItem value={y.name}>{y.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> :
                                    <a className='txt' name={["drawingName", i]} onClick={this.buttonMaker.bind(this)}> {x.name}</a>
                                }
                                : ₹ &nbsp;
                                {this.state.editModePartnersDrawings[i].amount ?
                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["drawingAmount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                        value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name={["drawingAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                }
                                &nbsp;on&nbsp;
                                {
                                    this.state.editModePartnersDrawings[i].date ?
                                        <TextField type="date" style={{ 'margin-top': "-15px", width: "15%" }} id='outlined-base'
                                            value={x.date} name={["drawingDate", i]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["drawingDate", i]} onClick={this.buttonMaker.bind(this)}> {x.date.slice(8, 10)}-{x.date.slice(5, 7)}-{x.date.slice(0, 4)}</a>

                                }
                                <IconButton style={{ width: "3%", color: "red" }} onClick={this.removeDrawings.bind(this, i)} name="-">-</IconButton>

                            </div>
                        ))}

                        <div align="right">
                            ADD DRAWINGS
                            <IconButton style={{ width: "3%" }} onClick={this.addDrawings.bind(this)}>+</IconButton></div>

                        There is provision in partnership deed to pay interest on capital at&nbsp;
                        {
                            this.state.editModeprovisionPartnershipDeed ?
                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} id='outlined-base' error={this.state.provisionPartnershipDeed === '' || isNaN(this.state.provisionPartnershipDeed) || this.state.provisionPartnershipDeed < 0 || this.state.provisionPartnershipDeed > 100}
                                    value={this.state.provisionPartnershipDeed} name="provisionPartnershipDeed" onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="provisionPartnershipDeed" onClick={this.buttonMaker.bind(this)}> {this.state.provisionPartnershipDeed}</a>

                        } % p.a.<br /><br />
                        and to charge interest on drawings at&nbsp;
                        {
                            this.state.editModechargeInterestDrawings ?
                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} id='outlined-base' error={this.state.chargeInterestDrawings === '' || isNaN(this.state.chargeInterestDrawings) || this.state.chargeInterestDrawings < 0 || this.state.chargeInterestDrawings > 100}
                                    value={this.state.chargeInterestDrawings} name="chargeInterestDrawings" onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="chargeInterestDrawings" onClick={this.buttonMaker.bind(this)}> {this.state.chargeInterestDrawings}</a>

                        } % p.a.<br /><br />

                        On {this.state.capitalPartnersDate.slice(8, 10)}-{this.state.capitalPartnersDate.slice(5, 7)}-{this.state.capitalPartnersDate.slice(0, 4)} balances in current accounts of partners :
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align='center'>Name</StyledTableCell>
                                        <StyledTableCell align='right'>Credit</StyledTableCell>
                                        <StyledTableCell align='right'>Debit</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.partners.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align='center'>{x.name}</StyledTableCell>
                                            <StyledTableCell align='right'>
                                                {this.state.editModePartners[i].credit ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} id='outlined-base' error={x.credit === '' || isNaN(x.credit)}
                                                        value={x.credit} name={["partnerCredit", i]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["partnerCredit", i]} onClick={this.buttonMaker.bind(this)}> {parseFloat(x.credit) !== 0 ? x.credit : "-"}</a>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align='right'>
                                                {this.state.editModePartners[i].debit ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} id='outlined-base' error={x.debit === '' || isNaN(x.debit)}
                                                        value={x.debit} name={["partnerDebit", i]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["partnerDebit", i]} onClick={this.buttonMaker.bind(this)}> {parseFloat(x.debit) !== 0 ? x.debit : "-"}</a>
                                                }
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />

                        Interest at&nbsp;

                        {this.state.editModeopeningBalanceInterest ?
                            <TextField style={{ 'margin-top': "-15px", width: "15%" }} id='outlined-base' error={this.state.openingBalanceInterest === '' || isNaN(this.state.openingBalanceInterest) || this.state.openingBalanceInterest < 0 || this.state.openingBalanceInterest > 100}
                                value={this.state.openingBalanceInterest} name="openingBalanceInterest" onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="openingBalanceInterest" onClick={this.buttonMaker.bind(this)}> {this.state.openingBalanceInterest}</a>

                        } % p.a.<br /><br />

                        {this.state.editModenameToBePaidCommission ?
                            <FormControl style={{ width: "15%" }}>
                                <InputLabel id="nameSelect">Partner Name</InputLabel>
                                <Select
                                    labelId="nameSelect"
                                    id="nameSelect"
                                    value={this.state.nameToBePaidCommission}
                                    label="Partner Name"
                                    onChange={this.nameChange}
                                >
                                    {this.state.partners.map((y) => (
                                        <MenuItem value={y.name}>{y.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> :
                            <a className='txt' name="nameToBePaidCommission" onClick={this.buttonMaker.bind(this)}> {this.state.nameToBePaidCommission}</a>
                        }&nbsp;
                        is to be paid&nbsp;
                        {
                            this.state.editModecommission ?
                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} id='outlined-base' error={this.state.commission === '' || isNaN(this.state.commission) || this.state.commission < 0 || this.state.commission > 100}
                                    value={this.state.commission} name="commission" onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="commission" onClick={this.buttonMaker.bind(this)}> {this.state.commission}</a>

                        } % p.a. commission on profit after deduction of his commission from profit <br /><br />

                        Before Considering the above mentioned adjustments, profit of the firm for the year ending on&nbsp;
                        31-{parseFloat(this.state.capitalPartnersDate.slice(5, 7)) - 1 === 0 ? "12" : parseFloat(this.state.capitalPartnersDate.slice(5, 7)) - 1}-{parseFloat(this.state.capitalPartnersDate.slice(0, 4)) + 1}&nbsp;
                        is ₹&nbsp;
                        {
                            this.state.editModefirmProfit ?
                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} id='outlined-base' error={this.state.firmProfit === '' || isNaN(this.state.firmProfit)}
                                    value={this.state.firmProfit} name="firmProfit" onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="firmProfit" onClick={this.buttonMaker.bind(this)}> {this.state.firmProfit}</a>

                        }.<br /><br />
                        <b>From this information prepare a profit and loss appropriation account, partners capital accounts and current accounts</b>

                        <br /><br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}

                    </div>
                    <div align="left" label="buttons">
                        <br />
                        <br />
                        <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                        <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                    </div>
                    <div align='right' label="sumNavigator">
                        <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {chartState.selected + 1}
                        &nbsp;&nbsp;&nbsp;
                        <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                    </div>
                    {this.state.solutionShow && <div>
                        <div label="solution" align="left">
                            <b>Ans. :</b><br />
                            <div align="center">
                                <b>Profit and Loss Appropriation Account for the Year Ending on 31-{parseFloat(data[chartState.selected].capitalPartnersDate.slice(5, 7)) - 1 === 0 ? "12" : parseFloat(data[chartState.selected].capitalPartnersDate.slice(5, 7)) - 1}-{parseFloat(data[chartState.selected].capitalPartnersDate.slice(0, 4)) + 1} of</b><br />
                                <b>Firm of&nbsp;
                                    {data[chartState.selected].partners.map((x, i) => (
                                        <span>
                                            {x.name} {data[chartState.selected].partners.length - 1 !== i ?
                                                data[chartState.selected].partners.length - 2 !== i ?
                                                    ", " : " and " : ""
                                            }
                                        </span>
                                    ))}</b>
                            </div>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align='center'>Particular</StyledTableCell>
                                            <StyledTableCell align='center'>Amt. (₹)</StyledTableCell>
                                            <StyledTableCell align='center'>Particular</StyledTableCell>
                                            <StyledTableCell align='center'>Amt. (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align="left">
                                                To Interest on capital A/c:<br />
                                                {data[chartState.selected].interestOnCapitalPerPerson.map((x, i) => (
                                                    <div>&emsp;{data[chartState.selected].partners[i].name} : <span style={{ float: "right" }}>{x}</span><br /></div>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>{data[chartState.selected].interestOnCapital}</StyledTableCell>
                                            <StyledTableCell align='left'>By Profit and Loss A/c (Net profit)</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].firmProfit}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell>
                                                By Interest on Drawings A/c :<br />
                                                {data[chartState.selected].interestOnDrawings.map((x, i) => (
                                                    <div>&emsp;{data[chartState.selected].partners[i].name} : <span style={{ float: "right" }}>{x}</span><br /></div>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>{data[chartState.selected].interestOnDrawings.reduce((x,y)=>{return parseFloat(x)+parseFloat(y)})}</StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].interestOnCurrentAccount.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell>{parseFloat(x.creditInterest) !== 0 && "To Interest on current A/c " + (x.name)}</StyledTableCell>
                                                <StyledTableCell align="right">{parseFloat(x.creditInterest) !== 0 && x.creditInterest}</StyledTableCell>
                                                <StyledTableCell>{parseFloat(x.debitInterest) !== 0 && "To Interest on current A/c " + (x.name)}</StyledTableCell>
                                                <StyledTableCell align="right">{parseFloat(x.debitInterest) !== 0 && x.debitInterest}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell>To Commission A/c ({data[chartState.selected].commission.nameToBePaidCommission})</StyledTableCell>
                                            <StyledTableCell align='right'>{data[chartState.selected].commission.commission}</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell>
                                                To Partners' current A/c :<br />
                                                &emsp;(Divisible profit)
                                                {data[chartState.selected].partnersCurrentAccount.map((x, i) => (
                                                    <div>&emsp;{data[chartState.selected].partners[i].name} : <span style={{ float: "right" }}>{x}</span><br /></div>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "bottom" }}>{data[chartState.selected].partnersCurrentAccountTotal}</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].profitLossAppropriationTotal}</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].profitLossAppropriationTotal}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br /><br />
                            <div align="center"><b>Partners' Capital Accounts</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align='center'>Date</StyledTableCell>
                                            <StyledTableCell align='center'>Particular</StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'>{x.name} <br /> (₹)</StyledTableCell>
                                            ))}
                                            <StyledTableCell align='center'>Date</StyledTableCell>
                                            <StyledTableCell align='center'>Particular</StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'>{x.name} <br /> (₹)</StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center'>{data[chartState.selected].endDate}</StyledTableCell>
                                            <StyledTableCell align='center'>To Balance c/d</StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'>{Math.round((parseFloat(data[chartState.selected].capitalPartners) * parseFloat(x.fixedCapitalRatio) / data[chartState.selected].totalFixedCapitalRatio)*100)/100}</StyledTableCell>
                                            ))}
                                            <StyledTableCell align='center'>{parseInt(data[chartState.selected].capitalPartnersDate.slice(8, 10)) + "-" + parseInt(data[chartState.selected].capitalPartnersDate.slice(5, 7)) + "-" + parseInt(data[chartState.selected].capitalPartnersDate.slice(2, 5))}</StyledTableCell>
                                            <StyledTableCell align='center'>By Balance b/d</StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'>{Math.round((parseFloat(data[chartState.selected].capitalPartners) * parseFloat(x.fixedCapitalRatio) / data[chartState.selected].totalFixedCapitalRatio)*100)/100}</StyledTableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'><b>{Math.round((parseFloat(data[chartState.selected].capitalPartners) * parseFloat(x.fixedCapitalRatio) / data[chartState.selected].totalFixedCapitalRatio)*100)/100}</b></StyledTableCell>
                                            ))}
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'><b>{Math.round((parseFloat(data[chartState.selected].capitalPartners) * parseFloat(x.fixedCapitalRatio)/ data[chartState.selected].totalFixedCapitalRatio)*100)/100}</b></StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br /><br />
                            <div align="center"><b>Partners' Current Accounts</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align='center'>Date</StyledTableCell>
                                            <StyledTableCell align='center'>Particular</StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'>{x.name} <br /> (₹)</StyledTableCell>
                                            ))}
                                            <StyledTableCell align='center'>Date</StyledTableCell>
                                            <StyledTableCell align='center'>Particular</StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'>{x.name} <br /> (₹)</StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center'>{parseFloat(data[chartState.selected].capitalPartnersDate.slice(8, 10))}-{parseFloat(data[chartState.selected].capitalPartnersDate.slice(5, 7))}-{parseFloat(data[chartState.selected].capitalPartnersDate.slice(2, 4))}</StyledTableCell>
                                            <StyledTableCell>To Balance b/d</StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'>{parseFloat(x.debit) === 0 ? "-" : x.debit}</StyledTableCell>
                                            ))}
                                            <StyledTableCell>{data[chartState.selected].endDate}</StyledTableCell>
                                            <StyledTableCell>By Balance b/d</StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'>{parseFloat(x.credit) === 0 ? "-" : x.credit}</StyledTableCell>
                                            ))}
                                        </TableRow>

                                        {data[chartState.selected].partnersDrawings.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell>{parseFloat(x.date.slice(8, 10))}-{parseFloat(x.date.slice(5, 7))}-{parseFloat(x.date.slice(2, 4))}</StyledTableCell>
                                                <StyledTableCell> To Drawings A/c</StyledTableCell>
                                                {data[chartState.selected].partners.map((y) => (
                                                    <StyledTableCell align='center'>{x.name === y.name ? x.amount : "-"}</StyledTableCell>
                                                ))}
                                                <StyledTableCell align='center'></StyledTableCell>
                                                <StyledTableCell align='center'></StyledTableCell>
                                                {data[chartState.selected].partners.map((x) => (
                                                    <StyledTableCell align='center'></StyledTableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'></StyledTableCell>
                                            ))}
                                            <StyledTableCell align='center'>{data[chartState.selected].endDate}</StyledTableCell>
                                            <StyledTableCell align='center'>By Interest on capital A/c</StyledTableCell>
                                            {data[chartState.selected].partners.map((x, i) => (
                                                <StyledTableCell align='center'>{data[chartState.selected].interestOnCapitalPerPerson[i]}</StyledTableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align='center'>{data[chartState.selected].endDate}</StyledTableCell>
                                            <StyledTableCell align='center'>To Interest on current A/c</StyledTableCell>
                                            {data[chartState.selected].interestOnCurrentAccount.map((x) => (
                                                <StyledTableCell align='center'>{parseFloat(x.debitInterest) === 0 ? "-" : x.debitInterest}</StyledTableCell>
                                            ))}
                                            <StyledTableCell align='center'>{data[chartState.selected].endDate}</StyledTableCell>
                                            <StyledTableCell align='center'>By Interest on capital A/c</StyledTableCell>
                                            {data[chartState.selected].interestOnCurrentAccount.map((x) => (
                                                <StyledTableCell align='center'>{parseFloat(x.creditInterest) === 0 ? "-" : x.creditInterest}</StyledTableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align='center'>{data[chartState.selected].endDate}</StyledTableCell>
                                            <StyledTableCell align='center'>To Interest on Drawings A/c</StyledTableCell>
                                            {data[chartState.selected].partners.map((x, i) => (
                                                <StyledTableCell align='center'>{data[chartState.selected].interestOnDrawings[i]}</StyledTableCell>
                                            ))}
                                            <StyledTableCell align='center'>{data[chartState.selected].endDate}</StyledTableCell>
                                            <StyledTableCell align='center'>By Commission A/c</StyledTableCell>
                                            {data[chartState.selected].partners.map((x) => (
                                                <StyledTableCell align='center'>{data[chartState.selected].commission.nameToBePaidCommission === x.name ? data[chartState.selected].commission.commission : "-"}</StyledTableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align='center'>{data[chartState.selected].endDate}</StyledTableCell>
                                            <StyledTableCell align='center'>To Balance</StyledTableCell>
                                            {data[chartState.selected].partners.map((x, i) => (
                                                <StyledTableCell align='center'>{data[chartState.selected].creditBalanceTotal[i]}</StyledTableCell>
                                            ))}
                                            <StyledTableCell align='center'>{data[chartState.selected].endDate}</StyledTableCell>
                                            <StyledTableCell align='center'>By Profit and loss appropriation A/c</StyledTableCell>
                                            {data[chartState.selected].partners.map((x, i) => (
                                                <StyledTableCell align='center'>{data[chartState.selected].partnersCurrentAccount[i]}</StyledTableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            {data[chartState.selected].partners.map((x, i) => (
                                                <StyledTableCell align='center'><b>{data[chartState.selected].debitBalanceTotal[i]}</b></StyledTableCell>
                                            ))}
                                            <StyledTableCell align='center'></StyledTableCell>
                                            <StyledTableCell align='center'></StyledTableCell>
                                            {data[chartState.selected].partners.map((x, i) => (
                                                <StyledTableCell align='center'><b>{data[chartState.selected].debitBalanceTotal[i]}</b></StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br /><br />
                            <div label="explanation" align="left">
                                <b>Explanation :</b><br /><br />
                                (1) The opening total capital of partners is ₹ {data[chartState.selected].capitalPartners}, it is in the proportion of&nbsp;
                                {data[chartState.selected].partners.map((x, i) => (
                                    <span>{x.fixedCapitalRatio}{i + 1 !== data[chartState.selected].partners.length && ":"}</span>
                                ))},&nbsp;
                                so capital of&nbsp;
                                {data[chartState.selected].partners.map((x, i) => (
                                    <span>{x.name} is ₹ {Math.round((parseFloat(data[chartState.selected].capitalPartners) * parseFloat(x.fixedCapitalRatio) / data[chartState.selected].totalFixedCapitalRatio)*100)/100}
                                        {data[chartState.selected].partners.length - 1 === i ?
                                            data[chartState.selected].partners.length - 2 === i ?
                                                "," : "" : " and "
                                        }
                                    </span>
                                ))}. This capital is considered as fixed capital and interest on capital is calculated on these amounts.<br /><br />
                                (2) Interest on drawings :&nbsp;
                                {data[chartState.selected].monthlyGapOfDrawing.map((x, i) => (
                                    <span>
                                        {data[chartState.selected].partners[i].name} for {x} months
                                        {i !== data[chartState.selected].partners.length - 1 && ", "}
                                    </span>
                                ))}.<br /><br />
                                (3) {data[chartState.selected].commissionPercentage} % commission on profit is&nbsp;
                                payable to {data[chartState.selected].commission.nameToBePaidCommission}, but after deduction of such commission from profit.<br /><br />
                                &emsp;&emsp;Assume, profit after commission is ₹ 100 where commission will be ₹ {data[chartState.selected].commissionPercentage}.&nbsp;
                                Therefore profit before deduction of commission is ₹ {100 + parseFloat(data[chartState.selected].commissionPercentage)} (100 + {data[chartState.selected].commissionPercentage}),&nbsp;
                                where ₹ {data[chartState.selected].commissionPercentage} commission are included.<br /><br />
                                &emsp;&emsp;∴&emsp;Commission of {data[chartState.selected].commission.nameToBePaidCommission} =&nbsp;
                                (<sup>{data[chartState.selected].commission.initialCommission} X {data[chartState.selected].commissionPercentage}</sup>/<sub>{100 + parseFloat(data[chartState.selected].commissionPercentage)}</sub>)<br />
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;= ₹ {data[chartState.selected].commission.commission}<br /><br />
                                (Total of credit side profit and loss appropriation account is ₹ {data[chartState.selected].profitLossAppropriationTotal} - (Interest on capital ₹ {data[chartState.selected].interestOnCapital}&nbsp;
                                + interest on current account ₹ {data[chartState.selected].commission.creditInterest}) = ₹ {data[chartState.selected].commission.initialCommission})<br /><br />
                                (4) Profit after commission (Divisible profit) = ₹ {data[chartState.selected].partnersCurrentAccountTotal} ( ₹ {data[chartState.selected].commission.initialCommission} - ₹ {data[chartState.selected].commission.commission}
                                &nbsp;{data[chartState.selected].extraCredit.map((x, i) => (
                                    <span>{"- " + x}</span>
                                ))})will be distributed amongst the partners in the ratio&nbsp;
                                {data[chartState.selected].partners.map((x, i) => (
                                    <span>
                                        {x.profitlossratio}{data[chartState.selected].partners.length - 1 !== i && ":"}
                                    </span>
                                ))}<br /><br />
                                {data[chartState.selected].partners.map((x, i) => (
                                    <span>
                                        Divisible profit of {x.name} = ₹ {data[chartState.selected].partnersCurrentAccountTotal} X <sup>{x.profitlossratio}</sup>/<sub>{data[chartState.selected].totalProfitLossRatio}</sub> <br /><br />
                                        &emsp;&emsp;&emsp;= ₹ {data[chartState.selected].partnersCurrentAccount[i]} <br /><br />
                                    </span>
                                ))}

                            </div>
                        </div>
                    </div>}
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

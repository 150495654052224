/* eslint-disable no-undef */
// This file is used to register material UI components with the our styles applied.

/* 
-------------------------------------------------------------
You can register new component and use it directly in any of 
the components with import 
----------------------------------------------------------------
 */

import {
    TabList
} from "@mui/lab";
import {
    Button,
    Grid,
    InputBase,
    Paper,
    TableCell,
    TextField,
    Autocomplete,
    Chip,
    Typography,
    Tabs,
    Tab,
    TableRow,
    tableCellClasses,
} from "@mui/material";
import {
    styled
} from "@mui/material/styles";
import {
    isMobile
} from "react-device-detect";

// Big Size Styled Button
const CustomizedButton = styled(Button)({
    boxShadow: "0 3px 6px #AFAFAF",
    background: "#fff",
    color: "#AFAFAF",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "16px",
    width: "300px",
    height: "250px",
    borderRadius: "18px",
    transition: "all 0.5s linear",
    margin: 0,
    "&:hover": {
        background: "#fff",
        boxShadow: "0 3px 6px #1CC9BE",
    },
});

// Styled Select Input
const BootstrapInput = styled(InputBase)(({
    theme
}) => ({
    root: {
        "& .MuiFormLabel-root-MuiInputLabel-root": {
            fontFamily: "var(--font-family)",
        },
        "& .MuiInputLabel-shrink": {
            fontFamily: "var(--font-family)",
        },
        fontFamily: "var(--font-family)",
    },
    "label + &": {
        fontFamily: "var(--font-family)",
        marginTop: "10px",
        boxShadow: "0 3px 6px #00000029",
        borderRadius: 18,
        // padding: "7px 15px",
    },
    "& .MuiInputBase-input": {
        borderRadius: 18,
        position: "relative",
        backgroundColor: "#ffffff",
        border: "none",
        fontSize: "var(--font-size)",
        padding: "7px 26px 7px 15px",
        boxShadow: "0 3px 6px #00000029",
        // Use the system font instead of the default Roboto font.
        fontFamily: "var(--font-family)",
        "&:focus": {
            boxShadow: "inset 0 3px 6px #b3b3b373",
            borderRadius: 18,
        },
        "& .MuiInput-input.Mui-disabled": {
            boxShadow: "0 3px 6px #00000029",
        }
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
        fontFamily: "var(--font-family)",
    },
}));

//   Styled Color button
const ColorButton = styled(Button)(({
    theme
}) => ({
    color: "#ffffff",
    backgroundColor: "#36CCA5",
    fontFamily: "var(--font-family)",
    textTransform: "capitalize",
    fontWeight: 600,
    justifyContent: "space-between",
    height: "33px",
    borderRadius: "18px",
    "&:hover": {
        backgroundColor: "#36CCA5",
    },
    "&:disabled":{
        backgroundColor: "#36CCA55D"
    }
}));

// Styled Custom Color button
const CustomColorButton = styled(Typography)(({
    theme
}) => ({
    color: "#ffffff",
    backgroundColor: "#36CCA5",
    fontFamily: "var(--font-family)",
    textTransform: "capitalize",
    fontWeight: 600,
    justifyContent: "space-between",
    height: "33px",
    borderRadius: "23px",
    position: "absolute",
    bottom: "10px",
    right: "8px",
    whiteSpace: "nowrap",
    padding: "2% 3%",
    "&:hover": {
        backgroundColor: "#36CCA5",
    },
}));

// to style add options button
const AddButton = styled(Button)(({
    theme
}) => ({
    color: "#ffffff",
    backgroundColor: "#36CCA5",
    fontFamily: "var(--font-family)",
    textTransform: "capitalize",
    fontWeight: 600,
    justifyContent: "space-evenly",
    width: "100%",
    borderRadius: 18,
    "&:hover": {
        backgroundColor: "#36CCA55D",
        border: "none",
        outline: "none",
        color: "#fff",
    },
    "&:disabled":{
        backgroundColor: "#36CCA55D"
    }
}));

// to style delete options button
const DeleteButton = styled(Button)(({
    theme
}) => ({
    color: "#ffffff",
    backgroundColor: "#f50057",
    fontFamily: "var(--font-family)",
    textTransform: "capitalize",
    fontWeight: 600,
    justifyContent: "space-evenly",
    width: "100%",
    borderRadius: 18,
    "&:hover": {
        backgroundColor: "#f500576a",
        border: "none",
        outline: "none",
        color: "#f5fffe",
    },
}));

// to style online exam button
const ActivateButton = styled(Button)(({
    theme
}) => ({
    "&:hover": {
        backgroundColor: "#36CCA5",
        border: "none",
        outline: "none",
        color: "#fff",
    },
}));

//   Styled Material UI Paper component
const Item = styled(Paper)(({
    theme
}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "100%",
}));

// to style input field
const BootstrapTextField = styled(TextField)(({
    theme
}) => ({
    "label + &": {
        // marginTop: theme.spacing(1),
        fontFamily: "var(--font-family)",
        borderRadius: 18,
    },
    "& .MuiInputBase-root-MuiFilledInput-root": {
        width: "100%",
        fontFamily: "var(--font-family)",
        borderRadius: 18,
    },
    "& .MuiFilledInput-root": {
        borderRadius: 18,
    },
    "& .MuiInputBase-input": {
        borderRadius: 18,
        position: "relative",
        border: "none",
        fontSize: "var(--font-size)",
        padding: "7px 15px",
        backgroundColor: "#ffffff",
        boxShadow: "0 3px 6px #00000029",
        // Use the system font instead of the default Roboto font.
        fontFamily: "var(--font-family)",
        width: "100%",
    },
    "& .MuiButtonBase-root-MuiMenuItem-root": {
        fontFamily: "var(--font-family)",
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
        fontFamily: "var(--font-family)",
    },
    "& input.Mui-disabled": {
        color: "#afafaf",
        boxShadow: "none",
        background: "none",
        WebkitTextFillColor: '#afafaf',
        // '-webkit-text-fill-color': '#afafaf',
    },
    "& .MuiInput-input.Mui-disabled": {
        color: "#afafaf",
        boxShadow: "none",
        background: "none",
        WebkitTextFillColor: '#afafaf',
        // '-webkit-text-fill-color': '#afafaf',
    }
}));

// to style options grid
const StyledGrid = styled(Grid)(({
    theme
}) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontFamily: "var(--font-family)",
    fontWeight: 500,
    color: "#707070",
    "& p": {
        padding: "0",
        margin: "0 0 0 10px",
    },
}));

// style view paper button
const LinkButton = styled(Button)(({
    theme
}) => ({
    background: "none",
    textDecoration: "underline",
    textTransform: "capitalize",
    color: "#AFAFAF",
    fontFamily: "var(--font-family)",
    fontWeight: 600,
    "&:hover": {
        backgroundColor: "transparent",
        color: "#1CC9BE",
    },
}));


// style view paper button
const ReverseLinkButton = styled(Button)(({
    theme
}) => ({
    background: "none",
    textDecoration: "none",
    textTransform: "capitalize",
    color: "#AFAFAF",
    fontFamily: "var(--font-family)",
    fontWeight: 600,
    "&:hover": {
        backgroundColor: "transparent",
        color: "#1CC9BE",
        textDecoration: "underline",
    },
}));

// style view paper button
const ResponseButton = styled(Button)(({
    theme
}) => ({
    background: "none",
    backgroundPosition: "200% 100%",
    textDecoration: "none",
    textTransform: "capitalize",
    color: "#1CC9BE",
    fontFamily: "var(--font-family)",
    fontWeight: 600,
    border: "1px solid #1CC9BE",
    borderRadius: "25px",
    // width: "30%",
    margin: "0 15px 15px 0",
    "&:hover": {
        backgroundPosition: "100% 100%",
        background: "#1CC9BE",
        color: "#ffffff",
    },
}));

// fixed arrow button with blink effect
const FixedBlinkingButton = styled(Button)(({
    theme
}) => ({
    color: "#ffffff",
    backgroundColor: "#36CCA5",
    borderRadius: "50%",
    position: "fixed",
    bottom: "100px",
    right: "10px",
    height: "40px",
    minWidth: "auto",
    // animation: "blink 0.7s infinite alternate",
    "&:hover": {
        backgroundColor: "#36CCA5",
        color: "#ffffff",
    },
}));

// style button of stepper
const StepperButton = styled(Button)(({
    theme
}) => ({
    color: "#ffffff",
    backgroundColor: "#36CCA5",
    borderRadius: 18,
    textTransform: "capitalize",
    fontFamily: "var(--font-family)",
    fontWeight: 600,
    padding: "5px 40px",
    "&:hover": {
        backgroundColor: "#36CCA5",
        color: "#ffffff",
    },
}));

// style login page textfields
const RedditTextField = styled(TextField)(({
    theme
}) => ({
    marginBottom: 2,
    "& .MuiFilledInput-root": {
        border: "none",
        overflow: "hidden",
        borderRadius: 18,
        backgroundColor: "#ffffff",
        boxShadow: "0 3px 6px #00000029",
        transition: theme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
        ]),
        color: "#afafaf",
        fontFamily: "var(--font-family)",
        fontWeight: 600,
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-focused": {
            backgroundColor: "#ffffff",
            boxShadow: "0 3px 6px #4d4d4d73",
        },
    },
    "& .MuiFilledInput-input": {
        padding: "15px",
        height: "1em",
    },
}));

const StyledChip = styled(Chip)(({
    bgcolor
}) => ({
    color: 'white',
    backgroundColor: "#1CC9BE",
}));

const StyledTabs = styled((props) => ( <
    TabList {
        ...props
    }
    TabIndicatorProps = {
        {
            children: < span className = "MuiTabs-indicatorSpan" / >
        }
    }
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#1cc9be',
    },
});

const StyledTab = styled((props) => < Tab disableRipple {
        ...props
    }
    />)(
    ({
        theme
    }) => ({
        textTransform: 'none',
        fontFamily: "var(--font-family)",
        marginRight: theme.spacing(1),
        color: '#afafaf',
        '&.Mui-selected': {
            color: '#1cc9be',
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#1cc9beb3',
        },
    }),
);

const StyledTableCell = styled(TableCell)(({
    theme
}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#1cc9be",
        color: "#ffffff",
        fontFamily: "var(--font-family)",
        fontWeight: 700,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "var(--font-family)",
        fontWeight: 600,
    },
}));

const StyledTableRow = styled(TableRow)(({
    theme
}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#f5fffe",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export {
    CustomizedButton,
    BootstrapInput,
    ColorButton,
    AddButton,
    DeleteButton,
    Item,
    StyledTableCell,
    StyledTableRow,
    BootstrapTextField,
    StyledGrid,
    LinkButton,
    FixedBlinkingButton,
    RedditTextField,
    ActivateButton,
    ResponseButton,
    StyledChip,
    StepperButton,
    ReverseLinkButton,
    CustomColorButton,
    StyledTabs,
    StyledTab,
};
import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {

    const data = [{
        "companyName": "Sheela Ltd.",
        "purchasedAssetName": "machine",
        "purchasedAssetFor": 48000,
        "purchasedDate": "2012-04-01",
        "installationExpense": 2000,
        "estimatedLifeOfAsset": 10,
        "scrapValueOfAsset": 10000,
        "endingDate": "31",
        "endingMonth": "March",
        "totalDateArray": [
            "2013-03-31",
            "2014-03-31",
            "2015-03-31"
        ],
        "array1": [
            {
                "date": "2013-03-31",
                "amount": 4000
            },
            {
                "date": "2013-03-31",
                "amount": 4000
            },
            {
                "date": "2014-03-31",
                "amount": 4000
            },
            {
                "date": "2014-03-31",
                "amount": 4000
            },
            {
                "date": "2015-03-31",
                "amount": 4000
            },
            {
                "date": "2015-03-31",
                "amount": 4000
            }
        ],
        "array1Total": 74000,
        "costPrice": 50000,
        "annualDepreciation": 4000,
        "rateOfDepreciation": 8
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                companyName: "Sheela Ltd.",
                purchasedAssetName: "machine",
                purchasedAssetFor: 48000,
                purchasedDate: "2012-04-01",
                installationExpense: 2000,
                estimatedLifeOfAsset: 10,
                scrapValueOfAsset: 10000,
                endingDate: "31",
                endingMonth: "March",


                editModecompanyName: false,
                editModepurchasedAssetName: false,
                editModepurchasedAssetFor: false,
                editModepurchasedDate: false,
                editModeinstallationExpense: false,
                editModeestimatedLifeOfAsset: false,
                editModescrapValueOfAsset: false,
                editModeendingDate: false,
                editModeendingMonth: false,

                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            switch (event.target.name) {
                case 'companyName':
                    this.setState({
                        companyName: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'purchasedAssetName':
                    this.setState({
                        purchasedAssetName: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'purchasedAssetFor':
                    this.setState({
                        purchasedAssetFor: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'purchasedDate':
                    this.setState({
                        purchasedDate: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'installationExpense':
                    this.setState({
                        installationExpense: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'estimatedLifeOfAsset':
                    this.setState({
                        estimatedLifeOfAsset: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'scrapValueOfAsset':
                    this.setState({
                        scrapValueOfAsset: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'endingDate':
                    this.setState({
                        endingDate: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'endingMonth':
                    this.setState({
                        endingMonth: event.target.value
                    }, () => { this.fillDetails() })
                    break;
            }
        }

        monthChange(event) {
            this.setState({
                endingMonth: event.target.value
            })
        }

        fillDetails() {
            let flag = true

            if(this.state.companyName === ''){
                flag = false
            }
            if(this.state.purchasedAssetName === ''){
                flag = false
            }
            if(this.state.purchasedAssetFor === '' || !NumberRegex.test(this.state.purchasedAssetFor)){
                flag = false
            }
            if(this.state.installationExpense === '' || !NumberRegex.test(this.state.installationExpense)){
                flag = false
            }
            if(this.state.estimatedLifeOfAsset === '' || !NumberRegex.test(this.state.estimatedLifeOfAsset)){
                flag = false
            }
            if(this.state.scrapValueOfAsset === '' || !NumberRegex.test(this.state.scrapValueOfAsset)){
                flag = false
            }
            if(this.state.endingDate === '' || !NumberRegex.test(this.state.endingDate) || parseFloat(this.state.endingDate) < 1 || parseFloat(this.state.endingDate) > 31){
                flag = false
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker:true
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker:false
                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            let monthNumber = ''

            switch (this.state.endingMonth) {
                case 'January':
                    monthNumber = '01'
                    break;
                case 'February':
                    monthNumber = '02'
                    break;
                case 'March':
                    monthNumber = '03'
                    break;
                case 'April':
                    monthNumber = '04'
                    break;
                case 'May':
                    monthNumber = '05'
                    break;
                case 'June':
                    monthNumber = '06'
                    break;
                case 'July':
                    monthNumber = '07'
                    break;
                case 'August':
                    monthNumber = '08'
                    break;
                case 'September':
                    monthNumber = '09'
                    break;
                case 'October':
                    monthNumber = '10'
                    break;
                case 'November':
                    monthNumber = '11'
                    break;
                case 'December':
                    monthNumber = '12'
                    break;
            }

            let totalDateArray = []
            // totalDateArray.push(this.state.purchasedDate)

            totalDateArray.push(String(parseInt(this.state.purchasedDate.slice(0, 4)) + 1) + "-" + monthNumber + "-" + this.state.endingDate)
            totalDateArray.push(String(parseInt(this.state.purchasedDate.slice(0, 4)) + 2) + "-" + monthNumber + "-" + this.state.endingDate)
            totalDateArray.push(String(parseInt(this.state.purchasedDate.slice(0, 4)) + 3) + "-" + monthNumber + "-" + this.state.endingDate)

            let costPrice = parseFloat(this.state.purchasedAssetFor) + parseFloat(this.state.installationExpense)
            let annualDepreciation = (costPrice - parseFloat(this.state.scrapValueOfAsset)) / parseFloat(this.state.estimatedLifeOfAsset)
            let rateOfDepreciation = (annualDepreciation / costPrice) * 100

            let array1 = []
            let array2 = []

            for (let i in totalDateArray) {
                array1.push({
                    date: totalDateArray[i],
                    amount: annualDepreciation
                })
                array1.push({
                    date: totalDateArray[i],
                    amount: annualDepreciation
                })
            }

            let dataObject = {
                companyName: this.state.companyName,
                purchasedAssetName: this.state.purchasedAssetName,
                purchasedAssetFor: this.state.purchasedAssetFor,
                purchasedDate: this.state.purchasedDate,
                installationExpense: this.state.installationExpense,
                estimatedLifeOfAsset: this.state.estimatedLifeOfAsset,
                scrapValueOfAsset: this.state.scrapValueOfAsset,
                endingDate: this.state.endingDate,
                endingMonth: this.state.endingMonth,

                totalDateArray,
                array1,
                array1Total: array1.reduce((a, b) => (a + b.amount), 0) + costPrice,
                costPrice,
                annualDepreciation,
                rateOfDepreciation
            }

            // data.push(JSON.parse(JSON.stringify(dataObject)))

            // chartState.selected += 1

            this.setState({})

            console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            switch (event.target.name) {
                case 'companyName':
                    this.setState({
                        editModecompanyName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'purchasedAssetName':
                    this.setState({
                        editModepurchasedAssetName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'purchasedAssetFor':
                    this.setState({
                        editModepurchasedAssetFor: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'purchasedDate':
                    this.setState({
                        editModepurchasedDate: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'installationExpense':
                    this.setState({
                        editModeinstallationExpense: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'estimatedLifeOfAsset':
                    this.setState({
                        editModeestimatedLifeOfAsset: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'scrapValueOfAsset':
                    this.setState({
                        editModescrapValueOfAsset: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'endingDate':
                    this.setState({
                        editModeendingDate: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'endingMonth':
                    this.setState({
                        editModeendingMonth: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;

            }
        }

        editModeOff() {
            this.setState({
                editModecompanyName: false,
                editModepurchasedAssetName: false,
                editModepurchasedAssetFor: false,
                editModepurchasedDate: false,
                editModeinstallationExpense: false,
                editModeestimatedLifeOfAsset: false,
                editModescrapValueOfAsset: false,
                editModeendingDate: false,
                editModeendingMonth: false,

                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >

                        {this.state.editModecompanyName ? <TextField style={{ 'margin-top': "-15px", }} name="companyName" id='outlined-base' error={this.state.companyName === ''}
                            value={this.state.companyName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="companyName" onClick={this.buttonMaker.bind(this)}> {this.state.companyName}</a>}&nbsp;

                        has purchased a&nbsp;
                        {this.state.editModepurchasedAssetName ? <TextField style={{ 'margin-top': "-15px", }} name="purchasedAssetName" id='outlined-base' error={this.state.purchasedAssetName === ''}
                            value={this.state.purchasedAssetName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="purchasedAssetName" onClick={this.buttonMaker.bind(this)}> {this.state.purchasedAssetName}</a>}&nbsp;

                        for ₹&nbsp;
                        {this.state.editModepurchasedAssetFor ? <TextField style={{ 'margin-top': "-15px", }} name="purchasedAssetFor" id='outlined-base' error={this.state.purchasedAssetFor === '' || isNaN(this.state.purchasedAssetFor)}
                            value={this.state.purchasedAssetFor} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="purchasedAssetFor" onClick={this.buttonMaker.bind(this)}> {this.state.purchasedAssetFor}</a>}&nbsp;

                        on&nbsp;
                        {this.state.editModepurchasedDate ? <TextField type="date" style={{ 'margin-top': "-15px", }} name="purchasedDate" id='outlined-base' error={this.state.purchasedDate === ''}
                            value={this.state.purchasedDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="purchasedDate" onClick={this.buttonMaker.bind(this)}> {this.state.purchasedDate}</a>}.<br /><br />


                        Installation expense of this {this.state.purchasedAssetName} was ₹&nbsp;
                        {this.state.editModeinstallationExpense ? <TextField style={{ 'margin-top': "-15px", }} name="installationExpense" id='outlined-base' error={this.state.installationExpense === '' || isNaN(this.state.installationExpense)}
                            value={this.state.installationExpense} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="installationExpense" onClick={this.buttonMaker.bind(this)}> {this.state.installationExpense}</a>}.<br /><br />

                        An estimated life of this {this.state.purchasedAssetName} is&nbsp;
                        {this.state.editModeestimatedLifeOfAsset ? <TextField style={{ 'margin-top': "-15px", }} name="estimatedLifeOfAsset" id='outlined-base' error={this.state.estimatedLifeOfAsset === '' || isNaN(this.state.estimatedLifeOfAsset)}
                            value={this.state.estimatedLifeOfAsset} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="estimatedLifeOfAsset" onClick={this.buttonMaker.bind(this)}> {this.state.estimatedLifeOfAsset}</a>}&nbsp;

                        years and scrap value of {this.state.purchasedAssetName} is expected to be ₹&nbsp;
                        {this.state.editModescrapValueOfAsset ? <TextField style={{ 'margin-top': "-15px", }} name="scrapValueOfAsset" id='outlined-base' error={this.state.scrapValueOfAsset === '' || isNaN(this.state.scrapValueOfAsset)}
                            value={this.state.scrapValueOfAsset} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="scrapValueOfAsset" onClick={this.buttonMaker.bind(this)}> {this.state.scrapValueOfAsset}</a>}.<br /><br />

                        Accounting year of the company is ending on&nbsp;
                        {this.state.editModeendingDate ? <TextField style={{ 'margin-top': "-15px", }} name="endingDate" id='outlined-base' error={this.state.endingDate === '' || isNaN(this.state.endingDate) || this.state.endingDate < 1 || this.state.endingDate > 31}
                            value={this.state.endingDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="endingDate" onClick={this.buttonMaker.bind(this)}> {this.state.endingDate}</a>}&nbsp;

                        {this.state.editModeendingMonth ?
                            <FormControl style={{ width: "20%" }} size="small">
                                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                <Select
                                    labelId="month"
                                    id="month"
                                    value={this.state.endingMonth}
                                    label="Month"
                                    onChange={this.monthChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"January"}>January</MenuItem>
                                    <MenuItem value={"February"}>February</MenuItem>
                                    <MenuItem value={"March"}>March</MenuItem>
                                    <MenuItem value={"April"}>April</MenuItem>
                                    <MenuItem value={"May"}>May</MenuItem>
                                    <MenuItem value={"June"}>June</MenuItem>
                                    <MenuItem value={"July"}>July</MenuItem>
                                    <MenuItem value={"August"}>August</MenuItem>
                                    <MenuItem value={"September"}>September</MenuItem>
                                    <MenuItem value={"October"}>October</MenuItem>
                                    <MenuItem value={"November"}>November</MenuItem>
                                    <MenuItem value={"December"}>December</MenuItem>
                                </Select>
                            </FormControl> :
                            <a className='txt' name="endingMonth" onClick={this.buttonMaker.bind(this)}> {this.state.endingMonth}</a>
                        }.<br /><br />

                        Determine annual depreciation and annual depreciation rate under straight line of depreciation, and also prepare Journal entries, {this.state.purchasedAssetName} account and depreciation accounts for first three years.&nbsp;
                        Disclose accounting effect of it in final accounts of first year.

                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>Ans. :</b><br />
                            <b>(1) Annual depreciation :</b> D = <sup>C - S</sup>/<sub>N</sub><br />
                            D = Amount of annual depreciation = ?<br />
                            C = Cost price = Purchase price + Installation expense<br />
                            &emsp;= {data[chartState.selected].purchasedAssetFor} + {data[chartState.selected].installationExpense} = ₹ {data[chartState.selected].costPrice}<br />
                            S = Scrap value = ₹ {data[chartState.selected].scrapValueOfAsset}<br />
                            N = Total life in years = {data[chartState.selected].estimatedLifeOfAsset} years
                            D = <sup>{data[chartState.selected].costPrice} - {data[chartState.selected].scrapValueOfAsset}</sup>/<sub>{data[chartState.selected].estimatedLifeOfAsset}</sub>&nbsp;
                            = <sup>{data[chartState.selected].costPrice - data[chartState.selected].scrapValueOfAsset}</sup>/<sub>{data[chartState.selected].estimatedLifeOfAsset}</sub>&nbsp;
                            = ₹ {data[chartState.selected].annualDepreciation}<br /><br />

                            <b>(2) Rate of depreciation :</b><br />
                            R = <sup>D</sup>/<sub>C</sub> X 100<br />
                            &emsp;= <sup>{data[chartState.selected].annualDepreciation}</sup>/<sub>{data[chartState.selected].costPrice}</sub> X 100<br />
                            R = {data[chartState.selected].rateOfDepreciation} %<br />
                            <b>Annual rate of depreciation {data[chartState.selected].rateOfDepreciation} %</b> <br /><br />

                            <div align='center'><b>Journal Entries in the Books of {data[chartState.selected].companyName}</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Date</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Debit (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Credit (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top" }}>{data[chartState.selected].purchasedDate}</StyledTableCell>
                                            <StyledTableCell align="left">
                                                {data[chartState.selected].purchasedAssetName} A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                &emsp;&emsp;To bank A/c<br />
                                                &emsp;&emsp;To cash A/c<br />
                                                [Being {data[chartState.selected].purchasedAssetName} of ₹ {data[chartState.selected].purchasedAssetFor} purchased and payment&nbsp;
                                                is made by cheque and installation expense ₹ {data[chartState.selected].installationExpense} paid in cash.]
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>{data[chartState.selected].costPrice}</StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].purchasedAssetFor}<br />
                                                {data[chartState.selected].installationExpense}
                                            </StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].array1.map((x, i) => (
                                            i % 2 === 0 ?
                                                <TableRow>
                                                    <StyledTableCell align='center' style={{ verticalAlign: "top" }}>{x.date}</StyledTableCell>
                                                    <StyledTableCell>
                                                        Depreciation A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                        &emsp;&emsp;To {data[chartState.selected].purchasedAssetName} A/c<br/>
                                                        (Being depreciation of ₹ {x.amount} is recorded.)
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                        {x.amount}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                        <br />
                                                        {x.amount}
                                                    </StyledTableCell>
                                                </TableRow> :
                                                <TableRow>
                                                    <StyledTableCell align='center' style={{ verticalAlign: "top" }}>{x.date}</StyledTableCell>
                                                    <StyledTableCell>
                                                        Profit and Loss A/c <span style={{ float: "right" }}>Dr.</span><br />
                                                        &emsp;&emsp;To depreciation A/c<br/>
                                                        (Being depreciation of ₹ {x.amount} transferred to profit and loss account.)
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                        {x.amount}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                        <br />
                                                        {x.amount}
                                                    </StyledTableCell>
                                                </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>Total</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].array1Total}</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].array1Total}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align="center"><b>Ledger of {data[chartState.selected].companyName}</b></div>
                            <div align="center"><b>{data[chartState.selected].purchasedAssetName} account</b></div>
                            <span style={{ float: "left" }}>Dr</span><span style={{ float: "right" }}>Cr</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Date</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Date</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].array1.map((x, i) => (
                                            i % 2 === 0 &&
                                            <TableRow>
                                                <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                    {data[chartState.selected].totalDateArray[i / 2].slice(0, 4) + data[chartState.selected].purchasedDate.slice(4,)}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ verticalAlign: "top" }}>
                                                    {i / 2 === 0 ?
                                                        <span>
                                                            To Bank A/c<br />
                                                            To Cash A/c
                                                        </span> :
                                                        <span>
                                                            To balance b/d
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {i / 2 === 0 ?
                                                        <span>
                                                            {data[chartState.selected].purchasedAssetFor}<br />
                                                            {data[chartState.selected].installationExpense}<br />
                                                            <b>{data[chartState.selected].costPrice}</b>
                                                        </span> :
                                                        <span>
                                                            {data[chartState.selected].costPrice - i / 2 * data[chartState.selected].annualDepreciation}<br />
                                                            <br />
                                                            <b>{data[chartState.selected].costPrice - i / 2 * data[chartState.selected].annualDepreciation}</b>
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                    {x.date}<br />
                                                    {x.date}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ verticalAlign: "top" }}>
                                                    By Depreciation A/c<br />
                                                    By balance c/d
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x.amount}<br />
                                                    {data[chartState.selected].costPrice - (i / 2 + 1) * data[chartState.selected].annualDepreciation}<br />
                                                    <b>{data[chartState.selected].costPrice - i / 2 * data[chartState.selected].annualDepreciation}</b>
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align='center'><b>Depreciation Account</b></div>
                            <span style={{ float: "left" }}>Dr</span><span style={{ float: "right" }}>Cr</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Date</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Date</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].array1.map((x, i) => (
                                            i % 2 === 0 &&
                                            <TableRow>
                                                <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                    {x.date}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ verticalAlign: "top" }}>
                                                    To {data[chartState.selected].purchasedAssetName} A/c
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x.amount}<br />
                                                    <b>{x.amount}</b>
                                                </StyledTableCell>
                                                <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                    {x.date}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ verticalAlign: "top" }}>
                                                    By profit and loss A/c
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x.amount}<br />
                                                    <b>{x.amount}</b>
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align='center'><b>Accounting Effect in Annual Accounts</b></div><br />
                            <div align='center'><b>Profit and Loss Account</b></div><br />
                            <div align='center'><b>for the Year Ending {data[chartState.selected].totalDateArray[0]}</b></div><br />
                            <span style={{ float: "left" }}>Dr</span><span style={{ float: "right" }}>Cr</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell>To {data[chartState.selected].purchasedAssetName} depreciation A/c</StyledTableCell>
                                            <StyledTableCell align='center'>{data[chartState.selected].annualDepreciation}</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align='center'><b>Balance Sheet as at {data[chartState.selected].totalDateArray[0]}</b></div><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Capital-Liabilities</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Assets-Debts</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell>
                                                {data[chartState.selected].purchasedAssetName}<span style={{ float: "right" }}>{data[chartState.selected].costPrice}</span><br />
                                                - Depreciation <span style={{ float: "right" }}>{data[chartState.selected].annualDepreciation}</span>
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "bottom" }}>
                                                {data[chartState.selected].costPrice - data[chartState.selected].annualDepreciation}
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

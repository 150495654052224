import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {

    const data = [{
        "answerPallete": [
            {
                "time": 110,
                "days": 7,
                "f": -10,
                "fd": -70
            },
            {
                "time": 113,
                "days": 17,
                "f": -7,
                "fd": -119
            },
            {
                "time": 120,
                "days": 11,
                "f": 0,
                "fd": 0
            },
            {
                "time": 122,
                "days": 10,
                "f": 2,
                "fd": 20
            },
            {
                "time": 126,
                "days": 5,
                "f": 6,
                "fd": 30
            }
        ],
        "assumedA": 120,
        "totaldays": 50,
        "totalfd": -139,
        "gcdTime": 1,
        "mean": 117.22,
        "type": "min"
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                name: "સમય",
                type: "min",
                travelTime: [
                    {
                        time: 110,
                        days: 7
                    }, {
                        time: 113,
                        days: 17
                    }, {
                        time: 120,
                        days: 11
                    }, {
                        time: 122,
                        days: 10
                    }, {
                        time: 126,
                        days: 5
                    }
                ],

                editModetravelTime: [
                    {
                        time: false,
                        days: false
                    }, {
                        time: false,
                        days: false
                    }, {
                        time: false,
                        days: false
                    }, {
                        time: false,
                        days: false
                    }, {
                        time: false,
                        days: false
                    }
                ],

                editModename: false,
                editModetype: false,
                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'time':
                        this.state.travelTime[customArray[1]].time = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'days':
                        this.state.travelTime[customArray[1]].days = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'name':
                    this.setState({
                        name: event.target.value
                    }, () => { this.fillDetails() })
                    break;

            }
        }

        handleChange(event) {
            this.setState({
                type: event.target.value
            })
        }



        fillDetails() {
            let flag = true

            for(let i of this.state.travelTime){
                if(!NumberRegex.test(i.days) || i.days === ''){
                    flag= false
                }
                if(!NumberRegex.test(i.time) || i.time === ''){
                    flag= false
                }
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        autoSave(event) {
            // if (this.state.totalPurchase !== '' && this.state.cashPurchase !== '' && this.state.purchaseReturn !== '' && this.state.openingTradePayables !== '' && this.state.closingTradePayables !== '') {
            if (event.key === 'Enter') {
                this.editModeOff()
            }
            // }
        }

        //#endregion EVENT FUNCTIONALTIES END



        //#region GREATEST COMMON DIVISOR START 

        gcd(a, b) {
            if (a == 0)
                return b;
            return this.gcd(b % a, a);
        }

        findGCD(arr, n) {
            let result = arr[0];
            for (let i = 1; i < n; i++) {
                result = this.gcd(arr[i], result);

                if (result == 1) {
                    return 1;
                }
            }
            return result;
        }

        //#endregion GREATEST COMMON DIVISOR END


        //#region BUTTON PANEL START

        onSubmit() {

            let timeArray = []

            for (let i of this.state.travelTime) {
                timeArray.push(i.time)
            }

            let gcdTime = this.findGCD(timeArray, timeArray.length)

            timeArray = timeArray.sort((a, b) => { return a - b })

            let assumedA = timeArray[Math.floor(timeArray.length / 2)]

            let answerPallete = []

            let totaldays = 0
            let totalfd = 0

            for (let i of this.state.travelTime) {
                answerPallete.push({
                    time: i.time,
                    days: i.days,
                    f: Math.round(((parseFloat(i.time) - assumedA) / gcdTime) * 100) / 100,
                    fd: i.days * Math.round(((parseFloat(i.time) - assumedA) / gcdTime) * 100) / 100
                })
                totaldays += i.days
                totalfd += i.days * Math.round(((parseFloat(i.time) - assumedA) / gcdTime) * 100) / 100
            }

            let dataObject = {
                answerPallete,
                assumedA,
                totaldays,
                totalfd,
                gcdTime,
                mean: assumedA + Math.round(((totalfd / totaldays) * gcdTime) * 100) / 100,
                type:this.state.type
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addRow() {
            this.state.travelTime.push({
                time: 100,
                days: 10
            })
            this.state.editModetravelTime.push({
                time: true,
                days: true
            })
            this.setState({
                editModeButton: true
            })
        }

        removeRow(event) {
            this.state.travelTime.splice(event, 1)
            this.state.editModetravelTime.splice(event, 1)
            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'time':
                        this.state.editModetravelTime[customArray[1]].time = true
                        this.setState({
                            editModeButton: true,
                            submitChecker: false
                        })
                        break;
                    case 'days':
                        this.state.editModetravelTime[customArray[1]].days = true
                        this.setState({
                            editModeButton: true,
                            submitChecker: false
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'type':
                    this.setState({
                        editModetype: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {

            for (let i of this.state.editModetravelTime) {
                i.days = false
                i.time = false
            }

            this.setState({

                submitChecker: true,

                editModename: false,
                editModetype: false,
                editModesampleString: false,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                    એક બસનો બે શહેરો વચ્ચેનો જુદા જુદા દિવસ લેવાયેલ પ્રવાસનો સમય&nbsp;
                        {this.state.editModetype ?
                            <FormControl style={{ width: "9%" }}>
                                <InputLabel id="demo-simple-select-label" style={{fontSize:"20px"}}>ઉમેરવાનો વિકલ્પ</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.type}
                                    label="Option to Add"
                                    onChange={this.handleChange.bind(this)}
                                >
                                    <MenuItem value={"ms"}>ms</MenuItem>
                                    <MenuItem value={"s"}>s</MenuItem>
                                    <MenuItem value={"min"}>min</MenuItem>
                                    <MenuItem value={"hr"}>hr</MenuItem>
                                </Select>
                            </FormControl> :
                            <a className='txt' name="type" onClick={this.buttonMaker.bind(this)}>({this.state.type})</a>}&nbsp;
                        નીચેના કોષ્ટકમાં દર્શાવેલ છે :
                        <br />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>સમય ({this.state.type})</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>દિવસોની સંખ્યા</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.travelTime.map((x, i) =>
                                        <TableRow key={i}>
                                            <StyledTableCell align="center">
                                                {this.state.editModetravelTime[i].time ? <TextField style={{ "width": "20%", "padding": "-10px" }} name={["time", i]} id='outlined-base' error={x.time === '' || isNaN(x.time)}
                                                    value={x.time} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["time", i]} onClick={this.buttonMaker.bind(this)}> {x.time}</a>}&nbsp;
                                                {this.state.travelTime.length > 2 && <IconButton style={{ width: "3%", color: "red" }} onClick={this.removeRow.bind(this, i)} name="-">-</IconButton>}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {this.state.editModetravelTime[i].days ? <TextField style={{ "width": "20%", "padding": "-10px" }} name={["days", i]} id='outlined-base' error={x.days === '' || isNaN(x.days)}
                                                    value={x.days} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["days", i]} onClick={this.buttonMaker.bind(this)}> {x.days}</a>}
                                            </StyledTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />
                        <AddButton style={{ width: "15%", fontSize:"20px" }} onClick={this.addRow.bind(this)}>દાખલો ઉમેરો</AddButton><br /><br />
                        પ્રવાસના સમયનો મધ્યક શોધો.

                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px"}} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            અહીં અવલોકનોની કિંમતો મોટી છે. આપણે ધારેલ મધ્યક <i>A</i> = {data[chartState.selected].assumedA} લઈશું.<br />
                            Aમાંથી લીધેલ તફાવતો<br />
                            {data[chartState.selected].answerPallete.map((x, i) => (
                                <span>{x.time} - {data[chartState.selected].assumedA} = {x.f} <br /></span>
                            ))}

                            તેમાં {data[chartState.selected].gcdTime} સિવાય કોઈ અન્ય સામાન્ય અવયવ નથી. તેથી <i>c</i> = {data[chartState.selected].gcdTime} લઈશું.<br />

                            આમ, આપણે <i>d</i> = <sup><i>(x - A)</i></sup>/<sub>c</sub> = <sup>(<i>x</i> - {data[chartState.selected].assumedA})</sup>/<sub>{data[chartState.selected].gcdTime} લઈશું.</sub><br /><br />
                            મધ્યકની ગણતરી નીચે પ્રમાણે થશે :
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>સમય ({this.state.type})<br /><i>x</i></StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>દિવસોની સંખ્યા<br /><i>f</i></StyledTableCell>
                                            <StyledTableCell align="center"><i>d = x - A</i><br /><i>A</i> = {data[chartState.selected].assumedA}</StyledTableCell>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top" }}><i>fd</i></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].answerPallete.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center">{x.time}</StyledTableCell>
                                                <StyledTableCell align="center">{x.days}</StyledTableCell>
                                                <StyledTableCell align="center">{x.f}</StyledTableCell>
                                                <StyledTableCell align="center">{x.fd}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}><b>કુલ</b></StyledTableCell>
                                            <StyledTableCell align="center"><b><i>n</i> = {data[chartState.selected].totaldays}</b></StyledTableCell>
                                            <StyledTableCell align="center"><b></b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].totalfd}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            મધ્યક x̄ = <i>A</i> + <sup>Σ<i>fd</i></sup>/<sub><i>n</i></sub> x <i>c</i><br/>
                            &emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].assumedA} + <sup>{data[chartState.selected].totalfd}</sup>/<sub>{data[chartState.selected].totaldays}</sub> x {data[chartState.selected].gcdTime}<br/>
                            &emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].assumedA} + {(Math.round((data[chartState.selected].totalfd/data[chartState.selected].totaldays)*100)/100)*(data[chartState.selected].gcdTime)}<br/>
                            &emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].mean}<br/><br/>

                            આમ, બસના પ્રવાસ માટેના સમયનો મધ્યક {data[chartState.selected].mean} {data[chartState.selected].type==="ms" && "milliseconds"} {data[chartState.selected].type==="s" && "seconds"} {data[chartState.selected].type==="min" && "minutes"} {data[chartState.selected].type==="hr" && "hours"} છે.
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

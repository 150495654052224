import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {
    const data = [{
        "totalFrequency": 50,
        "classesWithFrequency": [
            {
                "lower": 0,
                "upper": 39,
                "frequency": 2,
                "cumulativeFrequency": 2
            },
            {
                "lower": 40,
                "upper": 79,
                "frequency": 14,
                "cumulativeFrequency": 16
            },
            {
                "lower": 80,
                "upper": 119,
                "frequency": 23,
                "cumulativeFrequency": 39
            },
            {
                "lower": 120,
                "upper": 159,
                "frequency": 7,
                "cumulativeFrequency": 46
            },
            {
                "lower": 160,
                "upper": 199,
                "frequency": 4,
                "cumulativeFrequency": 50
            }
        ],
        "medianClass": 25,
        "medianClassValues": {
            "lower": 79.5,
            "upper": 119.5,
            "cf": 16,
            "cfhigher": 39,
            "f": 23
        },
        "medianA": 15.6522,
        "medianB": 95.1522,
        "classLength": 40
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }


    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                classesWithFrequency: [
                    {
                        lower: 0,
                        upper: 39,
                        frequency: 2
                    }, {
                        lower: 40,
                        upper: 79,
                        frequency: 14
                    }, {
                        lower: 80,
                        upper: 119,
                        frequency: 23
                    }, {
                        lower: 120,
                        upper: 159,
                        frequency: 7
                    }, {
                        lower: 160,
                        upper: 199,
                        frequency: 4
                    }
                ],
                classLength: 40,

                editModeclassesWithFrequency: [
                    {
                        lower: false,
                        frequency: false
                    }, {
                        frequency: false
                    }, {
                        frequency: false
                    }, {
                        frequency: false
                    }, {
                        frequency: false
                    }
                ],
                editModeclassLength: false,

                editModetotalSets: false,
                editModesampleString: false,
                editModenumberOfClass: false,
                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.changeFrequency = this.changeFrequency.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'frequency':
                        this.state.classesWithFrequency[customArray[1]].frequency = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'lowerClass':
                        this.state.classesWithFrequency[customArray[1]].lower = event.target.value
                        this.setState({}, () => {
                            this.changeFrequency()
                            this.fillDetails()
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'classLength':
                    this.setState({
                        classLength: event.target.value
                    }, () => {
                        this.changeFrequency()
                        this.fillDetails()
                    })
            }
        }

        changeFrequency() {
            for (let i in this.state.classesWithFrequency) {
                this.state.classesWithFrequency[i].upper = parseFloat(this.state.classesWithFrequency[0].lower) + (parseFloat(this.state.classLength) * (parseInt(i) + 1)) - 1
                if (parseInt(i) !== 0) {
                    this.state.classesWithFrequency[i].lower = parseFloat(this.state.classesWithFrequency[0].lower) + (parseFloat(this.state.classLength) * (parseInt(i)))
                }
            }
        }

        fillDetails() {
            let flag = true


            if (!NumberRegex.test(this.state.classLength) || this.state.classLength === '') {
                flag = false
            }

            if (!NumberRegex.test(this.state.classesWithFrequency[0].lower) || this.state.classesWithFrequency[0].lower === '') {
                flag = false
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            let cumulativeFrequency = 0

            for (let i of this.state.classesWithFrequency) {
                cumulativeFrequency += parseFloat(i.frequency)
                i.cumulativeFrequency = cumulativeFrequency
            }

            let medianClass = Math.ceil(this.state.classesWithFrequency[this.state.classesWithFrequency.length - 1].cumulativeFrequency / 2)

            let medianClassValues

            for (let i in this.state.classesWithFrequency) {
                if (parseInt(i) === 0) {
                    if (medianClass <= this.state.classesWithFrequency[i].cumulativeFrequency) {
                        medianClassValues = {
                            lower: parseFloat(this.state.classesWithFrequency[i].lower) - 0.5,
                            upper: this.state.classesWithFrequency[i].upper + 0.5,
                            cf: 0,
                            cfhigher: this.state.classesWithFrequency[i].cumulativeFrequency,
                            f: this.state.classesWithFrequency[i].frequency
                        }
                    }
                } else {
                    if (medianClass <= this.state.classesWithFrequency[i].cumulativeFrequency && medianClass > this.state.classesWithFrequency[i - 1].cumulativeFrequency) {
                        medianClassValues = {
                            lower: parseFloat(this.state.classesWithFrequency[i].lower) - 0.5,
                            upper: this.state.classesWithFrequency[i].upper + 0.5,
                            cf: this.state.classesWithFrequency[i - 1].cumulativeFrequency,
                            cfhigher: this.state.classesWithFrequency[i].cumulativeFrequency,
                            f: this.state.classesWithFrequency[i].frequency,
                        }
                    }
                }
            }

            let medianA = Math.round((medianClass - medianClassValues.cf) / medianClassValues.f * parseFloat(this.state.classLength) * 10000) / 10000

            let dataObject = {
                totalFrequency: this.state.classesWithFrequency[this.state.classesWithFrequency.length - 1].cumulativeFrequency,
                classesWithFrequency: this.state.classesWithFrequency,
                medianClass,
                medianClassValues,
                medianA,
                medianB: medianA + medianClassValues.lower,
                classLength: this.state.classLength
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addRow() {
            this.state.classesWithFrequency.push({
                lower: this.state.classesWithFrequency[this.state.classesWithFrequency.length - 1].upper + 1,
                upper: (this.state.classesWithFrequency[this.state.classesWithFrequency.length - 1].upper + 1) + parseFloat(this.state.classLength) - 1,
                frequency: 10
            })
            this.state.editModeclassesWithFrequency.push({
                frequency: true
            })
            this.setState({
                editModeButton: true
            })
        }

        removeRow(event) {
            this.state.classesWithFrequency.pop()
            this.state.editModeclassesWithFrequency.pop()

            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'frequency':
                        this.state.editModeclassesWithFrequency[customArray[1]].frequency = true
                        this.setState({
                            editModeButton: true,
                            submitChecker: false
                        })
                        break;
                    case 'lowerClass':
                        this.state.editModeclassesWithFrequency[customArray[1]].lower = true
                        this.setState({
                            editModeButton: true,
                            submitChecker: false
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'classLength':
                    this.setState({
                        editModeclassLength: true,
                        editModeButton: true,
                        submitChecker: false
                    })

            }
        }

        editModeOff() {

            for (let i in this.state.editModeclassesWithFrequency) {
                this.state.editModeclassesWithFrequency[i].lower = false
                this.state.editModeclassesWithFrequency[i].frequency = false
            }


            this.setState({


                submitChecker: true,

                editModeclassLength: false,
                editModenumberOfClass: false,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        The number of cheques deposited in a bank per day has the following frequency distribution :<br /><br />

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">No. of Cheques</StyledTableCell>
                                        <StyledTableCell align="center">No. of days</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.classesWithFrequency.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align="center">
                                                {parseInt(i) === 0 ? this.state.editModeclassesWithFrequency[i].lower ? <TextField style={{ "width": "5%", "padding": "-10px" }} name={["lowerClass", i]} id='outlined-base' error={x.lower === '' || isNaN(x.lower)}
                                                    value={x.lower} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["lowerClass", i]} onClick={this.buttonMaker.bind(this)}> {x.lower}</a> :
                                                    x.lower}
                                                &nbsp;-&nbsp;
                                                {x.upper}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {this.state.editModeclassesWithFrequency[i].frequency ? <TextField style={{ "width": "5%", "padding": "-10px" }} name={["frequency", i]} id='outlined-base' error={x.frequency === '' || isNaN(x.frequency)}
                                                    value={x.frequency} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["frequency", i]} onClick={this.buttonMaker.bind(this)}> {x.frequency}</a>} &nbsp;
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />
                        ADD/REMOVE ROW
                        <IconButton style={{ width: "3%" }} onClick={this.addRow.bind(this)} name="+">+</IconButton>
                        <IconButton disabled={this.state.classesWithFrequency.length < 3} style={{ width: "3%" }} onClick={this.removeRow.bind(this)} name="-">-</IconButton><br /><br />

                        Class Length =&nbsp;
                        {this.state.editModeclassLength ? <TextField style={{ "width": "5%", "padding": "-10px" }} name="classLength" id='outlined-base' error={this.state.classLength === '' || isNaN(this.state.classLength)}
                            value={this.state.classLength} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="classLength" onClick={this.buttonMaker.bind(this)}> {this.state.classLength}</a>} &nbsp;<br /><br />
                        <b>Find the median number of cheques deposited.</b>
                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">

                            We will find the cumulative frequency distribution as shown below
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">No. of Cheques</StyledTableCell>
                                            <StyledTableCell align="center">No. of days</StyledTableCell>
                                            <StyledTableCell align="center">Cumulative frequency</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].classesWithFrequency.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center">
                                                    {x.lower}
                                                    &nbsp;-&nbsp;
                                                    {x.upper}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {x.frequency}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {x.cumulativeFrequency}
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br /><br />
                            Here <i>n</i> = Σ<i>f</i> = {data[chartState.selected].totalFrequency}<br/>

                            Median Class = Class of the (<sup><i>n</i></sup>/<sub>2</sub>) th observation<br />
                            &emsp;&emsp;&emsp;&nbsp;= Class of the (<sup><i>{data[chartState.selected].totalFrequency}</i></sup>/<sub>2</sub>) th observation<br />
                            &emsp;&emsp;&emsp;&nbsp;= Class of the {data[chartState.selected].medianClass}th observation  (If the value is in decimal we will take the integer value above it)<br /><br />
                            It can be shown from the cumulative frequencies that all observations from the {data[chartState.selected].medianClassValues.cf + 1}th observation to the {data[chartState.selected].medianClassValues.cfhigher}th observation lie in {data[chartState.selected].medianClassValues.lower + 0.5} - {data[chartState.selected].medianClassValues.upper - 0.5}. Hence it will be the median class.<br />
                            Since this is exclusive type of classification, we will obtain the class boundary points from the class limits. Hence the median class will be taken as {data[chartState.selected].medianClassValues.lower} - {data[chartState.selected].medianClassValues.upper}<br /><br />
                            Taking, <i>L</i> = {data[chartState.selected].medianClassValues.lower}, <i>cf</i> = {data[chartState.selected].medianClassValues.cf}, <i>f</i> = {data[chartState.selected].medianClassValues.f}, <i>c</i> = {data[chartState.selected].classLength}<br />
                            Median <i>M</i> = <i>L</i> + <sup>(<sup><i>n</i></sup>/<sub>2</sub> - <i>cf</i>)</sup>/<i>f</i>  x <i>c</i><br />
                            &emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;= {data[chartState.selected].medianClassValues.lower} + <sup>({data[chartState.selected].medianClass} - {data[chartState.selected].medianClassValues.cf})</sup>/<sub>{data[chartState.selected].medianClassValues.f}</sub> x {data[chartState.selected].classLength}<br />
                            &emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;= {data[chartState.selected].medianClassValues.lower} + <sup>{data[chartState.selected].medianClass - data[chartState.selected].medianClassValues.cf}</sup>/<sub>{data[chartState.selected].medianClassValues.f}</sub> x {data[chartState.selected].classLength}<br />
                            &emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;= {data[chartState.selected].medianClassValues.lower} + <sup>{(data[chartState.selected].medianClass - data[chartState.selected].medianClassValues.cf) * (data[chartState.selected].classLength)}</sup>/<sub>{data[chartState.selected].medianClassValues.f}</sub><br />
                            &emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;= {data[chartState.selected].medianClassValues.lower} + {data[chartState.selected].medianA}<br />
                            &emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;= {data[chartState.selected].medianB}<br />
                            &emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;= {Math.round(data[chartState.selected].medianB * 100) / 100}<br /><br />

                            <b>Thus, the number of cheques deposited in the bank per day is {Math.round(data[chartState.selected].medianB * 100) / 100} cheques</b>
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {

    const data = [{
        "RMax": 5,
        "RMin": 0,
        "UniqueArray": [
            {
                "number": 0,
                "total": 18,
                "tally": "||||| ||||| ||||| |||"
            },
            {
                "number": 1,
                "total": 12,
                "tally": "||||| ||||| ||"
            },
            {
                "number": 2,
                "total": 9,
                "tally": "||||| ||||"
            },
            {
                "number": 3,
                "total": 6,
                "tally": "||||| |"
            },
            {
                "number": 4,
                "total": 3,
                "tally": "|||"
            },
            {
                "number": 5,
                "total": 2,
                "tally": "||"
            }
        ],
        "sampleStringLength": 50
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                totalSets: 500,
                sampleString: "32 28 47 63 71 9 60 10 96 14 31 148 53 67 29 10 62 40 80 54",
                sampleStringArray: [32, 28, 47, 63, 71, 9, 60, 10, 96, 14, 31, 148, 53, 67, 29, 10, 62, 40, 80, 54],
                sampleStringLength: 20,

                quartile: 1,
                decile: 7,
                percentile: 40,

                editModetotalSets: false,
                editModesampleString: false,
                editModequartile: false,
                editModedecile: false,
                editModepercentile: false,


                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.stringReducer = this.stringReducer.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            switch (event.target.name) {
                case 'totalSets':
                    this.setState({
                        totalSets: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'sampleString':
                    this.setState({
                        sampleString: this.stringReducer(event.target.value)
                    }, () => { this.fillDetails() })
                    break;
                case 'quartile':
                    this.setState({
                        quartile: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'decile':
                    this.setState({
                        decile: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'percentile':
                    this.setState({
                        percentile: event.target.value
                    }, () => { this.fillDetails() })
                    break;
            }
        }

        stringReducer(string) {
            let stringArray = string.split(" ")

            for (let i in stringArray) {
                if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === '') {
                    // console.log(i)
                    stringArray.splice(i, 1)
                }
            }

            if (stringArray[0] === null) {
                stringArray[0] = 0
            }

            this.setState({
                sampleStringLength: stringArray.length,
                sampleStringArray: stringArray
            })

            return string
        }

        fillDetails() {
            let flag = true

            if (this.state.sampleString === '') {
                flag = false
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            

            let dataObject = {
                sampleString: this.state.sampleString
            }

            // data.push(JSON.parse(JSON.stringify(dataObject)))

            // chartState.selected += 1

            this.setState({})

            console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            switch (event.target.name) {
                case 'totalSets':
                    this.setState({
                        editModetotalSets: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'sampleString':
                    this.setState({
                        editModesampleString: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'quartile':
                    this.setState({
                        editModequartile: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'decile':
                    this.setState({
                        editModedecile: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'percentile':
                    this.setState({
                        editModepercentile: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {
            this.setState({
                sampleString: this.state.sampleStringArray.reduce((a, b) => { return a + " " + b }),

                submitChecker: true,
                editModetotalSets: false,
                editModesampleString: false,
                editModequartile: false,
                editModedecile: false,
                editModepercentile: false,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        <b>
                            Find <i>Q</i>
                            {this.state.editModequartile ? <TextField style={{ "width": "4%", "padding": "-10px" }} name='quartile' id='outlined-base' error={this.state.quartile === '' || isNaN(this.state.quartile)}
                                value={this.state.quartile} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="quartile" onClick={this.buttonMaker.bind(this)}> {this.state.quartile}</a>}, &nbsp;

                            D
                            {this.state.editModedecile ? <TextField style={{ "width": "4%", "padding": "-10px" }} name='decile' id='outlined-base' error={this.state.decile === '' || isNaN(this.state.decile)}
                                value={this.state.decile} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="decile" onClick={this.buttonMaker.bind(this)}> {this.state.decile}</a>}, &nbsp;

                            P
                            {this.state.editModepercentile ? <TextField style={{ "width": "4%", "padding": "-10px" }} name='percentile' id='outlined-base' error={this.state.percentile === '' || isNaN(this.state.percentile)}
                                value={this.state.percentile} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="percentile" onClick={this.buttonMaker.bind(this)}> {this.state.percentile}</a>}&nbsp;
                            for the following data showing runs scored by a batsman in his 20 innings.

                        </b>
                        <br /><br />


                        {this.state.editModesampleString ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='sampleString' id='outlined-base' error={this.state.sampleString === ''}
                            value={this.state.sampleString} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="sampleString" onClick={this.buttonMaker.bind(this)}> {this.state.sampleString}</a>} &nbsp;

                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">

                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

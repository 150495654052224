import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button, Switch, touchRippleClasses } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
//ILLUSTRATION 4

export default function ComparativeProfitLossStatement() {

    const data = [{
        "bookOwnerName": "Dhyani Ceramic Limited",
        "questionPallete": [
            {
                "type": "application",
                "amount": 74,
                "premium": 70,
                "difference": 4
            },
            {
                "type": "allotment",
                "amount": 44,
                "premium": 40,
                "difference": 4
            },
            {
                "type": "finalCall",
                "amount": 42,
                "premium": 40,
                "difference": 2
            }
        ],
        "equityShares": 300000,
        "equitysharesEach": 10,
        "equitySharesPremium": 150,
        "helderName": "Vishal",
        "heldShares": 2000,
        "reissueName": "Avani",
        "reissueAmount": 80,
        "answerPallete": [
            {
                "equityShare": 21000000,
                "securitiesPremium": 1200000,
                "total": 22200000
            },
            {
                "equityShare": 12000000,
                "securitiesPremium": 1200000,
                "total": 13200000
            },
            {
                "equityShare": 12000000,
                "securitiesPremium": 600000,
                "total": 12600000
            }
        ],
        "pointSix": 12516000,
        "pointSeven": {
            "one": 20000,
            "two": 80000,
            "three": 16000,
            "four": 84000
        },
        "pointEight": {
            "one": 20000,
            "two": 140000
        },
        "pointNine": {
            "one": 16000
        },
        "totalTableOne": 96192000,
        "rightSidearray2": [
            21000000,
            12000000,
            12000000,
            140000
        ],
        "leftSideArray2": [
            80000,
            45060000
        ],
        "rightSidearray2Total": 45140000
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const AlphabetRegex = new RegExp(/^[a-zA-Z]+$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                bookOwnerName: "Dhyani Ceramic Limited",
                equityShares: 300000,
                equitysharesEach: 10,
                equitySharesPremium: 150,
                questionPallete: [
                    {//1
                        type: "application",
                        amount: 74,
                        premium: 70
                    },
                    {//2
                        type: "allotment",
                        amount: 44,
                        premium: 40
                    },
                    {//3
                        type: "finalCall",
                        amount: 42,
                        premium: 40
                    },
                ],
                helderName: "Vishal",
                heldShares: 2000,
                reissueName: "Avani",
                reissueAmount: 80,

                editModebookOwnerName: false,
                editModeequityShares: false,
                editModeequitysharesEach: false,
                editModeequitySharesPremium: false,
                editModequestionPalette: [
                    {//1
                        amount: false,
                        premium: false
                    },
                    {//2
                        amount: false,
                        premium: false
                    },
                    {//3
                        amount: false,
                        premium: false
                    }

                ],
                editModehelderName: false,
                editModeheldShares: false,
                editModereissueName: false,
                editModereissueAmount: false,




                addQuestionChecker: false,


                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
            this.nameSetter = this.nameSetter.bind(this)
            this.removeQuestion = this.removeQuestion.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (event.target.value !== '-') {
                if (customArray.length === 3) {
                    switch (customArray[0]) {
                        case 'question':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'premium':
                                    this.state.questionPallete[customArray[2]].premium = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                    }
                }

                switch (event.target.name) {
                    case 'bookOwnerName':
                        this.setState({
                            bookOwnerName: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'equityShares':
                        this.setState({
                            equityShares: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'equitysharesEach':
                        this.setState({
                            equitysharesEach: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'equitySharesPremium':
                        this.setState({
                            equitySharesPremium: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'helderName':
                        this.setState({
                            helderName: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'heldShares':
                        this.setState({
                            heldShares: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'reissueName':
                        this.setState({
                            reissueName: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'reissueAmount':
                        this.setState({
                            reissueAmount: event.target.value
                        }, () => { this.fillDetails() })
                        break;

                }
            }
        }

        nameSetter(name, newName, type) {
            if (type === 'purchase') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "purchaseReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            if (type === 'sales') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "order" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }

            if (type === 'preOrder') {
                for (let i of this.state.questionPallete) {
                    if (i.questionType === "sales" && i.context.name === name) {
                        i.context.name = newName
                    }
                    if (i.questionType === "salesReturns" && i.context.name === name) {
                        i.context.name = newName
                    }
                }
            }
            this.setState({})
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'date':
                    this.setState({
                        tempDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'name':
                    this.setState({
                        tempName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amount':
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'reason':
                    this.setState({
                        tempReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'shares':
                    this.setState({
                        tempShares: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'brokerage':
                    this.setState({
                        tempBrokerage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'discount':
                    this.setState({
                        tempDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Tdiscount':
                    this.setState({
                        tempTDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Cdiscount':
                    this.setState({
                        tempCDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'purchasedFrom':
                    this.setState({
                        tempPurchasedFrom: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'billno':
                    this.setState({
                        tempBillno: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'credit':
                    this.setState({
                        tempCredit: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPercentage':
                    this.setState({
                        tempAmountPercentage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'invoiceNo':
                    this.setState({
                        tempInvoiceNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'debitNoteNo':
                    this.setState({
                        tempDebitNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'creditNoteNo':
                    this.setState({
                        tempCreditNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPaidImmediately':
                    this.setState({
                        tempAmountPaidImmediately: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'memo':
                    this.setState({
                        tempMemo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'assetName':
                    this.setState({
                        tempAssetName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmount':
                    this.setState({
                        tempExtraSum: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmountReason':
                    this.setState({
                        tempExtraSumReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashPurchase':
                    this.setState({
                        tempCashPurchase: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashSales':
                    this.setState({
                        tempCashSales: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            switch (this.state.addQuestionType) {
                case 'debenturesPar':
                    if (this.state.tempShares !== '' && NumberRegex.test(this.state.tempShares) &&
                        this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100 &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'debenturesDiscount':
                    if (this.state.tempShares !== '' && NumberRegex.test(this.state.tempShares) &&
                        this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100 &&
                        this.state.tempTDiscount !== '' && NumberRegex.test(this.state.tempTDiscount) && parseFloat(this.state.tempTDiscount) > 0 && parseFloat(this.state.tempTDiscount) < 100 &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'debenturesPremium':
                    if (this.state.tempShares !== '' && NumberRegex.test(this.state.tempShares) &&
                        this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100 &&
                        this.state.tempTDiscount !== '' && NumberRegex.test(this.state.tempTDiscount) && parseFloat(this.state.tempTDiscount) > 0 && parseFloat(this.state.tempTDiscount) < 100 &&
                        this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
            }
        }

        genderChange(event) {
            this.setState({
                bookOwnerGender: event.target.value
            })
        }

        monthChange(event) {
            this.setState({
                month: event.target.value
            })
        }

        questionTypeChange(event) {
            this.setState({
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempReturneeName: '',
                tempAmount: '',
                tempReason: '',
                tempShares: '',
                tempBrokerage: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                addQuestionChecker: false,
                addQuestionType: event.target.value
            })
        }

        addNameChange(event) {
            this.setState({
                tempName: event.target.value
            })
        }

        addReturneeNameChange(event) {
            this.setState({
                tempReturneeName: event.target.value
            })
        }

        purchaseFromNameChange(event) {
            this.setState({
                tempPurchasedFrom: event.target.value
            })
        }

        rebateChange(event) {
            this.setState({
                tempRebate: event.target.value
            })
        }

        fillDetails() {
            let flag = true

            if (this.state.bookOwnerName === '') {
                flag = false
            }
            if (this.state.equityShares === '' || !NumberRegex.test(this.state.equityShares)) {
                flag = false
            }
            if (this.state.equitysharesEach === '' || !NumberRegex.test(this.state.equitysharesEach)) {
                flag = false
            }
            if (this.state.equitySharesPremium === '' || !NumberRegex.test(this.state.equitySharesPremium)) {
                flag = false
            }
            if (this.state.helderName === '') {
                flag = false
            }
            if (this.state.heldShares === '' || !NumberRegex.test(this.state.heldShares)) {
                flag = false
            }
            if (this.state.reissueName === '') {
                flag = false
            }
            if (this.state.reissueAmount === '' || !NumberRegex.test(this.state.reissueAmount)) {
                flag = false
            }

            for (let i of this.state.questionPallete) {
                if (i.amount === '' || !NumberRegex.test(i.amount) ||
                    i.premium === '' || !NumberRegex.test(i.premium) ||
                    parseFloat(i.premium) >= parseFloat(i.amount)) {
                    flag = false
                }
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: false,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: true,
                    submitChecker: false
                })
            }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            //#region TABLE ONE

            let leftSideArray2 = []
            let rightSidearray2 = []

            let totalTableOne = 0

            for (let i of this.state.questionPallete) {
                i.difference = parseFloat(i.amount) - parseFloat(i.premium)
            }

            let answerPallete = []

            for (let i of this.state.questionPallete) {
                answerPallete.push({
                    equityShare: parseFloat(i.premium) * parseFloat(this.state.equityShares),
                    securitiesPremium: parseFloat(i.difference) * parseFloat(this.state.equityShares),
                    total: parseFloat(i.premium) * parseFloat(this.state.equityShares) + parseFloat(i.difference) * parseFloat(this.state.equityShares)
                })

                rightSidearray2.push(parseFloat(i.premium) * parseFloat(this.state.equityShares))

                totalTableOne += parseFloat(i.premium) * parseFloat(this.state.equityShares) + parseFloat(i.difference) * parseFloat(this.state.equityShares)
            }

            totalTableOne += parseFloat(this.state.questionPallete[0].premium) * parseFloat(this.state.equityShares) + parseFloat(this.state.questionPallete[0].difference) * parseFloat(this.state.equityShares)
            totalTableOne += parseFloat(this.state.questionPallete[1].premium) * parseFloat(this.state.equityShares) + parseFloat(this.state.questionPallete[1].difference) * parseFloat(this.state.equityShares)



            let pointSix = (parseFloat(this.state.equityShares) - parseFloat(this.state.heldShares)) * parseFloat(this.state.questionPallete[2].amount)

            totalTableOne += pointSix

            let pointSeven = {
                one: parseFloat(this.state.heldShares) * parseFloat(this.state.equitysharesEach),
                two: parseFloat(this.state.heldShares) * parseFloat(this.state.questionPallete[2].premium),
                three: parseFloat(this.state.heldShares) * (parseFloat(this.state.equitysharesEach) + parseFloat(this.state.questionPallete[2].premium) - parseFloat(this.state.questionPallete[2].amount)),
                four: parseFloat(this.state.heldShares) * parseFloat(this.state.questionPallete[2].amount)
            }

            leftSideArray2.push(pointSeven.two)

            totalTableOne += pointSeven.one + pointSeven.two

            let pointEight = {
                one: parseFloat(this.state.heldShares) * parseFloat(this.state.equitysharesEach),
                two: parseFloat(this.state.heldShares) * (parseFloat(this.state.reissueAmount) - parseFloat(this.state.equitysharesEach))
            }

            rightSidearray2.push(pointEight.two)

            totalTableOne += pointEight.one + pointEight.two

            let pointNine = {
                one: pointSeven.three
            }

            totalTableOne += pointNine.one

            //#endregion TABLE ONE

            //#region TABLE TWO

            let rightSidearray2Total = rightSidearray2.reduce((a, b) => (a + b))
            let leftSideArray2Total = leftSideArray2.reduce((a, b) => (a + b))

            leftSideArray2.push(rightSidearray2Total - leftSideArray2Total)

            //#endregion TABLE TWO

            // console.log(JSON.stringify(this.state.questionPallete,null,4))


            // console.log(JSON.stringify(this.state.questionPallete, null, 4))

            // let dataObject = JSON.parse(JSON.stringify(this.state.questionPallete))

            let dataObject = {
                bookOwnerName: this.state.bookOwnerName,
                questionPallete: this.state.questionPallete,
                equityShares: this.state.equityShares,
                equitysharesEach: this.state.equitysharesEach,
                equitySharesPremium: this.state.equitySharesPremium,
                helderName: this.state.helderName,
                heldShares: this.state.heldShares,
                reissueName: this.state.reissueName,
                reissueAmount: this.state.reissueAmount,
                answerPallete,
                pointSix,
                pointSeven,
                pointEight,
                pointNine,
                totalTableOne,
                rightSidearray2,
                leftSideArray2,
                rightSidearray2Total
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.clear()
            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addQuestion() {
            let adderFlag = true

            let temporaryTemplate = {
                questionType: this.state.addQuestionType,
                date: parseFloat(this.state.tempDate),
                assetName: this.state.tempAssetName,
                name: this.state.tempName,
                amount: this.state.tempAmount,
                reason: this.state.tempReason,
                shares: this.state.tempShares,
                brokerage: this.state.tempBrokerage,
                amountPercentage: this.state.tempAmountPercentage,
                discount: this.state.tempDiscount,
                tradeDiscount: this.state.tempTDiscount,
                cashDiscount: this.state.tempCDiscount,
                purchasedFrom: this.state.tempPurchasedFrom,
                billno: this.state.tempBillno,
                invoiceNo: this.state.tempInvoiceNo,
                debitNoteNo: this.state.tempDebitNoteNo,
                creditNoteNo: this.state.tempCreditNoteNo,
                credit: this.state.tempCredit,
                memo: this.state.tempMemo,
                extraSum: this.state.tempExtraSum,
                extraSumReason: this.state.tempExtraSumReason,
                cashPurchase: this.state.tempCashPurchase,
                cashSales: this.state.tempCashSales,
                rebate: this.state.tempRebate,
                returneeName: this.state.tempReturneeName,

                amountPaidImmediately: this.state.tempAmountPaidImmediately
            }
            let questionObject
            let editModequestionObject

            switch (temporaryTemplate.questionType) {
                case 'debenturesPar':
                    questionObject = {
                        questionType: "debenture",
                        context: {
                            type: temporaryTemplate.questionType,
                            issuedAmount: temporaryTemplate.shares,
                            debenturePercent: temporaryTemplate.discount,
                            debentureAmount: temporaryTemplate.amount,
                        }
                    }
                    editModequestionObject = {
                        issuedAmount: false,
                        debenturePercent: false,
                        debentureAmount: false
                    }
                    break;
                case 'debenturesDiscount':
                    questionObject = {
                        questionType: "debenture",
                        context: {
                            type: temporaryTemplate.questionType,
                            issuedAmount: temporaryTemplate.shares,
                            debenturePercent: temporaryTemplate.discount,
                            debentureAmount: temporaryTemplate.amount,
                            percent: temporaryTemplate.tradeDiscount
                        }
                    }
                    editModequestionObject = {
                        issuedAmount: false,
                        debenturePercent: false,
                        debentureAmount: false,
                        percent: false
                    }
                    break;
                case 'debenturesPremium':
                    questionObject = {
                        questionType: "debenture",
                        context: {
                            type: temporaryTemplate.questionType,
                            issuedAmount: temporaryTemplate.shares,
                            debenturePercent: temporaryTemplate.discount,
                            debentureAmount: temporaryTemplate.amount,
                            percent: temporaryTemplate.tradeDiscount
                        }
                    }
                    editModequestionObject = {
                        issuedAmount: false,
                        debenturePercent: false,
                        debentureAmount: false,
                        percent: false
                    }
                    break;

            }

            this.state.questionPallete.push(questionObject)
            this.state.editModequestionPalette.push(editModequestionObject)

            this.setState({})

            // console.log(JSON.stringify(questionObject,null,4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            let questionType = this.state.questionPallete[event].context.type
            let questionName = this.state.questionPallete[event].context.name

            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPalette.splice(event, 1)

            if (questionType === 'sales') {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].context.type === 'salesReturns') {
                        this.state.questionPallete.splice(i, 1)
                        this.state.editModequestionPalette.splice(i, 1)
                    }
                }
            }
            if (questionType === 'nodiscount') {
                for (let i in this.state.questionPallete) {
                    if (this.state.questionPallete[i].context.type === 'purchaseReturns') {
                        this.state.questionPallete.splice(i, 1)
                        this.state.editModequestionPalette.splice(i, 1)
                    }
                }
            }

            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 3) {
                switch (customArray[0]) {
                    case 'question':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'premium':
                                this.state.editModequestionPalette[customArray[2]].premium = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;

                }
            }

            switch (event.target.name) {
                case 'bookOwnerName':
                    this.setState({
                        editModebookOwnerName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'equityShares':
                    this.setState({
                        editModeequityShares: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'equitysharesEach':
                    this.setState({
                        editModeequitysharesEach: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'equitySharesPremium':
                    this.setState({
                        editModeequitySharesPremium: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'helderName':
                    this.setState({
                        editModehelderName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'heldShares':
                    this.setState({
                        editModeheldShares: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'reissueName':
                    this.setState({
                        editModereissueName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'reissueAmount':
                    this.setState({
                        editModereissueAmount: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {

            for (let i of this.state.editModequestionPalette) {
                i.amount = false
                i.premium = false
            }


            this.setState({
                editModebookOwnerName: false,
                editModeequityShares: false,
                editModeequitysharesEach: false,
                editModeequitySharesPremium: false,
                editModehelderName: false,
                editModeheldShares: false,
                editModereissueName: false,
                editModereissueAmount: false,

                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >

                        {this.state.editModebookOwnerName ? <TextField style={{ marginTop: "-15px", width: "30%" }} name="bookOwnerName" id='outlined-base' error={this.state.bookOwnerName === ''}
                            value={this.state.bookOwnerName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="bookOwnerName" onClick={this.buttonMaker.bind(this)}> {this.state.bookOwnerName}</a>}&nbsp;

                        issued&nbsp;
                        {this.state.editModeequityShares ? <TextField style={{ marginTop: "-15px", width: "10%" }} name="equityShares" id='outlined-base' error={this.state.equityShares === '' || isNaN(this.state.equityShares)}
                            value={this.state.equityShares} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="equityShares" onClick={this.buttonMaker.bind(this)}> {this.state.equityShares}</a>}&nbsp;

                        equity shares of ₹&nbsp;
                        {this.state.editModeequitysharesEach ? <TextField style={{ marginTop: "-15px", width: "10%" }} name="equitysharesEach" id='outlined-base' error={this.state.equitysharesEach === '' || isNaN(this.state.equitysharesEach)}
                            value={this.state.equitysharesEach} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="equitysharesEach" onClick={this.buttonMaker.bind(this)}> {this.state.equitysharesEach}</a>}&nbsp;

                        each at a premium of ₹&nbsp;
                        {this.state.editModeequitySharesPremium ? <TextField style={{ marginTop: "-15px", width: "10%" }} name="equitySharesPremium" id='outlined-base' error={this.state.equitySharesPremium === '' || isNaN(this.state.equitySharesPremium)}
                            value={this.state.equitySharesPremium} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="equitySharesPremium" onClick={this.buttonMaker.bind(this)}> {this.state.equitySharesPremium}</a>}&nbsp;

                        per share.<br /><br />

                        Amount called up per share was as under :<br /><br />

                        {this.state.questionPallete.map((x, i) => (
                            <span>({i + 1})&emsp;
                                {x.type === 'application' &&
                                    <span>
                                        On application ₹&nbsp;
                                        {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "5%" }} name={["question", "amount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                            value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                            <a className='txt' name={["question", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>}&nbsp;

                                        (Including ₹&nbsp;
                                        {this.state.editModequestionPalette[i].premium ? <TextField style={{ marginTop: "-15px", width: "5%" }} name={["question", "premium", i]} id='outlined-base' error={x.premium === '' || isNaN(x.premium)}
                                            value={x.premium} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                            <a className='txt' name={["question", "premium", i]} onClick={this.buttonMaker.bind(this)}> {x.premium}</a>}&nbsp;

                                        for premium)
                                    </span>
                                }
                                {x.type === 'allotment' &&
                                    <span>
                                        On allotment ₹&nbsp;
                                        {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "5%" }} name={["question", "amount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                            value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                            <a className='txt' name={["question", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>}&nbsp;

                                        (Including ₹&nbsp;
                                        {this.state.editModequestionPalette[i].premium ? <TextField style={{ marginTop: "-15px", width: "5%" }} name={["question", "premium", i]} id='outlined-base' error={x.premium === '' || isNaN(x.premium)}
                                            value={x.premium} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                            <a className='txt' name={["question", "premium", i]} onClick={this.buttonMaker.bind(this)}> {x.premium}</a>}&nbsp;

                                        for premium)
                                    </span>
                                }
                                {x.type === 'finalCall' &&
                                    <span>
                                        On final call ₹&nbsp;
                                        {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "5%" }} name={["question", "amount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                            value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                            <a className='txt' name={["question", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>}&nbsp;

                                        (Including ₹&nbsp;
                                        {this.state.editModequestionPalette[i].premium ? <TextField style={{ marginTop: "-15px", width: "5%" }} name={["question", "premium", i]} id='outlined-base' error={x.premium === '' || isNaN(x.premium)}
                                            value={x.premium} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                            <a className='txt' name={["question", "premium", i]} onClick={this.buttonMaker.bind(this)}> {x.premium}</a>}&nbsp;

                                        for premium)
                                    </span>
                                }
                                <br /><br />
                            </span>
                        ))}

                        Company received application of {this.state.equityShares} shares.<br /><br />

                        All amounts due were received except final call on&nbsp;
                        {this.state.editModeheldShares ? <TextField style={{ marginTop: "-15px", width: "10%" }} name="heldShares" id='outlined-base' error={this.state.heldShares === '' || isNaN(this.state.heldShares)}
                            value={this.state.heldShares} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="heldShares" onClick={this.buttonMaker.bind(this)}> {this.state.heldShares}</a>}&nbsp;

                        shares held by&nbsp;
                        {this.state.editModehelderName ? <TextField style={{ marginTop: "-15px", width: "30%" }} name="helderName" id='outlined-base' error={this.state.helderName === ''}
                            value={this.state.helderName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="helderName" onClick={this.buttonMaker.bind(this)}> {this.state.helderName}</a>}.&nbsp;

                        Company forfeited {this.state.helderName}'s shares after carrying out necessary formalities.<br /><br />

                        Company then re-issued those shares to&nbsp;
                        {this.state.editModereissueName ? <TextField style={{ marginTop: "-15px", width: "30%" }} name="reissueName" id='outlined-base' error={this.state.reissueName === ''}
                            value={this.state.reissueName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="reissueName" onClick={this.buttonMaker.bind(this)}> {this.state.reissueName}</a>}'s&nbsp;

                        as full paid up ₹&nbsp;
                        {this.state.editModereissueAmount ? <TextField style={{ marginTop: "-15px", width: "30%" }} name="reissueAmount" id='outlined-base' error={this.state.reissueAmount === '' || isNaN(this.state.reissueAmount)}
                            value={this.state.reissueAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="reissueAmount" onClick={this.buttonMaker.bind(this)}> {this.state.reissueAmount}</a>}&nbsp;

                        per share.

                        <br /><br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)} >Edit Mode Off</ColorButton> : <span></span>}


                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right'>
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                        <br />
                        <br />

                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>Ans. :</b><br /><br />
                            <div align="center"><b>Journal of Entries in the Books of {data[chartState.selected].bookOwnerName}</b></div><br /><br />

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Sr No.</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Debit (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Credit (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>{/* 1 */}
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>1</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                Bank A/c<span style={{ float: "right" }}>Dr</span><br />
                                                &emsp;&emsp;To Equity share application A/c<br />
                                                [Being receipt of share application money on {data[chartState.selected].equityShares} equity shares at ₹ {data[chartState.selected].questionPallete[0].amount} per share.]
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].answerPallete[0].total}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].answerPallete[0].total}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>{/* 2 */}
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>2</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                Equity share application A/c<span style={{ float: "right" }}>Dr</span><br />
                                                &emsp;&emsp;To Equity share application A/c<br />
                                                &emsp;&emsp;To Securities premium A/c<br />
                                                [Being transfer of ₹ {data[chartState.selected].questionPallete[0].difference} per share to share capital account and&nbsp;
                                                ₹ {data[chartState.selected].questionPallete[0].premium} per share to securities premium account out of total share application money&nbsp;
                                                on {data[chartState.selected].equityShares} alloted shares]
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].answerPallete[0].total}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].answerPallete[0].securitiesPremium}<br />
                                                {data[chartState.selected].answerPallete[0].equityShare}<br />
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>{/* 3 */}
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>3</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                Equity share allotment A/c<span style={{ float: "right" }}>Dr</span><br />
                                                &emsp;&emsp;To Equity share application A/c<br />
                                                &emsp;&emsp;To Securities premium A/c<br />
                                                [Being allotment money of ₹ {data[chartState.selected].questionPallete[1].amount} per share (including premium of ₹ {data[chartState.selected].questionPallete[1].premium})&nbsp;
                                                due pm {data[chartState.selected].equityShares} shares.]
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].answerPallete[1].total}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].answerPallete[1].securitiesPremium}<br />
                                                {data[chartState.selected].answerPallete[1].equityShare}<br />
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>{/* 4 */}
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>4</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                Bank A/c<span style={{ float: "right" }}>Dr</span><br />
                                                &emsp;&emsp;To Equity share allotment A/c<br />
                                                [Being receipt of full amount of share allotment.]
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].answerPallete[1].total}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].answerPallete[1].total}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>{/* 5 */}
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>5</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                Equity shares final call A/c<span style={{ float: "right" }}>Dr</span><br />
                                                &emsp;&emsp;To Equity share capital A/c<br />
                                                &emsp;&emsp;To Securities premium A/c<br />
                                                [Being final call of ₹ {data[chartState.selected].questionPallete[2].amount} (including premium of ₹ {data[chartState.selected].questionPallete[2].premium})&nbsp;
                                                per share due on {data[chartState.selected].equityShares} shares.]
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].answerPallete[2].total}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].answerPallete[2].securitiesPremium}<br />
                                                {data[chartState.selected].answerPallete[2].equityShare}<br />
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>{/* 6 */}
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>6</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                Bank A/c<span style={{ float: "right" }}>Dr</span><br />
                                                &emsp;&emsp;To Equity shares' final call A/c<br />
                                                [Being receipt of share final call amount on {parseFloat(data[chartState.selected].equityShares) - parseFloat(data[chartState.selected].heldShares)} shares except&nbsp;
                                                {data[chartState.selected].heldShares} shares of {data[chartState.selected].helderName}.]
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].pointSix}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].pointSix}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>{/* 7 */}
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>7</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                Equity share capital <span style={{ float: "right" }}>Dr</span> A/c<br />
                                                ({data[chartState.selected].heldShares} shares X ₹ {data[chartState.selected].equitysharesEach})<br />
                                                Securities premium <span style={{ float: "right" }}>Dr</span>A/c<br />
                                                ({data[chartState.selected].heldShares} shares X ₹ {data[chartState.selected].questionPallete[2].premium})<br />
                                                &emsp;&emsp;To Share forfeiture A/c<br />
                                                &emsp;&emsp;({data[chartState.selected].heldShares} shares X ₹ {parseFloat(data[chartState.selected].equitysharesEach) + parseFloat(data[chartState.selected].questionPallete[2].premium) - parseFloat(data[chartState.selected].questionPallete[2].amount)})<br />
                                                &emsp;&emsp;To Equity shares final call A/c<br />
                                                &emsp;&emsp;({data[chartState.selected].heldShares} shares X ₹ {data[chartState.selected].questionPallete[2].amount})<br />
                                                [Being forfeiture of {data[chartState.selected].heldShares} shares of {data[chartState.selected].helderName} for non-receipt of final call.]
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].pointSeven.one}<br />
                                                <br />
                                                {data[chartState.selected].pointSeven.two}
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                {data[chartState.selected].pointSeven.three}<br />
                                                <br />
                                                {data[chartState.selected].pointSeven.four}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>{/* 8 */}
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>8</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                Bank A/c<span style={{ float: "right" }}>Dr</span><br />
                                                &emsp;&emsp;To Equity share capital A/c<br />
                                                &emsp;&emsp;({data[chartState.selected].heldShares} shares X ₹ {data[chartState.selected].equitysharesEach})<br />
                                                &emsp;&emsp;To Securities premium A/c <br />
                                                &emsp;&emsp;({data[chartState.selected].heldShares} shares X ₹ {parseFloat(data[chartState.selected].reissueAmount) - parseFloat(data[chartState.selected].equitysharesEach)})<br />
                                                [Being reissue of {data[chartState.selected].heldShares} shares at ₹ {data[chartState.selected].reissueAmount} per share.]
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].pointEight.one + data[chartState.selected].pointEight.two}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].pointEight.one}
                                                <br />
                                                {data[chartState.selected].pointEight.two}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>{/* 9 */}
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>9</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                Share forfeiture A/c<span style={{ float: "right" }}>Dr</span><br />
                                                &emsp;&emsp;To capital reserve A/c<br />
                                                [Being balance of share forfeiture account transfer to capital reserve account.]
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].pointNine.one}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].pointNine.one}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>Total</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].totalTableOne}</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].totalTableOne}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align='center'><b>In the Ledger of {data[chartState.selected].bookOwnerName}</b></div>
                            <div align='center'><b>Securities Premium Account</b></div><br />
                            <span style={{ float: "left" }}>Debit</span><span style={{ float: "right" }}>Credit</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Sr no</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                            <StyledTableCell align="center">Sr no</StyledTableCell>
                                            <StyledTableCell align="center">Particulars</StyledTableCell>
                                            <StyledTableCell align="center">Amount (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].rightSidearray2.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                    {parseFloat(i) === 0 && "7"}
                                                </StyledTableCell>
                                                <StyledTableCell align='left' style={{ verticalAlign: "top" }}>
                                                    {parseFloat(i) === 0 && "To Equity shares final call A/c"}
                                                    {parseFloat(i) === 1 && "To Balance c/d"}
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {parseFloat(i) === 0 && data[chartState.selected].leftSideArray2[i]}
                                                    {parseFloat(i) === 1 && data[chartState.selected].leftSideArray2[i]}
                                                </StyledTableCell>
                                                <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                    {parseFloat(i) === 0 && "2"}
                                                    {parseFloat(i) === 1 && "3"}
                                                    {parseFloat(i) === 2 && "5"}
                                                    {parseFloat(i) === 3 && "8"}
                                                </StyledTableCell>
                                                <StyledTableCell align='left' style={{ verticalAlign: "top" }}>
                                                    {parseFloat(i) === 0 && "By Equity share application A/c"}
                                                    {parseFloat(i) === 1 && "By Equity shares allotment A/c"}
                                                    {parseFloat(i) === 2 && "By Equity shares' final call A/c"}
                                                    {parseFloat(i) === 3 && "By Bank A/c"}
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x}
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].rightSidearray2Total}</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].rightSidearray2Total}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                        </div>
                    }
                    <br />
                    <br />
                </div >
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

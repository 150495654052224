import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  AddButton,
  BootstrapTextField,
  ColorButton,
  CustomColorButton,
  DeleteButton,
  LinkButton,
  RedditTextField,
  ResponseButton,
  ReverseLinkButton,
  StyledGrid,
  StyledTableCell,
  StyledTableRow,
} from "../../MelzoLibrary/StyledComponents";
import { Container } from "@mui/system";

import Icon from "@mui/material/Icon";

import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";

import "react-datepicker/dist/react-datepicker.css";

export default function SkewnessAndItsCoefficient() {
  const data = [
    {
      answerPallete: [
        {
          time: 120,
          days: 2,
          f: 2,
          d: -40,
          fd: -80,
          fd2: 3200,
        },
        {
          time: 130,
          days: 3,
          f: -7,
          fd: -119,
          d: -30,
          fd2: 2700,
        },
        {
          time: 140,
          days: 4,
          d: -20,
          f: -7,
          fd: -80,
          fd2: 1600,
        },
        {
          time: 150,
          days: 5,
          d: -10,
          f: -7,
          fd: -50,
          fd2: 500,
        },
        {
          time: 160,
          days: 11,
          d: 0,
          f: -7,
          fd: 0,
          fd2: 0,
        },
        {
          time: 170,
          days: 9,
          d: 10,
          f: -7,
          fd: 90,
          fd2: 900,
        },
        {
          time: 180,
          days: 9,
          d: 20,
          f: -7,
          fd: 180,
          fd2: 3600,
        },
        {
          time: 190,
          days: 6,
          d: 30,
          f: -7,
          fd: 180,
          fd2: 5400,
        },
        {
          time: 200,
          days: 1,
          d: 40,
          //   f: -7,
          fd: 40,
          fd2: 1600,
        },
      ],
      assumedA: 120,
      totaldays: 50,
      totalfd: 190,
      totalfd2: 19500,
      gcdTime: 1,
      mean: 163.8,
      standardDeviation: 19.38,
      standardDeviationStepTwoOne: 390,
      standardDeviationStepTwoTwo: 14.44,
      standardDeviationStepThree: 375.56,
      skewness: 3.8,
      j: 0.2,
      type: "min",
    },
  ];

  const NormalNumberRegex = new RegExp(/^[0-9]+$/);

  const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);

  const chartState = {
    selected: 0,
  };

  // const [value, onChange] = useState(new Date());
  // const [value2, onChange2] = useState(new Date());

  //   ====================================================================================================================
  //                            I use time insted of No. of units tranported &
  //                                       days insted of  trucks
  //   ====================================================================================================================

  class OperatingRatio extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: "time",
        type: "min",
        travelTime: [
          {
            time: 120,
            days: 2,
          },
          {
            time: 130,
            days: 3,
          },
          {
            time: 140,
            days: 4,
          },
          {
            time: 150,
            days: 5,
          },
          {
            time: 160,
            days: 11,
          },
          {
            time: 170,
            days: 9,
          },
          {
            time: 180,
            days: 9,
          },
          {
            time: 190,
            days: 6,
          },
          {
            time: 200,
            days: 1,
          },
        ],

        editModetravelTime: [
          {
            time: false,
            days: false,
          },
          {
            time: false,
            days: false,
          },
          {
            time: false,
            days: false,
          },
          {
            time: false,
            days: false,
          },
          {
            time: false,
            days: false,
          },
          {
            time: false,
            days: false,
          },
          {
            time: false,
            days: false,
          },
          {
            time: false,
            days: false,
          },
          {
            time: false,
            days: false,
          },
        ],

        editModename: false,
        editModetype: false,
        solutionShow: false,
        // tableModeChartModeSwitch: true,
        editModeButtonChecker: false,
        editModeButton: false,
        submitChecker: true,
      };
      this.eventHandler = this.eventHandler.bind(this);
      this.editModeOff = this.editModeOff.bind(this);
      this.autoSave = this.autoSave.bind(this);
      this.moveLeftAndRight = this.moveLeftAndRight.bind(this);
    }

    //#region EVENT FUNCTIONALITIES START

    eventHandler(event) {
      let customArray = event.target.name.split(",");

      if (customArray.length === 2) {
        switch (customArray[0]) {
          case "time":
            this.state.travelTime[customArray[1]].time = event.target.value;
            this.setState({}, () => {
              this.fillDetails();
            });
            break;
          case "days":
            this.state.travelTime[customArray[1]].days = event.target.value;
            this.setState({}, () => {
              this.fillDetails();
            });
            break;
        }
      }

      switch (event.target.name) {
        case "name":
          this.setState(
            {
              name: event.target.value,
            },
            () => {
              this.fillDetails();
            }
          );
          break;
      }
    }

    handleChange(event) {
      this.setState({
        type: event.target.value,
      });
    }

    fillDetails() {
      let flag = true;

      for (let i of this.state.travelTime) {
        if (!NumberRegex.test(i.days) || i.days === "") {
          flag = false;
        }
        if (!NumberRegex.test(i.time) || i.time === "") {
          flag = false;
        }
      }

      this.setState({
        editModeButtonChecker: !flag,
      });
    }

    autoSave(event) {}

    //#endregion EVENT FUNCTIONALTIES END

    //#region GREATEST COMMON DIVISOR START

    gcd(a, b) {
      if (a == 0) return b;
      return this.gcd(b % a, a);
    }

    findGCD(arr, n) {
      let result = arr[0];
      for (let i = 1; i < n; i++) {
        result = this.gcd(arr[i], result);

        if (result == 1) {
          return 1;
        }
      }
      return result;
    }

    //#endregion GREATEST COMMON DIVISOR END

    //#region BUTTON PANEL START

    onSubmit() {
      let timeArray = [];

      const makeThreeDigitPression = (value) => {
        return Number.parseFloat(value).toFixed(2);
      };
      console.log("🚀 ~ =======>", this.state.travelTime);

      for (let i of this.state.travelTime) {
        timeArray.push(i.time);
      }

      let gcdTime = this.findGCD(timeArray, timeArray.length);

      timeArray = timeArray.sort((a, b) => {
        return a - b;
      });
      let assumedA;

      if (timeArray.length % 2 > 0) {
        assumedA = Number(timeArray[(timeArray.length + 1) / 2]);
      } else {
        assumedA = Number(timeArray[timeArray.length / 2]);
      }

      let answerPallete = [];

      let totaldays = 0;
      let totalfd = 0;
      let totalfd2 = 0;

      console.log("🚀 ~let i of this.state.travelTime", this.state.travelTime);

      for (let i of this.state.travelTime) {
        let time = Number(i.time);
        let days = Number(i.days);

        answerPallete.push({
          time: time,
          days: days,

          f: Math.round(parseFloat(time) - assumedA),
          // f : Math.round((parseFloat(i.time) - assumedA)),

          fd: Math.round(parseFloat(time) - assumedA) * parseFloat(days),
          fd2: Math.round((parseFloat(time) - assumedA) ** 2) * parseFloat(days),
        });

        totaldays += Number(days);
        totalfd += Math.round(parseFloat(time) - assumedA) * parseFloat(days);
        totalfd2 += Math.round((parseFloat(time) - assumedA) ** 2) * parseFloat(days);
      }

      const standardDeviation = makeThreeDigitPression(Math.sqrt(totalfd2 / totaldays - (totalfd / totaldays) ** 2));
      const standardDeviationStepTwoOne = makeThreeDigitPression(totalfd2 / totaldays);

      const standardDeviationStepTwoTwo = makeThreeDigitPression((totalfd / totaldays) ** 2);

      const standardDeviationStepThree = standardDeviationStepTwoOne - standardDeviationStepTwoTwo;
      console.log(
        "🚀 ~ file: skewnessAndItsCoefficient.js ~ line 380 ~ OperatingRatio ~ onSubmit ~ standardDeviationStep3",
        standardDeviationStepThree
      );

      const skewness = makeThreeDigitPression(assumedA + Math.round(totalfd / totaldays) - assumedA);

      const j = makeThreeDigitPression(skewness / standardDeviation);

      let dataObject = {
        answerPallete,
        assumedA,
        totaldays,
        totalfd,
        totalfd2,
        gcdTime,
        skewness,
        j,
        standardDeviation,
        standardDeviationStepTwoOne,
        standardDeviationStepTwoTwo,
        standardDeviationStepThree,
        mean: assumedA + Math.round(totalfd / totaldays),
        type: this.state.type,
      };

      data.push(JSON.parse(JSON.stringify(dataObject)));

      chartState.selected += 1;

      this.setState({});

      console.log(JSON.stringify(dataObject, null, 4));
    }

    onReset() {}

    addRow() {
      this.state.travelTime.push({
        time: 100,
        days: 10,
      });
      this.state.editModetravelTime.push({
        time: true,
        days: true,
      });
      this.setState({
        editModeButton: true,
      });
    }

    removeRow(event) {
      this.state.travelTime.splice(event, 1);
      this.state.editModetravelTime.splice(event, 1);
      this.setState({});
    }

    switchTableToChartAndBack() {}

    showSolutionOnOff() {
      this.setState({
        solutionShow: !this.state.solutionShow,
      });
    }

    moveLeftAndRight(event) {
      if (event.target.name === "Left") {
        chartState.selected = chartState.selected - 1;
        this.setState({});
      } else if (event.target.name === "Right") {
        chartState.selected += 1;
        this.setState({});
      }
    }

    //#endregion BUTTON PANEL END

    //#region EDIT BUTTON START

    buttonMaker(event) {
      let customArray = event.target.name.split(",");

      if (customArray.length === 2) {
        switch (customArray[0]) {
          case "time":
            this.state.editModetravelTime[customArray[1]].time = true;
            this.setState({
              editModeButton: true,
              submitChecker: false,
            });
            break;
          case "days":
            this.state.editModetravelTime[customArray[1]].days = true;
            this.setState({
              editModeButton: true,
              submitChecker: false,
            });
            break;
        }
      }

      switch (event.target.name) {
        case "type":
          this.setState({
            editModetype: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
      }
    }

    editModeOff() {
      for (let i of this.state.editModetravelTime) {
        i.days = false;
        i.time = false;
      }

      this.setState({
        submitChecker: true,

        editModename: false,
        editModetype: false,
        editModesampleString: false,
        editModeButton: false,
      });
    }

    //#endregion EDIT BUTTON END

    render() {
      return (
        <div>
          <br />
          <div label="InputFieldTables" align="left">
            {/* The time&nbsp;
            {this.state.editModetype ? (
              <FormControl style={{ width: "9%" }}>
                <InputLabel id="demo-simple-select-label">Option to Add</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.type}
                  label="Option to Add"
                  onChange={this.handleChange.bind(this)}
                >
                  <MenuItem value={"ms"}>ms</MenuItem>
                  <MenuItem value={"s"}>s</MenuItem>
                  <MenuItem value={"min"}>min</MenuItem>
                  <MenuItem value={"hr"}>hr</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <a className="txt" name="type" onClick={this.buttonMaker.bind(this)}>
                ({this.state.type})
              </a>
            )} */}
            &nbsp; The following data related to units transported by 50 trucks from railway yards to different
            factories on a day. Find the <b>skewness and its coefficient</b> using Karl Pearsons`s method from these
            data.
            {/* taken by a bus
            to travel between two towns on different days is shown in the following */}
            <br />
            <br />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">No. of units transported</StyledTableCell>
                    <StyledTableCell align="center">No. of trucks</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.travelTime.map((x, i) => (
                    <TableRow key={i}>
                      <StyledTableCell align="center">
                        {this.state.editModetravelTime[i].time ? (
                          <TextField
                            style={{ width: "20%", padding: "-10px" }}
                            name={["time", i]}
                            id="outlined-base"
                            error={x.time === "" || isNaN(x.time)}
                            value={x.time}
                            onChange={this.eventHandler}
                            onKeyDown={this.autoSave}
                            variant="standard"
                            autoComplete="off"
                          />
                        ) : (
                          <a className="txt" name={["time", i]} onClick={this.buttonMaker.bind(this)}>
                            {" "}
                            {x.time}
                          </a>
                        )}
                        &nbsp;
                        {this.state.travelTime.length > 2 && (
                          <IconButton
                            style={{ width: "3%", color: "red" }}
                            onClick={this.removeRow.bind(this, i)}
                            name="-"
                          >
                            -
                          </IconButton>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {this.state.editModetravelTime[i].days ? (
                          <TextField
                            style={{ width: "20%", padding: "-10px" }}
                            name={["days", i]}
                            id="outlined-base"
                            error={x.days === "" || isNaN(x.days)}
                            value={x.days}
                            onChange={this.eventHandler}
                            onKeyDown={this.autoSave}
                            variant="standard"
                            autoComplete="off"
                          />
                        ) : (
                          <a className="txt" name={["days", i]} onClick={this.buttonMaker.bind(this)}>
                            {" "}
                            {x.days}
                          </a>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <br />
            <AddButton style={{ width: "15%" }} onClick={this.addRow.bind(this)}>
              ADD INSTANCE
            </AddButton>
            <br />
            <br />
            {this.state.editModeButton ? (
              <ColorButton
                disabled={this.state.editModeButtonChecker}
                style={{ "margin-top": "-20px" }}
                align="right"
                onClick={this.editModeOff.bind(this)}
              >
                Edit Mode Off
              </ColorButton>
            ) : (
              <span></span>
            )}
            <br />
            <br />
            <div align="left" label="buttons">
              <AddButton
                style={{ width: "15%" }}
                disabled={!this.state.submitChecker}
                onClick={this.onSubmit.bind(this)}
              >
                CALCULATE SUM
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
              <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>
                SHOW/HIDE SOLUTION
              </AddButton>
            </div>
            <div align="right" label="sumNavigator">
              <AddButton
                name="Left"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected - 1 < 0}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ◄
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {chartState.selected + 1}
              &nbsp;&nbsp;&nbsp;
              <AddButton
                name="Right"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected + 1 > data.length - 1}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ►
              </AddButton>
            </div>
          </div>
          <br />
          <br />
          {this.state.solutionShow && (
            <div label="Answer" align="left">
              The given frequency distribution is unimodal . Hence, we will find the mean, mode, and standard deviation
              to find skewness by Karl Person's Method. The given frequency distribution is unimodal . Hence, we will
              find the mean, mode, and standard deviation to find skewness by Karl Person's Method.
              <br /> <br />
              {/* {data[chartState.selected].answerPallete.map((x, i) => (
                <span>
                  {x.time} - {data[chartState.selected].assumedA} = {x.f} <br />
                </span>
              ))}
              The common factor among them is {data[chartState.selected].gcdTime}. Hence we will take <i>c</i> ={" "}
              {data[chartState.selected].gcdTime}
              <br />
              Thus, we will have <i>d</i> ={" "}
              <sup>
                <i>(x - A)</i>
              </sup>
              /<sub>c</sub> ={" "}
              <sup>
                (<i>x</i> - {data[chartState.selected].assumedA})
              </sup>
              /<sub>{data[chartState.selected].gcdTime}</sub>
              <br />
              <br /> */}
              {/* The calculations of the mean will be as follows : */}
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        No. of units transported
                        <br />
                        <i>x</i>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        No. of trucks
                        <br />
                        <i>f</i>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <i>d = x - A</i>
                        <br />
                        <i>A</i> = {data[chartState.selected].assumedA}
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ verticalAlign: "top" }}>
                        <i>fd</i>
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ verticalAlign: "top" }}>
                        <i>
                          fd<sup>2</sup>
                        </i>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data[chartState.selected].answerPallete &&
                      data[chartState.selected].answerPallete.map((x, i) => (
                        <TableRow>
                          <StyledTableCell align="center">{x.time}</StyledTableCell>
                          <StyledTableCell align="center">{x.days}</StyledTableCell>
                          <StyledTableCell align="center">{x.f}</StyledTableCell>
                          <StyledTableCell align="center">{x.fd}</StyledTableCell>
                          <StyledTableCell align="center">{x.fd2}</StyledTableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <StyledTableCell align="center">
                        <b>Total</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>
                          <i>n</i> = {data[chartState.selected].totaldays}
                        </b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b></b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>{data[chartState.selected].totalfd}</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>{data[chartState.selected].totalfd2}</b>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <br />
              Mean x̄ = <i>A</i> +{" "}
              <sup>
                Σ<i>fd</i>
              </sup>
              /
              <sub>
                <i>n</i>
              </sub>{" "}
              <br />
              &emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].assumedA} +{" "}
              <sup>{data[chartState.selected].totalfd}</sup>/<sub>{data[chartState.selected].totaldays}</sub>
              <br />
              &emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].assumedA} +{" "}
              {Math.round(data[chartState.selected].totalfd / data[chartState.selected].totaldays)}
              <br />
              &emsp;&emsp;&emsp;&nbsp;&nbsp;= {data[chartState.selected].mean}
              <br />
              <br />
              Thus the mean travel time for the bus is {data[chartState.selected].mean} Units
              <br />
              <br />
              Mode M<sub>0</sub> &emsp;= &emsp;observation with the highest frequency ={" "}
              {data[chartState.selected].assumedA}
              <br />
              &emsp;&emsp;&emsp;M<sub>0</sub> &emsp;= &emsp; {data[chartState.selected].mean} Units
              <br />
              <br />
              Standard Deviation <i>S</i> = &#8730; ({" "}
              <sup>
                Σ
                <i>
                  fd<sup>2</sup>
                </i>
              </sup>
              /
              <sub>
                <i>n</i>
              </sub>{" "}
              ) - (
              <sup>
                Σ<i>fd</i>
              </sup>
              /
              <sub>
                <i>n</i>
              </sub>{" "}
              )<sup>2</sup>
              <br />
              &emsp;&emsp;&emsp; &emsp;&emsp;&emsp; &emsp; &emsp;&emsp; = &#8730; ({" "}
              <sup>{data[chartState.selected].totalfd2}</sup>/<sub>{data[chartState.selected].totaldays}</sub> ) - (
              <sup>{data[chartState.selected].totalfd}</sup>/<sub>{data[chartState.selected].totaldays}</sub> )
              <sup>2</sup>
              <br />
              &emsp;&emsp;&emsp; &emsp;&emsp;&emsp; &emsp; &emsp;&emsp; = &#8730;
              {data[chartState.selected].standardDeviationStepTwoOne}&emsp;-&emsp;
              {data[chartState.selected].standardDeviationStepTwoTwo}
              <br />
              &emsp;&emsp;&emsp; &emsp;&emsp;&emsp; &emsp; &emsp;&emsp; = &#8730;
              {data[chartState.selected].standardDeviationStepThree}
              <br />
              &emsp;&#x2234;&emsp;&emsp; &emsp; &emsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&emsp; ={" "}
              {data[chartState.selected].standardDeviation}
              <br />
              &emsp;&#x2234;&emsp; &emsp;&emsp;&emsp; &emsp;&emsp; &emsp;<i>S</i> ={" "}
              {data[chartState.selected].standardDeviation} Units
              <br />
              <br />
              Skewness &emsp;&emsp; S<sub>k</sub> &emsp;= &emsp; <i>x̄</i> -
              <i>
                M<sub>o</sub>
              </i>{" "}
              <br />
              &emsp;&emsp; &emsp; &emsp; &emsp;&emsp;&emsp;&emsp; = &emsp;{data[chartState.selected].mean} &emsp; -
              &emsp; {data[chartState.selected].assumedA}
              <br />
              &emsp;&emsp;&emsp; &emsp; &emsp; &emsp;&emsp;&emsp; = &emsp;{data[chartState.selected].skewness} &emsp;
              <br />
              &emsp;&emsp;&emsp; &emsp; &emsp;&emsp; S<sub>k</sub> &emsp;= &emsp; {data[chartState.selected].skewness}
              Units
              <br />
              <br />
              Coefficient of Skewness <i>j</i> &emsp;= &emsp;{" "}
              <sup>
                (<i>x̄</i> &emsp; -&emsp;{" "}
                <i>
                  M<sub>o</sub>
                </i>
                )
              </sup>{" "}
              /{" "}
              <sub>
                {" "}
                <i>S</i>
              </sub>
              <br />
              &emsp;&emsp; &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp; &emsp;&emsp;&nbsp; =&emsp; (
              {data[chartState.selected].mean} &emsp; - &emsp; {data[chartState.selected].assumedA}) /{" "}
              <sub>{data[chartState.selected].standardDeviation}</sub> &emsp;
              <br />
              &emsp;&emsp; &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&nbsp;&nbsp; =
              <sup> {data[chartState.selected].skewness}</sup>/ <sub>{data[chartState.selected].standardDeviation}</sub>
              <br />
              &emsp;&#x2234;&emsp; &emsp;&emsp;&emsp; &emsp; &emsp;&emsp;&emsp;<i>j</i>&emsp;&nbsp;&nbsp; =
              {data[chartState.selected].j}
              <br />
              <br />
              <p>
                THis distribution has positive skewness. it should be noted that <i>j</i>={" "}
                {data[chartState.selected].skewness} is free from units as coefficient of sknwness is free from units.
              </p>
            </div>
          )}
          <br />
          <br />
        </div>
      );
    }
  }

  return (
    <div>
      <Container fixed>
        <OperatingRatio />
      </Container>
    </div>
  );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button, Switch, touchRippleClasses } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
//ILLUSTRATION 8

export default function ComparativeProfitLossStatement() {

    const data = [{
        "bookOwnerName": "Rucha",
        "date": "2015-02-18",
        "initialBalance": 10000,
        "answerPallete": [
            {
                "type": "depositedNotCredited",
                "left": "",
                "right": 6000
            },
            {
                "type": "issuedNotGiven",
                "left": 4000,
                "right": ""
            },
            {
                "type": "paidNotGiven",
                "left": "",
                "right": 12000
            },
            {
                "type": "depositedDirectly",
                "left": 20000,
                "right": ""
            },
            {
                "type": "recordedTwice",
                "left": 100,
                "right": ""
            },
            {
                "type": "dishonoured",
                "left": "",
                "right": 6000
            },
            {
                "type": "issuedNotPresented",
                "left": 2000,
                "right": ""
            },
            {
                "type": "issuedNotGivenTillDate",
                "left": "",
                "right": ""
            },
            {
                "type": "interestCreditedNotRecorded",
                "left": 200,
                "right": 100
            },
            {
                "type": "overcast",
                "left": "",
                "right": 4000
            },
            {
                "type": "discountedDishonouredNotRecorded",
                "left": "",
                "right": 5050
            },
            {
                "type": "paidNotRecorded",
                "left": "",
                "right": 2900
            }
        ],
        "answerPalleteTotal": 36300,
        "bankBalance": {
            "left": "",
            "right": 250
        }
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const AlphabetRegex = new RegExp(/^[a-zA-Z]+$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                bookOwnerName: "Rucha",
                initialBalance: 10000,
                year: "2015-02-18",
                questionPallete: [
                    {//1
                        questionType: "notCredited",
                        context: {
                            type: "depositedNotCredited",
                            originalAmount: 10000,
                            amount: 4000,
                            date: "2015-02-28"
                        }
                    },
                    {//2
                        questionType: "notGiven",
                        context: {
                            type: "issuedNotGiven",
                            amount: 4000
                        }
                    },
                    {//3
                        questionType: "notGiven",
                        context: {
                            type: "paidNotGiven",
                            name: "Insurance premium",
                            amount: 12000
                        }
                    },
                    {//4
                        questionType: "deposited",
                        context: {
                            type: "depositedDirectly",
                            name: "Rucha",
                            amount: 20000,
                            date: "2015-03-01"
                        }
                    },
                    {//5
                        questionType: "recordedTwice",
                        context: {
                            type: "recordedTwice",
                            amount: 100
                        }
                    },
                    {//6
                        questionType: "deposited",
                        context: {
                            type: "dishonoured",
                            name: "Rucha",
                            amount: 6000,
                            depositedDate: "2015-02-27",
                            receivedDate: "2015-03-03"
                        }
                    },
                    {//7
                        questionType: "notPresented",
                        context: {
                            type: "issuedNotPresented",
                            originalAmount: 8000,
                            amount: 2000,
                            date: "2015-03-01"
                        }
                    },
                    {//8
                        questionType: "notGiven",
                        context: {
                            type: "issuedNotGivenTillDate",
                            amount: 4000,
                            date: "2015-02-28"
                        }
                    },
                    {//9
                        questionType: "notRecorded",
                        context: {
                            type: "interestCreditedNotRecorded",
                            credit: 200,
                            debit: 100
                        }
                    },
                    {//10
                        questionType: "overcast",
                        context: {
                            type: "overcast",
                            amount: 4000
                        }
                    },
                    {//11
                        questionType: "triple",
                        context: {
                            type: "discountedDishonouredNotRecorded",
                            amount: 5000,
                            charges: 50,
                            dishonourDate: "2015-02-26",
                            notRecordedTillDate: "2015-02-28"
                        }
                    },
                    {//12
                        questionType: "notRecorded",
                        context: {
                            type: "paidNotRecorded",
                            amount: 3000,
                            rebate: 100,
                            rebateDate: "2015-02-28"
                        }
                    }


                    // {//1
                    //     questionType: "purchase",
                    //     context: {
                    //         type: "multipurchase",
                    //         name: ["cash balance", "bank balance"],
                    //         amount: [18600, 62000]
                    //     },
                    //     date: 1
                    // },
                    //#region
                    // {//2
                    //     questionType: "payment",
                    //     context: {
                    //         type: "paidAmount",
                    //         name: "Salary",
                    //         amount: 6000,
                    //         style: "cash"
                    //     },
                    //     date: 2
                    // },
                    // {//3
                    //     questionType: "payment",
                    //     context: {
                    //         type: "paidAmount",
                    //         name: "Electricity Bill",
                    //         amount: 1200,
                    //         style: "cash"
                    //     },
                    //     date: 2
                    // },
                    // {//4
                    //     questionType: "withdraw",
                    //     context: {
                    //         type: "withdraw",
                    //         name: "shop",
                    //         amount: 10000,
                    //         style: "cheque"
                    //     },
                    //     date: 4
                    // },
                    // {//5
                    //     questionType: "settlement",
                    //     context: {
                    //         type: "debtSettlement",
                    //         name: "Shah Brothers",
                    //         amount: 8000,
                    //         debt: 8200,
                    //         style: "cheque"
                    //     },
                    //     date: 5
                    // },
                    // {//6
                    //     questionType: "received",
                    //     context: {
                    //         type: "commission",
                    //         name: "Camlin Company",
                    //         amount: 2500,
                    //         style: "cash"
                    //     },
                    //     date: 6
                    // },
                    // {//7
                    //     questionType: "received",
                    //     context: {
                    //         type: "chequeSettlement",
                    //         name: "Desouza",
                    //         amount: 2550,
                    //         recievables: 2500,
                    //         style: "cheque"
                    //     },
                    //     date: 7
                    // },
                    // {//8
                    //     questionType: "withdraw",
                    //     context: {
                    //         type: "withdraw",
                    //         name: "household",
                    //         amount: 5000,
                    //         style: "cash"
                    //     },
                    //     date: 7
                    // },
                    //#endregion
                ],

                editModequestionPalette: [
                    {//1
                        originalAmount: false,
                        amount: false,
                        date: false
                    },
                    {//2
                        amount: false
                    },
                    {//3
                        name: false,
                        amount: false
                    },
                    {//4
                        name: false,
                        amount: false
                    },
                    {//5
                        amount: false
                    },
                    {//6
                        name: false,
                        amount: false,
                        depositedDate: false,
                        receivedDate: false,
                    },
                    {//7
                        originalAmount: false,
                        amount: false,
                        date: false,
                    },
                    {//8
                        amount: false,
                        date: false,
                    },
                    {//9
                        credit: false,
                        debit: false
                    },
                    {//10
                        amount: false
                    },
                    {//11
                        amount: false,
                        charges: false,
                        dishonourDate: false,
                        notRecordedTillDate: false
                    },
                    {//12
                        amount: false,
                        rebate: false,
                        rebateDate: false
                    }

                    // {//1
                    //     nameMultiple: [false, false],
                    //     amountMultiple: [false, false]
                    // },
                    //#region
                    // {//2
                    //     name: false,
                    //     amount: false
                    // },
                    // {//3
                    //     name: false,
                    //     amount: false
                    // },
                    // {//4
                    //     name: false,
                    //     amount: false
                    // },
                    // {//5
                    //     name: false,
                    //     amount: false,
                    //     debt: false
                    // },
                    // {//6
                    //     name: false,
                    //     amount: false
                    // },
                    // {//7
                    //     name: false,
                    //     amount: false,
                    //     recievables: false
                    // },
                    // {//
                    //     name: false,
                    //     amount: false
                    // }
                    //#endregion
                ],

                editModebookOwnerName: false,
                editModeyear: false,
                editModeinitialBalance: false,

                addQuestionChecker: false,


                solutionShow: false,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (event.target.value !== '-') {
                if (customArray.length === 3) {
                    switch (customArray[0]) {
                        case 'depositedNotCredited':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'date':
                                    this.state.questionPallete[customArray[2]].context.date = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'originalAmount':
                                    this.state.questionPallete[customArray[2]].context.originalAmount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'issuedNotGiven':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'paidNotGiven':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'depositedDirectly':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'date':
                                    this.state.questionPallete[customArray[2]].context.date = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'recordedTwice':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'dishonoured':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'depositedDate':
                                    this.state.questionPallete[customArray[2]].context.depositedDate = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'name':
                                    this.state.questionPallete[customArray[2]].context.name = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'receivedDate':
                                    this.state.questionPallete[customArray[2]].context.receivedDate = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'issuedNotPresented':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'date':
                                    this.state.questionPallete[customArray[2]].context.date = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'originalAmount':
                                    this.state.questionPallete[customArray[2]].context.originalAmount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'issuedNotGivenTillDate':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'date':
                                    this.state.questionPallete[customArray[2]].context.date = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'interestCreditedNotRecorded':
                            switch (customArray[1]) {
                                case 'credit':
                                    this.state.questionPallete[customArray[2]].context.credit = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'debit':
                                    this.state.questionPallete[customArray[2]].context.debit = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'overcast':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'discountedDishonouredNotRecorded':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'dishonourDate':
                                    this.state.questionPallete[customArray[2]].context.dishonourDate = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'charges':
                                    this.state.questionPallete[customArray[2]].context.charges = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'notRecordedTillDate':
                                    this.state.questionPallete[customArray[2]].context.notRecordedTillDate = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                        case 'notRecorded':
                            switch (customArray[1]) {
                                case 'amount':
                                    this.state.questionPallete[customArray[2]].context.amount = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'rebate':
                                    this.state.questionPallete[customArray[2]].context.rebate = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                                case 'rebateDate':
                                    this.state.questionPallete[customArray[2]].context.rebateDate = event.target.value
                                    this.setState({}, () => { this.fillDetails() })
                                    break;
                            }
                            break;
                    }
                }

                switch (event.target.name) {
                    case 'bookOwnerName':
                        this.setState({
                            bookOwnerName: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'year':
                        this.setState({
                            year: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                    case 'initialBalance':
                        this.setState({
                            initialBalance: event.target.value
                        }, () => { this.fillDetails() })
                        break;
                }
            }
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.questionPallete) {
                switch (i.context.type) {
                    case 'depositedNotCredited':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.originalAmount === '' || !NumberRegex.test(i.context.originalAmount) ||
                            parseFloat(i.context.originalAmount) <= parseFloat(i.context.amount)
                        ) {
                            flag = false
                        }
                        break;
                    case 'issuedNotGiven':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'paidNotGiven':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.name === '') {
                            flag = false
                        }
                        break;
                    case 'depositedDirectly':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'recordedTwice':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'dishonoured':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'issuedNotPresented':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.originalAmount === '' || !NumberRegex.test(i.context.originalAmount) ||
                            parseFloat(i.context.originalAmount) <= parseFloat(i.context.amount)
                        ) {
                            flag = false
                        }
                        break;
                    case 'issuedNotGivenTillDate':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'interestCreditedNotRecorded':
                        if (i.context.credit === '' || !NumberRegex.test(i.context.credit) ||
                            i.context.debit === '' || !NumberRegex.test(i.context.debit)) {
                            flag = false
                        }
                        break;
                    case 'overcast':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount)) {
                            flag = false
                        }
                        break;
                    case 'discountedDishonouredNotRecorded':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.charges === '' || !NumberRegex.test(i.context.charges)) {
                            flag = false
                        }
                        break;
                    case 'paidNotRecorded':
                        if (i.context.amount === '' || !NumberRegex.test(i.context.amount) ||
                            i.context.rebate === '' || !NumberRegex.test(i.context.rebate) ||
                            parseFloat(i.context.amount) <= parseFloat(i.context.rebate)) {
                            flag = false
                        }
                        break;
                }
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: false,
                })
            } else {
                this.setState({
                    editModeButtonChecker: true,
                    submitChecker: false
                })
            }
        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            // console.log(JSON.stringify(this.state.questionPallete,null,4))
            let answerPallete = []

            for (let i of this.state.questionPallete) {
                switch (i.context.type) {
                    case 'depositedNotCredited':
                        answerPallete.push({
                            type: "depositedNotCredited",
                            left: "",
                            right: parseFloat(i.context.originalAmount) - parseFloat(i.context.amount)
                        })
                        break;
                    case 'issuedNotGiven':
                        answerPallete.push({
                            type: "issuedNotGiven",
                            left: i.context.amount,
                            right: ""
                        })
                        break;
                    case 'paidNotGiven':
                        answerPallete.push({
                            type: "paidNotGiven",
                            left: "",
                            right: i.context.amount
                        })
                        break;
                    case 'depositedDirectly':
                        answerPallete.push({
                            type: "depositedDirectly",
                            left: i.context.amount,
                            right: ""
                        })
                        break;
                    case 'recordedTwice':
                        answerPallete.push({
                            type: "recordedTwice",
                            left: i.context.amount,
                            right: ""
                        })
                        break;
                    case 'dishonoured':
                        answerPallete.push({
                            type: "dishonoured",
                            left: "",
                            right: i.context.amount
                        })
                        break;
                    case 'issuedNotPresented':
                        answerPallete.push({
                            type: "issuedNotPresented",
                            left: i.context.amount,
                            right: ""
                        })
                        break;
                    case 'issuedNotGivenTillDate':
                        answerPallete.push({
                            type: "issuedNotGivenTillDate",
                            left: "",
                            right: ""
                        })
                        break;
                    case 'interestCreditedNotRecorded':
                        answerPallete.push({
                            type: "interestCreditedNotRecorded",
                            left: i.context.credit,
                            right: i.context.debit
                        })
                        break;
                    case 'overcast':
                        answerPallete.push({
                            type: "overcast",
                            left: "",
                            right: i.context.amount
                        })
                        break;
                    case 'discountedDishonouredNotRecorded':
                        answerPallete.push({
                            type: "discountedDishonouredNotRecorded",
                            left: "",
                            right: parseFloat(i.context.amount) + parseFloat(i.context.charges)
                        })
                        break;
                    case 'paidNotRecorded':
                        answerPallete.push({
                            type: "paidNotRecorded",
                            left: "",
                            right: parseFloat(i.context.amount) - parseFloat(i.context.rebate)
                        })
                        break;
                }
            }

            let answerPalleteTotal = {
                left: parseFloat(this.state.initialBalance),
                right: 0
            }

            let bankBalance

            for (let i of answerPallete) {
                if (i.left !== '') {
                    answerPalleteTotal.left += parseFloat(i.left)
                }
                if (i.right !== '') {
                    answerPalleteTotal.right += parseFloat(i.right)
                }
            }

            if (answerPalleteTotal.left > answerPalleteTotal.right) {
                bankBalance = {
                    left: "",
                    right: answerPalleteTotal.left - answerPalleteTotal.right
                }
            }
            else if (answerPalleteTotal.left < answerPalleteTotal.right) {
                bankBalance = {
                    left: answerPalleteTotal.right - answerPalleteTotal.left,
                    right: ""
                }
            }
            else {
                bankBalance = {
                    left: "",
                    right: ""
                }
            }

            let dataObject = {
                bookOwnerName: this.state.bookOwnerName,
                date: this.state.year,
                initialBalance: this.state.initialBalance,
                answerPallete,
                answerPalleteTotal: answerPalleteTotal.left > answerPalleteTotal.right ? answerPalleteTotal.left : answerPalleteTotal.right,
                bankBalance
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.clear()
            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 3) {
                switch (customArray[0]) {
                    case 'depositedNotCredited':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'date':
                                this.state.editModequestionPalette[customArray[2]].date = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'originalAmount':
                                this.state.editModequestionPalette[customArray[2]].originalAmount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'issuedNotGiven':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'paidNotGiven':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'depositedDirectly':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'date':
                                this.state.editModequestionPalette[customArray[2]].date = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'recordedTwice':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'dishonoured':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'depositedDate':
                                this.state.editModequestionPalette[customArray[2]].depositedDate = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'name':
                                this.state.editModequestionPalette[customArray[2]].name = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'receivedDate':
                                this.state.editModequestionPalette[customArray[2]].receivedDate = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'issuedNotPresented':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'date':
                                this.state.editModequestionPalette[customArray[2]].date = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'originalAmount':
                                this.state.editModequestionPalette[customArray[2]].originalAmount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'issuedNotGivenTillDate':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'date':
                                this.state.editModequestionPalette[customArray[2]].date = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'interestCreditedNotRecorded':
                        switch (customArray[1]) {
                            case 'credit':
                                this.state.editModequestionPalette[customArray[2]].credit = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'debit':
                                this.state.editModequestionPalette[customArray[2]].debit = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'overcast':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'discountedDishonouredNotRecorded':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'dishonourDate':
                                this.state.editModequestionPalette[customArray[2]].dishonourDate = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'charges':
                                this.state.editModequestionPalette[customArray[2]].charges = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'notRecordedTillDate':
                                this.state.editModequestionPalette[customArray[2]].notRecordedTillDate = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                    case 'notRecorded':
                        switch (customArray[1]) {
                            case 'amount':
                                this.state.editModequestionPalette[customArray[2]].amount = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'rebate':
                                this.state.editModequestionPalette[customArray[2]].rebate = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                            case 'rebateDate':
                                this.state.editModequestionPalette[customArray[2]].rebateDate = true
                                this.setState({
                                    editModeButton: true
                                })
                                break;
                        }
                        break;
                }
            }

            switch (event.target.name) {
                case 'bookOwnerName':
                    this.setState({
                        editModebookOwnerName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'year':
                    this.setState({
                        editModeyear: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'initialBalance':
                    this.setState({
                        editModeinitialBalance: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {
            for (let i of this.state.editModequestionPalette) {
                if (i.name) {
                    i.name = false
                }
                if (i.amount) {
                    i.amount = false
                }
                if (i.originalAmount) {
                    i.originalAmount = false
                }
                if (i.date) {
                    i.date = false
                }
                if (i.depositedDate) {
                    i.depositedDate = false
                }
                if (i.receivedDate) {
                    i.receivedDate = false
                }
                if (i.credit) {
                    i.credit = false
                }
                if (i.debit) {
                    i.debit = false
                }
                if (i.dishonourDate) {
                    i.dishonourDate = false
                }
                if (i.notRecordedTillDate) {
                    i.notRecordedTillDate = false
                }
                if (i.rebate) {
                    i.rebate = false
                }
                if (i.rebateDate) {
                    i.rebateDate = false
                }
                if (i.charges) {
                    i.charges = false
                }
            }


            this.setState({
                editModebookOwnerName: false,
                editModeyear: false,
                editModeinitialBalance: false,

                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        
                        {this.state.editModebookOwnerName ? <TextField style={{ 'margin-top': "-15px", }} name="bookOwnerName" id='outlined-base' error={this.state.bookOwnerName === ''}
                            value={this.state.bookOwnerName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="bookOwnerName" onClick={this.buttonMaker.bind(this)}> {this.state.bookOwnerName}</a>} ની રોકડમેળ પ્રમાણે તા.&nbsp;

                        
                        {this.state.editModeyear ? <TextField type="date" style={{ 'margin-top': "-15px", }} name="year" id='outlined-base' error={this.state.year === ''}
                            value={this.state.year} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="year" onClick={this.buttonMaker.bind(this)}> {this.state.year}</a>}&nbsp;

                        ના રોજ બેંક સિલ્ક ₹&nbsp;
                        {this.state.editModeinitialBalance ? <TextField style={{ 'margin-top': "-15px", }} name="initialBalance" id='outlined-base' error={this.state.initialBalance === '' || isNaN(this.state.initialBalance)}
                            value={this.state.initialBalance} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="initialBalance" onClick={this.buttonMaker.bind(this)}> {this.state.initialBalance}</a>} હતી,&nbsp;

                        જે પાસબુક પ્રમાણેની બેન્કની બાકી સાથે મળતી ન હતી.<br /><br />

                        નીચેની માહિતી પરથી {this.state.bookOwnerName} નો બૅન્ક સિલકમેળ તૈયાર કરો.
                        <br /><br /><br />
                        <div label="totalQuestionsInArray">
                            {this.state.questionPallete.map((x, i) => (
                                <div>
                                    <span>({i + 1})</span>&emsp;&emsp;
                                    {x.context.type === 'depositedNotCredited' &&
                                        <span>
                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].originalAmount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["depositedNotCredited", "originalAmount", i]} id='outlined-base' error={x.context.originalAmount === '' || isNaN(x.context.originalAmount)}
                                                value={x.context.originalAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["depositedNotCredited", "originalAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.originalAmount}</a>} ના ચેક બેન્કમાં ભરેલા,&nbsp;

                                            જેમાંથી ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["depositedNotCredited", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["depositedNotCredited", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            ના ચેક તા.&nbsp;
                                            {this.state.editModequestionPalette[i].date ? <TextField type="date" style={{ marginTop: "-15px", width: "10%" }} name={["depositedNotCredited", "date", i]} id='outlined-base' error={x.context.date === ''}
                                                value={x.context.date} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["depositedNotCredited", "date", i]} onClick={this.buttonMaker.bind(this)}> {x.context.date}</a>} સુધી બૅન્કમાં જમા થયેલ છે.&nbsp;

                                        </span>
                                    }
                                    {x.context.type === 'issuedNotGiven' &&
                                        <span>
                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["issuedNotGiven", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["issuedNotGiven", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            નો એક ચેક લખેલો અને રોકડમેળમાં નોંધેલ; પરંતુ લેણદારને આપવાનો જ રહી ગયો છે.
                                        </span>
                                    }
                                    {x.context.type === 'paidNotGiven' &&
                                        <span>
                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["paidNotGiven", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidNotGiven", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>} બૅન્કે&nbsp;

                                            {this.state.editModequestionPalette[i].name ? <TextField style={{ marginTop: "-15px", width: "20%" }} name={["paidNotGiven", "name", i]} id='outlined-base' error={x.context.name === ''}
                                                value={x.context.name} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["paidNotGiven", "name", i]} onClick={this.buttonMaker.bind(this)}> {x.context.name}</a>}&nbsp;
                                            
                                            ના ચૂકવ્યા છે, જેની નોંધ રોકડમેળમાં કરવામાં આવી નથી.
                                        </span>
                                    }
                                    {x.context.type === 'depositedDirectly' &&
                                        <span>
                                            એક ગ્રાહકે {this.state.bookOwnerName} ના બૅન્ક ખાતામાં ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["depositedDirectly", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["depositedDirectly", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            બારોબાર જમા કરાવી દીધેલ, જેની જાણકારી {this.state.bookOwnerName} ને તા.&nbsp;
                                            {this.state.editModequestionPalette[i].date ? <TextField type="date" style={{ marginTop: "-15px", width: "10%" }} name={["depositedDirectly", "date", i]} id='outlined-base' error={x.context.date === ''}
                                                value={x.context.date} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["depositedDirectly", "date", i]} onClick={this.buttonMaker.bind(this)}> {x.context.date}</a>} ના રોજ મળી.&nbsp;
                                        </span>
                                    }
                                    {x.context.type === 'recordedTwice' &&
                                        <span>
                                            બૅન્કે નોંધેલ બૅન્કચાર્જિસના ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["recordedTwice", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["recordedTwice", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            રોકડમેળમાં ભૂલથી બે વાર નોંધાઈ ગયા છે.
                                        </span>
                                    }
                                    {x.context.type === 'dishonoured' &&
                                        <span>
                                            
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["dishonoured", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["dishonoured", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>} નો ચેક તા. &nbsp;

                                            
                                            {this.state.editModequestionPalette[i].depositedDate ? <TextField type="date" style={{ marginTop: "-15px", width: "10%" }} name={["dishonoured", "depositedDate", i]} id='outlined-base' error={x.context.depositedDate === ''}
                                                value={x.context.depositedDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["dishonoured", "depositedDate", i]} onClick={this.buttonMaker.bind(this)}> {x.context.depositedDate}</a>} ના રોજ બૅન્કમાં ભરેલ,&nbsp;

                                            તે નકરાયો, જેની માહિતી {this.state.bookOwnerName} ને તા.&nbsp;
                                            {this.state.editModequestionPalette[i].receivedDate ? <TextField type="date" style={{ marginTop: "-15px", width: "10%" }} name={["dishonoured", "receivedDate", i]} id='outlined-base' error={x.context.receivedDate === ''}
                                                value={x.context.receivedDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["dishonoured", "receivedDate", i]} onClick={this.buttonMaker.bind(this)}> {x.context.receivedDate}</a>} ના રોજ મળી.&nbsp;

                                        </span>
                                    }
                                    {x.context.type === 'issuedNotPresented' &&
                                        <span>
                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].originalAmount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["issuedNotPresented", "originalAmount", i]} id='outlined-base' error={x.context.originalAmount === '' || isNaN(x.context.originalAmount)}
                                                value={x.context.originalAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["issuedNotPresented", "originalAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.originalAmount}</a>}&nbsp;

                                            ના ચેક લખેલા, જેમાંથી  ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["issuedNotPresented", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["issuedNotPresented", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            ના ચેક તા. &nbsp;
                                            {this.state.editModequestionPalette[i].date ? <TextField type="date" style={{ marginTop: "-15px", width: "10%" }} name={["issuedNotPresented", "date", i]} id='outlined-base' error={x.context.date === ''}
                                                value={x.context.date} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["issuedNotPresented", "date", i]} onClick={this.buttonMaker.bind(this)}> {x.context.date}</a>} ના રોજ બૅન્કમાં રજૂ થયા હતા.&nbsp;
                                        </span>
                                    }
                                    {x.context.type === 'issuedNotGivenTillDate' &&
                                        <span>
                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["issuedNotGivenTillDate", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["issuedNotGivenTillDate", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            નો ચેક બૅન્કમાં ભરેલ; પરંતુ તેની નોંધ રોકડમેળમાં કરવામાં આવી નથી. આ ચેક તા.&nbsp;
                                            {this.state.editModequestionPalette[i].date ? <TextField type="date" style={{ marginTop: "-15px", width: "10%" }} name={["issuedNotGivenTillDate", "date", i]} id='outlined-base' error={x.context.date === ''}
                                                value={x.context.date} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["issuedNotGivenTillDate", "date", i]} onClick={this.buttonMaker.bind(this)}> {x.context.date}</a>} સુધી બૅન્ક દ્વારા વસુલ થયેલ નથી..&nbsp;
                                        </span>
                                    }
                                    {x.context.type === 'interestCreditedNotRecorded' &&
                                        <span>
                                            બેંકે પાસબુકમાં ₹&nbsp;
                                            {this.state.editModequestionPalette[i].credit ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["interestCreditedNotRecorded", "credit", i]} id='outlined-base' error={x.context.credit === '' || isNaN(x.context.credit)}
                                                value={x.context.credit} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["interestCreditedNotRecorded", "credit", i]} onClick={this.buttonMaker.bind(this)}> {x.context.credit}</a>}&nbsp;

                                            બૅન્કવ્યાજના જમા કર્યા છે અને બૅન્કુચાર્જિસના ₹&nbsp;
                                            {this.state.editModequestionPalette[i].debit ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["interestCreditedNotRecorded", "debit", i]} id='outlined-base' error={x.context.debit === '' || isNaN(x.context.debit)}
                                                value={x.context.debit} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["interestCreditedNotRecorded", "debit", i]} onClick={this.buttonMaker.bind(this)}> {x.context.debit}</a>}&nbsp;

                                            ઉધાર્યા છે.
                                        </span>
                                    }
                                    {x.context.type === 'overcast' &&
                                        <span>
                                            રોકડમેળની આયબાજૂનો સરવાળો ભૂલથી ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["overcast", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["overcast", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>} વધારે લેવાઈ ગયો છે.&nbsp;
                                        </span>
                                    }
                                    {x.context.type === 'discountedDishonouredNotRecorded' &&
                                        <span>
                                            ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["discountedDishonouredNotRecorded", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["discountedDishonouredNotRecorded", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            ની લેણીહૂંડી બૅન્કમાં વટાવેલ જે તા.&nbsp;
                                            {this.state.editModequestionPalette[i].dishonourDate ? <TextField type="date" style={{ marginTop: "-15px", width: "10%" }} name={["discountedDishonouredNotRecorded", "dishonourDate", i]} id='outlined-base' error={x.context.dishonourDate === ''}
                                                value={x.context.dishonourDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["discountedDishonouredNotRecorded", "dishonourDate", i]} onClick={this.buttonMaker.bind(this)}> {x.context.dishonourDate}</a>}&nbsp;

                                            ના રોજ નકરાયેલ અને બૅન્કે નકરામણી ખર્ચ ₹&nbsp;
                                            {this.state.editModequestionPalette[i].charges ? <TextField style={{ marginTop: "-15px", width: "5%" }} name={["discountedDishonouredNotRecorded", "charges", i]} id='outlined-base' error={x.context.charges === '' || isNaN(x.context.charges)}
                                                value={x.context.charges} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["discountedDishonouredNotRecorded", "charges", i]} onClick={this.buttonMaker.bind(this)}> {x.context.charges}</a>}.&nbsp;<br /><br />

                                            સહિત પાસબુકમાં ઉધાર્યા છે; પરંતુ હૂંડી નકારાયાની નોંધ તા.&nbsp;
                                            {this.state.editModequestionPalette[i].notRecordedTillDate ? <TextField type="date" style={{ marginTop: "-15px", width: "10%" }} name={["discountedDishonouredNotRecorded", "notRecordedTillDate", i]} id='outlined-base' error={x.context.notRecordedTillDate === ''}
                                                value={x.context.notRecordedTillDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["discountedDishonouredNotRecorded", "notRecordedTillDate", i]} onClick={this.buttonMaker.bind(this)}> {x.context.notRecordedTillDate}</a>} સુધી રોકડમેળમાં થયેલ નથી.&nbsp;
                                        </span>
                                    }
                                    {x.context.type === 'paidNotRecorded' &&
                                        <span>
                                            એક માસ બાદ પાક્તી ₹&nbsp;
                                            {this.state.editModequestionPalette[i].amount ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["notRecorded", "amount", i]} id='outlined-base' error={x.context.amount === '' || isNaN(x.context.amount)}
                                                value={x.context.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["notRecorded", "amount", i]} onClick={this.buttonMaker.bind(this)}> {x.context.amount}</a>}&nbsp;

                                            ની દેવીહૂંડી બૅન્ક મારફ્ત ₹&nbsp;
                                            {this.state.editModequestionPalette[i].rebate ? <TextField style={{ marginTop: "-15px", width: "10%" }} name={["notRecorded", "rebate", i]} id='outlined-base' error={x.context.rebate === '' || isNaN(x.context.rebate)}
                                                value={x.context.rebate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["notRecorded", "rebate", i]} onClick={this.buttonMaker.bind(this)}> {x.context.rebate}</a>}&nbsp;

                                            ના રિબેટથી&nbsp;
                                            {this.state.editModequestionPalette[i].rebateDate ? <TextField type="date" style={{ marginTop: "-15px", width: "10%" }} name={["notRecorded", "rebateDate", i]} id='outlined-base' error={x.context.rebateDate === ''}
                                                value={x.context.rebateDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["notRecorded", "rebateDate", i]} onClick={this.buttonMaker.bind(this)}> {x.context.rebateDate}</a>}&nbsp;

                                            ના રોજ ચૂકવેલ જેની નોંધ રોકડમેળમાં થયેલ નથી.
                                        </span>
                                    }



                                    <br /><br />
                                </div>
                            ))}
                        </div>



                        <br /><br /><br />
                        {this.state.editModeButton ? <ColorButton disabled={this.state.editModeButtonChecker} style={{ 'margin-top': "-20px", fontSize:"15px" }} align='right' onClick={this.editModeOff.bind(this)} >સંપાદિત મોડ બંધ કરો </ColorButton> : <span></span>}


                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                        </div>
                        <div align='right'>
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                        <br />
                        <br />

                    </div>
                    <br /><br />
                    {
                        this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>જવાબ :</b><br /><br />
                            <div align="center"><b> તા. {data[chartState.selected].date} ના રોજનો {data[chartState.selected].bookOwnerName} નો બૅન્ક સિલકમેળ</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>વ્યવહાર નંબર</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>ઉધાર (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>જમા (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="left" style={{fontSize:"15px"}}>રોકડમેળ પ્રમાણે બૅન્કસિલક</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].initialBalance}</StyledTableCell>
                                            <StyledTableCell align="center"></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].answerPallete.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell>({i + 1})</StyledTableCell>
                                                <StyledTableCell style={{fontSize:"15px"}}>
                                                    {x.type === 'depositedNotCredited' &&
                                                        <span>
                                                            બૅન્કમાં ભરેલ પરંતુ જમા નહિ થયેલ ચેક
                                                        </span>
                                                    }
                                                    {x.type === 'issuedNotGiven' &&
                                                        <span>
                                                            લખેલ અને રોકડમેળમાં નોંધેલ પરંતુ લેણદારને આપવાનો રહી ગયેલ ચેક
                                                        </span>
                                                    }
                                                    {x.type === 'paidNotGiven' &&
                                                        <span>
                                                            બૅન્કે ચૂકવેલ વીમા-પ્રીમિયમ, રોકડમેળમાં નોંધેલ નથી.
                                                        </span>
                                                    }
                                                    {x.type === 'depositedDirectly' &&
                                                        <span>
                                                            ગ્રાહકે બેન્કમાં બારોબાર ભરેલ રકમ
                                                        </span>
                                                    }
                                                    {x.type === 'recordedTwice' &&
                                                        <span>
                                                            રોકડમેળમાં બે વખત નોંધાયેલ બેચાર્જિસ
                                                        </span>
                                                    }
                                                    {x.type === 'dishonoured' &&
                                                        <span>
                                                            બૅન્કમાં ભરેલ પરંતુ નકરાયેલ ચેક નકરામણીની નોંધ રોકડમેળમાં થયેલ નથી.
                                                        </span>
                                                    }
                                                    {x.type === 'issuedNotPresented' &&
                                                        <span>
                                                            લખેલ પરંતુ રજૂ નહિ થયેલ ચેક
                                                        </span>
                                                    }
                                                    {x.type === 'issuedNotGivenTillDate' &&
                                                        <span>
                                                            <b>સમજૂતી :</b> વ્યવહાર {i + 1} ની નોંધ થશે નહિ, કારણ કે આ વ્યવહારની નોંધ તા.&nbsp;
                                                            {data[chartState.selected].date}, સુધી રોકડમેળ અને પાસબુક બંનેમાં થયેલ નથી તેથી બૅન્ક સિલકમેળમાં નોંધાશે નહિ.
                                                        </span>
                                                    }
                                                    {x.type === 'interestCreditedNotRecorded' &&
                                                        <span>
                                                            બૅન્કે જમા કરેલ બૅન્ક વ્યાજ, રોકડમેળમાં નોંધેલ નથી.<br />
                                                            બૅન્કે ઉધારેલ બૅન્કચાર્જિસ, રોકડમેળમાં નોંધેલ નથી.
                                                        </span>
                                                    }
                                                    {x.type === 'overcast' &&
                                                        <span>
                                                            રોકડમેળની આયબાજુ ભૂલથી વધારે લેવાયેલ સરવાળો 
                                                        </span>
                                                    }
                                                    {x.type === 'discountedDishonouredNotRecorded' &&
                                                        <span>
                                                            બૅન્કમાં વટાવેલ લેણીહુંડી નકરાયેલ, જેની નોંધ રોકડમેળમાં થયેલ નથી.
                                                        </span>
                                                    }
                                                    {x.type === 'paidNotRecorded' &&
                                                        <span>
                                                            બૅન્ક મારફત ચૂકવેલ દેવીહૂંડી, રોકડમેળમાં નોંધાયેલ નથી.
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x.left}
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x.type !== 'interestCreditedNotRecorded' ? x.right : <span><br />{x.right}</span>}
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="left" style={{fontSize:"15px"}}>પાસબુક પ્રમાણે બૅન્કસિલક</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].bankBalance.left}</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].bankBalance.right}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].answerPalleteTotal}</b></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].answerPalleteTotal}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                        </div>
                    }
                    <br />
                    <br />
                </div >
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button, getTypographyUtilityClass } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
import { Co2Sharp } from '@mui/icons-material';

//ILLUSTRATION 8

export default function ComparativeProfitLossStatement() {

    const data = [{
        "side": "left",
        "date": "2017-03-31",
        "partnersCapitalIndividual": [
            {
                "name": "Harsh",
                "amount": 17325
            },
            {
                "name": "Dhruvil",
                "amount": 11550
            },
            {
                "name": "Manoj",
                "amount": 5775
            }
        ],
        "partnersCapital": 34650,
        "rightSideTotalAmount": 123000,
        "AnswerPalette": [
            {
                "side": "right",
                "name": "જમીન",
                "amount": 50000
            },
            {
                "side": "right",
                "name": "મકાન",
                "amount": 70000
            },
            {
                "side": "left",
                "name": "યંત્રો",
                "amount": 50000
            },
            {
                "side": "left",
                "name": "રોકાણો",
                "amount": 24000
            },
            {
                "side": "left",
                "name": "દેવાદાર",
                "originalAmount": 80000,
                "originalAmountBDDiff": 72000,
                "badDebtR": 10,
                "discountR": 5,
                "badDebt": 8000,
                "discount": 3600
            },
            {
                "type": "percent",
                "side": "left",
                "name": "સ્ટૉક",
                "amount": 750
            },
            {
                "type": "Outstanding expense",
                "side": "left",
                "name": "ચૂકવવાના બાકી ખર્ચ",
                "amount": 2000
            },
            {
                "type": "Outstanding rent",
                "side": "right",
                "name": "અગાઉથી ચૂકવેલ ભાડા",
                "amount": 3000
            }
        ],
        "initialProfitLossRatio": [
            3,
            2,
            1
        ],
        "leftSideArray": [
            {
                "side": "left",
                "name": "યંત્રો",
                "amount": 50000
            },
            {
                "side": "left",
                "name": "રોકાણો",
                "amount": 24000
            },
            {
                "name": "ઘાલખાધ અનામત",
                "amount": 8000
            },
            {
                "name": "દેવાદાર પર વટાવ અનામત",
                "amount": 3600
            },
            {
                "type": "percent",
                "side": "left",
                "name": "સ્ટૉક",
                "amount": 750
            },
            {
                "type": "Outstanding expense",
                "side": "left",
                "name": "ચૂકવવાના બાકી ખર્ચ",
                "amount": 2000
            }
        ],
        "rightSideArray": [
            {
                "side": "right",
                "name": "જમીન",
                "amount": 50000
            },
            {
                "side": "right",
                "name": "મકાન",
                "amount": 70000
            },
            {
                "type": "Outstanding rent",
                "side": "right",
                "name": "અગાઉથી ચૂકવેલ ભાડાt",
                "amount": 3000
            }
        ]
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                partners: ["Harsh", "Dhruvil", "Manoj"],
                capitalAccount: [365000, 300000, 100000],
                initialProfitLossRatio: [3, 2, 1],
                newProfitLossRatio: [1, 3, 2],
                date: "2017-03-31",
                liabilities: [{
                    type: "બેંક લોન",
                    amount: 450000
                }, {
                    type: "લેણદારો",
                    amount: 100000
                }, {
                    type: "દેવીહૂંડી",
                    amount: 32000
                }, {
                    type: "ચૂકવવાના બાકી ખર્ચા",
                    amount: 18000
                }],
                assets: [{
                    type: "જમીન",
                    amount: 300000
                }, {
                    type: "મકાન",
                    amount: 480000
                }, {
                    type: "યંત્રો",
                    amount: 200000
                }, {
                    type: "રોકાણો",
                    amount: 120000
                }, {
                    type: "દેવાદારો",
                    amount: 80000
                }, {
                    type: "લેણીહૂંડી",
                    amount: 20000
                }, {
                    type: "બેન્કસિલક",
                    amount: 100000
                }, {
                    type: "રોકડસિલક",
                    amount: 40000
                }, {
                    type: "મળવાની બાકી આવક",
                    amount: 10000
                }, {
                    type: "આખરસ્ટોક",
                    amount: 15000
                }],
                totalAssetAmount: 1365000,
                totalLiabilityAmount: 1365000,

                questionPallete: [{
                    type: "IncDecV",
                    name: "જમીન",
                    originalAmount: 300000,
                    amount: 350000
                }, {
                    type: "IncDecV",
                    name: "મકાન",
                    originalAmount: 480000,
                    amount: 550000
                }, {
                    type: "IncDecV",
                    name: "યંત્રો",
                    originalAmount: 200000,
                    amount: 150000
                }, {
                    type: "decreaseR",
                    name: "રોકાણો",
                    originalAmount: 120000,
                    amount: "-",
                    percent: 20
                }, {
                    type: "badDebtRdiscountR",
                    name: "દેવાદારો",
                    originalAmount: 80000,
                    badDebtR: 10,
                    discountR: 5
                }, {
                    type: "decreaseR",
                    name: "સ્ટોક",
                    originalAmount: 15000,
                    amount: 50,
                    percent: 10
                }, {
                    type: "unrecordedOutstandingExp",
                    amount: 2000
                }, {
                    type: "unrecordedPrepaidRent",
                    amount: 3000
                }],

                addQuestionType: "none",
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempPayType: '',
                tempReturneeName: '',
                tempAmount: '',
                tempDue: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempDueDate: '',
                tempRebate: false,

                editModepartners: [false, false, false],
                editModecapitalAccount: [false, false, false],
                editModeinitialProfitLossRatio: [false, false, false],
                editModenewProfitLossRatio: [false, false, false],
                editModedate: false,
                editModeliabilities: [{
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }],
                editModeassets: [{
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }],
                editModequestionPallete: [{
                    amount: false
                }, {
                    amount: false
                }, {
                    amount: false
                }, {
                    percent: false
                }, {
                    badDebtR: false,
                    discountR: false
                }, {
                    amount: false,
                    percent: false
                }, {
                    amount: false
                }, {
                    amount: false
                }],

                addQuestionChecker: false,
                submitChecker: true

            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.stringReducer = this.stringReducer.bind(this)
            this.addNameChange = this.addNameChange.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'partners':
                        this.state.partners[customArray[1]] = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'initialPL':
                        this.state.initialProfitLossRatio[customArray[1]] = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'liabilitiesType':
                        this.state.liabilities[customArray[1]].type = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'liabilitiesAmount':
                        this.state.liabilities[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'assetsType':
                        this.state.assets[customArray[1]].type = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'assetsAmount':
                        this.state.assets[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'capitalAccount':
                        this.state.capitalAccount[customArray[1]] = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;

                    case 'IncDecVAmount':
                        this.state.questionPallete[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'decreaseRAmount':
                        this.state.questionPallete[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'decreaseRPercent':
                        this.state.questionPallete[customArray[1]].percent = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'badDebtR':
                        this.state.questionPallete[customArray[1]].badDebtR = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'discountR':
                        this.state.questionPallete[customArray[1]].discountR = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'unrecordedOutstandingExp':
                        this.state.questionPallete[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'unrecordedPrepaidRent':
                        this.state.questionPallete[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'date':
                    this.setState({
                        date: event.target.value
                    }, () => { this.fillDetails() })
                    break;

            }
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'date':
                    this.setState({
                        tempDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'dueDate':
                    this.setState({
                        tempDueDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'name':
                    this.setState({
                        tempName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amount':
                    // console.log(event.target.value)
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'due':
                    this.setState({
                        tempDue: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'discount':
                    this.setState({
                        tempDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Tdiscount':
                    this.setState({
                        tempTDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Cdiscount':
                    this.setState({
                        tempCDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'purchasedFrom':
                    this.setState({
                        tempPurchasedFrom: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'billno':
                    this.setState({
                        tempBillno: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'credit':
                    this.setState({
                        tempCredit: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPercentage':
                    this.setState({
                        tempAmountPercentage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'invoiceNo':
                    this.setState({
                        tempInvoiceNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'debitNoteNo':
                    this.setState({
                        tempDebitNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'creditNoteNo':
                    this.setState({
                        tempCreditNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPaidImmediately':
                    this.setState({
                        tempAmountPaidImmediately: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'memo':
                    this.setState({
                        tempMemo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'assetName':
                    this.setState({
                        tempAssetName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmount':
                    this.setState({
                        tempExtraSum: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmountReason':
                    this.setState({
                        tempExtraSumReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashPurchase':
                    this.setState({
                        tempCashPurchase: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashSales':
                    this.setState({
                        tempCashSales: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            switch (this.state.addQuestionType) {
                case 'changeInValue':
                    if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempName !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'decreaseRatio':
                    if (this.state.tempAmountPercentage !== '' && NumberRegex.test(this.state.tempAmountPercentage) && parseFloat(this.state.tempAmountPercentage) > 0 && parseFloat(this.state.tempAmountPercentage) < 100 &&
                        this.state.tempName !== '') {
                        if (this.state.tempAmount !== '') {
                            if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) && parseFloat(this.state.tempAmount) > 0 && parseFloat(this.state.tempAmount) < 100) {
                                this.setState({
                                    addQuestionChecker: true
                                })
                            } else {
                                this.setState({
                                    addQuestionChecker: false
                                })
                            }
                        } else {
                            this.setState({
                                addQuestionChecker: true
                            })
                        }
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'badDebtRdiscountR':
                    if (this.state.tempName !== '' &&
                        this.state.tempAmountPercentage !== '' && NumberRegex.test(this.state.tempAmountPercentage) && parseFloat(this.state.tempAmountPercentage) > 0 && parseFloat(this.state.tempAmountPercentage) < 100 &&
                        this.state.tempDiscount !== '' && NumberRegex.test(this.state.tempDiscount) && parseFloat(this.state.tempDiscount) > 0 && parseFloat(this.state.tempDiscount) < 100) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'unrecordedOutstandingExp':
                    if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'unrecordedPrepaidRent':
                    if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
            }
        }

        questionTypeChange(event) {
            this.setState({
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempPayType: '',
                tempReturneeName: '',
                tempAmount: '',
                tempDue: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempDueDate: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                addQuestionChecker: false,
                addQuestionType: event.target.value
            })
        }

        addNameChange(event) {
            this.setState({
                tempName: event.target.value
            }, () => { this.addButtonChecker() })
        }

        stringReducer(string) {
            let stringArray = string.split(" ")

            for (let i in stringArray) {
                if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === '') {
                    // console.log(i)
                    stringArray.splice(i, 1)
                }
            }

            if (stringArray[0] === null) {
                stringArray[0] = 0
            }

            this.setState({
                sampleStringLength: stringArray.length,
                sampleStringArray: stringArray
            })

            return string
        }

        fillDetails() {

            //#region TOTAL CALCULATOR START
            let totalAssetAmount = 0

            let totalLiabilityAmount = this.state.capitalAccount.reduce((a, b) => parseFloat(a) + parseFloat(b))

            for (let i of this.state.liabilities) {
                totalLiabilityAmount += parseFloat(i.amount)
            }
            for (let i of this.state.assets) {
                totalAssetAmount += parseFloat(i.amount)
            }

            this.setState({
                totalAssetAmount: totalLiabilityAmount,
                totalLiabilityAmount: totalAssetAmount
            })

            //#endregion TOTAL CALCULATOR END

            let flag = true

            for (let i in this.state.partners) {
                if (this.state.partners[i] === '') {
                    flag = false
                }
            }
            for (let i in this.state.capitalAccount) {
                if (this.state.capitalAccount[i] === '' || !NumberRegex.test(this.state.capitalAccount[i])) {
                    flag = false
                }
            }
            for (let i in this.state.initialProfitLossRatio) {
                if (this.state.initialProfitLossRatio[i] === '' || !NumberRegex.test(this.state.initialProfitLossRatio[i]) || parseFloat(this.state.initialProfitLossRatio[i]) <= 0 || parseFloat(this.state.initialProfitLossRatio[i]) >= 100) {
                    flag = false
                }
            }

            for (let i of this.state.liabilities) {
                if (i.amount === '' || !NumberRegex.test(i.amount)) {
                    flag = false
                }
                if (i.type === '') {
                    flag = false
                }
            }
            for (let i of this.state.assets) {
                if (i.amount === '' || !NumberRegex.test(i.amount)) {
                    flag = false
                }
                if (i.type === '') {
                    flag = false
                }
            }

            for(let i of this.state.questionPallete){
                if(i.amount !== '-' && i.amount !== undefined){
                    if(i.amount === '' || !NumberRegex.test(i.amount)){
                        flag = false
                    }
                }
                if(i.percent){
                    if(i.percent === '' || !NumberRegex.test(i.percent) || parseFloat(i.percent) <= 0 || parseFloat(i.percent) >= 100){
                        flag = false
                    }
                }
                if(i.badDebtR){
                    if(i.badDebtR === '' || !NumberRegex.test(i.badDebtR) || parseFloat(i.badDebtR) <= 0 || parseFloat(i.badDebtR) >= 100){
                        flag = false
                    }
                }
                if(i.discountR){
                    if(i.discountR === '' || !NumberRegex.test(i.discountR) || parseFloat(i.discountR) <= 0 || parseFloat(i.discountR) >= 100){
                        flag = false
                    }
                }
            }

            if(this.state.totalAssetAmount !== this.state.totalLiabilityAmount)

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: false,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: true,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {
            let tempArray = []
            let leftSideArray = []
            let rightSideArray = []

            for (let i of this.state.questionPallete) {
                switch (i.type) {
                    case 'IncDecV':
                        if (i.amount > i.originalAmount) {
                            tempArray.push({
                                side: "right",
                                name: i.name,
                                amount: parseFloat(i.amount) - parseFloat(i.originalAmount)
                            })
                        }
                        if (i.amount <= i.originalAmount) {
                            tempArray.push({
                                side: "left",
                                name: i.name,
                                amount: parseFloat(i.originalAmount) - parseFloat(i.amount)
                            })
                        }
                        break;
                    case 'decreaseR':
                        if (i.type === 'decreaseR') {
                            if (i.amount !== '-') {
                                tempArray.push({
                                    type: "percent",
                                    side: "left",
                                    name: i.name,
                                    amount: (parseFloat(i.originalAmount) * parseFloat(i.amount) / 100) * parseFloat(i.percent) / 100
                                })
                            } else if (i.amount === '-') {
                                tempArray.push({
                                    side: "left",
                                    name: i.name,
                                    amount: parseFloat(i.originalAmount) * parseFloat(i.percent) / 100
                                })
                            }
                        }
                        break;
                    case 'badDebtRdiscountR':
                        tempArray.push({
                            side: "left",
                            name: i.name,
                            originalAmount: i.originalAmount,
                            originalAmountBDDiff: (parseFloat(parseFloat(i.originalAmount) - parseFloat(i.badDebtR) * parseFloat(i.originalAmount) / 100)),
                            badDebtR: i.badDebtR,
                            discountR: i.discountR,
                            badDebt: parseFloat(i.badDebtR) * parseFloat(i.originalAmount) / 100,
                            discount: parseFloat(i.discountR) * (parseFloat(parseFloat(i.originalAmount) - parseFloat(i.badDebtR) * parseFloat(i.originalAmount) / 100)) / 100,
                        })
                        break;
                    case 'unrecordedOutstandingExp':
                        tempArray.push({
                            type: "Outstanding expense",
                            side: "left",
                            name: "Outstanding expense",
                            amount: i.amount
                        })
                        break;
                    case 'unrecordedPrepaidRent':
                        tempArray.push({
                            type: "Outstanding rent",
                            side: "right",
                            name: "Outstanding rent",
                            amount: i.amount
                        })
                        break;
                }
            }

            let rightSideTotalAmount = 0
            for (let i in tempArray) {
                if (tempArray[i].side === "right") {
                    rightSideTotalAmount += parseFloat(tempArray[i].amount)
                }
            }
            let partnersCapital = rightSideTotalAmount
            for (let i in tempArray) {
                if (tempArray[i].side === "left" && !tempArray[i].badDebt) {
                    partnersCapital -= parseFloat(tempArray[i].amount)
                }
                if (tempArray[i].side === "left" && tempArray[i].badDebt) {
                    partnersCapital -= parseFloat(tempArray[i].badDebt)
                    partnersCapital -= parseFloat(tempArray[i].discount)
                }
            }

            let partnersCapitalIndividual = []
            for (let i in this.state.partners) {
                partnersCapitalIndividual.push({
                    name: this.state.partners[i],
                    amount: parseFloat(this.state.initialProfitLossRatio[i]) * partnersCapital / this.state.initialProfitLossRatio.reduce((a, b) => parseFloat(a) + parseFloat(b))
                })
            }
            let countR = 0
            let countL = 0

            for (let i in tempArray) {
                if (tempArray[i].side === "left") {
                    countL++
                    if (tempArray[i].badDebtR === undefined) {
                        leftSideArray.push(tempArray[i])
                    } else if (tempArray[i].badDebtR !== undefined) {
                        leftSideArray.push({
                            name: "Bad debt reserve",
                            amount: tempArray[i].badDebt
                        })
                        leftSideArray.push({
                            name: "Discount reserve on " + tempArray[i].name,
                            amount: tempArray[i].discount
                        })
                    }
                } else {
                    countR++
                    rightSideArray.push(tempArray[i])
                }
            }

            let dataObject = {
                side: countL > countR ? "left" : "right",
                date: this.state.date,
                partnersCapitalIndividual,
                partnersCapital,
                rightSideTotalAmount,
                AnswerPalette: tempArray,
                initialProfitLossRatio: this.state.initialProfitLossRatio,
                leftSideArray,
                rightSideArray
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addPartner() {
            this.state.partners.push("New Name")
            this.state.capitalAccount.push(10000)
            this.state.initialProfitLossRatio.push(1)

            this.state.editModepartners.push(true)
            this.state.editModecapitalAccount.push(true)
            this.state.editModeinitialProfitLossRatio.push(false)

            this.setState({
                editModeButton: true
            })

        }

        removePartner(event) {
            this.state.partners.splice(event, 1)
            this.state.capitalAccount.splice(event, 1)
            this.state.initialProfitLossRatio.splice(event, 1)

            this.state.editModepartners.splice(event, 1)
            this.state.editModecapitalAccount.splice(event, 1)
            this.state.editModeinitialProfitLossRatio.splice(event, 1)

            this.setState({})
        }

        addLiability() {
            this.state.liabilities.push({
                type: "Enter Text Here",
                amount: 10000
            })
            this.state.editModeliabilities.push({
                type: true,
                amount: true
            })
            this.setState({
                editModeButton: true
            }, () => { this.fillDetails() })
        }

        removeLiability(event) {
            this.state.liabilities.splice(event, 1)
            this.state.editModeliabilities.splice(event, 1)
            this.setState({}, () => { this.fillDetails() })
        }

        addAsset() {
            this.state.assets.push({
                type: "Enter Text Here",
                amount: 10000
            })
            this.state.editModeassets.push({
                type: true,
                amount: true
            })
            this.setState({
                editModeButton: true
            }, () => { this.fillDetails() })
        }

        removeAsset(event) {
            this.state.assets.splice(event, 1)
            this.state.editModeassets.splice(event, 1)
            this.setState({}, () => { this.fillDetails() })
        }

        addQuestion() {
            let adderFlag = true

            let temporaryTemplate = {
                questionType: this.state.addQuestionType,
                date: parseFloat(this.state.tempDate),
                assetName: this.state.tempAssetName,
                name: this.state.tempName,
                payType: this.state.tempPayType,
                amount: this.state.tempAmount,
                due: this.state.tempDue,
                amountPercentage: this.state.tempAmountPercentage,
                discount: this.state.tempDiscount,
                tradeDiscount: this.state.tempTDiscount,
                cashDiscount: this.state.tempCDiscount,
                purchasedFrom: this.state.tempPurchasedFrom,
                billno: this.state.tempBillno,
                invoiceNo: this.state.tempInvoiceNo,
                debitNoteNo: this.state.tempDebitNoteNo,
                creditNoteNo: this.state.tempCreditNoteNo,
                credit: this.state.tempCredit,
                memo: this.state.tempMemo,
                extraSum: this.state.tempExtraSum,
                extraSumReason: this.state.tempExtraSumReason,
                cashPurchase: this.state.tempCashPurchase,
                cashSales: this.state.tempCashSales,
                dueDate: this.state.tempDueDate,
                rebate: this.state.tempRebate,
                returneeName: this.state.tempReturneeName,

                amountPaidImmediately: this.state.tempAmountPaidImmediately
            }
            let questionObject
            let editModequestionObject

            switch (temporaryTemplate.questionType) {
                case 'changeInValue':
                    questionObject = {
                        type: "IncDecV",
                        name: this.state.tempName,
                        amount: this.state.tempAmount
                    }

                    editModequestionObject = {
                        amount: false
                    }

                    for (let i of this.state.assets) {
                        if (questionObject.name === i.type) {
                            questionObject.originalAmount = i.amount
                        }
                    }
                    break;
                case 'decreaseRatio':
                    questionObject = {
                        type: "decreaseR",
                        name: this.state.tempName,
                        amount: "-",
                        percent: this.state.tempAmountPercentage
                    }

                    editModequestionObject = {
                        percent: false
                    }

                    if (this.state.tempAmount !== '') {
                        questionObject.amount = this.state.tempAmount
                        editModequestionObject.amount = false
                    }

                    for (let i of this.state.assets) {
                        if (questionObject.name === i.type) {
                            questionObject.originalAmount = i.amount
                        }
                    }

                    break;
                case 'badDebtRdiscountR':
                    questionObject = {
                        type: "badDebtRdiscountR",
                        name: this.state.tempName,
                        badDebtR: this.state.tempAmountPercentage,
                        discountR: this.state.tempDiscount
                    }

                    editModequestionObject = {
                        amount: false,
                        percent: false
                    }

                    for (let i of this.state.assets) {
                        if (questionObject.name === i.type) {
                            questionObject.originalAmount = i.amount
                        }
                    }

                    break;
                case 'unrecordedOutstandingExp':
                    questionObject = {
                        type: "unrecordedOutstandingExp",
                        amount: this.state.tempAmount
                    }
                    editModequestionObject = {
                        amount: false,
                    }
                    break;
                case 'unrecordedPrepaidRent':
                    questionObject = {
                        type: "unrecordedPrepaidRent",
                        amount: this.state.tempAmount
                    }
                    editModequestionObject = {
                        amount: false,
                    }
                    break;
            }

            this.state.questionPallete.push(questionObject)
            this.state.editModequestionPallete.push(editModequestionObject)


            this.setState({})

            // console.log(JSON.stringify(questionObject, null, 4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPallete.splice(event, 1)
            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'partners':
                        this.state.editModepartners[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'initialPL':
                        this.state.editModeinitialProfitLossRatio[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'liabilitiesType':
                        this.state.editModeliabilities[customArray[1]].type = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'liabilitiesAmount':
                        this.state.editModeliabilities[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsType':
                        this.state.editModeassets[customArray[1]].type = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsAmount':
                        console.log(customArray)
                        this.state.editModeassets[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'capitalAccount':
                        this.state.editModecapitalAccount[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;

                    case 'IncDecVAmount':
                        this.state.editModequestionPallete[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'decreaseRAmount':
                        this.state.editModequestionPallete[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'decreaseRPercent':
                        this.state.editModequestionPallete[customArray[1]].percent = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'badDebtR':
                        this.state.editModequestionPallete[customArray[1]].badDebtR = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'discountR':
                        this.state.editModequestionPallete[customArray[1]].discountR = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'unrecordedOutstandingExp':
                        this.state.editModequestionPallete[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'unrecordedPrepaidRent':
                        this.state.editModequestionPallete[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'date':
                    this.state.editModedate = true
                    this.setState({
                        editModeButton: true
                    })
                    break;

            }

        }

        editModeOff() {

            for (let i in this.state.editModepartners) {
                this.state.editModepartners[i] = false
                this.state.editModecapitalAccount[i] = false
                this.state.editModeinitialProfitLossRatio[i] = false
            }

            for (let i of this.state.editModeliabilities) {
                i.type = false
                i.amount = false
            }

            for (let i of this.state.editModeassets) {
                i.type = false
                i.amount = false
            }

            for (let i of this.state.editModequestionPallete) {
                if (i.amount) {
                    i.amount = false
                }
                if (i.badDebtR) {
                    i.badDebtR = false
                }
                if (i.discountR) {
                    i.discountR = false
                }
                if (i.percent) {
                    i.percent = false
                }
            }

            this.setState({
                editModedate: false,
                editModeButton: false
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                    પેઢીના ભાગીદારો: <br /><br />

                        {this.state.partners.map((x, i) => (
                            <div>
                                ({i + 1})&nbsp;
                                {
                                    this.state.editModepartners[i] ?
                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["partners", i]} id='outlined-base' error={x === ''}
                                            value={x} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["partners", i]} onClick={this.buttonMaker.bind(this)}> {x}</a>
                                }

                                {this.state.partners.length > 2 ? <IconButton style={{ width: "3%", color: "red" }} onClick={this.removePartner.bind(this, i)} name="-">-</IconButton> : <span><br /><br /></span>}
                                {i + 1 === this.state.partners.length && <div align="right">
                                    ADD PARTNERS
                                    <IconButton style={{ width: "3%" }} onClick={this.addPartner.bind(this)}>+</IconButton></div>}
                            </div>
                        ))}

                        તેમની વચ્ચેનું નફાનુકસાન વહેંચણીનું પ્રમાણ&nbsp;
                        {this.state.initialProfitLossRatio.map((x, i) => (
                            <span>
                                {
                                    this.state.editModeinitialProfitLossRatio[i] ?
                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["initialPL", i]} id='outlined-base' error={x === '' || isNaN(x)}
                                            value={x} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["initialPL", i]} onClick={this.buttonMaker.bind(this)}> {x}</a>
                                }

                                {parseInt(i) !== this.state.initialProfitLossRatio.length - 1 && " : "}</span>
                        ))} છે.&nbsp;<br /><br />

                        તા.&nbsp;

                        {
                            this.state.editModedate ?
                                <TextField type="date" style={{ 'margin-top': "-15px", width: "15%" }} name="date" id='outlined-base' error={this.state.date === ''}
                                    value={this.state.date} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="date" onClick={this.buttonMaker.bind(this)}> {this.state.date}</a>
                        } ના રોજનું પેઢીનું પાકું સરવૈયું નીચે દર્શાવેલ છે :<br /><br />

                        <div align="center"><b>{this.state.partners.map((x, i) => (<span>{x} {parseInt(i) === this.state.partners.length - 2 ? " અને " : parseInt(i) < this.state.partners.length - 2 && ", "}</span>))}ની&nbsp;
                            ભાગીદારી પેઢીનું તા. {this.state.date} ના રોજનું પાકું સરવૈયું</b></div>


                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>મૂડી-દેવાં (₹)</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ (₹)</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>મિલકત-લેણાં</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ (₹)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell align="left" style={{fontSize: "15px"}}>
                                            <b>મૂડી ખાતાં :</b><br />
                                            {this.state.partners.map((x, i) => (
                                                <span>&emsp;&emsp;{x}
                                                    <span style={{ float: "right" }}>
                                                        {
                                                            this.state.editModecapitalAccount[i] ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["capitalAccount", i]} id='outlined-base' error={this.state.capitalAccount[i] === '' || isNaN(this.state.capitalAccount[i])}
                                                                    value={this.state.capitalAccount[i]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["capitalAccount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.capitalAccount[i]}</a>
                                                        }
                                                    </span><br /></span>
                                            ))}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>{this.state.capitalAccount.reduce((a, b) => { return parseFloat(a) + parseFloat(b) })}</StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                    </TableRow>
                                    {this.state.liabilities.length >= this.state.assets.length ?
                                        this.state.liabilities.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="left" style={{fontSize: "17px"}}>
                                                    {this.state.liabilities[i] !== undefined && <span>
                                                        {
                                                            this.state.editModeliabilities[i].type ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["liabilitiesType", i]} id='outlined-base' error={this.state.liabilities[i].type === ''}
                                                                    value={this.state.liabilities[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["liabilitiesType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].type}</a>
                                                        }&nbsp;
                                                        <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeLiability.bind(this, i)} name="-">-</IconButton>
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {this.state.liabilities[i] !== undefined && <span>
                                                        {
                                                            this.state.editModeliabilities[i].amount ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["liabilitiesAmount", i]} id='outlined-base' error={this.state.liabilities[i].amount === '' || isNaN(this.state.liabilities[i].amount)}
                                                                    value={this.state.liabilities[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["liabilitiesAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].amount}</a>
                                                        }
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{fontSize: "17px"}}>
                                                    {
                                                        this.state.editModeassets[i].type ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["assetsType", i]} id='outlined-base' error={this.state.assets[i].type === ''}
                                                                value={this.state.assets[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["assetsType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].type}</a>
                                                    }&nbsp;
                                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeAsset.bind(this, i)} name="-">-</IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {
                                                        this.state.editModeassets[i].amount ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsAmount", i]} id='outlined-base' error={this.state.assets[i].amount === '' || isNaN(this.state.assets[i].amount)}
                                                                value={this.state.assets[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["assetsAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].amount}</a>
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                        :
                                        this.state.assets.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="left" style={{fontSize: "17px"}}>
                                                    {this.state.liabilities[i] !== undefined && <span>
                                                        {
                                                            this.state.editModeliabilities[i].type ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["liabilitiesType", i]} id='outlined-base' error={this.state.liabilities[i].type === ''}
                                                                    value={this.state.liabilities[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["liabilitiesType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].type}</a>
                                                        }&nbsp;
                                                        <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeLiability.bind(this, i)} name="-">-</IconButton>
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {this.state.liabilities[i] !== undefined && <span>
                                                        {
                                                            this.state.editModeliabilities[i].amount ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["liabilitiesAmount", i]} id='outlined-base' error={this.state.liabilities[i].amount === '' || isNaN(this.state.liabilities[i].amount)}
                                                                    value={this.state.liabilities[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["liabilitiesAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].amount}</a>
                                                        }
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{fontSize: "17px"}}>
                                                    {
                                                        this.state.editModeassets[i].type ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["assetsType", i]} id='outlined-base' error={this.state.assets[i].type === ''}
                                                                value={this.state.assets[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["assetsType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].type}</a>
                                                    }&nbsp;
                                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeAsset.bind(this, i)} name="-">-</IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {
                                                        this.state.editModeassets[i].amount ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsAmount", i]} id='outlined-base' error={this.state.assets[i].amount === '' || isNaN(this.state.assets[i].amount)}
                                                                value={this.state.assets[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["assetsAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].amount}</a>
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                    }
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align='right'><b>{this.state.totalAssetAmount}</b></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align='right'><b>{this.state.totalLiabilityAmount}</b></StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align='center' style={{fontSize: "17px"}}><IconButton style={{ width: "3%", height: "10px" }} onClick={this.addLiability.bind(this)}>+</IconButton> દેવાં ઉમેરો</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align='center' style={{fontSize: "17px"}}><IconButton style={{ width: "3%", height: "10px" }} onClick={this.addAsset.bind(this)}>+</IconButton> મિલકત ઉમેરો</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />

                        &emsp;&emsp;ઉપરોક્ત પાકા સરવૈયાની તારીખે ભાગીદારોએ નફા-નુકસાનની વહેંચણીનું પ્રમાણ બદલી તથા ધંધાની મિલકતો અને દેવાંનું પુન:મૂલ્યાંકન કરવાનું નક્કી કર્યું જેની માહિતી નીચે મુજબ છે :<br /><br />

                        <div label="Questions">
                            {this.state.questionPallete.map((x, i) => (
                                <span>({i + 1})&nbsp;
                                    {x.type === 'IncDecV' &&
                                        <span>
                                            {x.originalAmount < x.amount &&
                                                <span>
                                                    {x.name}ની કિંમત ₹&nbsp;
                                                    {
                                                        this.state.editModequestionPallete[i].amount ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["IncDecVAmount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                                value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["IncDecVAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                                    } સુધી વધારવી.
                                                </span>
                                            }
                                            {x.originalAmount >= x.amount &&
                                                <span>
                                                    {x.name}ની કિંમત ₹&nbsp;
                                                    {
                                                        this.state.editModequestionPallete[i].amount ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["IncDecVAmount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                                value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["IncDecVAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                                    } સુધી રાખવી.
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.type === 'decreaseR' &&
                                        <span>
                                            {x.amount !== '-' &&
                                                <span>
                                                    {
                                                        this.state.editModequestionPallete[i].amount ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["decreaseRAmount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount) || x.amount < 0 || x.amount > 100}
                                                                value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["decreaseRAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                                    } %&nbsp;
                                                </span>
                                            }
                                            {x.name}ની કિંમતમાં&nbsp;
                                            {
                                                this.state.editModequestionPallete[i].percent ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["decreaseRPercent", i]} id='outlined-base' error={x.percent === '' || isNaN(x.percent) || x.percent < 0 || x.percent > 100}
                                                        value={x.percent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["decreaseRPercent", i]} onClick={this.buttonMaker.bind(this)}> {x.percent}</a>
                                            } % ઘટાડો કરવો
                                        </span>
                                    }
                                    {x.type === 'badDebtRdiscountR' &&
                                        <span>
                                            દેવાદારો પર &nbsp;
                                            {
                                                this.state.editModequestionPallete[i].badDebtR ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["badDebtR", i]} id='outlined-base' error={x.badDebtR === '' || isNaN(x.badDebtR) || x.badDebtR < 0 || x.badDebtR > 100}
                                                        value={x.badDebtR} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["badDebtR", i]} onClick={this.buttonMaker.bind(this)}> {x.badDebtR}</a>
                                            } %&nbsp;
                                            ધાલખાધ અનામતની જોગવાઈ અને&nbsp;
                                            {
                                                this.state.editModequestionPallete[i].discountR ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["discountR", i]} id='outlined-base' error={x.discountR === '' || isNaN(x.discountR) || x.discountR < 0 || x.discountR > 100}
                                                        value={x.discountR} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["discountR", i]} onClick={this.buttonMaker.bind(this)}> {x.discountR}</a>
                                            } %&nbsp;
                                            લેખે વટાવ અનામતની જોગવાઈ કરવાની છે.
                                        </span>
                                    }
                                    {x.type === 'unrecordedOutstandingExp' &&
                                        <span>
                                            ચોપડે નિહ નોંધેલ ચૂકવવાના બાકી ખર્ચા ₹&nbsp;
                                            {
                                                this.state.editModequestionPallete[i].amount ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["unrecordedOutstandingExp", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                        value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["unrecordedOutstandingExp", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                            }ના છે.
                                        </span>
                                    }
                                    {x.type === 'unrecordedPrepaidRent' &&
                                        <span>
                                            અગાઉથી ચૂકવેલ ભાડું ₹&nbsp;
                                            {
                                                this.state.editModequestionPallete[i].amount ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["unrecordedPrepaidRent", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                        value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["unrecordedPrepaidRent", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                            } ચોપડે નોંધવાનું બાકી છે.
                                        </span>
                                    }

                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeQuestion.bind(this, i)} name="-">-</IconButton>

                                    <br /><br />
                                </span>
                            ))}
                        </div>


                        <br /><br />
                        <div label="addQuestionInputs">
                            નવો પ્રશ્ન ઉમેરો :<br /><br /><br />
                            પ્રશ્નનો પ્રકાર: 
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">પ્રશ્ન</InputLabel>
                                <Select
                                    labelId="question"
                                    id="question"
                                    value={this.state.addQuestionType}
                                    label="Question"
                                    onChange={this.questionTypeChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"none"}>None</MenuItem>
                                    <MenuItem value={"changeInValue"}>મિલકતમાં વધારો/ઘટાડો</MenuItem>
                                    <MenuItem value={"decreaseRatio"}>અસ્કયામતોમાં ઘટાડો રેશિયો</MenuItem>
                                    <MenuItem value={"badDebtRdiscountR"}>ઘાલખાધ અનામત / દેવાદાર પર વટાવ અનામત</MenuItem>
                                    <MenuItem value={"unrecordedOutstandingExp"}>ચૂકવવાના બાકી ખર્ચ</MenuItem>
                                    <MenuItem value={"unrecordedPrepaidRent"}>અગાઉથી ચૂકવેલ ભાડુ</MenuItem>


                                    {/* <MenuItem value={"none"}>None</MenuItem>
                                    <MenuItem value={"tradeDiscount"}>Purchase</MenuItem>
                                    <MenuItem value={"sales"}>Sales</MenuItem>
                                    <MenuItem value={"pending"}>Pending Payment</MenuItem>
                                    <MenuItem value={"duePayment"}>Due Payment</MenuItem>
                                    <MenuItem value={"toBePaid"}>Payment to be paid</MenuItem> */}
                                    {/* <MenuItem value={"cashDiscount"}>Purchase - Cash Discount</MenuItem>
                                    <MenuItem value={"memo"}>Purchase - Cash Memo</MenuItem>
                                    <MenuItem value={"fixedAsset"}>Purchase - Fixed Assets</MenuItem>
                                    <MenuItem value={"nodiscount"}>Purchase - No Discount</MenuItem>
                                    <MenuItem value={"sales"}>Sales</MenuItem>
                                    <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem>
                                    <MenuItem value={"salesReturns"}>Sales Returns</MenuItem>
                                    <MenuItem value={"order"}>Pre-Order Placement</MenuItem>
                                    <MenuItem value={"cashPurchaseSales"}>Cash Purchase/Sales</MenuItem>                                    <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                </Select>
                            </FormControl><br /><br /><br />


                            {this.state.addQuestionType === 'changeInValue' &&
                                <span>
                                    બદલાયેલ રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    મિલકતનું નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.assets.map((y, j) => (
                                                <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'decreaseRatio' &&
                                <span>
                                    સ્ટોકની બદલાયેલ ટકાવારી દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={isNaN(this.state.tempAmount) || this.state.tempAmount < 0 || this.state.tempAmount > 100}
                                        placeholder="રકમ દાખલ કરો" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    બદલાયેલ રકમની ટકાવારી દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPercentage" error={this.state.tempAmountPercentage === '' || isNaN(this.state.tempAmountPercentage) || this.state.tempAmountPercentage < 0 || this.state.tempAmountPercentage > 100}
                                        placeholder="રકમની ટકાવારી દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    મિલકતનું નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.assets.map((y, j) => (
                                                <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'badDebtRdiscountR' &&
                                <span>
                                    બેડ ડેટ રિઝર્વ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPercentage" error={this.state.tempAmountPercentage === '' || isNaN(this.state.tempAmountPercentage) || this.state.tempAmountPercentage < 0 || this.state.tempAmountPercentage > 100}
                                        placeholder="રકમની ટકાવારી દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ડિસ્કાઉન્ટ રિઝર્વ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="discount" error={this.state.tempDiscount === '' || isNaN(this.state.tempDiscount) || this.state.tempDiscount < 0 || this.state.tempDiscount > 100}
                                        placeholder="ડિસ્કાઉન્ટ ટકાવારી દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    મિલકતનું નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.assets.map((y, j) => (
                                                <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'unrecordedOutstandingExp' &&
                                <span>
                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'unrecordedPrepaidRent' &&
                                <span>
                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }

                            {this.state.addQuestionType !== 'none' && <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%", fontSize:"20px" }} onClick={this.addQuestion.bind(this)}>નવો પ્રશ્ન ઉમેરો</AddButton>}
                        </div>

                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px", fontSize:"20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >સંપાદિત મોડ બંધ કરો </ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો/છુપાવો</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>જવાબ :</b><br />
                            <div align="center"><b>
                                {data[chartState.selected].partnersCapitalIndividual.map((x, i) => (
                                    <span>
                                        {x.name} {parseInt(i) === data[chartState.selected].partnersCapitalIndividual.length - 2 ? " અને " : parseInt(i) < data[chartState.selected].partnersCapitalIndividual.length - 2 && ", "}
                                    </span>
                                ))}ની પેઢીના ચોપડામાં આમનોંધ
                            </b></div>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>ઉધાર (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>જમા<br/> (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].AnswerPalette.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center" style={{ verticalAlign: "top" }}>({i + 1})</StyledTableCell>
                                                <StyledTableCell align="left" style={{fontSize: "17px"}}>
                                                    {x.side === 'right' &&
                                                        <span>
                                                            {x.name} ખાતે<br /> <span style={{ float: "right" }}>ઉ</span>
                                                            &emsp;&emsp;તે પુન:મૂલ્યાંકન ખાતે<br />

                                                            {x.type &&
                                                                <span>
                                                                    [બા.જે. : ચોપડે નહિ નોંધેલ અગાઉથી ચૂકવેલ ભાડાની નોંધ કરી તેના.]
                                                                </span>
                                                            }
                                                            {!x.type &&
                                                                <span>
                                                                    [બા.જે. : {x.name}ની કિંમતમાં પુન:મૂલ્યાંકનને લીધે થયેલ વધારો નોંધ્યો તેના.]
                                                                </span>
                                                            }
                                                        </span>
                                                    }
                                                    {x.side === 'left' &&
                                                        <span>
                                                            {!x.badDebt &&
                                                                <span>
                                                                    પુન:મૂલ્યાંકન ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                                    &emsp;&emsp;તે {x.name} ખાતે<br />

                                                                    {x.type === 'Outstanding expense' &&
                                                                        <span>
                                                                            [બા.જે. : ચોપડે નહિ નોંધેલ ચૂકવવાના બાકી ખર્ચની નોંધ કરી તેના.]
                                                                        </span>
                                                                    }
                                                                    {x.type === 'percent' &&
                                                                        <span>
                                                                            [બા.જે. : {x.name}ની કિંમતમાં પુનઃમૂલ્યાંક્તને લીધે થયેલ ઘટાડો નોંધ્યો તેના.]
                                                                        </span>
                                                                    }
                                                                    {!x.type &&
                                                                        <span>
                                                                            [બા.જે. : {x.name}ની કિંમતમાં પુન:મૂલ્યાંકનને લીધે થયેલ ઘટાડો નોંધ્યો તેના.]
                                                                        </span>
                                                                    }
                                                                </span>
                                                            }
                                                            {x.badDebt &&
                                                                <span>
                                                                    પુન:મૂલ્યાંકન ખાતે<span style={{ float: "right" }}>ઉ</span><br />
                                                                    &emsp;&emsp;તે ઘાલખાધ અનામત ખાતે <br />
                                                                    &emsp;&emsp;તે {x.name} પર વટાવ અનામત ખાતે<br />
                                                                    [બા.જે. : ₹ {x.originalAmount}ના {x.name} પર {x.badDebtR} % લેખે ₹ {x.badDebt}ની ઘાલખાધ અનામત અને ₹ {x.originalAmountBDDiff} (₹ {x.originalAmount} - ₹ {x.badDebt}) પર {x.discountR} % લેખે ₹ {x.discount} ની વટાવ અનામતની નોંધ કરી તેના
                                                                    .]

                                                                </span>
                                                            }
                                                        </span>

                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                    {!x.badDebt &&
                                                        <span>
                                                            {x.amount}
                                                        </span>
                                                    }
                                                    {x.badDebt &&
                                                        <span>
                                                            {x.badDebt + x.discount}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                    {!x.badDebt &&
                                                        <span>
                                                            <br />
                                                            {x.amount}
                                                        </span>
                                                    }
                                                    {x.badDebt &&
                                                        <span>
                                                            <br />
                                                            {x.badDebt}<br />
                                                            {x.discount}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>({data[chartState.selected].AnswerPalette.length + 1})</StyledTableCell>
                                            <StyledTableCell style={{fontSize: "17px"}}>
                                                પુન:મૂલ્યાંકન ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                {data[chartState.selected].partnersCapitalIndividual.map((x, i) => (
                                                    <span>&emsp;&emsp;તે {x.name}ના મૂડી ખાતે <br /></span>
                                                ))}
                                                [બા.જે. : પુન:મૂલ્યાંકનને લીધે થયેલ નફાની વહેંચણી ભાગીદારો વચ્ચે તેમના જૂના નફા-નુકસાનની વહેંચણીના પ્રમાણમાં&nbsp;
                                                ({data[chartState.selected].initialProfitLossRatio.map((x, i) => (
                                                    <span>
                                                        {x}{i !== data[chartState.selected].initialProfitLossRatio.length - 1 && ":"}
                                                    </span>
                                                ))}) કરી તેના.]
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].partnersCapital}
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].partnersCapitalIndividual.map((x, i) => (
                                                    <span>{x.amount}<br /></span>
                                                ))}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align="right" style={{fontSize: "17px"}}><b>ફુલ સરવાળો</b></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].rightSideTotalAmount * 2}</b></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].rightSideTotalAmount * 2}</b></StyledTableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align="center"><b>તા. {data[chartState.selected].date} ના રોજનું પુન:મૂલ્યાંકનનું ખાતું (નફા-નુકસાન હવાલા ખાતું )</b></div><br />
                            <span style={{ float: "left" }}>ઉધાર</span><span style={{ float: "right" }}>જમા</span><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>૨કમ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>૨કમ (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].side === 'left' &&
                                            data[chartState.selected].leftSideArray.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell align="left" style={{fontSize: "17px"}}>{data[chartState.selected].leftSideArray[i].name} ખાતે</StyledTableCell>
                                                    <StyledTableCell align="right">{data[chartState.selected].leftSideArray[i].amount}</StyledTableCell>
                                                    <StyledTableCell align="left" style={{fontSize: "17px"}}>
                                                        {
                                                            data[chartState.selected].rightSideArray[i] &&
                                                            "" + data[chartState.selected].rightSideArray[i].name +
                                                             " ખાતે"}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {
                                                            data[chartState.selected].rightSideArray[i] &&
                                                            data[chartState.selected].rightSideArray[i].amount
                                                        }
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))}
                                        {data[chartState.selected].side === 'right' &&
                                            data[chartState.selected].leftSideArray.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell align="left">
                                                        {
                                                            data[chartState.selected].leftSideArray[i] &&
                                                            "To " + data[chartState.selected].leftSideArray[i].name
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {
                                                            data[chartState.selected].leftSideArray[i] &&
                                                            "To " + data[chartState.selected].leftSideArray[i].amount
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                     {data[chartState.selected].rightSideArray[i].name}&nbsp;ખાતે
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {data[chartState.selected].rightSideArray[i].amount}
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))}
                                        <TableRow>
                                            <StyledTableCell style={{fontSize: "17px"}}>
                                                નફો: ભાગીદારોનાં મૂડી ખાતે :<br />
                                                {data[chartState.selected].partnersCapitalIndividual.map((x, i) => (
                                                    <span>&emsp;&emsp;{x.name} <span style={{ float: "right" }}>{x.amount}</span><br /></span>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align='right'>
                                                <br />
                                                {data[chartState.selected].partnersCapitalIndividual.map((x, i) => (
                                                    <span><br /></span>
                                                ))}
                                                {data[chartState.selected].partnersCapital}
                                            </StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].rightSideTotalAmount}</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].rightSideTotalAmount}</b></StyledTableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

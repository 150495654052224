import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, ColorButton, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import TextField from '@mui/material/TextField';


export default function creditorsTurnover() {

    const data = [{
        totalPurchase: 3500000,
        cashPurchase: 300000,
        purchaseReturn: 200000,
        openingTradePayables: 540000,
        closingTradePayables: 460000,
        creditPurchaseA: 3300000,
        creditPurchaseB: 3000000,
        averageTradePayables: 500000,
        creditorsTurnover: 6,
        paymentPeriodDays: 61,
    }]

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }
    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                companyName: "'A. A.' Company Ltd.",
                totalPurchase: 3500000,
                cashPurchase: 300000,
                purchaseReturn: 200000,
                openingTradePayables: 540000,
                closingTradePayables: 460000,
                creditPurchaseA: 3300000,
                creditPurchaseB: 3000000,
                averageTradePayables: 500000,
                creditorsTurnover: 6,
                paymentPeriodDays: 61,

                editModeTP: false,
                editModeCP: false,
                editModePR: false,
                editModeOTP: false,
                editModeCTP: false,
                editModeName: false,
                editModeButton: false,

                solutionShow: false,
                tableModeChartModeSwitch: true,
                submitChecker: true

            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //EVENT FUNCTIONALITIES START

        eventHandler(event) {
            switch (event.target.name) {
                case 'companyName':
                    this.setState({
                        companyName: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'totalPurchase':
                    this.setState({
                        totalPurchase: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'cashPurchase':
                    this.setState({
                        cashPurchase: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'purchaseReturn':
                    this.setState({
                        purchaseReturn: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'openingTradePayables':
                    this.setState({
                        openingTradePayables: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'closingTradePayables':
                    this.setState({
                        closingTradePayables: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                default:
                    this.setState({})
                    break;
            }
        }

        fillDetails() {
            let count = 0
            if (NumberRegex.test(this.state.totalPurchase) && this.state.totalPurchase != '') {
                count += 1
            }
            if (NumberRegex.test(this.state.cashPurchase) && this.state.cashPurchase != '') {
                count += 1
            }
            if (NumberRegex.test(this.state.purchaseReturn) && this.state.purchaseReturn != '') {
                count += 1
            }
            if (NumberRegex.test(this.state.openingTradePayables) && this.state.totalPuropeningTradePayableschase != '') {
                count += 1
            }
            if (NumberRegex.test(this.state.closingTradePayables) && this.state.closingTradePayables != '') {
                count += 1
            }
            if (count === 5) {
                this.setState({
                    submitChecker: true
                })
            } else {
                this.setState({
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //EVENT FUNCTIONALTIES END

        // BUTTON PANEL START

        onSubmit() {
            let creditPurchaseA = parseFloat(this.state.totalPurchase) - parseFloat(this.state.purchaseReturn)
            let creditPurchaseB = creditPurchaseA - parseFloat(this.state.cashPurchase)
            let averageTradePayables = parseFloat((parseFloat(this.state.openingTradePayables) + parseFloat(this.state.closingTradePayables)) / 2)
            let creditorsTurnover = Math.round(parseFloat(creditPurchaseB / averageTradePayables)*100)/100
            let paymentPeriodDays = Math.round(parseFloat(365 / creditorsTurnover)*100)/100

            data.push({
                totalPurchase: this.state.totalPurchase,
                cashPurchase: this.state.cashPurchase,
                purchaseReturn: this.state.purchaseReturn,
                openingTradePayables: this.state.openingTradePayables,
                closingTradePayables: this.state.closingTradePayables,
                creditPurchaseA: creditPurchaseA,
                creditPurchaseB: creditPurchaseB,
                averageTradePayables: averageTradePayables,
                creditorsTurnover: creditorsTurnover,
                paymentPeriodDays: paymentPeriodDays,
            })

            chartState.selected = data.length - 1

            this.setState({})
        }

        onReset() {
            window.location.reload()
        }

        switchTableToChartAndBack() {
            this.setState({
                tableModeChartModeSwitch: !this.state.tableModeChartModeSwitch
            })
        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event){
            if(event.target.name === 'Left'){
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if(event.target.name === 'Right'){
                chartState.selected += 1
                this.setState({})
            }
        }

        //BUTTON PANEL END


        // EDIT BUTTON START

        buttonMaker(event) {
            switch (event.target.name) {
                case 'companyName':
                    this.setState({
                        editModeName: true,
                        editModeButton: true
                    })
                    break;
                case 'totalPurchase':
                    this.setState({
                        editModeTP: true,
                        editModeButton: true
                    })
                    break;
                case 'cashPurchase':
                    this.setState({
                        editModeCP: true,
                        editModeButton: true
                    })
                    break;
                case 'purchaseReturn':
                    this.setState({
                        editModePR: true,
                        editModeButton: true
                    })
                    break;
                case 'openingTradePayables':
                    this.setState({
                        editModeOTP: true,
                        editModeButton: true
                    })
                    break;
                case 'closingTradePayables':
                    this.setState({
                        editModeCTP: true,
                        editModeButton: true
                    })
                    break;
            }
        }

        editModeOff() {
            this.setState({
                editModeTP: false,
                editModeCP: false,
                editModePR: false,
                editModeOTP: false,
                editModeCTP: false,
                editModeName: false,
                editModeButton: false
            })
        }

        // EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        <b>પ્રશ્ન :</b><br /><br />  <span>
                            {
                                this.state.editModeName ?
                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name='companyName' id='outlined-base' error={this.state.companyName === ''}
                                        value={this.state.companyName} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name="companyName" onClick={this.buttonMaker.bind(this)}> {this.state.companyName}</a>
                            }
                        </span> નીચે આપેલ વિગતોના આધારે લેણદારોનો ઉથલો અને ચૂકવણીની મુદ્દત દિવસોમાં શોધો.<br /> <br />
                        <div label="TableContents">
                            <div>
                                <TableContainer>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">વિગત </StyledTableCell>
                                                <StyledTableCell align="center">રકમ(₹)</StyledTableCell>
                                                <StyledTableCell align="center">વિગત </StyledTableCell>
                                                <StyledTableCell align="center">રકમ(₹)</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow key='1'>
                                                <StyledTableCell align="center">કુલ ખરીદી </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.editModeTP ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='totalPurchase' id='outlined-base' error={this.state.totalPurchase === '' || isNaN(this.state.totalPurchase)}
                                                        value={this.state.totalPurchase} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="totalPurchase" onClick={this.buttonMaker.bind(this)}> {this.state.totalPurchase}</a>}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">રોકડ ખરીદી </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.editModeCP ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='cashPurchase' id='outlined-base' error={this.state.cashPurchase === '' || isNaN(this.state.cashPurchase)}
                                                        value={this.state.cashPurchase} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="cashPurchase" onClick={this.buttonMaker.bind(this)}> {this.state.cashPurchase}</a>}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key='2'>
                                                <StyledTableCell align="center">ખરીદ પરત </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.editModePR ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='purchaseReturn' id='outlined-base' error={this.state.purchaseReturn === '' || isNaN(this.state.purchaseReturn)}
                                                        value={this.state.purchaseReturn} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="purchaseReturn" onClick={this.buttonMaker.bind(this)}> {this.state.purchaseReturn}</a>}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">શરૂના વેપારી દેવાં </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.editModeOTP ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='openingTradePayables' id='outlined-base' error={this.state.openingTradePayables === '' || isNaN(this.state.openingTradePayables)}
                                                        value={this.state.openingTradePayables} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="openingTradePayables" onClick={this.buttonMaker.bind(this)}> {this.state.openingTradePayables}</a>}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key='3'>
                                                <StyledTableCell align="center">આખરના વેપારી દેવાં </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.editModeCTP ? <TextField style={{ "width": "50%", "padding": "-10px" }} name='closingTradePayables' id='outlined-base' error={this.state.closingTradePayables === '' || isNaN(this.state.closingTradePayables)}
                                                        value={this.state.closingTradePayables} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name="closingTradePayables" onClick={this.buttonMaker.bind(this)}> {this.state.closingTradePayables}</a>}
                                                </StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <br /><br />
                            {this.state.editModeButton ? <ColorButton style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)} disabled={this.state.totalPurchase === '' || this.state.cashPurchase === '' || this.state.purchaseReturn === '' || this.state.openingTradePayables === '' || this.state.closingTradePayables === ''}>Edit Mode Off</ColorButton> : <span></span>}
                        </div>


                    </div>
                    <div align="left" label="buttons">
                        <br />
                        <br />
                        <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                        <AddButton style={{ width: "15%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        <AddButton style={{ width: "12%", fontSize:"20px" }} onClick={this.switchTableToChartAndBack.bind(this)}>ટેબલ / ચાર્ટ</AddButton>
                    </div>
                    <div align='right'>
                        <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {chartState.selected + 1}
                        &nbsp;&nbsp;&nbsp;
                        <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                    </div>
                    <br />
                    <br />
                    {this.state.solutionShow ? <div label="solution">
                        {this.state.tableModeChartModeSwitch ?
                            <div label="chart" align="left">
                                <h2>જવાબ  :</h2>
                                <div label="chart1">
                                    <b>(A) લેણદારોનો  ઉથલો  :</b> ( <sup>ઉધાર ખરીદી </sup>/<sub>સરેરાશ વેપારી દેવાં</sub> )<br />
                                    &emsp;&emsp;┃ <br />
                                    &emsp;&emsp;┃&emsp;&emsp;<b>ઉધાર ખરીદી  :</b>&emsp;&emsp;&emsp;&emsp;&emsp;(₹)<br />
                                    &emsp;&emsp;┃&emsp;&emsp;&emsp;┃&emsp;&emsp;&nbsp;&nbsp;કુલ ખરીદી  &emsp;&emsp;&nbsp;&nbsp;{data[chartState.selected].totalPurchase}<br />
                                    &emsp;&emsp;┃&emsp;&emsp;&emsp;┃&emsp;&emsp;- ખરીદ પરત  &emsp;- {data[chartState.selected].purchaseReturn} <br />
                                    &emsp;&emsp;┃&emsp;&emsp;&emsp;┃&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;──────────────   <br />
                                    &emsp;&emsp;┃&emsp;&emsp;&emsp;┃&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;{data[chartState.selected].creditPurchaseA}<br />
                                    &emsp;&emsp;┃&emsp;&emsp;&emsp;┃&emsp;&emsp;- રોકડ ખરીદી  &emsp;&emsp;- {data[chartState.selected].cashPurchase}  <br />
                                    &emsp;&emsp;┃&emsp;&emsp;&emsp;┃&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;────────────── <br />
                                    &emsp;&emsp;┃&emsp;&emsp;&emsp;&nbsp;━━━━━━━━━━━━━━►&emsp;&emsp;&nbsp;&nbsp;&nbsp;{data[chartState.selected].creditPurchaseB} <br />
                                    &emsp;&emsp;┃&emsp;&emsp;<br />
                                    &emsp;&emsp;┃&emsp;&emsp;<b>સરેરાશ વેપારી દેવાં </b> = ( <sup>શરૂના વેપારી દેવાં  + આખરના વેપારી દેવાં </sup>/<sub>2</sub> )<br />
                                    &emsp;&emsp;┃&emsp;&emsp;&emsp;┃&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;= ( <sup>{data[chartState.selected].openingTradePayables} + {data[chartState.selected].closingTradePayables}</sup>/<sub>2</sub> )<br />
                                    &emsp;&emsp;┃&emsp;&emsp;&emsp;&nbsp;━━━━━━━━━━━━━━━►&nbsp; = ₹ {data[chartState.selected].averageTradePayables}<br />
                                    &emsp;&emsp;┃&emsp;&emsp;&emsp;<br />
                                    &emsp;&emsp;&nbsp;━━━━━► ( <sup>{data[chartState.selected].creditPurchaseB}</sup>/<sub>{data[chartState.selected].averageTradePayables}</sub> ) = {data[chartState.selected].creditorsTurnover} વખત <br />
                                </div>
                                <div label="chart2">
                                    <b>(B) ચુકવણી મુદત દિવસોમાં  :</b> = ( <sup>365</sup>/<sub>લેણદારોનો  ઉથલો </sub> ) <br />
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp; = ( <sup>365</sup>/<sub>{data[chartState.selected].creditorsTurnover}</sub> ) <br />
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp; = {data[chartState.selected].paymentPeriodDays} દિવસો 
                                </div>
                            </div>


                            :
                            <div label="table">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">કુલ ખરીદી &nbsp;(₹)</StyledTableCell>
                                                <StyledTableCell align="center">રોકડ ખરીદી &nbsp;(₹)</StyledTableCell>
                                                <StyledTableCell align="center">ખરીદ પરત &nbsp;(₹)</StyledTableCell>
                                                <StyledTableCell align="center">શરૂના વેપારી દેવાં &nbsp;(₹)</StyledTableCell>
                                                <StyledTableCell align="center">આખરના વેપારી દેવાં &nbsp;(₹)</StyledTableCell>
                                                <StyledTableCell align="center">ઉધાર ખરીદી (₹) = <br />&nbsp;<br />(કુલ ખરીદી  - ખરીદ પરત  - રોકડ ખરીદી )</StyledTableCell>
                                                <StyledTableCell align="center">સરેરાશ વેપારી દેવાં (₹) =<br />&nbsp;(₹)<br />(<sup>શરૂના વેપારી દેવાં  + આખરના વેપારી દેવાં </sup>/<sub>2</sub>)</StyledTableCell>
                                                <StyledTableCell align="center">લેણદારોનો  ઉથલો  =<br />&nbsp;(<sup>ઉધાર ખરીદી </sup><sub>સરેરાશ વેપારી દેવાં </sub>)</StyledTableCell>
                                                <StyledTableCell align="center">ચુકવણી મુદત દિવસોમાં  = <br />&nbsp;(<sup>365</sup>/<sub>લેણદારોનો  ઉથલો </sub>)<br /></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {data.map((x, i) => ( */}
                                            <StyledTableRow key="1">
                                                <StyledTableCell align="center">{data[chartState.selected].totalPurchase}</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].cashPurchase}</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].purchaseReturn}</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].openingTradePayables}</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].closingTradePayables}</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].creditPurchaseB}</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].averageTradePayables}</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].creditorsTurnover} વખત </StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].paymentPeriodDays} દિવસો </StyledTableCell>
                                            </StyledTableRow>
                                            {/* ))} */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>}
                    </div> :
                        <div></div>}
                    <br />
                    <br />
                </div>
            )
        }
    }



    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

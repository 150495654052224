import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {

    const data = [{
        "firstName": "Ami",
        "secondName": "Rami",
        "soldGoodsAmount": 25000,
        "soldGoodsDate": "2016-07-01",
        "billDrawDate": "2016-07-03",
        "billDrawDayLimit": 20,
        "finalDateForDraw": "2016-07-23"
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    // const [value, onChange] = useState(new Date());
    // const [value2, onChange2] = useState(new Date());

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                firstName: "Ami",
                secondName: "Rami",
                soldGoodsAmount: 25000,
                soldGoodsDate: "2016-07-01",
                billDrawDate: "2016-07-03",
                billDrawDayLimit: 20,


                editModefirstName: false,
                editModesecondName: false,
                editModesoldGoodsAmount: false,
                editModesoldGoodsDate: false,
                editModebillDrawDate: false,
                editModebillDrawDayLimit: false,

                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            switch (event.target.name) {
                case 'firstName':
                    this.setState({
                        firstName: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'secondName':
                    this.setState({
                        secondName: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'soldGoodsAmount':
                    this.setState({
                        soldGoodsAmount: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'soldGoodsDate':
                    this.setState({
                        soldGoodsDate: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'billDrawDate':
                    this.setState({
                        billDrawDate: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'billDrawDayLimit':
                    this.setState({
                        billDrawDayLimit: event.target.value
                    }, () => { this.fillDetails() })
                    break;
            }
        }

        monthChange(event) {
            this.setState({
                endingMonth: event.target.value
            })
        }

        fillDetails() {
            let flag = true

            if (this.state.firstName === '') {
                flag = false
            }
            if (this.state.secondName === '') {
                flag = false
            }
            if (this.state.soldGoodsAmount === '' || !NumberRegex.test(this.state.soldGoodsAmount)) {
                flag = false
            }
            if (this.state.billDrawDayLimit === '' || !NumberRegex.test(this.state.billDrawDayLimit)) {
                flag = false
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            // console.log(this.state.billDrawDayLimit)

            let date = new Date(this.state.billDrawDate)
            date.setDate(date.getDate() + parseFloat(this.state.billDrawDayLimit));

            let finalDate = ''
            let finalMonth = ''

            let newDate = date.getDate()
            let newMonth = 1 + date.getMonth()

            if (newDate < 10) {
                finalDate = '0' + newDate
            } else {
                finalDate = newDate
            }
            if (newMonth < 10) {
                finalMonth = '0' + newMonth
            } else {
                finalMonth = newMonth
            }

            // console.log(finalDate)

            // let newDate = date.addDays(20)

            let dataObject = {
                firstName: this.state.firstName,
                secondName: this.state.secondName,
                soldGoodsAmount: this.state.soldGoodsAmount,
                soldGoodsDate: this.state.soldGoodsDate,
                billDrawDate: this.state.billDrawDate,
                billDrawDayLimit: this.state.billDrawDayLimit,

                finalDateForDraw: date.getFullYear() + "-" + finalMonth + "-" + finalDate
                // date,
                // newDate
            }

            // data.push(JSON.parse(JSON.stringify(dataObject)))

            // chartState.selected += 1

            this.setState({})

            console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            switch (event.target.name) {
                case 'firstName':
                    this.setState({
                        editModefirstName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'secondName':
                    this.setState({
                        editModesecondName: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'soldGoodsAmount':
                    this.setState({
                        editModesoldGoodsAmount: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'soldGoodsDate':
                    this.setState({
                        editModesoldGoodsDate: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'billDrawDate':
                    this.setState({
                        editModebillDrawDate: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
                case 'billDrawDayLimit':
                    this.setState({
                        editModebillDrawDayLimit: true,
                        editModeButton: true,
                        submitChecker: false
                    })
                    break;
            }
        }

        editModeOff() {
            this.setState({
                editModefirstName: false,
                editModesecondName: false,
                editModesoldGoodsAmount: false,
                editModesoldGoodsDate: false,
                editModebillDrawDate: false,
                editModebillDrawDayLimit: false,

                submitChecker: true,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >

                        {/* {this.state.editModecompanyName ? <TextField style={{ 'margin-top': "-15px", }} name="companyName" id='outlined-base' error={this.state.companyName === ''}
                            value={this.state.companyName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="companyName" onClick={this.buttonMaker.bind(this)}> {this.state.companyName}</a>}&nbsp; */}

                        {this.state.editModefirstName ? <TextField style={{ 'margin-top': "-15px", }} name="firstName" id='outlined-base' error={this.state.firstName === ''}
                            value={this.state.firstName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="firstName" onClick={this.buttonMaker.bind(this)}> {this.state.firstName}</a>}&nbsp;
                        
                        એ તા.&nbsp;
                        {this.state.editModesoldGoodsDate ? <TextField type="date" style={{ 'margin-top': "-15px", }} name="soldGoodsDate" id='outlined-base' error={this.state.soldGoodsDate === ''}
                            value={this.state.soldGoodsDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="soldGoodsDate" onClick={this.buttonMaker.bind(this)}> {this.state.soldGoodsDate}</a>}&nbsp;   
                        
                        ના રોજ&nbsp;
                        {this.state.editModesecondName ? <TextField style={{ 'margin-top': "-15px", }} name="secondName" id='outlined-base' error={this.state.secondName === ''}
                            value={this.state.secondName} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="secondName" onClick={this.buttonMaker.bind(this)}> {this.state.secondName}</a>}&nbsp;

                        ને ₹&nbsp;
                        {this.state.editModesoldGoodsAmount ? <TextField style={{ 'margin-top': "-15px", }} name="soldGoodsAmount" id='outlined-base' error={this.state.soldGoodsAmount === '' || isNaN(this.state.soldGoodsAmount)}
                            value={this.state.soldGoodsAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="soldGoodsAmount" onClick={this.buttonMaker.bind(this)}> {this.state.soldGoodsAmount}</a>} નો માલ વેચ્યો.<br /><br />

                        તા.&nbsp;
                        {this.state.editModebillDrawDate ? <TextField type="date" style={{ 'margin-top': "-15px", }} name="billDrawDate" id='outlined-base' error={this.state.billDrawDate === ''}
                            value={this.state.billDrawDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="billDrawDate" onClick={this.buttonMaker.bind(this)}> {this.state.billDrawDate}</a>} ના રોજ&nbsp;

                        {this.state.firstName} એ {this.state.secondName} પર ₹&nbsp;

                        {this.state.soldGoodsAmount} ની&nbsp;
                        {this.state.editModebillDrawDayLimit ? <TextField style={{ 'margin-top': "-15px", }} name="billDrawDayLimit" id='outlined-base' error={this.state.billDrawDayLimit === '' || isNaN(this.state.billDrawDayLimit)}
                            value={this.state.billDrawDayLimit} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                            <a className='txt' name="billDrawDayLimit" onClick={this.buttonMaker.bind(this)}> {this.state.billDrawDayLimit}</a>}&nbsp;

                        દિવસની મુદતની હૂંડી લખી, જે {this.state.secondName} એ સ્વીકારીને પરત કરી.<br /><br /> પાકતી તારીખે {this.state.secondName} એ હૂંડીની રકમ ચૂકવી આપી. બંને પક્ષકારોના ચોપડે જરૂરી આમનોંધ લખો.

                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px", fontSize:"15px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >સંપાદિત મોડ બંધ કરો</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>જવાબ :</b>
                            <div align='center'><b>{data[chartState.selected].firstName} ના ચોપડામાં આમનોંધ</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>ઉધાર (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>જમા (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].soldGoodsDate}
                                            </StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize:"17px"}}>
                                                {data[chartState.selected].secondName} ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                &emsp;&emsp;તે વેચાણ ખાતે<br />
                                                (બા.જે. ₹ {data[chartState.selected].soldGoodsAmount} નો માલ {data[chartState.selected].secondName} ને ઉધાર વેચ્યો તેના.)
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}><br />{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].billDrawDate}
                                            </StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize:"17px"}}>
                                                લેણી હૂંડી ખાતે<span style={{ float: "right" }}>ઉ</span><br />
                                                &emsp;&emsp;તે {data[chartState.selected].secondName} ખાતે<br />
                                                (બા.જે. ₹ {data[chartState.selected].soldGoodsAmount} ની {data[chartState.selected].secondName} એ હૂંડી સ્વીકારીને પરત કરી તેના.)
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}><br />{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].finalDateForDraw}
                                            </StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize:"17px"}}>
                                                બેન્ક/રોકડ ખાતે<span style={{ float: "right" }}>ઉ</span><br />
                                                &emsp;&emsp;તે લેણી હૂંડી ખાતે<br />
                                                (બા.જે. પાક્યા તારીખે હૂંડીનાં નાણાં ₹ {data[chartState.selected].soldGoodsAmount} મળ્યા તેના.)
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}><br />{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align='center'><b>{data[chartState.selected].secondName} ના ચોપડામાં આમનોધ</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                        <StyledTableCell align="center" style={{fontSize:"20px"}}>તારીખ</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>ઉધાર (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>જમા (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].soldGoodsDate}
                                            </StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize:"17px"}}>
                                                ખરીદ ખાતે<span style={{ float: "right" }}>ઉ</span><br />
                                                &emsp;&emsp;તે {data[chartState.selected].firstName} ખાતે<br />
                                                (બા.જે. ₹ {data[chartState.selected].soldGoodsAmount} નો માલ {data[chartState.selected].firstName} પાસેથી ઉધાર ખરીદ્યો તેના.)
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}><br />{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].billDrawDate}
                                            </StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize:"17px"}}>
                                                {data[chartState.selected].firstName} ખાતે<span style={{ float: "right" }}>ઉ</span><br />
                                                &emsp;&emsp;તે દેવીહૂંડી ખાતે<br />
                                                (બા.જે. ₹ {data[chartState.selected].soldGoodsAmount} ની {data[chartState.selected].firstName} એ લખેલી હૂંડી સ્વીકારીને પરત કરી તેના.)
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}><br />{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].finalDateForDraw}
                                            </StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize:"17px"}}>
                                                દેવીહૂંડી ખાતે<span style={{ float: "right" }}>ઉ</span><br />
                                                &emsp;&emsp;તે બૅન્ક રોકડ ખાતે<br />
                                                (બા.જે પાક્યા તારીખે દૈવીહૂંડીનાં નાણાં ₹ {data[chartState.selected].soldGoodsAmount} ચૂકવ્યા તેના.)
                                            </StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}>{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                            <StyledTableCell align='right' style={{ verticalAlign: "top" }}><br />{data[chartState.selected].soldGoodsAmount}</StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

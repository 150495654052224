import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  AddButton,
  BootstrapTextField,
  ColorButton,
  CustomColorButton,
  DeleteButton,
  LinkButton,
  RedditTextField,
  ResponseButton,
  ReverseLinkButton,
  StyledGrid,
  StyledTableCell,
  StyledTableRow,
} from "../../MelzoLibrary/StyledComponents";
import { Container } from "@mui/system";

import Icon from "@mui/material/Icon";

import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";

import "react-datepicker/dist/react-datepicker.css";

export default function DrawRandomSample() {
  const data = [
    {
      NumberOfElements: 15,
      size: 5,
      numberOfUnits: 50,
      sampleString: "62 25 6 60 95 55 98 11 71 25 20 45 89 27 40",
      sampleStringArray: [62, 25, 6, 60, 95, 55, 98, 11, 71, 25, 20, 45, 89, 27, 40],
      sampleStringLength: 15,
      arrayOfNumbersLessThanGivenUnits: "25,6,11,25,20,45,27 અને 40",
      arrayWithReplacement: "25,6,11,25 અને 20",
      arrayWithoutReplacement: "25,6,11,20 અને 45",
    },
  ];

  const NormalNumberRegex = new RegExp(/^[0-9]+$/);

  const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);

  const chartState = {
    selected: 0,
  };

  // const [value, onChange] = useState(new Date());
  // const [value2, onChange2] = useState(new Date());

  class OperatingRatio extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: "rainfall",
        type: "mm",
        NumberOfElements: 15,
        size: 5,
        numberOfUnits: 50,
        sampleString: "62 25 6 60 95 55 98 11 71 25 20 45 89 27 40",
        sampleStringArray: [62, 25, 6, 60, 95, 55, 98, 11, 71, 25, 20, 45, 89, 27, 40],
        sampleStringLength: 15,

        editModename: false,
        editModetype: false,
        editModesampleString: false,
        solutionShow: false,
        // tableModeChartModeSwitch: true,
        editModeButtonChecker: false,
        editModeButton: false,
        submitChecker: true,
      };
      this.eventHandler = this.eventHandler.bind(this);
      this.editModeOff = this.editModeOff.bind(this);
      this.stringReducer = this.stringReducer.bind(this);
      this.autoSave = this.autoSave.bind(this);
      this.moveLeftAndRight = this.moveLeftAndRight.bind(this);
    }

    //#region EVENT FUNCTIONALITIES START

    eventHandler(event) {
      switch (event.target.name) {
        case "sampleString":
          this.setState(
            {
              sampleString: this.stringReducer(event.target.value),
            },
            () => {
              this.fillDetails();
            }
          );
          break;
        case "size":
          this.setState(
            {
              size: Number(event.target.value),
            },
            () => {
              this.fillDetails();
            }
          );
          break;
        case "numberOfUnits":
          this.setState(
            {
              numberOfUnits: Number(event.target.value),
            },
            () => {
              this.fillDetails();
            }
          );
          break;
        case "NumberOfElements":
          this.setState(
            {
              NumberOfElements: Number(event.target.value),
            },
            () => {
              this.fillDetails();
            }
          );
          break;
      }
    }

    handleChange(event) {
      this.setState({
        type: event.target.value,
      });
    }

    stringReducer(string) {
      let stringArray = string.split(" ");

      for (let i in stringArray) {
        if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === "") {
          // console.log(i)
          stringArray.splice(i, 1);
        }
      }

      if (stringArray[0] === null) {
        stringArray[0] = 0;
      }

      this.setState({
        sampleStringLength: stringArray.length,
        sampleStringArray: stringArray,
      });

      return string;
    }

    fillDetails() {
      let flag = true;

      if (this.state.name === "") {
        flag = false;
      }

      if (flag === true) {
        this.setState({
          editModeButtonChecker: !flag,
        });
      } else {
        this.setState({
          editModeButtonChecker: !flag,
        });
      }
    }

    autoSave(event) {}

    //#endregion EVENT FUNCTIONALTIES END

    //#region BUTTON PANEL START

    onSubmit() {
      let NumberOfElements = this.state.NumberOfElements;
      let size = this.state.size;
      let numberOfUnits = this.state.numberOfUnits;
      let sampleString = this.state.sampleString;
      let sampleStringArray = this.state.sampleStringArray;
      let sampleStringLength = this.state.sampleStringLength;

      let arrayOfNumbersLessThanGivenUnits = sampleStringArray.filter((a, i) => {
        if (a < numberOfUnits) {
          return true;
        }
      });

      let uniqueArray = [...new Set(arrayOfNumbersLessThanGivenUnits)];

      let arrayWithReplacement = arrayOfNumbersLessThanGivenUnits.slice(0, size);
      let arrayWithoutReplacement = uniqueArray.slice(0, size);

      let dataObject = {
        NumberOfElements: NumberOfElements,
        size: size,
        numberOfUnits: numberOfUnits,
        sampleString: sampleString,
        sampleStringArray: sampleStringArray,
        sampleStringLength: sampleStringLength,
        arrayOfNumbersLessThanGivenUnits: arrayOfNumbersLessThanGivenUnits.toString(),
        arrayWithReplacement: arrayWithReplacement.toString(),
        arrayWithoutReplacement: arrayWithoutReplacement.toString(),
      };

      data.push(dataObject);

      chartState.selected += 1;

      this.setState({});
    }

    onReset() {}

    switchTableToChartAndBack() {}

    showSolutionOnOff() {
      this.setState({
        solutionShow: !this.state.solutionShow,
      });
    }

    moveLeftAndRight(event) {
      if (event.target.name === "Left") {
        chartState.selected = chartState.selected - 1;
        this.setState({});
      } else if (event.target.name === "Right") {
        chartState.selected += 1;
        this.setState({});
      }
    }

    //#endregion BUTTON PANEL END

    //#region EDIT BUTTON START

    buttonMaker(event) {
      switch (event.target.name) {
        case "sampleString":
          this.setState({
            editModesampleString: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
        case "size":
        case "numberOfUnits":
        case "NumberOfElements":
          this.setState({
            editModename: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
        case "type":
          this.setState({
            editModetype: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
      }
    }

    editModeOff() {
      this.setState({
        sampleString: this.state.sampleStringArray.reduce((a, b) => {
          return a + " " + b;
        }),

        submitChecker: true,

        editModename: false,
        editModetype: false,
        editModesampleString: false,
        editModeButton: false,
      });
    }

    //#endregion EDIT BUTTON END

    render() {
      return (
        <div style={{fontSize:"20px"}}>
          <br />
          <div label="InputFieldTables" align="left">
            
            
            {this.state.editModename ? (
              <TextField
                style={{ width: "9%", padding: "-10px", margin: "0rem 1rem" }}
                name="size"
                id="outlined-base"
                error={this.state.size === ""}
                value={this.state.size}
                onChange={this.eventHandler}
                variant="standard"
                autoComplete="off"
              />
            ) : (
              <a className="txt" name="size" onClick={this.buttonMaker.bind(this)}>
                {this.state.size}
              </a>
            )} કદનો નિદર્શ{" "}
            <br />
            <br />
            &emsp;&emsp;&emsp; (i) પુરવણી સહિત &emsp;&emsp;&emsp; (ii) પુરવણીરહિત પ્રકારે
            <br />
            <br />
            યાર્દચ્છિક સંખ્યાઓના કોષ્ટકમાંથી મેળવેલી ક્રમિક બે અંકોવાળી નીચેની &nbsp;
            {this.state.editModename ? (
              <TextField
                style={{ width: "9%", padding: "-10px", margin: "0rem 1rem" }}
                name="NumberOfElements"
                id="outlined-base3"
                error={this.state.NumberOfElements === ""}
                value={this.state.NumberOfElements}
                onChange={this.eventHandler}
                variant="standard"
                autoComplete="off"
              />
            ) : (
              <a className="txt" name="NumberOfElements" onClick={this.buttonMaker.bind(this)}>
                {this.state.NumberOfElements}
              </a>
            )}{" "}
            &nbsp; યાર્દચ્છિક સંખ્યાઓનો ઉપયોગ કરી &nbsp;
            
            
            {this.state.editModename ? (
              <TextField
                style={{ width: "9%", padding: "-10px", margin: "0rem 1rem" }}
                name="numberOfUnits"
                id="outlined-base2"
                error={this.state.numberOfUnits === ""}
                value={this.state.numberOfUnits}
                onChange={this.eventHandler}
                variant="standard"
                autoComplete="off"
              />
            ) : (
              <a className="txt" name="numberOfUnits" onClick={this.buttonMaker.bind(this)}>
                {this.state.numberOfUnits}
              </a>
            )}{" "} એકમો ધરાવતી એક સમષ્ટિમાંથી નિદર્શ પ્રકાર મેળવો<br/>
            {this.state.editModesampleString ? (
              <TextField
                style={{ width: "70%", padding: "-10px", margin: "0rem 1rem" }}
                name="sampleString"
                id="outlined-base4"
                error={this.state.sampleString === ""}
                value={this.state.sampleString}
                onChange={this.eventHandler}
                variant="standard"
                autoComplete="off"
              />
            ) : (
              <a className="txt" name="sampleString" onClick={this.buttonMaker.bind(this)}>
                {" "}
                {this.state.sampleString}
              </a>
            )}{" "}
           
            
            <br />
            <br />
            <br />
            {this.state.editModeButton ? (
              <ColorButton
                disabled={this.state.editModeButtonChecker}
                style={{ "margin-top": "-20px", margin: "0rem 1rem", fontSize:"15px" }}
                align="right"
                onClick={this.editModeOff.bind(this)}
              >
                સંપાદિત મોડ બંધ કરો
              </ColorButton>
            ) : (
              <span></span>
            )}
            <br />
            <br />
            <div align="left" label="buttons">
              <AddButton
                style={{ width: "15%", fontSize:"20px" }}
                disabled={!this.state.submitChecker}
                onClick={this.onSubmit.bind(this)}
              >
                રકમની ગણતરી કરો
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
              <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>
              ઉકેલ બતાવો / છુપાવો
              </AddButton>
            </div>
            <div align="right" label="sumNavigator">
              <AddButton
                name="Left"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected - 1 < 0}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ◄
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {chartState.selected + 1}
              &nbsp;&nbsp;&nbsp;
              <AddButton
                name="Right"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected + 1 > data.length - 1}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ►
              </AddButton>
            </div>
          </div>
          <br />
          <br />
          {this.state.solutionShow && (
            <div label="Answer" align="left">
              સૌપ્રથમ સમષ્ટિના &nbsp;{data[chartState.selected].numberOfUnits}&nbsp; એકમોને આપણે 
              1 થી {data[chartState.selected].numberOfUnits}&nbsp; ક્રમ આપીશું{" "}
              <br />
              <br />
              સમષ્ટિનું કદ {data[chartState.selected].numberOfUnits} હોવાથી
              &nbsp;{data[chartState.selected].numberOfUnits} થી મોટી યાદચ્છિક સંખ્યા આપણે અવગણીશું.&nbsp; તેથી આપણને
              &nbsp;{data[chartState.selected].arrayOfNumbersLessThanGivenUnits} યાદચ્છિક સંખ્યાઓ મળશે.
              <br />
              <br />( i ) હવે પ્રથમ પુરવણી સહિત પ્રકારે નિદર્શ મેળવવાનો હોઈ આપણે પુનરાવર્તિત થતી યાદચ્છિક સંખ્યાઓને અવગણવાને બદલે તેને પણ ધ્યાનમાં લઈશું. નિદર્શનું કદ&nbsp;
              {data[chartState.selected].size} હોવાથી આપણે&nbsp;
              {data[chartState.selected].size} યાદચ્છિક સંખ્યાઓ પસંદ કરીશું. આમ પસંદગી પામેલ યાદચ્છિક સંખ્યાઓ{" "}
              <b>:&nbsp;{data[chartState.selected].arrayWithReplacement} </b>થશે.
              <br />
              <br />( ii ) હવે પુરવણીરહિત પ્રકારે નિદર્શ મેળવવાનો હોઈ આપણે પુનરાવર્તિત થતી યાદચ્છિક સંખ્યાઓને અવગણીશું. નિદર્શનું કદ{" "}
              {data[chartState.selected].size} હોવાથી આપણે {data[chartState.selected].size} યાદચ્છિક સંખ્યાઓ પસંદ કરીશું. આમ, પસંદગી પામેલ યાદચ્છિક સંખ્યાઓ&nbsp;{" "}
              <b>{data[chartState.selected].arrayWithoutReplacement}</b> થશે.
            </div>
          )}
          <br />
          <br />
        </div>
      );
    }
  }

  return (
    <div>
      <Container fixed>
        <OperatingRatio />
      </Container>
    </div>
  );
}

import * as React from "react";

import { AddButton, BootstrapTextField, ColorButton } from "../../MelzoLibrary/StyledComponents";
import { Container } from "@mui/system";

import TextField from "@mui/material/TextField";

import "react-datepicker/dist/react-datepicker.css";

export default function FindGP() {
  const data = [
    {
      r: 3,
      t5: 324,
      n: 5,
      rPowerStep: 4,
      rPower: 81,
      ans: 4,
    },
  ];

  const chartState = {
    selected: 0,
  };

  const NormalNumberRegex = new RegExp(/^[0-9]+$/);

  const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);

  class OperatingRatio extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        equationVariables: { commonRatio: 3, fifthTerm: 324 },

        editModename: false,
        editModetype: false,
        editModesampleString: false,
        solutionShow: false,
        // tableModeChartModeSwitch: true,
        editModeButtonChecker: false,
        editModeButton: false,
        submitChecker: true,
      };
      this.eventHandler = this.eventHandler.bind(this);
      this.editModeOff = this.editModeOff.bind(this);
      this.stringReducer = this.stringReducer.bind(this);
      this.autoSave = this.autoSave.bind(this);
      this.moveLeftAndRight = this.moveLeftAndRight.bind(this);
    }

    //#region EVENT FUNCTIONALITIES START

    eventHandler(event) {
      const key = event.target.name;
      const value = Number(event.target.value);

      const updatedValue = { ...this.state.equationVariables, [key]: value };

      console.log(
        "🚀 ~ file: DomainCo_domainRange.js ~ line 194 ~ OperatingRatio ~ eventHandler ~ updatedValue",
        updatedValue
      );

      this.setState(
        {
          equationVariables: updatedValue,
        },
        () => {
          this.fillDetails();
        }
      );
    }

    handleChange(event) {
      this.setState({
        type: event.target.value,
      });
    }

    stringReducer(string) {
      let stringArray = string.split(" ");

      for (let i in stringArray) {
        if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === "") {
          // console.log(i)
          stringArray.splice(i, 1);
        }
      }

      if (stringArray[0] === null) {
        stringArray[0] = 0;
      }

      this.setState({
        sampleStringLength: stringArray.length,
        sampleStringArray: stringArray,
      });

      return string;
    }

    fillDetails() {
      let flag = true;

      if (this.state.name === "") {
        flag = false;
      }

      if (flag === true) {
        this.setState({
          editModeButtonChecker: !flag,
        });
      } else {
        this.setState({
          editModeButtonChecker: !flag,
        });
      }
    }

    autoSave(event) {}

    //#endregion EVENT FUNCTIONALTIES END

    //#region BUTTON PANEL START

    onSubmit() {
      let r = this.state.equationVariables.commonRatio;
      let t5 = this.state.equationVariables.fifthTerm;

      let dataObject = {
        r: r,
        t5: t5,
        n: 5,
        rPowerStep: 4,
        rPower: r ** 4,
        ans: Number.parseFloat(t5 / r ** 4).toFixed(2),
      };

      data.push(dataObject);

      chartState.selected += 1;

      this.setState({});
    }

    onReset() {}

    switchTableToChartAndBack() {}

    showSolutionOnOff() {
      this.setState({
        solutionShow: !this.state.solutionShow,
      });
    }

    moveLeftAndRight(event) {
      if (event.target.name === "Left") {
        chartState.selected = chartState.selected - 1;
        this.setState({});
      } else if (event.target.name === "Right") {
        chartState.selected += 1;
        this.setState({});
      }
    }

    //#endregion BUTTON PANEL END

    //#region EDIT BUTTON START

    buttonMaker(event) {
      switch (event.target.name) {
        case "sampleString":
          this.setState({
            editModesampleString: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
        case "commonRatio":
        case "fifthTerm":
          this.setState({
            editModename: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
      }
    }

    editModeOff() {
      this.setState({
        submitChecker: true,
        editModename: false,
        editModetype: false,
        editModesampleString: false,
        editModeButton: false,
      });
    }

    //#endregion EDIT BUTTON END

    render() {
      return (
        <div style={{fontSize:"20px"}}>
          <br />
          <div label="InputFieldTables" align="left">
            એક ગુણોત્તર-શ્રેણીમાં સામાન્ય ગુણોત્તર અને પાંચમું પદ અનુક્રમે &nbsp;
            {this.state.editModename ? (
              <TextField
                style={{ width: "6%", padding: "-10px", margin: "0rem 1rem" }}
                name="commonRatio"
                id="outlined-base"
                error={this.state.equationVariables.commonRatio === ""}
                value={this.state.equationVariables.commonRatio}
                onChange={this.eventHandler}
                variant="standard"
                autoComplete="off"
              />
            ) : (
              <a className="txt" name="commonRatio" onClick={this.buttonMaker.bind(this)}>
                {this.state.equationVariables.commonRatio}
              </a>
            )}
            &nbsp; અને &nbsp;
            {this.state.editModename ? (
              <TextField
                style={{ width: "6%", padding: "-10px", margin: "0rem 1rem" }}
                name="fifthTerm"
                id="outlined-base2"
                error={this.state.equationVariables.fifthTerm === ""}
                value={this.state.equationVariables.fifthTerm}
                onChange={this.eventHandler}
                variant="standard"
                autoComplete="off"
              />
            ) : (
              <a className="txt" name="fifthTerm" onClick={this.buttonMaker.bind(this)}>
                {this.state.equationVariables.fifthTerm}
              </a>
            )}{" "}&nbsp;
             હોય તો પ્રથમ પદ શોધો.
            <br />
            <br />
            {this.state.editModeButton ? (
              <ColorButton
                disabled={this.state.editModeButtonChecker}
                style={{ "margin-top": "-20px", margin: "0rem 1rem", fontSize:"15px" }}
                align="right"
                onClick={this.editModeOff.bind(this)}
              >
                સંપાદિત મોડ બંધ કરો
              </ColorButton>
            ) : (
              <span></span>
            )}
            <br />
            <br />
            <div align="left" label="buttons">
              <AddButton
                style={{ width: "15%", fontSize:"20px" }}
                disabled={!this.state.submitChecker}
                onClick={this.onSubmit.bind(this)}
              >
                રકમની ગણતરી કરો
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
              <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>
                ઉકેલ બતાવો / છુપાવો
              </AddButton>
            </div>
            <div align="right" label="sumNavigator">
              <AddButton
                name="Left"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected - 1 < 0}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ◄
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {chartState.selected + 1}
              &nbsp;&nbsp;&nbsp;
              <AddButton
                name="Right"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected + 1 > data.length - 1}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ►
              </AddButton>
            </div>
          </div>
          <br />
          <br />
          {this.state.solutionShow && (
            <div label="Answer" align="left">
              ગુણોત્તર-શ્રેણીમાં સામાન્ય ગુણોત્તર <i>r</i> = {data[chartState.selected].r} અને પાંચમું પદ &nbsp;
              {data[chartState.selected].t5}&nbsp; છે. અહીં T<sub>5</sub> = &nbsp;
              {data[chartState.selected].t5}&nbsp; અને પ્રથમપદ શોધવાનું છે
              <br />
              <br />
              અહીં, T<sub>5</sub> = &nbsp;
              {data[chartState.selected].t5}&nbsp;
              <br />
              <br />
              &emsp;∴ &emsp;&emsp;&emsp; <i>a</i>
              <i>
                {" "}
                r<sup> {data[chartState.selected].n} - 1 </sup>
              </i>{" "}
              = {data[chartState.selected].t5} &emsp;&emsp;&emsp; &emsp;(∴ T<sub>n</sub> =
              <i>
                a r<sup>n-1</sup>
              </i>
              )
              <br />
              <br />
              &emsp;∴ &emsp;&emsp;&emsp; <i>a</i>({data[chartState.selected].r})
              <sup> {data[chartState.selected].rPowerStep} </sup> = {data[chartState.selected].t5}{" "}
              &emsp;&emsp;&emsp;&emsp;&nbsp;(∴ <i>r</i>&nbsp;={data[chartState.selected].r}
              )
              <br />
              <br />
              &emsp;∴ &emsp;&emsp;&emsp; <i>a</i> x ({data[chartState.selected].rPower}) ={" "}
              {data[chartState.selected].t5}
              <br />
              <br />
              &emsp;∴ &emsp;&emsp;&emsp; <i>a</i> = <sup> {data[chartState.selected].t5}</sup>/
              <sub>{data[chartState.selected].rPower}</sub> ={data[chartState.selected].ans}
              <br />
              <br />
              આમ, શ્રેણીનું પ્રથમ પદ{" "}
              
                {data[chartState.selected].ans} છે.
            </div>
          )}
          <br />
          <br />
        </div>
      );
    }
  }

  return (
    <div>
      <Container fixed>
        <OperatingRatio />
      </Container>
    </div>
  );
}

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {
    const data = [{
        "answerPallete": [
            {
                "classlower": 0,
                "classupper": 20,
                "frequency": 20,
                "classlength": 20,
                "midvalue": 10
            },
            {
                "classlower": 20,
                "classupper": 50,
                "frequency": 30,
                "classlength": 30,
                "midvalue": 35
            },
            {
                "classlower": 50,
                "classupper": 70,
                "frequency": 30,
                "classlength": 20,
                "midvalue": 60
            },
            {
                "classlower": 70,
                "classupper": 90,
                "frequency": 15,
                "classlength": 20,
                "midvalue": 80
            },
            {
                "classlower": 90,
                "classupper": 100,
                "frequency": 5,
                "classlength": 10,
                "midvalue": 95
            }
        ],
        "totalFrequency": 100
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }


    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                classesWithFrequency: [
                    {
                        lower: 0,
                        upper: 20,
                        frequency: 20
                    }, {
                        lower: 20,
                        upper: 50,
                        frequency: 30
                    }, {
                        lower: 50,
                        upper: 70,
                        frequency: 30
                    }, {
                        lower: 70,
                        upper: 90,
                        frequency: 15
                    }, {
                        lower: 90,
                        upper: 100,
                        frequency: 5
                    }
                ],

                editModeclassesWithFrequency: [
                    {
                        lower: false,
                        upper: false,
                        frequency: false
                    }, {
                        upper: false,
                        frequency: false
                    }, {
                        upper: false,
                        frequency: false
                    }, {
                        upper: false,
                        frequency: false
                    }, {
                        upper: false,
                        frequency: false
                    }
                ],

                editModetotalSets: false,
                editModesampleString: false,
                editModenumberOfClass: false,
                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'frequency':
                        this.state.classesWithFrequency[customArray[1]].frequency = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'upperClass':
                        this.state.classesWithFrequency[customArray[1]].upper = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'lowerClass':
                        this.state.classesWithFrequency[customArray[1]].lower = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {

            }
        }

        fillDetails() {
            let flag = true

            for (let i in this.state.classesWithFrequency) {
                if (parseInt(i) < this.state.classesWithFrequency.length - 1) {
                    if (parseFloat(this.state.classesWithFrequency[i]) >= parseFloat(this.state.classesWithFrequency)[parseInt(i) + 1]) {
                        flag = false
                    }
                }

                if (!NumberRegex.test(this.state.classesWithFrequency[i].frequency) || this.state.classesWithFrequency[i].frequency === '') {
                    flag = false
                }

                if (!NumberRegex.test(this.state.classesWithFrequency[i].upper) || this.state.classesWithFrequency[i].upper === '') {
                    flag = false
                }

                if (parseInt(i) === 0) {
                    if (!NumberRegex.test(this.state.classesWithFrequency[i].lower) || this.state.classesWithFrequency[i].lower === '') {
                        flag = false
                    }
                }
                
                if (parseInt(i) < this.state.classesWithFrequency.length - 2) {
                    if (parseFloat(this.state.classesWithFrequency[i].upper) >= parseFloat(this.state.classesWithFrequency[parseInt(i)+1].upper)) {
                        flag = false
                    }
                }
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,

                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {
            let answerPallete = []
            let totalFrequency = 0

            for (let i in this.state.classesWithFrequency) {
                answerPallete.push({
                    classlower: parseInt(i) === 0 ? this.state.classesWithFrequency[i].lower : this.state.classesWithFrequency[i - 1].upper,
                    classupper: this.state.classesWithFrequency[i].upper,
                    frequency: this.state.classesWithFrequency[i].frequency
                })

            }

            for (let i of answerPallete) {
                i.classlength = parseFloat(i.classupper) - parseFloat(i.classlower)
                i.midvalue = (parseFloat(i.classupper) + parseFloat(i.classlower)) / 2
                totalFrequency += i.frequency
            }

            let dataObject = {
                answerPallete,
                totalFrequency
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'frequency':
                        this.state.editModeclassesWithFrequency[customArray[1]].frequency = true
                        this.setState({
                            editModeButton: true,
                            submitChecker:false
                        })
                        break;
                    case 'upperClass':
                        this.state.editModeclassesWithFrequency[customArray[1]].upper = true
                        this.setState({
                            editModeButton: true,
                            submitChecker:false
                        })
                        break;
                    case 'lowerClass':
                        this.state.editModeclassesWithFrequency[customArray[1]].lower = true
                        this.setState({
                            editModeButton: true,
                            submitChecker:false
                        })
                        break;
                }
            }

            switch (event.target.name) {


            }
        }

        editModeOff() {

            for (let i in this.state.editModeclassesWithFrequency) {
                if (parseInt(i) === 0) {
                    this.state.editModeclassesWithFrequency[i].lower = false
                }
                this.state.editModeclassesWithFrequency[i].frequency = false
                this.state.editModeclassesWithFrequency[i].upper = false
            }


            this.setState({
                submitChecker: true,
                editModenumberOfClass: false,
                editModesampleString: false,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        The following frequency distribution of unequal class length is obtained for some data. Prepare a frequency distribution stating class length and mid value of each class.<br /><br />

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Classes</StyledTableCell>
                                        <StyledTableCell align="center">Frequency</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.classesWithFrequency.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align="center">
                                                {i !== 0 ?
                                                    this.state.classesWithFrequency[i - 1].upper :

                                                    this.state.editModeclassesWithFrequency[i].lower ? <TextField style={{ "width": "5%", "padding": "-10px" }} name={["lowerClass", i]} id='outlined-base' error={x.lower === '' || isNaN(x.lower)}
                                                        value={x.lower} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["lowerClass", i]} onClick={this.buttonMaker.bind(this)}> {x.lower}</a>
                                                } -&nbsp;
                                                {this.state.editModeclassesWithFrequency[i].upper ? <TextField style={{ "width": "5%", "padding": "-10px" }} name={["upperClass", i]} id='outlined-base' error={x.upper === '' || isNaN(x.upper)}
                                                    value={x.upper} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["upperClass", i]} onClick={this.buttonMaker.bind(this)}> {x.upper}</a>} &nbsp;
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {this.state.editModeclassesWithFrequency[i].frequency ? <TextField style={{ "width": "5%", "padding": "-10px" }} name={["frequency", i]} id='outlined-base' error={x.frequency === '' || isNaN(x.frequency)}
                                                    value={x.frequency} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["frequency", i]} onClick={this.buttonMaker.bind(this)}> {x.frequency}</a>} &nbsp;
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <div align="center"><b>A frequency distribution showing mid value and class length of each class is as under :</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Classes</StyledTableCell>
                                            <StyledTableCell align="center">Class length</StyledTableCell>
                                            <StyledTableCell align="center">Mid value</StyledTableCell>
                                            <StyledTableCell align="center">Frequency</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].answerPallete.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center">{x.classlower}-{x.classupper}</StyledTableCell>
                                                <StyledTableCell align="center">{x.classupper}-{x.classlower}={x.classlength}</StyledTableCell>
                                                <StyledTableCell align="center"><sup>({x.classlower} + {x.classupper})</sup>/<sub>2</sub> = {x.midvalue}</StyledTableCell>
                                                <StyledTableCell align="center">{x.frequency}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="center"><b>Total</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].totalFrequency}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

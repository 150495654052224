import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {

    const data = [{
        "tableValues": [
            {
                "itemName": "A",
                "incdecpercentage": "90",
                "type": "increase",
                "importance": "4",
                "relativeTo": "B"
            },
            {
                "itemName": "B",
                "incdecpercentage": "120",
                "type": "increase",
                "importance": "1",
                "relativeTo": "self"
            },
            {
                "itemName": "C",
                "incdecpercentage": "70",
                "type": "increase",
                "importance": "6",
                "relativeTo": "A"
            },
            {
                "itemName": "D",
                "incdecpercentage": "2",
                "type": "decrease",
                "importance": "2.5",
                "relativeTo": "B"
            },
            {
                "itemName": "E",
                "incdecpercentage": "5",
                "type": "decrease",
                "importance": "2.5",
                "relativeTo": "B"
            }
        ],
        "indexNumber": [
            190,
            220,
            170,
            98,
            95
        ],
        "relativeWeight": [
            4,
            1,
            24,
            2.5,
            2.5
        ],
        "IW": [
            760,
            220,
            4080,
            245,
            237.5
        ],
        "totalTableValues": {
            "relativeWeight": 34,
            "IW": 5542.5
        },
        "generalPriceIndex": 163.01
    }]

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                tableValues: [
                    {
                        itemName: 'A',
                        incdecpercentage: '90',
                        type: 'increase',
                        importance: '4',
                        relativeTo: 'B'
                    }, {
                        itemName: 'B',
                        incdecpercentage: '120',
                        type: 'increase',
                        importance: '1',
                        relativeTo: 'B'
                    }, {
                        itemName: 'C',
                        incdecpercentage: '70',
                        type: 'increase',
                        importance: '6',
                        relativeTo: 'A'
                    }, {
                        itemName: 'D',
                        incdecpercentage: '2',
                        type: 'decrease',
                        importance: '2.5',
                        relativeTo: 'B'
                    }, {
                        itemName: 'E',
                        incdecpercentage: '5',
                        type: 'decrease',
                        importance: '2.5',
                        relativeTo: 'B'
                    }
                ],
                startYear: 2010,
                endYear: 2015,

                editModetableValues: [
                    {
                        itemName: false,
                        incdecpercentage: false,
                        type: false,
                        importance: false,
                        relativeTo: false
                    }, {
                        itemName: false,
                        incdecpercentage: false,
                        type: false,
                        importance: false,
                        relativeTo: false
                    }, {
                        itemName: false,
                        incdecpercentage: false,
                        type: false,
                        importance: false,
                        relativeTo: false
                    }, {
                        itemName: false,
                        incdecpercentage: false,
                        type: false,
                        importance: false,
                        relativeTo: false
                    }, {
                        itemName: false,
                        incdecpercentage: false,
                        type: false,
                        importance: false,
                        relativeTo: false
                    }
                ],
                editModestartYear: false,
                editModeendYear: false,

                tempStateChecker: [],

                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.handleChange = this.handleChange.bind(this)
            this.relativeChange = this.relativeChange.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'itemName':
                        this.state.tableValues[customArray[1]].itemName = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'incdecpercentage':
                        this.state.tableValues[customArray[1]].incdecpercentage = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'type':
                        this.state.tableValues[customArray[1]].type = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'importance':
                        this.state.tableValues[customArray[1]].importance = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'relativeTo':
                        this.state.tableValues[customArray[1]].relativeTo = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'startYear':
                    this.setState({
                        startYear: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'endYear':
                    this.setState({
                        endYear: event.target.value
                    }, () => { this.fillDetails() })
                    break;
            }
        }

        fillDetails() {
            let flag = true

            for (let i of this.state.tableValues) {
                if (i.itemName === '') {
                    flag = false
                }
                if (!NumberRegex.test(i.incdecpercentage) || i.incdecpercentage === '') {
                    flag = false
                }
                if (!NumberRegex.test(i.importance) || i.importance === '') {

                }
                for (let i in this.state.tempStateChecker) {
                    this.state.tempStateChecker.pop()
                }
                
                flag = this.checkStateAndReturn(i.itemName, i.relativeTo)
                if(flag === false){
                    break;
                }
            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region SELFSTATE CHECK AND RETURN START

        checkStateAndReturn(previousValue, value) {
            let counter = true
            if (previousValue === value) {
                return true
            } else {
                for (let i in this.state.tempStateChecker) {
                    if (this.state.tempStateChecker[i] === value) {
                        counter = false
                        break;
                    } else {
                        counter = true
                    }
                }
                if (counter === false) {
                    return false
                } else if (counter === true) {
                    for (let i in this.state.tableValues) {
                        if (this.state.tableValues[i].itemName === value) {
                            this.state.tempStateChecker.push(previousValue)
                            return this.checkStateAndReturn(value, this.state.tableValues[i].relativeTo)
                        }
                    }
                }
            }
        }

        getStateAndReturn(previousValue, value, currentMultiplier) {
            if (value === previousValue) {
                return parseFloat(currentMultiplier)
            } else {
                for (let i of this.state.tableValues) {
                    if (i.itemName === value) {
                        return this.getStateAndReturn(i.itemName, i.relativeTo, currentMultiplier * parseFloat(i.importance))
                    }
                }
            }
        }

        //#endregion SELFSTATE CHECK AND RETURN END

        //#region BUTTON PANEL START

        onSubmit() {

            let indexNumber = []

            for (let i of this.state.tableValues) {
                if (i.type === 'increase') {
                    indexNumber.push(100 + parseFloat(i.incdecpercentage))
                }
                else {
                    indexNumber.push(100 - parseFloat(i.incdecpercentage))
                }
            }

            let totalTableValues = {
                relativeWeight: 0,
                IW: 0
            }

            let relativeWeight = []

            for (let i of this.state.tableValues) {
                let tempValue = this.getStateAndReturn(i.itemName, i.relativeTo, parseFloat(i.importance))
                relativeWeight.push(tempValue)
            }

            let IW = []

            for (let i in relativeWeight) {
                IW.push(relativeWeight[i] * indexNumber[i])
            }

            for (let i in relativeWeight) {
                totalTableValues.relativeWeight += relativeWeight[i]
                totalTableValues.IW += IW[i]
            }


            // console.log(relativeWeight)

            let dataObject = {
                tableValues: this.state.tableValues,
                indexNumber: indexNumber,
                relativeWeight: relativeWeight,
                IW: IW,
                totalTableValues: totalTableValues,
                generalPriceIndex: Math.round(totalTableValues.IW / totalTableValues.relativeWeight * 100) / 100
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addRows() {
            this.state.tableValues.push({
                itemName: "Item Name",
                incdecpercentage: "10",
                type: "increase",
                importance:"1",
                relativeTo:this.state.tableValues[0].itemName

            })
            this.state.editModetableValues.push({
                itemName: true,
                incdecpercentage: true,
                type: true,
                importance:true,
                relativeTo:true
            })
            this.setState({
                editModeButton: true
            })
        }

        removeRows(event) {
            this.state.tableValues.splice(event, 1)
            this.state.editModetableValues.splice(event, 1)
            this.setState({})
        }

        handleChange(event) {
            this.state.tableValues[event.target.name].type = event.target.value
            this.setState({})
        }

        relativeChange(event) {
            this.state.tableValues[event.target.name].relativeTo = event.target.value
            this.setState({}, () => { this.fillDetails() })
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'itemName':
                        this.state.editModetableValues[customArray[1]].itemName = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'incdecpercentage':
                        this.state.editModetableValues[customArray[1]].incdecpercentage = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'type':
                        this.state.editModetableValues[customArray[1]].type = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'importance':
                        this.state.editModetableValues[customArray[1]].importance = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'relativeTo':
                        this.state.editModetableValues[customArray[1]].relativeTo = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'startYear':
                    this.setState({
                        editModestartYear: true,
                        editModeButton: true
                    })
                    break;
                case 'endYear':
                    this.setState({
                        editModeendYear: true,
                        editModeButton: true
                    })
                    break;
            }
        }

        editModeOff() {
            for (let i of this.state.editModetableValues) {
                i.itemName = false
                i.incdecpercentage = false
                i.type = false
                i.importance = false
                i.relativeTo = false
            }

            this.setState({
                editModeendYear: false,
                editModestartYear: false,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        The prices of the following items and their increase decrease with respect to year&nbsp;
                        {
                            this.state.editModestartYear ?
                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name="startYear" id='outlined-base' error={this.state.startYear === '' || isNaN(this.state.startYear) || this.state.startYear < 1000 || this.state.startYear > 9000}
                                    value={this.state.startYear} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="startYear" onClick={this.buttonMaker.bind(this)}> {this.state.startYear}</a>
                        } is given as follows:<br /><br />
                        <TableContainer>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Item Name</StyledTableCell>
                                        <StyledTableCell align="center">Increase/Decrease Percentage</StyledTableCell>
                                        <StyledTableCell align="center">Increase/Decrease</StyledTableCell>
                                        <StyledTableCell align="center">Importance</StyledTableCell>
                                        <StyledTableCell align="center">Relative to</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.tableValues.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModetableValues[i].itemName ?
                                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["itemName", i]} id='outlined-base' error={this.state.tableValues[i].itemName === ''}
                                                            value={this.state.tableValues[i].itemName} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["itemName", i]} onClick={this.buttonMaker.bind(this)}> {this.state.tableValues[i].itemName}</a>
                                                }&nbsp;
                                                {this.state.tableValues.length > 1 && <IconButton style={{ width: "3%", color: "red" }} onClick={this.removeRows.bind(this, i)} name="-">-</IconButton>}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModetableValues[i].incdecpercentage ?
                                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["incdecpercentage", i]} id='outlined-base' error={this.state.tableValues[i].incdecpercentage === '' || isNaN(this.state.tableValues[i].incdecpercentage)}
                                                            value={this.state.tableValues[i].incdecpercentage} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["incdecpercentage", i]} onClick={this.buttonMaker.bind(this)}> {this.state.tableValues[i].incdecpercentage}</a>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {this.state.editModetableValues[i].type ? <FormControl style={{ width: "50%" }}>
                                                    <InputLabel id="demo-simple-select-label">Increase/Decrease</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name={i}
                                                        value={this.state.tableValues[i].type}
                                                        label="Option to Add"
                                                        onChange={this.handleChange}
                                                    >
                                                        <MenuItem value={"increase"}>Increase</MenuItem>
                                                        <MenuItem value={"decrease"}>Decrease</MenuItem>
                                                    </Select>
                                                </FormControl> :
                                                    <a className='txt' name={["type", i]} onClick={this.buttonMaker.bind(this)}> {this.state.tableValues[i].type}</a>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModetableValues[i].importance ?
                                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["importance", i]} id='outlined-base' error={this.state.tableValues[i].importance === '' || isNaN(this.state.tableValues[i].importance)}
                                                            value={this.state.tableValues[i].importance} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["importance", i]} onClick={this.buttonMaker.bind(this)}> {this.state.tableValues[i].importance}</a>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {this.state.editModetableValues[i].relativeTo ?
                                                    <FormControl style={{ width: "50%" }}>
                                                        <InputLabel id="new">Relative To</InputLabel>
                                                        <Select
                                                            labelId="new"
                                                            id="new"
                                                            name={i}
                                                            value={this.state.tableValues[i].relativeTo}
                                                            label="Option to Add"
                                                            onChange={this.relativeChange}
                                                        >
                                                            {this.state.tableValues.map((y, j) => (
                                                                <MenuItem key={[y.itemName, j]} value={y.itemName}>{y.itemName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl> :
                                                    <a className='txt' name={["relativeTo", i]} onClick={this.buttonMaker.bind(this)}> {this.state.tableValues[i].relativeTo === this.state.tableValues[i].itemName ? "self" : this.state.tableValues[i].relativeTo}</a>
                                                }
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />
                        <AddButton style={{ width: "15%" }} onClick={this.addRows.bind(this)}>ADD ITEM</AddButton>
                        &nbsp;<br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >Edit Mode Off</ColorButton> : <span></span>}
                        <br /><br />
                        Compute the general price index number of the year&nbsp;
                        {
                            this.state.editModeendYear ?
                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name="endYear" id='outlined-base' error={this.state.endYear === '' || isNaN(this.state.endYear) || this.state.endYear < 1000 || this.state.endYear > 9000}
                                    value={this.state.endYear} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="endYear" onClick={this.buttonMaker.bind(this)}> {this.state.endYear}</a>
                        }
                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer">
                            <TableContainer>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Item</StyledTableCell>
                                            <StyledTableCell align="center">Percentage<br />increase (+) <br />decrease (-)</StyledTableCell>
                                            <StyledTableCell align="center">Index number<br /> <i>I</i> = (100 + increase)<br />= (100 - decrease)</StyledTableCell>
                                            <StyledTableCell align="center">Weight <br /><i>W</i></StyledTableCell>
                                            <StyledTableCell align="center"><i>IW</i></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].tableValues.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="center">{x.itemName}</StyledTableCell>
                                                <StyledTableCell align="center">{x.type === 'increase' ? "+ " : "- "}{x.incdecpercentage}</StyledTableCell>
                                                <StyledTableCell align="center">100 {x.type === 'increase' ? "+ " : "- "}{x.incdecpercentage} = {data[chartState.selected].indexNumber[i]}</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].relativeWeight[i]}</StyledTableCell>
                                                <StyledTableCell align="center">{data[chartState.selected].IW[i]}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="center"><b>Total</b></StyledTableCell>
                                            <StyledTableCell align="center"><b></b></StyledTableCell>
                                            <StyledTableCell align="center"><b></b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].totalTableValues.relativeWeight}</b></StyledTableCell>
                                            <StyledTableCell align="center"><b>{data[chartState.selected].totalTableValues.IW}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />
                            <div align="left">
                                General price index number = <sup>Σ<i>IW</i></sup>/<sub>Σ<i>W</i></sub><br />
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;= <sup>{data[chartState.selected].totalTableValues.IW}</sup>/<sub>{data[chartState.selected].totalTableValues.relativeWeight}</sub> <br />
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;= {Math.round((data[chartState.selected].totalTableValues.IW / data[chartState.selected].totalTableValues.relativeWeight) * 10000) / 10000}<br />
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;≃ {data[chartState.selected].generalPriceIndex}<br />
                                {data[chartState.selected].generalPriceIndex > 100 ? <span>Thus, if can be said that there is a rise of ({data[chartState.selected].generalPriceIndex} - 100) = {Math.round((data[chartState.selected].generalPriceIndex - 100) * 100) / 100} % in the prices in the current year as compared to the base year</span> :
                                    <span>Thus, if can be said that there is a decrease of (100 - {data[chartState.selected].generalPriceIndex}) = {Math.round((100 - data[chartState.selected].generalPriceIndex) * 100) / 100} % in the prices in the current year as compared to the base year</span>}
                            </div>

                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

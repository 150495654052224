import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
import { Co2Sharp } from '@mui/icons-material';

//ILLUSTRATION 8

export default function ComparativeProfitLossStatement() {

    const data = [{
        "date": "2016-03-31",
        "newDate": "2016-04-01",
        "profitLossAmount": [
            1800,
            1200
        ],
        "leftSideTotalAmount": 71000,
        "rightSideTotalAmount": 68000,
        "AnswerPalette": [
            {
                "side": "right",
                "incdec": "વધારો",
                "name": "જમીન-મકાન",
                "amount": 28000
            },
            {
                "side": "left",
                "incdec": "ઘટાડો",
                "name": "યંત્રો",
                "amount": 7000
            },
            {
                "side": "left",
                "incdec": "ઘટાડો",
                "name": "ફર્નિચર",
                "amount": 20000
            },
            {
                "side": "left",
                "incdec": "ઘટાડો",
                "name": "મોટરકાર",
                "amount": 24000
            },
            {
                "side": "left",
                "incdec": "વધારો",
                "name": "ઘાલખાય અનામત",
                "amount": 4000,
                "badDebtR": 5000
            },
            {
                "side": "left",
                "incdec": "ઘટાડો",
                "name": "પેટન્ટ",
                "amount": 10000
            },
            {
                "side": "left",
                "type": "Outstanding interest on loan",
                "name": "લોન પર ચૂક્વવાના વ્યાજ",
                "amount": 6000
            },
            {
                "side": "right",
                "incdec": "ઘટાડો",
                "name": "લેણદારો",
                "amount": 8000
            },
            {
                "side": "right",
                "incdec": "ઘટાડો",
                "name": "દેવીહૂંડી",
                "amount": 2000
            },
            {
                "side": "right",
                "type": "Investment",
                "name": "રોકાણો",
                "amount": 20000
            },
            {
                "side": "left",
                "type": "Prepaid insurance premium",
                "name": "અગાઉથી ચૂકવેલ વીમા-પ્રીમિયમ",
                "amount": 10000
            }
        ],
        "AnswerPaletteTotal": 342000,
        "partners": [
            "Patel",
            "Shah"
        ],
        "capitalAccount": [
            120000,
            130000
        ],
        "leftSideArray1": [
            {
                "side": "left",
                "incdec": "decrease",
                "name": "યંત્રો",
                "amount": 7000
            },
            {
                "side": "left",
                "incdec": "decrease",
                "name": "ફર્નિચર",
                "amount": 20000
            },
            {
                "side": "left",
                "incdec": "decrease",
                "name": "મોટરકાર",
                "amount": 24000
            },
            {
                "side": "left",
                "incdec": "increase",
                "name": "ઘાલખાધ અનામત",
                "amount": 4000,
                "badDebtR": 5000
            },
            {
                "side": "left",
                "incdec": "decrease",
                "name": "પેટન્ટ",
                "amount": 10000
            },
            {
                "side": "left",
                "type": "Outstanding interest on loan",
                "name": "લોન પર ચૂક્વવાના બાકી વ્યાજ",
                "amount": 6000
            }
        ],
        "rightSideArray1": [
            {
                "side": "right",
                "incdec": "increase",
                "name": "જમીન-મકાન",
                "amount": 28000
            },
            {
                "side": "right",
                "incdec": "decrease",
                "name": "લેણદારો",
                "amount": 8000
            },
            {
                "side": "right",
                "incdec": "decrease",
                "name": "દેવીહૂંડી",
                "amount": 2000
            },
            {
                "side": "right",
                "type": "Investment",
                "name": "રોકાણો",
                "amount": 20000
            },
            {
                "side": "right",
                "type": "Prepaid insurance premium",
                "name": "અગાઉથી ચૂકવેલ વીમા-પ્રીમિયમ",
                "amount": 10000
            }
        ],
        "newPartner": "Raval",
        "newPartnerCapital": 200000,
        "newCapitalAccount": [
            118200,
            128800
        ],
        "leftSideArray2": [
            {
                "name": "લોન પર ચૂક્વવાનું બાકી વ્યાજ",
                "amount": 6000
            },
            {
                "name": "લેણદારો",
                "amount": 72000
            },
            {
                "name": "દેવીહૂંડી",
                "amount": 18000
            },
            {
                "name": "લોન",
                "amount": 100000
            },
            {
                "name": "ખર્ચા ચૂકવવાના બાકી",
                "amount": 30000
            }
        ],
        "rightSideArray2": [
            {
                "name": "જમીન-મકાન",
                "amount": 168000
            },
            {
                "name": "યંત્રો",
                "amount": 63000
            },
            {
                "name": "ફર્નિચર",
                "amount": 60000
            },
            {
                "name": "મોટરકાર",
                "amount": 96000
            },
            {
                "name": "દેવાદારો",
                "amount": 16000,
                "badDebtR": 5000
            },
            {
                "name": "રોકાણો",
                "amount": 20000
            },
            {
                "name": "અગાઉથી ચૂકવેલ વીમા પ્રીમિયમ",
                "amount": 10000
            },
            {
                "name": "સ્ટૉક",
                "amount": 40000
            },
            {
                "name": "રોકડ",
                "amount": 200000
            }
        ],
        "finalPartners": [
            "Patel",
            "Shah",
            "Raval"
        ],
        "finalCapitalAccount": [
            118200,
            128800,
            200000
        ],
        "rightSideArray2Total": 673000
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                partners: ["Patel", "Shah"],
                capitalAccount: [120000, 130000],
                initialProfitLossRatio: [3, 2],
                date: "2016-03-31",
                liabilities: [{
                    type: "લોન",
                    amount: 100000
                }, {
                    type: "લેણદારો",
                    amount: 80000
                }, {
                    type: "દેવીહૂંડી",
                    amount: 20000
                }, {
                    type: "ખર્ચ ચૂકવવાના બાકી",
                    amount: 30000
                }],
                assets: [{
                    type: "પેટન્ટ",
                    amount: 10000
                }, {
                    type: "જમીન-મકાન",
                    amount: 140000
                }, {
                    type: "યંત્રો",
                    amount: 70000
                }, {
                    type: "ફર્નિચર",
                    amount: 80000
                }, {
                    type: "મોટરકાર",
                    amount: 120000
                }, {
                    type: "સ્ટૉક",
                    amount: 40000
                }, {
                    type: "દેવાદારો",
                    originalAmount: 21000,
                    badDebtR: 1000,
                    amount: 20000
                }],
                totalAssetAmount: 480000,
                totalLiabilityAmount: 480000,
                newPartner: "Raval",
                newDate: "2016-04-01",
                newPartnerCapital: 200000,

                questionPallete: [
                    {//1
                        type: "IncDecP",
                        name: "જમીન અને મકાન",
                        originalAmount: 140000,
                        percent: 20,
                        incdec: "increase"
                    },
                    {//2
                        type: "IncDecP",
                        name: "યંત્રો",
                        originalAmount: 70000,
                        percent: 10,
                        incdec: "decrease"
                    },
                    {//3
                        type: "IncDecV",
                        name: "ફર્નિચર",
                        originalAmount: 80000,
                        amount: 60000
                    },
                    {//4
                        type: "IncDecP",
                        name: "મોટરકાર",
                        originalAmount: 120000,
                        percent: 20,
                        incdec: "decrease"
                    },
                    {//5
                        type: "provision",
                        name: "દેવાદારો",
                        originalAmount: 21000,
                        oldBadDebtR: 1000,
                        badDebtR: 5000
                    },
                    {//6
                        type: "valueless",
                        name: "પેટન્ટ",
                        originalAmount: 10000
                    },
                    {//7
                        type: "outstandingInterestOnLoan",
                        amount: 6000
                    },
                    {//8
                        type: "lessPaid",
                        name: "લેણદારો",
                        originalAmount: 80000,
                        percent: 10
                    },
                    {//9
                        type: "lessPaid",
                        name: "દેવીહૂંડી",
                        originalAmount: 20000,
                        percent: 10
                    },
                    {//10
                        type: "unrecordedInvestment",
                        amount: 20000
                    },
                    {//11
                        type: "insurancePremium",
                        debitedAmount: 30000,
                        amount: 10000
                    }
                ],

                addQuestionType: "none",
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempPayType: '',
                tempReturneeName: '',
                tempAmount: '',
                tempDue: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempDueDate: '',
                tempRebate: false,

                editModepartners: [false, false, false],
                editModecapitalAccount: [false, false, false],
                editModeinitialProfitLossRatio: [false, false, false],
                editModedate: false,
                editModeliabilities: [{
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }],
                editModeassets: [{
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false,
                    originalAmount: false,
                    badDebtR: false
                }],

                editModenewPartner: false,
                editModenewDate: false,
                editModenewPartnerCapital: false,
                editModequestionPallete: [
                    {//1
                        percent: false
                    },
                    {//2
                        percent: false
                    },
                    {//3
                        amount: false
                    },
                    {//4
                        percent: false
                    },
                    {//5
                        badDebtR: false
                    },
                    {//6

                    },
                    {//7
                        amount: false
                    },
                    {//8
                        percent: false
                    },
                    {//9
                        percent: false
                    },
                    {//10
                        amount: false
                    },
                    {//
                        debitedAmount: false,
                        amount: false
                    }
                ],

                addQuestionChecker: false,
                submitChecker: true,
                solutionShow: false

            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.stringReducer = this.stringReducer.bind(this)
            this.addNameChange = this.addNameChange.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'partners':
                        this.state.partners[customArray[1]] = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'initialPL':
                        this.state.initialProfitLossRatio[customArray[1]] = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'liabilitiesType':
                        this.state.liabilities[customArray[1]].type = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'liabilitiesAmount':
                        this.state.liabilities[customArray[1]].amount = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;
                    case 'assetsType':
                        this.state.assets[customArray[1]].type = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'assetsAmount':
                        this.state.assets[customArray[1]].amount = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;
                    case 'assetsOriginalAmount':
                        this.state.assets[customArray[1]].originalAmount = event.target.value
                        this.setState({}, () => {
                            this.badDebtDecreaser(customArray[1])
                        })
                        break;
                    case 'assetsBadDebtR':
                        this.state.assets[customArray[1]].badDebtR = event.target.value
                        this.setState({}, () => {
                            this.badDebtDecreaser(customArray[1])
                        })
                        break;
                    case 'capitalAccount':
                        this.state.capitalAccount[customArray[1]] = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;

                    case 'IncDecVAmount':
                        this.state.questionPallete[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'IncDecP':
                        this.state.questionPallete[customArray[1]].percent = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'provision':
                        this.state.questionPallete[customArray[1]].badDebtR = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'outstandingInterestOnLoan':
                        this.state.questionPallete[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'lessPaid':
                        this.state.questionPallete[customArray[1]].percent = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'unrecordedInvestment':
                        this.state.questionPallete[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'insurancePremiumDAmount':
                        this.state.questionPallete[customArray[1]].debitedAmount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'insurancePremiumAmount':
                        this.state.questionPallete[customArray[1]].amount = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'date':
                    this.setState({
                        date: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'newDate':
                    this.setState({
                        newDate: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'newPartner':
                    this.setState({
                        newPartner: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'newPartnerCapital':
                    this.setState({
                        newPartnerCapital: event.target.value
                    }, () => { this.fillDetails() })
                    break

            }
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'date':
                    this.setState({
                        tempDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'dueDate':
                    this.setState({
                        tempDueDate: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'name':
                    this.setState({
                        tempName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amount':
                    // console.log(event.target.value)
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'due':
                    this.setState({
                        tempDue: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'discount':
                    this.setState({
                        tempDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Tdiscount':
                    this.setState({
                        tempTDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'Cdiscount':
                    this.setState({
                        tempCDiscount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'purchasedFrom':
                    this.setState({
                        tempPurchasedFrom: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'billno':
                    this.setState({
                        tempBillno: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'credit':
                    this.setState({
                        tempCredit: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPercentage':
                    this.setState({
                        tempAmountPercentage: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'invoiceNo':
                    this.setState({
                        tempInvoiceNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'debitNoteNo':
                    this.setState({
                        tempDebitNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'creditNoteNo':
                    this.setState({
                        tempCreditNoteNo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'amountPaidImmediately':
                    this.setState({
                        tempAmountPaidImmediately: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'memo':
                    this.setState({
                        tempMemo: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'assetName':
                    this.setState({
                        tempAssetName: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmount':
                    this.setState({
                        tempExtraSum: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'extraAmountReason':
                    this.setState({
                        tempExtraSumReason: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashPurchase':
                    this.setState({
                        tempCashPurchase: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
                case 'cashSales':
                    this.setState({
                        tempCashSales: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            switch (this.state.addQuestionType) {
                case 'changeInValue':
                    if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempName !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'IncDecP':
                    if (this.state.tempAmountPercentage !== '' && NumberRegex.test(this.state.tempAmountPercentage) && parseFloat(this.state.tempAmountPercentage) > 0 && parseFloat(this.state.tempAmountPercentage) < 100 &&
                        this.state.tempName !== '' &&
                        this.state.tempPayType !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'provision':
                    if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempName !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'valueless':
                    if (this.state.tempName !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break
                case 'lessPaid':
                    if (this.state.tempAmountPercentage !== '' && NumberRegex.test(this.state.tempAmountPercentage) && parseFloat(this.state.tempAmountPercentage) > 0 && parseFloat(this.state.tempAmountPercentage) < 100 &&
                        this.state.tempName !== '') {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'outstandingInterestOnLoan':
                    if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'unrecordedInvestment':
                    if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;
                case 'insurancePremium':
                    if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) &&
                        this.state.tempDue !== '' && NumberRegex.test(this.state.tempDue)) {
                        this.setState({
                            addQuestionChecker: true
                        })
                    } else {
                        this.setState({
                            addQuestionChecker: false
                        })
                    }
                    break;

            }
        }

        questionTypeChange(event) {
            this.setState({
                tempDate: '',
                tempAssetName: '',
                tempName: '',
                tempPayType: '',
                tempReturneeName: '',
                tempAmount: '',
                tempDue: '',
                tempAmountPercentage: '',
                tempDiscount: '',
                tempTDiscount: '',
                tempCDiscount: '',
                tempPurchasedFrom: '',
                tempBillno: '',
                tempInvoiceNo: '',
                tempDebitNoteNo: '',
                tempCreditNoteNo: '',
                tempCredit: '',
                tempMemo: '',
                tempExtraSum: '',
                tempExtraSumReason: '',
                tempCashPurchase: '',
                tempCashSales: '',
                tempDueDate: '',
                tempRebate: false,

                tempAmountPaidImmediately: '',

                addQuestionChecker: false,
                addQuestionType: event.target.value
            })
        }

        addNameChange(event) {
            this.setState({
                tempName: event.target.value
            }, () => { this.addButtonChecker() })
        }

        addTypeChange(event) {
            this.setState({
                tempPayType: event.target.value
            })
        }

        stringReducer(string) {
            let stringArray = string.split(" ")

            for (let i in stringArray) {
                if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === '') {
                    // console.log(i)
                    stringArray.splice(i, 1)
                }
            }

            if (stringArray[0] === null) {
                stringArray[0] = 0
            }

            this.setState({
                sampleStringLength: stringArray.length,
                sampleStringArray: stringArray
            })

            return string
        }

        badDebtDecreaser(pos) {
            this.state.assets[pos].amount = parseFloat(this.state.assets[pos].originalAmount) - parseFloat(this.state.assets[pos].badDebtR)
            this.setState({}, () => { this.totalAmountCalculator() })
        }

        totalAmountCalculator() {
            let totalAssetAmount = 0

            let totalLiabilityAmount = this.state.capitalAccount.reduce((a, b) => parseFloat(a) + parseFloat(b))

            for (let i of this.state.liabilities) {
                totalLiabilityAmount += parseFloat(i.amount)
            }
            for (let i of this.state.assets) {
                totalAssetAmount += parseFloat(i.amount)
            }

            this.setState({
                totalAssetAmount: totalLiabilityAmount,
                totalLiabilityAmount: totalAssetAmount
            },()=>{this.fillDetails()})

        }

        fillDetails() {

            let flag = true

            for (let i in this.state.partners) {
                if (this.state.partners[i] === '') {
                    flag = false
                }
            }
            for (let i in this.state.capitalAccount) {
                if (this.state.capitalAccount[i] === '' || !NumberRegex.test(this.state.capitalAccount[i])) {
                    flag = false
                }
            }
            for (let i in this.state.initialProfitLossRatio) {
                if (this.state.initialProfitLossRatio[i] === '' || !NumberRegex.test(this.state.initialProfitLossRatio[i]) || parseFloat(this.state.initialProfitLossRatio[i]) <= 0 || parseFloat(this.state.initialProfitLossRatio[i]) >= 100) {
                    flag = false
                }
            }

            for (let i of this.state.liabilities) {
                if (i.amount === '' || !NumberRegex.test(i.amount)) {
                    flag = false
                }
                if (i.type === '') {
                    flag = false
                }
            }
            for (let i of this.state.assets) {
                if (i.amount === '' || !NumberRegex.test(i.amount)) {
                    flag = false
                }
                if (i.type === '') {
                    flag = false
                }
                if (i.badDebtR) {
                    if (i.badDebtR === '' || !NumberRegex.test(i.badDebtR)) {
                        flag = false
                    }
                    if (i.originalAmount === '' || !NumberRegex.test(i.originalAmount)) {
                        flag = false
                    }
                }
            }

            for (let i of this.state.questionPallete) {
                if (i.amount) {
                    if (i.amount === '' || !NumberRegex.test(i.amount)) {
                        flag = false
                    }
                }
                if (i.debitedAmount) {
                    if (i.debitedAmount === '' || !NumberRegex.test(i.debitedAmount)) {
                        flag = false
                    }
                }
                if (i.percent) {
                    if (i.percent === '' || !NumberRegex.test(i.percent) || parseFloat(i.percent) <= 0 || parseFloat(i.percent) >= 100) {
                        flag = false
                    }
                }
                if (i.badDebtR) {
                    if (i.badDebtR === '' || !NumberRegex.test(i.badDebtR)) {
                        flag = false
                    }
                }

            }

            if (flag === true) {
                this.setState({
                    editModeButtonChecker: false,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: true,
                    submitChecker: false
                })
            }

            if (this.state.totalAssetAmount !== this.state.totalLiabilityAmount) {
                this.setState({
                    submitChecker: false
                })
            } else {
                this.setState({
                    submitChecker: true
                })
            }
        }


        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {
            let tempArray = []
            let leftSideArray1 = []
            let rightSideArray1 = []
            let tempArray2 = []
            let leftSideArray2 = []
            let rightSideArray2 = []

            for (let i of this.state.questionPallete) {
                switch (i.type) {
                    case 'IncDecV':
                        if (i.amount > i.originalAmount) {
                            tempArray.push({
                                side: "right",
                                incdec: "increase",
                                name: i.name,
                                amount: parseFloat(i.amount) - parseFloat(i.originalAmount)
                            })
                        }
                        if (i.amount <= i.originalAmount) {
                            tempArray.push({
                                side: "left",
                                incdec: "decrease",
                                name: i.name,
                                amount: parseFloat(i.originalAmount) - parseFloat(i.amount)
                            })
                        }
                        break;
                    case 'IncDecP':
                        if (i.incdec === 'increase') {
                            tempArray.push({
                                side: "right",
                                incdec: "increase",
                                name: i.name,
                                amount: parseFloat(i.originalAmount) * parseFloat(i.percent) / 100
                            })
                        }
                        if (i.incdec === 'decrease') {
                            tempArray.push({
                                side: "left",
                                incdec: "decrease",
                                name: i.name,
                                amount: parseFloat(i.originalAmount) * parseFloat(i.percent) / 100
                            })
                        }
                        break;
                    case 'provision':
                        tempArray.push({
                            side: "left",
                            incdec: "increase",
                            name: i.name,
                            amount: parseFloat(i.badDebtR) - parseFloat(i.oldBadDebtR),
                            badDebtR: i.badDebtR
                        })
                        break;
                    case 'valueless':
                        tempArray.push({
                            side: "left",
                            incdec: "decrease",
                            name: i.name,
                            amount: parseFloat(i.originalAmount),
                        })
                        break;
                    case 'outstandingInterestOnLoan':
                        tempArray.push({
                            side: "left",
                            type: "Outstanding interest on loan",
                            name: "Outstanding interest on loan",
                            amount: parseFloat(i.amount),
                        })
                        break;
                    case 'lessPaid':
                        tempArray.push({
                            side: "right",
                            incdec: "decrease",
                            name: i.name,
                            amount: parseFloat(i.originalAmount) * parseFloat(i.percent) / 100,
                        })
                        break;
                    case 'unrecordedInvestment':
                        tempArray.push({
                            side: "right",
                            type: "Investment",
                            name: "Investment",
                            amount: i.amount
                        })
                        break;
                    case 'insurancePremium':
                        tempArray.push({
                            side: "right",
                            type: "Prepaid insurance premium",
                            name: "Prepaid insurance premium",
                            amount: i.amount
                        })
                        break;
                }
            }

            //ARRAY TABLE 1
            for (let i of tempArray) {
                if (i.side === 'left') {
                    leftSideArray1.push(i)
                }
                if (i.side === 'right') {
                    rightSideArray1.push(i)
                }
            }

            let leftSideTotalAmount = 0
            for (let i in tempArray) {
                if (tempArray[i].side === "left") {
                    leftSideTotalAmount += parseFloat(tempArray[i].amount)
                }
            }

            let rightSideTotalAmount = 0
            for (let i in tempArray) {
                if (tempArray[i].side === "right") {
                    rightSideTotalAmount += parseFloat(tempArray[i].amount)
                }
            }

            let partnersTotalLoss = leftSideTotalAmount - rightSideTotalAmount

            let profitLossAmount = []

            for (let i in this.state.initialProfitLossRatio) {
                profitLossAmount.push(partnersTotalLoss * parseFloat(this.state.initialProfitLossRatio[i]) / this.state.initialProfitLossRatio.reduce((a, b) => parseFloat(a) + parseFloat(b)))
            }

            let newCapitalAccount = []

            for (let i in this.state.capitalAccount) {
                newCapitalAccount.push(parseFloat(this.state.capitalAccount[i]) - profitLossAmount[i])
            }

            for (let i of this.state.questionPallete) {
                switch (i.type) {
                    case 'IncDecV':
                        if (i.amount > i.originalAmount) {
                            rightSideArray2.push({
                                name: i.name,
                                amount: parseFloat(i.originalAmount) + (parseFloat(i.amount) - parseFloat(i.originalAmount))
                            })
                        }
                        if (i.amount <= i.originalAmount) {
                            rightSideArray2.push({
                                name: i.name,
                                amount: parseFloat(i.originalAmount) - (parseFloat(i.originalAmount) - parseFloat(i.amount))
                            })
                        }
                        break;
                    case 'IncDecP':
                        if (i.incdec === 'increase') {
                            rightSideArray2.push({
                                name: i.name,
                                amount: parseFloat(i.originalAmount) + (parseFloat(i.originalAmount) * parseFloat(i.percent) / 100)
                            })
                        }
                        if (i.incdec === 'decrease') {
                            rightSideArray2.push({
                                name: i.name,
                                amount: parseFloat(i.originalAmount) - (parseFloat(i.originalAmount) * parseFloat(i.percent) / 100)
                            })
                        }
                        break;
                    case 'provision':
                        rightSideArray2.push({
                            name: i.name,
                            amount: parseFloat(i.originalAmount) - parseFloat(i.badDebtR),
                            badDebtR: i.badDebtR
                        })
                        break;
                    case 'valueless':
                        rightSideArray2.push({
                            caller: "none",
                            name: i.name,
                            amount: parseFloat(i.originalAmount),
                        })
                        break;
                    case 'outstandingInterestOnLoan':
                        leftSideArray2.push({
                            name: "Outstanding interest on loan",
                            amount: parseFloat(i.amount),
                        })
                        break;
                    case 'lessPaid':
                        leftSideArray2.push({
                            name: i.name,
                            amount: parseFloat(i.originalAmount) - (parseFloat(i.originalAmount) * parseFloat(i.percent) / 100),
                        })
                        break;
                    case 'unrecordedInvestment':
                        rightSideArray2.push({
                            name: "Investment",
                            amount: i.amount
                        })
                        break;
                    case 'insurancePremium':
                        rightSideArray2.push({
                            name: "Prepaid insurance premium",
                            amount: i.amount
                        })
                        break;
                }
            }

            for (let i of this.state.liabilities) {
                let flag = false
                for (let j of leftSideArray2) {
                    if (i.type === j.name) {
                        flag = true
                    }
                }
                if (flag === false) {
                    leftSideArray2.push({
                        name: i.type,
                        amount: i.amount
                    })
                }
            }
            for (let i of this.state.assets) {
                let flag = false
                for (let j of rightSideArray2) {
                    if (i.type === j.name) {
                        flag = true
                    }
                }
                if (flag === false) {
                    rightSideArray2.push({
                        name: i.type,
                        amount: i.amount
                    })
                }
            }
            for (let i in rightSideArray2) {
                if (rightSideArray2[i].caller) {
                    rightSideArray2.splice(i, 1)
                }
            }

            let finalPartners = JSON.parse(JSON.stringify(this.state.partners))
            let finalCapitalAccount = JSON.parse(JSON.stringify(newCapitalAccount))

            finalPartners.push(this.state.newPartner)
            finalCapitalAccount.push(this.state.newPartnerCapital)

            rightSideArray2.push({
                name: "cash",
                amount: this.state.newPartnerCapital
            })

            let rightSideArray2Total = 0
            for (let i of rightSideArray2) {
                rightSideArray2Total += i.amount
            }

            let dataObject = {
                // side: countL > countR ? "left" : "right",
                date: this.state.date,
                newDate: this.state.newDate,
                // partnersCapitalIndividual,
                // partnersCapital,
                profitLossAmount,
                leftSideTotalAmount,
                rightSideTotalAmount,
                AnswerPalette: tempArray,
                AnswerPaletteTotal: tempArray.reduce((a, b) => a + parseFloat(b.amount), 0) + parseFloat(this.state.newPartnerCapital) + profitLossAmount.reduce((a, b) => a + parseFloat(b), 0),
                partners: this.state.partners,
                capitalAccount: this.state.capitalAccount,
                leftSideArray1,
                rightSideArray1,
                newPartner: this.state.newPartner,
                newPartnerCapital: this.state.newPartnerCapital,
                newCapitalAccount,
                leftSideArray2,
                rightSideArray2,
                finalPartners,
                finalCapitalAccount,
                rightSideArray2Total

                // initialProfitLossRatio: this.state.initialProfitLossRatio,
                // leftSideArray,
                // rightSideArray
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addPartner() {
            this.state.partners.push("New Name")
            this.state.capitalAccount.push(10000)
            this.state.initialProfitLossRatio.push(1)

            this.state.editModepartners.push(true)
            this.state.editModecapitalAccount.push(true)
            this.state.editModeinitialProfitLossRatio.push(false)

            this.setState({
                editModeButton: true
            })

        }

        removePartner(event) {
            this.state.partners.splice(event, 1)
            this.state.capitalAccount.splice(event, 1)
            this.state.initialProfitLossRatio.splice(event, 1)

            this.state.editModepartners.splice(event, 1)
            this.state.editModecapitalAccount.splice(event, 1)
            this.state.editModeinitialProfitLossRatio.splice(event, 1)

            this.setState({})
        }

        addLiability() {
            this.state.liabilities.push({
                type: "Enter Text Here",
                amount: 10000
            })
            this.state.editModeliabilities.push({
                type: true,
                amount: true
            })
            this.setState({
                editModeButton: true
            }, () => { this.fillDetails() })
        }

        removeLiability(event) {
            this.state.liabilities.splice(event, 1)
            this.state.editModeliabilities.splice(event, 1)
            this.setState({}, () => { this.fillDetails() })
        }

        addAsset() {
            this.state.assets.push({
                type: "Enter Text Here",
                amount: 10000
            })
            this.state.editModeassets.push({
                type: true,
                amount: true
            })
            this.setState({
                editModeButton: true
            }, () => { this.fillDetails() })
        }

        addAssetWithBadDebtR() {
            this.state.assets.push({
                type: "Enter Text Here",
                originalAmount: 5000,
                badDebtR: 1000,
                amount: 4000
            })
            this.state.editModeassets.push({
                type: true,
                amount: true,
                originalAmount: false,
                badDebtR: false
            })
            this.setState({
                editModeButton: true
            }, () => { this.fillDetails() })
        }

        removeAsset(event) {
            this.state.assets.splice(event, 1)
            this.state.editModeassets.splice(event, 1)
            this.setState({}, () => { this.fillDetails() })
        }

        addQuestion() {
            let adderFlag = true

            let temporaryTemplate = {
                questionType: this.state.addQuestionType,
                date: parseFloat(this.state.tempDate),
                assetName: this.state.tempAssetName,
                name: this.state.tempName,
                payType: this.state.tempPayType,
                amount: this.state.tempAmount,
                due: this.state.tempDue,
                amountPercentage: this.state.tempAmountPercentage,
                discount: this.state.tempDiscount,
                tradeDiscount: this.state.tempTDiscount,
                cashDiscount: this.state.tempCDiscount,
                purchasedFrom: this.state.tempPurchasedFrom,
                billno: this.state.tempBillno,
                invoiceNo: this.state.tempInvoiceNo,
                debitNoteNo: this.state.tempDebitNoteNo,
                creditNoteNo: this.state.tempCreditNoteNo,
                credit: this.state.tempCredit,
                memo: this.state.tempMemo,
                extraSum: this.state.tempExtraSum,
                extraSumReason: this.state.tempExtraSumReason,
                cashPurchase: this.state.tempCashPurchase,
                cashSales: this.state.tempCashSales,
                dueDate: this.state.tempDueDate,
                rebate: this.state.tempRebate,
                returneeName: this.state.tempReturneeName,

                amountPaidImmediately: this.state.tempAmountPaidImmediately
            }
            let questionObject
            let editModequestionObject

            switch (temporaryTemplate.questionType) {
                case 'changeInValue':
                    questionObject = {
                        type: "IncDecV",
                        name: this.state.tempName,
                        amount: this.state.tempAmount
                    }

                    editModequestionObject = {
                        amount: false
                    }

                    for (let i of this.state.assets) {
                        if (questionObject.name === i.type) {
                            questionObject.originalAmount = i.amount
                        }
                    }
                    break;
                case 'IncDecP':
                    questionObject = {
                        type: "IncDecP",
                        name: this.state.tempName,
                        percent: this.state.tempAmountPercentage,
                        incdec: this.state.tempPayType
                    }
                    editModequestionObject = {
                        percent: false
                    }

                    for (let i of this.state.assets) {
                        if (questionObject.name === i.type) {
                            questionObject.originalAmount = i.amount
                        }
                    }
                    break;
                case 'provision':
                    questionObject = {
                        type: "provision",
                        name: this.state.tempName,
                        badDebtR: this.state.tempAmount,
                    }

                    editModequestionObject = {
                        badDebtR: false
                    }

                    for (let i of this.state.assets) {
                        if (questionObject.name === i.type) {
                            questionObject.originalAmount = i.amount
                            questionObject.oldBadDebtR = i.badDebtR
                        }
                    }
                    break;
                case 'valueless':
                    questionObject = {
                        type: "valueless",
                        name: this.state.tempName,
                    }

                    editModequestionObject = {
                    }
                    for (let i of this.state.assets) {
                        if (questionObject.name === i.type) {
                            questionObject.originalAmount = i.amount
                        }
                    }
                    break;
                case 'lessPaid':
                    questionObject = {
                        type: "lessPaid",
                        name: this.state.tempName,
                        percent: this.state.tempAmountPercentage,
                    }
                    editModequestionObject = {
                        percent: false
                    }

                    for (let i of this.state.assets) {
                        if (questionObject.name === i.type) {
                            questionObject.originalAmount = i.amount
                        }
                    }
                    break;
                case 'outstandingInterestOnLoan':
                    questionObject = {
                        type: "outstandingInterestOnLoan",
                        amount: this.state.tempAmount,
                    }
                    editModequestionObject = {
                        amount: false
                    }
                    break;
                case 'unrecordedInvestment':
                    questionObject = {
                        type: "unrecordedInvestment",
                        amount: this.state.tempAmount,
                    }
                    editModequestionObject = {
                        amount: false
                    }
                    break;
                case 'insurancePremium':
                    questionObject = {
                        type: "unrecordedInvestment",
                        debitedAmount: this.state.tempDue,
                        amount: this.state.tempAmount,
                    }
                    editModequestionObject = {
                        amount: false,
                        debitedAmount: false
                    }
                    break;

            }

            this.state.questionPallete.push(questionObject)
            this.state.editModequestionPallete.push(editModequestionObject)


            this.setState({})

            // console.log(JSON.stringify(questionObject, null, 4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPallete.splice(event, 1)
            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'partners':
                        this.state.editModepartners[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'initialPL':
                        this.state.editModeinitialProfitLossRatio[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'liabilitiesType':
                        this.state.editModeliabilities[customArray[1]].type = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'liabilitiesAmount':
                        this.state.editModeliabilities[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsType':
                        this.state.editModeassets[customArray[1]].type = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsAmount':
                        this.state.editModeassets[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsOriginalAmount':
                        this.state.editModeassets[customArray[1]].originalAmount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsBadDebtR':
                        this.state.editModeassets[customArray[1]].badDebtR = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'capitalAccount':
                        this.state.editModecapitalAccount[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;

                    case 'IncDecVAmount':
                        this.state.editModequestionPallete[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'IncDecP':
                        this.state.editModequestionPallete[customArray[1]].percent = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'provision':
                        this.state.editModequestionPallete[customArray[1]].badDebtR = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'outstandingInterestOnLoan':
                        this.state.editModequestionPallete[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'lessPaid':
                        this.state.editModequestionPallete[customArray[1]].percent = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'unrecordedInvestment':
                        this.state.editModequestionPallete[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'insurancePremiumDAmount':
                        this.state.editModequestionPallete[customArray[1]].debitedAmount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'insurancePremiumAmount':
                        this.state.editModequestionPallete[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'date':
                    this.state.editModedate = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'newDate':
                    this.state.editModenewDate = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'newPartner':
                    this.state.editModenewPartner = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'newPartnerCapital':
                    this.state.editModenewPartnerCapital = true
                    this.setState({
                        editModeButton: true
                    })
                    break
            }

        }

        editModeOff() {

            for (let i in this.state.editModepartners) {
                this.state.editModepartners[i] = false
                this.state.editModecapitalAccount[i] = false
                this.state.editModeinitialProfitLossRatio[i] = false
            }

            for (let i of this.state.editModeliabilities) {
                i.type = false
                i.amount = false
            }

            for (let i of this.state.editModeassets) {
                i.type = false
                i.amount = false
                if (i.badDebtR) {
                    i.badDebtR = false
                }
                if (i.originalAmount) {
                    i.originalAmount = false
                }
            }

            for (let i of this.state.editModequestionPallete) {
                if (i.amount) {
                    i.amount = false
                }
                if (i.badDebtR) {
                    i.badDebtR = false
                }
                if (i.percent) {
                    i.percent = false
                }
                if (i.debitedAmount) {
                    i.debitedAmount = false
                }
            }

            this.setState({
                editModedate: false,
                editModenewDate: false,
                editModenewPartner: false,
                editModenewPartnerCapital: false,
                editModeButton: false
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                    પેઢીના ભાગીદારો: <br /><br />

                        {this.state.partners.map((x, i) => (
                            <div>
                                ({i + 1})&nbsp;
                                {
                                    this.state.editModepartners[i] ?
                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["partners", i]} id='outlined-base' error={x === ''}
                                            value={x} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["partners", i]} onClick={this.buttonMaker.bind(this)}> {x}</a>
                                }

                                {this.state.partners.length > 2 ? <IconButton style={{ width: "3%", color: "red" }} onClick={this.removePartner.bind(this, i)} name="-">-</IconButton> : <span><br /><br /></span>}
                                {i + 1 === this.state.partners.length && <div align="right">
                                ભાગીદારો ઉમેરો
                                    <IconButton style={{ width: "3%" }} onClick={this.addPartner.bind(this)}>+</IconButton></div>}
                            </div>
                        ))}

                        તેમનો નફો-નુકશાન વહેંચણીનું પ્રમાણ&nbsp;
                        {this.state.initialProfitLossRatio.map((x, i) => (
                            <span>
                                {
                                    this.state.editModeinitialProfitLossRatio[i] ?
                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["initialPL", i]} id='outlined-base' error={x === '' || isNaN(x)}
                                            value={x} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["initialPL", i]} onClick={this.buttonMaker.bind(this)}> {x}</a>
                                }

                                {parseInt(i) !== this.state.initialProfitLossRatio.length - 1 && " : "}</span>
                        ))} છે.&nbsp;<br /><br />

                        તા.&nbsp;

                        {
                            this.state.editModedate ?
                                <TextField type="date" style={{ 'margin-top': "-15px", width: "15%" }} name="date" id='outlined-base' error={this.state.date === ''}
                                    value={this.state.date} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="date" onClick={this.buttonMaker.bind(this)}> {this.state.date}</a>
                        } ના રોજ તેમની પેઢીનું પાકું સરવૈયું નીચે મુજબ હતું : <br /><br />

                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px", fontSize:"20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >સંપાદિત મોડ બંધ કરો</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="center"><b>પાકું સરવૈયું</b></div>


                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>મૂડી-દેવાં (₹)</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>૨કમ (₹)</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>મિલકત-લેણાં</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>૨કમ (₹)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell align="left" style={{fontSize: "15px"}}>
                                            <b>મૂડી :</b><br />
                                            {this.state.partners.map((x, i) => (
                                                <span>&emsp;&emsp;{x}
                                                    <span style={{ float: "right" }}>
                                                        {
                                                            this.state.editModecapitalAccount[i] ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["capitalAccount", i]} id='outlined-base' error={this.state.capitalAccount[i] === '' || isNaN(this.state.capitalAccount[i])}
                                                                    value={this.state.capitalAccount[i]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["capitalAccount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.capitalAccount[i]}</a>
                                                        }
                                                    </span><br /></span>
                                            ))}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>{this.state.capitalAccount.reduce((a, b) => { return parseFloat(a) + parseFloat(b) })}</StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                    </TableRow>
                                    {this.state.liabilities.length >= this.state.assets.length ?
                                        this.state.liabilities.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="left" style={{fontSize: "15px"}}>
                                                    {
                                                        this.state.editModeliabilities[i].type ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["liabilitiesType", i]} id='outlined-base' error={this.state.liabilities[i].type === ''}
                                                                value={this.state.liabilities[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["liabilitiesType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].type}</a>
                                                    }&nbsp;
                                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeLiability.bind(this, i)} name="-">-</IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {
                                                        this.state.editModeliabilities[i].amount ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["liabilitiesAmount", i]} id='outlined-base' error={this.state.liabilities[i].amount === '' || isNaN(this.state.liabilities[i].amount)}
                                                                value={this.state.liabilities[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["liabilitiesAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].amount}</a>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{fontSize: "15px"}}>
                                                    {this.state.assets[i] !== undefined && <span>
                                                        {
                                                            this.state.assets[i].badDebtR === undefined ?
                                                                <span>
                                                                    {
                                                                        this.state.editModeassets[i].type ?
                                                                            <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["assetsType", i]} id='outlined-base' error={this.state.assets[i].type === ''}
                                                                                value={this.state.assets[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                            <a className='txt' name={["assetsType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].type}</a>
                                                                    }&nbsp;
                                                                </span> :
                                                                <span>
                                                                    {
                                                                        this.state.editModeassets[i].type ?
                                                                            <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["assetsType", i]} id='outlined-base' error={this.state.assets[i].type === ''}
                                                                                value={this.state.assets[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                            <a className='txt' name={["assetsType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].type}</a>
                                                                    }&nbsp;
                                                                    <span style={{ float: "right" }}>
                                                                        {
                                                                            this.state.editModeassets[i].originalAmount ?
                                                                                <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsOriginalAmount", i]} id='outlined-base' error={this.state.assets[i].originalAmount === '' || isNaN(this.state.assets[i].originalAmount)}
                                                                                    value={this.state.assets[i].originalAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                                <a className='txt' name={["assetsOriginalAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].originalAmount}</a>
                                                                        }
                                                                    </span>

                                                                    <br /><br />
                                                                    - Bad debt reserve
                                                                    <span style={{ float: "right" }}>
                                                                        {
                                                                            this.state.editModeassets[i].badDebtR ?
                                                                                <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsBadDebtR", i]} id='outlined-base' error={this.state.assets[i].badDebtR === '' || isNaN(this.state.assets[i].badDebtR)}
                                                                                    value={this.state.assets[i].badDebtR} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                                <a className='txt' name={["assetsBadDebtR", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].badDebtR}</a>
                                                                        }
                                                                    </span>
                                                                </span>
                                                        }
                                                    </span>}
                                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeAsset.bind(this, i)} name="-">-</IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell align="right" >
                                                    {this.state.assets[i] !== undefined && <span>
                                                        {
                                                            this.state.assets[i].badDebtR === undefined ?
                                                                <span>
                                                                    {
                                                                        this.state.editModeassets[i].amount ?
                                                                            <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsAmount", i]} id='outlined-base' error={this.state.assets[i].amount === '' || isNaN(this.state.assets[i].amount)}
                                                                                value={this.state.assets[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                            <a className='txt' name={["assetsAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].amount}</a>
                                                                    }
                                                                </span> :
                                                                <span>
                                                                    <br /><br />
                                                                    {this.state.assets[i].amount}
                                                                </span>
                                                        }
                                                    </span>}
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                        :
                                        this.state.assets.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="left" style={{fontSize: "15px"}}>
                                                    {this.state.liabilities[i] !== undefined && <span>
                                                        {
                                                            this.state.editModeliabilities[i].type ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["liabilitiesType", i]} id='outlined-base' error={this.state.liabilities[i].type === ''}
                                                                    value={this.state.liabilities[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["liabilitiesType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].type}</a>
                                                        }&nbsp;
                                                        <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeLiability.bind(this, i)} name="-">-</IconButton>
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{fontSize: "15px"}}>
                                                    {this.state.liabilities[i] !== undefined && <span>
                                                        {
                                                            this.state.editModeliabilities[i].amount ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["liabilitiesAmount", i]} id='outlined-base' error={this.state.liabilities[i].amount === '' || isNaN(this.state.liabilities[i].amount)}
                                                                    value={this.state.liabilities[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["liabilitiesAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].amount}</a>
                                                        }
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{fontSize: "15px"}}>
                                                    {
                                                        this.state.assets[i].badDebtR === undefined ?
                                                            <span>
                                                                {
                                                                    this.state.editModeassets[i].type ?
                                                                        <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["assetsType", i]} id='outlined-base' error={this.state.assets[i].type === ''}
                                                                            value={this.state.assets[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                        <a className='txt' name={["assetsType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].type}</a>
                                                                }&nbsp;
                                                            </span> :
                                                            <span>
                                                                {
                                                                    this.state.editModeassets[i].type ?
                                                                        <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["assetsType", i]} id='outlined-base' error={this.state.assets[i].type === ''}
                                                                            value={this.state.assets[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                        <a className='txt' name={["assetsType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].type}</a>
                                                                }&nbsp;
                                                                <span style={{ float: "right" }}>
                                                                    {
                                                                        this.state.editModeassets[i].originalAmount ?
                                                                            <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsOriginalAmount", i]} id='outlined-base' error={this.state.assets[i].originalAmount === '' || isNaN(this.state.assets[i].originalAmount)}
                                                                                value={this.state.assets[i].originalAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                            <a className='txt' name={["assetsOriginalAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].originalAmount}</a>
                                                                    }
                                                                </span>

                                                                <br /><br />
                                                                - ઘાલખાધ અનામત
                                                                <span style={{ float: "right" }}>
                                                                    {
                                                                        this.state.editModeassets[i].badDebtR ?
                                                                            <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsBadDebtR", i]} id='outlined-base' error={this.state.assets[i].badDebtR === '' || isNaN(this.state.assets[i].badDebtR)}
                                                                                value={this.state.assets[i].badDebtR} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                            <a className='txt' name={["assetsBadDebtR", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].badDebtR}</a>
                                                                    }
                                                                </span>
                                                            </span>
                                                    }
                                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeAsset.bind(this, i)} name="-">-</IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {
                                                        this.state.assets[i].badDebtR === undefined ?
                                                            <span>
                                                                {
                                                                    this.state.editModeassets[i].amount ?
                                                                        <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsAmount", i]} id='outlined-base' error={this.state.assets[i].amount === '' || isNaN(this.state.assets[i].amount)}
                                                                            value={this.state.assets[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                        <a className='txt' name={["assetsAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].amount}</a>
                                                                }
                                                            </span> :
                                                            <span>
                                                                <br /><br />
                                                                {this.state.assets[i].amount}
                                                            </span>
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                    }
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align='right'><b>{this.state.totalAssetAmount}</b></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align='right'><b>{this.state.totalLiabilityAmount}</b></StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align='center' style={{fontSize: "15px"}}><IconButton style={{ width: "3%", height: "10px" }} onClick={this.addLiability.bind(this)}>+</IconButton> મૂડી ઉમેરો</StyledTableCell>
                                        <StyledTableCell></StyledTableCell >
                                        <StyledTableCell align='center' style={{fontSize: "15px"}}>
                                            <IconButton style={{ width: "3%", height: "10px" }} onClick={this.addAsset.bind(this)}>+</IconButton> મિલકત ઉમેરો<br />
                                            <IconButton style={{ width: "3%", height: "10px" }} onClick={this.addAssetWithBadDebtR.bind(this)}>+</IconButton> ઘાલખાધ અનામત સાથે મિલકત ઉમેરો
                                        </StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />

                        તા.&nbsp;
                        {
                            this.state.editModenewDate ?
                                <TextField type="date" style={{ 'margin-top': "-15px", width: "15%" }} name="newDate" id='outlined-base' error={this.state.newDate === ''}
                                    value={this.state.newDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="newDate" onClick={this.buttonMaker.bind(this)}> {this.state.newDate}</a>
                        }ના રોજ તેઓએ&nbsp;

                        &nbsp;
                        {
                            this.state.editModenewPartner ?
                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name="newPartner" id='outlined-base' error={this.state.newPartner === ''}
                                    value={this.state.newPartner} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="newPartner" onClick={this.buttonMaker.bind(this)}> {this.state.newPartner}</a>
                        }ને નવા ભાગીદાર તરીકે પેઢીમાં દાખલ કરવાનું નક્કી કર્યું.&nbsp;

                        

                        {this.state.newPartner} મૂડી તરીકે ₹&nbsp;
                        {
                            this.state.editModenewPartnerCapital ?
                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name="newPartnerCapital" id='outlined-base' error={this.state.newPartnerCapital === '' || isNaN(this.state.newPartnerCapital)}
                                    value={this.state.newPartnerCapital} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="newPartnerCapital" onClick={this.buttonMaker.bind(this)}> {this.state.newPartnerCapital}</a>
                        }&nbsp;

                        લાવે છે. ભાગીદારોએ મિલકત અને દેવાંનું પુન:મૂલ્યાંકન નીચે મુજબ કર્યું :

                        <br /><br />

                        <div label="Questions">
                            {this.state.questionPallete.map((x, i) => (
                                <span>({i + 1})&nbsp;
                                    {x.type === 'IncDecV' &&
                                        <span>
                                            {x.originalAmount < x.amount &&
                                                <span>
                                                    {x.name} value is increased upto ₹&nbsp;
                                                    {
                                                        this.state.editModequestionPallete[i].amount ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["IncDecVAmount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                                value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["IncDecVAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                                    }.
                                                </span>
                                            }
                                            {x.originalAmount >= x.amount &&
                                                <span>
                                                    {x.name} ની કિંમત ₹&nbsp;
                                                    {
                                                        this.state.editModequestionPallete[i].amount ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["IncDecVAmount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                                value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["IncDecVAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                                    } ગણવી.
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.type === 'IncDecP' &&
                                        <span>
                                            {x.incdec === 'increase' &&
                                                <span>
                                                    {x.name} ની કિંમતમાં&nbsp;

                                                    {
                                                        this.state.editModequestionPallete[i].percent ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["IncDecP", i]} id='outlined-base' error={x.percent === '' || isNaN(x.percent) || x.percent < 0 || x.percent > 100}
                                                                value={x.percent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["IncDecP", i]} onClick={this.buttonMaker.bind(this)}> {x.percent}</a>
                                                    } % વધારો કરવા.
                                                </span>
                                            }
                                            {x.incdec === 'decrease' &&
                                                <span>
                                                    {x.name} ની કિંમતમાં&nbsp;

                                                    {
                                                        this.state.editModequestionPallete[i].percent ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["IncDecP", i]} id='outlined-base' error={x.percent === '' || isNaN(x.percent) || x.percent < 0 || x.percent > 100}
                                                                value={x.percent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["IncDecP", i]} onClick={this.buttonMaker.bind(this)}> {x.percent}</a>
                                                    } % ઘટાડો કરવો.
                                                </span>
                                            }
                                        </span>
                                    }
                                    {x.type === 'provision' &&
                                        <span>
                                            {x.name} પર ઘાલખાધ અનામત રાખવી ₹&nbsp;

                                            {
                                                this.state.editModequestionPallete[i].badDebtR ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["provision", i]} id='outlined-base' error={x.badDebtR === '' || isNaN(x.badDebtR)}
                                                        value={x.badDebtR} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["provision", i]} onClick={this.buttonMaker.bind(this)}> {x.badDebtR}</a>
                                            }
                                        </span>
                                    }
                                    {x.type === 'valueless' &&
                                        <span>
                                            {x.name} ની કિંમત શૂન્ય ગણવી.
                                        </span>
                                    }
                                    {x.type === 'outstandingInterestOnLoan' &&
                                        <span>
                                            લોન પર ₹&nbsp;
                                            {
                                                this.state.editModequestionPallete[i].amount ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["outstandingInterestOnLoan", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                        value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["outstandingInterestOnLoan", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                            } વ્યાજ ચૂકવવાનું બાકી છે.
                                        </span>
                                    }
                                    {x.type === 'lessPaid' &&
                                        <span>
                                            {x.name} માં&nbsp;

                                            {
                                                this.state.editModequestionPallete[i].percent ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["lessPaid", i]} id='outlined-base' error={x.percent === '' || isNaN(x.percent) || x.percent < 0 || x.percent > 100}
                                                        value={x.percent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["lessPaid", i]} onClick={this.buttonMaker.bind(this)}> {x.percent}</a>
                                            } % ઓછા ચૂકવવા પડશે.
                                        </span>
                                    }
                                    {x.type === 'unrecordedInvestment' &&
                                        <span>
                                            ચોપડે નહિ નોંધેલ રોકાણોનું મૂલ્ય ₹&nbsp;
                                            {
                                                this.state.editModequestionPallete[i].amount ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["unrecordedInvestment", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                        value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["unrecordedInvestment", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                            } નક્કી કરવામાં આવ્યું.
                                        </span>
                                    }
                                    {x.type === 'insurancePremium' &&
                                        <span>
                                            નફા-નુકસાન ખાતે ઉધારેલ વીમા પ્રીમિયમ ₹&nbsp;

                                            {
                                                this.state.editModequestionPallete[i].debitedAmount ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["insurancePremiumDAmount", i]} id='outlined-base' error={x.debitedAmount === '' || isNaN(x.debitedAmount)}
                                                        value={x.debitedAmount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["insurancePremiumDAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.debitedAmount}</a>
                                            }

                                            માંથી ₹&nbsp;
                                            {
                                                this.state.editModequestionPallete[i].debitedAmount ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["insurancePremiumAmount", i]} id='outlined-base' error={x.amount === '' || isNaN(x.amount)}
                                                        value={x.amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["insurancePremiumAmount", i]} onClick={this.buttonMaker.bind(this)}> {x.amount}</a>
                                            }&nbsp;

                                            આવતા વર્ષ માટેના છે.
                                        </span>
                                    }

                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeQuestion.bind(this, i)} name="-">-</IconButton>

                                    <br /><br />
                                </span>
                            ))}
                        </div>


                        <br /><br />
                        <div label="addQuestionInputs">
                            નવો પ્રશ્ન ઉમેરો :<br /><br /><br />
                            પ્રશ્નનો પ્રકાર: 
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">પ્રશ્ન</InputLabel>
                                <Select
                                    labelId="question"
                                    id="question"
                                    value={this.state.addQuestionType}
                                    label="Question"
                                    onChange={this.questionTypeChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"none"}>None</MenuItem>
                                    <MenuItem value={"changeInValue"}>મિલકતમાં વધારો/ઘટાડો</MenuItem>
                                    <MenuItem value={"IncDecP"}>મિલકતમાં % વધારો/ઘટાડો</MenuItem>
                                    <MenuItem value={"provision"}>ઘાલખાધ અનામત રાખવી</MenuItem>
                                    <MenuItem value={"valueless"}>મૂલ્યહીન મિલકત</MenuItem>
                                    <MenuItem value={"lessPaid"}>મૂડીની ઓછી ચૂકવણી કરવી</MenuItem>
                                    <MenuItem value={"outstandingInterestOnLoan"}>લોન પર વ્યાજ</MenuItem>
                                    <MenuItem value={"unrecordedInvestment"}>રોકાણ</MenuItem>
                                    <MenuItem value={"insurancePremium"}>વીમા પ્રીમિયમ</MenuItem>
                                </Select>
                            </FormControl><br /><br /><br />


                            {this.state.addQuestionType === 'changeInValue' &&
                                <span>
                                    બદલાયેલ રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    મિલકતનું નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.assets.map((y, j) => (
                                                this.state.assets[j].badDebtR === undefined && <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }

                            {this.state.addQuestionType === 'IncDecP' &&
                                <span>
                                    બદલાયેલ રકમની ટકાવારી દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPercentage" error={this.state.tempAmountPercentage === '' || isNaN(this.state.tempAmountPercentage) || this.state.tempAmountPercentage < 0 || this.state.tempAmountPercentage > 100}
                                        placeholder="રકમની ટકાવારી દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    ફેરફારનો પ્રકાર દાખલ કરો:
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">પ્રકાર*</InputLabel>
                                        <Select
                                            labelId="Type"
                                            id="Type"
                                            error={this.state.tempPayType === ''}
                                            value={this.state.tempPayType}
                                            label="Type"
                                            onChange={this.addTypeChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >

                                            <MenuItem value={"increase"}>વધારો</MenuItem>
                                            <MenuItem value={"decrease"}>ઘટાડો</MenuItem>
                                        </Select>
                                    </FormControl><br /><br />

                                    મિલકતનું નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.assets.map((y, j) => (
                                                this.state.assets[j].badDebtR === undefined && <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }

                            {this.state.addQuestionType === 'provision' &&
                                <span>
                                    જોગવાઈની રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    મિલકતનું નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.assets.map((y, j) => (
                                                this.state.assets[j].badDebtR !== undefined && <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }

                            {this.state.addQuestionType === 'valueless' &&
                                <span>
                                    મિલકતનું નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.assets.map((y, j) => (
                                                this.state.assets[j].badDebtR === undefined && <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }

                            {this.state.addQuestionType === 'lessPaid' &&
                                <span>
                                    બદલાયેલ રકમની ટકાવારી દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amountPercentage" error={this.state.tempAmountPercentage === '' || isNaN(this.state.tempAmountPercentage) || this.state.tempAmountPercentage < 0 || this.state.tempAmountPercentage > 100}
                                        placeholder="રકમની ટકાવારી દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    મૂડીનું નામ દાખલ કરો:&nbsp;
                                    <FormControl style={{ m: 1, width: "30%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                        <Select
                                            labelId="Name"
                                            id="Name"
                                            error={this.state.tempName === ''}
                                            value={this.state.tempName}
                                            label="Name"
                                            onChange={this.addNameChange.bind(this)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.liabilities.map((y, j) => (
                                                <MenuItem value={y.type}>{y.type}</MenuItem>
                                            ))}
                                            {/* <MenuItem value={"purchaseReturns"}>Purchase Returns</MenuItem> */}

                                        </Select>
                                    </FormControl><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'outstandingInterestOnLoan' &&
                                <span>
                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'unrecordedInvestment' &&
                                <span>
                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }
                            {this.state.addQuestionType === 'insurancePremium' &&
                                <span>
                                    નફા-નુકસાન ખાતામાં ડેબિટ થયેલ પ્રીમિયમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="due" error={this.state.tempDue === '' || isNaN(this.state.tempDue)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                                    રકમ દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                        placeholder="રકમ દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />
                                </span>
                            }

                            {this.state.addQuestionType !== 'none' && <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%", fontSize:"20px" }} onClick={this.addQuestion.bind(this)}>નવો પ્રશ્ન ઉમેરો</AddButton>}
                        </div>

                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px", fontSize:"20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >સંપાદિત મોડ બંધ કરો</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો/છુપાવો</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>જવાબ. :</b><br />
                            <div align="center"><b>આમનોંધ</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align='center' style={{fontSize: "20px"}}>ક્રમ</StyledTableCell>
                                            <StyledTableCell align='center' style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align='center' style={{fontSize: "20px"}}>ઉધાર (₹)</StyledTableCell>
                                            <StyledTableCell align='center' style={{fontSize: "20px"}}>જમા (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].AnswerPalette.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align='center' style={{ verticalAlign: "top" }}>
                                                    ({i + 1})
                                                </StyledTableCell>
                                                <StyledTableCell align='left' style={{fontSize: "15px"}}>
                                                    {x.side === 'left' &&
                                                        <span>
                                                            પુન:મૂલ્યાંકન ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                            &emsp;&emsp;તે {x.name} ખાતે
                                                        </span>
                                                    }
                                                    {x.side === 'right' &&
                                                        <span>
                                                            {x.name} ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                            &emsp;&emsp;તે પુન:મૂલ્યાંકન ખાતે
                                                        </span>
                                                    }<br />
                                                    [
                                                    {x.side === "left" && !x.type &&
                                                        <span>
                                                            બાજે : {x.name}માં {x.incdec} પુન:મૂલ્યાંકન ખાતે લઈ ગયા તેના.
                                                        </span>
                                                    }
                                                    {x.side === "left" && x.type &&
                                                        <span>
                                                            બાજે : {x.name} પુન:મૂલ્યાંકન ખાતે લઈ ગયા તેના.
                                                        </span>
                                                    }
                                                    {x.side === "right" && !x.type &&
                                                        <span>
                                                            બાજે : {x.name}માં {x.incdec} પુન:મૂલ્યાંકન ખાતે લઈ ગયા તેના.
                                                        </span>
                                                    }
                                                    {x.side === "right" && x.type &&
                                                        <span>
                                                            બાજે : નહિ નોંધેલ {x.name} પુન:મૂલ્યાંકન ખાતે લઈ ગયા તેના.
                                                        </span>
                                                    }                                                   
                                                    ]
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    {x.amount}
                                                </StyledTableCell>
                                                <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                    <br />
                                                    {x.amount}
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top" }}>
                                                ({data[chartState.selected].AnswerPalette.length + 1})
                                            </StyledTableCell>
                                            <StyledTableCell align="left" style={{fontSize: "15px"}}>
                                                {data[chartState.selected].partners.map((x, i) => (
                                                    <span>
                                                        {x}ના મૂડી ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                    </span>
                                                ))}
                                                &emsp;&emsp;તે પુન:મૂલ્યાંકન ખાતે<br />
                                                [પુન:મૂલ્યાંકન ખાતાની ખોટ જૂના ભાગીદારો વચ્ચે જૂના નફા-નુકસાનના પ્રમાણમાં વહેંચી તેના.]
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].profitLossAmount.map((x, i) => (
                                                    <span>
                                                        {x}<br />
                                                    </span>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].profitLossAmount.map((x, i) => (
                                                    <span>
                                                        <br />
                                                    </span>
                                                ))}
                                                {data[chartState.selected].profitLossAmount.reduce((a, b) => parseFloat(a) + parseFloat(b))}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{ verticalAlign: "top" }}>
                                                ({data[chartState.selected].AnswerPalette.length + 2})
                                            </StyledTableCell>
                                            <StyledTableCell align="left" style={{fontSize: "15px"}}>
                                                રોકડ ખાતે <span style={{ float: "right" }}>ઉ</span><br />
                                                &emsp;&emsp;તે {data[chartState.selected].newPartner}ના મૂડી ખાતે<br />
                                                [બા.જે. : ₹ {data[chartState.selected].newPartnerCapital} નવો ભાગીદાર મૂડી પેટે રોકડમાં લાગ્યા તેના.]
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                {data[chartState.selected].newPartnerCapital}
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "top" }}>
                                                <br />
                                                {data[chartState.selected].newPartnerCapital}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align="right" style={{fontSize: "15px"}}><b>ફુલ સરવાળો</b></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].AnswerPaletteTotal}</b></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].AnswerPaletteTotal}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align="center"><b>પુન:મૂલ્યાંકન ખાતું</b></div><br />
                            <span style={{ float: "left" }}>ઉધાર</span><span style={{ float: "right" }}>જમા</span><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align='center' style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align='center' style={{fontSize: "20px"}}>૨કમ (₹)</StyledTableCell>
                                            <StyledTableCell align='center' style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align='center' style={{fontSize: "20px"}}>૨કમ (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].leftSideArray1.length >= data[chartState.selected].rightSideArray1.length ?
                                            data[chartState.selected].leftSideArray1.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell align='left' style={{fontSize: "15px"}}>
                                                        {data[chartState.selected].leftSideArray1[i].name} ખાતે<br />
                                                        {data[chartState.selected].leftSideArray1[i].badDebtR &&
                                                            <span>(નવી ઘાલખાધ અનામત ₹ {data[chartState.selected].leftSideArray1[i].badDebtR} - જૂની ઘાલખાધ અનામત ₹ {data[chartState.selected].leftSideArray1[i].badDebtR - data[chartState.selected].leftSideArray1[i].amount})</span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                        {data[chartState.selected].leftSideArray1[i].amount}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='left' style={{fontSize: "15px"}}>
                                                        {data[chartState.selected].rightSideArray1[i] &&
                                                            <span>
                                                                {data[chartState.selected].rightSideArray1[i].name} ખાતે<br />
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        {data[chartState.selected].rightSideArray1[i] &&
                                                            <span>
                                                                {data[chartState.selected].rightSideArray1[i].amount}<br />
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                </TableRow>
                                            )) :
                                            data[chartState.selected].rightSideArray1.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell align='left'>
                                                        {data[chartState.selected].leftSideArray1[i] &&
                                                            <span>
                                                                To {data[chartState.selected].leftSideArray1[i].name} A/c<br />
                                                                {data[chartState.selected].leftSideArray1[i].badDebtR &&
                                                                    <span>(New bad debt reserve ₹ {data[chartState.selected].leftSideArray1[i].badDebtR} - Old bad debt reserve ₹ {data[chartState.selected].leftSideArray1[i].badDebtR - data[chartState.selected].leftSideArray1[i].amount})</span>
                                                                }
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right' style={{ verticalAlign: "top" }}>
                                                        {data[chartState.selected].leftSideArray1[i] &&
                                                            <span>
                                                                {data[chartState.selected].leftSideArray1[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align='left'>
                                                        By {data[chartState.selected].rightSideArray1[i].name} A/c<br />
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        By {data[chartState.selected].rightSideArray1[i].amount} A/c<br />
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))
                                        }
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize: "15px"}}>
                                                ખોટ - જૂના ભાગીદારોના મૂડી ખાતે<br />
                                                {data[chartState.selected].partners.map((x, i) => (
                                                    <span>&emsp;&emsp;{x} <span style={{ float: "right" }}>{data[chartState.selected].profitLossAmount[i]}</span><br /></span>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>
                                                {data[chartState.selected].profitLossAmount.reduce((a, b) => a + parseFloat(b))}
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].leftSideArray1.reduce((a, b) => (a + parseFloat(b.amount)), 0)}</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].leftSideArray1.reduce((a, b) => (a + parseFloat(b.amount)), 0)}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align="center"><b>ભાગીદારોનાં મૂડી ખાતાં</b></div><br />
                            <span style={{ float: "left" }}>ઉધાર</span><span style={{ float: "right" }}>જમા</span><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                            {data[chartState.selected].partners.map((x, i) => (
                                                <StyledTableCell align="center">{x} (₹)</StyledTableCell>
                                            ))}
                                            <StyledTableCell align="center">{data[chartState.selected].newPartner} (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>વિગત</StyledTableCell>
                                            {data[chartState.selected].partners.map((x, i) => (
                                                <StyledTableCell align="center">{x} (₹)</StyledTableCell>
                                            ))}
                                            <StyledTableCell align="center">{data[chartState.selected].newPartner} (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align='left' style={{fontSize: "15px"}}>પુન:મૂલ્યાંકન ખાતે</StyledTableCell>
                                            {data[chartState.selected].profitLossAmount.map((x, i) => (
                                                <StyledTableCell align='right'>{x}</StyledTableCell>
                                            ))}
                                            <StyledTableCell align='right'>-</StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize: "15px"}}>બાકી આગળ લવ્યા</StyledTableCell>
                                            {data[chartState.selected].capitalAccount.map((x, i) => (
                                                <StyledTableCell align='right'>{x}</StyledTableCell>
                                            ))}
                                            <StyledTableCell align='right'>-</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align='left' style={{fontSize: "15px"}}>બા. આ. લઈ ગયા</StyledTableCell>
                                            {data[chartState.selected].newCapitalAccount.map((x, i) => (
                                                <StyledTableCell align='right'>{x}</StyledTableCell>
                                            ))}
                                            <StyledTableCell align='right'>{data[chartState.selected].newPartnerCapital}</StyledTableCell>
                                            <StyledTableCell align='left' style={{fontSize: "15px"}}>રોકડ ખાતે</StyledTableCell>
                                            {data[chartState.selected].profitLossAmount.map((x, i) => (
                                                <StyledTableCell align='right'>-</StyledTableCell>
                                            ))}
                                            <StyledTableCell align='right'>{data[chartState.selected].newPartnerCapital}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align='left'></StyledTableCell>
                                            {data[chartState.selected].capitalAccount.map((x, i) => (
                                                <StyledTableCell align='right'><b>{x}</b></StyledTableCell>
                                            ))}
                                            <StyledTableCell align='right'><b>{data[chartState.selected].newPartnerCapital}</b></StyledTableCell>
                                            <StyledTableCell align='left'></StyledTableCell>
                                            {data[chartState.selected].capitalAccount.map((x, i) => (
                                                <StyledTableCell align='right'><b>{x}</b></StyledTableCell>
                                            ))}
                                            <StyledTableCell align='right'><b>{data[chartState.selected].newPartnerCapital}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <div align="center"><b>પ્રવેશ બાદનું પાકું સરવૈયું</b></div><br />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>મૂડી-દેવાં</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>મિલકત-લેણાં</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize: "20px"}}>રકમ (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell align="left" style={{fontSize: "15px"}}>
                                                <b>મૂડી :</b><br />
                                                {data[chartState.selected].finalPartners.map((x, i) => (
                                                    <span>&emsp;&emsp;{x}  <span style={{ float: "right" }}>{data[chartState.selected].finalCapitalAccount[i]}</span><br /></span>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>
                                                {data[chartState.selected].finalCapitalAccount.reduce((a, b) => (a + parseFloat(b)), 0)}
                                            </StyledTableCell>
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="center"></StyledTableCell>
                                        </TableRow>
                                        {data[chartState.selected].leftSideArray2.length >= data[chartState.selected].rightSideArray2.length ?
                                            data[chartState.selected].leftSideArray2.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell align='left' style={{fontSize: "15px"}}>
                                                        <span>
                                                            {data[chartState.selected].leftSideArray2[i].name}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        <span>
                                                            {data[chartState.selected].leftSideArray2[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='left' style={{fontSize: "15px"}}>
                                                        {data[chartState.selected].rightSideArray2[i] && <span>
                                                            {data[chartState.selected].rightSideArray2[i].badDebtR ?
                                                                <span>
                                                                    {data[chartState.selected].rightSideArray2[i].name} <span style={{ float: "right" }}>{parseFloat(data[chartState.selected].rightSideArray2[i].amount) + parseFloat(data[chartState.selected].rightSideArray2[i].badDebtR)}</span><br />
                                                                    - ઘાલખાધ અનામત <span style={{ float: "right" }}>{data[chartState.selected].rightSideArray2[i].badDebtR}</span>
                                                                </span> :
                                                                <span>
                                                                    {data[chartState.selected].rightSideArray2[i].name}
                                                                </span>
                                                            }
                                                        </span>}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        {data[chartState.selected].rightSideArray2[i] && <span>
                                                            {data[chartState.selected].rightSideArray2[i].badDebtR ?
                                                                <span>
                                                                    <br />
                                                                    {data[chartState.selected].rightSideArray2[i].amount}
                                                                </span> :
                                                                <span>
                                                                    {data[chartState.selected].rightSideArray2[i].amount}
                                                                </span>
                                                            }
                                                        </span>}
                                                    </StyledTableCell>
                                                </TableRow>
                                            )) :
                                            data[chartState.selected].rightSideArray2.map((x, i) => (
                                                <TableRow>
                                                    <StyledTableCell align='left' style={{fontSize: "15px"}}>
                                                        {data[chartState.selected].leftSideArray2[i] && <span>
                                                            {data[chartState.selected].leftSideArray2[i].name}
                                                        </span>}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        {data[chartState.selected].leftSideArray2[i] && <span>
                                                            {data[chartState.selected].leftSideArray2[i].amount}
                                                        </span>}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='left' style={{fontSize: "15px"}}>
                                                        <span>
                                                            {data[chartState.selected].rightSideArray2[i].badDebtR ?
                                                                <span>
                                                                    {data[chartState.selected].rightSideArray2[i].name} <span style={{ float: "right" }}>{parseFloat(data[chartState.selected].rightSideArray2[i].amount) + parseFloat(data[chartState.selected].rightSideArray2[i].badDebtR)}</span><br />
                                                                    - ઘાલખાધ અનામત <span style={{ float: "right" }}>{data[chartState.selected].rightSideArray2[i].badDebtR}</span>
                                                                </span> :
                                                                <span>
                                                                    {data[chartState.selected].rightSideArray2[i].name}
                                                                </span>
                                                            }
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='right'>
                                                        <span>
                                                            {data[chartState.selected].rightSideArray2[i].badDebtR ?
                                                                <span>
                                                                    <br />
                                                                    {data[chartState.selected].rightSideArray2[i].amount}
                                                                </span> :
                                                                <span>
                                                                    {data[chartState.selected].rightSideArray2[i].amount}
                                                                </span>
                                                            }
                                                        </span>
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))
                                        }
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].rightSideArray2Total}</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].rightSideArray2Total}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

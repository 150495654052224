import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";

export default function ComparativeProfitLossStatement() {

    const data = [{
        "item": "ખાદ્ય ચીજવસ્તુ",
        "list": [
            {
                "indexNumber": 134.8,
                "baseIndexNumber": 134.8
            },
            {
                "indexNumber": 115.28,
                "baseIndexNumber": 155.4
            },
            {
                "indexNumber": 115.57,
                "baseIndexNumber": 179.6
            },
            {
                "indexNumber": 107.29,
                "baseIndexNumber": 192.69
            },
            {
                "indexNumber": 109.91,
                "baseIndexNumber": 211.79
            },
            {
                "indexNumber": 112.8,
                "baseIndexNumber": 238.9
            },
            {
                "indexNumber": 106.24,
                "baseIndexNumber": 253.81
            },
            {
                "indexNumber": 102.48,
                "baseIndexNumber": 260.1
            }
        ],
        "dates": [
            "2008-09",
            "2009-10",
            "2010-11",
            "2011-12",
            "2012-13",
            "2013-14",
            "2014-15",
            "2015-16",
            "2016-17",
            "2017-18"
        ]
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)
    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                itemName: "ખાદ્ય ચીજવસ્તુ",
                startingDate: 2008,
                totalDates: ["2008-09", "2009-10", "2010-11", "2011-12", "2012-13", "2013-14", "2014-15", "2015-16", "2016-17", "2017-18"],
                students: [
                    {
                        indexNumber: 134.8,
                    }, {
                        indexNumber: 115.28,
                    }, {
                        indexNumber: 115.57,
                    }, {
                        indexNumber: 107.29,
                    }, {
                        indexNumber: 109.91,
                    }, {
                        indexNumber: 112.80,
                    }, {
                        indexNumber: 106.24
                    }, {
                        indexNumber: 102.48
                    }
                ],

                editModeitemName: false,
                editModestandard: {
                    first: false,
                    last: false
                },
                editModestartingDate: false,
                editModestudents: [
                    {
                        indexNumber: false
                    }, {
                        indexNumber: false
                    }, {
                        indexNumber: false
                    }, {
                        indexNumber: false
                    }, {
                        indexNumber: false
                    }, {
                        indexNumber: false
                    }, {
                        indexNumber: false
                    }, {
                        indexNumber: false
                    }
                ],
                editModedensityPopulation: false,

                solutionShow: false,
                // tableModeChartModeSwitch: true,
                editModeButtonChecker: false,
                editModeButton: false,
                submitChecker: true
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'indexNumber':
                        this.state.students[customArray[1]].indexNumber = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'standardFirst':
                    this.state.standard.first = event.target.value
                    this.setState({}, () => { this.fillDetails() })
                    break;
                case 'standardLast':
                    this.state.standard.last = event.target.value
                    this.setState({}, () => { this.fillDetails() })
                    break;
                case 'startingDate':
                    this.state.startingDate = event.target.value
                    for (let i in this.state.totalDates) {
                        this.state.totalDates[i] = String((parseInt(event.target.value) + parseInt(i))) + "-" + String(parseInt(event.target.value) + parseInt(i) + 1).slice(2, 4)
                    }
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'itemName':
                    this.state.itemName = event.target.value
                    this.setState({}, () => { this.fillDetails() })
                    break;
            }
        }

        fillDetails() {
            let flag = true

            if(!NormalNumberRegex.test(this.state.startingDate) || this.state.startingDate < 1000 || this.state.startingDate > 9000 || this.state.startingDate === ''){
                flag = false
            }
            if(this.state.itemName === ''){
                flag = false
            }
            
            for(let i of this.state.students){
                if(i.indexNumber === '' || !NumberRegex.test(i.indexNumber)){
                    flag = false
                }
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: !flag,
                    submitChecker: false
                })
            }
        }

        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region GREATEST COMMON DIVISOR START 

        gcd(a, b) {
            if (a == 0)
                return b;
            return this.gcd(b % a, a);
        }

        findGCD(arr, n) {
            let result = arr[0];
            for (let i = 1; i < n; i++) {
                result = this.gcd(arr[i], result);

                if (result == 1) {
                    return 1;
                }
            }
            return result;
        }

        //#endregion GREATEST COMMON DIVISOR END

        //#region BUTTON PANEL START

        onSubmit() {


            for(let i in this.state.students){
                if(parseInt(i) === 0){
                    this.state.students[i].baseIndexNumber = this.state.students[i].indexNumber
                }else{
                    // this.state.students[i].previousNumber = parseFloat(this.state.students[parseInt(i) - 1].indexNumber)
                    this.state.students[i].baseIndexNumber = Math.round((parseFloat(this.state.students[parseInt(i) - 1].baseIndexNumber)*parseFloat(this.state.students[i].indexNumber)/100)*100)/100
                }
            }

            let dataObject = {
                item:this.state.itemName,
                list:this.state.students,
                dates:this.state.totalDates
            }

            // data.push(JSON.parse(JSON.stringify(dataObject)))

            // chartState.selected += 1

            this.setState({})

            console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addRemoveYear(event) {
            switch (event.target.name) {
                case '+':
                    this.state.students.push({ indexNumber: "100" })
                    this.state.editModestudents.push({ indexNumber: true })
                    this.state.totalDates.push(String((parseInt(this.state.startingDate) + this.state.totalDates.length)) + "-" + String(parseInt(this.state.startingDate) + this.state.totalDates.length + 1).slice(2, 4))
                    this.setState({ editModeButton: true })
                    break;
                case '-':
                    this.state.students.pop()
                    this.state.editModestudents.pop()
                    this.state.totalDates.pop()
                    this.setState({})
                    break;
            }
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(',')

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'indexNumber':
                        this.state.editModestudents[customArray[1]].indexNumber = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'standardFirst':
                    this.state.editModestandard.first = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'standardLast':
                    this.state.editModestandard.last = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'startingDate':
                    this.state.editModestartingDate = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'itemName':
                    this.state.editModeitemName = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
            }
        }

        editModeOff() {
            for (let i of this.state.editModestudents) {
                i.indexNumber = false
            }

            this.state.editModestandard.first = false
            this.state.editModestandard.last = false

            this.setState({
                editModeitemName: false,
                editModestartingDate: false,
                editModeButton: false,
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                    વર્ષ {this.state.totalDates[0]} થી {this.state.totalDates[this.state.totalDates.length - 3]} સુધીના&nbsp;

                            {
                                this.state.editModeitemName ?
                                    <TextField style={{ 'margin-top': "-15px", width: "20%" }} name="itemName" id='outlined-base' error={this.state.itemName === ''}
                                        value={this.state.startingDate} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name="itemName" onClick={this.buttonMaker.bind(this)}> {this.state.itemName}</a>
                            }ઓના&nbsp;

                            ભાવના પરંપરિત આધારે મેળવાયેલ સૂચક આંક નીચે પ્રમાણે છે, તે પરથી અચલ આધારે સૂચક આંક ગણો. (આધાર વર્ષ {this.state.totalDates[0]} લો.)

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>વર્ષ</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>{this.state.itemName}નો<br/> સૂચક આંક</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.students.map((x, i) => (
                                        <TableRow>
                                            <StyledTableCell align="center">
                                                {this.state.totalDates[i]}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    this.state.editModestudents[i].indexNumber ?
                                                        <TextField style={{ 'margin-top': "-15px", width: "20%" }} name={["indexNumber", i]} id='outlined-base' error={x.indexNumber === '' || isNaN(x.indexNumber) || x.indexNumber < 0 || x.indexNumber > 100}
                                                            value={x.indexNumber} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                                        <a className='txt' name={["indexNumber", i]} onClick={this.buttonMaker.bind(this)}> {x.indexNumber}</a>
                                                }
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />
                        
                        વર્ષ: {
                            this.state.editModestartingDate ?
                                <TextField style={{ 'margin-top': "-15px", width: "20%" }} name="startingDate" id='outlined-base' error={this.state.startingDate === '' || isNaN(this.state.startingDate) || this.state.startingDate < 1000 || this.state.startingDate > 9000}
                                    value={this.state.startingDate} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="startingDate" onClick={this.buttonMaker.bind(this)}> {this.state.startingDate}</a>
                        }&nbsp;&nbsp;
                        વર્ષ ઉમેરો/દૂર કરો
                        <IconButton style={{ width: "3%" }} onClick={this.addRemoveYear.bind(this)} name="+">+</IconButton>
                        <IconButton disabled={this.state.students.length < 5} style={{ width: "3%" }} onClick={this.addRemoveYear.bind(this)} name="-">-</IconButton><br /><br />


                        <br /><br />
                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px", fontSize: "20px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >સંપાદિત મોડ બંધ કરો</ColorButton> : <span></span>}
                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize: "20px"}} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize: "20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>જવાબ</b><br />
                            અહીં વર્ષ {data[chartState.selected].dates[0]} ના આધાર વર્ષ લેવાનું છે. તેથી વર્ષ {data[chartState.selected].dates[0]} માટે અચલ આધારે સૂચક આંક બદલાશે નહિ.<br/><br/>

                            ચાલુ વર્ષનો અચલ આધારનો સૂચક આંક = <sup>(ચાલુ વર્ષનો પરંપરિત આધારે સૂચક આંક)x(ચાલુ વર્ષના અગાઉના વર્ષનો અચલ આધારે સૂચક આંક)</sup>/<sub>100</sub><br/><br/>

                            <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>વર્ષ</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>{data[chartState.selected].item}નો<br/>સૂચક આંક</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize: "20px"}}>અચલ આધારે સૂચક આંક</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data[chartState.selected].list.map((x,i)=>(
                                        <TableRow>
                                            <StyledTableCell align="center">{data[chartState.selected].dates[i]}</StyledTableCell>
                                        <StyledTableCell align="center">{x.indexNumber}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            {parseInt(i) !== 0 &&
                                            <span>
                                                <sup>({x.indexNumber} x {data[chartState.selected].list[i-1].baseIndexNumber})</sup>/<sub>100</sub>
                                            </span>
                                            }
                                            <span style={{float:"right"}}>
                                                = {x.baseIndexNumber}
                                            </span>
                                        </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />
                        </div>}
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, ColorButton, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import TextField from '@mui/material/TextField';



export default function OperatingProfitRatio() {
    const data = [{
        sales: 750000,
        costOfGoods: 300000,
        operatingExpenses: 150000,
        operatingCost: 450000,
        operatingProfit: 300000,
        operatingRatio: 40,
        operatingExtraRatio: 60,
        selected: 0
    }]
    
    const NumberRegex = new RegExp('^[0-9]+$')
    
    const chartState = {
        selected: 0
    }
    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                companyName: "'H' Limited",
                sales: 750000,
                costOfGoods: 300000,
                operatingExpenses: 150000,
                submitChecker: true,
                editModeName: false,
                editModeButton: false,
                editModeSales: false,
                editModeGoods: false,
                editModeExpenses: false,

                solutionShow: false,
                tableModeChartModeSwitch: false
            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //EVENT FUNCTIONALITIES START

        eventHandler(event) {
            console.log(event)
            switch (event.target.name) {
                case 'companyName':
                    this.setState({
                        companyName: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'sales':
                    this.setState({
                        sales: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'costOfGoods':
                    this.setState({
                        costOfGoods: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                case 'operatingExpenses':
                    this.setState({
                        operatingExpenses: event.target.value
                    }, () => {
                        this.fillDetails()
                    })
                    break;
                default:
                    this.setState({})
                    break;
            }
        }

        fillDetails() {

            let count = 0
            if (NumberRegex.test(this.state.sales) && this.state.sales !== '' && this.state.sales !== null) {
                count += 1
            }
            if (NumberRegex.test(this.state.costOfGoods) && this.state.costOfGoods !== '' && this.state.costOfGoods !== null) {
                count += 1
            }
            if (NumberRegex.test(this.state.operatingExpenses) && this.state.operatingExpenses !== '' && this.state.operatingExpenses !== null) {
                count += 1
            }

            if (count === 3) {
                this.setState({
                    submitChecker: true
                })
            } else {
                this.setState({
                    submitChecker: false
                })
            }
        }

        autoSave(event) {
 
        }

        //EVENT FUNCTIONALTIES END

        // BUTTON PANEL START

        onSubmit() {
            let operatingCost = parseFloat(this.state.costOfGoods) + parseFloat(this.state.operatingExpenses)
            let operatingProfit = parseFloat(this.state.sales) - operatingCost
            let operatingRatio = parseFloat(operatingProfit * 100 / parseFloat(this.state.sales))
            let operatingExtraRatio = 100 - parseFloat(String(operatingRatio).slice(0, 6))

            let dataObject = {
                sales: parseFloat(this.state.sales),
                costOfGoods: parseFloat(this.state.costOfGoods),
                operatingExpenses: parseFloat(this.state.operatingExpenses),
                operatingCost: operatingCost,
                operatingProfit: operatingProfit,
                operatingExtraRatio: operatingExtraRatio,
                operatingRatio: String(operatingRatio).slice(0, 6)
            }
            data.push(dataObject)

            this.editModeOff()

            chartState.selected = data.length - 1

            this.setState({})
        }

        onReset() {
            window.location.reload()
        }

        switchTableToChartAndBack() {
            this.setState({
                tableModeChartModeSwitch: !this.state.tableModeChartModeSwitch
            })
        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event){
            if(event.target.name === 'Left'){
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if(event.target.name === 'Right'){
                chartState.selected += 1
                this.setState({})
            }
        }

        //BUTTON PANEL END


        // EDIT BUTTON START

        buttonMaker(event) {
            switch (event.target.name) {
                case 'sales':
                    this.setState({
                        editModeSales: true,
                        editModeButton: true
                    })
                    break;
                case 'goods':
                    this.setState({
                        editModeGoods: true,
                        editModeButton: true
                    })
                    break;
                case 'expenses':
                    this.setState({
                        editModeExpenses: true,
                        editModeButton: true
                    })
                    break;
                case 'companyName':
                    this.setState({
                        editModeName: true,
                        editModeButton: true
                    })
                    break;
            }
        }

        editModeOff() {
            this.setState({
                editModeSales: false,
                editModeExpenses: false,
                editModeGoods: false,
                editModeButton: false,
                editModeName: false
            })
        }

        // EDIT BUTTON END


        render() {
            return (
                <div>
                    <br />
                    <div label="Company Name Field" align="left" >
                        <b>Question:</b><br /><br /> From the following information of <span>
                            {
                                this.state.editModeName ?
                                    <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "10%" }} name='companyName' id='outlined-base' error={this.state.companyName === ''}
                                        value={this.state.companyName} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name="companyName" onClick={this.buttonMaker.bind(this)}> {this.state.companyName}</a>
                            }
                        </span> , calculate operating profit ratio.<br /> <br />
                        <div label="inputBoxes"> Sales &nbsp;
                            {
                                this.state.editModeSales ?
                                    <TextField onBlurCapture={this.editModeOff} style={{ 'margin-top': "-15px", width: "10%" }} name='sales' id='outlined-base' error={this.state.sales === '' || isNaN(this.state.sales)}
                                        value={this.state.sales} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                    <a className='txt' name="sales" onClick={this.buttonMaker.bind(this)}> {this.state.sales}</a>
                            }&nbsp;
                            , Cost of goods sold &nbsp;
                            {this.state.editModeGoods ?
                                <TextField style={{ 'margin-top': "-15px", width: "10%" }} name='costOfGoods' id='outlined-base' error={this.state.costOfGoods === '' || isNaN(this.state.costOfGoods)}
                                    value={this.state.costOfGoods} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="goods" onClick={this.buttonMaker.bind(this)}>{this.state.costOfGoods}</a>
                            }&nbsp;
                            , Operating expenses &nbsp;
                            {this.state.editModeExpenses ?
                                <TextField style={{ 'margin-top': "-15px", width: "10%" }} name='operatingExpenses' id='outlined-base' error={this.state.operatingExpenses === '' || isNaN(this.state.operatingExpenses)}
                                    value={this.state.operatingExpenses} onChange={this.eventHandler} onKeyDown={this.autoSave} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="expenses" onClick={this.buttonMaker.bind(this)}>{this.state.operatingExpenses}</a>
                            }
                            &nbsp; &nbsp;
                            {this.state.editModeButton ? <ColorButton style={{ 'margin-top': "-20px" }} align='right' onClick={this.editModeOff.bind(this)} disabled={this.state.companyName === '' || this.state.costOfGoods === '' || this.state.operatingExpenses === '' || this.state.sales === ''}>Edit Mode Off</ColorButton> : <span></span>}
                        </div>


                    </div>
                    <div align="left" label="buttons">
                        <br />
                        <br />
                        <AddButton style={{ width: "15%" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>CALCULATE SUM</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                        <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>SHOW/HIDE SOLUTION</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        <AddButton style={{ width: "12%" }} onClick={this.switchTableToChartAndBack.bind(this)}>TABLE/CHART</AddButton>
                        
                    </div>
                    <div align='right'>
                        <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                        &nbsp;&nbsp;&nbsp;
                        {chartState.selected + 1}
                        &nbsp;&nbsp;&nbsp;
                        <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                    </div>
                    <br />
                    <br />
                    {this.state.solutionShow?
                    <div>{this.state.tableModeChartModeSwitch ?
                        <div label='Table'>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Sales&nbsp;(₹)</StyledTableCell>
                                            <StyledTableCell align="center">Cost of Goods Sales&nbsp;(₹)</StyledTableCell>
                                            <StyledTableCell align="center">Operating Expenses&nbsp;(₹)</StyledTableCell>
                                            <StyledTableCell align="center">Operating Cost&nbsp;(₹) = <br />Cost of Goods + Operating Expenses</StyledTableCell>
                                            <StyledTableCell align="center">Operating Profit&nbsp;(₹) = <br />Sales - Operating Cost</StyledTableCell>
                                            <StyledTableCell align="center">Operating<br /> Ratio&nbsp;(₹) = <br />(<sup>Operating-Profit</sup>/<sub>Sales</sub>) X 100</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((x, i) => (
                                            <StyledTableRow key={i}>
                                                <StyledTableCell align="right">{x.sales}</StyledTableCell>
                                                <StyledTableCell align="right">{x.costOfGoods}</StyledTableCell>
                                                <StyledTableCell align="right">{x.operatingExpenses}</StyledTableCell>
                                                <StyledTableCell align="right">{x.operatingCost}</StyledTableCell>
                                                <StyledTableCell align="right">{x.operatingProfit}</StyledTableCell>
                                                <StyledTableCell align="right">{x.operatingRatio + " %"}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer><br/><br/>
                        </div> :
                        <div label="charts">
                            <div label="chart1" align="left">
                                <h3>Ans:</h3><br />< br />
                                Operating Profit Ratio = (<sup>Operating Profit</sup>/<sub>Sales</sub>) X 100 <br />
                                &emsp;┃&emsp;Operating Profit = Sales - Operating Cost <br />
                                &emsp;┃&emsp;&emsp;┃&emsp;Operating Cost = Cost Of Goods Sold&emsp;&emsp;&emsp;₹ {data[chartState.selected].costOfGoods}<br />
                                &emsp;┃&emsp;&emsp;┃&emsp;&emsp;&nbsp;┃&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;+ Operating Expenses &emsp;&nbsp;+&nbsp;₹ {data[chartState.selected].operatingExpenses}<br />
                                &emsp;┃&emsp;&emsp;┃&emsp;&emsp;&nbsp;┃&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;──────────────── <br />
                                &emsp;┃&emsp;&emsp;┃&emsp;&emsp;&nbsp;&nbsp;━━━━━━━━━━━━━━━━━━━━━━━━━━━━━► ₹ {data[chartState.selected].operatingCost}<br />
                                &emsp;┃&emsp;&emsp;┃&emsp;&emsp;<br />
                                &emsp;┃&emsp;&emsp;&nbsp;━━━━━━━━━━━━━━━━━► ₹ {data[chartState.selected].sales} - ₹ {data[chartState.selected].operatingCost} = ₹ {data[chartState.selected].operatingProfit}  <br />
                                &emsp;┃&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<br />
                                &emsp;&nbsp;━━━━━━━━━━━━━━━━━━━━━━━━━━━━━► (<sup>{data[chartState.selected].operatingProfit}</sup>/<sub>{data[chartState.selected].sales}</sub>) X 100 = {data[chartState.selected].operatingRatio} &nbsp;%
                            </div>
                            <h2>OR</h2>
                            <div label='chart2' align='left'>
                                Operating Profit Ratio = 100% - Operating Ratio <br />
                                &emsp;┃&emsp;Operating Ratio = (<sup>Cost Of Goods Sold + operating Expenses</sup>/<sub>Sales</sub>) X 100 <br />
                                &emsp;┃&emsp;&emsp;┃ <br />
                                &emsp;┃&emsp;&emsp;&nbsp;━━━━━━━━━━━━━━━━━►<sup>{data[chartState.selected].costOfGoods} + {data[chartState.selected].operatingExpenses}</sup>/<sub>{data[chartState.selected].sales}</sub> X 100 = {data[chartState.selected].operatingRatio} % <br />
                                &emsp;&nbsp;━━━━━━━━━━━━━━━━━━━━━━━━━━━━━► 100 % - {data[chartState.selected].operatingExtraRatio} % = {data[chartState.selected].operatingRatio} % <br />
                            </div>
                        </div>}</div>:
                        <div></div>
                        }
                    <br />
                    <br />
                </div>
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  AddButton,
  BootstrapTextField,
  ColorButton,
  CustomColorButton,
  DeleteButton,
  LinkButton,
  RedditTextField,
  ResponseButton,
  ReverseLinkButton,
  StyledGrid,
  StyledTableCell,
  StyledTableRow,
} from "../../MelzoLibrary/StyledComponents";
import { Container } from "@mui/system";

import Icon from "@mui/material/Icon";

import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";

import "react-datepicker/dist/react-datepicker.css";

export default function Permutation() {
  const data = [{ ans: 336, n: 8, r: 3, difference: 5 }];

  const NormalNumberRegex = new RegExp(/^[0-9]+$/);

  const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);

  const chartState = {
    selected: 0,
  };

  // const [value, onChange] = useState(new Date());
  // const [value2, onChange2] = useState(new Date());

  class OperatingRatio extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: "rainfall",
        valueOf_n: 8,
        ans: 3,
        // showError: false,
        // errorMessage: "SomeThing went wrong",

        type: "mm",
        sampleString: "126 110 91 115 112 80 101 93 97 113",
        sampleStringArray: [126, 110, 91, 115, 112, 80, 101, 93, 97, 113],
        sampleStringLength: 10,
        editModeValueOf_n: false,
        editModeans: false,

        editModename: false,
        editModetype: false,
        editModesampleString: false,
        solutionShow: false,
        // tableModeChartModeSwitch: true,
        editModeButtonChecker: false,
        editModeButton: false,
        submitChecker: true,
      };
      this.eventHandler = this.eventHandler.bind(this);
      this.editModeOff = this.editModeOff.bind(this);
      this.stringReducer = this.stringReducer.bind(this);
      this.autoSave = this.autoSave.bind(this);
      this.moveLeftAndRight = this.moveLeftAndRight.bind(this);
    }

    //#region EVENT FUNCTIONALITIES START

    eventHandler(event) {
      switch (event.target.name) {
        case "valueOf_n":
          this.setState(
            {
              valueOf_n: Number(event.target.value),
            },
            () => {
              this.fillDetails();
            }
          );
          break;
        case "ans":
          this.setState(
            {
              ans: Number(event.target.value),
            },
            () => {
              this.fillDetails();
            }
          );
          break;
      }
    }

    handleChange(event) {
      this.setState({
        type: event.target.value,
      });
    }

    stringReducer(string) {
      let stringArray = string.split(" ");

      for (let i in stringArray) {
        if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === "") {
          // console.log(i)
          stringArray.splice(i, 1);
        }
      }

      if (stringArray[0] === null) {
        stringArray[0] = 0;
      }

      this.setState({
        sampleStringLength: stringArray.length,
        sampleStringArray: stringArray,
      });

      return string;
    }

    fillDetails() {
      let flag = true;

      if (this.state.name === "") {
        flag = false;
      }

      if (flag === true) {
        this.setState({
          editModeButtonChecker: !flag,
        });
      } else {
        this.setState({
          editModeButtonChecker: !flag,
        });
      }
    }

    autoSave(event) {}

    //#endregion EVENT FUNCTIONALTIES END

    //#region BUTTON PANEL START

    onSubmit() {
      // let inputValues = { n: this.state.editModeValueOf_n, r: this.state.ans };

      function factorialize(num) {
        if (num < 0) return -1;
        else if (num == 0) return 1;
        else {
          return num * factorialize(num - 1);
        }
      }
      console.log("factorialize(3)", factorialize(this.state.ans));

      console.log(
        "🚀 ~ file: Permutation.js ~ line 174 factorialize (this.state.valueOf_n)",
        factorialize(this.state.valueOf_n)
      );
      console.log("🚀 ~ file: Permutation.js ~ line 174 ~ factorialize(this.state.ans)", factorialize(this.state.ans));

      const commingAns = factorialize(this.state.valueOf_n) / factorialize(this.state.valueOf_n - this.state.ans);
      console.log("🚀 ~ file: Permutation.js ~ line 174 ~ OperatingRatio ~ onSubmit ~ commingAns", commingAns);

      data.push({
        ans: commingAns,
        n: this.state.valueOf_n,
        r: this.state.ans,
        difference: this.state.valueOf_n - this.state.ans,
      });

      chartState.selected += 1;

      this.setState({});

      // // const ansStepString = "";
      // // let i = 0;

      // // while (i < inputValues.n - inputValues.r) {
      // //   if (inputValues.n - inputValues.r == 1) {
      // //     ansStepString += `${inputValues.n}`;
      // //     i++;
      // //   } if (inputValues.n - inputValues.r > 1) {
      // //     ansStepString += ` x ${inputValues.n}`;
      // //     i++;
      // //   }
      // // }

      // // for (let i = inputValues.n; 1 < i > 0; i++) {}

      // let originalArray = JSON.parse(JSON.stringify(this.state.sampleStringArray));

      // let sortedArray = this.state.sampleStringArray.sort((a, b) => {
      //   return a - b;
      // });

      // let assumedA = Math.round((parseFloat(sortedArray[0]) + parseFloat(sortedArray[sortedArray.length - 1])) / 2);

      // let answerPallete = [];
      // let totalA = 0;
      // // console.log(sortedArray)

      // for (let i in originalArray) {
      //   answerPallete.push({
      //     number: parseFloat(originalArray[i]),
      //     A: parseFloat(originalArray[i]) - assumedA,
      //   });
      //   totalA += parseFloat(originalArray[i]) - assumedA;
      // }

      // let dataObject = {
      //   type: this.state.type,
      //   name: this.state.name,
      //   answerPallete,
      //   assumedA,
      //   totalA,
      //   n: this.state.sampleStringLength,
      //   mean: assumedA + Math.round((totalA / this.state.sampleStringLength) * 100) / 100,
      // };

      // data.push(JSON.parse(JSON.stringify(dataObject)));

      // chartState.selected += 1;

      // this.setState({});

      // // console.log(JSON.stringify(dataObject, null, 4))
    }

    onReset() {}

    switchTableToChartAndBack() {}

    showSolutionOnOff() {
      this.setState({
        solutionShow: !this.state.solutionShow,
      });
    }

    moveLeftAndRight(event) {
      if (event.target.name === "Left") {
        chartState.selected = chartState.selected - 1;
        this.setState({});
      } else if (event.target.name === "Right") {
        chartState.selected += 1;
        this.setState({});
      }
    }

    //#endregion BUTTON PANEL END

    //#region EDIT BUTTON START

    buttonMaker(event) {
      switch (event.target.name) {
        case "sampleString":
          this.setState({
            editModesampleString: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
        case "valueOf_n":
          this.setState({
            editModeValueOf_n: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
        case "ans":
          this.setState({
            editModeans: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
        case "type":
          this.setState({
            editModetype: true,
            editModeButton: true,
            submitChecker: false,
          });
          break;
      }
    }

    editModeOff() {
      this.setState({
        sampleString: this.state.sampleStringArray.reduce((a, b) => {
          return a + " " + b;
        }),

        submitChecker: true,

        editModeValueOf_n: false,
        editModetype: false,
        editModeans: false,
        editModeButton: false,
      });
    }

    //#endregion EDIT BUTTON END

    render() {
      return (
        <div>
          <br />
          <div label="InputFieldTables" align="left">
            If &emsp;
            <sup>
              {this.state.editModeValueOf_n ? (
                <TextField
                  style={{ width: "9%", padding: "-10px" }}
                  type="number"
                  name="valueOf_n"
                  id="outlined-base"
                  error={this.state.valueOf_n === ""}
                  value={this.state.valueOf_n}
                  onChange={this.eventHandler}
                  variant="standard"
                  autoComplete="off"
                />
              ) : (
                <a className="txt" name="valueOf_n" onClick={this.buttonMaker.bind(this)}>
                  {this.state.valueOf_n}
                </a>
              )}{" "}
            </sup>
            P{" "}
            <sub>
              {this.state.editModeans ? (
                <TextField
                  style={{ width: "9%", padding: "-10px" }}
                  type="number"
                  name="ans"
                  id="outlined-base"
                  error={this.state.ans === ""}
                  value={this.state.ans}
                  onChange={this.eventHandler}
                  variant="standard"
                  autoComplete="off"
                />
              ) : (
                <a className="txt" name="ans" onClick={this.buttonMaker.bind(this)}>
                  {this.state.ans}
                </a>
              )}
            </sub>{" "}
            <br />
            then find the <b> value </b> of above ?
            <br />
            <br />
            {this.state.editModeButton ? (
              <ColorButton
                disabled={this.state.editModeButtonChecker}
                style={{ "margin-top": "-20px" }}
                align="right"
                onClick={this.editModeOff.bind(this)}
              >
                Edit Mode Off
              </ColorButton>
            ) : (
              <span></span>
            )}
            <br />
            {/* {this.state.showError ? <p style={{ color: red }}>Error: {this.state.errorMessage} </p> : null} */}
            <br />
            <div align="left" label="buttons">
              <AddButton
                style={{ width: "15%" }}
                disabled={!this.state.submitChecker}
                onClick={this.onSubmit.bind(this)}
              >
                CALCULATE SUM
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
              <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>
                SHOW/HIDE SOLUTION
              </AddButton>
            </div>
            <div align="right" label="sumNavigator">
              <AddButton
                name="Left"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected - 1 < 0}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ◄
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {chartState.selected + 1}
              &nbsp;&nbsp;&nbsp;
              <AddButton
                name="Right"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected + 1 > data.length - 1}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ►
              </AddButton>
            </div>
          </div>
          <br />
          <br />
          {this.state.solutionShow && (
            <div label="Answer" align="left">
              <br />
              {/* <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        {data[chartState.selected].name} ({data[chartState.selected].type}) <i>x</i>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <i>d</i> = <i>x</i> - <i>A,A</i> = {data[chartState.selected].assumedA}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data[chartState.selected].answerPallete.map((x, i) => (
                      <TableRow>
                        <StyledTableCell align="center">{x.number}</StyledTableCell>
                        <StyledTableCell align="center">{x.A}</StyledTableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <StyledTableCell align="center">
                        <b>Total</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>{data[chartState.selected].totalA}</b>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer> */}
              <br />
              Here, <i>n</i> = {data[chartState.selected].n}
              <br />
              and, <i>r</i> = {data[chartState.selected].r}
              <br />
              <br />
              Formula , <sup>n</sup>P <sub>r</sub> = <sup>n!</sup>/<sub>(n-r)!</sub>
              <br />
              <br />
              Now ,
              <br />
              &emsp;&emsp;&emsp;&nbsp; <sup>{data[chartState.selected].n}</sup>P{" "}
              <sub>{data[chartState.selected].r}</sub> = <sup>{data[chartState.selected].n}!</sup>/
              <sub>
                ({data[chartState.selected].n}-{data[chartState.selected].r})!
              </sub>
              <br />
              <br />
              &emsp;&emsp;&emsp;&emsp;&emsp;&nbsp; = <sup>{data[chartState.selected].n}!</sup>/
              <sub>{data[chartState.selected].difference}!</sub>
              <br />
              <br />
              &emsp;&emsp;&emsp;&emsp;&emsp;&nbsp; = {data[chartState.selected].ans}
              <br />
              <br />
              Our answer is {data[chartState.selected].ans}.
            </div>
          )}
          <br />
          <br />
        </div>
      );
    }
  }

  return (
    <div>
      <Container fixed>
        <OperatingRatio />
      </Container>
    </div>
  );
}

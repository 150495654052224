import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddButton, BootstrapTextField, ColorButton, CustomColorButton, DeleteButton, LinkButton, RedditTextField, ResponseButton, ReverseLinkButton, StyledGrid, StyledTableCell, StyledTableRow } from '../../MelzoLibrary/StyledComponents';
import { Container } from '@mui/system';

import Icon from '@mui/material/Icon';

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import "react-datepicker/dist/react-datepicker.css";
import { Co2Sharp } from '@mui/icons-material';

//ILLUSTRATION 8

export default function ComparativeProfitLossStatement() {

    const data = [{
        "partners": [
            "A",
            "B",
            "C"
        ],
        "capitalAccount": [
            90000,
            90000,
            80000
        ],
        "partnersLoan": [
            12000,
            20000,
            0
        ],
        "initialProfitLossRatio": [
            3,
            2,
            1
        ],
        "retiredPartner": "B",
        "newDate": "2016-06-30",
        "alivePartnersBalance": [
            72000,
            74000
        ],
        "retiredPartnersBalance": 145550,
        "liabilities": [
            {
                "type": "લેણદારો",
                "amount": 10000
            }
        ],
        "assets": [
            {
                "type": "મકાન",
                "amount": 96000
            },
            {
                "type": "ફર્નિચર",
                "amount": 60000
            },
            {
                "type": "સ્ટોક",
                "amount": 50000
            },
            {
                "type": "દેવાદારો",
                "amount": 60000
            },
            {
                "type": "રોકડ",
                "amount": 15000
            }
        ],
        "firstPart": [
            {
                "nameLeft": "પેટન્ટ",
                "amountLeft": 25000,
                "nameRight": "મકાન",
                "amountRight": 16000
            }
        ],
        "totalInFirstPart": {
            "leftAmount": 25000,
            "rightAmount": 16000,
            "differenceForProfit": 9000,
            "partnersProfit": [
                4500,
                3000,
                1500
            ]
        },
        "secondPart": [
            {
                "leftAmount": [
                    4500,
                    3000,
                    1500
                ],
                "rightAmount": [
                    90000,
                    90000,
                    80000
                ]
            },
            {
                "leftAmount": [
                    15000,
                    10000,
                    5000
                ],
                "rightAmount": [
                    9000,
                    6000,
                    3000
                ]
            },
            {
                "leftAmount": [
                    7500,
                    0,
                    2500
                ],
                "rightAmount": [
                    0,
                    2250,
                    0
                ]
            },
            {
                "leftAmount": [
                    0,
                    145550,
                    0
                ],
                "rightAmount": [
                    0,
                    20000,
                    0
                ]
            },
            {
                "leftAmount": [
                    72000,
                    0,
                    74000
                ],
                "rightAmount": [
                    0,
                    300,
                    0
                ]
            },
            {
                "rightAmount": [
                    0,
                    7500,
                    0
                ]
            },
            {
                "rightAmount": [
                    0,
                    2500,
                    0
                ]
            },
            {
                "rightAmount": [
                    0,
                    30000,
                    0
                ]
            }
        ],
        "secondPartTotal": {
            "left": [
                27000,
                13000,
                9000
            ],
            "right": [
                99000,
                158550,
                83000
            ]
        },
        "thirdPart": {
            "name": "B",
            "firstDate": "2016-4-1",
            "secondDate": "2016-06-30",
            "capital": 90000,
            "months": 3,
            "pa": 10,
            "interestOnCapital": 2250
        },
        "fourthPart": {
            "name": "B",
            "months": 3,
            "pa": 6,
            "loan": 20000,
            "interestOnCapital": 300
        },
        "fifthPart": {
            "nameLeft": [
                "A",
                "C"
            ],
            "profitLossRatioLeft": [
                3,
                1
            ],
            "nameLeftLoan": [
                12000,
                0
            ],
            "nameLeft2": [
                "A",
                "B",
                "C"
            ],
            "profitLossRatioLeft2": [
                3,
                0,
                1
            ],
            "goodwill": 30000,
            "shareOfGoodwill": 10000,
            "capital": [
                7500,
                2500
            ],
            "capital2": [
                7500,
                0,
                2500
            ]
        },
        "sixthPart": {
            "months": 3,
            "sales": 1200000,
            "netProfit": 240000,
            "monthSales": 450000,
            "monthProfit": 90000,
            "retiredPartnerProfit": 30000,
            "shareInProfit": 32550
        },
        "deadPartner": {
            "name": "B",
            "capital": 90000,
            "loan": 20000,
            "monthGap": 3,
            "outstandingInterest": 6,
            "ratio": 2
        }
    }]

    const NormalNumberRegex = new RegExp(/^[0-9]+$/)

    const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const chartState = {
        selected: 0
    }

    class OperatingRatio extends React.Component {
        constructor(props) {
            super(props)
            this.state = ({
                partners: ["A", "B", "C"],
                capitalAccount: [90000, 90000, 80000],
                partnersLoan: [12000, 20000, 0],
                initialProfitLossRatio: [3, 2, 1],
                date: "2016-03-31",
                liabilities: [{
                    type: "સામાન્ય અનામત",
                    amount: 18000
                }, {
                    type: "લેણદારો",
                    amount: 10000
                }],
                assets: [{
                    type: "પાઘડી",
                    amount: 30000
                }, {
                    type: "પેટન્ટ",
                    amount: 25000,
                    added: true,
                }, {
                    type: "મકાન",
                    amount: 80000,
                    added: true
                }, {
                    type: "ફર્નિચર",
                    amount: 60000
                }, {
                    type: "સ્ટૉક",
                    amount: 50000
                }, {
                    type: "દેવાદારો",
                    amount: 60000
                }, {
                    type: "રોકડ",
                    amount: 15000,
                }],
                totalAssetAmount: 320000,
                totalLiabilityAmount: 320000,
                retiredPartner: "B",
                newDate: "2016-06-30",
                staticQuestionPallete: [
                    {//1
                        type: "interestOnCapital",
                        percent: 10
                    },
                    {//2
                        type: "share"
                    },
                    {//3
                        type: "loanInterest"
                    },
                    {//4
                        type: "goodwillDecided",
                        amount: 30000
                    },
                    {//5
                        type: "shareOfProfit",
                        amountFirst: 1200000,
                        amountCurrent: 450000,
                        amountNet: 240000
                    }
                ],

                questionPallete: [

                    {//1
                        type: "writtenOffAppreciated",
                        nameOne: "પેટન્ટ",
                        nameTwo: "મકાન",
                        percent: 20
                    }
                ],

                tempAmount: '',
                tempAssetWrittenOffName: '',
                tempAssetAppreciatedName: '',

                editModepartners: [false, false, false],
                editModecapitalAccount: [false, false, false],
                editModepartnersLoan: [false, false, false],
                editModeinitialProfitLossRatio: [false, false, false],
                editModedate: false,
                editModeliabilities: [{
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }],
                editModeassets: [{
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false
                }, {
                    type: false,
                    amount: false,
                    originalAmount: false,
                    badDebtR: false
                }],

                editModeretiredPartner: false,
                editModenewDate: false,

                editModestaticQuestionPallete: [
                    {//1
                        percent: false
                    },
                    {//2

                    },
                    {//3

                    },
                    {//4
                        amount: false
                    },
                    {//5
                        amountFirst: false,
                        amountCurrent: false,
                        amountNet: false
                    }
                ],

                editModequestionPallete: [

                    {//1
                        percent: false
                    }
                ],

                addQuestionChecker: false,
                submitChecker: true,
                solutionShow: false

            })
            this.eventHandler = this.eventHandler.bind(this)
            this.editModeOff = this.editModeOff.bind(this)
            this.addNameChange = this.addNameChange.bind(this)
            this.autoSave = this.autoSave.bind(this)
            this.addEventHandler = this.addEventHandler.bind(this)
            this.moveLeftAndRight = this.moveLeftAndRight.bind(this)
        }

        //#region EVENT FUNCTIONALITIES START

        eventHandler(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'partners':
                        if (this.state.partners[customArray[1]] === this.state.retiredPartner) {
                            this.state.retiredPartner = event.target.value
                        }
                        this.state.partners[customArray[1]] = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'initialPL':
                        this.state.initialProfitLossRatio[customArray[1]] = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'liabilitiesType':
                        this.state.liabilities[customArray[1]].type = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'liabilitiesAmount':
                        this.state.liabilities[customArray[1]].amount = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;
                    case 'assetsType':
                        for(let i of this.state.questionPallete){
                            if(i.nameOne === this.state.assets[customArray[1]].type){
                                i.nameOne = event.target.value
                            } else if(i.nameTwo === this.state.assets[customArray[1]].type){
                                i.nameTwo = event.target.value
                            }
                        }
                        this.state.assets[customArray[1]].type = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'assetsAmount':
                        this.state.assets[customArray[1]].amount = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;
                    case 'assetsOriginalAmount':
                        this.state.assets[customArray[1]].originalAmount = event.target.value
                        this.setState({}, () => {
                            this.badDebtDecreaser(customArray[1])
                        })
                        break;
                    case 'assetsBadDebtR':
                        this.state.assets[customArray[1]].badDebtR = event.target.value
                        this.setState({}, () => {
                            this.badDebtDecreaser(customArray[1])
                        })
                        break;
                    case 'capitalAccount':
                        this.state.capitalAccount[customArray[1]] = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;
                    case 'partnersLoan':
                        this.state.partnersLoan[customArray[1]] = event.target.value
                        this.setState({}, () => {
                            this.totalAmountCalculator()
                        })
                        break;

                    case 'interestOnCapital':
                        this.state.staticQuestionPallete[customArray[1]].percent = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'amountFirst':
                        this.state.staticQuestionPallete[customArray[1]].amountFirst = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'amountCurrent':
                        this.state.staticQuestionPallete[customArray[1]].amountCurrent = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                    case 'amountNet':
                        this.state.staticQuestionPallete[customArray[1]].amountNet = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;

                    case 'writtenOffAppreciated':
                        this.state.questionPallete[customArray[1]].percent = event.target.value
                        this.setState({}, () => { this.fillDetails() })
                        break;
                }
            }

            switch (event.target.name) {
                case 'date':
                    this.setState({
                        date: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'newDate':
                    this.setState({
                        newDate: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'newPartner':
                    this.setState({
                        newPartner: event.target.value
                    }, () => { this.fillDetails() })
                    break;
                case 'newPartnerCapital':
                    this.setState({
                        newPartnerCapital: event.target.value
                    }, () => { this.fillDetails() })
                    break

            }
        }

        addEventHandler(event) {
            switch (event.target.name) {
                case 'amount':
                    this.setState({
                        tempAmount: event.target.value
                    }, () => { this.addButtonChecker() })
                    break;
            }
        }

        addButtonChecker() {
            if (this.state.tempAmount !== '' && NumberRegex.test(this.state.tempAmount) && this.state.tempAmount > 0 && this.state.tempAmount < 100 &&
                this.state.tempAssetWrittenOffName !== '' &&
                this.state.tempAssetAppreciatedName !== '' &&
                this.state.tempAssetWrittenOffName !== this.state.tempAssetAppreciatedName) {
                this.setState({
                    addQuestionChecker: true
                })
            } else {
                this.setState({
                    addQuestionChecker: false
                })
            }
        }

        retiredPartnerChange(event) {
            this.setState({
                retiredPartner: event.target.value
            })
        }

        addNameChange(event) {
            this.setState({
                tempName: event.target.value
            }, () => { this.addButtonChecker() })
        }

        AssetWrittenOffName(event) {
            this.setState({
                tempAssetWrittenOffName: event.target.value
            }, () => { this.addButtonChecker() })
        }

        AssetAppreciatedName(event) {
            this.setState({
                tempAssetAppreciatedName: event.target.value
            }, () => { this.addButtonChecker() })
        }

        addTypeChange(event) {
            this.setState({
                tempPayType: event.target.value
            })
        }

        stringReducer(string) {
            let stringArray = string.split(" ")

            for (let i in stringArray) {
                if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === '') {
                    // console.log(i)
                    stringArray.splice(i, 1)
                }
            }

            if (stringArray[0] === null) {
                stringArray[0] = 0
            }

            this.setState({
                sampleStringLength: stringArray.length,
                sampleStringArray: stringArray
            })

            return string
        }

        totalAmountCalculator() {
            let totalAssetAmount = 0

            let totalLiabilityAmount = this.state.capitalAccount.reduce((a, b) => parseFloat(a) + parseFloat(b))
            totalLiabilityAmount += this.state.partnersLoan.reduce((a, b) => parseFloat(a) + parseFloat(b))

            for (let i of this.state.liabilities) {
                totalLiabilityAmount += parseFloat(i.amount)
            }
            for (let i of this.state.assets) {
                totalAssetAmount += parseFloat(i.amount)
            }

            this.setState({
                totalAssetAmount: totalLiabilityAmount,
                totalLiabilityAmount: totalAssetAmount
            }, () => { this.fillDetails() })

        }

        fillDetails() {

            let flag = true

            for (let i in this.state.partners) {
                if (this.state.partners[i] === '') {
                    flag = false
                }
                if (this.state.capitalAccount[i] === '' || !NumberRegex.test(this.state.capitalAccount[i])) {
                    flag = false
                }
                if (this.state.partnersLoan[i] === '' || !NumberRegex.test(this.state.partnersLoan[i])) {
                    flag = false
                }
                if (this.state.initialProfitLossRatio[i] === '' || !NumberRegex.test(this.state.initialProfitLossRatio[i])) {
                    flag = false
                }
            }
            for (let i of this.state.liabilities) {
                if (i.type === '') {
                    flag = false
                }
                if (i.amount === '' || !NumberRegex.test(i.amount)) {
                    flag = false
                }
            }
            for (let i of this.state.assets) {
                if (i.type === '') {
                    flag = false
                }
                if (i.amount === '' || !NumberRegex.test(i.amount)) {
                    flag = false
                }
            }
            if (this.state.totalAssetAmount !== this.state.totalLiabilityAmount) {
                flag = false
            }
            if (this.state.staticQuestionPallete[0].percent === '' || !NumberRegex.test(this.state.staticQuestionPallete[0].percent) || parseFloat(this.state.staticQuestionPallete[0].percent) < 0 || parseFloat(this.state.staticQuestionPallete[0].percent) > 100) {
                flag = false
            }
            if (this.state.staticQuestionPallete[3].amount === '' || !NumberRegex.test(this.state.staticQuestionPallete[3].amount)) {
                flag = false
            }
            if (this.state.staticQuestionPallete[4].amountFirst === '' || !NumberRegex.test(this.state.staticQuestionPallete[4].amountFirst) ||
                this.state.staticQuestionPallete[4].amountCurrent === '' || !NumberRegex.test(this.state.staticQuestionPallete[4].amountCurrent) ||
                this.state.staticQuestionPallete[4].amountNet === '' || !NumberRegex.test(this.state.staticQuestionPallete[4].amountNet)) {
                flag = false
            }

            for (let i of this.state.questionPallete) {
                if (i.percent === '' || !NumberRegex.test(i.percent) || parseFloat(i.percent) < 0 || parseFloat(i.percent) > 100) {
                    flag = false
                }
            }

            let monthChecker = parseFloat(this.state.newDate.slice(5,7)) - parseFloat(this.state.date.slice(5,7))  + 12*(parseFloat(this.state.newDate.slice(0,4)) - parseFloat(this.state.date.slice(0,4))) 

            if(monthChecker <= 0){
                flag = false
            }


            if (flag === true) {
                this.setState({
                    editModeButtonChecker: false,
                    submitChecker: true
                })
            } else {
                this.setState({
                    editModeButtonChecker: true,
                    submitChecker: false
                })
            }
        }


        autoSave(event) {

        }

        //#endregion EVENT FUNCTIONALTIES END

        //#region BUTTON PANEL START

        onSubmit() {

            let deadPartner = {}

            for (let i in this.state.partners) {
                if (this.state.partners[i] === this.state.retiredPartner) {
                    deadPartner.name = this.state.partners[i]
                    deadPartner.capital = this.state.capitalAccount[i]
                    deadPartner.loan = this.state.partnersLoan[i]
                    deadPartner.monthGap = parseInt(this.state.newDate.slice(5, 7)) - parseInt(this.state.date.slice(5, 7)) + 12 * (parseInt(this.state.newDate.slice(0, 4)) - parseInt(this.state.date.slice(0, 4)))
                    deadPartner.outstandingInterest = 6
                    deadPartner.ratio = this.state.initialProfitLossRatio[i]
                }
            }

            //#region THIRD PART START

            let thirdPart = {
                name: deadPartner.name,
                firstDate: "",
                secondDate: this.state.newDate,
                capital: deadPartner.capital,
                months: deadPartner.monthGap,
                pa: this.state.staticQuestionPallete[0].percent
            }

            thirdPart.interestOnCapital = parseFloat(thirdPart.capital) * (parseFloat(thirdPart.pa) / 100) * (parseFloat(thirdPart.months) / 12)

            let thirdPartNewDate = new Date(this.state.date)
            thirdPartNewDate.setDate(thirdPartNewDate.getDate() + 1);

            thirdPart.firstDate = thirdPartNewDate.getFullYear() + "-" + String(parseInt(thirdPartNewDate.getMonth()) + 1) + "-" + thirdPartNewDate.getDate()

            //#endregion THIRD PART END

            //#region FOURTH PART START

            let fourthPart = {
                name: thirdPart.name,
                months: thirdPart.months,
                pa: 6,
                loan: deadPartner.loan,
            }

            fourthPart.interestOnCapital = parseFloat(fourthPart.loan) * (parseFloat(fourthPart.pa) / 100) * (parseFloat(fourthPart.months) / 12)

            //#endregion FOURTH PART END

            //#region FIFTH PART START

            let fifthPart = {
                nameLeft: [],
                profitLossRatioLeft: [],
                nameLeftLoan: [],
                nameLeft2: [],
                profitLossRatioLeft2: [],
                goodwill: this.state.assets[0].amount,
                shareOfGoodwill: parseFloat(deadPartner.ratio) / parseFloat(this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0)) * (parseFloat(this.state.assets[0].amount))
            }

            for (let i in this.state.partners) {
                if (this.state.partners[i] !== deadPartner.name) {
                    fifthPart.nameLeft.push(this.state.partners[i])
                    fifthPart.profitLossRatioLeft.push(this.state.initialProfitLossRatio[i])
                    fifthPart.nameLeftLoan.push(this.state.partnersLoan[i])
                }
            }

            for (let i in this.state.partners) {
                if (this.state.partners[i] !== deadPartner.name) {
                    fifthPart.nameLeft2.push(this.state.partners[i])
                    fifthPart.profitLossRatioLeft2.push(this.state.initialProfitLossRatio[i])
                } else {
                    fifthPart.nameLeft2.push(this.state.partners[i])
                    fifthPart.profitLossRatioLeft2.push(0)
                }
            }

            fifthPart.capital = []
            fifthPart.capital2 = []

            for (let i in fifthPart.profitLossRatioLeft) {
                fifthPart.capital[i] = parseFloat(fifthPart.profitLossRatioLeft[i]) * parseFloat(fifthPart.shareOfGoodwill) / parseFloat(fifthPart.profitLossRatioLeft.reduce((a, b) => (a + parseFloat(b)), 0))
            }
            for (let i in fifthPart.profitLossRatioLeft2) {
                fifthPart.capital2[i] = parseFloat(fifthPart.profitLossRatioLeft2[i]) * parseFloat(fifthPart.shareOfGoodwill) / parseFloat(fifthPart.profitLossRatioLeft2.reduce((a, b) => (a + parseFloat(b)), 0))
            }

            //#endregion FIFTH PART END

            //#region SIXTH PART START

            let sixthPart = {
                months: thirdPart.months,
                sales: this.state.staticQuestionPallete[4].amountFirst,
                netProfit: this.state.staticQuestionPallete[4].amountNet,
                monthSales: this.state.staticQuestionPallete[4].amountCurrent
            }

            sixthPart.monthProfit = parseFloat(sixthPart.netProfit) * parseFloat(sixthPart.monthSales) / parseFloat(sixthPart.sales)

            sixthPart.retiredPartnerProfit = sixthPart.monthProfit * parseFloat(deadPartner.ratio) / (parseFloat(this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0)))

            sixthPart.shareInProfit = thirdPart.interestOnCapital + fourthPart.interestOnCapital + sixthPart.retiredPartnerProfit

            //#endregion SIXTH PART END

            //#region FIRST PART START

            for (let i of this.state.questionPallete) {
                for (let j of this.state.assets) {
                    if (i.nameOne === j.type) {
                        i.nameOneAmount = j.amount
                    }
                    if (i.nameTwo === j.type) {
                        i.nameTwoAmount = j.amount
                    }
                }
            }

            let firstPart = []

            for (let i of this.state.questionPallete) {
                firstPart.push({
                    nameLeft: i.nameOne,
                    amountLeft: i.nameOneAmount,
                    nameRight: i.nameTwo,
                    amountRight: i.nameTwoAmount * parseFloat(i.percent) / 100,
                })
            }

            let totalInFirstPart = {
                leftAmount: firstPart.reduce((a, b) => (a + b.amountLeft), 0),
                rightAmount: firstPart.reduce((a, b) => (a + b.amountRight), 0),
                differenceForProfit: firstPart.reduce((a, b) => (a + b.amountLeft), 0) - firstPart.reduce((a, b) => (a + b.amountRight), 0),
                partnersProfit: []
            }

            for (let i in this.state.initialProfitLossRatio) {
                totalInFirstPart.partnersProfit.push(Math.round(parseFloat(this.state.initialProfitLossRatio[i]) * totalInFirstPart.differenceForProfit / parseFloat(this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0)) / 100) * 100)
            }

            //#endregion FIRST PART END

            //#region SECOND PART START

            let secondPart = [
                {//1
                    leftAmount: [
                        totalInFirstPart.partnersProfit[0],
                        totalInFirstPart.partnersProfit[1],
                        totalInFirstPart.partnersProfit[2],
                    ],
                    rightAmount: [
                        this.state.capitalAccount[0],
                        this.state.capitalAccount[1],
                        this.state.capitalAccount[2]
                    ],
                },
                {//2
                    leftAmount: [
                        parseFloat(this.state.assets[0].amount) * parseFloat(this.state.initialProfitLossRatio[0]) / this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0),
                        parseFloat(this.state.assets[0].amount) * parseFloat(this.state.initialProfitLossRatio[1]) / this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0),
                        parseFloat(this.state.assets[0].amount) * parseFloat(this.state.initialProfitLossRatio[2]) / this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0),
                    ],
                    rightAmount: [
                        parseFloat(this.state.liabilities[0].amount) * parseFloat(this.state.initialProfitLossRatio[0]) / this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0),
                        parseFloat(this.state.liabilities[0].amount) * parseFloat(this.state.initialProfitLossRatio[1]) / this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0),
                        parseFloat(this.state.liabilities[0].amount) * parseFloat(this.state.initialProfitLossRatio[2]) / this.state.initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0),
                    ],
                },
                {//3
                    leftAmount: [],
                    rightAmount: []
                },
                {//4
                    leftAmount: [],
                    rightAmount: []
                },
                {//5
                    leftAmount: [],
                    rightAmount: []
                },
                {//6
                    rightAmount: []
                },
                {//7
                    rightAmount: []
                },
                {//8
                    rightAmount: []
                },
            ]

            for (let i in this.state.partners) {
                if (this.state.partners[i] !== this.state.retiredPartner) {
                    secondPart[2].leftAmount.push(fifthPart.capital2[i])
                    secondPart[2].rightAmount.push(0)

                    secondPart[3].rightAmount.push(0)
                    secondPart[4].rightAmount.push(0)
                    secondPart[5].rightAmount.push(0)
                    secondPart[6].rightAmount.push(0)
                    secondPart[7].rightAmount.push(0)
                } else {
                    secondPart[2].leftAmount.push(0)
                    secondPart[2].rightAmount.push(thirdPart.interestOnCapital)

                    secondPart[3].rightAmount.push(deadPartner.loan)
                    secondPart[4].rightAmount.push(fourthPart.interestOnCapital)
                    secondPart[5].rightAmount.push(fifthPart.capital[0])
                    secondPart[6].rightAmount.push(fifthPart.capital[1])
                    secondPart[7].rightAmount.push(sixthPart.retiredPartnerProfit)

                }
            }

            let secondPartTotal = {
                left: [
                    secondPart[0].leftAmount[0] + secondPart[1].leftAmount[0] + secondPart[2].leftAmount[0],
                    secondPart[0].leftAmount[1] + secondPart[1].leftAmount[1] + secondPart[2].leftAmount[1],
                    secondPart[0].leftAmount[2] + secondPart[1].leftAmount[2] + secondPart[2].leftAmount[2],
                ],
                right: [
                    secondPart.reduce((a, b) => (a + b.rightAmount[0]), 0),
                    secondPart.reduce((a, b) => (a + b.rightAmount[1]), 0),
                    secondPart.reduce((a, b) => (a + b.rightAmount[2]), 0),
                ]
            }

            let alivePartnersBalance = []
            let retiredPartnersBalance = 0

            for (let i in this.state.partners) {
                if (this.state.partners[i] !== this.state.retiredPartner) {
                    secondPart[3].leftAmount.push(0)
                    secondPart[4].leftAmount.push(secondPartTotal.right[i] - secondPartTotal.left[i])

                    alivePartnersBalance.push(secondPartTotal.right[i] - secondPartTotal.left[i])
                } else {
                    secondPart[3].leftAmount.push(secondPartTotal.right[i] - secondPartTotal.left[i])
                    secondPart[4].leftAmount.push(0)

                    retiredPartnersBalance = secondPartTotal.right[i] - secondPartTotal.left[i]
                }
            }



            //#endregion SECOND PART END

            //#region ANSWER PALETTE START

            let assets = JSON.parse(JSON.stringify(this.state.assets))
            assets.splice(0, 1)

            let liabilities = []

            for (let i in this.state.liabilities) {
                if (parseInt(i) !== 0) {
                    liabilities.push({
                        type: this.state.liabilities[i].type,
                        amount: this.state.liabilities[i].amount
                    })
                }
            }

            for (let i in this.state.questionPallete) {
                for (let j in assets) {
                    if (assets[j].type === this.state.questionPallete[i].nameOne) {
                        assets.splice(j, 1)
                    }
                    if (assets[j].type === this.state.questionPallete[i].nameTwo) {
                        assets[j].amount = parseFloat(assets[j].amount) + parseFloat(assets[j].amount) * parseFloat(this.state.questionPallete[i].percent) / 100
                    }
                }
            }

            // if (assets[0] === undefined) {
            //     for (let i in this.state.assets) {
            //         if (parseInt(i) !== 0) {
            //             for (let j in this.state.questionPallete) {
            //                 if (this.state.assets[i].type === this.state.questionPallete[j].nameOne) {

            //                 } else if (this.state.assets[i].type === this.state.questionPallete[j].nameTwo) {
            //                     assets.push({
            //                         type: this.state.assets[i].type,
            //                         amount: parseFloat(this.state.assets[i].amount) + parseFloat(this.state.assets[i].amount) * parseFloat(this.state.questionPallete[j].percent) / 100
            //                     })
            //                 } else {
            //                     assets.push({
            //                         type: this.state.assets[i].type,
            //                         amount: this.state.assets[i].amount
            //                     })
            //                 }
            //             }
            //         }
            //     }
            // } else {
            //     for (let i in assets) {
            //         if (parseInt(i) !== 0) {
            //             for (let j in this.state.questionPallete) {
            //                 if (assets[i].type === this.state.questionPallete[j].nameOne) {
            //                         assets.splice(i,1)
            //                 } else if (assets[i].type === this.state.questionPallete[j].nameTwo) {
            //                     assets.amount = parseFloat(assets[i].amount) + parseFloat(assets[i].amount)*parseFloat(this.state.questionPallete[j].percent)/100
            //                 }
            //             }
            //         }
            //     }
            // }


            //#endregion ANSWER PALETTE END


            let dataObject = {
                partners: this.state.partners,
                capitalAccount: this.state.capitalAccount,
                partnersLoan: this.state.partnersLoan,
                initialProfitLossRatio: this.state.initialProfitLossRatio,
                retiredPartner: this.state.retiredPartner,
                newDate: this.state.newDate,
                alivePartnersBalance,
                retiredPartnersBalance,
                liabilities,
                assets,
                firstPart,
                totalInFirstPart,
                secondPart,
                secondPartTotal,
                thirdPart,
                fourthPart,
                fifthPart,
                sixthPart,
                deadPartner
            }

            data.push(JSON.parse(JSON.stringify(dataObject)))

            chartState.selected += 1

            this.setState({})

            // console.log(JSON.stringify(dataObject, null, 4))
        }

        onReset() {

        }

        addLiability() {
            this.state.liabilities.push({
                type: "Enter Text Here",
                amount: 10000
            })
            this.state.editModeliabilities.push({
                type: true,
                amount: true
            })
            this.setState({
                editModeButton: true
            }, () => { this.fillDetails() })
        }

        removeLiability(event) {
            this.state.liabilities.splice(event, 1)
            this.state.editModeliabilities.splice(event, 1)
            this.setState({}, () => { this.fillDetails() })
        }

        addAsset() {
            this.state.assets.push({
                type: "Enter Text Here",
                amount: 10000
            })
            this.state.editModeassets.push({
                type: true,
                amount: true
            })
            this.setState({
                editModeButton: true
            }, () => { this.fillDetails() })
        }

        removeAsset(event) {
            for (let i in this.state.questionPallete) {
                if (this.state.assets[event].type === this.state.questionPallete[i].nameOne ||
                    this.state.assets[event].type === this.state.questionPallete[i].nameTwo) {
                    this.state.questionPallete.splice(i, 1)
                }
            }

            this.state.assets.splice(event, 1)
            this.state.editModeassets.splice(event, 1)
            this.setState({}, () => { this.fillDetails() })
        }

        addQuestion() {

            for (let i of this.state.assets) {
                if (i.type === this.state.tempAssetWrittenOffName || i.type === this.state.tempAssetAppreciatedName) {
                    i.added = true
                }
            }

            let questionObject = {
                type: "writtenOffAppreciated",
                nameOne: this.state.tempAssetWrittenOffName,
                nameTwo: this.state.tempAssetAppreciatedName,
                percent: this.state.tempAmount
            }
            let editModequestionObject = {
                percent: false
            }

            this.state.questionPallete.push(questionObject)
            this.state.editModequestionPallete.push(editModequestionObject)


            this.setState({
                tempAssetWrittenOffName: '',
                tempAssetAppreciatedName: '',
                addQuestionChecker: false,
            })

            // console.log(JSON.stringify(questionObject, null, 4))
            // console.log(JSON.stringify(temporaryTemplate))
            // console.log(this.state.tempTDiscount)
        }

        removeQuestion(event) {
            for (let i in this.state.questionPallete) {
                if (parseInt(i) === parseInt(event)) {
                    for (let j in this.state.assets) {
                        if (this.state.assets[j].type === this.state.questionPallete[i].nameOne) {
                            this.state.assets[j].added = false
                        }
                        if (this.state.assets[j].type === this.state.questionPallete[i].nameTwo) {
                            this.state.assets[j].added = false
                        }
                    }
                }
            }

            this.state.questionPallete.splice(event, 1)
            this.state.editModequestionPallete.splice(event, 1)
            this.setState({})
        }

        switchTableToChartAndBack() {

        }

        showSolutionOnOff() {
            this.setState({
                solutionShow: !this.state.solutionShow
            })
        }

        moveLeftAndRight(event) {
            if (event.target.name === 'Left') {
                chartState.selected = chartState.selected - 1
                this.setState({})
            }
            else if (event.target.name === 'Right') {
                chartState.selected += 1
                this.setState({})
            }
        }

        //#endregion BUTTON PANEL END

        //#region EDIT BUTTON START

        buttonMaker(event) {
            let customArray = event.target.name.split(",")

            if (customArray.length === 2) {
                switch (customArray[0]) {
                    case 'partners':
                        this.state.editModepartners[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'initialPL':
                        this.state.editModeinitialProfitLossRatio[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'liabilitiesType':
                        this.state.editModeliabilities[customArray[1]].type = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'liabilitiesAmount':
                        this.state.editModeliabilities[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsType':
                        this.state.editModeassets[customArray[1]].type = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsAmount':
                        this.state.editModeassets[customArray[1]].amount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsOriginalAmount':
                        this.state.editModeassets[customArray[1]].originalAmount = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'assetsBadDebtR':
                        this.state.editModeassets[customArray[1]].badDebtR = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'capitalAccount':
                        this.state.editModecapitalAccount[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'partnersLoan':
                        this.state.editModepartnersLoan[customArray[1]] = true
                        this.setState({
                            editModeButton: true
                        })
                        break;

                    case 'interestOnCapital':
                        this.state.editModestaticQuestionPallete[customArray[1]].percent = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'amountFirst':
                        this.state.editModestaticQuestionPallete[customArray[1]].amountFirst = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'amountCurrent':
                        this.state.editModestaticQuestionPallete[customArray[1]].amountCurrent = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                    case 'amountNet':
                        this.state.editModestaticQuestionPallete[customArray[1]].amountNet = true
                        this.setState({
                            editModeButton: true
                        })
                        break;

                    case 'writtenOffAppreciated':
                        this.state.editModequestionPallete[customArray[1]].percent = true
                        this.setState({
                            editModeButton: true
                        })
                        break;
                }
            }

            switch (event.target.name) {
                case 'date':
                    this.state.editModedate = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'newDate':
                    this.state.editModenewDate = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
                case 'retiredPartner':
                    this.state.editModeretiredPartner = true
                    this.setState({
                        editModeButton: true
                    })
                    break;
            }

        }

        editModeOff() {

            for (let i in this.state.editModepartners) {
                this.state.editModepartners[i] = false
                this.state.editModecapitalAccount[i] = false
                this.state.editModeinitialProfitLossRatio[i] = false
                this.state.editModepartnersLoan[i] = false
            }

            for (let i of this.state.editModeliabilities) {
                i.type = false
                i.amount = false
            }

            for (let i of this.state.editModeassets) {
                i.type = false
                i.amount = false
                if (i.badDebtR) {
                    i.badDebtR = false
                }
                if (i.originalAmount) {
                    i.originalAmount = false
                }
            }

            for (let i of this.state.editModequestionPallete) {
                i.percent = false
            }

            for (let i of this.state.editModestaticQuestionPallete) {
                if (i.percent) {
                    i.percent = false
                }
                if (i.amount) {
                    i.amount = false
                }
                if (i.amountFirst) {
                    i.amountFirst = false
                }
                if (i.amountCurrent) {
                    i.amountCurrent = false
                }
                if (i.amountNet) {
                    i.amountNet = false
                }
            }

            this.setState({
                editModedate: false,
                editModenewDate: false,
                editModeretiredPartner: false,
                editModeButton: false
            })
        }

        //#endregion EDIT BUTTON END


        render() {
            return (
                <div style={{fontSize:"20px"}}>
                    <br />
                    <div label="InputFieldTables" align="left" >
                        પેઢીના ભાગીદારો: <br /><br />

                        {this.state.partners.map((x, i) => (
                            <div>
                                ({i + 1})&nbsp;
                                {
                                    this.state.editModepartners[i] ?
                                        <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["partners", i]} id='outlined-base' error={x === ''}
                                            value={x} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["partners", i]} onClick={this.buttonMaker.bind(this)}> {x}</a>
                                }<br /><br />
                            </div>
                        ))}

                        
                        {this.state.initialProfitLossRatio.map((x, i) => (
                            <span>
                                {
                                    this.state.editModeinitialProfitLossRatio[i] ?
                                        <TextField style={{ 'margin-top': "-15px", width: "5%" }} name={["initialPL", i]} id='outlined-base' error={x === '' || isNaN(x)}
                                            value={x} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                        <a className='txt' name={["initialPL", i]} onClick={this.buttonMaker.bind(this)}> {x}</a>
                                }

                                {parseInt(i) !== this.state.initialProfitLossRatio.length - 1 && " : "}</span>
                        ))} ના પ્રમાણમાં નફો-નુકસાનની વહેંચણી.&nbsp;<br /><br />

                       

                        {
                            this.state.editModedate ?
                                <TextField type="date" style={{ 'margin-top': "-15px", width: "15%" }} name="date" id='outlined-base' error={this.state.date === ''}
                                    value={this.state.date} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="date" onClick={this.buttonMaker.bind(this)}> {this.state.date}</a>
                        } ના રોજ તેમની પેઢીનું પાકું સરવૈયું નીચે મુજબ હતું :<br /><br />

                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px", fontSize:"15px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >સંપાદિત મોડ બંધ કરો</ColorButton> : <span></span>}
                        <br /><br />

                        <div align="center"><b>પાકું સરવૈયું</b></div>


                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{fontSize:"20px"}}>મૂડી-દેવાં (₹)</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize:"20px"}}>૨કમ (₹)</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize:"20px"}}>મિલકત લેણાં</StyledTableCell>
                                        <StyledTableCell align="center" style={{fontSize:"20px"}}>૨કમ (₹)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                            <b>મૂડી ખાતાં :</b><br />
                                            {this.state.partners.map((x, i) => (
                                                <span>&emsp;&emsp;{x}
                                                    <span style={{ float: "right" }}>
                                                        {
                                                            this.state.editModecapitalAccount[i] ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["capitalAccount", i]} id='outlined-base' error={this.state.capitalAccount[i] === '' || isNaN(this.state.capitalAccount[i])}
                                                                    value={this.state.capitalAccount[i]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["capitalAccount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.capitalAccount[i]}</a>
                                                        }
                                                    </span><br /></span>
                                            ))}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>{this.state.capitalAccount.reduce((a, b) => { return parseFloat(a) + parseFloat(b) })}</StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                            <b>ભાગીદારોની લોન :</b><br />
                                            {this.state.partners.map((x, i) => (
                                                <span>&emsp;&emsp;{x}
                                                    <span style={{ float: "right" }}>
                                                        {
                                                            this.state.editModepartnersLoan[i] ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["partnersLoan", i]} id='outlined-base' error={this.state.partnersLoan[i] === '' || isNaN(this.state.partnersLoan[i])}
                                                                    value={this.state.partnersLoan[i]} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["partnersLoan", i]} onClick={this.buttonMaker.bind(this)}> {this.state.partnersLoan[i]}</a>
                                                        }
                                                    </span><br /></span>
                                            ))}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>{this.state.partnersLoan.reduce((a, b) => { return parseFloat(a) + parseFloat(b) })}</StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                    </TableRow>
                                    {this.state.liabilities.length >= this.state.assets.length ?
                                        this.state.liabilities.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                    {i !== 0 && <span>
                                                        {
                                                            this.state.editModeliabilities[i].type ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["liabilitiesType", i]} id='outlined-base' error={this.state.liabilities[i].type === ''}
                                                                    value={this.state.liabilities[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["liabilitiesType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].type}</a>
                                                        }&nbsp;
                                                        <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeLiability.bind(this, i)} name="-">-</IconButton>
                                                    </span>}
                                                    {i === 0 && this.state.liabilities[i].type}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {
                                                        this.state.editModeliabilities[i].amount ?
                                                            <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["liabilitiesAmount", i]} id='outlined-base' error={this.state.liabilities[i].amount === '' || isNaN(this.state.liabilities[i].amount)}
                                                                value={this.state.liabilities[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                            <a className='txt' name={["liabilitiesAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].amount}</a>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                    {this.state.assets[i] !== undefined && <span>
                                                        {i !== 0 ? <span>
                                                            {
                                                                this.state.editModeassets[i].type ?
                                                                    <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["assetsType", i]} id='outlined-base' error={this.state.assets[i].type === ''}
                                                                        value={this.state.assets[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                    <a className='txt' name={["assetsType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].type}</a>
                                                            }&nbsp;
                                                            <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeAsset.bind(this, i)} name="-">-</IconButton>
                                                        </span> : this.state.assets[i].type}
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="right" >
                                                    {this.state.assets[i] !== undefined && <span>
                                                        {
                                                            this.state.assets[i].badDebtR === undefined ?
                                                                <span>
                                                                    {
                                                                        this.state.editModeassets[i].amount ?
                                                                            <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsAmount", i]} id='outlined-base' error={this.state.assets[i].amount === '' || isNaN(this.state.assets[i].amount)}
                                                                                value={this.state.assets[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                            <a className='txt' name={["assetsAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].amount}</a>
                                                                    }
                                                                </span> :
                                                                <span>
                                                                    <br /><br />
                                                                    {this.state.assets[i].amount}
                                                                </span>
                                                        }
                                                    </span>}
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                        :
                                        this.state.assets.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                    {this.state.liabilities[i] !== undefined && <span>
                                                        {i !== 0 && <span>
                                                            {
                                                                this.state.editModeliabilities[i].type ?
                                                                    <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["liabilitiesType", i]} id='outlined-base' error={this.state.liabilities[i].type === ''}
                                                                        value={this.state.liabilities[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                    <a className='txt' name={["liabilitiesType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].type}</a>
                                                            }&nbsp;
                                                            <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeLiability.bind(this, i)} name="-">-</IconButton>
                                                        </span>}
                                                        {i === 0 && this.state.liabilities[i].type}
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {this.state.liabilities[i] !== undefined && <span>
                                                        {
                                                            this.state.editModeliabilities[i].amount ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["liabilitiesAmount", i]} id='outlined-base' error={this.state.liabilities[i].amount === '' || isNaN(this.state.liabilities[i].amount)}
                                                                    value={this.state.liabilities[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["liabilitiesAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.liabilities[i].amount}</a>
                                                        }
                                                    </span>}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                    {i !== 0 ? <span>
                                                        {
                                                            this.state.editModeassets[i].type ?
                                                                <TextField style={{ 'margin-top': "-15px", width: "60%" }} name={["assetsType", i]} id='outlined-base' error={this.state.assets[i].type === ''}
                                                                    value={this.state.assets[i].type} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                <a className='txt' name={["assetsType", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].type}</a>
                                                        }&nbsp;
                                                        <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeAsset.bind(this, i)} name="-">-</IconButton>
                                                    </span> : this.state.assets[i].type}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {
                                                        this.state.assets[i].badDebtR === undefined ?
                                                            <span>
                                                                {
                                                                    this.state.editModeassets[i].amount ?
                                                                        <TextField style={{ 'margin-top': "-15px", width: "30%" }} name={["assetsAmount", i]} id='outlined-base' error={this.state.assets[i].amount === '' || isNaN(this.state.assets[i].amount)}
                                                                            value={this.state.assets[i].amount} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                                        <a className='txt' name={["assetsAmount", i]} onClick={this.buttonMaker.bind(this)}> {this.state.assets[i].amount}</a>
                                                                }
                                                            </span> :
                                                            <span>
                                                                <br /><br />
                                                                {this.state.assets[i].amount}
                                                            </span>
                                                    }
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                    }
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align='right'><b>{this.state.totalAssetAmount}</b></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align='right'><b>{this.state.totalLiabilityAmount}</b></StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align='center' style={{fontSize:"15px"}}><IconButton style={{ width: "3%", height: "10px" }} onClick={this.addLiability.bind(this)}>+</IconButton> મૂડી-દેવાં ઉમેરો</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align='center' style={{fontSize:"15px"}}>
                                            <IconButton style={{ width: "3%", height: "10px" }} onClick={this.addAsset.bind(this)}>+</IconButton> મિલકત-લેણાં ઉમેરો<br />
                                        </StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer><br /><br />

                        તા.&nbsp;
                        {
                            this.state.editModenewDate ?
                                <TextField type="date" style={{ 'margin-top': "-15px", width: "15%" }} name="newDate" id='outlined-base' error={this.state.newDate === ''}
                                    value={this.state.newDate} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                <a className='txt' name="newDate" onClick={this.buttonMaker.bind(this)}> {this.state.newDate}</a>
                        } ના રોજ&nbsp;

                        {this.state.editModeretiredPartner ?
                            <FormControl style={{ m: 1, width: "10%" }} size="small">
                                <InputLabel id="RP">ભાગીદાર</InputLabel>
                                <Select
                                    labelId="question"
                                    id="question"
                                    value={this.state.retiredPartner}
                                    label="Question"
                                    onChange={this.retiredPartnerChange.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    {this.state.partners.map((x, i) => (
                                        <MenuItem value={x}>{x}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> :
                            <a className='txt' name="retiredPartner" onClick={this.buttonMaker.bind(this)}> {this.state.retiredPartner}</a>
                        } નિવૃત્ત થયો.&nbsp;                       

                        ભાગીદારી કરાર મુજબ {this.state.retiredPartner} ના વહીવટકર્તાને નીચે મુજબની રકમ ચૂકવવાપાત્ર છે :

                        <br /><br />

                        <div label="Static Questions">
                            {this.state.staticQuestionPallete.map((x, i) => (
                                <span>({i + 1})&emsp;
                                    {x.type === 'interestOnCapital' &&
                                        <span>
                                            તેમની મૂડી પર વાર્ષિક&nbsp;
                                            {
                                                this.state.editModestaticQuestionPallete[i].percent ?
                                                    <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["interestOnCapital", i]} id='outlined-base' error={x.percent === '' || isNaN(x.percent) || x.percent < 0 || x.percent > 100}
                                                        value={x.percent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                    <a className='txt' name={["interestOnCapital", i]} onClick={this.buttonMaker.bind(this)}> {x.percent}</a>
                                            } % લેખે વ્યાજ.
                                        </span>
                                    }
                                    {x.type === 'share' &&
                                        <span>
                                            સામાન્ય અનામતમાં ભાગ.
                                        </span>
                                    }
                                    {x.type === 'loanInterest' &&
                                        <span>
                                            તેની લોન અને તેના પર બાકી વ્યાજ.
                                        </span>
                                    }
                                    {x.type === 'goodwillDecided' &&
                                        <span>
                                            પેઢીની પાઘડીનું મૂલ્ય ₹ {this.state.assets[0].amount} નક્કી થયું.
                                        </span>
                                    }
                                    {x.type === 'shareOfProfit' &&
                                        <span>
                                            વર્ષની શરૂઆતથી નિવૃત્તિની તારીખ સુધી પેઢીના નફામાં ભાગ ગયા વર્ષના વેચાણ અને નફાના આધારે આપવો.&nbsp;
                                            {String(parseInt(this.state.newDate.slice(0, 4)) - 1) + "-" + this.state.newDate.slice(2, 4)} ના વર્ષનું વેચાણ ₹&nbsp;
                                            {this.state.editModestaticQuestionPallete[i].amountFirst ?
                                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["amountFirst", i]} id='outlined-base' error={x.amountFirst === '' || isNaN(x.amountFirst)}
                                                    value={x.amountFirst} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["amountFirst", i]} onClick={this.buttonMaker.bind(this)}> {x.amountFirst}</a>
                                            } હતું.<br /><br />&emsp;&emsp;

                                            પ્રથમ ત્રણ માસનું ચાલુ વર્ષનું વેચાણ ₹&nbsp;
                                            {this.state.editModestaticQuestionPallete[i].amountCurrent ?
                                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["amountCurrent", i]} id='outlined-base' error={x.amountCurrent === '' || isNaN(x.amountCurrent)}
                                                    value={x.amountCurrent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["amountCurrent", i]} onClick={this.buttonMaker.bind(this)}> {x.amountCurrent}</a>
                                            } છે.<br /><br />&emsp;&emsp;

                                            વર્ષ {String(parseInt(this.state.newDate.slice(0, 4)) - 1) + "-" + this.state.newDate.slice(2, 4)} નો ચોખ્ખો નફો ₹&nbsp;
                                            {this.state.editModestaticQuestionPallete[i].amountNet ?
                                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["amountNet", i]} id='outlined-base' error={x.amountNet === '' || isNaN(x.amountNet)}
                                                    value={x.amountNet} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["amountNet", i]} onClick={this.buttonMaker.bind(this)}> {x.amountNet}</a>
                                            } હતો.
                                        </span>
                                    }
                                    <br /><br />
                                </span>
                            ))}
                        </div>

                        <div label="Questions">
                            {this.state.questionPallete.map((x, i) => (
                                <span>({this.state.staticQuestionPallete.length + 1})&nbsp;
                                    {x.type === 'writtenOffAppreciated' &&
                                        <span>
                                            {x.nameOne} પૂરેપૂરા માડી વાળવા. {x.nameTwo} ની કિંમતમાં&nbsp;
                                            {this.state.editModequestionPallete[i].percent ?
                                                <TextField style={{ 'margin-top': "-15px", width: "15%" }} name={["writtenOffAppreciated", i]} id='outlined-base' error={x.percent === '' || isNaN(x.percent) || x.percent < 0 || x.percent > 100}
                                                    value={x.percent} onChange={this.eventHandler} variant="standard" autoComplete='off' /> :
                                                <a className='txt' name={["writtenOffAppreciated", i]} onClick={this.buttonMaker.bind(this)}> {x.percent}</a>
                                            } % વધારો કરવા.
                                        </span>
                                    }
                                    <IconButton style={{ width: "3%", color: "red", height: "10px" }} onClick={this.removeQuestion.bind(this, i)} name="-">-</IconButton>

                                    <br /><br />
                                </span>
                            ))}
                        </div>
                        {this.state.retiredPartner} ના નિવૃત્તિ બાદ તા. {this.state.newDate} ના રોજનું પાકું સરવૈયું તૈયાર કરો.
                        <br /><br />

                        {this.state.editModeButton ? <ColorButton
                            disabled={this.state.editModeButtonChecker}
                            style={{ 'margin-top': "-20px", fontSize:"15px" }} align='right' onClick={this.editModeOff.bind(this)}
                        >સંપાદિત મોડ બંધ કરો</ColorButton> : <span></span>}

                        <br /><br />
                        <div label="addQuestionInputs">
                            મિલકત-લેણાં ઉમેરો જે માંડી વાળવું છે અને અને જેમાં વધારો કરવો છે :<br /><br /><br />
                            મિલકત-લેણાં જે પૂરેપૂરા માડી વાળવા :&nbsp;
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                <Select
                                    labelId="Name"
                                    id="Name"
                                    error={this.state.tempAssetWrittenOffName === ''}
                                    value={this.state.tempAssetWrittenOffName}
                                    label="Name"
                                    onChange={this.AssetWrittenOffName.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    {this.state.assets.map((x, i) => (
                                        i !== 0 && x.added !== true && <MenuItem value={x.type}>{x.type}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl><br /><br />

                            મિલકત-લેણાં જેમાં વધારો કરવો છે :&nbsp;
                            <FormControl style={{ m: 1, width: "30%" }} size="small">
                                <InputLabel id="demo-simple-select-label">નામ*</InputLabel>
                                <Select
                                    labelId="Name"
                                    id="Name"
                                    error={this.state.tempAssetAppreciatedName === ''}
                                    value={this.state.tempAssetAppreciatedName}
                                    label="Name"
                                    onChange={this.AssetAppreciatedName.bind(this)}
                                    MenuProps={MenuProps}
                                >
                                    {this.state.assets.map((x, i) => (
                                        i !== 0 && x.added !== true && <MenuItem value={x.type}>{x.type}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl><br /><br />

                            ટકા દાખલ કરો: <TextField style={{ 'margin-top': "-15px", }} id='outlined-base' name="amount" error={this.state.tempAmount === '' || isNaN(this.state.tempAmount)}
                                placeholder="ટકા દાખલ કરો*" onChange={this.addEventHandler} variant="standard" autoComplete='off' /><br /><br />

                            {this.state.addQuestionType !== 'none' && <AddButton disabled={!this.state.addQuestionChecker} style={{ width: "20%", fontSize:"20px" }} onClick={this.addQuestion.bind(this)}>પ્રશ્ન ઉમેરો</AddButton>}
                        </div>



                        <div align="left" label="buttons">
                            <br />
                            <br />
                            <AddButton style={{ width: "15%", fontSize:"20px" }} disabled={!this.state.submitChecker} onClick={this.onSubmit.bind(this)}>રકમની ગણતરી કરો</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
                            <AddButton style={{ width: "20%", fontSize:"20px" }} onClick={this.showSolutionOnOff.bind(this)}>ઉકેલ બતાવો / છુપાવો</AddButton>
                        </div>
                        <div align='right' label="sumNavigator">
                            <AddButton name="Left" onClick={this.moveLeftAndRight} disabled={chartState.selected - 1 < 0} style={{ width: "0%", minWidth: "34px" }}>◄</AddButton>
                            &nbsp;&nbsp;&nbsp;
                            {chartState.selected + 1}
                            &nbsp;&nbsp;&nbsp;
                            <AddButton name="Right" onClick={this.moveLeftAndRight} disabled={chartState.selected + 1 > data.length - 1} style={{ width: "0%", minWidth: "34px" }}>►</AddButton>
                        </div>
                    </div>
                    <br /><br />
                    {this.state.solutionShow &&
                        <div label="Answer" align="left">
                            <b>જવાબ :</b><br />
                            <div align="center"><b>પાકું સરવૈયું</b></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>મૂડી-દેવાં</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>૨કમ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>મિલકત લેણાં</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>૨કમ (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].assets.length >= data[chartState.selected].liabilities.length ?
                                            data[chartState.selected].assets.map((x, i) => (
                                                <TableRow >
                                                    <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                        {data[chartState.selected].liabilities[i] &&
                                                            <span>
                                                                {data[chartState.selected].liabilities[i].type}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {data[chartState.selected].liabilities[i] &&
                                                            <span>
                                                                {data[chartState.selected].liabilities[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell >
                                                    <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                        <span>
                                                            {data[chartState.selected].assets[i].type}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <span>
                                                            {data[chartState.selected].assets[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                </TableRow>
                                            )) :
                                            data[chartState.selected].liabilities.map((x, i) => (
                                                <TableRow >
                                                    <StyledTableCell align="left">
                                                        <span>
                                                            {data[chartState.selected].liabilities[i].type}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <span>
                                                            {data[chartState.selected].liabilities[i].amount}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {data[chartState.selected].assets[i] &&
                                                            <span>
                                                                {data[chartState.selected].assets[i].type}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {data[chartState.selected].assets[i] &&
                                                            <span>
                                                                {data[chartState.selected].assets[i].amount}
                                                            </span>
                                                        }
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))
                                        }
                                        {data[chartState.selected].fifthPart.nameLeft.map((x, i) => (
                                            <TableRow >
                                                <StyledTableCell align="left" style={{fontSize:"15px"}}>{x}ની લોન</StyledTableCell>
                                                <StyledTableCell align="right">{data[chartState.selected].fifthPart.nameLeftLoan[i]}</StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="right"></StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow >
                                            <StyledTableCell align="left" style={{fontSize:"15px"}}>{data[chartState.selected].retiredPartner}ના વહીવટકર્તાનું ખાતું</StyledTableCell>
                                            <StyledTableCell align="right">{data[chartState.selected].retiredPartnersBalance}</StyledTableCell>
                                            <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                નફા-નુકસાન ઉપલક ખાતું<br />
                                                ({data[chartState.selected].sixthPart.retiredPartnerProfit} નફો + {data[chartState.selected].thirdPart.interestOnCapital} મૂડીનું વ્યાજ + {data[chartState.selected].fourthPart.interestOnCapital} લોનનું વ્યાજ)
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>{data[chartState.selected].sixthPart.shareInProfit}</StyledTableCell>
                                        </TableRow>
                                        <TableRow >
                                            <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                <b>મૂડી ખાતાં :</b><br />
                                                {data[chartState.selected].partners.map((x, i) => (
                                                    x !== data[chartState.selected].retiredPartner &&
                                                    <span>
                                                        &emsp;&emsp;{x}<span style={{ float: "right" }}>{data[chartState.selected].secondPart[4].leftAmount[i]}</span><br />
                                                    </span>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>{data[chartState.selected].secondPart[4].leftAmount.reduce((a, b) => (a + parseFloat(b)), 0)}</StyledTableCell>
                                            <StyledTableCell align="left">
                                            </StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                        </TableRow>
                                        <TableRow >
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].sixthPart.shareInProfit + data[chartState.selected].assets.reduce((a, b) => (a + parseFloat(b.amount)), 0)}</b></StyledTableCell>
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].sixthPart.shareInProfit + data[chartState.selected].assets.reduce((a, b) => (a + parseFloat(b.amount)), 0)}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <b>સમજૂતી : (1)</b><br /><br />

                            <div align="center"><b>પુન: મૂલ્યાંકન ખાતું</b></div>
                            <span style={{ float: "left" }}>ઉધાર</span><span style={{ float: "right" }}>જમા</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>૨કમ (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>૨કમ (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].firstPart.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align="left" style={{fontSize:"15px"}}>{x.nameLeft} ખાતે</StyledTableCell>
                                                <StyledTableCell align="right">{x.amountLeft}</StyledTableCell>
                                                <StyledTableCell align="left" style={{fontSize:"15px"}}>{x.nameRight} ખાતે</StyledTableCell>
                                                <StyledTableCell align="right">{x.amountRight}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="left" style={{fontSize:"15px"}}>
                                                ખોટ : ભાગીદારોનાં મૂડી ખાતે :<br />
                                                {data[chartState.selected].totalInFirstPart.partnersProfit.map((x, i) => (
                                                    <span>&emsp;&emsp;{data[chartState.selected].partners[i]}  <span style={{ float: "right" }}>{x}</span><br /></span>
                                                ))}
                                            </StyledTableCell>
                                            <StyledTableCell align="right" style={{ verticalAlign: "bottom" }}>{data[chartState.selected].totalInFirstPart.differenceForProfit}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].totalInFirstPart.leftAmount}</b></StyledTableCell>
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="right"><b>{data[chartState.selected].totalInFirstPart.leftAmount}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <b>(2)</b><br /><br />

                            <div align="center"><b>ભાગીદારોનાં મૂડી ખાતાં</b></div>
                            <span style={{ float: "left" }}>ઉધાર</span><span style={{ float: "right" }}>જમા</span>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center">{data[chartState.selected].partners[0]} (₹)</StyledTableCell>
                                            <StyledTableCell align="center">{data[chartState.selected].partners[1]} (₹)</StyledTableCell>
                                            <StyledTableCell align="center">{data[chartState.selected].partners[2]} (₹)</StyledTableCell>
                                            <StyledTableCell align="center" style={{fontSize:"20px"}}>વિગત</StyledTableCell>
                                            <StyledTableCell align="center">{data[chartState.selected].partners[0]} (₹)</StyledTableCell>
                                            <StyledTableCell align="center">{data[chartState.selected].partners[1]} (₹)</StyledTableCell>
                                            <StyledTableCell align="center">{data[chartState.selected].partners[2]} (₹)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data[chartState.selected].secondPart.map((x, i) => (
                                            <TableRow>
                                                <StyledTableCell align='left' style={{fontSize:"15px"}}>
                                                    {parseInt(i) === 0 &&
                                                        <span>
                                                            પુન: મૂલ્યાંકન ખાતે
                                                        </span>
                                                    }
                                                    {parseInt(i) === 1 &&
                                                        <span>
                                                            પાઘડી ખાતે
                                                        </span>
                                                    }
                                                    {parseInt(i) === 2 &&
                                                        <span>
                                                            {data[chartState.selected].retiredPartner}ના મૂડી ખાતે
                                                        </span>
                                                    }
                                                    {parseInt(i) === 3 &&
                                                        <span>
                                                            {data[chartState.selected].retiredPartner}ના વહીવટર્ડ ખાતે
                                                        </span>
                                                    }
                                                    {parseInt(i) === 4 &&
                                                        <span>
                                                            બા. આ. લઈ ગયા
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right'>
                                                    {x.leftAmount &&
                                                        <span>
                                                            {x.leftAmount[0] === 0 ? "-" : x.leftAmount[0]}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right'>
                                                    {x.leftAmount &&
                                                        <span>
                                                            {x.leftAmount[1] === 0 ? "-" : x.leftAmount[1]}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right'>
                                                    {x.leftAmount &&
                                                        <span>
                                                            {x.leftAmount[2] === 0 ? "-" : x.leftAmount[2]}
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='left' style={{fontSize:"15px"}}>
                                                    {parseInt(i) === 0 &&
                                                        <span>
                                                            બાકી આગળ લાવ્યા
                                                        </span>
                                                    }
                                                    {parseInt(i) === 1 &&
                                                        <span>
                                                            સામાન્ય અના. ખાતે
                                                        </span>
                                                    }
                                                    {parseInt(i) === 2 &&
                                                        <span>
                                                            મૂડી પર વ્યાજ ખાતે
                                                        </span>
                                                    }
                                                    {parseInt(i) === 3 &&
                                                        <span>
                                                            {data[chartState.selected].retiredPartner}ની લોન ખાતે
                                                        </span>
                                                    }
                                                    {parseInt(i) === 4 &&
                                                        <span>
                                                           {data[chartState.selected].retiredPartner}ની લોનના વ્યાજ ખાતે
                                                        </span>
                                                    }
                                                    {parseInt(i) === 5 &&
                                                        <span>
                                                            {data[chartState.selected].fifthPart.nameLeft[0]}ના મૂડી ખાતે
                                                        </span>
                                                    }
                                                    {parseInt(i) === 6 &&
                                                        <span>
                                                            {data[chartState.selected].fifthPart.nameLeft[1]}ના મૂડી ખાતે
                                                        </span>
                                                    }
                                                    {parseInt(i) === 7 &&
                                                        <span>
                                                            ન. નુ. ઉપલક ખાતે
                                                        </span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align='right'>
                                                    {x.rightAmount[0] === 0 ? "-" : x.rightAmount[0]}
                                                </StyledTableCell>
                                                <StyledTableCell align='right'>
                                                    {x.rightAmount[1] === 0 ? "-" : x.rightAmount[1]}
                                                </StyledTableCell>
                                                <StyledTableCell align='right'>
                                                    {x.rightAmount[2] === 0 ? "-" : x.rightAmount[2]}
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].secondPartTotal.right[0]}</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].secondPartTotal.right[1]}</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].secondPartTotal.right[2]}</b></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].secondPartTotal.right[0]}</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].secondPartTotal.right[1]}</b></StyledTableCell>
                                            <StyledTableCell align='right'><b>{data[chartState.selected].secondPartTotal.right[2]}</b></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer><br /><br />

                            <b>(3) {data[chartState.selected].thirdPart.name} ની મૂડી પર વ્યાજ {data[chartState.selected].thirdPart.firstDate} થી {data[chartState.selected].thirdPart.secondDate} સુધી {data[chartState.selected].thirdPart.months} માસનું</b><br /><br />
                            &emsp;&ensp;= ₹ {data[chartState.selected].thirdPart.capital} X <sup>{data[chartState.selected].thirdPart.pa}</sup>/<sub>100</sub> X <sup>{data[chartState.selected].thirdPart.months}</sup>/<sub>12</sub> = ₹ {data[chartState.selected].thirdPart.interestOnCapital}<br /><br />

                            <b>(4) {data[chartState.selected].fourthPart.name} ની લોન પર બાકી વ્યાજ {data[chartState.selected].thirdPart.months} માસનું {data[chartState.selected].fourthPart.pa} % લેખે (ભાગીદાર કરારમાં ઉલ્લેખ ન હોવાથી)</b><br /><br />
                            &emsp;&ensp;= ₹ {data[chartState.selected].fourthPart.loan} X <sup>{data[chartState.selected].fourthPart.pa}</sup>/<sub>100</sub> X <sup>{data[chartState.selected].thirdPart.months}</sup>/<sub>12</sub> = ₹ {data[chartState.selected].fourthPart.interestOnCapital}<br /><br />

                            <b>(5) પાઘડીમાં {data[chartState.selected].thirdPart.name} નો ભાગ અને તેની વહેંચણી</b><br /><br />
                            &emsp;&ensp;{data[chartState.selected].partners[0]}, {data[chartState.selected].partners[1]} અને {data[chartState.selected].partners[2]} નું જૂનું પ્રમાણ = {data[chartState.selected].initialProfitLossRatio[0]}:{data[chartState.selected].initialProfitLossRatio[1]}:{data[chartState.selected].initialProfitLossRatio[2]}<br /><br />
                            &emsp;&ensp;{data[chartState.selected].thirdPart.name} નિવૃત્ત થવાથી {data[chartState.selected].fifthPart.nameLeft[0]} અને {data[chartState.selected].fifthPart.nameLeft[1]} ના લાભનું પ્રમાણ = {data[chartState.selected].fifthPart.profitLossRatioLeft[0]}:{data[chartState.selected].fifthPart.profitLossRatioLeft[1]}<br /><br />
                            &emsp;&ensp;{data[chartState.selected].thirdPart.name} નો પાઘડીમાં ભાગ = <sup>{data[chartState.selected].deadPartner.ratio}</sup>/<sub>{data[chartState.selected].initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0)}</sub> X {data[chartState.selected].fifthPart.goodwill} = ₹ {data[chartState.selected].fifthPart.shareOfGoodwill}<br /><br />
                            &emsp;&ensp;જે {data[chartState.selected].fifthPart.nameLeft[0]} અને {data[chartState.selected].fifthPart.nameLeft[1]} ના પ્રમાણમાં આપશે {data[chartState.selected].fifthPart.profitLossRatioLeft[0]}:{data[chartState.selected].fifthPart.profitLossRatioLeft[1]}.<br /><br />

                            &emsp;&ensp;આમનોંધ :<br /><br />
                            &emsp;&ensp;{data[chartState.selected].fifthPart.nameLeft[0]}ના મૂડી ખાતે...ઉ&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{data[chartState.selected].fifthPart.capital[0]}<br /><br />
                            &emsp;&ensp;{data[chartState.selected].fifthPart.nameLeft[1]}ના મૂડી ખાતે...ઉ&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{data[chartState.selected].fifthPart.capital[1]}<br /><br />
                            &emsp;&ensp;&emsp;&ensp;તે {data[chartState.selected].thirdPart.name}ના મૂડી ખાતે&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{data[chartState.selected].fifthPart.shareOfGoodwill}<br /><br />

                            <b>(6) તા. {data[chartState.selected].thirdPart.firstDate} થી {data[chartState.selected].thirdPart.secondDate} સુધી {data[chartState.selected].thirdPart.months} માસના પેઢીના નફામાં {data[chartState.selected].thirdPart.name} નો ભાગ :</b><br /><br />
                            &emsp;&ensp;{String(parseInt(data[chartState.selected].thirdPart.firstDate.slice(0, 4)) - 1) + "-" + data[chartState.selected].thirdPart.firstDate.slice(2, 4)} નું વેચાણ ₹ {data[chartState.selected].sixthPart.sales} : ચોખ્ખો નફો ₹ {data[chartState.selected].sixthPart.netProfit}<br /><br />
                            &emsp;&ensp;{data[chartState.selected].thirdPart.firstDate.slice(0, 4) + "-" + String(parseInt(data[chartState.selected].thirdPart.firstDate.slice(2, 4)) + 1)} માં {data[chartState.selected].deadPartner.monthGap} માસનું વેચાણ {data[chartState.selected].thirdPart.firstDate.slice(0, 4) + "-" + String(parseInt(data[chartState.selected].thirdPart.firstDate.slice(2, 4)) + 1)} ₹ {data[chartState.selected].sixthPart.monthSales} : (?)<br /><br />

                            &emsp;&ensp;<sup>({data[chartState.selected].sixthPart.netProfit} X {data[chartState.selected].sixthPart.monthSales})</sup>/<sub>{data[chartState.selected].sixthPart.sales}</sub> = ₹ {data[chartState.selected].sixthPart.monthProfit} ({data[chartState.selected].sixthPart.months} ત્રણ માસનો નફો)<br /><br />
                            &emsp;&ensp;{data[chartState.selected].thirdPart.name}નો નફામાં ભાગ = {data[chartState.selected].sixthPart.monthProfit} X <sup>{data[chartState.selected].deadPartner.ratio}</sup>/<sub>{data[chartState.selected].initialProfitLossRatio.reduce((a, b) => (a + parseFloat(b)), 0)}</sub> = ₹ {data[chartState.selected].sixthPart.retiredPartnerProfit}<br /><br />

                            &emsp;&ensp;<b>નોંધ :</b> {data[chartState.selected].thirdPart.name}ને ચૂકવવાપાત્ર નફો, મૂડી પર વ્યાજ અને લોન પર વ્યાજ નફા-નુકસાન ઉપલક ખાતે લઈ જવામાં આવશે અને પાકા સરવૈયાની મિલકત બાજુ દર્શાવાશે.<br /><br />
                            &emsp;&ensp;મૂડી પર વ્યાજ&emsp;&emsp; ₹ {data[chartState.selected].thirdPart.interestOnCapital}<br />
                            &emsp;&ensp;+ લોન પર વ્યાજ&emsp;&emsp;&nbsp; ₹ {data[chartState.selected].fourthPart.interestOnCapital}<br />
                            &emsp;&ensp;+ નફામાં ભાગ&emsp;&emsp;&emsp; ₹ {data[chartState.selected].sixthPart.retiredPartnerProfit}<br />
                            &emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;<b>₹ {data[chartState.selected].sixthPart.shareInProfit}</b>
                        </div>
                    }
                    <br />
                    <br />
                </div >
            )
        }
    }

    return (
        <div>
            <Container fixed>
                <OperatingRatio />
            </Container>
        </div>
    );
}

import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

import "../../MelzoLibrary/style.css";

import { AddButton, ColorButton } from "../../MelzoLibrary/StyledComponents";
import { Container } from "@mui/system";

import { Button, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import "react-datepicker/dist/react-datepicker.css";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    align: "center",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "3rem",
    padding: "1px 1px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
    "&.MuiInputLabel-root": { shrink: "true" },
  },
}));

const BootstrapInputForString = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "15rem",
    padding: "1px 1px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
    "&.MuiInputLabel-root": { shrink: "true" },
  },
}));

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function DomainCodomainRange() {
  const data = [
    {
      domain: [-2, -1, 0],
      stepsOfFunction: [
        { x: -2, aXb: 4, c: 1, ans: 5 },
        { x: -1, aXb: 1, c: 1, ans: 2 },
        { x: 0, aXb: 0, c: 1, ans: 1 },
      ],
      co_domain: "N",
      range: ["5,", "2,", "1,"],
      setA: "A",
      setB: "N",
      stringoFfunction: "",
      a: 1,
      aP: 2,
      b: 0,
      bP: 0,
      c: 1,
      loweLimitRange: -2,
      upperLimitRange: 1,
      loweLimitRangeComparator: "≤",
      upperRangeComparator: "<",
      xBelongsTo: "Z",
      errorMessage: "Every thing is OK!",
      limitation: false,
    },
  ];

  const NormalNumberRegex = new RegExp(/^[0-9]+$/);

  const NumberRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);

  const chartState = {
    selected: 0,
  };

  // const [value, onChange] = useState(new Date());
  // const [value2, onChange2] = useState(new Date());

  class OperatingRatio extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        equationVariables: {
          a: 1,
          aP: 2,
          b: 2,
          bP: 1,
          c: 3,
          loweLimitRange: -2,
          upperLimitRange: 1,
          setA: "A",
          setB: "N",
          loweLimitRangeComparator: "≤",
          upperRangeComparator: "<",
          xBelongsTo: "Z",
        },
        addWithoutLimitationQuestion: false,
        errorMessage: false,

        NumberOfElements: 15,
        size: 5,
        numberOfUnits: 50,
        sampleString: "62 25 6 60 95 55 98 11 71 25 20 45 89 27 40",
        sampleStringArray: [62, 25, 6, 60, 95, 55, 98, 11, 71, 25, 20, 45, 89, 27, 40],
        sampleStringLength: 15,

        editModename: false,
        editModetype: false,
        editModesampleString: false,
        solutionShow: false,
        // tableModeChartModeSwitch: true,
        editModeButtonChecker: false,
        editModeButton: false,
        submitChecker: true,
      };
      this.eventHandler = this.eventHandler.bind(this);
      this.editModeOff = this.editModeOff.bind(this);
      this.stringReducer = this.stringReducer.bind(this);
      this.autoSave = this.autoSave.bind(this);
      this.moveLeftAndRight = this.moveLeftAndRight.bind(this);
      this.setCheckBoxValue = this.setCheckBoxValue.bind(this);
      this.selectwithoutRangeValue = this.selectwithoutRangeValue.bind(this);
    }

    //#region EVENT FUNCTIONALITIES START

    eventHandler(event) {
      const key = event.target.name;
      const value = Number(event.target.value);

      const updatedValue = { ...this.state.equationVariables, [key]: value };

      console.log(
        "🚀 ~ file: DomainCo_domainRange.js ~ line 194 ~ OperatingRatio ~ eventHandler ~ updatedValue",
        updatedValue
      );

      this.setState(
        {
          equationVariables: updatedValue,
        },
        () => {
          this.fillDetails();
        }
      );

      switch (key) {
        case "sampleString":
          this.setState(
            {
              sampleString: this.stringReducer(event.target.value),
            },
            () => {
              this.fillDetails();
            }
          );
          break;
      }
    }

    selectwithoutRangeValue(event) {
      this.setState({
        addWithoutLimitationQuestion: event.target.checked,
        errorMessage: false,
      });
    }

    setAChange(event) {
      this.state.equationVariables.setA = event.target.value;

      if (event.target.value !== "A") {
        this.state.equationVariables.xBelongsTo = event.target.value;
      }

      this.setState({}, () => {
        this.fillDetails();
      });
    }

    setBChange(event) {
      this.state.equationVariables.setB = event.target.value;
      this.setState({}, () => {
        this.fillDetails();
      });
    }

    setCheckBoxValue(event) {
      const key = event.target.name;
      const value = event.target.value;

      // const updatedValue = {};

      // if (key == "setA" && value !== "A") {
      //   updatedValue = { ...this.state.equationVariables, ["xBelongsTo"]: value };
      // } else {
      //   updatedValue = { ...this.state.equationVariables };
      // }

      const updatedValue = { ...this.state.equationVariables, [key]: value };

      console.log(
        "🚀 ~ file: DomainCo_domainRange.js ~ line 194 ~ OperatingRatio ~ eventHandler ~ updatedValue",
        updatedValue
      );

      this.setState(
        {
          equationVariables: updatedValue,
        },
        () => {
          this.fillDetails();
        }
      );
    }

    handleChange(event) {
      this.setState({
        type: event.target.value,
      });
    }

    stringReducer(string) {
      let stringArray = string.split(" ");

      for (let i in stringArray) {
        if (!NormalNumberRegex.test(stringArray[i]) || stringArray[i] === "") {
          // console.log(i)
          stringArray.splice(i, 1);
        }
      }

      if (stringArray[0] === null) {
        stringArray[0] = 0;
      }

      this.setState({
        sampleStringLength: stringArray.length,
        sampleStringArray: stringArray,
      });

      return string;
    }

    fillDetails() {
      let flag = true;

      if (this.state.name === "") {
        flag = false;
      }

      if (flag === true) {
        this.setState({
          editModeButtonChecker: !flag,
        });
      } else {
        this.setState({
          editModeButtonChecker: !flag,
        });
      }
    }

    autoSave(event) {}

    //#endregion EVENT FUNCTIONALTIES END

    //#region BUTTON PANEL START

    onSubmit() {
      // equationVariables: { a: 1, aP: 2, b: 2, bP: 1, c: 3, loweLimitRange: -2, upperLimitRange: 1 },
      this.setState({ errorMessage: false });

      try {
        let a = this.state.equationVariables.a;
        let aP = this.state.equationVariables.aP;
        let b = this.state.equationVariables.b;
        let bP = this.state.equationVariables.bP;
        let c = this.state.equationVariables.c;
        let loweLimitRange = Number(this.state.equationVariables.loweLimitRange);
        let upperLimitRange = Number(this.state.equationVariables.upperLimitRange);
        let setA = this.state.equationVariables.setA;
        let setB = this.state.equationVariables.setB;
        let limitation = this.state.addWithoutLimitationQuestion;
        let loweLimitRangeComparator = this.state.equationVariables.loweLimitRangeComparator;
        let upperRangeComparator = this.state.equationVariables.upperRangeComparator;
        let xBelongsTo = this.state.equationVariables.xBelongsTo;

        let arrayOfDomain;
        let functionSteps = [];
        let arrayOFRange = [];

        let stringofArray;

        let range = [];
        let errorMessage;

        if (loweLimitRange > upperLimitRange) {
          throw new Error("Lower limit is Greater then Upperlimit");
        }

        let domain = () => {
          console.log("xBelongsTo limitation", limitation, xBelongsTo);
          // condition first
          if (xBelongsTo == "Z" && limitation == true) {
            console.log("xBelongsTo", xBelongsTo);
            let negativeValues = Array.from(Array(Math.abs(5)).keys());
            negativeValues.shift();
            const negativeVAlue = negativeValues.map((a) => `-${a}`);
            const positive = negativeVAlue.map((a) => Number(a));
            range.push(...positive.reverse());

            let positiveValues = Array.from(Array(Math.abs(5)).keys());
            range.push(...positiveValues);
          }
          // condition second

          if (xBelongsTo == "W" && limitation == true) {
            let positiveValues = Array.from(Array(Math.abs(5)).keys());
            range.push(...positiveValues);
          }
          // condition third

          if (xBelongsTo == "N" && limitation == true) {
            let positiveValues = Array.from(Array(Math.abs(6)).keys());
            positiveValues.shift();
            range.push(...positiveValues);
          }
          // condition forth

          if (xBelongsTo == "Z" && limitation == false) {
            if (loweLimitRange <= 0) {
              if (loweLimitRangeComparator == "≤") {
                let negativeValues = Array.from(Array(Math.abs(loweLimitRange - 1)).keys());
                negativeValues.shift();
                const negativeVAlue = negativeValues.map((a) => `-${a}`);
                const positive = negativeVAlue.map((a) => Number(a));
                range.push(...positive.reverse());
              }
              if (loweLimitRangeComparator == "<") {
                let negativeValues = Array.from(Array(Math.abs(loweLimitRange)).keys());
                negativeValues.shift();
                const negativeVAlue = negativeValues.map((a) => `-${a}`);
                const positive = negativeVAlue.map((a) => Number(a));
                range.push(...positive.reverse());
              }
            }
            if (loweLimitRange > upperLimitRange) {
              throw new Error("Lower limit is Greater then Upperlimit");
            }

            if (upperLimitRange >= 0) {
              if (upperRangeComparator == "≤") {
                let positiveValues = Array.from(Array(Math.abs(upperLimitRange + 1)).keys());
                range.push(...positiveValues);
              }
              if (upperRangeComparator == "<") {
                let positiveValues = Array.from(Array(Math.abs(upperLimitRange)).keys());
                range.push(...positiveValues);
              }
            }
          }

          // CONDITION FIFTH
          if ((xBelongsTo == "N" && limitation == false) || (xBelongsTo == "W" && limitation == false)) {
            let tempRange = [];

            if (xBelongsTo == "N" && loweLimitRange < 1) {
              throw new Error(
                "You are selecting Natural number as a Domain means setA but you are select lower limt value is less one Natural number range is from  ( 1,2,3,4,5,6,.....,∞ )  "
              );
            }

            if (xBelongsTo == "W" && upperLimitRange < 0) {
              throw new Error(
                "You are selecting whole number as a Domain means setA but you are select upper limt value is less than zero, Whole number range is from  ( 0,1,2,3,4,5,6,.....,∞ )  "
              );
            }

            if (xBelongsTo == "W" && loweLimitRange < 0) {
              throw new Error(
                "You are selecting whole number as a Domain means setA but you are select lower limt value is less than zero, Whole number range is from  ( 0,1,2,3,4,5,6,.....,∞ )  "
              );
            }

            if (loweLimitRangeComparator == "≤" && upperRangeComparator == "≤") {
              let i = loweLimitRange;

              while (i <= upperLimitRange) {
                tempRange.push(i + 1);
                i++;
              }
            }
            if (loweLimitRangeComparator == "≤" && upperRangeComparator == "<") {
              let i = loweLimitRange;

              while (i <= upperLimitRange - 1) {
                tempRange.push(i + 1);
                i++;
              }
            }
            if (loweLimitRangeComparator == "<" && upperRangeComparator == "≤") {
              let i = loweLimitRange - 1;

              while (i <= upperLimitRange) {
                tempRange.push(i + 1);
                i++;
              }
            }
            if (loweLimitRangeComparator == "<" && upperRangeComparator == "<") {
              let i = loweLimitRange - 1;

              while (i <= upperLimitRange - 1) {
                tempRange.push(i + 1);
                i++;
              }
            }

            range.push(...tempRange);
          }

          console.log(" ~ range", range);

          stringofArray = range.map((a) => `${a},`);
          // console.log(" ~ stringofArray", stringofArray);

          if (xBelongsTo == "Z" && limitation == true) {
            stringofArray = [".....", ...stringofArray, "....."];
          }
          if (xBelongsTo == "W" && limitation == true) {
            stringofArray = [...stringofArray, "....."];
          }
          if (xBelongsTo == "N" && limitation == true) {
            stringofArray = [...stringofArray, "....."];
          }
          stringofArray = JSON.stringify(stringofArray);
          console.log(" ~ stringofArray", stringofArray);

          arrayOfDomain = range;
        };

        domain();

        const stringoFfunction = JSON.parse(
          JSON.stringify(`&nbsp; ${a}<i>x</i><sup>${aP}</sup> + &nbsp; ${b}<i>x</i><sup>${bP}</sup> + &nbsp; ${c}`)
        );
        // console.log(
        //   "🚀 ~ file: DomainCo_domainRange.js ~ line 328 ~ OperatingRatio ~ onSubmit ~ stringoFfunction",
        //   stringoFfunction
        // );

        const equations = (arrayOfDomain) => {
          arrayOfDomain.map((x, i) => {
            const equation = a * x ** aP + b * x ** bP + c;

            const stringOfSteps = { x: x, aXb: a * x ** aP + b * x ** bP, c: c, ans: equation };

            console.log("🚀 stringOfSteps", stringOfSteps);
            // const stringOfSteps1 = `<li>For <i>x</i> = ${x}, &#402; (${x}) = &nbsp; ${
            //   a * x ** aP + b * x ** bP
            // } &nbsp;+ &nbsp; ${c}&nbsp; = &nbsp; ${equation} </li>`;

            functionSteps.push(stringOfSteps);
            if (i !== 0) {
              arrayOFRange.push(",");
            }
            arrayOFRange.push(equation.toString());
          });
        };
        equations(arrayOfDomain);

        console.log("🚀 ~ file: DomainCo_domainRange.js ~ line 287 ~ OperatingRatio ~ onSubmit ~ Range", range);

        let dataObject = {
          domain: arrayOfDomain,
          stepsOfFunction: functionSteps,
          range: arrayOFRange,
          setA: setA,
          setB: setB,
          stringoFfunction: stringoFfunction,
          a: a,
          aP: aP,
          b: b,
          bP: bP,
          c: c,
          loweLimitRange: loweLimitRange,
          upperLimitRange: upperLimitRange,
          loweLimitRangeComparator: loweLimitRangeComparator,
          upperRangeComparator: upperRangeComparator,
          xBelongsTo: xBelongsTo,
          limitation: limitation,
          //   errorMessage: errorMessage,
        };

        console.log("🚀 dataObject", dataObject);

        data.push(dataObject);

        chartState.selected += 1;

        this.setState({});
      } catch (e) {
        this.setState({ errorMessage: e.message });
      }
    }

    onReset() {}

    switchTableToChartAndBack() {}

    showSolutionOnOff() {
      this.setState({
        solutionShow: !this.state.solutionShow,
      });
    }

    moveLeftAndRight(event) {
      if (event.target.name === "Left") {
        chartState.selected = chartState.selected - 1;
        this.setState({});
      } else if (event.target.name === "Right") {
        chartState.selected += 1;
        this.setState({});
      }
    }

    //#endregion BUTTON PANEL END

    //#region EDIT BUTTON START

    buttonMaker(event) {
      if (!event.target.checked) {
        this.setState({
          submitChecker: true,
          editModename: false,
          editModetype: false,
          editModesampleString: false,
          editModeButton: false,
          errorMessage: false,
        });
      }

      if (event.target.checked) {
        this.setState({
          editModename: true,
          editModesampleString: true,
          editModeButton: true,
          submitChecker: false,
          errorMessage: false,
        });
      }
    }

    editModeOff() {
      this.setState({
        sampleString: this.state.sampleStringArray.reduce((a, b) => {
          return a + " " + b;
        }),

        submitChecker: true,

        editModename: false,
        editModetype: false,
        editModesampleString: false,
        editModeButton: false,
      });
    }

    //#endregion EDIT BUTTON END

    render() {
      return (
        <div>
          <br />
          <div label="InputFieldTables" align="left">
            Find domain, co-domain and range for the following function :
            <br />
            <br />
            <Box sx={{ width: "100%" }}>
              <Grid container rowSpacing={0}>
                <Grid item xs={12} md={3}>
                  Syntax of equation :
                </Grid>
                <Grid item xs={12} md={8} style={{ justifyContent: "flex-start" }}>
                  &emsp;&emsp;&#402;(<i>x</i>) =&nbsp;&nbsp; a<i>x</i> <sup>power</sup>
                  &nbsp;&nbsp; + &nbsp;&nbsp; b &nbsp;&nbsp;
                  <i>x</i>
                  &nbsp;&nbsp; +&nbsp;&nbsp; c
                </Grid>
              </Grid>
            </Box>
            <br />
            Enable Edit Mode <BpCheckbox checked={this.state.editModename} onChange={this.buttonMaker.bind(this)} />
            {/* Syntax of equation : &emsp;&emsp;&#402;(<i>x</i>) =&nbsp;&nbsp; a<i>x</i> <sup>power</sup>
                &nbsp;&nbsp; + &nbsp;&nbsp; b &nbsp;&nbsp;
                <i>x</i>
                &nbsp;&nbsp; +&nbsp;&nbsp; c */}
            <br />
            <br />
            &#402; &nbsp; : &nbsp; &nbsp;&nbsp;
            {this.state.editModename ? (
              <FormControl style={{ width: "9%" }}>
                <InputLabel id="demo-simple-select-label">Select setA</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="setA"
                  value={this.state.equationVariables.setA}
                  label="Option to Add"
                  input={<BootstrapInput />}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.setAChange.bind(this)}
                >
                  <MenuItem value={"A"}>A</MenuItem>
                  <MenuItem value={"Z"}>z</MenuItem>
                  <MenuItem value={"N"}>N</MenuItem>
                  <MenuItem value={"W"}>w</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <a className="txt" name="setA" onClick={this.buttonMaker.bind(this)}>
                {this.state.equationVariables.setA}
              </a>
            )}
            &#x2192;
            {/* till here f : A--> COMPLETED */}
            {this.state.editModename ? (
              <FormControl style={{ width: "9%" }}>
                <InputLabel id="demo-simple-select-label">Select setB</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="setB"
                  disabled
                  value={this.state.equationVariables.setB}
                  label="Option to Add"
                  input={<BootstrapInput />}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.setBChange.bind(this)}
                >
                  <MenuItem value={"A"}>A</MenuItem>
                  <MenuItem value={"Z"}>z</MenuItem>
                  <MenuItem selected value={"N"}>
                    N
                  </MenuItem>
                  <MenuItem value={"W"}>w</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <a className="txt" name="setB" onClick={this.buttonMaker.bind(this)}>
                {this.state.equationVariables.setB}
              </a>
            )}
            , {/* till here f : A--> N COMPLETED */}
            &emsp;&#402;(<i>x</i>) =&nbsp;&nbsp;
            {this.state.editModename ? (
              <BootstrapInput
                // defaultValue="12"
                name="a"
                type="number"
                value={this.state.equationVariables.a}
                onChange={this.eventHandler}
                variant="standard"
                id="bootstrap-input3"
              />
            ) : (
              <a className="txt" name="a" onClick={this.buttonMaker.bind(this)}>
                {this.state.equationVariables.a}
              </a>
            )}
            &nbsp;&nbsp;
            <i>x</i>{" "}
            <sup>
              {this.state.editModename ? (
                <BootstrapInput
                  // defaultValue="12"
                  name="aP"
                  value={this.state.equationVariables.aP}
                  type="number"
                  onChange={this.eventHandler}
                  id="bootstrap-input"
                />
              ) : (
                <a className="txt" name="numberOfUnits" onClick={this.buttonMaker.bind(this)}>
                  {this.state.equationVariables.aP}
                </a>
              )}
            </sup>{" "}
            &nbsp;&nbsp; + &nbsp;&nbsp;
            {this.state.editModename ? (
              <BootstrapInput
                // defaultValue="12"
                name="b"
                value={this.state.equationVariables.b}
                type="number"
                onChange={this.eventHandler}
                id="bootstrap-input4"
              />
            ) : (
              <a className="txt" name="numberOfUnits" onClick={this.buttonMaker.bind(this)}>
                {this.state.equationVariables.b}
              </a>
            )}
            &nbsp;&nbsp;
            <i>x</i>{" "}
            {/* <sup>
              power{" "}
              {this.state.editModename ? (
                <BootstrapInput defaultValue="12" name="bP" type="number" id="bootstrap-input" />
              ) : (
                <a className="txt" name="numberOfUnits" onClick={this.buttonMaker.bind(this)}>
                  {this.state.equationVariables.bP}
                </a>
              )}
            </sup> */}
            &nbsp;&nbsp; +&nbsp;&nbsp;
            {this.state.editModename ? (
              <BootstrapInput
                // defaultValue="12"
                name="c"
                value={this.state.equationVariables.c}
                onChange={this.eventHandler}
                type="number"
                id="bootstrap-input5"
              />
            ) : (
              <a className="txt" name="numberOfUnits" onClick={this.buttonMaker.bind(this)}>
                {this.state.equationVariables.c}
              </a>
            )}
            &nbsp;,
            {/* till here f : A--> N , f(x)= ax2 + bx + c, COMPLETED */} &nbsp;
            {!this.state.addWithoutLimitationQuestion && (
              <>
                A =&nbsp; &#x7b; <i>x</i>&nbsp;| &nbsp;&nbsp;
                {this.state.editModename ? (
                  <BootstrapInput
                    // defaultValue="12"
                    name="loweLimitRange"
                    type="number"
                    value={this.state.equationVariables.loweLimitRange}
                    onChange={this.eventHandler}
                    id="bootstrap-input2"
                  />
                ) : (
                  <a className="txt" name="loweLimitRange" onClick={this.buttonMaker.bind(this)}>
                    {this.state.equationVariables.loweLimitRange}
                  </a>
                )}
                &nbsp;&nbsp;
                {this.state.editModename ? (
                  <FormControl style={{ width: "5%" }}>
                    {/* <InputLabel id="demo-simple-select-label">Select setB</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select-loweLimitRangeComparator"
                      name="loweLimitRangeComparator"
                      value={this.state.equationVariables.loweLimitRangeComparator}
                      // label="Option to Add"
                      input={<BootstrapInput />}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.setCheckBoxValue.bind(this)}
                    >
                      <MenuItem value={"≤"}>≤</MenuItem>
                      <MenuItem value={"<"}>&#x3c;</MenuItem>
                      {/* <MenuItem value={"="}>=</MenuItem> */}
                    </Select>
                  </FormControl>
                ) : (
                  <a className="txt" name="loweLimitRangeComparator" onClick={this.buttonMaker.bind(this)}>
                    {this.state.equationVariables.loweLimitRangeComparator}
                  </a>
                )}
                <i>x</i>
                &nbsp;&nbsp; &nbsp;&nbsp;
                {this.state.editModename ? (
                  <FormControl style={{ width: "5%" }}>
                    {/* <InputLabel id="demo-simple-select-label">Select setB</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select-upperRangeComparator"
                      name="upperRangeComparator"
                      value={this.state.equationVariables.upperRangeComparator}
                      // label="Option to Add"
                      input={<BootstrapInput />}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.setCheckBoxValue.bind(this)}
                    >
                      <MenuItem value={"≤"}>≤</MenuItem>
                      <MenuItem value={"<"}>&#x3c;</MenuItem>
                      {/* <MenuItem value={"="}>=</MenuItem> */}
                    </Select>
                  </FormControl>
                ) : (
                  <a className="txt" name="upperRangeComparator" onClick={this.buttonMaker.bind(this)}>
                    {this.state.equationVariables.upperRangeComparator}
                  </a>
                )}
                &nbsp;
                {this.state.editModename ? (
                  <BootstrapInput
                    // defaultValue="12"
                    name="upperLimitRange"
                    type="number"
                    value={this.state.equationVariables.upperLimitRange}
                    onChange={this.eventHandler}
                    id="bootstrap-input1"
                  />
                ) : (
                  <a className="txt" name="upperLimitRange" onClick={this.buttonMaker.bind(this)}>
                    {this.state.equationVariables.upperLimitRange}
                  </a>
                )}
                &nbsp;&nbsp;,
              </>
            )}
            <i>x</i> &#x220A;{" "}
            {this.state.editModename ? (
              <FormControl style={{ width: "5%" }}>
                {/* <InputLabel id="demo-simple-select-label">Select setB</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-xBelongsTo5"
                  name="xBelongsTo"
                  disabled={this.state.equationVariables.setA == "A" ? false : true}
                  value={this.state.equationVariables.xBelongsTo}
                  input={<BootstrapInput />}
                  InputLabelProps={{
                    style: { fontSize: 18, color: "grey", backgroundColor: "white", fontFamily: "monospace" },
                    shrink: true,
                  }}
                  onChange={this.setCheckBoxValue.bind(this)}
                >
                  <MenuItem value={"Z"}>Z</MenuItem>
                  <MenuItem value={"N"}>N</MenuItem>
                  <MenuItem value={"W"}>W</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <a className="txt" name="xBelongsTo" onClick={this.buttonMaker.bind(this)}>
                {this.state.equationVariables.xBelongsTo}
              </a>
            )}
            &nbsp;
            {!this.state.addWithoutLimitationQuestion && <> &#x7d;</>}
            <br />
            <br />
            Want to add a question without limitation of set A :{" "}
            <Switch
              checked={this.state.addWithoutLimitationQuestion}
              onChange={this.selectwithoutRangeValue.bind(this)}
            />
            <br />
            {this.state.errorMessage && (
              <>
                <Typography variant="subtitle2" sx={{ color: "red" }} gutterBottom>
                  {this.state.errorMessage}
                </Typography>
              </>
            )}
            {this.state.editModeButton ? (
              <ColorButton
                disabled={this.state.editModeButtonChecker}
                style={{ "margin-top": "-20px", margin: "0rem 1rem" }}
                align="right"
                onClick={this.editModeOff.bind(this)}
              >
                Edit Mode Off
              </ColorButton>
            ) : (
              <span></span>
            )}
            <br />
            <br />
            <div align="left" label="buttons">
              <AddButton
                style={{ width: "15%" }}
                disabled={!this.state.submitChecker}
                onClick={this.onSubmit.bind(this)}
              >
                CALCULATE SUM
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {/* <AddButton style={{ width: "10%" }} onClick={this.onReset.bind(this)}>RESET</AddButton>
                        &nbsp;&nbsp;&nbsp; */}
              <AddButton style={{ width: "20%" }} onClick={this.showSolutionOnOff.bind(this)}>
                SHOW/HIDE SOLUTION
              </AddButton>
            </div>
            <div align="right" label="sumNavigator">
              <AddButton
                name="Left"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected - 1 < 0}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ◄
              </AddButton>
              &nbsp;&nbsp;&nbsp;
              {chartState.selected + 1}
              &nbsp;&nbsp;&nbsp;
              <AddButton
                name="Right"
                onClick={this.moveLeftAndRight}
                disabled={chartState.selected + 1 > data.length - 1}
                style={{ width: "0%", minWidth: "34px" }}
              >
                ►
              </AddButton>
            </div>
          </div>
          <br />
          <br />
          {this.state.solutionShow && (
            <div label="Answer" align="left">
              Now let`s find the Domain, Co-domain and Range
              <br />
              <br />( 1 ) <i>f</i> : &nbsp; {data[chartState.selected].setA}&nbsp;&#x2192;
              {data[chartState.selected].setB} ,<i>f</i> (<i>x</i>) = &nbsp; {data[chartState.selected].a}
              <i>x</i>
              <sup>{data[chartState.selected].aP}</sup> + &nbsp; {data[chartState.selected].b}
              <i>x</i>
              <sup>{data[chartState.selected].bP}</sup> + &nbsp; {data[chartState.selected].c} &nbsp; , A= &#x7b;&nbsp;
              <i>x</i>&nbsp;|{data[chartState.selected].loweLimitRange}&nbsp;
              {data[chartState.selected].loweLimitRangeComparator} <i>x</i>
              {data[chartState.selected].upperRangeComparator} {data[chartState.selected].upperLimitRange}, <i>x</i> ∊{" "}
              {data[chartState.selected].xBelongsTo} &#x7d;;
              <br />
              <br />
              Here, <i>Domain </i> D<sub>f</sub> = A = &#x7b;{" "}
              {data[chartState.selected].domain.map((a, i) => {
                if (i !== 0) {
                  return `,${a}`;
                }
                return `${a}`;
              })}{" "}
              &#x7d;
              <br />
              <br />
              Co-domain B = &nbsp; {data[chartState.selected].setA}
              <br />
              <br />
              Function <i>f</i> (<i>x</i>) = &nbsp; {data[chartState.selected].a}
              <i>x</i>
              <sup>{data[chartState.selected].aP}</sup> + &nbsp; {data[chartState.selected].b}
              <i>x</i>
              <sup>{data[chartState.selected].bP}</sup> + &nbsp; {data[chartState.selected].c}
              <br />
              <br />
              {console.log("data=>>", data.stepsOfFunction)}
              <ul>
                {data[chartState.selected].stepsOfFunction &&
                  data[chartState.selected].stepsOfFunction.map((a, i) => (
                    <li>
                      For <i>x</i> = {a.x}, &#402; ({a.x}) = &nbsp; {a.aXb} &nbsp;+ &nbsp; {a.c}
                      &nbsp; = &nbsp; {a.ans}
                    </li>
                  ))}
              </ul>
              <br />
              <br />
              &#x2234; &nbsp; Range of the function R
              <sub>
                <i>f</i>
              </sub>{" "}
              = &#x7b;{" "}
              {data[chartState.selected].xBelongsTo == "Z" && data[chartState.selected].limitation ? "....." : " "}
              {data[chartState.selected].range} {data[chartState.selected].limitation ? "....." : " "}&#x7d;
              <br />
              <br />
            </div>
          )}
          <br />
          <br />
        </div>
      );
    }
  }

  return (
    <div>
      <Container fixed>
        <OperatingRatio />
      </Container>
    </div>
  );
}
